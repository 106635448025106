import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import Entry from 'components/utils/draftJS/mentionEntry';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import {
  getCustomNumericSuggestions,
  getFormulaSuggestions,
  getNumericSuggestions,
  getVariableSuggestions,
} from 'utils/suggestions';

export class AutoSuggestTextInputTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: [],
      choices: [],
      suggestions2: [],
      choices2: [],
      flag: true,
      openVariablesMentionPopup: false,
      openNumMentionPopup: false,
    };

    this.mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      entityMutability: 'IMMUTABLE',
      mentionComponent: (mentionProps) => {
        let ignoreKWs = ['VARIABLES', 'FORMULAE', 'NUMERICS'];
        if (ignoreKWs.includes(mentionProps.mention.code)) return null;

        return (
          <span>
            <span
              style={{
                color: mentionProps.mention.code.includes('_delet_code') ? '#FF0000' : '#08CA6B',
                textDecorationLine: 'underline',
              }}
            >
              {mentionProps.children}
            </span>
          </span>
        );
      },
    });

    this.mentionPlugin2 = createMentionPlugin({
      mentionTrigger: '$',
      entityMutability: 'IMMUTABLE',
      mentionComponent: (mentionProps) => {
        return (
          <span>
            <span style={{ background: 'gainsboro' }}>{mentionProps.children}</span>
          </span>
        );
      },
    });
  }

  componentDidUpdate() {
    const condition =
      this.props.conditionContent !== 'undefined' && this.props.conditionContent !== ''
        ? this.props.conditionContent
        : null;
    if (condition && this.state.flag) {
      let editorState = EditorState.createWithContent(convertFromRaw(condition));
      this.setState({ editorState: editorState, flag: false });
    }
  }

  async componentDidMount() {
    const formulaSuggestions = getFormulaSuggestions();
    const customNumericSuggestions = getCustomNumericSuggestions();

    const numericSuggestions = await getNumericSuggestions(
      this.props.type === 'calculator' ? this.props.calculatorId : this.props.moduleId,
      this.props.type
    );
    const variableSuggestions = await getVariableSuggestions(
      this.props.type === 'calculator' ? this.props.calculatorId : this.props.moduleId,
      this.props.type,
      false
    );

    const suggestions = [
      ...formulaSuggestions,
      ...numericSuggestions,
      ...customNumericSuggestions,
      ...variableSuggestions,
    ];

    let values = [];
    for (let j = 0; j < 10; j++) {
      values.push({ code: j, name: j });
    }

    this.setState({
      suggestions: suggestions,
      suggestions2: values,
      choices2: values,
      choices: suggestions,
    });
  }

  onMentionOpenChange = (open, varName) => this.setState({ [varName]: open });

  onChange = (editorState) => {
    this.setState({ editorState });
    this.props.setTrigger(this.onExtractData());
  };

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List' ? list.size : list.length);

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) => !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1
    );
    const length = size(filteredSuggestions) < 100 ? size(filteredSuggestions) : 100;
    return filteredSuggestions.slice(0, length);
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: this.customSuggestionsFilter(value, this.state.choices),
    });
  };

  onSearchChange2 = ({ value }) => {
    this.setState({
      suggestions2: this.customSuggestionsFilter(value, this.state.choices2),
    });
  };

  onExtractData = () => {
    const contentState = this.state.editorState.getCurrentContent();
    return convertToRaw(contentState);
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const MentionSuggestions2 = this.mentionPlugin2.MentionSuggestions;
    const plugins = [this.mentionPlugin, this.mentionPlugin2];

    let editorStyle = {
      boxSizing: 'border-box',
      border: '1px solid #ddd',
      cursor: 'text',
      padding: '16px',
      marginBottom: '2em',
      background: '#fefefe',
    };

    return (
      <div style={editorStyle}>
        <Editor editorState={this.state.editorState} onChange={this.onChange} plugins={plugins} />
        <MentionSuggestions
          open={this.state.openVariablesMentionPopup}
          onOpenChange={(open) => this.onMentionOpenChange(open, 'openVariablesMentionPopup')}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          entryComponent={Entry}
        />
        <MentionSuggestions2
          open={this.state.openNumMentionPopup}
          onOpenChange={(open) => this.onMentionOpenChange(open, 'openNumMentionPopup')}
          onSearchChange={this.onSearchChange2}
          suggestions={this.state.suggestions2}
          entryComponent={Entry}
        />
      </div>
    );
  }
}

AutoSuggestTextInputTrigger.propTypes = {
  moduleId: PropTypes.string.isRequired,
  calculatorId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  conditionContent: PropTypes.string.isRequired,
};
