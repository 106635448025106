import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext } from 'react';
import { generatePath } from 'react-router';
import {
  MODULE_APIS,
  MODULE_CONDITIONAL_TEXTS,
  MODULE_CUSTOM_NUMBERS,
  MODULE_FLOW,
  MODULE_FORMULAS,
  MODULE_INFOBOXES,
  MODULE_INTEGRATIONS,
  MODULE_OUTPUT,
  MODULE_REFERENCES,
  MODULE_SMART_NOTES,
  MODULE_TRIGGERS,
} from 'routes/urls';
import { MODULE_TYPES } from '../../constants';
import { MenuItem } from './MainSidebar/MenuItem';

export const ModuleSidebar = () => {
  const { module } = useContext(ModuleContext);

  if (!module) return null;

  const submenus = [
    {
      link: generatePath(MODULE_TRIGGERS, { moduleType: module.type, moduleId: module.id }),
      label: 'Triggers',
    },
    {
      link: generatePath(MODULE_INFOBOXES, { moduleType: module.type, moduleId: module.id }),
      label: 'Infoboxes / Media',
    },
    {
      link: generatePath(MODULE_FORMULAS, { moduleType: module.type, moduleId: module.id }),
      label: 'Formulas',
      algoOnly: true,
    },
    {
      link: generatePath(MODULE_REFERENCES, { moduleType: module.type, moduleId: module.id }),
      label: 'Reference Managers',
    },
    {
      link: generatePath(MODULE_SMART_NOTES, {
        moduleType: module.type,
        moduleId: module.id,
      }),
      label: 'Smart Notes',
    },
    {
      link: generatePath(MODULE_CUSTOM_NUMBERS, {
        moduleType: module.type,
        moduleId: module.id,
      }),
      label: 'Custom Numbers',
    },
    {
      link: generatePath(MODULE_INTEGRATIONS, {
        moduleType: module.type,
        moduleId: module.id,
      }),
      label: 'Integrations',
      algoOnly: true,
    },
    {
      link: generatePath(MODULE_CONDITIONAL_TEXTS, {
        moduleType: module.type,
        moduleId: module.id,
      }),
      label: 'Conditional Texts',
    },
    {
      link: generatePath(MODULE_APIS, { moduleType: module.type, moduleId: module.id }),
      label: 'APIs',
    },
  ];

  return (
    <div>
      <MenuItem
        label='Flow'
        link={generatePath(MODULE_FLOW, { moduleType: module.type, moduleId: module.id })}
      />
      {module.type === MODULE_TYPES.CALCULATOR && (
        <>
          <MenuItem
            label='Formulas'
            link={generatePath(MODULE_FORMULAS, { moduleType: module.type, moduleId: module.id })}
          />
          <MenuItem
            label='Output'
            link={generatePath(MODULE_OUTPUT, { moduleType: module.type, moduleId: module.id })}
          />
        </>
      )}
      <MenuItem
        label='Resource'
        link={generatePath(MODULE_TRIGGERS, { moduleType: module.type, moduleId: module.id })}
        subMenu={
          module.type === MODULE_TYPES.ALGO ? submenus : submenus.filter((menu) => !menu.algoOnly)
        }
      />
    </div>
  );
};
