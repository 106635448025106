import { MenuList, Popover } from '@material-ui/core';
import CreateIcon from 'assets/icons/createIcon';
import SearchIcon from 'assets/icons/searchIcon';
import {
  CreateCustom,
  EditButton,
  InfoboxSearchInput,
  MentionInfoboxMenuItem,
} from 'components/utils/styled-components/FormStyle';
import { Component } from 'react';
import { connect } from 'react-redux';
import { InputGroup } from 'reactstrap';

class ConditionalTextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { searchInput: '' };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  addItem = (obj) => {
    this.props.addItem(obj);
    this.setState({ searchInput: '' });
  };

  closeMenu = () => {
    this.props.closeMenu();
    this.setState({ searchInput: '' });
  };

  render() {
    const conditionalTexts = this.props.conditionalTextState?.conditionalTexts || [];
    const filteredConditionalTexts = conditionalTexts.filter((conditionalText) =>
      conditionalText.name.toLowerCase().includes(this.state.searchInput.toLowerCase())
    );

    return (
      <div>
        <Popover
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          // disableScrollLock
          // disableEnforceFocus
          getContentAnchorEl={null}
          anchorEl={this.props.dropDownEl}
          open={Boolean(this.props.dropDownEl)}
          onClose={this.closeMenu}
        >
          <InputGroup className='ip-grp' style={{ alignItems: 'center' }}>
            <SearchIcon style={{ marginLeft: '25px' }} />
            <InfoboxSearchInput
              autoFocus
              name='searchInput'
              placeholder='Search'
              value={this.state.searchInput}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={this.onChange}
            />
          </InputGroup>

          {filteredConditionalTexts?.length > 0 && (
            <MenuList
              autoFocus
              style={{
                maxHeight: 200,
                overflowY: 'scroll',
                paddingBottom: 15,
                backgroundColor: '#F9FAFB',
                paddingTop: '13px',
              }}
            >
              {filteredConditionalTexts.map((item) => (
                <MentionInfoboxMenuItem
                  className='numericsMenuWrapper'
                  key={item.id}
                  style={{
                    color: item.unique_code === this.props.selectedItem ? '#08A88E' : '#172B4D',
                  }}
                  onClick={() => this.addItem({ name: item.name, code: item.unique_code })}
                >
                  {item.name || 'No Title'}
                  <EditButton
                    className='editButton'
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.toggleEditModal(item.id);
                    }}
                  >
                    {' '}
                    Edit{' '}
                  </EditButton>
                </MentionInfoboxMenuItem>
              ))}
            </MenuList>
          )}
          <div
            style={{
              borderTop: '1px solid #EBECF0',
              width: '334px',
              paddingLeft: 8,
              paddingTop: 19,
              paddingBottom: 18,
            }}
          >
            <CreateCustom
              startIcon={<CreateIcon />}
              className='waves-effect waves-light mr-2'
              onClick={this.props.toggleCreateModal}
            >
              <p style={{ marginTop: '5px', fontSize: '18px', fontWeight: '500' }}> Create </p>
            </CreateCustom>
          </div>
        </Popover>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  conditionalTextState: state.conditionalTextState,
});

export default connect(mapStateToProps, {})(ConditionalTextMenu);
