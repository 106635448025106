import Invitation from 'components/teams/Invitation';
import AuthorForm from 'components/teams/authorForm';
import MemberManagment from 'components/teams/memberManagment';
import EHRConfig from 'components/ehr';
import CreateTeam from 'components/teams/team-settings/createTeam';
import {
  CREATE_TEAM_ROUTE,
  MEM_MANGEMENT_ROUTE,
  OLD_CREATE_TEAM_ROUTE,
  EHR_CONFIG_ROUTE,
  OLD_EHR_ORDER_INDIVIDUAL_ROUTE,
} from '../../constants';
import { OldIndividualEHROrder } from '../../components/ehr/ehr-order/OldIndividualEHROrder';

const TeamRoutes = [
  {
    type: 'PUBLIC',
    props: {
      path: '/invitation/:inviteCode',
      component: Invitation,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: '/authorForm',
      component: AuthorForm,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CREATE_TEAM_ROUTE,
      component: CreateTeam,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: OLD_CREATE_TEAM_ROUTE,
      to: CREATE_TEAM_ROUTE,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MEM_MANGEMENT_ROUTE,
      component: MemberManagment,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: EHR_CONFIG_ROUTE,
      component: EHRConfig,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: OLD_EHR_ORDER_INDIVIDUAL_ROUTE,
      component: OldIndividualEHROrder,
    },
  },
];

export default TeamRoutes;
