import mixpanel from 'mixpanel-browser';
const projectToken =
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '64a81e89c4bd66e362f852178fb900e1'; // fallback to dev token
mixpanel.init(projectToken, { debug: process.env.NODE_ENV === 'development', ignore_dnt: true });
if (process.env.NODE_ENV === 'test') mixpanel.disable();

export const identify = (email: string, properties?: any) => {
  mixpanel.identify(email);
  if (properties) mixpanel.people.set(properties);
};

export const track = (event: Events, obj?: any) => {
  if (typeof obj !== 'object') obj = { message: obj };
  mixpanel.track(event, obj);
};

export enum Events {
  API_ERROR = 'API_ERROR',
}
