import _ from 'lodash';

// traversal of nested object to find an empty rule
export const forEachNested = (O, f, cur) => {
  O = [O]; // ensure that f is called with the top-level object
  while (O.length) {
    // keep on processing the top item on the stack
    if (
      !f((cur = O.pop())) && // do not spider down if `f` returns true
      cur instanceof Object && // ensure cur is an object, but not null
      [Object, Array].includes(cur.constructor) //limit search to [] and {}
    ) {
      // delete valueSrc property from object; (Not required to check for empty rule)
      // In case of "between" operator, it has inconsistant behavior then other operators,
      // it contains null value even if all values are filled
      cur.hasOwnProperty('valueSrc') && delete cur.valueSrc;
      // delete valueError property from object; (Not required to check for empty rule)
      cur.hasOwnProperty('valueError') && delete cur.valueError;
      O.push.apply(O, Object.values(cur));
    } //search all values deeper inside
  }
};

export const ifEmptyRuleFound = (queryValue) => {
  let isEmptyRuleFound = false;
  forEachNested(_.cloneDeep(queryValue), function (currentValue) {
    if (currentValue === null || currentValue === undefined) {
      isEmptyRuleFound = true;
      return;
    }
  });

  return isEmptyRuleFound;
};

export const convertCondition = (condition, index) => {
  let start = 0;
  let end = 0;
  for (let i = index; i >= 0; i--) {
    if (condition[i] === '[') {
      start = i;
      break;
    }
  }
  for (let i = index; i <= condition.length; i++) {
    if (condition[i] === ')') {
      end = i + 1;
      break;
    }
  }
  let multiselect = condition.slice(start, end);
  var regExp = /\(([^)]+)\)/;
  let match = regExp.exec(multiselect);
  let choices = match && match[1] ? match[1].split(',') : [];
  let variable = multiselect.match(/\[(.*?)\]/)[0];
  let final_condition = '';
  if (~multiselect.indexOf('NOT IN')) {
    for (let i = 0; i < choices.length; i++) {
      final_condition += variable + ' != ' + choices[i];
      if (i < choices.length - 1) {
        final_condition += ' || ';
      }
    }
  } else if (~multiselect.indexOf('IN')) {
    for (let i = 0; i < choices.length; i++) {
      final_condition += variable + ' == ' + choices[i];
      if (i < choices.length - 1) {
        final_condition += ' || ';
      }
    }
  }
  condition = [condition.slice(0, start), final_condition, condition.slice(end)].join('');
  return condition;
};

export const removePrefixesInCondition = (inputString) => {
  return inputString
    .replace(/numerics./g, '')
    .replace(/customNumerics./g, '')
    .replace(/candidate_triggers./g, '')
    .replace(/triggers./g, '')
    .replace(/variables./g, '')
    .replace(/formulae./g, '');
};
