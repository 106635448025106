import { Combobox } from '@headlessui/react';
import Button from 'components/utils/Button';
import { useState } from 'react';
import DropdownArrowIcon from '../../../assets/icons/dropdownArrowIcon';
import { useTeam } from '../../../hooks/useTeam';
import Avatar from '../../utils/Avatar';
import { Body2, Caption2 } from '../../utils/typo';

interface VerifierListBoxProps {
  onChange: (userId: number | null) => void;
  reviewer: number | null;
}

export function VerifierComboBox({ onChange, reviewer }: VerifierListBoxProps) {
  const { team } = useTeam();
  const [query, setQuery] = useState<string>('');
  const filteredTeammates = !query
    ? team.teammates
    : team.teammates.filter(
        ({ user }) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );

  return (
    <div className='w-[600px] select-none'>
      <Combobox value={reviewer} onChange={onChange}>
        <Combobox.Button className='mt-1 flex h-[40px] w-full items-center justify-between rounded border border-gray-300 px-4 py-3'>
          <Combobox.Input
            className='w-full focus:outline-0'
            onChange={(event) => setQuery(event.target.value)}
            placeholder='Select member by name or email'
            displayValue={(reviewer) =>
              team.teammates.find((teammate) => teammate.user.id === reviewer)?.user.name ?? ''
            }
          />
          <DropdownArrowIcon />
        </Combobox.Button>
        <Combobox.Options className='relative'>
          <div className='absolute z-10 max-h-[432px] w-[600px] overflow-y-scroll rounded bg-white pb-1 shadow-04'>
            {filteredTeammates.map((teammate) => (
              <Combobox.Option key={teammate.user.id} value={teammate.user.id}>
                <div className='flex cursor-pointer items-center gap-3 px-4 py-3 hover:bg-gray-200'>
                  <Avatar name={teammate.user.name} />
                  <div>
                    <Body2>{teammate.user.name}</Body2>
                    <Caption2>{teammate.user.email}</Caption2>
                  </div>
                </div>
              </Combobox.Option>
            ))}
          </div>
        </Combobox.Options>
      </Combobox>
      {!!reviewer && (
        <div className='flex'>
          <div className='flex'>
            <Button.Reverse type='button' onClick={() => onChange(null)}>
              Deselect
            </Button.Reverse>
          </div>
        </div>
      )}
    </div>
  );
}
