import EditIcon from 'assets/icons/grayEditIcon';
import { Icons } from 'components/utils/Icons';
import { twMerge } from 'tailwind-merge';
import { Editor } from '@tiptap/core';
import { useRef } from 'react';
import { UploadMedia } from '../../tiptap-expand-options/UploadMedia';

interface ImageAlignmentButtonsProps {
  editor: Editor;
  float: 'left' | 'right' | 'center';
}

export const ImageAlignmentButtons = ({ editor, float }: ImageAlignmentButtonsProps) => {
  const mediaRef = useRef<HTMLInputElement>(null);
  const alignImage = (e, float: 'left' | 'right' | 'center') => {
    e.preventDefault();
    editor.chain().focus().updateAttributes('image', { float }).run();
  };

  return (
    <>
      <div className='flex h-5 items-center justify-center gap-1.5 !rounded-sm !border !border-solid border-primary-800 bg-gray-100 !pr-1'>
        <div className='flex h-full w-5 items-center justify-center border-r border-solid border-primary-800 bg-primary-200 '>
          <Icons.TiptapMediaNode />
        </div>
        <Icons.TiptapLeftAlign
          onClick={(e) => alignImage(e, 'left')}
          className={twMerge('cursor-pointer', float === 'left' && 'bg-gray-300')}
        />
        <Icons.TiptapCenterAlign
          onClick={(e) => alignImage(e, 'center')}
          className={twMerge('cursor-pointer', float === 'center' && 'bg-gray-300')}
        />
        <Icons.TiptapRightAlign
          onClick={(e) => alignImage(e, 'right')}
          className={twMerge('cursor-pointer', float === 'right' && 'bg-gray-300')}
        />
        <div className='h-3 w-[1px] bg-gray-300' />
        <button
          onClick={(e) => {
            e.preventDefault();
            mediaRef.current?.click();
          }}
        >
          <EditIcon className='h-3 w-3.5 cursor-pointer' />
        </button>
        <div className='h-3 w-[1px] bg-gray-300' />
        <Icons.TrashBin
          className='h-3.5 w-3.5 cursor-pointer'
          onClick={() => editor.commands.deleteSelection()}
        />
      </div>

      <UploadMedia isEditMode editor={editor} mediaRef={mediaRef} />
    </>
  );
};
