import React from 'react';

export default function HighlightIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' rx='4' fill='white' />
      <path
        d='M19.7863 6.38636L16.6577 3.25951C16.4928 3.09429 16.2692 3.001 16.0357 3.00001C15.8023 2.99901 15.5779 3.09039 15.4116 3.2542L7.74005 10.8063C7.62047 10.9246 7.53672 11.0743 7.49844 11.2382L6.59215 15.1651L5 17H7.5029L8.5 16L11.6891 15.4288C11.8484 15.3917 11.9944 15.3111 12.1113 15.1969L19.7819 7.64312C19.8652 7.56114 19.9313 7.46348 19.9767 7.35579C20.022 7.2481 20.0455 7.1325 20.0459 7.01566C20.0463 6.89883 20.0236 6.78307 19.9791 6.67506C19.9345 6.56705 19.869 6.46893 19.7863 6.38636ZM11.4944 13.3189L9.61722 11.4426L16.0276 5.13137L17.9039 7.00766L11.4944 13.3189Z'
        fill='#1E1F20'
      />
      <path d='M4 18H20V20H4V18Z' fill='#FE8E3D' />
    </svg>
  );
}
