import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../common/Icon';

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onClose: () => void;
  size?: 'small' | 'medium' | 'large';
}
export function Modal({ open, children, className, onClose, size = 'medium' }: ModalProps) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog onClose={onClose} className='relative z-[1300]'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 z-0 bg-black/30' aria-hidden='true' />
        </Transition.Child>
        <div className='fixed inset-0'>
          <div className='flex min-h-full items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={twMerge(
                  'h-full max-h-[80vh] w-full overflow-y-auto rounded bg-white px-6',
                  clsx(
                    { 'max-w-[920px]': size === 'large' },
                    { 'max-w-[504px]': size === 'medium' },
                    { 'max-w-[360px]': size === 'small' }
                  ),
                  className
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export interface ModalHeadProps extends HTMLAttributes<HTMLHeadingElement> {
  onClose?: () => void;
}
Modal.Head = function ModalHead({ onClose, children, className, ...props }: ModalHeadProps) {
  return (
    <Dialog.Title
      as='h4'
      className={twMerge(
        'flex w-full items-center justify-between gap-x-3 py-3 text-gray-900',
        className
      )}
      {...props}
    >
      <div>{children} </div>
      {onClose && (
        <button
          onClick={onClose}
          className='focus:bg-transparent' // due to material ui
        >
          <Icon.Close />
        </button>
      )}
    </Dialog.Title>
  );
};

export interface modalBodyProps extends HTMLAttributes<HTMLDivElement> {}
Modal.Body = function modalBody({ children, className, ...props }: modalBodyProps) {
  return (
    <div
      className={twMerge('w-full gap-y-4 whitespace-pre-line py-3 text-gray-900', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export interface modalFooterProps extends HTMLAttributes<HTMLDivElement> {}
Modal.Footer = function modalFooter({ children, className, ...props }: modalFooterProps) {
  return (
    <div
      className={twMerge('sticky bottom-0 z-10 w-full whitespace-pre-line bg-white', className)}
      {...props}
    >
      {children}
    </div>
  );
};
