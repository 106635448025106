import { ReactNode } from 'react';

interface AccoutLayoutProps {
  children?: ReactNode;
}

const AccountLayout = ({ children }: AccoutLayoutProps) => {
  return (
    <div className='min-h-screen h-0 py-10 px-3'>
      <div className='max-w-[440px] w-full mx-auto h-full flex items-center justify-center'>
        {children}
      </div>
    </div>
  );
};

export default AccountLayout;
