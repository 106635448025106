import { Box, Checkbox, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#08A88E',
    },
  },
  checked: {},
})(Checkbox);

export const FormTitle = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '30px',
    color: '#000000',
  },
})(Typography);

export const FormSubtitle = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#989898',
    marginBottom: '40',
  },
})(Typography);

export const ChannelsList = withStyles({
  root: {
    marginTop: 10,
    overflow: 'scroll',
    height: '100%',
    paddingBottom: '20px',
  },
})(Box);

export const ChannelBox = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) -62.73%, #FFFFFF 68.16%)',
  },
}))(Box);

export const ChannelNameBox = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
})(Box);

export const ChannelName = withStyles({
  root: {
    marginBottom: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '27px',
  },
})(Typography);

export const ChannelLastSynced = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#AFAFAF',
  },
})(Typography);

export const UniversalCheckboxContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: 70,
    alignItems: 'center',
  },
})(Box);

export const UniversalCheckboxLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: 20,
    color: '#797979',
    // marginTop: 5,
    marginLeft: 10,
  },
})(Typography);
