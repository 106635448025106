import { GET_TEAM_MIRRORS } from 'actions/actionsTypes';

const mirrorReducer = (state = { mirrors: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_MIRRORS:
      return {
        ...state,
        mirrors: action.payload,
      };

    default:
      return state;
  }
};

export default mirrorReducer;
