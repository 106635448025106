import Button from 'components/utils/Button';
import { useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import {
  DecimalOptions,
  FormattingOptions,
  RoundingOptions,
} from '../../tiptap-expand-options/suggestion-handler/VariableHandler';

interface EditLinkProps {
  editor: Editor;
}

export const EditNumber = ({ editor }: EditLinkProps) => {
  const [decimal, setDecimal] = useState<string>('0');
  const [rounding, setRounding] = useState<string>('ceil');

  useEffect(() => {
    const { node } = editor.state.selection as any;
    setDecimal(node?.attrs.decimalPlaces || '0');
    setRounding(node?.attrs.decimalRounding || 'ceil');
  }, [editor]);
  const onAdd = () => {
    editor
      .chain()
      .focus()
      .updateAttributes('mention', { decimalPlaces: decimal, decimalRounding: rounding })
      .run();
  };

  return (
    <div className='flex w-[340px] flex-col gap-4 rounded bg-white !p-4 shadow-04'>
      <div className='flex gap-5'>
        <FormattingOptions
          title='Show'
          selected={decimal}
          onClick={setDecimal}
          options={DecimalOptions}
        />
        <FormattingOptions
          title='Rounded'
          selected={rounding}
          onClick={setRounding}
          options={RoundingOptions}
        />
      </div>
      <div className='flex justify-end gap-2'>
        {/* <Button.Reverse
          type='button'
          className='flex h-9 !w-20 items-center justify-center text-button-1'
        >
          Cancel
        </Button.Reverse> */}
        <Button
          type='button'
          onClick={onAdd}
          className='flex h-9 !w-20 items-center justify-center text-button-1'
        >
          Save
        </Button>
      </div>
    </div>
  );
};
