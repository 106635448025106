import { QueryBuilder } from 'components/resources/triggers/QueryBuilder';
import { CreateButton } from 'components/utils/styled-components/FormStyle';

function ConditionBuilderForm(props) {
  const {
    moduleId,
    calculatorId,
    type,
    setCondition,
    setConditionLogic,
    setQueryValue,
    setChoiceMapping,
    queryValue,
    addConditionTrigger,
    closePanel,
  } = props;
  const queryBuilderProps = {
    moduleId: moduleId,
    calculatorId: calculatorId,
    type: type,
    setCondition: setCondition,
    setConditionLogic: setConditionLogic,
    setQueryValue: setQueryValue,
    setChoiceMapping: setChoiceMapping,
    queryValue: queryValue,
  };

  return (
    <div className='flex min-h-[95%] flex-col'>
      <div className='flex-grow'>
        <QueryBuilder {...queryBuilderProps} />
      </div>

      <div className='flex justify-end py-4'>
        <CreateButton
          onClick={() => {
            addConditionTrigger();
            closePanel();
          }}
        >
          {queryValue ? 'Update' : 'Create'}
        </CreateButton>
      </div>
    </div>
  );
}

export default ConditionBuilderForm;
