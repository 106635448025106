import createAlignmentPlugin from '@draft-js-plugins/alignment';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import RedirectIcon from 'assets/icons/RedirectIcon';
import PhoneIconForText from 'assets/icons/phoneIconForText';
import { DEFAULT_EDITOR_JSON } from 'constants';
import { CompositeDecorator, EditorState, convertFromRaw } from 'draft-js';
import _ from 'lodash';
import React from 'react';
import { twJoin } from 'tailwind-merge';
import './draftjsEditorStyles.css';
import { conditionalTextStyle, editorPreviewStyle, styleMap } from './styles';

class EditorDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.focusPlugin = createFocusPlugin();
    this.resizeablePlugin = createResizeablePlugin();
    this.alignmentPlugin = createAlignmentPlugin();
    // const { AlignmentTool } = alignmentPlugin;
    const decorator = composeDecorators(
      this.resizeablePlugin.decorator,
      this.alignmentPlugin.decorator,
      this.focusPlugin.decorator
    );
    const isSimulator = this.props.isSimulator;
    this.imagePlugin = createImagePlugin({ decorator });
    this.mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      mentionComponent: (mentionProps) => {
        let ignoreKWs = [
          'KNOWLEDGE_BASES',
          'VARIABLES',
          'INFOBOXES',
          'REFERENCES',
          'FORMULAE',
          'NUMERICS',
          'MEDIA',
          'heading',
        ];
        const code = mentionProps.mention.code || mentionProps.mention.unique_code;
        if (ignoreKWs.includes(code)) return null;
        const isPhoneNo = code === 'phoneNumber',
          isLink = ['embedded', 'unembedded', 'module', 'calculator'].includes(
            mentionProps.mention.type
          ),
          isMultiVariable =
            code.includes('variable') && mentionProps.mention.type === 'multi_variable',
          isChoiceVariable =
            code.includes('variable') && mentionProps.mention.type === 'choice_variable',
          isTextVariable =
            code.includes('variable') && mentionProps.mention.type === 'text_input_variable',
          isCustomNumeric = code.includes('customnumeric'),
          isNumeric = code.includes('numeric'),
          isConditionalText = code.includes('conditionaltext');

        const normalStyle = {
          textDecorationLine: isPhoneNo ? 'none' : 'underline',
          display: isLink ? 'inline-flex' : 'inherit',
          color: code.includes('_delet_code') ? '#FF0000' : '#08A88E',
        };
        const simulatorStyle = {
          color: '#08A88E',
        };

        return (
          <span>
            <span
              style={
                isSimulator && !isPhoneNo
                  ? simulatorStyle
                  : isConditionalText ||
                      isChoiceVariable ||
                      isMultiVariable ||
                      isTextVariable ||
                      isNumeric ||
                      isCustomNumeric
                    ? {
                        ...conditionalTextStyle,
                        color: code.includes('_delet_code') ? '#FF0000' : '#08A88E',
                      }
                    : normalStyle
              }
            >
              {isLink ? (
                <span href={code} style={normalStyle}>
                  <div style={{ marginRight: 5 }}>
                    <RedirectIcon />
                  </div>
                  {mentionProps.children}
                </span>
              ) : (
                <>
                  {isPhoneNo && <PhoneIconForText style={{ marginRight: 5, marginBottom: 3 }} />}

                  {isSimulator ? mentionProps.mention.name : mentionProps.children}
                </>
              )}
            </span>
          </span>
        );
      },
    });

    this.linkPlugin = createLinkPlugin({
      Link: (props) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();

        return (
          <a href={url}>
            <span
              style={{
                color: '#2996da',
                textDecoration: 'underline',
              }}
            >
              {props.children}
            </span>
          </a>
        );
      },
    });
  }

  async componentDidMount() {
    const contentJson =
      this.props.contentJson !== 'undefined' &&
      this.props.contentJson !== null &&
      this.props.contentJson !== ''
        ? this.props.contentJson
        : DEFAULT_EDITOR_JSON;

    const plugins = [this.mentionPlugin, this.linkPlugin];
    const decorators = _.flattenDeep(plugins.map((plugin) => plugin.decorators));

    const decorator = new CompositeDecorator(decorators.filter((decorator, index) => index !== 1));

    let editorState = null;
    if (contentJson) {
      editorState = EditorState.createWithContent(convertFromRaw(contentJson), decorator);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.setState({ editorState: editorState });
  }

  async componentDidUpdate(prevProps) {
    const contentJson =
      this.props.contentJson !== 'undefined' && this.props.contentJson !== null
        ? this.props.contentJson
        : null;

    if (prevProps.contentJson !== this.props.contentJson && contentJson) {
      const plugins = [this.mentionPlugin, this.linkPlugin];
      const decorators = _.flattenDeep(plugins.map((plugin) => plugin.decorators));

      const decorator = new CompositeDecorator(
        decorators.filter((decorator, index) => index !== 1)
      );

      let editorState = EditorState.createWithContent(convertFromRaw(contentJson), decorator);

      this.setState({ editorState: editorState });
    }
  }

  render() {
    const plugins = [
      this.mentionPlugin,
      this.linkPlugin,
      this.imagePlugin,
      this.alignmentPlugin,
      this.focusPlugin,
      this.resizeablePlugin,
    ];

    return (
      <div
        style={editorPreviewStyle}
        className={twJoin(
          this.props.isLineClampTwo && 'line-clamp-2',
          this.props.isListItem && 'line-clamp-4 text-caption-2'
        )}
      >
        <Editor
          editorState={this.state.editorState}
          plugins={plugins}
          readOnly={true}
          customStyleMap={styleMap}
          onChange={() => {}}
        />

        {/* <MentionSuggestions suggestions={[]} /> */}
      </div>
    );
  }
}

export default EditorDisplay;
