import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { hasViewOnlyPermission } from 'utils/permissions';
import ListItem from './ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 549,
    background: '#FBFBFB',
    border: '1px dashed #E4E3E3',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '16px',
  },
}));

export default function OutputCategory(props) {
  const classes = useStyles();
  const {
    calcId,
    outputCategories,
    addCategory,
    updateCategory,
    updateCategories,
    deleteCatgeory,
    clearCategoryCondition,
    setCategoryCondition,
  } = props;

  return (
    <div>
      <Card className={classes.root} variant='outlined'>
        <CardContent>
          <div
            style={{
              background: '#FFFFFF',
              border: '1px solid #DADADA',
              boxSizing: 'border-box',
              borderRadius: '10px',
              borderBottom: '2px solid rgb(218, 218, 218)',
            }}
          >
            <ListItem
              list={outputCategories}
              handleDeleteClick={deleteCatgeory}
              handleListChange={updateCategory}
              updateListItems={updateCategories}
              calcId={calcId}
              clearCategoryCondition={clearCategoryCondition}
              setCategoryCondition={setCategoryCondition}
            />
          </div>
          {!hasViewOnlyPermission('calculator') && (
            <div style={{ marginTop: '1.5em' }}>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '20px',
                  /* identical to box height */
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: '#bdbdbd',
                  marginRight: '6px',
                }}
              >
                Add
              </span>
              <Button
                variant='contained'
                style={{
                  width: '115px',
                  height: '40px',
                  background: '#08A88E',
                  borderRadius: '6px',
                  border: 'none',
                  color: 'white',
                }}
                className={classes.button}
                onClick={addCategory}
                startIcon={<AddCircleOutlineIcon />}
              >
                Category
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
