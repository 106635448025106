export const capitalizeWords = (inputString: string): string => {
  // Replace '-' with space
  let updatedString: string = inputString.replace(/-/g, ' ');

  // Capitalize the first letter of each word
  let capitalizedString: string = updatedString
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return capitalizedString;
};
