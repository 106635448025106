import * as React from 'react';

function PicIcon(props) {
  return (
    <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.66667 3.2H0V7.2H0.00833333L0 14.4C0 15.28 0.75 16 1.66667 16H16.6667V14.4H1.66667V3.2ZM5.83333 10.4H17.5L14.5833 6.8L12.5 9.208L9.58333 5.6L5.83333 10.4ZM18.3333 1.6H11.6667L10 0H5C4.08333 0 3.34167 0.72 3.34167 1.6L3.33333 11.2C3.33333 12.08 4.08333 12.8 5 12.8H18.3333C19.25 12.8 20 12.08 20 11.2V3.2C20 2.32 19.25 1.6 18.3333 1.6ZM18.3333 11.2H5V1.6H9.30833L10.4833 2.728L10.975 3.2H18.3333V11.2Z'
        fill='#1E1F20'
      />
    </svg>
  );
}

export default PicIcon;
