import axios from 'axios';
import { sprintf } from 'sprintf-js';
import { CHANNEL_API, TEAM_CAHNNELS_BULK_DELETE, TEAM_CHANNELS_API } from '../constants';
import { IModuleList } from './module';

export interface IChannelList {
  id: number;
  name: string;
  password?: string;
  channel_code: string;
  is_institutional: boolean;
  description?: string;
}

interface IChannel extends IChannelList {
  team: number;
  last_synced?: string;
  subscription_link?: string;
  is_generic: boolean;
  modules: IModuleList[];
}

export interface ChannelData {
  name: string;
  password?: string;
  channel_code: string;
  is_institutional: boolean;
  description: string;
  is_generic: boolean;
  modules: IModuleList[];
}

export const teamChannelAPI = {
  getTeamChannels: () => axios.get<IChannelList[]>(TEAM_CHANNELS_API).then((res) => res.data),
  getChannel: (
    channelId: number // TODO: need to refactor after backend refactoring
  ) =>
    axios.get<IChannel>(sprintf(CHANNEL_API, channelId)).then((res) => {
      const mirrors = (res.data as any).mirrors.map((mirrorItem) => {
        const { module, id, type } = mirrorItem;
        const { code, is_in_current_team, is_universal, title } = module;
        return {
          code,
          id,
          type,
          is_in_current_team,
          is_universal,
          title,
        };
      });
      return {
        ...res.data,
        modules: [...res.data.modules, ...(res.data as any).calculators, ...mirrors],
      };
    }),
  deleteTeamChannel: (channelId: number) =>
    axios.delete(sprintf(CHANNEL_API, channelId)).then((res) => res.data),
  bulkDeleteChannel: (channelIds: { ids: string[] }) =>
    axios.post(TEAM_CAHNNELS_BULK_DELETE, channelIds).then((res) => res.data),
  postChannel: (data) => axios.post(TEAM_CHANNELS_API, data).then((res) => res.data), // TODO: need to refactor after backend refactoring
  patchChannel: (
    data: ChannelData,
    channelId: number // TODO: need to refactor after backend refactoring
  ) => axios.patch(sprintf(CHANNEL_API, channelId), data).then((res) => res.data),
};
