import { Box, CircularProgress } from '@material-ui/core';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import setSelectedTeam from 'actions/team/selectedTeamAction';
import InviteToPlatformModal from '../invitePlatform';
import TeamMembers from './partials/team-members';
import { MainButton, StyledBackdrop, StyledBox, Title } from './styles';
import { withTeam } from '../../../hooks/useTeam';
import { ROLES } from '../../../api/team';

class TeamDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteMemberModal: false,
      inviteModal: false,
      isRedirect: false,
      isLoading: false,
      memberId: null,
    };
  }

  async componentDidMount() {
    await this.resetTeams();
  }

  resetTeams = async () => {
    this.setState({ isLoading: true });
    await this.props.setSelectedTeam(false);
    this.setState({ isLoading: false });
  };

  toggleInviteModal = () => {
    this.setState((previous) => ({
      inviteModal: !previous.inviteModal,
    }));
  };

  render() {
    const team = this.props.team;
    const isAdmin = team?.current_teammate.role === ROLES.Admin;

    return (
      <>
        <StyledBackdrop open={this.state.isLoading}>
          <CircularProgress style={{ color: '#08A88E' }} />
        </StyledBackdrop>

        {team?.teammates?.length > 0 && (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledBox>
              <Title>{isAdmin ? ' Member Management' : 'Members'}</Title>
              <Link
                to='#'
                style={{ marginLeft: 'auto', float: 'right' }}
                onClick={this.toggleInviteModal}
              >
                <MainButton>Invite Members</MainButton>
              </Link>
            </StyledBox>

            <TeamMembers isLoading={this.state.isLoading} resetTeams={this.resetTeams} />
          </Box>
        )}

        <InviteToPlatformModal open={this.state.inviteModal} toggle={this.toggleInviteModal} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withTeam(connect(mapStateToProps, { setSelectedTeam })(TeamDetail));
