import { SuggestionTypeEnum } from 'components/utils/tiptap/tiptapInterfaces';
import { SuggestionHandlerProps } from '../MentionPopover';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MentionContext } from '../../../module/MentionContext';
import { ComboBox } from '../../../ComboBox';

export const EHROrderHandler = ({ onChange, onDisabledChange }: SuggestionHandlerProps) => {
  const { suggestions } = useContext(MentionContext);

  const filteredSuggestions = useMemo(() => {
    const suggestionType = SuggestionTypeEnum.EHR_ORDER;

    return suggestions
      .filter((suggestion) => suggestion.type.includes(suggestionType))
      .map((option) => ({
        value: option.code,
        label: option.name,
      }));
  }, [suggestions]);

  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    const option = suggestions.find((option) => option.code === selectedOption);
    if (!option) {
      onDisabledChange(true);
      return;
    }
    onChange({ ...option });
    onDisabledChange(false);
  }, [suggestions, selectedOption]);

  return (
    <>
      <ComboBox
        options={filteredSuggestions}
        selectedValue={selectedOption}
        onChange={(value) => setSelectedOption(value as string)}
      />
    </>
  );
};
