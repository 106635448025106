import { Box, Button, CircularProgress, Grid, Popover } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { getCalculators } from 'actions/calculators/getCalculators';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getTriggers } from 'actions/resources/getTriggers';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import { dataExtractorAPI } from 'api/data-extractor';
import AddIcon from 'assets/icons/addIcon';
import axios from 'axios';
import clsx from 'clsx';
import AppearingSituation from 'components/utils/AppearingSituation';
import SectionDropdown from 'components/utils/SectionDropdown';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import AnswerSection from 'components/utils/answers/AnswerSection';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import ExampleNToolsList from 'components/utils/example-n-tools/ExampleNToolsList';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Header } from 'components/utils/panels/Header';
import { StyledFormGroupMB30 } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, CARD_MULTIPLE_CHOICE, SAVE_BUTTON } from 'constants/variables';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { getVariableName } from 'utils/gptUtils';
import { isOnboardingModule } from 'utils/utilityFunctions';
import {
  CHOICE_PANEL_API_URL,
  MODULE_API_URL,
  MODULE_TYPES,
  RETRIEVE_CHOICE_ITEM_API_URL,
} from '../../constants';
import { ChoicePanelFormDispatchProps } from './types';

const AddLabel = styled(Label)`
  font-family: Roboto !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  text-transform: uppercase;

  color: #bdbdbd !important;
`;

const AddButton = withStyles(() => ({
  root: {
    background: '#08A88E !important',
    borderRadius: '6px 6px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#ffffff !important',
    minWidth: '95px',
    height: '40px',
    '&:hover': {
      background: '#08A88E !important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      background: '#08A88E !important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
}))(Button);

export const CreateButton = withStyles(() => ({
  root: {
    width: '148px',
    height: '60px',
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: '#08A88E',
    borderRadius: '100px',
    boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      background: '#08A88E',
      boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    },
    '&:focus': {
      background: '#08A88E',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
  disabled: {
    backgroundColor: '#E5EDF0 !important',
    color: '#ffffff !important',
  },
}))(Button);

export const CancelButton = withStyles(() => ({
  root: {
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: 'transparent',
    color: '#08A88E',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))(Button);

const Examples = styled(Grid)`
  width: 99%;
  background: #fbfbfb;
  border: 1px dashed #e4e3e3;
  box-sizing: border-box;
  border-radius: 10px;
`;
export const FixedRow = styled(Box)`
  position: sticky;
  bottom: 0;
  right: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 9;
`;
export const UnderlyingElement = styled(Box)`
  width: 100%;
  height: 100px;
  background-color: white;
`;

export const MemberInput = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 850px;
  height: 45px;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: bold;
`;

const StyledFormControlLabel = withStyles((theme) => ({
  label: {
    marginTop: 5,
  },
}))(FormControlLabel);

interface ChoicePanelFormState {
  textModal: boolean;
  updateTextModal: boolean;
  choiceModal: boolean;
  updateChoiceModal: boolean;
  infoboxModal: boolean;
  updateInfoboxModal: boolean;
  toolInfoboxModal: boolean;
  updateToolInfoboxModal: boolean;
  isChangeAble: boolean;
  calculatorModal: boolean;
  imageModal: boolean;
  updateImageModal: boolean;
  isDropdownItem: boolean;
  images: any;
  examples: any;
  tools: any;
  dynamicLists: { unique_code: string; name: string }[];

  pk: string;
  title: string;
  subtitle: string;
  choices: any;
  question_panel: any;
  question: string;
  trigger: string;
  module: string;
  variable: any;
  variable_code: any;
  containerCard: any;
  showCalcTypes: any;
  titleSync: boolean;
  infoboxes: any;
  position: any;
  renderPreview: boolean;
  searchText: string;
  isLoading: boolean;
  moduleCode: string;
  is_choice_dynamic: boolean;
  dynamic_choice_unique_code?: string;
}

interface ChoicePanelFormProps {
  choiceId: string;
  calculatorId: number;
  moduleId: number;
  position: number;
  container: boolean;
  handleSelectedItem: () => void;
  handleLivePreview: (
    choices: any,
    question: any,
    examples: any,
    subtitle: any,
    trigger: any,
    tools: any,
    renderPreview: any
  ) => void;
  handleAddChoices: (choice: any) => void;
  handleAddVariable: (variable: any) => void;
  handleAddQuestion: (question: string) => void;
  handleAddSubtitle: (subtitle: string) => void;
  handleAddExample: (example: any) => void;
  handleAddTools: (tools: any) => void;
  handleAddTrigger: (trigger: any) => void;
  handleIsDynamicChoice: (isDynamic: boolean) => void;
  handleDynamicChoiceCode: (dynamicUniqueCode: string) => void;
  toggleModal: () => void;
  resetState: () => void;
  resetAnswerPages: () => void;
  infoboxState?: any;
  mediaState?: any;
  calculatorState?: any;
  teamCalculatorState?: any;
  choiceCoefficient?: boolean;
  triggerState?: any;
  formState?: any;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}

type Props = ChoicePanelFormDispatchProps & ChoicePanelFormProps;

class ChoicePanelForm extends Component<Props, ChoicePanelFormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      textModal: false,
      updateTextModal: false,
      choiceModal: false,
      updateChoiceModal: false,
      infoboxModal: false,
      updateInfoboxModal: false,
      toolInfoboxModal: false,
      updateToolInfoboxModal: false,
      isChangeAble: false,
      calculatorModal: false,
      imageModal: false,
      updateImageModal: false,
      isDropdownItem: false,
      images: [],
      examples: [],
      tools: [],
      dynamicLists: [],

      pk: '',
      title: '',
      subtitle: '',
      choices: [],
      question_panel: [],
      question: '',
      trigger: '',
      module: '',
      variable: '',
      variable_code: null,
      showCalcTypes: null,
      infoboxes: [],
      position: null,
      renderPreview: false,
      searchText: '',
      containerCard: '',
      titleSync: false,
      isLoading: false,
      moduleCode: '',
      is_choice_dynamic: false,
      dynamic_choice_unique_code: undefined,
    };
  }

  setSearchText = (text: string) => {
    this.setState({ searchText: text });
  };

  setChoices = async () => {
    this.setState({
      choices: [
        { sortable_id: 1, name: 'Answer 1', coefficient: 0, position: 0, isSelected: false },
        { sortable_id: 2, name: 'Answer 2', coefficient: 1, position: 1, isSelected: false },
        { sortable_id: 3, name: 'Answer 3', coefficient: 2, position: 2, isSelected: false },
      ],
    });
  };

  helpInfoData = getHelpInfoData('ChoicePanel');

  // checks url and updates state accordingly (edit or create)
  componentDidMount() {
    const { calculatorId, getTriggers, moduleId, getInfoBoxes } = this.props;
    // TODO: remove when redux data is depracated
    const moduleType = calculatorId ? MODULE_TYPES.CALCULATOR : MODULE_TYPES.ALGO;
    const id = calculatorId || moduleId;

    getTriggers(moduleType, null, false, id);
    getInfoBoxes(moduleType, null, id);
    if (moduleId)
      dataExtractorAPI
        .getDataExtractorGeneratedVariables(moduleId, ['list'])
        .then((variables) => this.setState({ dynamicLists: variables }));

    if (!this.props.choiceId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data: any) => data.title === 'Always On'
      );
      this.props.handleSelectedItem();
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
        position: this.props.position,
        renderPreview: true,
      });
    }

    // if update request encountered
    if (this.props.choiceId) {
      axios
        .get(CHOICE_PANEL_API_URL + this.props.choiceId + '/')
        .then((res) => this.updateState(res.data));
    } else {
      this.setState({
        choices: [
          { sortable_id: 1, name: 'Answer 1', position: 0, coefficient: 1 },
          { sortable_id: 2, name: 'Answer 2', coefficient: 1, position: 1 },
          { sortable_id: 3, name: 'Answer 3', coefficient: 1, position: 2 },
        ],
      });
      this.setChoices().then(() =>
        this.props.handleLivePreview(
          this.state.choices,
          this.state.question || 'What question should this card answer?',
          this.state.examples,
          this.state.subtitle || 'Subtitle',
          this.state.trigger ? this.state.trigger : '',
          this.state.tools,
          this.state.renderPreview
        )
      );
    }
    if (window.location.pathname.split('/')[1] === MODULE_TYPES.ALGO) {
      // this only need for algo to check if it's a onboarding module. (ALGO)
      // it's not a elegant way. Need to refactor whole component with functional component.
      axios.get(MODULE_API_URL + this.props.moduleId).then((res) => {
        this.setState({ moduleCode: res.data.code });
      });
    }
  }

  updateState = (data: any) => {
    axios.get(RETRIEVE_CHOICE_ITEM_API_URL + this.props.choiceId).then((res) => {
      let choicesObj: any = [];
      res.data.forEach((element: any) => {
        element.normalized_coefficient !== null && element.normalized_coefficient !== undefined
          ? choicesObj.push({
              id: element.id,
              name: element.name,
              coefficient: element.normalized_coefficient,
              position: element.position,
              sortable_id: element.id,
              isSelected: element.is_selected,
            })
          : choicesObj.push({
              id: element.id,
              name: element.name,
              position: element.position,
              sortable_id: element.id,
              isSelected: element.is_selected,
            });
      });

      choicesObj.sort((a: any, b: any) => (a.position > b.position ? 1 : -1));

      this.setState({
        choices: choicesObj,
      });
      this.props.handleAddChoices(choicesObj);
    });

    this.setState({
      pk: data.id,
      subtitle: data.subtitle,
      question: data.question,
      trigger: data.trigger ? data.trigger : '',
      module: data.module,
      infoboxes: data.infobox_detail,
      images: data.images,
      examples: data.examples,
      variable: data.variable_item?.name,
      variable_code: data.variable_item?.unique_code,
      tools: data.tools,
      position: data.position,
      isDropdownItem: data.is_dropdown_item,
      is_choice_dynamic: data.is_choice_dynamic,
      dynamic_choice_unique_code: this.state.dynamicLists.some(
        (dynamiclist) => dynamiclist.unique_code == data.dynamic_choice_unique_code
      )
        ? data.dynamic_choice_unique_code
        : undefined,
    });
    this.props.handleLivePreview(
      this.state.choices,
      data.question,
      data.examples,
      data.subtitle,
      data.trigger ? data.trigger : '',
      data.tools,
      this.state.renderPreview
    );
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'question') {
      this.props.handleAddQuestion(e.target.value);
    }
    if (e.target.name === 'subtitle') {
      this.props.handleAddSubtitle(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<ChoicePanelFormState, any>);
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isDropdownItem: !prevState.isDropdownItem,
    }));
  };

  setVariableName = (variable: string) => {
    this.setState({ variable });
    this.props.handleAddVariable?.(variable);
  };

  // add text item to example
  addText = () => {
    let text = {
      id: generate_ID(),
      section: 'E',
      text: {
        statement: 'Text Item',
      },
    };

    let examples = this.state.examples.concat(text);
    this.setState({ examples: examples });
    this.props.handleAddExample(examples);
  };

  // add infobox to example
  addInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'E',
      infobox: {},
    };

    let examples = this.state.examples.concat(infobox);
    this.setState({ examples: examples });
    this.props.handleAddExample(examples);
  };

  addToolInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'T',
      infobox: {},
    };

    let tools = this.state.tools.concat(infobox);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addImage = () => {
    let image = {
      id: generate_ID(),
      label: '',
      section: 'T',
      image: {},
    };

    let tools = this.state.tools.concat(image);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addLegacyCalculator = (calculatorObj: any) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      legacy_calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addCalculator = (calculatorObj: any) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  deleteExample = (example: any) => {
    let examples = this.state.examples.filter((el) => el.id !== example.id);
    this.setState({
      examples: examples,
    });
    this.props.handleAddExample(examples);
  };

  updateExample = (id: number, value: string, inputName: string) => {
    const updatedExamples = this.state.examples.map((example: any) => {
      let updatedItem = example;

      if (example.id === id) {
        if (inputName === 'label') {
          // text item doesn't have label
          if (example.text) {
            updatedItem = {
              ...example,
              text: {
                statement: value,
              },
            };
          } else {
            updatedItem = {
              ...example,
              label: value,
            };
          }
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox: any) => infobox.id === value
          );
          updatedItem = {
            ...example,
            infobox: infoboxObj || {},
            label: !example.label && infoboxObj ? infoboxObj.title : example.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image: any) => image.id === value);
          updatedItem = {
            ...example,
            image: imageObj || {},
            label: !example.label && imageObj ? imageObj.title : example.label,
          };
          delete updatedItem.infobox;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ examples: updatedExamples });
    this.props.handleAddExample(updatedExamples);
  };

  updateTool = (id: number, value: string, inputName: string) => {
    const updatedTools = this.state.tools.map((tool: any) => {
      let updatedItem = tool;

      if (tool.id === id) {
        if (inputName === 'label') {
          updatedItem = {
            ...tool,
            label: value,
          };
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...tool,
            infobox: infoboxObj || {},
            label: !tool.label && infoboxObj ? infoboxObj.title : tool.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image: any) => image.id === value);
          updatedItem = {
            ...tool,
            image: imageObj || {},
            label: !tool.label && imageObj ? imageObj.title : tool.label,
          };
          delete updatedItem.infobox;
        } else if (inputName === 'legacy_calc') {
          let calcObj = this.props.calculatorState.calculators.find(
            (image: any) => image.id === value
          );
          updatedItem = {
            ...tool,
            legacy_calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.title : tool.label,
          };
          delete updatedItem.calculator;
        } else if (inputName === 'calc') {
          const calcs = [
            ...(this.props.teamCalculatorState?.teamCalculators || []),
            ...(this.props.teamCalculatorState?.universalCalculators || []),
          ];
          let calcObj = calcs.find((calc) => calc.id === value);
          updatedItem = {
            ...tool,
            calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.name : tool.label,
          };
          delete updatedItem.legacy_calculator;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ tools: updatedTools });
    this.props.handleAddTools(updatedTools);
  };

  updateExamples = (newList: any) => {
    this.setState({
      examples: newList,
    });
    this.props.handleAddExample(newList);
  };

  updateTools = (newList: any) => {
    this.setState({
      tools: newList,
    });
    this.props.handleAddTools(newList);
  };

  deleteTool = (tool: any) => {
    let tools = this.state.tools.filter((el) => el.id !== tool.id);
    this.setState({
      tools: tools,
    });
    this.props.handleAddTools(tools);
  };

  // add choice in choices array
  addChoice = () => {
    // create choice obj
    let obj = {
      sortable_id: generate_ID(),
      name: 'Answer ' + (this.state.choices.length + 1),
      position: this.state.choices.length,
      isSelected: false,
      coefficient: this.state.choices.length,
    };

    let concatedArray = this.state.choices.concat(obj);
    this.setState({ choices: concatedArray });
    this.props.handleAddChoices(concatedArray);
  };

  updateChoices = (newList: any) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }
    this.setState({
      choices: newList,
    });
    this.props.handleAddChoices(newList);
  };

  updateChoice = (id: number, value: string, inputName: string) => {
    const updatedChoices = this.state.choices.map((choice: any) => {
      let updatedItem = choice;

      if (choice.sortable_id === id) {
        if (inputName === 'name') {
          updatedItem = {
            ...choice,
            name: value,
          };
        } else {
          updatedItem = {
            ...choice,
            coefficient: value,
          };
        }
        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ choices: updatedChoices });
    this.props.handleAddChoices(updatedChoices);
  };

  deleteQuestion = (question: any) => {
    let qp = this.state.question_panel.filter((el: any) => el.id !== question.id);
    this.setState({
      question_panel: qp,
    });
  };

  /* TODO: decide string or number for choiceId type */
  deleteChoice = (choiceId: string | number) => {
    let prevChoices = this.state.choices.filter((el: any) => el.sortable_id !== choiceId);
    for (let i = 0; i < prevChoices.length; i++) {
      prevChoices[i].position = i;
    }

    this.setState({ choices: prevChoices });
  };

  getSelectedTrigger = (data: any) => {
    this.setState({
      trigger: data,
    });
    this.props.handleAddTrigger(data);
  };

  handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>, item_id: string) => {
    const newList = this.state.choices.map((item: any) => {
      let checkedVal = e.target.checked && item.sortable_id === item_id ? true : false;

      const updatedItem = {
        ...item,
        isSelected: checkedVal,
      };
      return updatedItem;
    });
    this.setState({ choices: newList });
  };

  getPayload = () => {
    let payload = {
      subtitle: this.state.subtitle,
      variable: this.state.variable,
      container: this.props.container,
      choices: this.state.choices,
      question: this.state.question,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      calculator: this.props.calculatorId,
      question_items: this.state.examples.concat(this.state.tools),
      position: this.state.position,
      is_dropdown_item: this.state.isDropdownItem,
      dynamic_choice_unique_code: this.state.dynamic_choice_unique_code,
      is_choice_dynamic: this.state.is_choice_dynamic,
    };
    return payload;
  };

  isExampleOrToolEmpty = () => {
    for (let i = 0; i < this.state.examples.length; i++) {
      const example = this.state.examples[i];

      if (
        (example.text && !example.text.statement) ||
        (example.infobox && (!example.label || Object.keys(example.infobox).length === 0))
      ) {
        return true;
      }
    }

    for (let i = 0; i < this.state.tools.length; i++) {
      const tool = this.state.tools[i];

      if (
        !tool.label ||
        (tool.infobox && Object.keys(tool.infobox).length === 0) ||
        (tool.image && Object.keys(tool.image).length === 0) ||
        (tool.legacy_calculator && Object.keys(tool.legacy_calculator).length === 0) ||
        (tool.calculator && Object.keys(tool.calculator).length === 0)
      ) {
        return true;
      }
    }

    return false;
  };

  isChoicesEmpty = () => {
    for (let i = 0; i < this.state.choices.length; i++) {
      if (!this.state.choices[i].name) {
        return true;
      }
    }

    return false;
  };

  createChoicePanel = () => {
    if (this.state.choices.length <= 1) {
      toast.error(CustomToast, { data: 'Choices must be more than 1' });
      return;
    }

    if (this.isChoicesEmpty()) {
      toast.error(CustomToast, { data: 'Label is missing in answers' });
      return;
    }

    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }
    if (this.props.choiceCoefficient) {
      let isEmptyValueFound = false;
      for (let i = 0; i < this.state.choices.length; i++) {
        if (
          !this.state.choices[i].name ||
          this.state.choices[i].coefficient === null ||
          this.state.choices[i].coefficient === undefined ||
          this.state.choices[i].coefficient === ''
        ) {
          isEmptyValueFound = true;
          break;
        }
      }

      if (isEmptyValueFound) {
        toast.error(CustomToast, { data: 'Label or coefficient is missing in answers' });
        return;
      }
    }

    this.props.toggleModal();
    axios.post(CHOICE_PANEL_API_URL, this.getPayload()).then(async () => {
      // reset unique codes mapping to get latest result

      if (this.props.calculatorId) {
        await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
      } else {
        await this.props.getUniqueCode(this.props.moduleId);
      }
      this.props.resetState();
    });
  };

  editChoicePanel = () => {
    if (this.state.choices.length <= 1) {
      toast.error(CustomToast, { data: 'Choices must be more than 1' });
      return;
    }

    if (this.isChoicesEmpty()) {
      toast.error(CustomToast, { data: 'Label is missing in answers' });
      return;
    }

    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }
    if (this.props.choiceCoefficient) {
      let isEmptyValueFound = false;
      for (let i = 0; i < this.state.choices.length; i++) {
        if (
          !this.state.choices[i].name ||
          this.state.choices[i].coefficient === null ||
          this.state.choices[i].coefficient === undefined ||
          this.state.choices[i].coefficient === ''
        ) {
          isEmptyValueFound = true;
          break;
        }
      }

      if (isEmptyValueFound) {
        toast.error(CustomToast, { data: 'Label or coefficient is missing in answers' });
        return;
      }
    }

    this.props.toggleModal();
    axios.put(CHOICE_PANEL_API_URL + this.state.pk + '/', this.getPayload()).then(async () => {
      // reset unique codes mapping to get latest result

      if (this.props.calculatorId) {
        await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
        this.props.getInfoBoxes(MODULE_TYPES.CALCULATOR, null, this.props.calculatorId);
        this.props.getNoteGenerator(MODULE_TYPES.CALCULATOR, null, this.props.calculatorId);
      } else {
        await this.props.getUniqueCode(this.props.moduleId);
        this.props.resetAnswerPages();
        this.props.getInfoBoxes(MODULE_TYPES.ALGO, null, this.props.moduleId);
        this.props.getNoteGenerator(MODULE_TYPES.ALGO, null, this.props.moduleId);
      }
      this.props.resetState();
    });
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { question, variable, choices } = this.state;
    const { choiceId, modal, closeUiElementPane } = this.props;
    // Check if any object has an empty "coefficient" or "label" value
    const hasEmptyCoefficientOrName =
      !this.state.is_choice_dynamic &&
      choices.some((obj) => obj.coefficient === '' || obj.name === '');

    const isDynamicSelected =
      !this.state.is_choice_dynamic || !!this.state.dynamic_choice_unique_code;

    if (question === '') {
      toast.error(CustomToast, { data: 'Please fill out the Multi choice question field.' });
    } else if (hasEmptyCoefficientOrName) {
      toast.error(CustomToast, { data: 'Please fill out all the Answers fields.' });
    } else if (!isDynamicSelected) {
      toast.error(CustomToast, { data: 'Please select dynamic choice variable' });
    } else {
      this.setState({ isLoading: true });
      if (!variable) {
        const variable = await getVariableName(question);
        this.setState({ variable });
      }

      if (choiceId) {
        this.editChoicePanel();
      } else {
        this.createChoicePanel();
      }
    }
    modal && closeUiElementPane?.();
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    // Check if any object has an empty "coefficient" or "label" value
    const hasEmptyCoefficientOrName =
      !this.state.is_choice_dynamic &&
      this.state.choices.some((obj) => obj.coefficient === '' || obj.name === '');

    const isDynamicSelected =
      !this.state.is_choice_dynamic || !!this.state.dynamic_choice_unique_code;

    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    const helpInfoData = this.helpInfoData;
    return (
      <div className='row mt-14'>
        <div className='side-panel-form'>
          <Header title={CARD_MULTIPLE_CHOICE} toggleModal={this.props.toggleModal} />
          <Form
            className='flex h-full flex-col px-3 pb-0'
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            onSubmit={this.handleSubmit}
          >
            {/* only for onboarding module */}
            {isOnboardingModule(this.state.moduleCode) && (
              <StyledFormControlLabel
                style={{ margin: 0, alignItems: 'center', display: 'flex' }}
                control={
                  <Checkbox
                    style={{ color: '#08A88E' }}
                    checked={this.state.isDropdownItem}
                    onChange={() => this.toggleModal()}
                  />
                }
                label='Dropdown Item'
              />
            )}
            <Box data-code={this.state.variable_code}>
              <AppearingSituation
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
                calculatorId={this.props.calculatorId}
                type={this.props.calculatorId && 'calculator'}
              />
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <InputField
                name='question'
                required={true}
                value={this.state.question}
                onChange={this.onChange}
                label={helpInfoData?.question?.label}
                detail={helpInfoData?.question?.detail}
                placeholder={helpInfoData?.question?.placeholder}
                maxLength={helpInfoData?.question?.character_limit}
              />
            </Box>

            <VariableAutoGenerator
              tagTitle='Choice'
              variable={this.state.variable}
              question={this.state.question}
              setVariableName={(variable) => this.setVariableName(variable)}
            />

            <Box style={{ marginTop: '25px' }}>
              <InputField
                name='subtitle'
                value={this.state.subtitle}
                onChange={this.onChange}
                label={helpInfoData?.subtitle?.label}
                detail={helpInfoData?.subtitle?.detail}
                placeholder={helpInfoData?.subtitle?.placeholder}
                maxLength={helpInfoData?.subtitle?.character_limit}
              />
            </Box>

            <Box className='ml-1' style={{ marginTop: 25 }}>
              <StyledFormGroupMB30>
                <FieldLabel
                  required
                  label={helpInfoData?.choices?.label}
                  // detail={helpInfoData?.choices?.detail}
                />
                {this.props.moduleId && (
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>
                    <div className='mb-2 flex cursor-pointer overflow-hidden text-center'>
                      <div
                        onClick={() => {
                          this.setState({ is_choice_dynamic: false });
                          this.props.handleIsDynamicChoice(false);
                        }}
                        className={clsx(
                          'flex-1 rounded-bl rounded-tl border py-2',
                          !this.state.is_choice_dynamic
                            ? 'border-gray-300 bg-white text-button-1 text-primary-600'
                            : 'border-r-0 border-gray-200 bg-gray-100 text-button-2 text-gray-700'
                        )}
                      >
                        Manual
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ is_choice_dynamic: true });
                          this.props.handleIsDynamicChoice(true);
                        }}
                        className={clsx(
                          'flex-1 rounded-br rounded-tr border py-2',
                          this.state.is_choice_dynamic
                            ? '!border-gray-300 bg-white text-button-1 text-primary-600'
                            : 'border-l-0 border-gray-200 bg-gray-100 text-button-2 text-gray-700'
                        )}
                      >
                        Auto
                      </div>
                    </div>
                  </StaffOnlyWrapper>
                )}
                {this.state.is_choice_dynamic ? (
                  <DropdownSingle
                    value={this.state.dynamic_choice_unique_code}
                    options={this.state.dynamicLists.map((variable) => ({
                      id: variable.unique_code,
                      title: variable.name,
                    }))}
                    onChange={(value) => this.setState({ dynamic_choice_unique_code: value + '' })}
                    placeholder='Select'
                    emptyDescription='Generate an array-type variable using the Data Extractor to display selectable lists.'
                  />
                ) : (
                  <AnswerSection
                    answers={this.state.choices}
                    addChoice={this.addChoice}
                    updateChoices={this.updateChoices}
                    updateChoice={this.updateChoice}
                    deleteChoice={this.deleteChoice}
                    handleChangeCheckBox={this.handleChangeCheckBox}
                  />
                )}
              </StyledFormGroupMB30>
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <FormGroup>
                <FieldLabel
                  label={helpInfoData?.examples?.label}
                  detail={helpInfoData?.examples?.detail}
                />
                <Examples
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <div className='example-n-tools-textBox m-4 mt-2'>
                    <ExampleNToolsList
                      moduleId={this.props.moduleId}
                      calculatorId={this.props.calculatorId}
                      type={this.props.calculatorId && 'calculator'}
                      list={this.state.examples}
                      handleDeleteClick={this.deleteExample}
                      handleListChange={this.updateExample}
                      updateListItems={this.updateExamples}
                    />
                  </div>
                  <div className='row d-flex align-items-center ml-2  mt-2'>
                    <AddLabel className='ml-4 mr-2'>Add</AddLabel>
                    <AddButton
                      style={{
                        display: 'inline-flex',
                      }}
                      disableRipple
                      disableFocusRipple
                      startIcon={<AddIcon />}
                      className=' waves-effect waves-light  mr-2 inline-flex'
                      onClick={this.addText}
                    >
                      Text
                    </AddButton>
                    <AddButton
                      style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                      startIcon={<AddIcon />}
                      className=' waves-effect waves-light mr-2'
                      onClick={this.addInfoBox}
                    >
                      InfoBox / Media
                    </AddButton>
                  </div>
                </Examples>
              </FormGroup>
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <FormGroup>
                <FieldLabel
                  label={helpInfoData?.tools?.label}
                  detail={helpInfoData?.tools?.detail}
                />
                <Examples
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <div className='example-n-tools-textBox m-4 mt-2'>
                    <ExampleNToolsList
                      moduleId={this.props.moduleId}
                      calculatorId={this.props.calculatorId}
                      type={this.props.calculatorId && 'calculator'}
                      list={this.state.tools}
                      handleDeleteClick={this.deleteTool}
                      handleListChange={this.updateTool}
                      updateListItems={this.updateTools}
                    />
                  </div>
                  <div className='row d-flex align-items-center ml-2  mt-2'>
                    <AddLabel className='ml-4 mr-2'>Add</AddLabel>
                    <AddButton
                      style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                      startIcon={<AddIcon />}
                      className='waves-effect waves-light mr-2'
                      onClick={this.addToolInfoBox}
                    >
                      InfoBox / Media
                    </AddButton>
                    <AddButton
                      style={{
                        maxWidth: '152px',
                        width: '152px',
                        display: 'inline-flex',
                      }}
                      startIcon={<AddIcon />}
                      className='waves-effect waves-light mr-2'
                      onClick={(e) => this.setState({ showCalcTypes: e.target })}
                    >
                      Calculator
                    </AddButton>
                    <Popover
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      id='simple-menu'
                      anchorEl={this.state.showCalcTypes}
                      // keepMounted
                      open={Boolean(this.state.showCalcTypes)}
                      onClose={(e) => this.setState({ showCalcTypes: null })}
                    >
                      <SectionDropdown
                        sectionDropdown
                        section1='Team Calculators'
                        section2='Universal Calculators'
                        sectionOneItems={this.props.teamCalculatorState.teamCalculators}
                        sectionTwoItems={this.props.calculatorState.calculators}
                        universalCalculators={this.props.teamCalculatorState?.universalCalculators}
                        sectionOneClick={(obj: any) => {
                          this.addCalculator(obj);
                          this.setState({ showCalcTypes: null });
                        }}
                        sectionTwoClick={(obj: any) => {
                          this.addLegacyCalculator(obj);
                          this.setState({ showCalcTypes: null });
                        }}
                      />
                    </Popover>
                  </div>
                </Examples>
              </FormGroup>
            </Box>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.props.toggleModal} className='ml-auto mr-4'>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton
                  disabled={!this.state.question || hasEmptyCoefficientOrName || !isDynamicSelected} // need to refine
                  type='submit'
                >
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>

        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

const mapDispatchToProps = (dispatch, ChoicePanelFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getNoteGenerator: (moduleType, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(moduleType, mirrorId, moduleId)),
  getCalculators: () => dispatch(getCalculators()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoicePanelForm);
