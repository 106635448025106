import { applyMiddleware, compose, legacy_createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

const config = { key: 'root', storage };
const reducer = persistReducer(config, rootReducer);
const preloadedState = {};
const enhancer = compose(
  applyMiddleware(reduxThunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

export const store = legacy_createStore(reducer, preloadedState, enhancer);
