export default function Highlight(props) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 36345'>
        <path
          id='Vector'
          d='M14.7757 3.4026L12.0725 0.72244C11.93 0.580819 11.7368 0.50086 11.5351 0.500007C11.3334 0.499154 11.1395 0.577478 10.9958 0.717888L4.36746 7.19108C4.26414 7.29253 4.19178 7.42085 4.1587 7.56128L3.37565 10.9272L2 12.5H4.16255L5.02407 11.6429L7.77953 11.1533C7.91717 11.1214 8.04334 11.0524 8.14428 10.9545L14.7719 4.47982C14.8438 4.40955 14.901 4.32584 14.9401 4.23353C14.9793 4.14123 14.9996 4.04214 15 3.942C15.0004 3.84185 14.9807 3.74263 14.9422 3.65005C14.9037 3.55747 14.8471 3.47337 14.7757 3.4026ZM7.61129 9.34476L5.98938 7.73652L11.5281 2.32689L13.1492 3.93514L7.61129 9.34476Z'
          fill='#2E3438'
        />
        <path
          id='Vector_2'
          d='M0 13.7C0 13.5895 0.0895431 13.5 0.2 13.5H15.8C15.9105 13.5 16 13.5895 16 13.7V15.3C16 15.4105 15.9105 15.5 15.8 15.5H0.2C0.0895429 15.5 0 15.4105 0 15.3V13.7Z'
          fill='#FF8E3D'
        />
      </g>
    </svg>
  );
}
