import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './inputComponents.styles.css';

function TypedInput({
  type,
  unit,
  code,

  previewContent,
  setPreviewContent,

  entityKey,
}) {
  const [text, setText] = useState('');
  const textChangeHandler = (e) => {
    setText(type === 'number' ? e.target.value.replace(/\D/, '') : e.target.value);
  };

  useEffect(() => {
    if (code.includes('/') && previewContent?.entityMap[entityKey]) {
      setText(previewContent.entityMap[entityKey].data.mention.name);
    }
  }, [previewContent, entityKey, code]);

  useEffect(() => {
    if (!code.includes('/')) {
      const content = previewContent;
      if (content?.entityMap[entityKey]) {
        content.entityMap[entityKey].data.mention.name = text;
        setPreviewContent(content);
      }
    }
  }, [text, entityKey, code]);

  return (
    <div
      className='typed-input'
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingRight: 20,
        paddingLeft: 0,
      }}
    >
      {' '}
      <input
        className='input-component'
        value={text}
        onChange={textChangeHandler}
        disabled={code.includes('/')}
      />
      <Typography style={{ opacity: 0.5 }}>{unit ? unit : 'Unit'}</Typography>
    </div>
  );
}

export default TypedInput;
