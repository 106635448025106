import { GET_UNIQUE_CODE } from 'actions/actionsTypes';

const uniqueCodesReducer = (state = { uniqueCodes: {} }, action) => {
  switch (action.type) {
    case GET_UNIQUE_CODE:
      return {
        ...state,
        uniqueCodes: action.payload,
      };

    default:
      return state;
  }
};

export default uniqueCodesReducer;
