import Button from 'components/utils/Button';
import { useEffect, useState } from 'react';
import AddAuthorModal from './AddAuthorModal';
import AuthorItem from './AuthorItem';
import { Author } from '../../api/author';

interface AddAuthorControllerProps {
  onChange: (authors: Author[]) => void;
  defaultAuthors?: IAuthor[];
}

export interface IAuthor extends Author {
  title: string;
}

const AddAuthorController = ({ onChange, defaultAuthors = [] }: AddAuthorControllerProps) => {
  const [open, setOpen] = useState(false);
  const [authors, setAuthors] = useState<IAuthor[]>(defaultAuthors);
  useEffect(() => {
    onChange(authors);
  }, [authors]);
  return (
    <div>
      <div className='flex'>
        <div>
          <Button type='button' onClick={() => setOpen(true)}>
            Add Author
          </Button>
        </div>
        <AddAuthorModal
          open={open}
          selectedAuthorIds={authors.map((author) => author.id)}
          onClose={() => setOpen(false)}
          onAdd={(author) => setAuthors((prev) => [...prev, author])}
        />
      </div>
      <div className='mt-2 space-y-2'>
        {authors.map((author, idx) => (
          <AuthorItem
            key={idx}
            author={author}
            onDelete={(id) => setAuthors((prev) => prev.filter((author) => author.id !== id))}
          />
        ))}
      </div>
    </div>
  );
};

export default AddAuthorController;
