import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCard = withStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '185px',
    float: 'left',
    marginBottom: '15px',
    borderRadius: 10,
    border: '1px solid #DCDCDC',
    boxShadow: 'none',
  },
}))(Card);

export const StyledCardV2 = withStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '105px',
    float: 'left',
    background: '#FFFFFF',
    cursor: 'default',

    position: 'relative',
    boxShadow: 'none',
    border: '1px solid #E5EDF0',
    borderRadius: '8px',
    marginBottom: 12,
  },
}))(Card);

export const StyledCardMedia = withStyles((theme: Theme) => ({
  root: {
    marginLeft: '27px',
    marginRight: '10px',
    width: '120px',
    height: '120px',
    maxWidth: '120px',
    borderRadius: '50%',
  },
}))(CardMedia);

export const StyledCardContent = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}))(CardContent);

export const StyledCardActionArea = withStyles((theme) => ({
  root: {
    minHeight: 'inherit',
    cursor: 'default',
    alignItems: 'center',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    transition: 'none',

    '&:hover': {
      backgroundColor: '#F5F8F9',
    },
  },
}))(CardActionArea);

export const AuthorName = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '20px',
    marginBottom: 10,
    color: '#3F3F59',
  },
}))(Typography);

export const AuthorDescription = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#525D6A',
  },
}))(Typography);

export const ActionBox = withStyles((theme) => ({
  root: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))(Box);

export const StyledLink = styled(Link)`
  color: #08a88e !important;
  font-size: 16px;
  text-decoration: none;
`;
