import { Box } from '@material-ui/core';
import axios from 'axios';
import { MainButton } from 'components/teams/team-detail/styles';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { useState } from 'react';
import { AUTHOR_PROFILE_API_URL, TEAM_API_URL } from '../../constants';
import AuthorCard from './partials/author-card';
import { StyledBox, StyledContainer, Title } from './styles';
import { useTeam } from '../../hooks/useTeam';
import { FeaturedAuthorModal } from '../members/featured-author/FeaturedAuthorModal';
import { Author, authorAPI } from '../../api/author';
import { useQuery } from '@tanstack/react-query';

const FeaturedAuthors = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [editingProfile, setEditingProfile] = useState<Author | undefined>(undefined);
  const [authorId, setAuthorId] = useState('');
  const [authorModal, setAuthorModal] = useState(false);

  const { team } = useTeam();
  const { data: authors, refetch } = useQuery(['teamAuthors', team.id], authorAPI.getAuthors, {
    initialData: [],
  });

  const toggleAuthorModal = (authorId?: any) => {
    setAuthorModal((prev) => !prev);
    setAuthorId(authorId);
  };

  const deleteAuthor = (authorId) => {
    axios.delete(AUTHOR_PROFILE_API_URL + authorId + '/').then(() => {
      refetch();
    });
  };

  const onModalOpen = (authorId: number | undefined = undefined) => {
    setEditingProfile(authors.find((author) => author.id === authorId));
    setOpen(true);
  };

  return (
    <>
      <StyledBox>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Title gutterBottom variant='h4'>
            Featured Authors
          </Title>
        </Box>

        <MainButton style={{ width: '290px', marginLeft: 'auto' }} onClick={() => onModalOpen()}>
          Create Featured Author
        </MainButton>
      </StyledBox>

      <StyledContainer>
        {authors.map((author) => (
          <AuthorCard author={author} toggleAuthorModal={toggleAuthorModal} onEdit={onModalOpen} />
        ))}
      </StyledContainer>

      <ConfirmModal
        preset='delete'
        open={authorModal}
        content='Are you sure you want to delete this Author?'
        toggleModal={toggleAuthorModal}
        performAction={() => {
          toggleAuthorModal();
          deleteAuthor(authorId);
        }}
      />
      <FeaturedAuthorModal open={open} onClose={() => setOpen(false)} author={editingProfile} />
    </>
  );
};

export default FeaturedAuthors;
