import * as React from 'react';

function AddIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V7C9 8.10457 8.10457 9 7 9H6C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11H7C8.10457 11 9 11.8954 9 13V14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14V13C11 11.8954 11.8954 11 13 11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9H13C11.8954 9 11 8.10457 11 7V6Z'
        fill='white'
      />
      <path
        d='M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18Z'
        fill='white'
      />
    </svg>
  );
}

export default AddIcon;
