const initSentry = async (sentry_dsn: string) => {
  const Sentry = await import('@sentry/react');
  Sentry.init({
    dsn: sentry_dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV === 'production',
  });
};

const sentry_dsn = process.env.REACT_APP_SENTRY_DSN;
if (sentry_dsn) {
  // TODO: When a sentry init exception occurs, a log loading process is needed.
  initSentry(sentry_dsn).catch((e) => console.log(e));
}
