import ErrorImage from 'assets/images/404-error.png';
import History from 'components/utils/History';
import {
  ErrorPage,
  ErrorText,
  Heading,
  ImageContainer,
  MainButton,
  Small,
} from 'components/utils/styled-components/NotFoundPageStyle';
import { Component } from 'react';

class NotFoundPage extends Component {
  render() {
    return (
      <ErrorPage data-testid='error-page__test'>
        <ErrorText>
          <Heading>
            Sorry, <br /> We cannot find the page you are looking for
          </Heading>
          <Small>Please try searching again</Small>
          <MainButton onClick={() => History.push('/')}>Back to the Main Page</MainButton>
        </ErrorText>
        <ImageContainer>
          <img src={ErrorImage} alt='404-not-found' width='424px' height='288px' />
        </ImageContainer>
      </ErrorPage>
    );
  }
}

export default NotFoundPage;
