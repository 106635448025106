// TODO: change of this method is for preventing from existing module's
// position random reordering which is due to group position logic change (#AVONG-1631)
// once all existing old module's group card position values are applied,
// we can revert back to the original function which is:
// export const compareCardPosition = (a, b) => (a.position > b.position ? 1 : -1);
export const compareCardPosition = (a, b) => {
  if (checkIfGroupCardDoesNotHavePositionValue(a)) {
    a.position = getMinPosition(a.ui_elements);
  }

  if (checkIfGroupCardDoesNotHavePositionValue(b)) {
    b.position = getMinPosition(b.ui_elements);
  }

  return a.position > b.position ? 1 : -1;
};

const getMinPosition = (uiElements) => {
  let minPosition = Number.POSITIVE_INFINITY;

  // Iterate through the object array
  for (const obj of uiElements) {
    if (obj.position < minPosition) {
      minPosition = obj.position;
    }
  }

  return minPosition;
};

const checkIfGroupCardDoesNotHavePositionValue = (card) => {
  return card.ui_elements && !card.position;
};
