import React, { Component } from 'react';

class GifTooltip extends Component {
  render() {
    return (
      <div
        className='row'
        style={{
          color: 'white',
          backgroundColor: 'grey',
          borderRadius: 10,
          border: '3px solid darkgrey',
          padding: 20,
          margin: 5,
          width: 230,
        }}
      >
        {this.props.image ? (
          <img src={this.props.image} height='200' width='180' alt={'img'} />
        ) : null}
        <p
          style={{
            fontSize: 14,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          {this.props.helpText}
        </p>
      </div>
    );
  }
}
export default GifTooltip;
