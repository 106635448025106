export default function EditIcon(props) {
  return (
    <svg
      {...props}
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9572 0.176224C10.7229 -0.0580904 10.343 -0.0580904 10.1087 0.176224L1.34056 8.94432C1.27115 9.01373 1.21983 9.0991 1.1911 9.19296L0.0366717 12.9641C-0.15101 13.5772 0.422706 14.1509 1.0358 13.9632L4.80694 12.8088C4.9008 12.7801 4.98617 12.7287 5.05558 12.6593L13.8237 3.89125C14.058 3.65693 14.058 3.27703 13.8237 3.04272L10.9572 0.176224ZM8.98285 2.99908L10.5329 1.44902L12.5509 3.46698L11.0008 5.01705L8.98285 2.99908ZM8.1229 3.85903L2.29479 9.68715L1.40451 12.5954L4.31275 11.7051L10.1409 5.877L8.1229 3.85903Z'
        fill='#6C7C83'
      />
    </svg>
  );
}
