import OutputPanel from 'components/calculator/board/OutputPanel';
import { ModuleLayout } from 'components/layout/ModuleLayout';
import { useParams } from 'react-router';

export const Output = () => {
  const { moduleId, moduleType } = useParams<{ moduleId: string; moduleType: string }>();
  return (
    <ModuleLayout className='bg-gray-100'>
      <OutputPanel calculatorId={moduleId} type={moduleType} />
    </ModuleLayout>
  );
};
