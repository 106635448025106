function ConditionalText(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#E5EDF0' />
      <path
        d='M25.0866 38V28.1818H27.8097V38H25.0866ZM26.4545 26.9162C26.0497 26.9162 25.7024 26.782 25.4126 26.5135C25.1271 26.2408 24.9844 25.9148 24.9844 25.5355C24.9844 25.1605 25.1271 24.8388 25.4126 24.5703C25.7024 24.2976 26.0497 24.1612 26.4545 24.1612C26.8594 24.1612 27.2045 24.2976 27.4901 24.5703C27.7798 24.8388 27.9247 25.1605 27.9247 25.5355C27.9247 25.9148 27.7798 26.2408 27.4901 26.5135C27.2045 26.782 26.8594 26.9162 26.4545 26.9162ZM35.0708 28.1818V30.2273H29.0111V28.1818H35.0708ZM30.3982 38V27.4723C30.3982 26.7607 30.5367 26.1705 30.8137 25.7017C31.0949 25.233 31.4784 24.8814 31.9642 24.647C32.45 24.4126 33.0019 24.2955 33.6198 24.2955C34.0374 24.2955 34.4188 24.3274 34.764 24.3913C35.1134 24.4553 35.3733 24.5128 35.5438 24.5639L35.058 26.6094C34.9515 26.5753 34.8194 26.5433 34.6617 26.5135C34.5083 26.4837 34.3506 26.4688 34.1887 26.4688C33.7881 26.4688 33.509 26.5625 33.3513 26.75C33.1936 26.9332 33.1148 27.1911 33.1148 27.5234V38H30.3982Z'
        fill='#1E1F20'
      />
      <g clip-path='url(#clip0_4603_979)'>
        <path
          d='M22.5377 19.3333H21.5055C20.2696 19.3333 19.3779 19.6633 18.8302 20.3233C18.2824 20.9974 18.0086 22.2403 18.0086 24.052V25.2948C18.0086 26.9099 17.7979 28.0334 17.3766 28.6653C16.9553 29.2973 16.2742 29.6133 15.3333 29.6133V31.7199C16.2742 31.7199 16.9553 32.0359 17.3766 32.6678C17.7979 33.3138 18.0086 34.4373 18.0086 36.0383V37.3023C18.0086 39.0999 18.2824 40.3357 18.8302 41.0098C19.3779 41.6699 20.2696 41.9999 21.5055 41.9999H22.5377V40.3147H22.1375C21.337 40.3147 20.7822 40.1391 20.4733 39.788C20.1643 39.451 20.0098 38.6294 20.0098 37.3233V36.5018C20.0098 34.7182 19.7992 33.4051 19.3779 32.5625C18.9706 31.7058 18.2684 31.0809 17.2713 30.6876C18.2684 30.2663 18.9706 29.6344 19.3779 28.7917C19.7992 27.9491 20.0098 26.636 20.0098 24.8525V24.0098C20.0098 22.7178 20.1643 21.8962 20.4733 21.5451C20.7822 21.1941 21.337 21.0185 22.1375 21.0185H22.5377V19.3333Z'
          fill='#1E1F20'
          stroke='#1E1F20'
          stroke-linejoin='round'
        />
        <path
          d='M36.6666 19.3333H37.6989C38.9347 19.3333 39.8265 19.6633 40.3742 20.3233C40.9219 20.9974 41.1958 22.2403 41.1958 24.052V25.2948C41.1958 26.9099 41.4064 28.0334 41.8277 28.6653C42.249 29.2973 42.9302 29.6133 43.8711 29.6133V31.7199C42.9302 31.7199 42.249 32.0359 41.8277 32.6678C41.4064 33.3138 41.1958 34.4373 41.1958 36.0383V37.3023C41.1958 39.0999 40.9219 40.3357 40.3742 41.0098C39.8265 41.6699 38.9347 41.9999 37.6989 41.9999H36.6666V40.3147H37.0669C37.8674 40.3147 38.4221 40.1391 38.7311 39.788C39.04 39.451 39.1945 38.6294 39.1945 37.3233V36.5018C39.1945 34.7182 39.4052 33.4051 39.8265 32.5625C40.2338 31.7058 40.9359 31.0809 41.9331 30.6876C40.9359 30.2663 40.2338 29.6344 39.8265 28.7917C39.4052 27.9491 39.1945 26.636 39.1945 24.8525V24.0098C39.1945 22.7178 39.04 21.8962 38.7311 21.5451C38.4221 21.1941 37.8674 21.0185 37.0669 21.0185H36.6666V19.3333Z'
          fill='#1E1F20'
          stroke='#1E1F20'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4603_979'>
          <rect width='32' height='32' fill='white' transform='translate(14 14)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ConditionalText;
