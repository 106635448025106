import { Backdrop, Box, CircularProgress, Fade, Modal, Step } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { HighlightOff, KeyboardBackspace } from '@material-ui/icons';
import axios from 'axios';
import { CustomToast } from 'components/utils/toast-message';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import getTeamCalculators from 'actions/calculators/getTeamCalculators';
import knowledgeBaseListAction, { getKnowledgeBase } from 'actions/knowledge-base/getKnowledgeBase';
import getModule from 'actions/modules/moduleAction';
import getModules from 'actions/modules/modulesAction';
import SuccessSVGComponent from 'assets/icons/SuccessSVG';
import { MODULE_TYPES } from 'constants';
import SyncWarnings from '../SyncWarnings';
import FirebaseChannelForm from '../channel-form';

import 'components/utils/modals/modal.css';
import {
  CALC_SYNC_FOR_RELEASE_API,
  FIREBASE_RELEASE_SYNC_API_URL,
  KNOWLEDGE_BASE_SYNC_API_URL,
} from 'constants';
import { withAuthentication } from '../../../../hooks/useAuthentication';
import { withTeam } from '../../../../hooks/useTeam';
import {
  BackIcon,
  CloseIcon,
  QontoStepIcon,
  StyledPaper,
  StyledStepConnector,
  StyledStepLabel,
  StyledStepper,
  SuccessBox,
  SyncButton,
  SyncCaption,
  SyncSuccessText,
  theme,
} from './styles';

class SyncWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.isCalc = this.props.module.type === MODULE_TYPES.CALCULATOR;
  }

  getInitialState = () => ({
    isLoading: false,
    module: this.props.module.id,
    noErrors: false,
    startSync: false,
    activeStep: 1,
    channelSelect: [],
    errorOccurred: false,
    isUniversal: false,
    steps:
      this.props.authentication?.user?.user_type === 'trial'
        ? ['Warnings']
        : ['Warnings', 'Select Channels'],
  });

  closeModal = async () => {
    this.props.toggleModal();

    setTimeout(() => {
      this.setState(this.getInitialState());
    }, 1000);
  };

  handleChange = (event) => this.setState({ activeStep: event.target.value });

  handleChannelSelect = (channels) => {
    let channelsArray = channels;
    this.setState({ channelSelect: channelsArray });
  };

  errorOccurred = () => this.setState({ errorOccurred: true });

  triggerSuccess = () => {
    const isTrialUser = this.props.authentication?.user?.user_type === 'trial';
    if (isTrialUser) {
      this.sync();
      this.setState({ noErrors: 'true' });
    } else {
      this.setState({ noErrors: 'true', activeStep: 2 });
    }
  };

  handleForwardModalNavigation = () => {
    if (this.state.activeStep < 3 && this.state.activeStep >= 1) {
      this.setState((previous) => ({
        activeStep: previous.activeStep + 1,
      }));
    }
  };

  handleBackwardModalNavigation = () => {
    if (this.state.activeStep <= 3 && this.state.activeStep > 1) {
      this.setState((previous) => ({
        activeStep: previous.activeStep - 1,
      }));
    }
  };

  handleNext = () => {
    if (!this.state.errorOccurred) {
      const isTrialUser = this.props.authentication?.user?.user_type === 'trial';
      if ((isTrialUser && this.state.activeStep === 1) || this.state.activeStep === 2) {
        this.sync();
      } else {
        this.handleForwardModalNavigation();
      }
    }
  };

  sync = () => {
    if (this.isCalc) this.syncCalculatorAndChannels();
    else if (this.props.isAIKnowledgeBase) this.syncKnowledgeBaseAndChannels();
    else this.syncModuleAndChannels();
  };

  syncModuleAndChannels = () => {
    this.setState({ isLoading: true });
    axios
      .post(FIREBASE_RELEASE_SYNC_API_URL + this.props.module.id, {
        module: this.props.module.id,
        channels: this.state.channelSelect,
        team: this.props.team.id,
      })
      .then(async () => {
        await this.props.getModule(this.props.module.id);
        this.props.fromHomePage && this.props.getModules();
        this.setState({ activeStep: 3, isLoading: false });
        this.props.onSuccess?.();
        this.props.syncStatus();
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Sync unsuccessful!' });
        this.setState({ isLoading: false });
      });
  };

  syncCalculatorAndChannels = () => {
    this.setState({ isLoading: true });
    axios
      .post(CALC_SYNC_FOR_RELEASE_API + this.props.module.id, {
        calculator: this.props.module.id,
        channels: this.state.channelSelect,
        is_universal: this.state.isUniversal,
      })
      .then(async () => {
        await this.props.getModule(this.props.module.id, 'calculator');
        this.props.fromHomePage && this.props.getTeamCalculators();
        this.setState({ activeStep: 3, isLoading: false });
        this.props.onSuccess?.();
        this.props.syncStatus();
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Sync unsuccessful!' });
        this.setState({ isLoading: false });
      });
  };

  syncKnowledgeBaseAndChannels = () => {
    this.setState({ isLoading: true });
    axios
      .post(KNOWLEDGE_BASE_SYNC_API_URL + this.props.module.id, {
        ai_knowledge_base: this.props.module.id,
        channels: this.state.channelSelect,
      })
      .then(async () => {
        await this.props.getKnowledgeBase(this.props.module.id);
        this.props.fromHomePage && this.props.knowledgeBaseListAction();
        this.setState({ activeStep: 3, isLoading: false });
        this.props.onSuccess?.();
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Sync unsuccessful!' });
        this.setState({ isLoading: false });
      });
  };

  render() {
    const isTrialUser = this.props.authentication?.user?.user_type === 'trial';
    return (
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={this.props.modal}
        toggle={this.props.toggleModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: 'light-overlay',
        }}
        closeAfterTransition
      >
        <Fade in={this.props.modal}>
          <StyledPaper>
            <div style={{ height: '80%' }}>
              <Backdrop
                style={{ zIndex: 1200, background: 'rgba(214, 216, 219, 0.9)' }}
                open={this.state.isLoading}
              >
                <CircularProgress style={{ color: '#08A88E' }} />
              </Backdrop>

              <div
                className='modal-body-sync'
                style={{
                  zIndex: 1200,
                  backgroundColor: '#fff',
                  height: '100%',
                }}
              >
                {this.state.activeStep === 2 && (
                  <BackIcon>
                    <KeyboardBackspace
                      // to handle state if there are no errors so we dont want to show sync warnings or allow to go there
                      onClick={() =>
                        !(this.state.noErrors && this.state.activeStep === 2) &&
                        this.handleBackwardModalNavigation()
                      }
                    />
                  </BackIcon>
                )}

                <CloseIcon onClick={this.closeModal} style={{ zIndex: '1200' }}>
                  <HighlightOff />
                </CloseIcon>

                {this.state.activeStep === 1 && this.state.noErrors === false && (
                  <Fade in={true}>
                    <Box className='ml-2 mt-2' style={{ height: '100%' }}>
                      <SyncWarnings
                        moduleId={this.props.module.id}
                        isCalc={this.isCalc} // TODO: use module.type instead of isCalc
                        isAIKnowledgeBase={this.props.isAIKnowledgeBase}
                        triggerSuccess={this.triggerSuccess}
                        errorOccurred={this.errorOccurred}
                      />
                    </Box>
                  </Fade>
                )}

                {this.state.activeStep === 2 && (
                  <Fade in={true}>
                    <Box className='ml-2 mt-2' style={{ height: '100%' }}>
                      <FirebaseChannelForm
                        module={this.props.module}
                        isCalc={this.isCalc} // TODO: use module.type instead of isCalc
                        isAIKnowledgeBase={this.props.isAIKnowledgeBase}
                        handleChannelSelection={this.handleChannelSelect}
                        isUniversal={this.state.isUniversal}
                        setIsUniversal={(value) => this.setState({ isUniversal: value })}
                      />
                    </Box>
                  </Fade>
                )}

                {this.state.activeStep === 3 && (
                  <Fade in={true}>
                    <SuccessBox>
                      <SuccessSVGComponent style={{ width: '221.77px', height: '222.06px' }} />
                      <SyncSuccessText>Sync Successful</SyncSuccessText>
                      <SyncCaption>Data has been synced for release</SyncCaption>
                    </SuccessBox>
                  </Fade>
                )}
              </div>
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0) -62.73%, #FFFFFF 68.16%)',
                  width: '90%',
                  position: 'relative',
                  bottom: '60px',
                  height: '60px',
                }}
              >
                {' '}
              </div>

              {this.state.activeStep !== 3 && (
                <div className='sync-footer'>
                  <ThemeProvider theme={theme}>
                    <div
                      style={{
                        fontSize: '18px !important',
                        width: '50%',
                      }}
                    >
                      <StyledStepper
                        activeStep={this.state.activeStep - 1}
                        connector={<StyledStepConnector />}
                      >
                        {this.state.steps.map((label) => (
                          <Step key={label}>
                            <StyledStepLabel StepIconComponent={QontoStepIcon} l>
                              {label}
                            </StyledStepLabel>
                          </Step>
                        ))}
                      </StyledStepper>
                    </div>
                  </ThemeProvider>

                  <SyncButton
                    id='next-btn'
                    variant='contained'
                    onClick={this.handleNext}
                    style={{
                      backgroundColor: this.state.errorOccurred ? '#FFFFFF' : '#08A88E',
                      color: this.state.errorOccurred ? '#E3E3E3' : '#FFFFFF',
                    }}
                  >
                    {(isTrialUser && this.state.activeStep === 1) || this.state.activeStep === 2
                      ? 'Sync'
                      : 'Next'}
                  </SyncButton>
                </div>
              )}
            </div>
          </StyledPaper>
        </Fade>
      </Modal>
    );
  }
}

SyncWarningModal.propTypes = {
  module: PropTypes.object.isRequired,
  modal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  closeMenu: PropTypes.func, // TODO: need it?
  isAIKnowledgeBase: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  syncStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({});

export default withTeam(
  withAuthentication(
    connect(mapStateToProps, {
      getModule,
      getModules,
      getTeamCalculators,
      getKnowledgeBase,
      knowledgeBaseListAction,
    })(SyncWarningModal)
  )
);
