import 'css/project.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { EntireScreenLoading } from 'components/loader/EntireScreenLoading';
import { Suspense } from 'react';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'store';
import { queryClient } from './queryClient';

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<EntireScreenLoading />}>
          <App />
        </Suspense>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
