import { FormControlLabel } from '@material-ui/core';
import { StyledBadge } from 'components/module-detail/AvoAssistantForm';
import {
  IOSSwitch,
  PanelLabel,
  StyledFormHelperText,
} from 'components/utils/styled-components/FormStyle';

interface FieldLabelProps {
  label: string;
  detail?: string;
  required?: boolean;
  addToggle?: boolean;
  toggleValue?: boolean;
  handleToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldLabel: React.FC<FieldLabelProps> = ({
  addToggle,
  detail,
  toggleValue,
  handleToggle,
  label,
  required,
}) => {
  return (
    <PanelLabel style={{ fontSize: '16px !important' }} for='question'>
      {label} {required && <StyledBadge variant='dot' overlap='circular' />}
      {/* for conditional text form only */}
      {addToggle && (
        <FormControlLabel
          label=''
          control={
            /* For some reason, the onChange event is not working. 
       We can achieve the desired result by using the onClick 
       event and updating the data only when the switch is toggled. */
            <IOSSwitch checked={toggleValue} onClick={handleToggle} />
          }
        />
      )}
      {detail && <StyledFormHelperText id='component-helper-text'>{detail}</StyledFormHelperText>}
    </PanelLabel>
  );
};

export default FieldLabel;
