function Bold(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='texteditor/bold'>
        <path
          id='Vector'
          d='M14.6186 9.73867C15.1993 9.01251 15.5163 8.11981 15.52 7.2C15.52 4.8844 13.5818 3 11.2 3H4V17H11.68C14.0618 17 16 15.1156 16 12.8C15.9987 12.2248 15.8754 11.656 15.6377 11.1293C15.4 10.6025 15.053 10.1291 14.6186 9.73867ZM11.2 5.8C11.9939 5.8 12.64 6.42813 12.64 7.2C12.64 7.97187 11.9939 8.6 11.2 8.6H6.88V5.8H11.2ZM11.68 14.2H6.88V11.4H11.68C12.4739 11.4 13.12 12.0281 13.12 12.8C13.12 13.5719 12.4739 14.2 11.68 14.2Z'
          fill='#2E3438'
        />
      </g>
    </svg>
  );
}

export default Bold;
