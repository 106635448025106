import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import NotFoundPage from '../../components/home/NotFoundPage';
import { routes } from '../route-definitions';
import { v2routes } from '../route-definitions/v2';

import { useEffect } from 'react';
import ManualButton from '../../components/buttons/ManualButton';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useManualButton } from '../../hooks/useManualButton';
import PermanentRedirect from './PermanentRedirect';

const appRoutes = routes.map((routeDef, i) => {
  if (routeDef.type === 'REDIRECT') {
    return (
      <Route
        key={'route-index-' + i}
        path={routeDef.props.path}
        exact
        render={() => <PermanentRedirect {...(routeDef.props as any)} />}
      />
    );
  } else if (routeDef.type === 'PRIVATE') {
    return <PrivateRoute key={'route-index-' + i} exact {...routeDef.props} />;
  } else {
    return <Route exact key={'route-index-' + i} {...routeDef.props} />;
  }
});

const appRoutesV2 = v2routes.map((routeDef, i) => {
  if (routeDef.type === 'REDIRECT') {
    return (
      <Route
        key={'v2-route-index-' + i}
        path={routeDef.props.path}
        exact
        render={() => <PermanentRedirect {...(routeDef.props as any)} />}
      />
    );
  } else if (routeDef.type === 'PRIVATE') {
    return <PrivateRoute key={'v2-route-index-' + i} exact {...routeDef.props} />;
  } else {
    return <Route exact key={'v2-route-index-' + i} {...routeDef.props} />;
  }
});

const Routes = () => {
  const { isNewMember, isVisible, setVisible } = useManualButton();
  const { isLoggedIn } = useAuthentication();
  useEffect(() => {
    if (!isNewMember) setVisible(!!isLoggedIn);
  }, [isLoggedIn, isNewMember]);

  return (
    <>
      <Switch>
        {appRoutesV2}
        {appRoutes}
        <Route path='*' component={NotFoundPage} />
      </Switch>
      {isVisible && <ManualButton effect={isNewMember} />}
    </>
  );
};

export default Routes;
