import { Box, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getTriggers } from 'actions/resources/getTriggers';
import axios from 'axios';
import TextCardPreview from 'components/app-previews/card-previews/TextCardPreview';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import AppearingSituation from 'components/utils/AppearingSituation';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import InputField from 'components/utils/form-input/field';
import CharacterLimit from 'components/utils/form-input/fieldCharLimit';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { debounce } from 'lodash';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label } from 'reactstrap';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { ANSWER_CARD_API_URL, CANCEL_BUTTON, MODULE_TYPES, SAVE_BUTTON } from '../../constants';
import '../utils/modals/modal.css';
import {
  CancelButton,
  CreateButton,
  CreateRefInfoboxButton,
  InfoboxRefMenu,
  InfoboxRefMenuItem,
  StyledTextArea,
} from '../utils/styled-components/FormStyle';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface AnswerCardFormProps {
  formState?: any;
  ansCardId: any;
  triggerState?: any;
  answerPageId?: any;
  moduleId: any;
  toggleModal: () => void;
  resetAnsCards: () => void;
  getTriggers: Function;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  setIsFormDisabled: (val) => void;
}

interface AnswerCardFormState {
  id: any;
  card_text: any;
  new_card_text_content: any;
  card_text_json_tiptap: any;
  card_summary: any;
  card_title: any;
  answer_page: any;
  trigger: any;
  variables: any[];
  numerics: any[];
  textEditorsuggestions: any[];
  modal: boolean;
  cusrsorPosition: any;
  refModal: boolean;
  infoModal: boolean;
  showError: boolean;
  temp_text: any;
  refInfoDropdown: any;
}

class AnswerCardForm extends Component<AnswerCardFormProps, AnswerCardFormState> {
  debTextChangeTiptap: any;

  constructor(props: AnswerCardFormProps) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      id: '',
      card_text: '',
      new_card_text_content: '',
      card_text_json_tiptap: {},
      card_summary: '',
      card_title: '',
      answer_page: '',
      trigger: '',
      variables: [],
      numerics: [],
      textEditorsuggestions: [],
      modal: false,
      cusrsorPosition: '',
      refModal: false,
      infoModal: false,
      showError: false,
      temp_text: null,
      refInfoDropdown: '',
    };
    this.debTextChangeTiptap = debounce(this.onTextChangeTiptap, 300);
  }

  onTextChangeTiptap = (editor) => this.setState({ card_text_json_tiptap: editor.getJSON() });

  helpInfoData = getHelpInfoData('TextCard');

  componentDidMount() {
    this.props.setIsFormDisabled(false);
    this.setState({
      showError: false,
    });

    if (!this.props.ansCardId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({ trigger: alwayOnTrigger ? alwayOnTrigger.id : '' });
    }

    this.populateSuggestions();

    // for update
    if (this.props.ansCardId) {
      axios.get(ANSWER_CARD_API_URL + this.props.ansCardId + '/').then((res) => {
        this.setState({
          id: res.data.id,
          card_text: res.data.card_text ? res.data.card_text : '',
          new_card_text_content: res.data.new_card_text_content,
          card_text_json_tiptap: res.data.card_text_json_tiptap,
          card_title: res.data.card_title ? res.data.card_title : '',
          card_summary: res.data.card_summary ? res.data.card_summary : '',
          answer_page: res.data.answer_page,
          trigger: res.data.trigger,
          temp_text: res.data.new_card_text_content,
        });
      });
    } else {
      this.setState({
        answer_page: this.props.answerPageId,
      });
    }
  }

  populateSuggestions = async () => {
    // suggestions for text field
    const suggestions = await getSuggestions(this.props.moduleId);
    const variables = await getVariables(this.props.moduleId);
    const numerics = await getNumerics(this.props.moduleId);
    this.setState({ textEditorsuggestions: suggestions, variables, numerics });
  };

  // updates the state on field input
  onTextChange = (e) => {
    let text = '';
    // eslint-disable-next-line
    e.blocks.map((block) => {
      text += block.text + '\n';
    });

    this.setState({
      card_text: text,
      new_card_text_content: e,
    });
  };

  // updates the state on field input
  onSummaryChange = (e: any) =>
    this.setState({ [e.target.name]: e.target.value } as Pick<AnswerCardFormState, any>);

  toggleModal = (modalName?: any) => {
    this.setState((prev) => ({ [modalName]: !prev[modalName] }) as Pick<AnswerCardFormState, any>);
  };

  getSelectedTrigger = (data) => this.setState({ trigger: data });

  getPayload = () => {
    return {
      card_text: this.state.card_text,
      new_card_text_content: this.state.new_card_text_content,
      card_text_json_tiptap: this.state.card_text_json_tiptap,
      card_title: this.state.card_title,
      card_summary: this.state.card_summary,
      answer_page: this.state.answer_page,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      card_type: 'T',
    };
  };

  createAnsCard = () => {
    // close modal
    if (this.state.card_text.length > 1) {
      let payload = this.getPayload();
      this.props.toggleModal();

      axios.post(ANSWER_CARD_API_URL, payload).then(() => {
        // reset answer cards
        this.props.resetAnsCards();

        // fetch triggers to update used in field of triggers
        this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
      });
    } else {
      this.setState({ showError: true });
    }
  };

  editAnsCard = () => {
    // close modal
    if (this.state.card_text.length > 1) {
      let payload = this.getPayload();
      this.props.toggleModal();
      axios.put(ANSWER_CARD_API_URL + this.state.id + '/', payload).then(() => {
        // reset answer cards list
        this.props.resetAnsCards();

        // fetch triggers to update used in field of triggers
        this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
      });
    } else
      this.setState({
        showError: true,
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { card_title } = this.state;
    const { ansCardId, modal, closeUiElementPane } = this.props;

    if (card_title === '') {
      toast.error(CustomToast, { data: 'Please fill out the answer page title field.' });
    } else if (ansCardId) {
      this.editAnsCard();
    } else {
      this.createAnsCard();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const card_text_content = this.state.temp_text;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];
    const RefManagerFormProps = {
      toggleModal: () => this.toggleModal('refModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };

    const InfoBoxFormProps = {
      isFullHeight: true,
      createModal: true,
      toggleModal: () => this.toggleModal('infoModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };

    return (
      <div className='row mt-14'>
        <div className='side-panel-form'>
          <Header title='Edit Answer Card' toggleModal={this.props.toggleModal} />
          <Overlay show={isFormDisabled} />
          <Label> App Preview </Label>
          <TextCardPreview
            text={this.state.card_text}
            title={this.state.card_title}
            summary={this.state.card_summary}
            card_text_json={this.state.new_card_text_content}
            card_text_json_tiptap={this.state.card_text_json_tiptap}
          />
          <Form
            autoComplete='off'
            onSubmit={this.handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 20,
              pointerEvents: isFormDisabled ? 'none' : 'auto',
            }}
          >
            <Box className='ml-1 mt-3'>
              <AppearingSituation
                conditionLabel='Trigger (Optional)'
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box className='ml-1 mt-3'>
              {helpInfoData['card_summary'] && (
                <FormGroup>
                  <FieldLabel
                    detail={helpInfoData?.card_summary?.detail}
                    label={helpInfoData?.card_summary?.label}
                  />
                  <StyledTextArea
                    type='textarea'
                    name='card_summary'
                    style={{ borderRadius: 0 }}
                    rows='10'
                    onChange={this.onSummaryChange}
                    maxLength={helpInfoData['card_summary'].character_limit}
                    value={this.state.card_summary ? this.state.card_summary : ''}
                    placeholder={helpInfoData['card_summary'].placeholder}
                  />
                  <CharacterLimit
                    totalCount={helpInfoData['card_summary'].character_limit}
                    currentCount={this.state.card_summary.length}
                  />
                </FormGroup>
              )}
            </Box>

            <Box className='ml-1 mt-3'>
              <InputField
                name='card_title'
                required={true}
                value={this.state.card_title}
                onChange={this.onSummaryChange}
                label={helpInfoData?.card_title?.label}
                detail={helpInfoData?.card_title?.detail}
                placeholder={helpInfoData?.card_title?.placeholder}
                maxLength={helpInfoData?.card_title?.character_limit}
              />
            </Box>

            <Box className='ml-1 mt-3'>
              {helpInfoData['card_text'] && (
                <FormGroup>
                  <FieldLabel
                    detail={helpInfoData?.card_text?.detail}
                    label={helpInfoData?.card_text?.label}
                  />
                  {this.state.showError && (
                    <p style={{ color: 'red' }}> Text field can't be empty</p>
                  )}

                  <AvoEditor
                    suggestions={this.state.textEditorsuggestions}
                    setValue={this.onTextChange}
                    prevValue={card_text_content}
                    moduleId={this.props.moduleId}
                    variables={this.state.variables}
                    numerics={this.state.numerics}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    onUpdate={this.debTextChangeTiptap}
                    suggestionsToExclude={suggestionsToExclude}
                    initialContent={this.state.card_text_json_tiptap}
                    richTextButtonsShowListTiptap={richTextButtonsShowList}
                    richTextButtonShowList={[
                      ToolbarButton.TEXT_STYLE,
                      ToolbarButton.BOLD,
                      ToolbarButton.ITALIC,
                      ToolbarButton.HIGHLIGHT,
                      ToolbarButton.BULLETED_LIST,
                      ToolbarButton.NUMBERED_LIST,
                      ToolbarButton.INFOBOX,
                      ToolbarButton.INSERT_LINK,
                      ToolbarButton.MEDIA,
                      ToolbarButton.PHONE_NUMBER,
                      ToolbarButton.VARIABLES,
                      ToolbarButton.CONDITIONAL_TEXT,
                    ]}
                  />
                  <Grid container style={{ marginTop: 5 }}>
                    <Grid item xs={7}>
                      <CreateRefInfoboxButton
                        style={{
                          color: '#08A88E',
                          borderBottom: '3 solid #08A88E',
                        }}
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        onClick={(event) => this.setState({ refInfoDropdown: event.target })}
                      >
                        Create Infobox/Reference
                        <ExpandMoreIcon color='disabled' />
                      </CreateRefInfoboxButton>
                      <InfoboxRefMenu
                        style={{
                          marginTop: 35,
                        }}
                        id='simple-menu'
                        anchorEl={this.state.refInfoDropdown}
                        keepMounted
                        open={Boolean(this.state.refInfoDropdown)}
                        onClose={() => this.setState({ refInfoDropdown: null })}
                      >
                        <InfoboxRefMenuItem
                          onClick={() => {
                            this.toggleModal('infoModal');
                            this.setState({ refInfoDropdown: null });
                          }}
                        >
                          Create Infobox
                        </InfoboxRefMenuItem>
                        <Divider variant='middle' light />

                        <InfoboxRefMenuItem
                          onClick={() => {
                            this.toggleModal('refModal');
                            this.setState({ refInfoDropdown: null });
                          }}
                        >
                          Create Reference
                        </InfoboxRefMenuItem>
                      </InfoboxRefMenu>
                    </Grid>
                  </Grid>
                </FormGroup>
              )}
            </Box>

            <Box className='ml-auto'>
              <CancelButton onClick={() => this.props.toggleModal()} className='mr-4'>
                {CANCEL_BUTTON}
              </CancelButton>
              <CreateButton type='submit'>{SAVE_BUTTON}</CreateButton>
            </Box>

            <SlidingPane
              isOpen={this.state.refModal}
              onRequestClose={() => this.toggleModal('refModal')}
              from='bottom'
              // hideHeader
              className='sliding-pan-modal  side-popup-shadow'
              title={
                <div>
                  Create a Reference Manager
                  <HighlightOffIcon
                    className='backIcon'
                    onClick={() => this.toggleModal('refModal')}
                  ></HighlightOffIcon>
                </div>
              }
              //   subtitle='Build a Trigger'
              width='587px'
              closeIcon={
                <div>
                  <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
                </div>
              }
            >
              <RefManagerForm {...RefManagerFormProps} />
            </SlidingPane>

            <SlidingPane
              isOpen={this.state.infoModal}
              onRequestClose={() => this.toggleModal('infoModal')}
              overlayClassName='infobox-overlay-for-zindex'
              from='right'
              // hideHeader
              className='no-padding add-info-box sliding-panel-shadow'
              width='1210px'
            >
              <InfoBoxForm {...InfoBoxFormProps} />
            </SlidingPane>
          </Form>

          <ConfirmModal
            preset='unsaved'
            open={this.props?.modal!}
            toggleModal={this.props.closeUiElementPane!}
            performAction={() => {
              this.props.closeUiElementPane?.();
            }}
            handleSubmit={this.handleSubmit}
            panelForm
          />
        </div>
      </div>
    );
  }
}

const suggestionsToExclude: SuggestionTypes[] = ['knowledge_base', 'media', 'ehr_order'];

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch, AnswerCardFormProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerCardForm);
