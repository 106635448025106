import { SVGProps } from 'react';

export const AlgoMoreIcon = ({ fill = '#6C7C83', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='20' height='20' rx='4' fill='#6C7C83' />
      <circle cx='5' cy='10' r='1.5' fill='white' />
      <circle cx='10' cy='10' r='1.5' fill='white' />
      <circle cx='15' cy='10' r='1.5' fill='white' />
    </svg>
  );
};
export const AlgoDragIcon = ({ fill = '#6C7C83', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <circle cx='6' cy='4' r='1' transform='rotate(90 6 4)' fill='#6C7C83' />
      <circle cx='6' cy='8' r='1' transform='rotate(90 6 8)' fill='#6C7C83' />
      <circle cx='6' cy='12' r='1' transform='rotate(90 6 12)' fill='#6C7C83' />
      <circle cx='10' cy='4' r='1' transform='rotate(90 10 4)' fill='#6C7C83' />
      <circle cx='10' cy='8' r='1' transform='rotate(90 10 8)' fill='#6C7C83' />
      <circle cx='10' cy='12' r='1' transform='rotate(90 10 12)' fill='#6C7C83' />
    </svg>
  );
};
export const CircleIcon = ({ fill = '#6C7C83', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <circle cx='9' cy='9' r='8' fill='white' stroke='#C5D1D8' />
    </svg>
  );
};
