import React, { ReactNode, useMemo, useState } from 'react';
import { H4 } from './typo';
import { ReactComponent as ArrowDown } from '../../assets/icons/v2/item/arrow_down.svg';
import { twMerge } from 'tailwind-merge';

interface OrderedListProps<T> {
  list: T[];
  keyword?: string;
  emptyComponent?: any;
  columns: Partial<Record<keyof T, string>>;
  renderItem: (item: T) => ReactNode;
}

export const SortableList = <T extends object>({
  list,
  keyword,
  emptyComponent,
  columns,
  renderItem,
}: OrderedListProps<T>) => {
  const [orderDirection, setOrderDirection] = useState<any>('desc');
  const [sortBy, setSortBy] = useState('last_updated');
  const handleClick = (target: string) => {
    setOrderDirection(sortBy !== target || orderDirection === 'asc' ? 'desc' : 'asc');
    setSortBy(target);
  };

  const sortedList = useMemo(() => {
    // Sort modules
    const compareDirection = orderDirection === 'asc' ? 1 : -1;
    const sortedlist = list.sort((a, b) =>
      Object.keys(a).includes(sortBy)
        ? compareDirection *
          a[sortBy]
            ?.toString()
            .toLowerCase()
            .localeCompare(b[sortBy]?.toString().toLowerCase())
        : 0
    );

    // Filter modules from keyword
    return !!keyword
      ? sortedlist.filter((module) =>
          Object.keys(module).some((key) =>
            String(module[key]).toLowerCase().includes(keyword.toLowerCase())
          )
        )
      : sortedlist;
  }, [list, orderDirection, sortBy, keyword]);

  return (
    <>
      {sortedList.length <= 0 ? (
        { ...emptyComponent }
      ) : (
        <table className='w-full table-auto'>
          <thead>
            <tr className='!border-b-2 !border-gray-300'>
              {Object.keys(columns).map((key) => (
                <th
                  className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0'
                  align='left'
                  key={key}
                >
                  <label
                    className='flex cursor-pointer items-center'
                    onClick={handleClick.bind(this, key)}
                  >
                    <H4>{columns[key]}</H4>
                    <ArrowDown
                      className={twMerge(
                        'duration-150',
                        orderDirection === 'desc' && sortBy === key && 'rotate-180',
                        sortBy === key ? 'text-primary-600' : 'text-gray-300'
                      )}
                    />
                  </label>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{sortedList.map((module: any) => renderItem(module))}</tbody>
        </table>
      )}
    </>
  );
};
