import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getMedia } from 'actions/resources/getMedia';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import FilePreviewer from 'components/utils/file-handling/file-previewer';
import UploadFile from 'components/utils/file-handling/file-upload';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import { debounce } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import { getRepresentationPhrase } from 'utils/utilityFunctions';
import { INFOBOX_IMAGE_API_URL } from '../../../constants';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

const StyledInput = styled(Input)`
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  max-width: 550px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  font-weight: 300;
`;

interface ImageFormProps {
  selectedText: any;
  formState: any;
  toolsForm: any;
  label: any;
  infoBoxId: any;
  type: any;
  isFullHeight: any;
  refManagerState: any;
  imageId: any;
  modal?: boolean;

  closeUiElementPane?: () => void;
  toggleModal: (modalName?: any) => void;
  savePosition: () => void;
  getInfoBoxes: (type: any, mirrorId, moduleId) => void;
  selectValue: (val?: any) => void;
  populateSuggestions: () => void;
  getUniqueCode: (id?: any, type?: any) => void;
  getMedia: (type: any, mirrorId, moduleId) => void;
  getNoteGenerator: (type: any, mirrorId, moduleId) => void;
  setIsFormDisabled: (val) => void;

  // for withRouter
  match: any;
}

interface ImageFormState {
  title: string;
  refModal: boolean;
  representation_phrase: any;
  newCaptionContent: any;
  captionJSONTiptap: any;
  caption: any;
  image: any;
  imagePreviewUrl: string;
  video_url: string;
  tempContent: any;
  references: any;
  module: string;

  urls: any;
  selectedReferences: any;
  onSelectHeight: boolean;
  repPhraseSync: boolean;
  fileType: any;

  moduleId: string;
}

// TODO: check if it is still being used
class ImageForm extends Component<ImageFormProps, ImageFormState> {
  debOnCaptionChangeTiptap: any;

  constructor(props: any) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      title: '',
      refModal: false,
      representation_phrase: '',
      caption: '',
      newCaptionContent: null,
      captionJSONTiptap: {},
      tempContent: null,
      references: [],
      module: '',

      image: '',
      imagePreviewUrl: '',
      video_url: '',
      repPhraseSync: true,
      urls: [],
      fileType: '',
      selectedReferences: [],
      onSelectHeight: false,

      moduleId: this.props.match.params.moduleId,
    };
    this.debOnCaptionChangeTiptap = debounce(this.onCaptionChangeTiptap, 1000);
  }

  onCaptionChangeTiptap = (editor) => this.setState({ captionJSONTiptap: editor.getJSON() });

  setReferences = (references: Array<{ source: any; id: any; url: any }>) => {
    let selectedReferences: Array<{ source: any; id: any; url: any }> = [];
    references.forEach(function (item) {
      selectedReferences.push({
        source: item.source,
        id: item.id,
        url: item.url,
      });
    });
    return selectedReferences;
  };

  helpInfoData = getHelpInfoData('Image');

  componentDidMount() {
    this.props.setIsFormDisabled(false);

    if (this.props.label) {
      this.setState({
        title: this.props.label.substring(0, this.helpInfoData['title'].character_limit),
        representation_phrase: getRepresentationPhrase(
          this.props.label,
          this.helpInfoData['representation_phrase'].character_limit
        ),
      });
    }

    if (this.props.imageId) {
      axios.get(INFOBOX_IMAGE_API_URL + this.props.imageId + '/').then((res) => {
        this.setState({
          title: res.data.title || '',
          representation_phrase: res.data.representation_phrase || '',
          caption: res.data.caption || '',
          newCaptionContent: res.data.new_caption_content,
          tempContent: res.data.new_caption_content,
          references: res.data.references,
          selectedReferences: res.data.reference_details
            ? this.setReferences(res.data.reference_details)
            : [],
          image: res.data.file_name || '',
          imagePreviewUrl: res.data.image,
          fileType: res.data.file_type,
          video_url: res.data.video_url,
          captionJSONTiptap: res.data.caption_json_tiptap,
        });

        if (this.state.selectedReferences.length > 0) {
          this.setState({ onSelectHeight: true });
        }
      });
    }
  }

  onSelect = (stateName, selectedList: Array<{ id: any }>, selectedItem) => {
    let selectedItemId: Array<{ id: any }> = [];

    selectedList.forEach(function (item) {
      selectedItemId.push(item.id);
    });

    this.setState({
      references: selectedItemId,
      selectedReferences: selectedList,
      onSelectHeight: true,
    });
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (prevState) =>
        ({
          [modalName]: !prevState[modalName],
        }) as Pick<ImageFormState, any>
    );
  };

  // updates the state on field input
  onChange = (e) => {
    if (e.target.name === 'title' && this.state.repPhraseSync === true) {
      this.setState({
        representation_phrase: getRepresentationPhrase(
          e.target.value,
          this.helpInfoData['representation_phrase'].character_limit
        ),
      });
    }
    this.setState({ [e.target.name]: e.target.value } as Pick<ImageFormState, any>);
  };

  // updates the state on field input
  onCaptionChange = (e) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      caption: text,
      newCaptionContent: e,
    });
  };

  handleImage = (e: any) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileInput = document.getElementById('fileInput') as HTMLInputElement | null;
    if (file && file.size > 25000000) {
      toast.error(CustomToast, { data: 'File size must be less than 25MB' });
      if (fileInput) fileInput.value = '';
    } else {
      reader.onloadend = () => {
        this.setState({
          image: file,
          imagePreviewUrl: URL.createObjectURL(file),
          fileType: file?.type,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  createImage = async () => {
    if (!this.state.image && !this.state.video_url) {
      toast.error(CustomToast, { data: 'Media is not added' });
      return;
    }

    // let refs = this.state.references;

    let formdata = new FormData();
    formdata.set('title', this.state.title);
    formdata.set('caption', this.state.caption);
    formdata.set('new_caption_content', JSON.stringify(this.state.newCaptionContent));
    formdata.set('caption_json_tiptap', JSON.stringify(this.state.captionJSONTiptap));
    formdata.set('representation_phrase', this.state.representation_phrase);
    formdata.set('references', this.state.references);

    if (this.props.type === 'calculator') {
      formdata.set('calculator', this.state.moduleId);
    } else {
      formdata.set('module', this.state.moduleId);
    }

    formdata.set('image', this.state.image);
    formdata.set('video_url', this.state.video_url);

    this.props.toggleModal();
    // wait for save Position to complete
    await this.props.savePosition();
    axios({
      url: INFOBOX_IMAGE_API_URL,
      method: 'POST',
      data: formdata,
    }).then(async (res) => {
      await this.props.getMedia(this.props.type, null, this.state.moduleId);
      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }

      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }
    });
  };

  editImage = async () => {
    if (!this.state.image && !this.state.video_url) {
      toast.error(CustomToast, { data: 'Media is not added' });
      return;
    }

    let formdata = new FormData();
    formdata.set('title', this.state.title);
    formdata.set('caption', this.state.caption);
    formdata.set('new_caption_content', JSON.stringify(this.state.newCaptionContent));
    formdata.set('representation_phrase', this.state.representation_phrase);
    formdata.set('caption_json_tiptap', JSON.stringify(this.state.captionJSONTiptap));
    formdata.set('references', this.state.references);

    if (this.props.type === 'calculator') {
      formdata.set('calculator', this.state.moduleId);
    } else {
      formdata.set('module', this.state.moduleId);
    }

    formdata.set('image', this.state.image);
    formdata.set('video_url', this.state.video_url);

    await this.props.savePosition();

    axios({
      url: INFOBOX_IMAGE_API_URL + this.props.imageId + '/',
      method: 'PUT',
      data: formdata,
    }).then(async (res) => {
      this.props.toggleModal(this.props.imageId);
      await this.props.getMedia(this.props.type, null, this.state.moduleId);
      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }

      // refresh infoboxes list to get update the name of images used in infoboxes
      this.props.getInfoBoxes(this.props.type, null, this.state.moduleId);
      this.props.getNoteGenerator(this.props.type, null, this.state.moduleId);

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { title } = this.state;
    const { imageId, modal, closeUiElementPane } = this.props;

    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the title field.' });
    } else if (imageId) {
      this.editImage();
    } else {
      this.createImage();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const refManrProps = {
      type: this.props.type,
      toggleModal: () => this.toggleModal('refModal'),
      savePosition: () => [],
    };
    const filePreviwer = {
      fileType: this.state.fileType,
      previewWidth: '500px',
      previewHeight: '500px',
      fileURL: this.state.imagePreviewUrl,
    };

    let multiSelectstyle = {
      chips: {
        background: '#08A88E',
      },
      searchBox: {
        height: this.state.onSelectHeight ? 'auto' : '46px',
        minHeight: '46px',
        background: '#ffffff',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        borderRadius: '5px',
        margin: '0px',
      },
      multiselectContainer: {
        color: '#2bbbad',
      },
      inputField: {
        textIndent: '10px',
        paddingTop: 0,
        marginTop: '-4px',
        display: 'inline-block',
        borderBottom: 'none',
        boxShadow: 'none',
      },
    };

    return (
      <Form
        onKeyPress={this.onKeyPress}
        className={this.props.toolsForm ? 'mediaToolsForm' : ''}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          pointerEvents: isFormDisabled ? 'none' : 'auto',
        }}
        autoComplete='off'
        onSubmit={this.handleSubmit}
      >
        <Overlay show={isFormDisabled} />
        {this.props.isFullHeight ? (
          <Header className='px-0' title='Media' toggleModal={this.props.toggleModal} />
        ) : (
          ''
        )}
        <div className='row'>
          <div className='col-md-12'>
            <InputField
              name='title'
              required={true}
              value={this.state.title}
              onChange={this.onChange}
              label={helpInfoData?.title?.label}
              detail={helpInfoData?.title?.detail}
              placeholder={helpInfoData?.title?.placeholder}
              maxLength={helpInfoData?.title?.character_limit}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {helpInfoData['caption'] && (
              <FormGroup>
                <FieldLabel
                  detail={helpInfoData?.caption?.detail}
                  label={helpInfoData?.caption?.label}
                />

                <AvoEditor
                  moduleId={this.state.moduleId}
                  moduleType={this.props.type}
                  suggestions={[]}
                  setValue={this.onCaptionChange}
                  prevValue={this.state.tempContent}
                  disableMentionPlugin={true}
                  richTextButtonShowList={[
                    ToolbarButton.TEXT_STYLE,
                    ToolbarButton.BOLD,
                    ToolbarButton.ITALIC,
                    ToolbarButton.HIGHLIGHT,
                    ToolbarButton.BULLETED_LIST,
                    ToolbarButton.NUMBERED_LIST,
                    ToolbarButton.INSERT_LINK,
                    ToolbarButton.PHONE_NUMBER,
                    ToolbarButton.VARIABLES,
                    ToolbarButton.CONDITIONAL_TEXT,
                  ]}
                  wrapperClassNames='flex-grow'
                  onUpdate={this.debOnCaptionChangeTiptap}
                  initialContent={this.state.captionJSONTiptap}
                  richTextButtonsShowListTiptap={richTextButtonsShowList}
                  suggestionsToExclude={suggestionsToExclude}
                  characterLimitTotalCount={helpInfoData['caption'].character_limit}
                  characterLimitCurrentCount={this.state.caption?.length}
                />
              </FormGroup>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {helpInfoData['references'] && (
              <FormGroup>
                <FieldLabel
                  detail={helpInfoData?.references?.detail}
                  label={helpInfoData?.references?.label}
                />
                <Divider style={{ backgroundColor: '#ffffff' }} />
                <div className='col-md-3 float-right pr-0'>
                  <Button
                    variant='contained'
                    color='default'
                    onClick={() => this.toggleModal('refModal')}
                    style={{
                      background: '#08A88E',
                      color: '#ffffff',
                      borderRadius: '6px',
                      width: '100px',
                      height: '46px',
                      float: 'right',
                      boxShadow: 'none',
                    }}
                  >
                    CREATE
                  </Button>
                </div>
                <div className='col-md-9 pl-0 pr-0'>
                  <Multiselect
                    name='references'
                    showArrow
                    options={this.props.refManagerState.refmanagers}
                    selectedValues={this.state.selectedReferences}
                    onSelect={(selectedList, selectedItem) =>
                      this.onSelect('selectedReferences', selectedList, selectedItem)
                    }
                    onRemove={(selectedList, removedItem) =>
                      this.onSelect('selectedReferences', selectedList, removedItem)
                    }
                    displayValue='source'
                    style={multiSelectstyle}
                  />
                </div>
              </FormGroup>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {helpInfoData['image'] && (
              <FormGroup>
                <FieldLabel
                  detail={helpInfoData?.image?.detail}
                  label={helpInfoData?.image?.label}
                />
                <UploadFile
                  handleFile={this.handleImage}
                  allowedFormats='image/*, .pdf'
                  fileName={this.state.image?.name || this.state.image || 'No file selected'}
                />
              </FormGroup>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <FormGroup>
              <FieldLabel label='YouTube URL' />
              <StyledInput
                name='video_url'
                type='url'
                title='Only youtube video URL is allowed'
                pattern='https://(www\.youtube\.com|youtu\.?be)\/.+$'
                onChange={this.onChange}
                value={this.state.video_url ? this.state.video_url : ''}
              />
            </FormGroup>
          </div>
        </div>

        {this.state.imagePreviewUrl && (
          <div className='row'>
            <div className='col-md-12'>
              <FilePreviewer {...filePreviwer} />
            </div>
          </div>
        )}

        <div className='row' style={{ marginLeft: 'auto', marginTop: 'auto' }}>
          <CancelButton
            style={{ color: 'black', marginRight: '10px' }}
            onClick={this.props.toggleModal}
          >
            {CANCEL_BUTTON}
          </CancelButton>

          <CreateButton type='submit' name='action'>
            {SAVE_BUTTON}
          </CreateButton>
        </div>
        <SlidingPane
          isOpen={this.state.refModal}
          onRequestClose={() => this.toggleModal('refModal')}
          from='bottom'
          // hideHeader
          className='sliding-pan-modal side-popup-shadow'
          title={
            <div>
              Create a Reference Manager
              <HighlightOffIcon
                className='backIcon'
                onClick={() => this.toggleModal('refModal')}
              ></HighlightOffIcon>
            </div>
          }
          //   subtitle='Build a Trigger'
          width='550px'
          closeIcon={
            <div>
              <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
            </div>
          }
        >
          <RefManagerForm {...refManrProps} />
        </SlidingPane>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

const mapDispatchToProps = (dispatch, ImageFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getMedia: (type, mirrorId, moduleId) => dispatch(getMedia(type, mirrorId, moduleId)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getNoteGenerator: (moduleType, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(moduleType, mirrorId, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

const suggestionsToExclude: SuggestionTypes[] = [
  'knowledge_base',
  'variable',
  'reference',
  'infobox',
  'media',
  'ehr_order',
];

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageForm));
