import { UserIcon, XCircleIcon } from '@heroicons/react/20/solid';

import ConfirmationModal from 'components/utils/ConfirmationModal';
import { useState } from 'react';
import { IAuthor } from './AddAuthorController';

interface AuthorItemProps {
  author: IAuthor;
  onDelete: (id: number) => void;
}

const AuthorItem = ({ author, onDelete }: AuthorItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='flex gap-4 p-[17px] rounded border border-gray-300'>
      <div>
        {author.image ? (
          <img src={author.image} alt={'author'} className='h-9 w-9 rounded-full' />
        ) : (
          <UserIcon className='h-9 w-9 text-gray-400 border border-gray-500 rounded-full' />
        )}
      </div>
      <div className='grow'>
        <div className='font-bold text-lg'>{author.name}</div>
        <div className='italic text-sm text-gray-500'>{author.title}</div>
        <div>{author.description}</div>
      </div>
      <div className='cursor-pointer' onClick={() => setOpen(true)}>
        <XCircleIcon className='h-9 w-9 rounded-full text-gray-300' />
      </div>
      <ConfirmationModal
        open={open}
        onClose={() => setOpen(false)}
        action={() => onDelete(author.id)}
        title='Delete Featured Author'
        text={`Are you sure you want to delete ${author.name}?`}
      />
    </div>
  );
};

export default AuthorItem;
