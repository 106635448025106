import axios, { AxiosResponse } from 'axios';
import { CONVERSION_TO_DRAFTJS_URL, CONVERSION_TO_TIPTAP_URL } from '../constants';
import { ConversionPayload } from '../components/utils/tiptap/converter/converting-button/utils';

export const moduleConversionAPI = {
  convertToTiptap: (payload: ConversionPayload) =>
    axios
      .post<any, AxiosResponse<any, any>, ConversionPayload>(CONVERSION_TO_TIPTAP_URL, payload)
      .then((response) => response.data),
  convertToDraftJS: ({ moduleId }: { moduleId: number }) =>
    axios.post(CONVERSION_TO_DRAFTJS_URL, { module: moduleId }).then((response) => response.data),
};
