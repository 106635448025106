import { Box, Button, Fade, Paper } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Typography } from 'antd';
import React, { Component } from 'react';
import './modal.css';

export const CancelButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    marginTop: '10px',
    color: '#08A88E',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',

    '&:hover': {
      background: 'transparent',
    },
  },
}))(Button);

class CustomModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const closeBtn = (
      <button className='close' style={{ marginLeft: 'auto' }}>
        <HighlightOffIcon
          style={{
            color: '#C7C7C7',
            cursor: 'pointer',
          }}
          onClick={this.props.toggleModal}
        />
      </button>
    );

    return (
      <Modal
        open={this.props.modal}
        onClose={this.props.toggleModal}
        className='d-flex align-items-center justify-content-center'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: 'light-overlay',
        }}
        closeAfterTransition
      >
        <Fade in={this.props.modal}>
          <Paper
            style={{
              width: this.props.width || '407px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '40px',
              boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.08)',
            }}
          >
            <Box style={{ marginRight: '28px', marginTop: 40, display: 'flex' }}>
              <Typography
                style={{
                  marginLeft: 62,
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '28px',
                  lineHeight: '30px',
                  textAlign: 'left',
                  color: '#000000',
                }}
              >
                {this.props.modalHeader}
              </Typography>
              {closeBtn}
            </Box>
            <Box
              style={{
                paddingLeft: '60px',
                paddingRight: '60px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '20px',
                paddingBottom: '30px',
              }}
            >
              <div style={{ marginTop: 30, textAlign: 'center' }}>{this.props.children}</div>

              {!this.props.cancelDisplay && (
                <CancelButton variant='text' disableRipple onClick={this.props.toggleModal}>
                  {this.props.cancelText ? this.props.cancelText : 'Cancel'}
                </CancelButton>
              )}
            </Box>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

export default CustomModal;
