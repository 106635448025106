import ProfileLayout from 'components/accounts/profile/ProfileLayout';
import { MainLayout } from 'components/layout/MainLayout';

export const Profile = () => {
  return (
    <MainLayout>
      <ProfileLayout />
    </MainLayout>
  );
};
