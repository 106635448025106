import { makeStyles, Popover } from '@material-ui/core';
import { StyledMenuItemV2 } from '../../teams/styles';

const useStyles = makeStyles((theme) => ({
  paperProps: {
    minWidth: '150px',
    background: '#FFFFFF',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    maxHeight: 200,
    padding: 0,
  },
  permissionItem: {
    height: 40,
    cursor: 'pointer',
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#1E1F20',
  },
}));
function Dropdown({ dropdownAnchorEl, items, setDropdownAnchorEl, performAction }) {
  const classes = useStyles();

  return (
    <Popover
      style={{ minWidth: 160, maxWidth: 160 }}
      anchorEl={dropdownAnchorEl}
      open={Boolean(dropdownAnchorEl)}
      onClose={() => setDropdownAnchorEl(null)}
      disableEnforceFocus
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        className: classes.paperProps,
      }}
    >
      {items.map((item, index) => (
        <StyledMenuItemV2
          key={`${item.id + index}`}
          value={item.id}
          //  style={classes.permissionItem}
          onClick={() => performAction(item)}
        >
          {item.title || 'Title Not Found'}
        </StyledMenuItemV2>
      ))}
    </Popover>
  );
}

export default Dropdown;
