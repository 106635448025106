import { MODULE_TYPES, Permissions } from '../../../constants';
import { ModuleWithMirror as NewModuleWithMirror } from '../../../hooks/useModules';

// TODO: Remove all exclude getUniqueModuleKey function when new home page is applied
export interface Mirror {
  id: number;
  name: string;
  team: number;
  module: number | null;
  calculator: number | null;
  knowledge_base: number | null;
  details: Module;
  is_mirror_of: string;
}

interface Module {
  id: number;
  name: string;
  status: string;
  last_synced: string | null;
  last_updated: string;
  type: string;
  owner: string;
  current_user_role: Permissions | null;
  code: string;
  last_updated_by?: string;
  permission_type?: Permissions;
  last_verified?: string | null;
  reviewed_by?: ReviewedBy | null;
}

interface ReviewedBy {
  id: number;
  email: string;
  name: string;
}

export interface ModuleWithMirror extends Module {
  team?: number;
  mirrorOf?: string;
  mirroredModuleId?: number;
}

// combine algos, calcs, knowledge bases, mirrors
// sort by its key and direction
// filter by keyword
export const organizeModules = (
  modules: ModuleWithMirror[],
  mirrors: Mirror[],
  keyword = '',
  sortBy = 'last_updated',
  orderDirection = 'desc'
): ModuleWithMirror[] => {
  // Combine modules & mirrors
  // mirror.id : mirror's unique id
  // mirror.mirroredModuleId : mirrored module id
  const mirrorModules = mirrors.reduce(
    (prev: ModuleWithMirror[], mirror) => [
      ...prev,
      {
        ...mirror.details,
        id: mirror.id,
        mirroredModuleId: mirror.details?.id,
        mirrorOf: mirror.is_mirror_of,
        status: 'View Only',
        type: MODULE_TYPES.MIRROR,
      },
    ],
    []
  );
  const combinedModules = mirrorModules.concat(modules);

  // Sort modules
  const compareDirection = orderDirection === 'asc' ? 1 : -1;
  const sortedModules = combinedModules.sort((a, b) =>
    Object.keys(a).includes(sortBy)
      ? compareDirection *
        a[sortBy]
          ?.toString()
          .toLowerCase()
          .localeCompare(b[sortBy]?.toString().toLowerCase())
      : 0
  );

  // Filter modules from keyword
  return !!keyword
    ? sortedModules.filter((module) =>
        Object.keys(module).some((key) =>
          String(module[key]).toLowerCase().includes(keyword.toLowerCase())
        )
      )
    : sortedModules;
};

// TODO: remove ModuleWithMirror interface
export const getUniqueModuleKey = (module: ModuleWithMirror | NewModuleWithMirror) =>
  `${module.id}-${module.type}`;
