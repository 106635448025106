import { Icons } from '../Icons';
import { Caption2 } from '../typo';
import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useState } from 'react';

export const FormTooltip = ({ message }: { message: string }) => {
  const [open, onOpenChange] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open,
    onOpenChange,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const hover = useHover(context);
  const role = useRole(context, { role: 'label' });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, role, focus, dismiss]);
  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Icons.Info />
      </div>
      {open && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className='z-10 rounded bg-gray-800 px-[12px] py-[8px]'
        >
          <Caption2 className='text-white'>{message}</Caption2>
        </div>
      )}
    </>
  );
};
