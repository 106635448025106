import { Backdrop, CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Input } from 'reactstrap';
import styled from 'styled-components';

import AddBasicIcon from 'assets/icons/addBasicIcon';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import ImageForm from 'components/resources/image-Infobox/imageForm';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import NoteGeneratorForm from 'components/resources/smart-note/smartNoteForm';
import { onSortEnd } from 'components/utils/ListItemUtil';
import { Accordion, AccordionDetails, AccordionSummary, exmapleNToolsStyles } from './styles';

const StyledInput = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 462px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: 300;
`;

const SortableItem = SortableElement(
  ({
    item,
    indexNo,
    handleDeleteClick,
    handleListChange,
    infoboxes,
    noteGenerators,
    media,
    legacyCalculators,
    teamCalculators,
    universalCalculators,
    moduleId,
    calculatorId,
    type,
  }) => {
    const [expanded, setExpanded] = React.useState('panel1');
    const [createInfobox, setCreateInfobox] = React.useState(false);
    const [updateInfoboxModal, setUpdateInfoboxModal] = React.useState(false);

    const [createNoteGenerator, setCreateNoteGenerator] = React.useState(false);
    const [updateNote, setUpdateNote] = React.useState(false);

    const [calcRefreshing] = React.useState(false);
    const [createMedia, setCreateMedia] = React.useState(false);
    const [updateMedia, setUpdateMedia] = React.useState(false);
    const classes = exmapleNToolsStyles();

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const editInfoMedia = () => {
      if (item.infobox && Object.keys(item.infobox).length > 0) {
        setUpdateInfoboxModal(true);
      } else if (item.image && Object.keys(item.image).length > 0) {
        setUpdateMedia(true);
      }
    };

    const universalCalculatorIds = universalCalculators?.map(
      (universalCalculator) => universalCalculator?.id
    );
    const isUniversal = universalCalculatorIds.includes(item?.calculator?.id);

    return (
      <Accordion
        square
        expanded={expanded === `panel${indexNo + 1}`}
        onChange={handleChange(`panel${indexNo + 1}`)}
        key={indexNo}
      >
        <AccordionSummary
          aria-controls={`panel${indexNo + 1}d-content`}
          id={`panel${indexNo + 1}d-header`}
        >
          {expanded === `panel${indexNo + 1}` ? (
            <KeyboardArrowDownIcon style={{ color: '#A6A6A6', marginTop: '-2px' }} />
          ) : (
            <ChevronRightIcon style={{ color: '#A6A6A6', marginTop: '-2px' }} />
          )}
          <Typography id={`label${indexNo}`} className={classes.accordianTitle}>
            {item.label || (item.text && item.text.statement) || 'Label'}
          </Typography>

          <span style={{ position: 'absolute', right: '1em' }}>
            <DeleteIcon style={{ color: 'red' }} onClick={() => handleDeleteClick(item)} />
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={'span'} style={{ width: '549px' }}>
            <label className={classes.label}> Label </label>
            <br />
            <StyledInput
              type='text'
              name={`label${indexNo}`}
              placeholder='Label'
              key={item.id}
              maxLength={255}
              value={item?.label || item?.text?.statement || ''}
              onChange={(e) => handleListChange(item.id, e.target.value, 'label')}
            />
            <p className={classes.remainingCharacters}>
              {item?.label?.length || item?.text?.statement?.length || 0}/255 characters
            </p>

            {item.infobox && item.infobox['infobox_type'] === 'NG' && (
              <React.Fragment>
                <label className={classes.label}> Note </label>
                <br />
                <Box className='d-flex align-items-center flex-row'>
                  <Select
                    name='noteGenerator'
                    value={item.infobox.id || '-----'}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => handleListChange(item.id, e.target.value, 'noteGenerator')}
                    className='answerInbox'
                    classes={{ icon: classes.icon }}
                    label='Select Note Generator'
                    disableUnderline
                  >
                    <StyledMenuItem value='-----'>Select</StyledMenuItem>
                    {noteGenerators.map((noteGenerator) => (
                      <StyledMenuItem key={noteGenerator.id} value={noteGenerator.id}>
                        {noteGenerator.shortened_title}
                      </StyledMenuItem>
                    ))}
                  </Select>
                  <Box
                    className={classes.editBox}
                    onClick={() => {
                      item.infobox && Object.keys(item.infobox).length > 0 && setUpdateNote(true);
                    }}
                    style={{
                      pointerEvents: item.infobox.id ? 'all' : 'none',
                      opacity: item.infobox.id ? '1' : '0.4',
                    }}
                  >
                    <Typography
                      className={classes.editButton}
                      style={{ color: item?.infobox?.id ? '#08A88E' : '#CBCBCB' }}
                    >
                      Edit
                    </Typography>
                  </Box>
                  <Divider orientation='vertical' style={{ height: '20px', marginRight: '10px' }} />
                  <Button
                    className={classes.createButton}
                    variant='text'
                    startIcon={
                      <div style={{ marginBottom: '2px' }}>
                        <AddBasicIcon />
                      </div>
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCreateNoteGenerator((prevState) => !prevState);
                    }}
                  >
                    New Note
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {((item.infobox && item.infobox['infobox_type'] !== 'NG') || item.image) && (
              <React.Fragment>
                <label className={classes.label}> Infobox/Media </label>
                <br />
                <Box className='d-flex align-items-center flex-row'>
                  <Select
                    name='infobox'
                    value={item?.infobox?.id || item?.image?.id || '-----'}
                    IconComponent={ExpandMoreIcon}
                    // onChange={handleInfoboxNMedia}
                    className='answerInbox'
                    classes={{ icon: classes.icon }}
                    label='Select Infobox'
                    disableUnderline
                    style={{
                      icon: (provided, state) => ({
                        ...provided,
                        height: 46,
                        position: 'initial',
                      }),
                    }}
                  >
                    <StyledMenuItem value='-----'>Select</StyledMenuItem>
                    {infoboxes.map((infobox) => (
                      <StyledMenuItem
                        key={infobox.id}
                        value={item?.infobox?.id && infobox.id}
                        onClick={() => handleListChange(item.id, infobox.id, 'infobox')}
                      >
                        {infobox.shortened_title}
                      </StyledMenuItem>
                    ))}
                    {media.map((image) => (
                      <StyledMenuItem
                        key={image.id}
                        value={item?.image?.id && image.id}
                        onClick={() => handleListChange(item.id, image.id, 'image')}
                      >
                        {image.representation_phrase}
                      </StyledMenuItem>
                    ))}
                  </Select>
                  <Box className={classes.editBox} onClick={editInfoMedia}>
                    <Typography
                      className={classes.editButton}
                      style={{
                        color:
                          (item.infobox && Object.keys(item.infobox).length > 0) ||
                          (item.image && Object.keys(item.image).length > 0)
                            ? '#08A88E'
                            : '#CBCBCB',
                      }}
                    >
                      Edit
                    </Typography>
                  </Box>
                  <Divider orientation='vertical' style={{ height: '20px', marginRight: '10px' }} />
                  <Button
                    className={classes.createButton}
                    variant='text'
                    startIcon={
                      <div style={{ marginBottom: '2px' }}>
                        <AddBasicIcon />
                      </div>
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCreateInfobox((prevState) => !prevState);
                    }}
                  >
                    New Infobox
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {(item.legacy_calculator || (item.calculator && isUniversal)) && (
              <React.Fragment>
                <label className={classes.label}> Universal Calculator </label>
                <br />
                <Select
                  name='calc'
                  value={item?.legacy_calculator?.id || item?.calculator?.id || '-----'}
                  IconComponent={ExpandMoreIcon}
                  className='calc-select-menu'
                  classes={{ icon: classes.icon }}
                  label='Select Output Value'
                  disableUnderline
                >
                  <StyledMenuItem value='-----'>Select</StyledMenuItem>
                  {universalCalculators.map((universalCalculator) => (
                    <StyledMenuItem
                      key={universalCalculator.id}
                      value={item?.calculator?.id && universalCalculator.id}
                      onClick={() => handleListChange(item.id, universalCalculator.id, 'calc')}
                    >
                      {universalCalculator.name}
                    </StyledMenuItem>
                  ))}
                  {legacyCalculators.map((legacyCalculator) => (
                    <StyledMenuItem
                      key={legacyCalculator.id}
                      value={item?.legacy_calculator?.id && legacyCalculator.id}
                      onClick={() => handleListChange(item.id, legacyCalculator.id, 'legacy_calc')}
                    >
                      {legacyCalculator.title}
                    </StyledMenuItem>
                  ))}
                </Select>
              </React.Fragment>
            )}
            {item.calculator && !isUniversal && (
              <React.Fragment>
                <label className={classes.label}> Team Calculator </label>
                <br />
                <Select
                  name='calc'
                  value={item.calculator.id || '-----'}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) => handleListChange(item.id, e.target.value, 'calc')}
                  className='calc-select-menu'
                  classes={{ icon: classes.icon }}
                  label='Select Output Value'
                  disableUnderline
                >
                  <StyledMenuItem value='-----'>Select</StyledMenuItem>
                  {teamCalculators.map((teamCalculator) => (
                    <StyledMenuItem key={teamCalculator.id} value={teamCalculator.id}>
                      {teamCalculator.name}
                    </StyledMenuItem>
                  ))}
                </Select>
              </React.Fragment>
            )}
          </Typography>
        </AccordionDetails>

        {/* Infoboxes modal */}
        <SlidingPane
          isOpen={createInfobox}
          onRequestClose={() => setCreateInfobox((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='1210px'
        >
          <div className='mt-14'>
            <InfoBoxForm
              label={item.label}
              type={type}
              toggleModal={() => setCreateInfobox((prevState) => !prevState)}
              savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
              selectValue={(infobox) => handleListChange(item.id, infobox.id, 'infobox')}
              isFullHeight={true}
              createModal={true}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={updateInfoboxModal}
          onRequestClose={() => setUpdateInfoboxModal((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='1210px'
        >
          <div className='mt-14'>
            <InfoBoxForm
              isFullHeight={true}
              type={type}
              infoBoxId={item.infobox && item.infobox.id}
              toggleModal={() => setUpdateInfoboxModal((prevState) => !prevState)}
              savePosition={() => []}
              selectValue={(infobox) => handleListChange(item.id, infobox.id, 'infobox')}
            />
          </div>
        </SlidingPane>

        {/* note generator modals*/}
        <SlidingPane
          isOpen={createNoteGenerator}
          onRequestClose={() => setCreateNoteGenerator((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='1210px'
        >
          <div className='mt-14'>
            <NoteGeneratorForm
              label={item.label}
              type={type}
              toggleModal={() => setCreateNoteGenerator((prevState) => !prevState)}
              savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
              selectValue={(noteGenerator) =>
                handleListChange(item.id, noteGenerator.id, 'noteGenerator')
              }
              isFullHeight={true}
              createModal={true}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={updateNote}
          onRequestClose={() => setUpdateNote((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='1210px'
        >
          <div className='mt-14'>
            <NoteGeneratorForm
              isFullHeight={true}
              type={type}
              noteGeneratorId={item.infobox && item.infobox.id}
              toggleModal={() => setUpdateNote((prevState) => !prevState)}
              savePosition={() => []}
              selectValue={(noteGenerator) =>
                handleListChange(item.id, noteGenerator.id, 'noteGenerator')
              }
            />
          </div>
        </SlidingPane>

        {/* Media Modals */}
        <SlidingPane
          isOpen={createMedia}
          onRequestClose={() => setCreateMedia((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='620px'
        >
          <div className='mt-14'>
            <ImageForm
              toggleModal={() => setCreateMedia((prevState) => !prevState)}
              label={item.label}
              type={type}
              toolsForm={true}
              isFullHeight={true}
              savePosition={() => []} // No need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
              selectValue={(image) => handleListChange(item.id, image.id, 'image')}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={updateMedia}
          onRequestClose={() => setUpdateMedia((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          hideHeader
          className='no-padding add-info-box'
          width='620px'
        >
          <div className='mt-14'>
            <ImageForm
              toggleModal={() => setUpdateMedia((prevState) => !prevState)}
              type={type}
              isFullHeight={true}
              imageId={item.image && item.image.id}
              toolsForm={true}
              savePosition={() => []} // No need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
              selectValue={(image) => handleListChange(item.id, image.id, 'image')}
            />
          </div>
        </SlidingPane>

        <Backdrop
          style={{
            zIndex: 2000,
            color: '#08A88E',
            background: 'rgba(214, 216, 219, 0.9)',
          }}
          open={calcRefreshing}
        >
          <CircularProgress style={{ color: '#08A88E' }} />
        </Backdrop>
      </Accordion>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    moduleId,
    calculatorId,
    infoboxes,
    noteGenerators,
    media,
    legacyCalculators,
    handleDeleteClick,
    handleListChange,
    teamCalculators,
    universalCalculators,
    type,
  }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            indexNo={index}
            item={item}
            moduleId={moduleId}
            calculatorId={calculatorId}
            type={type}
            infoboxes={infoboxes}
            noteGenerators={noteGenerators}
            legacyCalculators={legacyCalculators}
            teamCalculators={teamCalculators}
            universalCalculators={universalCalculators}
            media={media}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

function ListItem(props) {
  const shouldCancelStart = (e) => {
    // e.target.tagName.toUpperCase() == 'INPUT' added for the avoiding drag and drop from input box
    return e.target.id === 'mui-component-select-infobox' ||
      e.target.id === 'mui-component-select-image' ||
      e.target.id === 'mui-component-select-calc' ||
      e.target.id === 'mui-component-select-noteGenerator' ||
      e.target.tagName.toUpperCase() === 'INPUT'
      ? true
      : false;
  };

  return (
    <SortableList
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      handleDeleteClick={props.handleDeleteClick}
      handleListChange={props.handleListChange}
      moduleId={props.moduleId}
      calculatorId={props.calculatorId}
      type={props.type}
      infoboxes={props.infoboxState.infoBoxes}
      noteGenerators={props.noteGeneratorState.noteGenerators}
      media={props.mediaState.images}
      legacyCalculators={props.legacyCalcState.calculators}
      teamCalculators={props.teamCalculatorState.teamCalculators}
      universalCalculators={props.teamCalculatorState.universalCalculators}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
      shouldCancelStart={(e) => shouldCancelStart(e)}
      lockAxis='y'
    />
  );
}

const mapStateToProps = (state) => ({
  mediaState: state.mediaState,
  infoboxState: state.infoboxState,
  noteGeneratorState: state.noteGeneratorState,
  legacyCalcState: state.calculatorState,
  teamCalculatorState: state.teamCalculatorState,
});

export default connect(mapStateToProps, {})(ListItem);
