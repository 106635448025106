import { LinkIcon } from '@heroicons/react/20/solid';
import { NodeViewWrapper } from '@tiptap/react';
import { Icons } from 'components/utils/Icons';
import { FC } from 'react';
import { TiptapNodePreview, TiptapReadOnlyNode } from '../../TiptapNodePreview';

export const LinkPreview = ({ node, deleteNode, editor }) => (
  <NodeViewWrapper className='inline'>
    {editor.isEditable ? (
      <TiptapNodePreview
        Icon={LinkIcon as FC}
        onDelete={deleteNode}
        onEdit={() => true} // to show the edit icon
        title={node?.attrs?.title || node?.attrs?.name}
      />
    ) : (
      <TiptapReadOnlyNode
        title={node?.attrs?.title || node?.attrs?.name}
        Icon={Icons.TiptapLinkReadOnly}
      />
    )}
  </NodeViewWrapper>
);
