import { Checkbox, MenuList, Popover } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { StyledMenuItemV2 } from 'components/teams/styles';
import { DropdownIcon } from 'components/utils/styled-components/icons';
import { useEffect, useState } from 'react';
import './inputComponents.styles.css';

interface ChoiceDropdownProps {
  values: any;
  multi?: any;
  code: any;
  previewContent: any;
  setPreviewContent: any;
  arrayIndex: any;
  name: any;
  entityKey: any;
}

function ChoiceDropdown(props: ChoiceDropdownProps) {
  const { values, multi, previewContent, setPreviewContent, entityKey } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState('');

  const handleChoiceInput = (value) => {
    setSelectedItem(value);
  };

  useEffect(() => {
    const content = previewContent;

    entityKey.forEach((ind) => {
      if (content?.entityMap[ind]) {
        content.entityMap[ind].data.mention.name = selectedItem;
        setPreviewContent(content);
      }
    });
  }, [selectedItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginLeft: 'auto' }}>
      <div
        className='typed-input'
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
        onClick={(e) => handleClick(e)}
      >
        <span
          style={{
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {selectedItem}
        </span>
        <DropdownIcon style={{ marginLeft: 'auto', color: '#6C7C83', marginRight: 13 }} />
      </div>
      <Popover
        PaperProps={{
          style: {
            width: 160,
            boxShadow:
              '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
            borderRadius: '4px',
          },
        }}
        // style={{ width: 200 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuList autoFocusItem>
          {values?.map((value) => (
            <StyledMenuItemV2
              style={{
                height: 30,
                paddingLeft: 12,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#1E1F20',
                padding: multi ? 0 : '10',
                paddingRight: multi ? 5 : 10,
              }}
              onClick={() => {
                handleChoiceInput(value);
                if (!multi) setAnchorEl(null);
              }}
            >
              {multi && <Checkbox size='small' />}
              {value}{' '}
              {value === selectedItem && (
                <Done style={{ marginLeft: 'auto', color: '#23C29D', fontSize: 17 }} />
              )}
            </StyledMenuItemV2>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
}

export default ChoiceDropdown;
