import { Box, Button, Divider, FormControlLabel, IconButton, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddBasicIcon from 'assets/icons/addBasicIcon';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import { onSortEnd } from 'components/utils/ListItemUtil';
import { GreenCheckbox } from 'components/utils/styled-components/FormStyle';
import React from 'react';
import SlidingPane from 'react-sliding-pane';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { onlyAllowNumbers } from 'utils/utilityFunctions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTooltip,
  StyledInput,
  exmapleNToolsStyles,
} from './styles';
// Props for SortableItem component
interface SortableItemProps {
  item: any;
  indexNo: number;
  handleDeleteClick: (id: any) => void;
  handleListChange: (id: any, value: any, key: string, numericType: any) => void;
  infoboxes: any[];
  moduleId: any;
  calculatorId: any;
  type: any;
  nextButton: any;
}

// Props for SortableList component
interface SortableListProps {
  items: any[];
  moduleId: any;
  calculatorId: any;
  type: any;
  nextButton: any;
  infoboxes: any[];
  handleDeleteClick: (id: any) => void;
  handleListChange: (id: any, value: any, key: string, numericType: any) => void;
}

const StyledFormLabel = withStyles((theme) => ({
  label: {
    marginTop: 2,
    fontSize: 16,
    color: '#1E1F20',
  },
}))(FormControlLabel);

const SortableItem = SortableElement<SortableItemProps>(
  ({
    item,
    indexNo,
    handleDeleteClick,
    handleListChange,
    infoboxes,
    moduleId,
    calculatorId,
    type,
    nextButton,
  }) => {
    const [expanded, setExpanded] = React.useState('panel1');
    const [createInfobox, setCreateInfobox] = React.useState(false);
    const [updateInfoboxModal, setUpdateInfoboxModal] = React.useState(false);
    const classes = exmapleNToolsStyles();

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <>
        <div className='rounded-xl border-b'>
          <Accordion
            square
            expanded={expanded === `panel${indexNo + 1}`}
            onChange={handleChange(`panel${indexNo + 1}`)}
            key={indexNo}
          >
            <AccordionSummary
              aria-controls={`panel${indexNo + 1}d-content`}
              id={`panel${indexNo + 1}d-header`}
            >
              {expanded === `panel${indexNo + 1}` ? (
                <KeyboardArrowDownIcon style={{ color: '#A6A6A6' }} />
              ) : (
                <ChevronRightIcon style={{ color: '#A6A6A6' }} />
              )}
              <Typography id={`label${indexNo}`} style={{ marginLeft: '5px', color: '#191919' }}>
                {item.display_value}
              </Typography>

              <span style={{ position: 'absolute', right: '1em' }}>
                <DeleteIcon style={{ color: 'red' }} onClick={(e) => handleDeleteClick(item.id)} />
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: '549px' }}>
                <StyledFormLabel
                  style={{ display: 'flex', alignItems: 'center' }}
                  control={
                    <GreenCheckbox
                      checked={item.is_optional}
                      onChange={(e) => {
                        handleListChange(
                          item.id,
                          !item.is_optional,
                          'is_optional',
                          item.numeric_type
                        );
                      }}
                      name='is_optional'
                    />
                  }
                  label='Make this input optional'
                />
                <label className={classes.label}> Name </label>
                <br />
                <StyledInput key={item.id} value={item.display_value} type='text' disabled />
                {'default_value' in item && (
                  <React.Fragment>
                    <label className={classes.label}>
                      Default Value (Optional)
                      <CustomTooltip
                        placement='bottom-end'
                        title={
                          <Typography style={{ fontSize: '13px' }}>
                            Enable the next button to use default value feature
                          </Typography>
                        }
                      >
                        <span>
                          <IconButton style={{ padding: '0px 0px 0px 5px' }} aria-label='delete'>
                            <ErrorOutlineOutlinedIcon style={{ color: '#C5D1D8' }} />
                          </IconButton>
                        </span>
                      </CustomTooltip>
                    </label>
                    <br />
                    <StyledInput
                      type='number'
                      name='default-value'
                      step='any'
                      nextButton={nextButton}
                      value={item.default_value}
                      onKeyDown={(e) => onlyAllowNumbers(e)}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onChange={(e) =>
                        handleListChange(
                          item.id,
                          e.target.value,
                          'default_value',
                          item.numeric_type
                        )
                      }
                    />
                  </React.Fragment>
                )}

                {'infobox' in item && (
                  <React.Fragment>
                    <label className={classes.label}> Infobox (Optional) </label>
                    <br />
                    <Box className='d-flex align-items-center flex-row'>
                      <Select
                        name='infobox'
                        value={item.infobox || '-----'}
                        IconComponent={ExpandMoreIcon}
                        className='answerInbox'
                        classes={{ icon: classes.icon }}
                        label='Select Output Value'
                        disableUnderline
                        onChange={(e) =>
                          handleListChange(item.id, e.target.value, 'infobox', item.numeric_type)
                        }
                      >
                        <StyledMenuItem value='-----'>Select</StyledMenuItem>
                        {infoboxes.map((infobox) => (
                          <StyledMenuItem key={infobox.id} value={infobox.id}>
                            {infobox.shortened_title}
                          </StyledMenuItem>
                        ))}
                      </Select>
                      <Box
                        className={classes.editBox}
                        onClick={() => item.infobox && setUpdateInfoboxModal(true)}
                      >
                        <Typography
                          className={classes.editButton}
                          style={{ color: item.infobox ? '#08A88E' : '#CBCBCB' }}
                        >
                          Edit
                        </Typography>
                      </Box>
                      <Divider
                        orientation='vertical'
                        style={{ height: '20px', marginRight: '10px' }}
                      />
                      <Button
                        className={classes.createButton}
                        variant='text'
                        startIcon={
                          <div style={{ marginBottom: '2px' }}>
                            <AddBasicIcon />
                          </div>
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCreateInfobox((prevState) => !prevState);
                        }}
                      >
                        New Infobox
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Typography>
            </AccordionDetails>

            <SlidingPane
              isOpen={createInfobox}
              onRequestClose={() => setCreateInfobox((prevState) => !prevState)}
              overlayClassName='infobox-overlay-for-zindex'
              from='right'
              hideHeader
              className='no-padding add-info-box sliding-panel-shadow mt-5'
              width='1210px'
            >
              <InfoBoxForm
                createModal={true}
                type={type}
                toggleModal={() => setCreateInfobox((prevState) => !prevState)}
                savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
                selectValue={(infobox) =>
                  handleListChange(item.id, infobox.id, 'infobox', item.numeric_type)
                }
                isFullHeight={true}
              />
            </SlidingPane>

            <SlidingPane
              isOpen={updateInfoboxModal}
              onRequestClose={() => setUpdateInfoboxModal((prevState) => !prevState)}
              overlayClassName='infobox-overlay-for-zindex'
              from='right'
              hideHeader
              className='no-padding add-info-box mt-5'
              width='1210px'
            >
              <InfoBoxForm
                isFullHeight={true}
                type={type}
                infoBoxId={item.infobox}
                toggleModal={() => setUpdateInfoboxModal((prevState) => !prevState)}
                savePosition={() => []}
                selectValue={(infobox) =>
                  handleListChange(item.id, infobox.id, 'infobox', item.numeric_type)
                }
              />
            </SlidingPane>
          </Accordion>
        </div>
      </>
    );
  }
);

const SortableList = SortableContainer<SortableListProps>(
  ({
    items,
    moduleId,
    calculatorId,
    type,
    nextButton,
    infoboxes,
    handleDeleteClick,
    handleListChange,
  }) => {
    return (
      <div>
        {items.map((val, index) => (
          <SortableItem
            nextButton={nextButton}
            key={val.id}
            index={index}
            indexNo={index}
            item={val}
            moduleId={moduleId}
            calculatorId={calculatorId}
            type={type}
            infoboxes={infoboxes}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

function ListItem(props) {
  const shouldCancelStart = (e) => {
    // e.target.tagName.toUpperCase() == 'INPUT' added for the avoiding drag and drop from input box
    return e.target.id === 'mui-component-select-infobox' ||
      e.target.tagName.toUpperCase() === 'INPUT'
      ? true
      : false;
  };

  return (
    <SortableList
      nextButton={props.nextButton}
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      infoboxes={props.infoboxes}
      moduleId={props.moduleId}
      calculatorId={props.calculatorId}
      type={props.type}
      handleDeleteClick={props.handleDeleteClick}
      handleListChange={props.handleListChange}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
      shouldCancelStart={(e) => shouldCancelStart(e)}
      lockAxis='y'
    />
  );
}

export default ListItem;
