import { CustomPopover } from '../utils/popover/Popover';

interface ModulePopoverProps {
  open: boolean;
  onClose: () => void;
  sectionInfoList: SectionInfo[];
}

export interface SectionInfo {
  head: string;
  description: string;
  owner?: string;
  additionalHead?: string;
}

export function ModulePopover({ open, onClose, sectionInfoList }: ModulePopoverProps) {
  return (
    <CustomPopover open={open} onClose={onClose}>
      <div
        className='w-[245px] divide-y divide-gray-200 px-[8px] py-[4px] shadow-08'
        onMouseLeave={onClose}
      >
        {sectionInfoList.map((sectionInfo) => (
          <div className='whitespace-nowrap px-[16px] py-[12px]' key={sectionInfo.head}>
            <p className='pb-[12px] text-[14px] font-semibold leading-5 tracking-[0.25px] text-gray-700'>
              {sectionInfo.head}
              {sectionInfo.additionalHead && (
                <span className='font-normal'>({sectionInfo.additionalHead})</span>
              )}
              :
            </p>
            <p className='truncate text-body-1'>{sectionInfo.description}</p>
            {sectionInfo.owner && <p className='text-body-1 text-gray-700'>{sectionInfo.owner}</p>}
          </div>
        ))}
      </div>
    </CustomPopover>
  );
}
