import { ModuleLayout } from 'components/layout/ModuleLayout';
import { TriggerResourceTable } from 'components/resources/triggers/TriggerTable';

export const Triggers = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <TriggerResourceTable />
    </ModuleLayout>
  );
};
