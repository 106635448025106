import {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Icons } from '../Icons';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  autoUpdate,
  ReferenceType,
  useClick,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';

const IsOpensContext = createContext<{
  isOpenItems: boolean[];
  setIsOpenItems: (isOpenItems: boolean[]) => void;
}>({
  isOpenItems: [],
  setIsOpenItems: () => {},
});

const moveArrayElement = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const [element] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
};

interface ExpandableItemsProps {
  children: ReactNode | ReactNode[];
  onAdd?: () => void;
  setValue: (value: any[]) => void;
  addItemLabel?: string;
  items: any[];
}

export const ExpandableList = ({
  children,
  onAdd,
  setValue,
  addItemLabel = 'Add Data',
  items,
}: ExpandableItemsProps) => {
  const [isOpenItems, setIsOpenItems] = useState<boolean[]>([]);
  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    setValue(moveArrayElement(items, source.index, destination.index));
    setIsOpenItems(moveArrayElement(isOpenItems, source.index, destination.index));
  };

  const handleAdd = () => {
    if (!onAdd) return;
    setIsOpenItems([...isOpenItems, true]);
    onAdd();
  };

  useEffect(() => {
    setIsOpenItems(Array.from({ length: items.length }, (_, index) => index === 0));
  }, []);

  // dnd needs to be rendered after call requestAnimationFrame
  // if not, unexpected Draggable component be rendered
  // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <div className='w-full rounded border border-gray-200 bg-gray-bg-strong'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='ExpandableListDroppable' key='ExpandableListDroppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='select-none'>
              <IsOpensContext.Provider value={{ isOpenItems, setIsOpenItems }}>
                {Array.isArray(children) ? children[0] : children}
              </IsOpensContext.Provider>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {Array.isArray(children) && children[1]}
      {!!onAdd && (
        <div className='mt-[8px] px-[14px] py-[9px]'>
          <button
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              handleAdd();
            }}
            className='flex cursor-pointer items-center space-x-1 focus:bg-transparent'
          >
            <Icons.Plus className='w-3 fill-primary-600' />
            <p className='text-button-1 font-semibold text-primary-600'>{addItemLabel}</p>
          </button>
        </div>
      )}
    </div>
  );
};

interface ExpandableContextValues {
  setReference: (node: ReferenceType | null) => void;
  setFloating: (node: HTMLElement | null) => void;
  getReferenceProps: (userProps?: React.HTMLProps<Element> | undefined) => Record<string, unknown>;
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
  open: boolean;
}

const ExpandableContext = createContext<ExpandableContextValues>({
  setFloating: () => {},
  setReference: () => {},
  getFloatingProps: () => ({}),
  getReferenceProps: () => ({}),
  open: false,
});

type ExpandableItemProps = {
  children: ReactNode;
  index?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const ExpandableItem = forwardRef<HTMLDivElement, ExpandableItemProps>(
  ({ children, index, ...props }, ref) => {
    const { isOpenItems, setIsOpenItems } = useContext(IsOpensContext);
    const [open, onOpenChange] = useState(true);
    const {
      refs: { setReference, setFloating },
      context,
    } = useFloating({
      open: index !== undefined ? isOpenItems[index] : open,
      onOpenChange:
        index !== undefined
          ? (open) =>
              setIsOpenItems(
                isOpenItems.map((isOpen, openIndex) => (openIndex === index ? open : isOpen))
              )
          : onOpenChange,
      whileElementsMounted: autoUpdate,
    });
    const click = useClick(context, {
      keyboardHandlers: false,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([click]);
    return (
      <div ref={ref} {...props}>
        {index !== undefined ? (
          <Draggable draggableId={`item-${index}`} key={`item-${index}`} index={index}>
            {(provided) => (
              <div
                className='!left-auto !right-auto' // for ignoring parent css
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <div className='m-[4px] rounded border border-gray-border-strong bg-white'>
                  <ExpandableContext.Provider
                    value={{
                      setReference,
                      setFloating,
                      getReferenceProps,
                      getFloatingProps,
                      open: index !== undefined ? isOpenItems[index] : open,
                    }}
                  >
                    {children}
                  </ExpandableContext.Provider>
                </div>
              </div>
            )}
          </Draggable>
        ) : (
          <div className='m-[4px] rounded border border-gray-border-strong bg-white'>
            <ExpandableContext.Provider
              value={{ setReference, setFloating, getReferenceProps, getFloatingProps, open }}
            >
              {children}
            </ExpandableContext.Provider>
          </div>
        )}
      </div>
    );
  }
);

type ExpandableButtonProps = {
  children: ReactNode | ((open: boolean) => ReactNode);
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const ExpandableButton = forwardRef<HTMLDivElement, ExpandableButtonProps>(
  ({ children, ...props }, ref) => {
    const { setReference, getReferenceProps, open } = useContext(ExpandableContext);
    return (
      <div ref={useMergeRefs([setReference, ref])} {...getReferenceProps()} {...props}>
        {typeof children === 'function' ? children(open) : children}
      </div>
    );
  }
);

type ExpandablePanelProps = {
  children: ReactNode;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const ExpandablePanel = forwardRef<HTMLDivElement, ExpandablePanelProps>(
  ({ children, className, ...props }, ref) => {
    const { setFloating, getFloatingProps, open } = useContext(ExpandableContext);
    const refs = useMergeRefs([setFloating, ref]);

    return (
      <>
        {open && (
          <div
            ref={refs}
            className={twMerge('px-[16px] pb-[14px]', className)}
            {...getFloatingProps()}
            {...props}
          >
            {children}
          </div>
        )}
      </>
    );
  }
);

ExpandableList.Items = ({ children }) => <>{children}</>;
ExpandableList.Item = ExpandableItem;
ExpandableList.Button = ExpandableButton;
ExpandableList.Panel = ExpandablePanel;
