import { ExpandMore } from '@material-ui/icons';
import { ModuleEditorNavLink, NavButton } from 'components/bars/styles';
import { useState } from 'react';
import TeamMenu from './menu';
interface TeamManagmentProps {
  activeState: string;
  moduleEditor: boolean;
}
const TeamManagment: React.FC<TeamManagmentProps> = ({ activeState, moduleEditor }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <>
      {!moduleEditor ? (
        <NavButton
          aria-haspopup='true'
          aria-controls='simple-menu'
          onClick={(e) => setAnchorEl(e.target as HTMLElement)}
          style={{
            color: activeState === 'TeamManagement' ? '#08A88E' : '#616262',
            borderBottom: activeState === 'TeamManagement' ? '3 solid #08A88E' : 'none',
          }}
          className='focus:bg-transparent'
        >
          Team Management
          <ExpandMore color='disabled' style={{ marginLeft: 5 }} />
        </NavButton>
      ) : (
        <ModuleEditorNavLink
          className='navbar-btn-res focus:bg-transparent'
          variant='text'
          onClick={(e) => setAnchorEl(e.target as HTMLElement)}
        >
          Team Management
          <ExpandMore color='disabled' style={{ marginLeft: 5 }} />
        </ModuleEditorNavLink>
      )}

      <TeamMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
    </>
  );
};

export default TeamManagment;
