import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { MouseEvent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Author, authorAPI } from '../../../api/author';
import { useTeam } from '../../../hooks/useTeam';
import Button from '../../utils/Button';
import { Icons } from '../../utils/Icons';
import Input from '../../utils/Input';
import Label from '../../utils/Label';
import { Modal } from '../../utils/modals/Modal';
import RequiredMark from '../../utils/requiredMark';
import Textarea from '../../utils/textarea';
import { CustomToast } from '../../utils/toast-message';
import { Body2 } from '../../utils/typo';

interface FeaturedAuthorModalProps {
  author?: Author;
  open: boolean;
  onClose: () => void;
}

interface AuthorState {
  name: string;
  description: string;
  team: number;
  image?: File;
}

export function FeaturedAuthorModal({ author, open, onClose }: FeaturedAuthorModalProps) {
  const { getInputProps, getRootProps, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
    },
  });
  const queryClient = useQueryClient();
  const { team } = useTeam();
  const { mutate, isLoading } = useMutation(
    (formData: FormData) => authorAPI.upsertAuthor(formData, author?.id),
    {
      onSuccess: () => {
        toast.success(CustomToast, {
          data: `Author ${!!author ? 'Updated' : 'Created'} Successfully`,
        });
        queryClient.invalidateQueries(['teamAuthors', team.id]);
        onClose();
      },
    }
  );
  const [preview, setPreview] = useState<string>();
  const [authorState, setAuthorState] = useState<AuthorState>({
    name: '',
    description: '',
    team: team.id,
  });

  useEffect(() => {
    setPreview(author?.image);
    setAuthorState({
      ...authorState,
      name: author?.name ?? '',
      description: author?.description ?? '',
      image: undefined,
    });
  }, [author, open]);

  useEffect(() => {
    const image = acceptedFiles[0];
    if (!image) {
      return;
    }
    setPreview(URL.createObjectURL(image));
    setAuthorState({ ...authorState, image });
  }, [acceptedFiles]);

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    const formData = new FormData();
    Object.keys(authorState).forEach(
      (key) => !!authorState[key] && formData.set(key, authorState[key])
    );

    mutate(formData);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPreview(undefined);
    setAuthorState({ ...authorState, image: undefined });
  };

  return (
    <Modal open={open} onClose={onClose} className='px-0'>
      <Modal.Head onClose={onClose} className='border-b px-[16px] py-[12px]'>
        <div className='text-heading-3'>{!!author ? 'Update' : 'Add'} Featured Author</div>
      </Modal.Head>
      <Modal.Body className='py-0'>
        <div className='space-y-2.5 p-5'>
          <div className='mx-auto w-full'>
            <div className='cursor-pointer' {...getRootProps()}>
              {preview ? (
                <>
                  <img
                    src={preview}
                    alt='author preview'
                    className='mx-auto h-[120px] w-[120px] rounded-full'
                  />
                  <div className='gap flex items-center justify-center gap-6'>
                    <button className='select-none py-2 text-center text-button-1 text-primary-600'>
                      Change
                    </button>
                    <button
                      className='select-none py-2 text-center text-button-1 text-primary-600'
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <Icons.Profile className='mx-auto' />
                  <p className='select-none py-2 text-center text-button-1 text-primary-600'>
                    Upload Image
                  </p>
                </>
              )}
              <Input
                className='hidden'
                type='file'
                name='image'
                accept='image/*'
                {...getInputProps()}
              />
            </div>
          </div>
          <div>
            <Label className='w-full space-y-1'>
              <Body2 className='flex text-gray-700'>
                Featured Author Name
                <RequiredMark />
              </Body2>
              <Input
                className='rounded border border-gray-200 px-4 py-3'
                type='text'
                name='name'
                value={authorState.name}
                onChange={(e) =>
                  setAuthorState({ ...authorState, [e.target.name]: e.target.value })
                }
              />
              <p className='text-end text-caption-2'>
                <span className='text-gray-900'>{authorState.name.length}</span>
                <span className='text-gray-700'>/250</span>
              </p>
            </Label>
          </div>
          <div>
            <Label className='w-full space-y-1'>
              <Body2 className='flex text-gray-700'>
                Description
                <RequiredMark />
              </Body2>
              <Textarea
                className='h-[160px] rounded border border-gray-200 px-4 py-3'
                defaultValue={authorState.description}
                onChange={(e) =>
                  setAuthorState({ ...authorState, description: e.currentTarget.value })
                }
              />
              <p className='text-end text-caption-2'>
                <span className='text-gray-900'>{authorState.description.length}</span>
                <span className='text-gray-700'>/1000</span>
              </p>
            </Label>
          </div>
        </div>
        <div className='flex justify-end space-x-3 px-5 py-4'>
          <div>
            <Button.Reverse type='button' className='px-4 py-2' onClick={onClose}>
              Cancel
            </Button.Reverse>
          </div>
          <div>
            <Button
              type='button'
              className='!w-28 px-8 py-2'
              onClick={handleSubmit}
              disabled={!authorState.name || !authorState.description}
            >
              {isLoading ? <LoadingSpinner size='small' /> : 'Save'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
