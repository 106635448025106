import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IModuleList } from 'api/module';
import { simulateInWebapp } from 'components/module-detail/ConversationBoardUtils';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import { Badge } from 'components/utils/Badge';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import { Icons } from 'components/utils/Icons';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { Modal } from 'components/utils/modals/Modal';
import { Body2 } from 'components/utils/typo';
import { useModules } from 'hooks/useModules';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { MODULE_LABELS, MODULE_TYPES } from '../../constants';

interface ModuleSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (modules: IModuleList[]) => void;
  selected: IModuleList[];
}

export const ModuleSelectionModal = ({
  open,
  onClose,
  onSelect,
  selected,
}: ModuleSelectionModalProps) => {
  const selectedArray = selected.map((module) => module.id + module.type);
  const { modules } = useModules();
  const [teamOnly, setTeamOnly] = useState(true);
  const [query, setQuery] = useState('');
  const columns = useMemo<ColumnDef<IModuleList>[]>(
    () => [
      {
        id: 'select-col',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        id: 'id',
        accessorKey: 'id',
      },
      {
        id: 'moduleName',
        header: 'Module Name',
        accessorKey: 'title',
        cell: (cell) => {
          const title = cell.getValue();
          const isInCurrentTeam = cell.row.getValue('isInCurrentTeam');
          const isUniversal = cell.row.getValue('isUniversal');
          const moduleId = cell.row.getValue('id') + '';
          const moduleType = cell.row.getValue('type') + '';
          const isSimulatable =
            moduleType !== MODULE_TYPES.KNOWLEDGE_BASE && !isInCurrentTeam && !!isUniversal;

          return (
            <div className='flex items-center gap-[4px]'>
              <span>{title + ''}</span>
              {!cell.row.getCanSelect() && (
                <Badge className='text-gray-text-weak'>Not Published</Badge>
              )}
              {isSimulatable && (
                <div
                  className='cursor-pointer text-primary-600'
                  onClick={() => simulateInWebapp(moduleId, moduleType)}
                >
                  <Icons.LinkOpen />
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'mirrorOf',
        accessorKey: 'mirrorOf',
      },
      {
        id: 'isUniversal',
        accessorKey: 'is_universal',
      },
      {
        id: 'isInCurrentTeam',
        accessorKey: 'is_in_current_team',
      },
      { id: 'lastSynced', accessorKey: 'last_synced' },
      {
        id: 'type',
        header: 'Type',
        accessorKey: 'type',
        cell: (cell) => {
          const mirrorOf = cell.row.getValue('mirrorOf');
          const isUniversal = cell.row.getValue('isUniversal');
          const isInCurrentTeam = cell.row.getValue('isInCurrentTeam');
          let type;
          switch (mirrorOf ?? cell.getValue()) {
            case MODULE_TYPES.CALCULATOR:
              type = MODULE_LABELS.CALCULATOR;
              break;
            case MODULE_TYPES.KNOWLEDGE_BASE:
              type = MODULE_LABELS.KNOWLEDGE_BASE;
              break;
            default:
            case MODULE_TYPES.ALGO:
              type = MODULE_LABELS.ALGO;
              break;
          }
          if (!isInCurrentTeam && !!isUniversal) type += ' (Global)';
          else if (!!mirrorOf) type += ` (${MODULE_LABELS.MIRROR})`;
          return type;
        },
      },
    ],
    []
  );
  const { getHeaderGroups, getRowModel, getSelectedRowModel, reset } = useReactTable({
    columns,
    data: modules
      .filter((module) => (module.mirrorOf || module.type) !== MODULE_TYPES.KNOWLEDGE_BASE)
      .filter((module) => !selectedArray.includes(module.id + module.type))
      .filter((module) => (teamOnly ? module.is_in_current_team : true))
      .filter((module) =>
        query
          ? module.title.toLowerCase().includes(query) || module.type.toLowerCase().includes(query)
          : true
      )
      .map(
        (module) =>
          ({
            id: module.id,
            title: module.title,
            type: module.type,
            mirrorOf: module.mirrorOf,
            is_universal: module.is_universal,
            is_in_current_team: module.is_in_current_team,
            last_synced: module.last_synced,
          }) as IModuleList
      ),
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id + row.type,
    state: {
      columnVisibility: {
        id: false,
        mirrorOf: false,
        isUniversal: false,
        isInCurrentTeam: false,
        lastSynced: false,
      },
    },
    enableRowSelection: (row) => !!row.getValue('lastSynced'),
  });

  const selectedRows = getSelectedRowModel();

  useEffect(() => {
    reset();
  }, [teamOnly, query]);

  const onSubmit = () => {
    onSelect(selectedRows.rows.map((row) => row.original));
    onClose();
    reset();
  };

  return (
    <Modal open={open} onClose={onClose} size='large'>
      <Modal.Head onClose={onClose}>Add Modules</Modal.Head>
      <Modal.Body>
        <div>
          <div className='relative'>
            <Input
              placeholder='Search modules by name or type'
              className='pl-[40px]'
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
            <div className='absolute top-1/2 ml-[12px] -translate-y-1/2'>
              <Icons.Search className='text-gray-600' />
            </div>
          </div>
          <div className='mt-[16px]'>
            <Label className='flex items-center justify-end gap-[8px]'>
              <Body2 className='text-gray-900'>Show team module only</Body2>
              <AvoSwitch checked={teamOnly} onChange={setTeamOnly} />
            </Label>
          </div>
          <div className='mt-[8px] max-h-[40vh] overflow-y-auto'>
            <table className='w-full border-separate border-spacing-0 overflow-y-auto'>
              {getHeaderGroups().map((headerGroup, idx) => (
                <thead className='sticky top-0 z-10 w-full bg-white' key={idx}>
                  {headerGroup.headers.map((header) =>
                    header.isPlaceholder ? null : (
                      <th
                        key={header.id}
                        className='border-b-2 border-t-2 border-gray-300 px-[8px] py-[16px] text-left'
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    )
                  )}
                </thead>
              ))}
              <tbody>
                {getRowModel().rows.map((row) => (
                  <Fragment key={row.id + row.getValue('type')}>
                    <tr
                      className={twJoin(
                        'w-full border-b border-gray-300',
                        !row.getCanSelect() && 'text-gray-300'
                      )}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className='px-[8px] py-[12px]'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className='flex justify-end gap-[8px] py-[12px]'>
            <div className='flex'>
              <Button.Reverse type='button' onClick={onClose}>
                Cancel
              </Button.Reverse>
            </div>
            <div className='flex min-w-[70px]'>
              <Button type='button' onClick={onSubmit} disabled={selectedRows.rows.length === 0}>
                Add {selectedRows.rows.length > 0 && <>({selectedRows.rows.length})</>}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
