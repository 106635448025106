import { Menu } from '@headlessui/react';
import { Trigger } from 'api/resources';
import clsx from 'clsx';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { hasViewOnlyPermission } from 'utils/permissions';
import { TRIGGER_ALWAYS_ON_TEXT } from 'v2/utils/constant';
import { UIElement } from '../../../api/moduleElement';
import { AlgoMoreIcon } from '../../../assets/icons/algoCardIcons';
import { MODULE_TYPES } from '../../../constants';
import { VariablesPreview } from '../../app-previews/common/variable';
import AmbientListeningForm, { AmbientListeningTypeValue } from '../../panels/AmbientListeningForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { Body2, H4 } from '../../utils/typo';
import { ModuleElementContext } from '../ModuleBoard';

interface FormValues {
  id?: string;
  title: string;
  subtitle: string;
  variable: string;
  container: any;
  trigger?: Trigger;
  module?: number;
  position?: number;
  is_optional: boolean;
  type: AmbientListeningTypeValue;
}

interface AmbientListeningCardProps {
  element: UIElement;
  containerId?: number;
}

export const AmbientListeningCard = ({ element, containerId }: AmbientListeningCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo<FormValues>(
    () => ({
      title: element.title ?? '',
      subtitle: element.subtitle ?? '',
      variable: element.variable_item ? element.variable_item.name : '',
      container: element.container,
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      position: element.position,
      is_optional: !!element.is_optional,
      type: element.type ?? 'H_N_P',
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const trigger = form.watch('trigger');
  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const variable = form.watch('variable');
  const variables = useMemo(
    () => [
      {
        type: 'Scribe',
        name: variable,
      },
    ],
    [variable]
  );

  return (
    <>
      <div
        data-testid='ambient_listening__test'
        className={clsx(
          '!mb-4 max-h-fit',
          panelOpen ? 'relative z-[30]' : '',
          isContainer ? 'w-full' : 'max-w-[580px]'
        )}
      >
        <div
          className={clsx(
            'item-to-hover max-h-fit !rounded-xl !border-2 border-none !p-4 shadow-06 hover:!bg-primary-200',
            isContainer
              ? 'w-full !border-none !bg-transparent !shadow-none'
              : '!bg-hasViewOnlyPerhasViewOnlyPermissionmissionwhite max-w-[580px] !bg-opacity-90',
            panelOpen ? 'dark:border-08A88E  !border-primary-600' : ''
          )}
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
        >
          <div className='!m-0 !p-0'>
            <div>
              <div className='right item-to-show'>
                <Menu>
                  {!hasViewOnlyPermission(module?.type) && (
                    <Menu.Button>
                      <AlgoMoreIcon />
                    </Menu.Button>
                  )}
                  <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
                    <Menu.Item
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      as='p'
                      onClick={() => setPanelOpen(true)}
                    >
                      <Body2>Edit</Body2>
                    </Menu.Item>
                    <Menu.Item
                      as='p'
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      onClick={() => setDeleteModal(true)}
                    >
                      <Body2>Delete</Body2>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>

              {!(isContainer && trigger?.title === TRIGGER_ALWAYS_ON_TEXT) && (
                <div className='flex flex-row items-center'>
                  <span className='max-w-fit rounded-sm bg-green-500 px-2 py-[4px] text-caption-2 text-white'>
                    {trigger
                      ? isContainer
                        ? trigger.title !== 'Always On' &&
                          (trigger.title || 'Title is not assigned')
                        : trigger.title || 'Title is not assigned'
                      : 'Not Assigned'}
                  </span>
                </div>
              )}
              <div className='flex text-center'>
                <H4 className='my-2'>{form.watch('title')}</H4>
                {form.watch('is_optional') && (
                  <Body2 className='ml-1 self-center pt-0.5 text-[#566267]'>(Optional)</Body2>
                )}
              </div>

              <Body2 className='text-gray-900'>{form.watch('subtitle')}</Body2>
            </div>
          </div>
        </div>

        {!isContainer && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal((prev) => !prev)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='h-full w-[620px] overflow-x-visible bg-white px-[16px]'>
          <AmbientListeningForm
            handleSelectedItem={() => {}}
            startPreview={() => {}}
            handleAddTitle={(title: string) => form.setValue('title', title)}
            handleAddSubtitle={(subtitle: string) => form.setValue('subtitle', subtitle)}
            handleAddVariable={(variable: string) => form.setValue('variable', variable)}
            handleAddTrigger={(triggerId: string) => setTriggerId(triggerId)}
            handleAmbientListeningOptional={(isOptional: boolean) =>
              form.setValue('is_optional', isOptional)
            }
            moduleId={module?.id}
            container={form.watch('container')}
            position={element.position}
            toggleModal={handlePanelClose}
            ambId={!!element.id ? String(element.id) : ''}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
