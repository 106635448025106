import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  withStyles,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import axios from 'axios';
import SectionDropdown from 'components/utils/SectionDropdown';
import { CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  CALCULATOR_API_URL,
  CALC_INTEGRATION_API_URL,
  MODULE_API_URL,
  SAVE_BUTTON,
} from '../../constants';
import CategoricalOverride from './categoricalOverride';
import NumericOverride from './numericalOverride';
import { CalculatorIntegrationFormProps } from './types';
interface StyledMenuItemProps {
  disabled?: any;
}
const StyledMenuItem = withStyles({
  root: {
    color: (props: StyledMenuItemProps) => (props.disabled ? '#d7d7d7' : 'black'),
    opacity: '1 !important',
  },
})(MenuItem);

interface CalculatorIntegrationFormState {
  integrations: any[];
  numericalOutputMappings: any[];
  categoricalOutputMappings: any;
  categoricalArray: any[];
  choiceSearchText: any;
  integrationMenuEl: any;
  choiceOverrideEl: any;
  choices: any[];
  // calculator: {},
  calculator: any;
}
class CalculatorIntegrationForm extends Component<
  CalculatorIntegrationFormProps,
  CalculatorIntegrationFormState
> {
  constructor(props: CalculatorIntegrationFormProps) {
    super(props);

    this.state = {
      integrations: [],
      numericalOutputMappings: [],
      categoricalOutputMappings: {},
      categoricalArray: [],
      choiceSearchText: '',
      integrationMenuEl: null,
      choiceOverrideEl: null,
      choices: [],
      // calculator: {},
      calculator: null,
    };
  }
  componentDidMount() {
    const calc = this.props.teamCalculatorState?.teamCalculators?.find(
      (calc) => calc.id === this.props.calculatorId
    );

    this.setState({ calculator: calc });
    if (this.props.editor) {
      //called when an integration is opened in edit mode,this sets the current categorical & numerical mappings in state to allow editing. states are later passed to backend for update
      this.setState({
        numericalOutputMappings: this.props.editableIntegration.numerical_output_mappings,
        categoricalOutputMappings: this.props.editableIntegration.categorical_output_mappings,
      });
    }
    const url = MODULE_API_URL + this.props.moduleId + '/choice_panels';
    const calcUrl = CALCULATOR_API_URL + this.props.calculatorId + '/?send_complete_data=true';

    axios.get(url).then((res) => this.setState({ choices: res.data }));
    axios.get(calcUrl).then((res) => this.setState({ calculator: res.data }));
  }
  setChoiceSearchText = (text) => {
    this.setState({ choiceSearchText: text });
  };
  // checkValidity = async () => {
  //   let valid = true;
  //   this.state.numericalOutputMappings.forEach((obj) => {
  //     if (obj.numeric_id === null) {
  //       valid = false;
  //     }
  //   });

  //   Object.keys(this.state.categoricalOutputMappings).forEach((key) => {
  //     this.state.categoricalOutputMappings[key].mappings.forEach((obj) => {
  //       if (obj.choice_id === null) valid = false;
  //     });
  //   });
  //   return valid;
  // };
  removeNumericIntegration = (index) => {
    const dummy = this.state.numericalOutputMappings;
    if (index > -1) {
      dummy.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.setState({ numericalOutputMappings: dummy });
  };
  removeCategoricIntegration = (id) => {
    const dummy = this.state.categoricalOutputMappings;
    delete dummy[id];
    this.setState({ categoricalOutputMappings: dummy });
  };
  generateMappings = () => {
    if (
      this.state.numericalOutputMappings.length === 0 &&
      Object.keys(this.state.categoricalOutputMappings).length === 0
    ) {
      toast.error(CustomToast, { data: 'Please add a mapping' });
    } else {
      // this.checkValidity().then((validity) => {
      //   if (validity) {
      let payload = {
        module: this.props.moduleId,
        integrated_calculator: this.props.calculatorId,
        numerical_output_mappings: this.state.numericalOutputMappings,
        categorical_output_mappings: this.state.categoricalOutputMappings,
      };

      if (!this.props.editor) {
        axios.post(CALC_INTEGRATION_API_URL, payload).then((res) => {
          toast.success(CustomToast, { data: 'Integration Created Successfully' });
          this.props.closePanel();
        });
        // .catch((err) => {
        //   console.log(err);
        //   NotificationManager.error('Integration Creation Failed: ', err);
        // });
      } else {
        axios
          .put(CALC_INTEGRATION_API_URL + this.props.editableIntegration.id + '/', payload)
          .then(() => {
            toast.success(CustomToast, { data: 'Integration Updated Successfully' });
            this.props.closePanel();
          });
      }
      // } else {
      //   NotificationManager.error('Mappings are not complete');
      // }
    }
  };
  setMappingBehaviour = (index, behavior) => {
    const copy = this.state.numericalOutputMappings;
    copy[index].behavior = behavior;
    this.setState({ numericalOutputMappings: copy });
  };
  setMappingNumerical = (index, id, custom) => {
    const copy = this.state.numericalOutputMappings;

    if (custom) {
      delete copy[index].numeric_id;
      delete copy[index].numeric_name;

      const isDuplicatedOverrideVar = this.props.integrations.some((integration) => {
        if (this.props.editableIntegration.id === integration.id) return false;
        return integration.numerical_output_mappings.some(
          (numericalMapping) => numericalMapping.custom_numeric_id === id
        );
      });
      const isDuplicatedOverrideVarSelf = copy.find(
        (obj, objIndex) => objIndex !== index && obj.custom_numeric_id === id
      );
      if (isDuplicatedOverrideVar || isDuplicatedOverrideVarSelf) {
        toast.error(CustomToast, { data: 'Mapping exists already' });
        delete copy[index].custom_numeric_id;
        delete copy[index].custom_numeric_name;
        this.setState({ numericalOutputMappings: copy });
      } else {
        copy[index].custom_numeric_id = id;
        this.setState({ numericalOutputMappings: copy });
      }
    } else {
      delete copy[index].custom_numeric_id;
      delete copy[index].custom_numeric_name;

      const isDuplicatedOverrideVar = this.props.integrations.some((integration) => {
        if (this.props.editableIntegration.id === integration.id) return false;
        return integration.numerical_output_mappings.some(
          (numericalMapping) => numericalMapping.numeric_id === id
        );
      });
      const isDuplicatedOverrideVarSelf = copy.find(
        (obj, objIndex) => objIndex !== index && obj.numeric_id === id
      );
      if (isDuplicatedOverrideVar || isDuplicatedOverrideVarSelf) {
        toast.error(CustomToast, { data: 'Mapping exists already' });
        delete copy[index].numeric_id;
        delete copy[index].numeric_name;
        this.setState({ numericalOutputMappings: copy });
      } else {
        copy[index].numeric_id = id;
        this.setState({ numericalOutputMappings: copy });
      }
    }
  };
  setMappingVariableName = (index, behavior) => {
    const copy = this.state.numericalOutputMappings;
    copy[index].behavior = behavior;
    this.setState({ numericalOutputMappings: copy });
  };
  makeChoiceMapping = (choice) => {
    const categoricalMappingCopy = this.state.categoricalOutputMappings;
    const id = choice.id;
    let initialMappings: Array<{
      choice_id: any;
      output_category_id: any;
      choice_name: any;
      output_category_label: any;
    }> = [];
    this.state.calculator?.output?.output_category?.forEach((obj) => {
      const dummyMapping = {
        choice_id: null,
        output_category_id: obj.id,
        choice_name: 'Not Selected',
        output_category_label: obj.label,
      };
      initialMappings.push(dummyMapping);
    });

    categoricalMappingCopy[id] = {
      //choice_panel_name: choice.title,
      mappings: initialMappings,
      //  id: id,
      choice: choice,
    };
    this.setState({
      categoricalOutputMappings: categoricalMappingCopy,
      choiceOverrideEl: null,
      integrationMenuEl: null,
    });
  };
  overrideNumeric = () => {
    let numericalMappingCopy = this.state.numericalOutputMappings;
    let mapping = {
      behavior: 'override',
      numeric_id: null,
      custom_numeric_id: null,
    };
    numericalMappingCopy.push(mapping);
    this.setState({
      numericalOutputMappings: numericalMappingCopy,
      integrationMenuEl: null,
    });
  };
  insertCategoricalMapping = (panelId, choiceId, categoryId, name) => {
    const copy = this.state.categoricalOutputMappings;
    const index = copy[panelId].mappings.findIndex((obj) => obj.output_category_id === categoryId);
    copy[panelId].mappings[index].choice_id = choiceId;
    copy[panelId].mappings[index].choice_name = name;
    this.setState({ categoricalOutputMappings: copy });
  };

  integrationTypeMenu = (el) => {
    el.preventDefault();
    this.setState({ integrationMenuEl: el.target });
  };

  render() {
    const disabled = this.state.numericalOutputMappings.some(
      (numericalMapping) => !numericalMapping.numeric_id && !numericalMapping.custom_numeric_id
    );
    const owner = this.state.calculator?.members?.find((user) => user?.permission_type === 'Owner');
    const categoricalArray: Array<{ id: any }> = [];
    for (const prop in this.state.categoricalOutputMappings) {
      categoricalArray.push(this.state.categoricalOutputMappings[prop]);
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          marginLeft: '-24px',
          //    marginTop: '-30px',
        }}
      >
        <Box
          style={{
            paddingTop: '40px',
            //  overflow: 'scroll',
            width: '522px',
            height: '100%',
            boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.07)',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 36,
              marginTop: 50,
            }}
          >
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '22px',
                lineHeight: '22px',
                color: '#000000',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              Calculator Integration
            </p>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#545454',
              }}
            >
              {(this.state.calculator && this.state.calculator.description) || ''}
            </p>
            <Box
              style={{
                background: '#FFFFFF',
                border: '1px solid #E6E6E6',
                boxSizing: 'border-box',
                borderRadius: '10px',
                width: '442px',
                height: '342px',
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 25,
                paddingBottom: 25,
              }}
            >
              <p className='panel-label-calc'>Name</p>
              <p className='label-desc-calc'>
                {this.state.calculator && this.state.calculator.title}
              </p>
              <p className='panel-label-calc'>Description</p>
              <p className='label-desc-calc'>
                {this.state.calculator && this.state.calculator.description}
              </p>
              <p className='panel-label-calc'>Creator</p>
              <p className='label-desc-calc'>{owner?.user?.name}</p>
              <p className='panel-label-calc'>Output Name</p>
              <p className='label-desc-calc'>
                {this.state.calculator
                  ? this.state.calculator.output
                    ? this.state.calculator.output.name
                    : 'No Output'
                  : '--'}
              </p>
              <p className='panel-label-calc'>Output Types</p>
              <p className='label-desc-calc' style={{ marginBottom: '0px !important' }}>
                {this.state.calculator
                  ? this.state.calculator.output
                    ? this.state.calculator.output.output_type === 'C'
                      ? 'Categorical'
                      : this.state.calculator.output.output_type === 'N'
                      ? 'Numerical'
                      : 'Categorical & Numerical'
                    : 'No Output'
                  : '--'}
              </p>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 20,
            width: 622,
            paddingRight: 10,
            paddingBottom: 40,
            overflow: 'scroll',
            paddingTop: '40px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 65,
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                fontWeight: 'normal',
                fontSize: '22px',
                lineHeight: '22px',
                color: '#000000',
              }}
            >
              {this.state.calculator && this.state.calculator.title}
            </Typography>
            <HighlightOff
              onClick={() => this.props.closePanel()}
              style={{
                color: '#C7C7C7',
                fontSize: 31,
                marginLeft: 'auto',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          {this.state.numericalOutputMappings.map((mapping, index) => (
            <Box style={{ marginBottom: 16 }}>
              <NumericOverride
                customNumerics={this.props.customNumericState.customNumerics}
                removeIntegration={this.removeNumericIntegration}
                key={mapping.id}
                moduleId={this.props.moduleId}
                mapping={mapping}
                index={index}
                setMappingBehaviour={this.setMappingBehaviour}
                setMappingNumerical={this.setMappingNumerical}
                //setMappingVariableName={this.setMappingVariableName}
              />
            </Box>
          ))}
          {categoricalArray.map((obj, index) => (
            <Box style={{ marginBottom: 16 }}>
              <CategoricalOverride
                removeIntegration={this.removeCategoricIntegration}
                key={obj.id}
                // calculator={this.state.calculator && this.state.calculator}
                //moduleId={this.props.moduleId}
                insertCategoricalMapping={this.insertCategoricalMapping}
                // mapping={obj.mapping}
                choicePanel={obj}
                // index={index}

                // setMappingBehaviour={this.setMappingBehaviour}
                // setMappingNumerical={this.setMappingNumerical}
                // setMappingVariableName={this.setMappingVariableName}
              />
            </Box>
          ))}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              background: '#FFFFFF',
              border: '1px solid #08A88E',
              boxSizing: 'border-box',
              boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
              borderRadius: '6px',
              minHeight: 54,
              color: '#08A88E',
              paddingLeft: '26px',
              paddingRight: '17px',
              alignItems: 'center',
              fontSize: '18px',
              cursor: 'pointer',
              marginBottom: 30,
            }}
            onMouseDown={this.integrationTypeMenu}
          >
            {' '}
            Add Integration
            <AddCircleOutlineIcon style={{ marginLeft: 'auto', color: '#08A88E' }} />
          </Box>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                background: '#FFFFFF',
                border: '1px solid #E7E7E7',
                boxSizing: 'border-box',
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
                borderRadius: '6px',
              },
            }}
            getContentAnchorEl={null}
            anchorEl={this.state.integrationMenuEl}
            open={Boolean(this.state.integrationMenuEl)}
            onClose={() => this.setState({ integrationMenuEl: null })}
          >
            <MenuList autoFocus>
              <StyledMenuItem className='border-to-hover' disabled>
                <span
                  className='border-to-change'
                  style={{
                    borderBottom: '1px solid',
                    width: '100%',
                    paddingBottom: '6px',
                  }}
                >
                  Build a New Variable (kg)
                </span>
              </StyledMenuItem>
              <StyledMenuItem
                className='border-to-hover'
                onClick={this.overrideNumeric}
                disabled={
                  this.state.calculator
                    ? this.state.calculator.output
                      ? !Boolean(this.state.calculator.output.output_value)
                      : false
                    : false
                }
              >
                <span
                  className='border-to-change'
                  style={{
                    borderBottom: '1px solid #ECECEC',
                    borderColor: '#ECECEC',
                    width: '100%',
                    paddingBottom: '6px',
                  }}
                >
                  Override a Numeric Variable (kg)
                </span>
              </StyledMenuItem>
              <StyledMenuItem
                disabled={
                  this.state.calculator
                    ? this.state.calculator.output
                      ? !Boolean(this.state.calculator.output.output_category.length > 0)
                      : false
                    : false
                }
                onClick={(e) => this.setState({ choiceOverrideEl: e.target })}
              >
                Override/mapping for Choice Button
                <div style={{ marginLeft: 'auto' }}>
                  <ChevronRightIcon />
                </div>
              </StyledMenuItem>
            </MenuList>
          </Popover>
          <Popover
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                background: '#FFFFFF',
                border: '1px solid #E7E7E7',
                boxSizing: 'border-box',
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
                borderRadius: '6px',
                width: 236,
              },
            }}
            getContentAnchorEl={null}
            anchorEl={this.state.choiceOverrideEl}
            open={Boolean(this.state.choiceOverrideEl)}
            onClose={() => this.setState({ choiceOverrideEl: null })}
          >
            <SectionDropdown
              dense
              items={this.state.choices}
              searchText={this.state.choiceSearchText}
              setSearchText={this.setChoiceSearchText}
              itemClick={this.makeChoiceMapping}
            />
          </Popover>
          <CreateButton
            onClick={() => this.generateMappings()}
            style={{ minHeight: '60px', marginLeft: 'auto', marginTop: 'auto' }}
            disabled={disabled}
          >
            {SAVE_BUTTON}
          </CreateButton>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(CalculatorIntegrationForm);
