import { Box, Button, MenuItem, MenuList, Popover, Typography } from '@material-ui/core';
import { Close, KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import axios from 'axios';
import { Component } from 'react';
import { MODULE_API_URL } from '../../constants';
import { NumericOverrideProps } from './types';
interface NumericOverrideState {
  numericEl: any;
  numerics: any[];

  selectedNumeric: any;
}
export default class NumericOverride extends Component<NumericOverrideProps, NumericOverrideState> {
  constructor(props) {
    super(props);
    this.state = {
      numericEl: null,
      numerics: [],

      selectedNumeric: '',
    };
  }
  componentDidMount() {
    const url = MODULE_API_URL + this.props.moduleId + '/numerics';
    axios.get(url).then((res) => this.setState({ numerics: res.data }));
  }
  closeNumericDropdown = () => {
    this.setState({ numericEl: null });
  };
  render() {
    return (
      <div
        className='item-to-hover'
        style={{
          background: '#FFFFFF',
          border: '1px solid #E6E6E6',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
          borderRadius: '10px',
          width: '100%',
          // height: 227,
          padding: 28,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              // lineHeight: '36px',
              textTransform: 'uppercase',
              color: '#8C94A1',
              marginBottom: 15,
            }}
          >
            Numerical Output
          </Typography>
          <Close
            className='item-to-show'
            style={{
              color: '#C7C7C7',
              marginLeft: 'auto',
              marginTop: '-40px',
              marginRight: '-10px',
              fontSize: 20,
              cursor: 'pointer',
            }}
            onClick={() => this.props.removeIntegration(this.props.index)}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            alignItems: 'center',
          }}
        >
          <Typography className='numeric-label'> Behavior</Typography>

          <Button
            style={{
              minWidth: '108px',
              marginLeft: 64,
              marginRight: 10,
              height: '34px',
              background: '#08A88E',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
              borderRadius: '50px',
              color: 'white',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '15px',
              lineHeight: '15px',
              textTransform: 'none',
            }}
            onClick={() => this.props.setMappingBehaviour(this.props.index, 'new var')}
          >
            Override
          </Button>
          <Button
            disabled
            style={{
              width: '170px',
              height: '34px',

              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
              borderRadius: '50px',
              color: '#949494',
              textTransform: 'none',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '15px',
              lineHeight: '15px',

              background: '#FFFFFF',
              opacity: 0.3,
              border: '1px solid #D9D9D9',
            }}
          >
            New Variable
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            alignItems: 'center',
          }}
        >
          {' '}
          <Typography className='numeric-label'> Overriding Var</Typography>{' '}
          <Box
            style={{
              background: '#FFFFFF',
              border: '1px solid #CCCCCC',
              display: 'flex',
              marginLeft: 25,
              alignItems: 'center',
              boxSizing: 'border-box',
              borderRadius: '5px',
              width: '288px',
              paddingLeft: 18,
              //   paddingTop: 10,
              //   paddingBottom: 10,
              paddingRight: 10,
              height: '37px',
              cursor: 'pointer',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              this.setState({ numericEl: e.target });
            }}
          >
            {this.state.selectedNumeric
              ? this.state.selectedNumeric
              : this.props.mapping.numeric_name
              ? this.props.mapping.numeric_name
              : this.props.mapping.custom_numeric_name
              ? this.props.mapping.custom_numeric_name
              : 'Numeric/ Custom Numeric'}
            {Boolean(this.state.numericEl) ? (
              <KeyboardArrowUp style={{ marginLeft: 'auto', color: '#A6A6A6' }} />
            ) : (
              <KeyboardArrowDown style={{ marginLeft: 'auto', color: '#A6A6A6' }} />
            )}
          </Box>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            anchorEl={this.state.numericEl}
            open={Boolean(this.state.numericEl)}
            onClose={() => this.setState({ numericEl: null })}
          >
            <MenuList autoFocus>
              {this.state.numerics &&
                this.state.numerics.map((numeric, index) => (
                  <MenuItem
                    key={numeric.id}
                    divider={this.state.numerics[index + 1]}
                    style={{ width: '288px' }}
                    onClick={() => {
                      this.props.setMappingNumerical(this.props.index, numeric.id);
                      this.setState({
                        numericEl: null,
                        selectedNumeric: numeric.title,
                      });
                    }}
                  >
                    {numeric.title}
                  </MenuItem>
                ))}
              <MenuItem disabled> Custom Numerics</MenuItem>
              {this.props.customNumerics &&
                this.props.customNumerics.map((numeric, index) => (
                  <MenuItem
                    key={numeric.id}
                    divider={this.props.customNumerics[index + 1]}
                    style={{ width: '288px' }}
                    onClick={() => {
                      this.props.setMappingNumerical(this.props.index, numeric.id, true);
                      this.setState({
                        numericEl: null,
                        selectedNumeric: numeric.name,
                      });
                    }}
                  >
                    {numeric.name}
                  </MenuItem>
                ))}
            </MenuList>
          </Popover>
        </Box>
      </div>
    );
  }
}
