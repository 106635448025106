import { Icons } from 'components/utils/Icons';
import { twJoin } from 'tailwind-merge';

interface ExpandButtonProps {
  expanded: boolean;
  onClick: () => void;
}

export const ExpandButton = ({ expanded, onClick }: ExpandButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={twJoin(
        'focus:bg-white', // due to material UI
        'absolute right-0 top-1/2 z-20 flex -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full border border-gray-300 bg-white p-[8px] transition-all',
        !expanded && 'rotate-180'
      )}
    >
      <Icons.ChevronLeft />
    </button>
  );
};
