import { useQuery } from '@tanstack/react-query';
import { mentionAPI, ModuleSuggestions, ResourceSuggestions } from 'api/mention';
import { resourcesAPI } from 'api/resources';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext } from 'react';
import {
  getCustomNumericSuggestions,
  getFormulaSuggestions,
  getInfoboxSuggestions,
  getMediaSuggestions,
  getReferenceSuggestions,
} from 'utils/suggestions';
import { MODULE_TYPES } from '../constants';
import {
  demographics,
  medications,
  problemLists,
  useLabDataValue,
  useNotesValue,
  useVitalSignsValue,
} from './useEHRVariables';
import { useTeam } from './useTeam';
import { useAuthentication } from './useAuthentication';
import { ehrAPI } from '../api/ehr';
import { SuggestionTypes } from '../components/utils/tiptap/tiptapInterfaces';

const DefaultModuleSuggestions: Readonly<ModuleSuggestions> = {
  [MODULE_TYPES.ALGO]: [],
  [MODULE_TYPES.CALCULATOR]: [],
  [MODULE_TYPES.KNOWLEDGE_BASE]: [],
  mirrors: [],
};

const DefaultResourceSuggestions: Readonly<ResourceSuggestions> = {
  conditional_text: [],
  formula: [],
  infobox: [],
  numeric: [],
  reference: [],
  variable: [],
};

export interface Suggestion {
  code: string;
  unique_code?: string;
  name: string;
  type: SuggestionTypes;
  [key: string]: any;
}

export const useSuggestions = (): Suggestion[] => {
  const { authentication } = useAuthentication();
  const { team } = useTeam();
  const { module: selectedModule } = useContext(ModuleContext);
  const notes = useNotesValue();
  const labData = useLabDataValue();
  const vitalSigns = useVitalSignsValue();

  const { data: resourcesSuggestions } = useQuery(
    ['module', selectedModule?.type, selectedModule?.id, 'resources'],
    mentionAPI.getResourcesSuggestions,
    {
      initialData: DefaultResourceSuggestions,
      refetchOnWindowFocus: false,
    }
  );

  const { data: modulesSuggestions } = useQuery(
    ['team', team?.id, 'resources'],
    mentionAPI.getModulesSuggestions,
    {
      initialData: DefaultModuleSuggestions,
      refetchOnWindowFocus: false,
      select: (data): ModuleSuggestions => {
        return Object.keys(data).reduce((prev, cur) => {
          return {
            ...prev,
            [cur]:
              cur !== 'mirrors'
                ? data[cur].map((item) => ({
                    code: item.code,
                    id: item.id,
                    type: item.type,
                    name: item.title,
                  }))
                : [...data[cur]],
          };
        }, DefaultModuleSuggestions);
      },
    }
  );

  // for individual order mention
  const { data: orderSuggestions } = useQuery(
    ['team', 'medications', 'individual'],
    ehrAPI.getMedications,
    {
      select: (medications): Suggestion[] =>
        medications.map((medication) => ({
          ...medication,
          type: 'ehr_order',
          last_updated: undefined,
        })),
      initialData: [],
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
      enabled: !!authentication.user?.is_staff, // TODO: beta feature
    }
  );

  // add mirrors under respective module type
  const algoCodes = modulesSuggestions.algo.map((item) => item.code);
  const calculatorCodes = modulesSuggestions.calculator.map((item) => item.code);
  const kbCodes = modulesSuggestions.knowledge_base.map((item) => item.code);

  modulesSuggestions.mirrors.map((mirror) => {
    const { module } = mirror;
    const mirrorType = module?.type;
    const code = module?.code;
    const mirrorModule = {
      code: module?.code,
      id: module!.id,
      type: 'link' as SuggestionTypes,
      name: module?.title,
      is_in_current_team: mirror.is_in_current_team,
      last_updated: mirror.last_updated,
    };
    switch (mirrorType) {
      case MODULE_TYPES.ALGO:
        if (!algoCodes?.includes(code)) {
          modulesSuggestions.algo.push(mirrorModule);
        }
        break;
      case MODULE_TYPES.CALCULATOR:
        if (!calculatorCodes?.includes(code)) {
          modulesSuggestions.calculator.push(mirrorModule);
        }
        break;
      case MODULE_TYPES.KNOWLEDGE_BASE:
        if (!kbCodes?.includes(code)) {
          modulesSuggestions.knowledge_base.push(mirrorModule);
        }
        break;
      default:
        break;
    }
  });

  const suggestions = {
    ...resourcesSuggestions,
    ...modulesSuggestions,
    orderSuggestions,
    notes,
    labData,
    vitalSigns,
    medications,
    problemLists,
    demographics,
  };
  const { mirrors, ...restSuggestions } = suggestions;

  return Object.values(restSuggestions).flat();
};

export const useDraftJSSuggestions = () => {
  const formulaSuggestions = getFormulaSuggestions();
  const customNumericSuggestions = getCustomNumericSuggestions();
  const referenceSuggestions = getReferenceSuggestions();
  const mediaSuggestions = getMediaSuggestions();
  const infoboxSuggestions = getInfoboxSuggestions();

  let suggestions = [
    ...formulaSuggestions,
    ...customNumericSuggestions,
    ...referenceSuggestions,
    ...mediaSuggestions,
    ...infoboxSuggestions,
  ];

  const { module: selectedModule } = useContext(ModuleContext);
  const { data: moduleVariables = [], isLoading } = useQuery(
    ['module-variables', selectedModule?.type, selectedModule?.id, 'for_text_editor'],
    resourcesAPI.getVariables,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: moduleNumerics = [], isLoading: numericLoading } = useQuery(
    ['module-numerics', selectedModule?.type, selectedModule?.id],
    resourcesAPI.getNumerics,
    {
      refetchOnWindowFocus: false,
    }
  );

  suggestions.push({
    name: 'VARIABLES',
    code: 'VARIABLES',
  });

  moduleVariables.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.name,
      type: obj.type,
    });
  });

  suggestions.push({
    name: 'NUMERICS',
    code: 'NUMERICS',
  });

  moduleNumerics.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.title,
    });
  });

  return {
    suggestions,
    moduleNumerics,
    moduleVariables,
  };
};
