import * as React from 'react';

function EditIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.669 2.803L17.159.317a1.166 1.166 0 00-1.6 0l-2.232 2.217H1.357c-.36 0-.705.142-.96.394a1.337 1.337 0 00-.397.95v14.779c0 .356.143.698.397.95.255.251.6.393.96.393h14.92c.36 0 .705-.142.96-.393.254-.252.397-.594.397-.95V6.404l2.035-2.016a1.116 1.116 0 000-1.585zm-9.61 9.223l-2.843.625.679-2.788 6.477-6.429 2.19 2.17-6.504 6.422zm7.236-7.188l-2.19-2.17 1.254-1.242 2.19 2.17-1.254 1.242z'
        fill='#08A88E'
      />
    </svg>
  );
}

export default EditIcon;
