import { ModuleLayout } from 'components/layout/ModuleLayout';
import { SmartNoteResourceTable } from 'components/resources/smart-note/SmartNoteTable';

export const SmartNotes = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <SmartNoteResourceTable />
    </ModuleLayout>
  );
};
