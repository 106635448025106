import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    zIndex: 1200,
    backgroundColor: '#fff',
    height: '100%',
    paddingBottom: '0px !important',
    marginBottom: '0px !important',
    borderRadius: '40px 40px 0px 0px !important',
    marginLeft: '20px',
  },
  textBox: {
    margin: 'auto',
    marginTop: '50px',
    width: '800px',
    textAlign: 'center',
  },
}));
