import { Dialog, Popover, Transition } from '@headlessui/react';
import React, {
  forwardRef,
  Fragment,
  HTMLAttributes,
  ReactNode,
  Ref,
  useEffect,
  useState,
} from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { createPortal } from 'react-dom';
import { PANEL_Z_INDEX } from '../../../v2/utils/constant';

interface PanelState {
  modalOpen: boolean;
  closeModal: () => void;
  closePanel: () => void;
}

interface SlidePanelProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode | ((panelState: PanelState) => ReactNode);
  className?: string;
  id?: string;
}

const Backdrop = ({ onClose }) =>
  createPortal(
    <div onClick={onClose} className='fixed inset-0 z-20 bg-white/80' aria-hidden='true' />,
    document.body
  );

// TODO: need to work for nested panel
const SlidePanelDialog = forwardRef<any, any>(function (props, ref) {
  return <Dialog className='fixed z-30' ref={ref} {...props} />;
});

export const SlidePanel = ({ open, onClose, children, className, id }: SlidePanelProps) => {
  const isUsedModal = typeof children === 'function';
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const closeModal = () => setModalOpen(false);
  const closePanel = () => {
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    if (!isUsedModal && modalOpen) {
      closePanel();
    }
  }, [modalOpen, isUsedModal]);

  return (
    <>
      {open && <Backdrop onClose={onClose} />}
      <Transition as={SlidePanelDialog} show={open} onClose={() => setModalOpen(true)}>
        <div className='fixed right-0 top-0'>
          <Transition.Child
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
            as={Dialog.Panel}
          >
            <div
              className={twJoin('relative h-screen pt-[51px]', PANEL_Z_INDEX)}
              id={id ?? 'slide-panel'}
            >
              <div className={twMerge('h-full overflow-y-scroll bg-white shadow-06', className)}>
                {typeof children === 'function'
                  ? children({ modalOpen, closeModal, closePanel })
                  : children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

interface SlidePanelChildPortalProps {
  children: ReactNode;
  parentId?: string;
}

const SlidePanelChildPortal = ({
  children,
  parentId = 'slide-panel',
}: SlidePanelChildPortalProps) => {
  const container =
    parentId && !!document.getElementById(parentId)
      ? document.getElementById(parentId)!
      : document.body;
  return createPortal(<>{children}</>, container);
};

SlidePanel.Child = ({ children }: HTMLAttributes<HTMLDivElement>) => <Popover>{children}</Popover>;

interface SlidePanelChildButtonProps extends HTMLAttributes<HTMLDivElement> {
  ref?: Ref<HTMLButtonElement>;
}
SlidePanel.ChildButton = forwardRef<HTMLButtonElement, SlidePanelChildButtonProps>(
  ({ children, className, ...props }, ref) => (
    <Popover.Button className={twMerge('cursor-pointer', className)} ref={ref}>
      {children}
    </Popover.Button>
  )
);

interface SlidePanelChildBodyProps {
  children: ReactNode | ((close: () => void) => ReactNode);
  className?: string;
  parentId?: string;
  id?: string;
}

SlidePanel.ChildBody = ({ children, className, parentId, id }: SlidePanelChildBodyProps) => (
  <>
    <SlidePanelChildPortal parentId={parentId}>
      <Transition
        enter='transition ease-in-out duration-300 transform'
        enterFrom='translate-x-0'
        enterTo='-translate-x-full'
        leave='transition ease-in-out duration-300 transform'
        leaveFrom='translate-x-0'
        leaveTo='translate-x-full'
        as={Fragment}
      >
        <Popover.Panel className='absolute left-0 top-0 -z-10 h-full -translate-x-full pt-[51px]'>
          {({ close }) => (
            <div className='relative h-full bg-white' id={id}>
              <div className={twMerge('h-full overflow-y-scroll shadow-06', className)}>
                {typeof children === 'function' ? children(close) : children}
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>

      <Popover.Overlay className='fixed inset-0 -z-20' />
    </SlidePanelChildPortal>
  </>
);
