import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import React from 'react';

import Entry from 'components/utils/draftJS/mentionEntry';
import {
  getCustomNumericSuggestions,
  getFormulaSuggestions,
  getNumericSuggestions,
  getVariableSuggestions,
} from 'utils/suggestions';

/*
 * TODO: there are 3 similar name files
 *
 * components/utils/draftJS/autoSuggest.js
 *  => this one is normal draftjs editor
 *
 * components/calculator/sections/AutoSuggestTextInput.tsx
 * components/resources/triggers/AutoSuggestTextInput.js
 *  => these 2 are for one line text input field with mention feature
 */

interface TextInputProps {
  conditionContent: any;
  calculatorId: string;
  setCondition: Function;
  isReadOnly: boolean;
  width: number;
  placeholder?: string;
}

interface TextInputState {
  editorState: any;
  suggestions: any[];
  choices: any[];
  suggestions2: any[];
  choices2: any[];
  flag: boolean;
  openVariablesMentionPopup: boolean;
  openNumMentionPopup: boolean;
}

export class AutoSuggestTextInputCondition extends React.Component<TextInputProps, TextInputState> {
  mentionPlugin: any;
  mentionPlugin2: any;
  editorRef: any;
  constructor(props: TextInputProps) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: [],
      choices: [],
      suggestions2: [],
      choices2: [],
      flag: true,
      openVariablesMentionPopup: false,
      openNumMentionPopup: false,
    };

    this.mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      entityMutability: 'IMMUTABLE',
      mentionComponent: (mentionProps) => {
        let ignoreKWs = ['VARIABLES', 'FORMULAE', 'NUMERICS'];
        if (ignoreKWs.includes(mentionProps.mention.code)) return null;
        return (
          <span>
            <span
              style={{
                textDecorationLine: 'underline',
                color: mentionProps.mention.code.includes('_delet_code') ? '#FF0000' : '#08CA6B',
              }}
            >
              {mentionProps.children}
            </span>
          </span>
        );
      },
    });

    this.mentionPlugin2 = createMentionPlugin({
      mentionTrigger: '$',
      entityMutability: 'IMMUTABLE',
      mentionComponent: (mentionProps) => {
        return (
          <span>
            <span style={{ background: 'gainsboro' }}>{mentionProps.children}</span>
          </span>
        );
      },
    });
  }

  componentDidUpdate() {
    const condition =
      this.props.conditionContent !== 'undefined' && this.props.conditionContent !== ''
        ? this.props.conditionContent
        : null;

    if (condition && this.state.flag) {
      let editorState = EditorState.createWithContent(convertFromRaw(condition));
      this.setState({ editorState: editorState, flag: false });
    }
  }

  async componentDidMount() {
    if (this.props.isReadOnly) return;
    const formulaSuggestions = getFormulaSuggestions();
    const numericSuggestions = await getNumericSuggestions(this.props.calculatorId, 'calculator');
    const customNumericSuggestions = getCustomNumericSuggestions();
    const variableSuggestions = await getVariableSuggestions(this.props.calculatorId, 'calculator');

    const suggestions = [
      ...formulaSuggestions,
      ...numericSuggestions,
      ...customNumericSuggestions,
      ...variableSuggestions,
    ];

    let values: Array<{}> = [];
    for (let j = 0; j < 10; j++) {
      values.push({ code: j, name: j });
    }

    this.setState({
      suggestions: suggestions,
      suggestions2: values,
      choices2: values,
      choices: suggestions,
    });
  }

  onMentionOpenChange = (open, varName) =>
    this.setState({ [varName]: open } as Pick<TextInputState, any>);

  onChange = (editorState) => {
    this.setState({ editorState });
    this.props.setCondition(this.onExtractData());
  };

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List' ? list.size : list.length);

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) => !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1
    );
    const length = size(filteredSuggestions) < 100 ? size(filteredSuggestions) : 100;
    return filteredSuggestions.slice(0, length);
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: this.customSuggestionsFilter(value, this.state.choices),
    });
  };

  onSearchChange2 = ({ value }) => {
    this.setState({
      suggestions2: this.customSuggestionsFilter(value, this.state.choices2),
    });
  };

  onExtractData = () => {
    const contentState = this.state.editorState.getCurrentContent();
    return convertToRaw(contentState);
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const MentionSuggestions2 = this.mentionPlugin2.MentionSuggestions;
    const plugins = [this.mentionPlugin, this.mentionPlugin2];

    let editorStyle = {
      background: this.props.isReadOnly ? '#F4F4F4' : '#FFFFFF',
      opacity: this.props.isReadOnly && '0.4',
      boxSizing: 'border-box',
      border: '1px solid #cccccc',
      cursor: 'text',
      width: this.props.width,
      height: 50,
      borderRadius: 5,
      padding: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

    return (
      <div style={editorStyle as React.CSSProperties} onClick={() => this.editorRef.focus()}>
        <Editor
          ref={(ref) => (this.editorRef = ref)}
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          readOnly={this.props.isReadOnly}
          placeholder={this.props.placeholder}
        />
        <MentionSuggestions
          open={this.state.openVariablesMentionPopup}
          onOpenChange={(open) => this.onMentionOpenChange(open, 'openVariablesMentionPopup')}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          entryComponent={Entry}
        />
        <MentionSuggestions2
          open={this.state.openNumMentionPopup}
          onOpenChange={(open) => this.onMentionOpenChange(open, 'openNumMentionPopup')}
          onSearchChange={this.onSearchChange2}
          suggestions={this.state.suggestions2}
          entryComponent={Entry}
        />
      </div>
    );
  }
}
