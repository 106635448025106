import { knowledgebaseAPI } from 'api/knowledgebase';
import axios from 'axios';
import { Dispatch } from 'redux';
import { TEAM_API_URL } from '../../constants';
import { GET_KNOWLEDGE_BASE, GET_KNOWLEDGE_BASE_LIST, SET_IS_KB_MIRROR } from '../actionsTypes';
import { TEAM_QUERY_KEY } from '../../hooks/useTeam';
import { TeamWithMates } from '../../api/team';
import { queryClient } from '../../queryClient';

const knowledgeBaseListAction = () => async (dispatch: Dispatch, getState: any) => {
  const team = queryClient.getQueryData<TeamWithMates>([TEAM_QUERY_KEY]);
  const res = await axios.get(TEAM_API_URL + team?.id + '/knowledge_bases');

  dispatch({
    type: GET_KNOWLEDGE_BASE_LIST,
    payload: res?.data || [],
  });
};

export const getKnowledgeBase =
  (kbID: string, mirrorId: string | null = null) =>
  async (dispatch: Dispatch) => {
    const res = await knowledgebaseAPI.get(kbID, mirrorId);
    dispatch({
      type: GET_KNOWLEDGE_BASE,
      payload: res.data,
    });
  };

type MirrorState = {
  isMirror?: boolean;
  mirrorId?: string;
  mirrorName?: string;
};

export const setKnowledgeMirrorState =
  ({ isMirror, mirrorId, mirrorName }: MirrorState) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SET_IS_KB_MIRROR, isMirror, mirrorId, mirrorName });
  };

export default knowledgeBaseListAction;
