import { ToastContentProps, TypeOptions } from 'react-toastify';
export const CustomToast = ({ toastProps, data }: ToastContentProps<string>) => {
  const title = toastProps.type.charAt(0).toUpperCase() + toastProps.type.slice(1);
  return (
    <>
      <p className='mb-0 py-1 text-base font-bold leading-5 tracking-[0.25px] text-[#1E1F20]'>
        {title}
      </p>
      <p className='mt-1 text-base font-normal leading-4 text-[#566267]'>{data}</p>
    </>
  );
};
type ContextClass = Record<TypeOptions, string>;
export const contextClass: ContextClass = {
  success: 'before:bg-[#5cb85c]',
  error: 'before:bg-[#d9534f]',
  info: 'before:bg-[#3498db]',
  warning: 'before:bg-[#ff8e3d]',
  default: 'before:bg-[#4598db],',
};
