export default function Arrow(props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Caret'>
        <path id='Vector' d='M10 13L6 8H14L10 13Z' fill='#6C7C83' />
      </g>
    </svg>
  );
}
