import { Checkbox, MenuList, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { StyledMenuItemV2 } from 'components/teams/styles';
import { greenCheckbox } from 'components/utils/styled-components/FormStyle';
import { DropdownIcon } from 'components/utils/styled-components/icons';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import './inputComponents.styles.css';

function SimulatorMultiDropdown({
  values,

  code,
  previewContent,
  setPreviewContent,
  arrayIndex,
  entityKey,
  name,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedArray, setSelectedArray] = useState<any[]>([]);

  const handleChoiceInput = (value: any) => {
    if (selectedArray.includes(value)) {
      setSelectedArray((arr) => arr.filter((obj) => obj !== value));
    } else {
      setSelectedArray((oldArr) => [...oldArr, value]);
    }
  };

  useEffect(() => {
    const content = previewContent;
    entityKey.forEach((ind) => {
      if (content?.entityMap[ind]) {
        content.entityMap[ind].data.mention.name = selectedArray.toString();

        map(content.entityMap, (value, key) => {
          if (value.data.mention.code.includes(`${content.entityMap[ind].data.mention.code}/`)) {
            content.entityMap[key].data.mention.name = selectedArray.length;
          }
        });

        setPreviewContent(content);
      }
    });
  }, [selectedArray]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginLeft: 'auto' }}>
      <div
        className='typed-input'
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={(e) => handleClick(e)}
      >
        <div className='multiCount-chip'>
          {selectedArray.length}{' '}
          <Close
            style={{ color: 'white', fontSize: 13, cursor: 'pointer' }}
            onClick={() => setSelectedArray([])}
          />
        </div>

        <DropdownIcon style={{ marginLeft: 'auto', color: '#6C7C83', marginRight: 13 }} />
      </div>
      <Popover
        PaperProps={{ style: { width: 160 } }}
        // style={{ width: 200 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuList autoFocusItem>
          {values?.map((value) => (
            <StyledMenuItemV2
              style={{
                height: 30,
                paddingLeft: 12,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#1E1F20',
                padding: 0,
                paddingRight: 5,
              }}
              onClick={() => {
                handleChoiceInput(value);
              }}
            >
              <Checkbox
                size='small'
                checked={selectedArray.includes(value)}
                style={greenCheckbox}
              />
              {value}{' '}
            </StyledMenuItemV2>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
}

export default SimulatorMultiDropdown;
