import getTeamCalculators from 'actions/calculators/getTeamCalculators';
import getKnowledgeBases from 'actions/knowledge-base/getKnowledgeBase';
import getMirrors from 'actions/mirrors/mirrorList';
import getModules from 'actions/modules/modulesAction';
import { TEAM_QUERY_KEY } from '../../hooks/useTeam';
import { queryClient } from '../../queryClient';

const setTeam =
  (refreshResources = true) =>
  async (dispatch, getState) => {
    const team = queryClient.getQueryData([TEAM_QUERY_KEY]);
    const selectedTeamId = team?.id;
    if (!selectedTeamId) return;

    const modules = getState().modulesState?.modules || [];
    if (refreshResources) {
      // if modules are cached then send async request
      if (modules.length > 0) {
        dispatch(getModules());
        dispatch(getKnowledgeBases());
        dispatch(getTeamCalculators());
        dispatch(getMirrors());
      } else {
        await dispatch(getModules());
        await dispatch(getKnowledgeBases());
        await dispatch(getTeamCalculators());
        await dispatch(getMirrors());
      }
    }
  };

export default setTeam;
