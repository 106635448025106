import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { EHR_MEDICATIONS } from '../constants';
import { JSONContent } from '@tiptap/react';

export interface Medication {
  unique_code: string;
  name: string;
  code: string;
  indication: JSONContent | null;
  description?: string;
  last_updated: string;
}

export interface MedicationRequest {
  unique_code?: string;
  name: string;
  code: string;
  indication: JSONContent | null;
  description?: string;
}

export const ehrAPI = {
  getMedications: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<Medication[]>(EHR_MEDICATIONS + (!!queryKey[3] ? queryKey[3] + '/' : ''))
      .then((res) => res.data),
  upsertMedication: (request: MedicationRequest) =>
    !!request.unique_code
      ? axios
          .put(EHR_MEDICATIONS + request.unique_code + '/', { ...request, unique_code: undefined })
          .then((res) => res.data)
      : axios.post(EHR_MEDICATIONS, request).then((res) => res.data),
  deleteMedication: (unique_code: string) =>
    axios.delete(EHR_MEDICATIONS + unique_code + '/').then((res) => res.data),
};
