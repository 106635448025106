import { Box, InputLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const StyledBox = withStyles(() => ({
  root: {
    display: 'flex',
  },
}))(Box);

export const FileName = withStyles(() => ({
  root: {
    padding: 8,
    marginLeft: 4,
    maxWidth: '79%',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: '#566267',
    overflowWrap: 'break-word',
  },
}))(Typography);

export const SelectFile = withStyles(() => ({
  root: {
    height: '36px',
    width: '102px',
    padding: '10px 10px',
    cursor: 'pointer',

    background: '#FFFFFF',
    color: '#08A88E !important',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '14px !important',

    borderRadius: 4,
    border: '1px solid #08A88E',
  },
}))(InputLabel);
