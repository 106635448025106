import PDFDocument from 'assets/images/pdf.png';
import React from 'react';

const FilePreviewer = ({ fileType, fileURL, previewWidth, previewHeight }) => {
  return (
    <>
      {fileType.includes('image') ? (
        <img
          style={{ maxWidth: previewWidth || '500px', maxHeight: previewHeight || '500px' }}
          src={fileURL}
          alt='Preview broken'
        />
      ) : fileType.includes('pdf') ? (
        <a href={fileURL} target='_blank'>
          <img style={{ width: '100px', height: '70px' }} src={PDFDocument} alt='Get pdf' />
        </a>
      ) : null}
    </>
  );
};

export default FilePreviewer;
