import { Modal } from './Modal';
import { Button } from '../common/Button';

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
  content: string;
}

export function DeleteModal({ open, onClose, onDelete, title, content }: DeleteModalProps) {
  return (
    <Modal className='!px-[16px]' size='small' open={open} onClose={onClose}>
      <Modal.Head onClose={onClose}>{title}</Modal.Head>
      <Modal.Body>
        {content}
        <div className='flex flex-row-reverse gap-x-2 pt-3'>
          <Button className='bg-error text-white' onClick={onDelete}>
            Delete
          </Button>
          <Button className='bg-white text-error' onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
