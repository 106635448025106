import React from 'react';

function RedirectIcon() {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.181 12.1862C12.1787 12.1862 12.1773 12.1877 12.1773 12.1877L12.181 12.1862ZM1.81397 12.1906C1.81397 12.1877 1.81249 12.1862 1.81027 12.1862H1.81397V12.1906ZM12.1773 12.1862V7.74476H13.6577V12.1877C13.6579 12.3817 13.6199 12.574 13.5458 12.7534C13.4716 12.9327 13.3629 13.0958 13.2258 13.2331C13.0886 13.3705 12.9258 13.4794 12.7465 13.5538C12.5672 13.6282 12.3751 13.6665 12.181 13.6666H1.81027C1.41879 13.6665 1.04339 13.5109 0.76651 13.2342C0.489626 12.9574 0.333888 12.5821 0.333496 12.1906V1.81844C0.333496 1.00344 0.996747 0.342409 1.81175 0.342409H6.25538V1.82288H1.81397V12.1862H12.1773ZM1.81397 12.1906C1.81397 12.1877 1.81249 12.1862 1.81027 12.1862H1.81397V12.1906ZM6.25538 1.82288H1.81397V12.1862H12.1773V7.74476H13.6577V12.1877C13.6579 12.3817 13.6199 12.574 13.5458 12.7534C13.4716 12.9327 13.3629 13.0958 13.2258 13.2331C13.0886 13.3705 12.9258 13.4794 12.7465 13.5538C12.5672 13.6282 12.3751 13.6665 12.181 13.6666H1.81027C1.41879 13.6665 1.04339 13.5109 0.76651 13.2342C0.489626 12.9574 0.333888 12.5821 0.333496 12.1906V1.81844C0.333496 1.00344 0.996747 0.342409 1.81175 0.342409H6.25538V1.82288ZM12.1773 1.82288V4.04359C12.1773 4.23991 12.2552 4.42819 12.3941 4.56701C12.5329 4.70583 12.7212 4.78382 12.9175 4.78382C13.1138 4.78382 13.3021 4.70583 13.4409 4.56701C13.5797 4.42819 13.6577 4.23991 13.6577 4.04359V1.08264C13.6577 0.886322 13.5797 0.69804 13.4409 0.559219C13.3021 0.420398 13.1138 0.342409 12.9175 0.342409H9.95656C9.76023 0.342409 9.57195 0.420398 9.43313 0.559219C9.29431 0.69804 9.21632 0.886322 9.21632 1.08264C9.21632 1.27897 9.29431 1.46725 9.43313 1.60607C9.57195 1.74489 9.76023 1.82288 9.95656 1.82288H12.1773Z'
        fill='#08A88E'
      />
      <path
        d='M7.51896 7.52787L13.4408 1.60599C13.5115 1.53771 13.5679 1.45603 13.6067 1.36571C13.6455 1.2754 13.6659 1.17827 13.6668 1.07998C13.6677 0.981693 13.6489 0.884219 13.6117 0.793247C13.5745 0.702274 13.5195 0.619626 13.45 0.550123C13.3805 0.48062 13.2979 0.425655 13.2069 0.388436C13.1159 0.351216 13.0184 0.332487 12.9202 0.333341C12.8219 0.334195 12.7247 0.354615 12.6344 0.39341C12.5441 0.432205 12.4624 0.488598 12.3941 0.559298L6.47227 6.48118C6.33743 6.62079 6.26282 6.80778 6.2645 7.00186C6.26619 7.19595 6.34404 7.38161 6.48128 7.51886C6.61853 7.6561 6.80419 7.73395 6.99828 7.73564C7.19237 7.73733 7.37935 7.66271 7.51896 7.52787Z'
        fill='#08A88E'
      />
    </svg>
  );
}

export default RedirectIcon;
