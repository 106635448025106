import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getConditionalTexts } from '../../../actions/resources/getConditionalTexts';
import { ConditionalText, resourcesAPI } from '../../../api/resources';
import { useConditionalTexts } from '../../../hooks/module/resources/useConditionalTexts';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { Caption2, H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';
import { ConditionalTextForm } from './form/ConditionalTextForm';

interface RequiredResourceFormProps {
  conditionalTextId?: string;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  closeUiElementPane: () => void;
  modal: boolean;
  resource: boolean;
  savePosition: () => void;
}

const labels = {
  title: 'Conditional Texts',
  input: 'conditional text',
  button: 'Conditional Text',
  data: 'conditional texts',
};

export const ConditionalTextResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const { data, refetch } = useConditionalTexts();

  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const onOpen = (resourceId: string | null) => {
    setOpen(true);
    setResourceId(resourceId);
  };
  const onClose = () => {
    setOpen(false);
    refetch();
  };

  const resourceFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      conditionalTextId: resourceId ?? undefined,
      toggleModal: onClose,
      type: module?.type as ResourceFormModuleTypes,
      closeUiElementPane: () => setModal(false),
      modal: modal,
      resource: true,

      savePosition: () => {},
    }),
    [open, modal]
  );

  // TODO: remove when redux resource data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    if (!module) return;
    dispatch(getConditionalTexts(module.type, null, module.id));
  }, [data, module]);

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={data}
        renderCard={(resource) => ResourceCard(resource, onOpen)}
        onOpen={onOpen}
      />
      <SlidePanel open={open} onClose={onClose} className='px-[16px] py-[20px]' id='slide-panel'>
        {({ modalOpen, closeModal, closePanel }) => (
          <ConditionalTextForm
            conditionalTextId={resourceId}
            open={modalOpen}
            closePanel={closePanel}
            closeModal={closeModal}
          />
        )}
      </SlidePanel>
    </>
  );
};

const ResourceCard = (
  resource: ConditionalText,
  onOpen: (resourceId: string | null) => void
): ReactNode => {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(resourcesAPI.deleteConditionalText, {
    onSuccess: () => queryClient.invalidateQueries([module?.type, module?.id, 'conditional-texts']),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.id);
  const onDelete = () => {
    mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none divide-x divide-gray-200 rounded-xl bg-white py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-1/2 px-[20px]'>
          <div className='space-y-2'>
            <Caption2 className='text-gray-700'>name</Caption2>
            <H4>{resource.name}</H4>
          </div>
        </div>
        <div className='flex w-1/2 px-[20px]'>
          <div className='w-full space-y-2'>
            <Caption2 className='text-gray-700'>Text</Caption2>
            <div className='w-full space-y-1 divide-y divide-gray-300'>
              {resource.conditions.map((condition: any, index: number) => (
                <AvoEditorPreview
                  key={condition.id}
                  contentTiptap={condition.text_content_tiptap}
                  contentDraftJS={condition.text_content}
                />
              ))}
            </div>
          </div>
          {hasEditPermission && (
            <FloatingActionButton
              open={open}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={toggleDelete}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this conditional text?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
