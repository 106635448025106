import { Combobox } from '@headlessui/react';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { LinkInvitationRequest, MemberInvitationRequest, ROLES } from '../../api/team';
import TagDeleteIcon from '../../assets/icons/tagCloseIcon';
import { ReactComponent as Email } from '../../assets/icons/v2/item/email.svg';
import { ReactComponent as Link } from '../../assets/icons/v2/item/link.svg';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';
import { checkEmail } from '../../utils/utilityFunctions';
import LoadingSpinner from '../loader/LoadingSpinner';
import Button from '../utils/Button';
import Input from '../utils/Input';
import Label from '../utils/Label';
import CircleButton from '../utils/RadioButton';
import { Modal } from '../utils/modals/Modal';
import { CustomToast } from '../utils/toast-message';

interface MemberInvitationModalProps {
  open: boolean;
  onClose: () => void;
}

const MemberInvitationModal = ({ open, onClose }: MemberInvitationModalProps) => {
  const [input, setInput] = useState<string>('');
  const { authentication } = useAuthentication();
  const { inviteMembers, team } = useTeam();
  const { register, handleSubmit, setValue, watch } = useForm<MemberInvitationRequest>({
    defaultValues: {
      role: 'M',
      emails: [],
      team: 0,
    },
  });

  useEffect(() => {
    if (!!authentication.user?.last_selected_team) {
      setValue('team', team.id);
    }
  }, [authentication, setValue, team]);

  const { role, emails } = watch();
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', 'Comma'].includes(event.code)) {
      event.preventDefault();
      setValue('emails', [...emails, input.toLocaleLowerCase()]);
      setInput('');
    }
  };
  const handleCancel = (item: string) => {
    setValue(
      'emails',
      emails.filter((email) => email !== item)
    );
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const onSubmit = (data: MemberInvitationRequest) => {
    if (data.emails.length <= 0) {
      toast.warning(CustomToast, {
        data: 'Please provide an email address to send the invitation.',
      });
      return;
    }

    const invalidEmail = emails.find((email) => !checkEmail(email));
    if (!!invalidEmail) {
      toast.error(CustomToast, { data: 'Invalid Email ' + invalidEmail });
      return;
    }

    setIsSubmitting(true);
    inviteMembers(data, {
      onSuccess: () => {
        toast.success(CustomToast, { data: 'Invitation Sent!.' });
      },
      onSettled: () => setIsSubmitting(false),
    });
  };

  const [inviteLink, setInviteLink] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const handleClick = () => {
    if (!authentication.user) {
      toast.error(CustomToast, { data: 'Invalid user' });
      return;
    }
    const data: LinkInvitationRequest = {
      team: team.id,
      sender: authentication.user.name,
      role,
    };

    setIsGenerating(true);
    inviteMembers(data, {
      onSuccess: ({ invite_code }) => {
        setInviteLink(window.location.host + '/invitation/' + invite_code);
      },
      onSettled: () => setIsGenerating(false),
    });
  };
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const handleCopy = () => {
    // TODO: add Toast
    setIsCopied(true);
    toast.success(CustomToast, { data: 'Link is copied into the clipboard' });
  };
  const handleClose = () => {
    setValue('emails', []);
    setInviteLink('');
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className='px-0'>
      <Modal.Head onClose={handleClose} className='border-b px-[16px] py-[12px]'>
        <div className='text-heading-3'>Invite Members</div>
      </Modal.Head>
      <Modal.Body className='py-0'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='space-y-[44px] px-[16px] pb-[22px] pt-[32px]'
        >
          <div className='flex gap-[44px]'>
            <Label className='flex items-center space-x-2'>
              {role === ROLES.Member ? <CircleButton.Selected /> : <CircleButton />}
              <span>Member</span>
              <input type='radio' {...register('role')} value={'M'} className='hidden' />
            </Label>
            {team.current_teammate?.role === ROLES.Admin && (
              <Label className='flex items-center space-x-2'>
                {role === ROLES.Admin ? <CircleButton.Selected /> : <CircleButton />}
                <span>Admin</span>
                <input type='radio' {...register('role')} value={'A'} className='hidden' />
              </Label>
            )}
          </div>
          <div>
            <Label className='space-y-5'>
              <div className='flex grow items-center gap-1.5'>
                <Email />
                <span className='text-body-1'>Invite with Email</span>
              </div>
              <div className='flex gap-4'>
                <Combobox value={emails} multiple>
                  {({ open }) => (
                    <>
                      <div
                        className={twMerge(
                          'flex grow flex-col rounded-sm border border-gray-300',
                          open && 'border-primary-600',
                          emails.length > 0 && 'gap-1.5'
                        )}
                      >
                        <div
                          className={twMerge(
                            'flex flex-wrap gap-1.5',
                            emails.length > 0 && 'px-[12px] py-[8px]'
                          )}
                        >
                          {emails.map((email) => (
                            <div
                              key={email}
                              className='flex rounded-[2px] !bg-gray-100 text-body-2 text-gray-700'
                            >
                              <div className='p-[4px] pl-[7px] text-[12px] font-medium tracking-tight'>
                                {email}
                              </div>
                              <div
                                onClick={(e) => handleCancel(email)}
                                className='flex cursor-pointer rounded-[2px] px-[5px] hover:bg-red-200 focus:bg-gray-100'
                              >
                                <div className='pointer-events-none self-center hover:text-red-500 '>
                                  <TagDeleteIcon />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Combobox.Input
                          className='px-[12px] py-[8px] text-body-2 focus:outline-0'
                          value={input}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder={
                            emails.length <= 0 && !input ? 'Email, comma separated' : undefined
                          }
                        />
                      </div>
                      <Combobox.Options className='hidden'>
                        <Combobox.Option value={input}></Combobox.Option>
                      </Combobox.Options>
                    </>
                  )}
                </Combobox>
                <div>
                  <Button className='!w-[95px] py-[9px] text-button-1'>
                    {isSubmitting ? <LoadingSpinner size='small' /> : 'Invite'}
                  </Button>
                </div>
              </div>
            </Label>
          </div>
        </form>
        <div className='bg-gray-100'>
          <div className='p-[16px] pt-[22px]'>
            <Label className='space-y-5'>
              <div className='flex grow items-center gap-1.5'>
                <Link />
                <span className='text-body-1'>Invite with Link</span>
              </div>
              <div className='flex gap-4'>
                <Input
                  className='focus:!border-primary-600 focus:outline-0'
                  placeholder='Link will be generate here'
                  value={inviteLink}
                  readOnly
                />
                <div>
                  {inviteLink ? (
                    <CopyToClipboard text={inviteLink} onCopy={handleCopy}>
                      <Button
                        className='!w-[95px] py-[9px] text-button-1 disabled:bg-primary-500'
                        disabled={isCopied}
                      >
                        {isCopied ? 'Copied!' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    <Button onClick={handleClick} className='!w-[95px] py-[9px] text-button-1'>
                      {isGenerating ? <LoadingSpinner size='small' /> : 'Generate'}
                    </Button>
                  )}
                </div>
              </div>
            </Label>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MemberInvitationModal;
