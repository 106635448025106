import { MenuItem, MenuList, Popper } from '@material-ui/core';
import { connect } from 'react-redux';
import { classes } from '../../../module-detail/styles';

function ModuleDropdown({ moduleAnchor, moduleList, calcList, text, setSelectedModuleCalc }) {
  const open = Boolean(moduleAnchor);
  return (
    <Popper
      open={open}
      style={classes.highZindex}
      disableEnforceFocus
      disableScrollLock
      anchorEl={moduleAnchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuList style={classes.moduleDropdown}>
        {Array.isArray(moduleList) && moduleList?.length === 0
          ? ``
          : moduleList
              .filter((module) => module.name.toLowerCase().includes(text.toLowerCase()))
              .map((module) => (
                <MenuItem
                  style={classes.permissionItem}
                  className='hover-effect-v2'
                  onClick={() => setSelectedModuleCalc(module, 'module')}
                >
                  {module.title || module.name}
                </MenuItem>
              ))}
        {Array.isArray(calcList) && calcList?.length === 0
          ? ``
          : calcList
              .filter((calc) => calc.name.toLowerCase().includes(text.toLowerCase()))
              .map((calc) => (
                <MenuItem
                  style={classes.permissionItem}
                  className='hover-effect-v2'
                  onClick={() => setSelectedModuleCalc(calc, 'calculator')}
                >
                  {calc.title || calc.name}
                </MenuItem>
              ))}
      </MenuList>
    </Popper>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ModuleDropdown);
