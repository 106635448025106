export const globalStyles = (
  <>
    <link
      type='text/css'
      rel='stylesheet'
      href='https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.3/css/materialize.css'
    />
    <link
      href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
      type='text/css'
      rel='stylesheet'
    />
  </>
);
