import calculatorIcon from '@iconify/icons-mdi/calculator';
import { Icon } from '@iconify/react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ImageIcon from 'assets/icons/imageIcon';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { Trigger } from './Trigger';
import { VariablesPreview } from './common/variable';

interface ChoiceButtonProps {
  container?: boolean;
}
const ChoiceButton = withStyles((theme) => ({
  root: {
    width: (props: ChoiceButtonProps) => (props.container ? '100%' : '475px'),
    backgroundColor: '#FFFFFF',
    // display: 'grid',
    // position: 'inherit',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: 'auto !important',
    textAlign: 'left',
    color: '#000000',
    borderRadius: '20px',
    minHeight: '40px',
    wordBreak: 'break-all',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '8px',
    textTransform: 'none',
    justifyContent: 'flex-start',
    fontSize: 16,
    paddingLeft: '20px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: '#62E37D',
      color: 'white',
    },
    child: {
      backgroundColor: 'pink',
    },
  },
  label: {
    padding: '5px',
  },
}))(Button);
interface ChoicePanelProps extends ChoiceButtonProps {
  //container: any;
  teamType?: any;
  editMode: any;
  editorChoice?: any;
  choices: any;
  editorTrigger: any;
  trigger?: any;
  variable?: string;
  editorElements?: any;
  elements?: any;
  editorRationale?: any;
  rationale?: any;
  answerForm?: any;
  containerCard: any;
  type?: any;
  editorQuestion: any;
  question?: any;
  update?: () => void;
  deleteCard?: Function;
  panelId?: any;
  duplicateCard?: Function;
  editorSubtitle?: any;
  subtitle?: any;
}
interface ChoicePanelState {
  modal: boolean;
  deleteModal: boolean;
  duplicateModal: boolean;
  anchorEl: any;
  editor: boolean;
}
class ChoicePanel extends Component<ChoicePanelProps, ChoicePanelState> {
  constructor(props: ChoicePanelProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      duplicateModal: false,
      anchorEl: null,
      editor: false,
    };
  }
  setAnchorEl = (e) => {
    this.setState({ anchorEl: e.target });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  // for open/close modal
  // toggleModal = () => {
  //   this.setState((previous) => ({
  //     modal: !previous.modal,
  //   }));
  // };
  handleEditMode = () => {
    this.setState((previous) => ({ editor: !previous.editor }));
  };

  toggleModal = (modalName) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<ChoicePanelState, any>
    );
  };

  render() {
    const teamType = this.props.teamType;
    let choices =
      (this.state.editor || this.props.editMode) && this.props.editorChoice
        ? this.props.editorChoice
        : this.props.choices
        ? this.props.choices
        : [];
    const trigger =
      this.state.editor || this.props.editMode ? this.props.editorTrigger : this.props.trigger;
    let cardStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '10px',
      marginBottom: '0px',
      paddingBottom: '10px',
      width: this.props.container ? '100%' : '576px',
      borderRadius: '30px',
      backgroundColor: this.props.container ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      boxShadow: this.props.container ? 'none' : '0px 4px 25px rgba(0, 0, 0, 0.15)',
    };
    let questionStyle = {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      marginBottom: '15px',
    };
    let elements =
      this.state.editor || this.props.editMode
        ? this.props.editorElements
        : this.props.elements
        ? this.props.elements
        : [];

    let examples: Array<{ label: any; type: any }> = [];
    let tools: Array<{ label: any; type: any }> = [];

    elements?.forEach((item) => {
      if (item?.section === 'T') {
        tools.push({
          label:
            item?.label ||
            item?.image?.representation_phrase ||
            item?.legacy_calculator?.title ||
            item?.calculator?.name ||
            item?.infobox?.shortened_title,
          type: item?.image
            ? 'image'
            : item?.legacy_calculator
            ? 'legacy_calculator'
            : item?.calculator
            ? 'calculator'
            : item?.infobox
            ? 'infobox'
            : 'No type found',
        });
      } else if (item?.section === 'E') {
        examples.push({
          label:
            item?.text?.statement ||
            item?.label ||
            item?.infobox?.shortened_title ||
            item?.image?.representation_phrase,
          type: item?.text
            ? 'text'
            : item?.infobox
            ? 'infobox'
            : item?.image
            ? 'image'
            : 'No type found',
        });
      }
    });

    const variables = [
      {
        type: 'choice',
        name: this.props.variable,
      },
    ];

    return (
      <div
        data-testid='choice-panel__test'
        className='!mb-4'
        style={
          {
            position: this.state.editor || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editor || this.props.editMode ? '12' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            // marginLeft: this.props.answerForm ? '' : !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <Trigger trigger={trigger} containerCard={this.props.containerCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={
            !hasViewOnlyPermission(this.props.type) && this.props.update
              ? this.props.update
              : undefined
          }
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}

              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update && this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                {teamType !== 'A' && (
                  <MenuItem
                    onClick={() => {
                      this.toggleModal('deleteModal');
                      this.handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    this.toggleModal('duplicateModal');
                    this.handleClose();
                  }}
                >
                  Duplicate
                </MenuItem>
              </Menu>

              {this.state.editor || this.props.editMode
                ? this.props.editorQuestion
                : this.props.question}
            </Typography>
            <div className='mb-[28px]'></div>
            <Typography
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#ADADAD',
              }}
            >
              {this.state.editor || this.props.editMode
                ? this.props.editorSubtitle
                : this.props.subtitle}
            </Typography>

            {examples.length > 0 && (
              <Box
                style={{
                  border: '0.75px solid #B3B3B3',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '58px',
                  marginTop: 12,
                  marginBottom: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                {examples.map((example, index) => {
                  return example.type === 'text' ? (
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#000000',
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && <span style={{ marginRight: 4 }}>,</span>}
                    </Typography>
                  ) : example.type === 'infobox' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : example.type === 'image' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : null;
                })}
              </Box>
            )}

            {tools.map((tool, index) => {
              return tool.type === 'infobox' ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    fontSize: '16px',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 600,

                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<InfoBoxIcon />}
                  label={tool.label}
                />
              ) : tool.type === 'image' ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    alignItems: 'center',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<ImageIcon />}
                  label={tool.label}
                />
              ) : tool.type === 'legacy_calculator' ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label}
                />
              ) : tool.type === 'calculator' ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label}
                />
              ) : null;
            })}

            {choices.map((choice, index) => (
              <div key={index} style={{ marginTop: 12 }}>
                <ChoiceButton container={this.props.container}>{choice.name}</ChoiceButton>
                {choice.mapped_output_categories &&
                  choice.mapped_output_categories.map((output_category, index) => (
                    <Chip
                      key={index}
                      style={{
                        backgroundColor: '#08A88E',
                        color: 'white',
                        marginLeft: '22px',
                      }}
                      size='small'
                      label={output_category}
                    />
                  ))}
              </div>
            ))}
          </CardContent>
        </Card>

        {!this.props.container && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => this.toggleModal('deleteModal')}
          performAction={() => {
            this.props.deleteCard && this.props.deleteCard(this.props.panelId);
            this.toggleModal('deleteModal');
          }}
        />
        <ConfirmModal
          preset='duplicate'
          open={this.state.duplicateModal}
          content='Are you sure you want to duplicate this card?'
          toggleModal={() => this.toggleModal('duplicateModal')}
          performAction={() => {
            this.props.duplicateCard &&
              this.props.duplicateCard(this.props.panelId, 'is_choice_panel');
            this.toggleModal('duplicateModal');
          }}
        />
      </div>
    );
  }
}

export default ChoicePanel;
