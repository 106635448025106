import { MainLayout } from 'components/layout/MainLayout';
import MemberManagement from '../components/members/MemberManagement';

const Members = () => {
  return (
    <MainLayout>
      <MemberManagement />
    </MainLayout>
  );
};

export default Members;
