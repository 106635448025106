import { TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
export const VerifiedChip = withStyles(() => ({
  root: {
    color: '#08A88E',
    display: 'grid',
    placeItems: 'center',
    marginLeft: 10,
  },
}))(CheckCircleOutlineIcon);
// Module list styles

export const StyledTableSortLabel = withStyles((theme) => ({
  icon: {
    color: 'black !important',
    marginBottom: '5px',
  },
}))(TableSortLabel);
