import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  DuplicateRequest,
  Positions,
  ResourceTypes,
  UIElement,
  moduleElementAPI,
} from '../../api/moduleElement';
import { MODULE_TYPES } from '../../constants';
import { hasViewOnlyPermission } from '../../utils/permissions';
import { DraggableChips } from '../module-detail/DraggableChips';
import { UiElementContext } from '../utils/module/UiElementContext';
import { AnswerBoard } from './AnswerBoard';
import { CardBoard } from './CardBoard';

const INITIAL_CARD_ID = 0;
const CONVERSATION_ITEM = 'ConversationItem';
const CONVERSATION_CARD = 'ConversationCard';
export const CONTAINER_CARD = 'ContainerCard';

interface DefaultModuleElementOptions {
  parentId?: number;
  position: number;
  resourcetype: (typeof ResourceTypes)[keyof typeof ResourceTypes];
}

const clearUIelementPositions = (moduleElements: UIElement[]): UIElement[] => {
  return moduleElements.map((moduleElement, position) => {
    if (!!moduleElement.ui_elements) {
      return {
        ...moduleElement,
        ui_elements: clearUIelementPositions(moduleElement.ui_elements),
        position,
      };
    }

    return {
      ...moduleElement,
      position,
    };
  });
};

export const sortUIElementPositions = (moduleElements: UIElement[]) => {
  if (moduleElements.length < 2) {
    return moduleElements;
  }

  // should place introduction card first
  moduleElements.sort((a, b) =>
    !!a.is_introduction ? -1 : !!b.is_introduction ? 1 : a.position - b.position
  );

  return moduleElements.map((element) =>
    !!element.ui_elements
      ? { ...element, ui_elements: sortUIElementPositions([...element.ui_elements]) }
      : { ...element }
  );
};

const getPositions = (moduleElements: UIElement[]): Positions => {
  return moduleElements.reduce((positions: Positions, moduleElement) => {
    if (moduleElement.id === INITIAL_CARD_ID) {
      return { ...positions };
    }
    if (!!moduleElement.ui_elements) {
      const position = getPositions(moduleElement.ui_elements);
      return { ...positions, ...position, [moduleElement.id]: moduleElement.position };
    }
    return { ...positions, [moduleElement.id]: moduleElement.position };
  }, {});
};

interface ModuleElementContextValues {
  addModuleElement: ({ parentId, position, resourcetype }: DefaultModuleElementOptions) => void;
  clearModuleElements: () => void;
  refetchModuleElements: () => void;
  deleteUIElement: UseMutateFunction<any, unknown, number>;
  duplicateUIElement: UseMutateFunction<any, unknown, DuplicateRequest>;
}

export const ModuleElementContext = createContext<ModuleElementContextValues>({
  addModuleElement: () => {},
  clearModuleElements: () => {},
  refetchModuleElements: () => {},
  deleteUIElement: () => {},
  duplicateUIElement: () => {},
});

export const DraggableContext = createContext({
  isDragDisabled: false,
  setIsDragDisabled: (isDragDisabled: boolean) => {},
});

export const ModuleBoard = () => {
  const { module } = useContext(ModuleContext);
  const moduleTypeKey =
    module?.type === MODULE_TYPES.ALGO
      ? 'modules'
      : module?.type === MODULE_TYPES.CALCULATOR
      ? 'calculators'
      : null;
  const hasPermission = !hasViewOnlyPermission(module?.type);
  const { uiElements, refetch: refetchModuleElements, isLoading } = useContext(UiElementContext);

  const { mutate: deleteUIElement } = useMutation(
    (elementId: number) =>
      moduleElements.find((moduleElement) => moduleElement.id === elementId)?.resourcetype ===
      ResourceTypes.group
        ? moduleElementAPI.deleteContainer(elementId)
        : moduleElementAPI.deleteUIElement(elementId),
    {
      onSuccess: refetchModuleElements,
    }
  );
  const { mutate: duplicateUIElement } = useMutation(moduleElementAPI.duplicateUIElement, {
    onSuccess: refetchModuleElements,
  });

  const { mutate: saveUIElementPosition } = useMutation((positions: Positions) =>
    moduleElementAPI.saveUIElementPosition({
      moduleType: moduleTypeKey!,
      moduleId: module!.id + '',
      positions,
    })
  );

  const [moduleElements, setModuleElements] = useState<UIElement[]>([]);

  // Combines separately loaded card information.
  // Adjust and save the positions to make them sequential.
  useEffect(() => {
    if (isLoading || !module) {
      return;
    }
    const sortedModuleElements = sortUIElementPositions(uiElements);
    const updatedModuleElements = clearUIelementPositions(sortedModuleElements);

    const positions = getPositions(updatedModuleElements);

    if (hasPermission) {
      saveUIElementPosition(positions);
    }
    setModuleElements(updatedModuleElements);
  }, [isLoading]);

  // Adds a card but does not save it to the api.
  // Adjust and save the entire card position for the card position to be added.
  const addModuleElement = ({ parentId, position, resourcetype }: DefaultModuleElementOptions) => {
    if (!module) return;
    const defaultModuleElement: UIElement = {
      id: 0,
      position,
      resourcetype,
      container: parentId ?? undefined,
    };

    if (parentId) {
      const parentModuleElement = moduleElements.find(
        (moduleElement) => moduleElement.id === parentId
      )!;
      const deepCopiedChildModuleElements: UIElement[] = [...parentModuleElement.ui_elements];
      deepCopiedChildModuleElements.splice(position, 0, defaultModuleElement);
      const updatedChildModuleElements: UIElement[] = clearUIelementPositions(
        deepCopiedChildModuleElements
      );
      const updatedModuleElements = moduleElements.map((moduleElement) => {
        if (moduleElement.id === parentId) {
          return {
            ...parentModuleElement,
            ui_elements: updatedChildModuleElements,
          } as UIElement;
        }
        return moduleElement;
      });
      const positions = getPositions(updatedModuleElements);
      saveUIElementPosition(positions);
      setModuleElements(updatedModuleElements);
    } else {
      const deepCopiedModuleElements = [...moduleElements];
      deepCopiedModuleElements.splice(position, 0, defaultModuleElement);
      const updatedModuleElements = clearUIelementPositions(deepCopiedModuleElements);
      const positions = getPositions(updatedModuleElements);

      saveUIElementPosition(positions);
      setModuleElements(updatedModuleElements);
    }
  };

  // Reset the cards.
  // Save to restore the adjusted position.
  const clearModuleElements = async () => {
    if (!module) return;
    const recursiveClear = (moduleElements: UIElement[]): UIElement[] => {
      return moduleElements.reduce((acc: UIElement[], moduleElement) => {
        if (!!moduleElement.ui_elements) {
          const ui_elements = recursiveClear(moduleElement.ui_elements);
          acc.push({ ...moduleElement, ui_elements });
        } else if (!!moduleElement.id) {
          acc.push(moduleElement);
        }
        return acc;
      }, []);
    };

    const clearedModuleElements = recursiveClear(moduleElements);
    const positions = getPositions(clearedModuleElements);
    saveUIElementPosition(positions);
    setModuleElements(clearedModuleElements);
  };

  const contextValue: ModuleElementContextValues = {
    addModuleElement,
    clearModuleElements,
    refetchModuleElements,
    deleteUIElement,
    duplicateUIElement,
  };

  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const onDragEnd = ({ destination, draggableId, source }: DropResult, _) => {
    if (!module || !destination || destination.droppableId === CONVERSATION_ITEM) return;

    // add new card
    if (source.droppableId === CONVERSATION_ITEM) {
      addModuleElement({
        position: destination.index,
        resourcetype: ResourceTypes[draggableId],
      });
      // change position
    } else {
      const containerId = source.droppableId.split('-')?.[1];
      const deepCopiedModuleElements = [
        ...(!!containerId
          ? moduleElements.find((moduleElement) => moduleElement.id === Number(containerId))!
              .ui_elements
          : moduleElements),
      ];
      const [targetElements] = deepCopiedModuleElements.splice(source.index, 1);
      deepCopiedModuleElements.splice(destination.index, 0, targetElements);

      const sortedModuleElements = clearUIelementPositions(deepCopiedModuleElements);
      const positions = getPositions(sortedModuleElements);
      saveUIElementPosition(positions);

      setModuleElements(
        !!containerId
          ? moduleElements.map((moduleElement) =>
              moduleElement.id === Number(containerId)
                ? { ...moduleElement, ui_elements: [...sortedModuleElements] }
                : moduleElement
            )
          : sortedModuleElements
      );
    }
  };

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <DraggableContext.Provider value={{ isDragDisabled, setIsDragDisabled }}>
      <ModuleElementContext.Provider value={contextValue}>
        <DragDropContext onDragEnd={onDragEnd}>
          {!hasViewOnlyPermission() && <DraggableChips />}
          <div className='p-6'>
            <CardBoard droppableId={CONVERSATION_CARD} moduleElements={moduleElements} />
          </div>
        </DragDropContext>
      </ModuleElementContext.Provider>
      {module?.type === MODULE_TYPES.ALGO && <AnswerBoard />}
    </DraggableContext.Provider>
  );
};
