import { onSortEnd } from 'components/utils/ListItemUtil';
import { atom, useSetAtom } from 'jotai';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ConditionalTextAccordionItem from './ConditionalTextItem';

export const DEFAULT_CONDITIONS_COUNT_ATOM = atom(1);
interface SortableConditionalTextItemProps {
  deleteCondition: Function;
  setCaseText: Function;
  index: number;
  item: any;
  type: string;
  conditionIndex: number;
  moduleId: string;
  calculatorId: string;
  setRawCondition: Function;
  numerics: any;
  handleDeleteClick: Function;
  handleListChange: Function;
  conditionalTextConditionsHandler: Function;
  resourceSection: any;
}

const SortableConditionalTextItem = SortableElement<SortableConditionalTextItemProps>(
  ({
    deleteCondition,
    setCaseText,
    index,
    item,
    type,
    conditionIndex,
    moduleId,
    calculatorId,
    setRawCondition,
    numerics,
    conditionalTextConditionsHandler,
    resourceSection,
  }) => (
    <ConditionalTextAccordionItem
      id={item.id}
      conditionalTextConditionsHandler={conditionalTextConditionsHandler}
      resourceSection={resourceSection}
      deleteCondition={deleteCondition}
      setCaseText={setCaseText}
      indexNo={index}
      item={item}
      type={type}
      conditionIndex={conditionIndex}
      moduleId={moduleId}
      calculatorId={calculatorId}
      setRawCondition={setRawCondition}
      numerics={numerics}
    />
  )
);

interface SortableConditionalTextContainerProps {
  items: any[];
  deleteCondition: Function;
  setCaseText: Function;
  type: string;
  moduleId: string;
  calculatorId: string;
  setRawCondition: Function;
  numerics: any;
  handleDeleteClick: Function;
  handleListChange: Function;
  resourceSection: any;
  conditionalTextConditionsHandler: Function;
}

const SortableConditionalTextContainer = SortableContainer<SortableConditionalTextContainerProps>(
  ({
    items,
    deleteCondition,
    setCaseText,
    type,
    moduleId,
    calculatorId,
    setRawCondition,
    numerics,
    handleDeleteClick,
    handleListChange,
    resourceSection,
    conditionalTextConditionsHandler,
  }) => {
    const setdefaultConditionsCount = useSetAtom(DEFAULT_CONDITIONS_COUNT_ATOM);
    setdefaultConditionsCount(items.filter((obj) => obj.is_default).length);
    return (
      <div style={{ width: '100%', border: '1px solid #DADADA', borderRadius: 10 }}>
        {items.map((obj, index) => (
          <SortableConditionalTextItem
            collection={obj.is_default ? 1 : 0}
            disabled={obj.is_default ? true : false}
            conditionalTextConditionsHandler={conditionalTextConditionsHandler}
            resourceSection={resourceSection}
            key={obj.id ? obj.id : obj.sortableId}
            deleteCondition={deleteCondition}
            setCaseText={setCaseText}
            item={obj}
            type={type}
            index={index}
            conditionIndex={index}
            moduleId={moduleId}
            calculatorId={calculatorId}
            setRawCondition={setRawCondition}
            numerics={numerics}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

interface SortableConditionalTextListProps {
  items: any[];
  deleteCondition: Function;
  setCaseText: Function;
  type: string;
  moduleId: string;
  calculatorId: string;
  setRawCondition: Function;
  numerics: any;
  updateListItems: Function;
  handleDeleteClick: Function;
  handleListChange: Function;
  resourceSection: any;
  conditionalTextConditionsHandler: Function;
}

function SortableConditionalTextList({
  items,
  deleteCondition,
  setCaseText,
  type,
  moduleId,
  calculatorId,
  setRawCondition,
  numerics,
  updateListItems,
  handleDeleteClick,
  handleListChange,
  resourceSection,
  conditionalTextConditionsHandler,
}: SortableConditionalTextListProps) {
  return (
    <SortableConditionalTextContainer
      conditionalTextConditionsHandler={conditionalTextConditionsHandler}
      resourceSection={resourceSection}
      distance={1} // this means we need to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={items}
      deleteCondition={deleteCondition}
      setCaseText={setCaseText}
      type={type}
      moduleId={moduleId}
      calculatorId={calculatorId}
      setRawCondition={setRawCondition}
      numerics={numerics}
      handleDeleteClick={handleDeleteClick}
      handleListChange={handleListChange}
      onSortEnd={({ oldIndex, newIndex }) => updateListItems(onSortEnd(items, oldIndex, newIndex))}
    />
  );
}

export default SortableConditionalTextList;
