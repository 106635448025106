import NavBar from 'components/bars/NavBar';
import { ChannelList } from './ChannelList';

export const OldChannelPage = () => {
  return (
    <div>
      <NavBar />
      <div className='mx-auto max-w-[1280px] px-[40px]'>
        <ChannelList />
      </div>
    </div>
  );
};
