import { ComboBox } from 'components/utils/ComboBox';
import ConfirmationModal from 'components/utils/ConfirmationModal';
import { useAuthentication } from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CREATE_TEAM } from 'routes/urls';
import { ROLES } from '../../../api/team';
import { useTeam } from '../../../hooks/useTeam';

export const TeamSelectComboBox = () => {
  const { authentication } = useAuthentication();
  const history = useHistory();
  const isStaff = authentication.user?.is_staff;
  const isTrialUser = authentication.user?.user_type === 'trial';
  const { team } = useTeam();
  const [staffTeamJoinModal, setStaffTeamJoinModal] = useState(false);
  const [teamId, setTeamId] = useState<number>(team.id);

  const { teamList, staffJoin: staffJoinMutate, setTeam } = useTeam();
  const teams = teamList.map((team) => ({ value: team.id, label: team.name }));

  const staffJoin = () => {
    if (!isStaff) return;
    staffJoinMutate({
      team: teamId,
      user: authentication.user?.id,
      role: ROLES.Admin,
      status: 'A',
    });
  };

  useEffect(() => {
    if (team.id === teamId) return;
    const selectedTeam = teamList.find((team) => team.id === teamId);
    if (!selectedTeam) return;

    if (isStaff && !selectedTeam.is_team_member) {
      setStaffTeamJoinModal(true);
      return;
    }
    setTeam(teamId);
  }, [teamId]);

  const onCloseModal = () => {
    setStaffTeamJoinModal(false);
    setTeamId(team.id);
    setTimeout(blurFocus, 300);
  };

  /**
   * When staff join modal closes, focus go back to the combobox input element.
   * This method is not very clear.
   * Should be replaced when have a better solution
   *
   * This functionality is only for staff user, so could be better to keep it as it is.
   */
  const blurFocus = () => {
    const input = document.activeElement as HTMLElement & { blur: () => void };
    if (!input) return;
    input.focus();
    input.blur();
  };

  useEffect(() => {
    setTeamId(team.id);
  }, [team.id]);

  return (
    <>
      <ComboBox
        options={teams}
        selectedValue={teamId}
        onChange={(value) => setTeamId(value as number)}
        createButtonLabel='Create New Team'
        onCreate={!isTrialUser ? () => history.push(CREATE_TEAM) : undefined}
      />
      {isStaff && (
        <ConfirmationModal
          open={staffTeamJoinModal}
          onClose={onCloseModal}
          action={staffJoin}
          title='Staff member permission'
          text='You are not part of the team member. Please click the "Confirm" button for bypassing team
            invitation'
        />
      )}
    </>
  );
};
