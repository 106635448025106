import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import * as React from 'react';
import { useRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { PANEL_Z_INDEX } from '../../../v2/utils/constant';

const MenuContext = React.createContext<{
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
});

interface MenuProps {
  label?: string | React.ReactNode;
  nested?: boolean;
  children?: React.ReactNode;
  focusDisabled?: boolean;
}

const MenuComponent = React.forwardRef<
  HTMLButtonElement,
  MenuProps & Omit<React.HTMLProps<HTMLButtonElement>, 'label'>
>(({ children, label, ...props }, forwardedRef) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState(!label);
  const [hasFocusInside, setHasFocusInside] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
  const labelsRef = React.useRef<Array<string | null>>([]);
  const parent = React.useContext(MenuContext);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null && parentId !== ROOT_NODE_ID;

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isNested ? 'right-start' : 'bottom-start',
    middleware: [offset(isNested ? 7 : -10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    event: 'mousedown',
  });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, { bubbles: parentId !== ROOT_NODE_ID });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  React.useEffect(() => {
    if (!tree) return;

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    function onKeyDown(event) {
      if (forwardedRef && typeof forwardedRef !== 'function') {
        (forwardedRef.current as HTMLButtonElement).dispatchEvent(
          new KeyboardEvent('keydown', {
            key: event.key,
          })
        );
      }
    }

    tree.events.on('keydown', onKeyDown);
    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  React.useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpen, nodeId, parentId]);

  const FloatingElement = (
    <FloatingFocusManager
      context={context}
      modal={false}
      initialFocus={isNested ? -1 : 0}
      returnFocus={!isNested}
      disabled={props.focusDisabled}
    >
      <div
        ref={refs.setFloating}
        className={twMerge(
          props.className,
          'focus:outline-0',
          'flex max-h-[272px] flex-col gap-1 overflow-y-auto rounded bg-white shadow-04',
          PANEL_Z_INDEX
        )}
        style={floatingStyles}
        {...getFloatingProps({
          onKeyDown(event) {
            tree?.events.emit('keydown', event);
          },
        })}
      >
        {children}
      </div>
    </FloatingFocusManager>
  );
  return (
    <FloatingNode id={nodeId}>
      <button
        ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
        tabIndex={!isNested ? undefined : parent.activeIndex === item.index ? 0 : -1}
        role={isNested ? 'menuitem' : undefined}
        data-open={isOpen ? '' : undefined}
        data-nested={isNested ? '' : undefined}
        data-focus-inside={hasFocusInside ? '' : undefined}
        className={twJoin(
          isNested &&
            'flex w-full items-center gap-[4px] px-[12px] py-[10px] hover:bg-gray-100 focus:bg-gray-100'
        )}
        {...getReferenceProps(
          parent.getItemProps({
            ...props,
            onFocus(event: React.FocusEvent<HTMLButtonElement>) {
              props.onFocus?.(event);
              setHasFocusInside(false);
              parent.setHasFocusInside(true);
            },
            onKeyDown(event) {
              if (event.key === 'Enter') {
                event.stopPropagation();
                if (ref.current) {
                  ref.current?.click();
                }
              }
            },
          })
        )}
      >
        {label}
      </button>
      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          setHasFocusInside,
          isOpen,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen &&
            (isNested ? <FloatingPortal>{FloatingElement}</FloatingPortal> : FloatingElement)}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
});

interface MenuItemProps {
  label: React.ReactNode;
  disabled?: boolean;
}

export const MenuItem = React.forwardRef<
  HTMLButtonElement,
  MenuItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ label, disabled, ...props }, forwardedRef) => {
  const ref = useRef<HTMLButtonElement>(null);
  const menu = React.useContext(MenuContext);
  const item = useListItem();
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;

  return (
    <button
      {...props}
      ref={useMergeRefs([item.ref, forwardedRef, ref])}
      type='button'
      role='menuitem'
      className={props.className}
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      {...menu.getItemProps({
        onClick(event: React.MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
          menu.setHasFocusInside(true);
        },
        onKeyDown(event) {
          if (event.key === 'Enter') {
            event.stopPropagation();
            if (ref.current) {
              ref.current?.click();
            }
          }
        },
      })}
    >
      {label}
    </button>
  );
});

export const ROOT_NODE_ID = 'floating-root';

export const Menu = React.forwardRef<
  HTMLButtonElement,
  MenuProps & Omit<React.HTMLProps<HTMLButtonElement>, 'label'>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null || parentId === 'floating-root') {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});
