import { DropdownValue } from '../../utils/ComboBox';

export const formatJson = (jsonString: string, indent = 2) => {
  try {
    const parsedJson = JSON.parse(jsonString);
    return JSON.stringify(parsedJson, null, indent);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return jsonString;
  }
};

type Primitive = string | number | boolean;
interface OutputTypeOption {
  label: string;
  value: Primitive | Primitive[];
  children?: OutputTypeOption[];
}

export const generateOptions = (obj: object, parentKey = ''): OutputTypeOption[] => {
  let options: any[] = [];

  for (const key in obj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      options.push({
        label: currentKey,
        value: currentKey,
        children: generateOptions(obj[key], currentKey),
      });
    } else {
      options.push({ label: currentKey, value: currentKey });
    }
  }

  return options;
};

export const flattenOptions = (options: OutputTypeOption[]): DropdownValue[] => {
  let flattened: any[] = [];
  options.forEach((option) => {
    const { children, ...opt } = option;
    flattened.push(opt);
    if (children) {
      flattened = flattened.concat(flattenOptions(children));
    }
  });
  return flattened;
};
