import { GET_CANDIDATE_TRIGGERS, GET_TRIGGERS } from 'actions/actionsTypes';

const triggerReducer = (
  state = {
    triggers: [],
    candidate_triggers: [],
  },

  action
) => {
  switch (action.type) {
    case GET_TRIGGERS:
      return {
        ...state,
        triggers: action.payload,
      };

    case GET_CANDIDATE_TRIGGERS:
      return {
        ...state,
        candidate_triggers: action.payload,
      };

    default:
      return state;
  }
};

export default triggerReducer;
