import setPrev from 'actions/miscellaneous/setPrevPath';
import axios from 'axios';
import { isAfter } from 'date-fns';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth,
  clearState,
  setPrev,
  ...rest
}) => {
  const { authentication, isLoggedIn, reset } = useAuthentication();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          // set prev path in redux
          if (props.location.pathname.includes('/invitation/')) {
            setPrev(props.location.pathname);
          } else {
            setPrev('/');
            return <Redirect to={LOGIN_ROUTE} />;
          }
        }
        // check if token expire
        else if (
          authentication.token_expiry &&
          isAfter(new Date(), new Date(authentication.token_expiry))
        ) {
          // delete token from axios header
          delete axios.defaults.headers.common['Authorization'];

          // set prev path in redux
          if (props.location.pathname.includes('/invitation/')) {
            setPrev(props.location.pathname);
          } else {
            setPrev('/');
            reset();
            return <Redirect to={LOGIN_ROUTE} />;
          }
        } else {
          return <Component {...props} {...componentProps} />;
        }
      }}
    />
  );
};

export default connect(null, { setPrev })(PrivateRoute);
