import { HTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {}

export const Badge = ({ children, className, ...rest }: BadgeProps) => {
  return (
    <span
      className={twJoin('rounded bg-gray-200 px-[4px] py-[2px] text-caption-2', className)}
      {...rest}
    >
      {children}
    </span>
  );
};
