import { ModuleLayout } from 'components/layout/ModuleLayout';
import { CustomNumericResourceTable } from 'components/resources/custom-numerics/CustomNumericTable';

export const CustomNumbers = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <CustomNumericResourceTable />
    </ModuleLayout>
  );
};
