import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { AMB_LISTENING_API_URL, DESCRIPTION_PANEL_API_URL } from '../constants';

export interface PostAmbListeningVariable {
  id?: number;
  title: string;
  subtitle?: string;
  type: string;
  variable: string;
  container?: string | number;
  trigger: string | number | null;
  module?: number;
  position: number;
}

export interface PostAmbListeningData {
  id: number;
}

export const ambListeningAPI = {
  getAmbListening: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${AMB_LISTENING_API_URL}${queryKey[1]}`).then((response) => response.data),
  postAmbListening: (data) =>
    axios.post(AMB_LISTENING_API_URL, data).then((response) => response.data),
  putAmbListening: (data, ambId) =>
    axios.patch(`${AMB_LISTENING_API_URL}${ambId}/`, data).then((response) => response.data),
};

export const msgCardAPI = {
  getMsgCard: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${DESCRIPTION_PANEL_API_URL}${queryKey[1]}/`).then((response) => response.data),
  postMsgCard: (data) =>
    axios.post(DESCRIPTION_PANEL_API_URL, data).then((response) => response.data),
  putMsgCard: (data, msgId) =>
    axios.patch(`${DESCRIPTION_PANEL_API_URL}${msgId}/`, data).then((response) => response.data),
};
