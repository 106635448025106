import { Author, authorAPI } from '../../../api/author';
import { H4, H5 } from '../../utils/typo';
import { Icons } from '../../utils/Icons';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CustomToast } from '../../utils/toast-message';
import { useTeam } from '../../../hooks/useTeam';
import { DeleteModal } from '../../utils/modals/DeleteModal';

interface AuthorProps {
  author: Author;
  onOpen: (authorId: number | undefined) => void;
}

export function AuthorCard({ author, onOpen }: AuthorProps) {
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { team } = useTeam();
  const { mutate } = useMutation(authorAPI.deleteAuthor, {
    onSuccess: () => {
      toast.success(CustomToast, {
        data: 'Author Deleted Successfully',
      });
      queryClient.invalidateQueries(['teamAuthors', team.id]);
    },
  });

  const handleDelete = () => {
    mutate(author.id);
  };

  return (
    <>
      <div className='border-gra flex select-none gap-3 rounded-md border border-gray-200 p-4'>
        <div className='h-[64px] w-[64px]'>
          {!!author.image && (
            <img className='h-full rounded-full' src={author.image} alt={author.name} />
          )}
        </div>
        <div className='flex-1 space-y-1.5'>
          <H4 className='font-bold'>{author.name}</H4>
          <H5 className='font-normal'>{author.description}</H5>
        </div>
        <Icons.Edit className='cursor-pointer fill-gray-600' onClick={() => onOpen(author.id)} />
        <Icons.TrashBin className='cursor-pointer fill-gray-600' onClick={() => setOpen(true)} />
      </div>
      <DeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onDelete={handleDelete}
        title='Delete Author'
        content='Are you sure you want to delete this Author?'
      />
    </>
  );
}
