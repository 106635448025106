import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { KnowledgeBaseSavePayload } from 'components/utils/tiptap/tiptapInterfaces';
import { sprintf } from 'sprintf-js';
import {
  AI_KNOWLEDGE_BASE_API_URL,
  KB_PDF_JOB_CANCEL,
  KB_PDF_JOB_QUEUE,
  KB_PDF_PRESIGNED_POST,
  KNOWLEDGE_BASE_CONTENT_URL,
  VERIFY_AI_KB_API_URL,
} from '../constants';

type KnowledgeBaseType = {
  id: number;
  code: string;
  name: string;
  last_synced: string;
  last_verified: null;
  last_updated: string;
  team: {
    id: number;
    name: string;
  };
  permission_type: string;
  current_user_role: null | string;
};

export interface KnowledgeBasePDF {
  file_name: string;
}
export interface KnowledgeBasePDFPost extends KnowledgeBasePDF {
  file_size: number;
}

export interface PresignedPostResponse {
  url: string;
  fields: {
    'Content-Type'?: 'string';
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  };
}

interface KnowledgeBaseContent {
  text_json: string;
  markdown: string;
  last_updated: string;
  last_synced: string;
}

interface GetKnowledgeBaseContent extends KnowledgeBaseContent {
  code: string;
  current_user_role: string;
  id: number;
  isMirror: boolean;
  last_verified: string;
  mirrorId: number;
  mirrorName: string;
  name: string;
  permission_type: string;
  team: number;
  parsing_started?: string;
  parsing_queued?: string;
  parsing_pdf_file_name?: string;
  parsing_pdf_file_size?: number;
}

export const knowledgebaseAPI = {
  get: async (kbId: string, mirrorId: string | null = null) => {
    let API_URL = `${AI_KNOWLEDGE_BASE_API_URL}${kbId}/?from_flow_screen=true`;

    if (mirrorId) {
      API_URL = `${API_URL}&mirror_id=${mirrorId}`;
    }

    return axios.get<KnowledgeBaseType>(API_URL);
  },
  getContent: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<GetKnowledgeBaseContent>(sprintf(KNOWLEDGE_BASE_CONTENT_URL, queryKey[1]))
      .then((res) => res.data),
  saveContent: async (kbId: number, payload: KnowledgeBaseSavePayload) => {
    return axios
      .patch<KnowledgeBaseContent>(sprintf(KNOWLEDGE_BASE_CONTENT_URL, kbId), payload)
      .then((res) => res.data);
  },
  verify: (moduleId: number) =>
    axios.patch(VERIFY_AI_KB_API_URL + moduleId + '/').then((res) => res.data),
  getPdfPresignedPost: (moduleId: number, payload: KnowledgeBasePDF) =>
    axios
      .post<PresignedPostResponse>(sprintf(KB_PDF_PRESIGNED_POST, moduleId), payload)
      .then((res) => res.data),
  uploadPdfPresignedPost: ({ url, formData }: { url: string; formData: FormData }) => {
    const tmpAxiosInstance = axios.create();
    return tmpAxiosInstance
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  },
  queuePdfParsingJob: (moduleId: number, payload: KnowledgeBasePDFPost) =>
    axios.post(sprintf(KB_PDF_JOB_QUEUE, moduleId), payload).then((res) => res.data),
  cancelPdfParsingJob: (moduleId: number) =>
    axios.post(sprintf(KB_PDF_JOB_CANCEL, moduleId)).then((res) => res.data),
};
