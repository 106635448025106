import { Box, Button, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/calculator/dragable/ListItem';
import {
  convertCondition,
  removePrefixesInCondition,
} from 'components/resources/triggers/SituationBuilderUtils';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { PanelLabel, StyledInput } from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { useKnoweledgeBase } from 'hooks/useEHRVariables';
import { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { FormGroup } from 'reactstrap';
import { getSuggestions, getVariables } from 'utils/suggestions';
import { convertCodeToText } from 'utils/utilityFunctions';
import '../conditional_text.css';
import ConditionBuilderForm from './ConditionBuilderForm';
import InlineEdit from './InlineEdit';
import { conditionalTextStyles } from './styles';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

const suggestionsToExclude: SuggestionTypes[] = [
  'link',
  'reference',
  'knowledge_base',
  'conditional_text',
  'media',
  'contact_number',
  'ehr_order',
];

interface ConditionalTextAccordionItemProps {
  item: any;
  conditionIndex: number;
  moduleId: string;
  numerics: any;
  calculatorId: string;
  type: string;
  setCaseText: (conditionIndex: any, text: string, event: any, tiptapJSON?: any) => void;
  setRawCondition: (
    conditionIndex: any,
    condition: any,
    conditionLogic: any,
    builderConditionJson: any
  ) => void;
  indexNo: number;
  deleteCondition: (index: number) => void;
  resourceSection: boolean;
  id: string;
  conditionalTextConditionsHandler: (conditionIndex: any, label: any) => void; // Replace 'any' with the appropriate type for conditionalTextConditionsHandler
}
interface AccordionSummaryProps {
  conditionalText?: boolean;
  expanded?: boolean;
}
interface Suggestion {
  name: string;
  code: string;
}

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: (props: AccordionSummaryProps) =>
      props.conditionalText && props.expanded ? 'white' : '#F4F6F9',
    borderBottom: '1px solid #E4E4E4',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    marginRight: 25,
    marginLeft: 25,
    padding: 0,
  },
  content: {
    alignItems: 'center',
    '&:hover': {
      '& .ct-action-btns': {
        display: (props: AccordionSummaryProps) => !props.expanded && 'flex !important',
      },
    },
  },
  focused: {
    backgroundColor: 'transparent !important',
  },
  focusVisible: {
    backgroundColor: 'transparent !important',
  },
})(AccordionSummary);

export default function ConditionalTextAccordionItem({
  item,
  conditionIndex,
  moduleId,
  numerics,
  calculatorId,
  type,
  setCaseText,
  setRawCondition,
  indexNo,
  deleteCondition,
  resourceSection,
  id,
  conditionalTextConditionsHandler,
}: ConditionalTextAccordionItemProps) {
  const classes = conditionalTextStyles();
  const [condition, setCondition] = useState('');
  const [conditionLogic, setConditionLogic] = useState<any>();
  const [variables, setVariables] = useState([]);
  const [, setConditionalText] = useState('');
  const [, setConditionalTextJson] = useState(null);
  const [conditionModal, setConditionModal] = useState(false);
  const [, setChoiceMapping] = useState(null);
  const [builderConditionJson, setBuilderConditionJson] = useState(null);
  const [prevTextJson, setPrevTextJson] = useState(null);
  const [textEditorsuggestions, setTextEditorsuggestions] = useState<Suggestion[]>([]);
  const knowledgeBases = useKnoweledgeBase();

  const [expanded, setExpanded] = useState('panel1');
  const [inlineEditMode] = useState(false);
  const [label, setLabel] = useState(
    item.is_default
      ? 'None of the above (Default)'
      : item.label || `Condition ${conditionIndex + 1}`
  );

  useEffect(() => {
    const fetchData = async () => {
      const vars = await getVariables(type === 'calculator' ? calculatorId : moduleId, type);
      setVariables(vars);
      populateSuggestions();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (item?.text_content) {
      setPrevTextJson(item?.text_content);
    }
    if (item?.builder_condition_json) {
      setBuilderConditionJson(item?.builder_condition_json);
    }
    if (item?.condition) {
      setCondition(item?.condition);
    }
    if (item?.condition_logic) {
      setConditionLogic(item?.condition_logic);
    }
    if (item?.label) {
      setLabel(item?.label);
    }
  }, [id]);

  const changeText = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');
    setConditionalText(text);
    setConditionalTextJson(e);
    setCaseText(conditionIndex, text, e);
  };

  const setBuilderCondition = (e) => {
    let formattedCondition = removePrefixesInCondition(e);
    let index = formattedCondition.indexOf('IN');

    while (index !== -1) {
      formattedCondition = convertCondition(formattedCondition, index);
      index = formattedCondition.indexOf('IN');
    }
    setCondition(formattedCondition);
  };

  const addConditionTrigger = () => {
    setRawCondition(conditionIndex, condition, conditionLogic, builderConditionJson);
  };

  const setQueryValue = (e) => {
    setBuilderConditionJson(e);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (!inlineEditMode || expanded !== `panel${indexNo + 1}`)
      setExpanded(newExpanded ? panel : false);
  };

  const saveLabelChange = (label) => {
    setLabel(label);
    conditionalTextConditionsHandler(conditionIndex, label);
  };

  const populateSuggestions = async () => {
    const suggestions = await getSuggestions(
      type === 'calculator' ? calculatorId : moduleId,
      false,
      type
    );
    setTextEditorsuggestions([...suggestions, ...knowledgeBases]);
  };

  const isAccordianExpanded = expanded === `panel${indexNo + 1}`;
  const accordionProps = {
    conditionalText: true,
    square: true,
    expanded: isAccordianExpanded,
  };

  {
    /* set the height of the conditional sliding pane to 80% as the default,which is 90%. */
  }

  const slidingPaneBaseClasses = 'no-padding sliding-panel-shadow build-popup con-text-cus-height';
  const slidingPaneResourceClasses = 'sliding-pan-modal sliding-panel-shadow';

  const slidingPaneClasses = resourceSection ? slidingPaneResourceClasses : slidingPaneBaseClasses;

  return (
    <Accordion
      {...accordionProps}
      style={{ position: 'relative', marginBottom: 0, marginTop: 0 }}
      onChange={handleChange(`panel${indexNo + 1}`)}
    >
      <StyledAccordionSummary
        conditionalText
        expanded={isAccordianExpanded}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        {isAccordianExpanded ? (
          <KeyboardArrowDownIcon style={{ color: '#A6A6A6', marginRight: 7 }} />
        ) : (
          <ChevronRightIcon style={{ color: '#A6A6A6', marginRight: 7 }} />
        )}
        <InlineEdit
          label={label}
          saveChanges={saveLabelChange}
          showActionButtons={!item.is_default}
          width={isAccordianExpanded ? 420 : 360}
          isEditable={!item.is_default && isAccordianExpanded}
          deleteField={() => deleteCondition(conditionIndex)}
        />
      </StyledAccordionSummary>

      <AccordionDetails>
        <Box style={{ width: '100%' }}>
          {!item.is_default && (
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '25px',
                  color: '#969696',
                  marginTop: 12,
                  marginBottom: 8,
                }}
              >
                Category Condition
              </Typography>

              <div style={{ display: 'flex' }}>
                <StyledInput
                  disabled
                  value={item?.condition ? convertCodeToText(item?.condition) : null}
                />

                <Button
                  className={classes.buildBtn}
                  onClick={() => setConditionModal((prevState) => !prevState)}
                >
                  Build
                </Button>
              </div>
            </Box>
          )}

          <FormGroup style={{ marginBottom: '23px' }}>
            <PanelLabel
              style={{
                marginBottom: '12px',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '25px',
                color: '#969696',
              }}
              for='conditional_text'
            >
              Description
            </PanelLabel>
            <AvoEditor
              conditionalText
              suggestions={textEditorsuggestions}
              setValue={changeText}
              prevValue={prevTextJson ? prevTextJson : null}
              disableConditionalText={true}
              numerics={numerics}
              moduleId={moduleId}
              variables={variables}
              knowledgeBases={knowledgeBases}
              moduleType={type}
              richTextButtonShowList={[
                ToolbarButton.TEXT_STYLE,
                ToolbarButton.BOLD,
                ToolbarButton.ITALIC,
                ToolbarButton.HIGHLIGHT,
                ToolbarButton.INFOBOX,
                ToolbarButton.INSERT_LINK,
                ToolbarButton.VARIABLES,
              ]}
              wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
              onUpdate={(editor) =>
                setCaseText(conditionIndex, editor.getText(), null, editor.getJSON())
              }
              initialContent={item.text_content_tiptap}
              suggestionsToExclude={suggestionsToExclude}
              richTextButtonsShowListTiptap={richTextButtonsShowList}
            />
          </FormGroup>
        </Box>
      </AccordionDetails>

      <SlidingPane
        isOpen={conditionModal}
        onRequestClose={() => setConditionModal((prevState) => !prevState)}
        from='bottom'
        // hideHeader
        className={slidingPaneClasses}
        overlayClassName={
          resourceSection
            ? 'sliding-panel-cards high-z-index'
            : 'sliding-panel-cards conditional-text-popup-overlay high-zindex'
        }
        title={
          <div>
            Condition Builder
            <HighlightOffIcon
              className='backIcon'
              onClick={() => setConditionModal((prevState) => !prevState)}
            ></HighlightOffIcon>
          </div>
        }
        width='587px'
        closeIcon={
          <div>
            <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
          </div>
        }
      >
        <ConditionBuilderForm
          moduleId={moduleId}
          calculatorId={calculatorId}
          type={type}
          setCondition={setBuilderCondition}
          setConditionLogic={setConditionLogic}
          setQueryValue={setQueryValue}
          setChoiceMapping={setChoiceMapping}
          queryValue={item?.builder_condition_json ? item?.builder_condition_json : null}
          conditionIndex={conditionIndex}
          addConditionTrigger={addConditionTrigger}
          closePanel={() => setConditionModal(false)}
        />
      </SlidingPane>
    </Accordion>
  );
}
