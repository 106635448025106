import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Trigger } from 'api/resources';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { hasViewOnlyPermission } from 'utils/permissions';
import { UIElement } from '../../../api/moduleElement';
import { MODULE_TYPES } from '../../../constants';
import { Trigger as TriggerComponent } from '../../app-previews/Trigger';
import { VariablesPreview } from '../../app-previews/common/variable';
import TextInputForm from '../../panels/TextInputForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { questionStyle } from '../../utils/styles';
import { Body2, H4 } from '../../utils/typo';
import { ModuleElementContext } from '../ModuleBoard';

interface FormValues {
  id?: string;
  title: string;
  subtitle: string;
  variable: string;
  container: any;
  trigger?: Trigger;
  module?: number;
  position?: number;
  is_optional: boolean;
  default_value: any;
  default_value_tiptap: any;
}

interface TextInputCardProps {
  element: UIElement;
  containerId?: number;
}

export const TextInputCard = ({ element, containerId }: TextInputCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, duplicateUIElement, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo<FormValues>(
    () => ({
      title: element.title ?? '',
      subtitle: element.subtitle ?? '',
      variable: element.variable_item ? element.variable_item.name : '',
      container: element.container,
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      position: element.position,
      is_optional: !!element.is_optional,
      default_value: element.default_value,
      default_value_tiptap: element.default_value_tiptap,
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const variable = form.watch('variable');
  const variables = useMemo(
    () => [
      {
        type: 'text',
        name: variable,
      },
    ],
    [variable]
  );

  // TODO: need refactoring
  //choices trigger element
  let cardStyle = {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '10px',
    marginBottom: '0px',
    paddingBottom: '10px',
    border: 'none',
    borderRadius: '30px',
    width: isContainer ? '100%' : '576px',
    backgroundColor: isContainer ? 'transparent' : '#FFFFFF',
    boxShadow: isContainer
      ? 'none'
      : '0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.06)',
  };

  return (
    <>
      <div
        data-testid='choice-panel__test'
        style={
          {
            position: panelOpen ? 'relative' : '',
            zIndex: panelOpen ? '30' : '',
            maxWidth: isContainer ? '100%' : '580px',
            // marginLeft: this.props.answerForm ? '' : !this.props.container && '25px',
            marginBottom: 15,
          } as React.CSSProperties
        }
      >
        <TriggerComponent trigger={form.watch('trigger')} containerCard={isContainer} />
        <Card
          className='item-to-hover'
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(module?.type) && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Typography>
            <div className='flex text-center'>
              <H4>{form.watch('title')}</H4>
              {form.watch('is_optional') && (
                <Body2 className='ml-1 text-gray-700'>(Optional)</Body2>
              )}
            </div>
            <Typography
              className='pb-2'
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#ADADAD',
              }}
            >
              {form.watch('subtitle')}
            </Typography>
            <Box
              style={{
                border: '1px solid #C5D1D8',
                borderRadius: '6px',
                height: 40,
                padding: '9px 11px 15px',
                marginTop: '12px',
              }}
            >
              <Typography
                style={{
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#515151',
                }}
              >
                {!!form.watch('default_value') ? (
                  <AvoEditorPreview
                    contentTiptap={form.watch('default_value_tiptap')}
                    contentDraftJS={form.watch('default_value')}
                  />
                ) : (
                  'Sample text'
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {!isContainer && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='w-[620px] overflow-x-visible bg-white'>
          <TextInputForm
            handleRichText={(value: any) => form.setValue('default_value', value)}
            handleDefaultValueTiptap={(value: any) => form.setValue('default_value_tiptap', value)}
            handleSelectedItem={() => {}}
            handleAddTitle={(title: string) => form.setValue('title', title)}
            handleAddSubtitle={(subtitle: string) => form.setValue('subtitle', subtitle)}
            handleAddVariable={(variable: string) => form.setValue('variable', variable)}
            handleAddTrigger={(triggerId: string) => setTriggerId(triggerId)}
            handleTextInputOptional={(isOptional: boolean) =>
              form.setValue('is_optional', isOptional)
            }
            moduleId={module?.id}
            container={form.watch('container')}
            position={element.position}
            toggleModalOnCreate={handlePanelClose}
            toggleModalOnEdit={handlePanelClose}
            textInputId={element.id}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
