import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useRef, useState } from 'react';

export function TextStylesButton({ editor }) {
  const [direction, setDirection] = useState('down');
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = (action: string) => {
    switch (action) {
      case 'h1':
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 'h2':
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 'h3':
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      case 'h4':
        editor.chain().focus().toggleHeading({ level: 4 }).run();
        break;
      case 'h5':
        editor.chain().focus().toggleHeading({ level: 5 }).run();
        break;
      case 'h6':
        editor.chain().focus().toggleHeading({ level: 6 }).run();
        break;
      case 'normal text':
        editor.chain().focus().clearNodes().unsetAllMarks().run();
        break;
      default:
        break;
    }
  };

  const checkDirection = () => {
    const buttonElement = buttonRef.current;
    const menu = menuRef.current;

    if (!buttonElement) {
      return;
    }

    const buttonRect = buttonElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    if (spaceBelow < 200 && spaceAbove > spaceBelow) {
      setDirection('up');
    } else {
      setDirection('down');
    }

    if (menu) {
      menu.style.top = direction === 'up' ? 'auto' : '100%';
      menu.style.bottom = direction === 'up' ? '100%' : 'auto';
    }
  };

  useEffect(() => {
    checkDirection();
    window.addEventListener('resize', checkDirection);
    return () => {
      window.removeEventListener('resize', checkDirection);
    };
  }, []);

  const getButtonDisplayName = () => {
    const attr = editor.getAttributes('heading');
    if (attr.level) {
      return 'Heading ' + attr.level;
    } else {
      return 'Normal text';
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <div className='relative inline-block text-left'>
      <Menu>
        {({ open }) => (
          <>
            <Tooltip title='Text style' placement='top'>
              <Menu.Button
                ref={buttonRef}
                type='button'
                className='inline-flex items-center justify-center border-gray-300 pl-2 pr-2'
              >
                {getButtonDisplayName()}
                <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
              </Menu.Button>
            </Tooltip>

            {open && (
              <Menu.Items
                static
                ref={menuRef}
                className={`${
                  direction === 'up' ? 'bottom-0 mb-5 origin-bottom-left' : 'origin-top-left'
                } absolute left-0 z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
              >
                <div className='py-1' role='none'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h1')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 1
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h2')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 2
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h3')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 3
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h4')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 4 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 4
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h5')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 5 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 5
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('h6')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          editor.isActive('heading', { level: 6 }) ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Heading 6
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleButtonClick('normal text')}
                        className={`${active ? 'bg-gray-100' : ''} ${
                          !editor.isActive('heading') ? 'is-active' : ''
                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                        role='menuitem'
                      >
                        Normal Text
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            )}
          </>
        )}
      </Menu>
    </div>
  );
}
