import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ComboBox } from 'components/utils/ComboBox';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import {
  NumberFormattingOptions,
  SuggestionTypeEnum,
  TiptapVariableTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SuggestionHandlerProps } from '../MentionPopover';
import { MentionContext } from '../../../module/MentionContext';
import { Icons } from '../../../Icons';
import {
  autoUpdate,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';

interface NumberFormattingOptionsProps {
  title: string;
  options: NumberFormattingOptions;
  onClick: (key: string) => void;
  selected: string | number;
}

export const VariableHandler = ({ onDisabledChange, onChange }: SuggestionHandlerProps) => {
  const { suggestions } = useContext(MentionContext);
  const filteredSuggestions = useMemo(() => {
    const suggestionType = SuggestionTypeEnum.VARIABLE;

    return suggestions.filter(
      (suggestion) =>
        suggestion.type.includes(suggestionType) ||
        suggestion.type === SuggestionTypeEnum.DATA_EXTRACTOR ||
        suggestion.type === SuggestionTypeEnum.NUMBER
    );
  }, [suggestions]);

  const [variable, setVariable] = useState<string>();
  const [variableType, setVariableType] = useState<string>('');
  const [decimal, setDecimal] = useState<string>('0');
  const [rounding, setRounding] = useState<string>('ceil');
  const isNumber = useMemo(() => ['number', 'formula'].includes(variableType), [variableType]);

  const variableOptions = useMemo(() => {
    const varOptions = filteredSuggestions.filter((option) => option.type === variableType);
    return varOptions.map((option) => ({
      value: (option.code || option.unique_code) as string,
      label: option.name,
    }));
  }, [variableType]);

  const filteredVariableTypes = useMemo(() => {
    return VARIABLE_TYPES.filter((type) =>
      filteredSuggestions.some((option) => option.type === type.id)
    );
  }, [filteredSuggestions]);

  const changeVariableType = (value: string) => {
    setVariable('');
    setVariableType(value);
  };

  useEffect(() => {
    const option = filteredSuggestions.find(
      (option) => (option.code || option.unique_code) === variable
    );
    if (!option || !variable) {
      onDisabledChange(true);
      return;
    }

    onChange({
      ...option,
      decimalPlaces: isNumber ? decimal : undefined,
      decimalRounding: isNumber ? rounding : undefined,
    });
    onDisabledChange(false);
  }, [filteredSuggestions, variable, isNumber, decimal, rounding]);

  return (
    <>
      <DropdownSingle
        value={variableType}
        options={filteredVariableTypes}
        placeholder='Select Variable Type'
        onChange={(value) => changeVariableType(value as string)}
      />
      <ComboBox
        selectedValue={variable}
        options={variableOptions}
        disabled={!variableType}
        onChange={(value) => setVariable(value as string)}
      />
      {isNumber && (
        <div className='flex gap-5'>
          <FormattingOptions
            title='Show'
            selected={decimal}
            onClick={setDecimal}
            options={DecimalOptions}
          />
          <FormattingOptions
            title='Rounded'
            selected={rounding}
            onClick={setRounding}
            options={RoundingOptions}
          />
        </div>
      )}
    </>
  );
};

export const FormattingOptions = ({
  title,
  options,
  onClick,
  selected,
}: NumberFormattingOptionsProps) => {
  const [open, onOpenChange] = useState(false);
  const [activeIndex, onNavigate] = useState<number | null>(null);
  const elementRef = useRef<(HTMLButtonElement | null)[]>([]);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: 'bottom-start',
    middleware: [shift({ crossAxis: true })],
    whileElementsMounted: autoUpdate,
  });
  const listNav = useListNavigation(context, {
    listRef: elementRef,
    activeIndex,
    onNavigate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    listNav,
  ]);

  return (
    <div className='flex gap-[8px] pb-[16px]'>
      <p className='m-0 text-body-2 text-gray-700'>{title}</p>
      <button
        type='button'
        className='flex w-[90px] text-button-1 text-primary-600 focus:bg-transparent'
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {options?.[selected]}
        <ChevronDownIcon className='h-[20px] w-[20px]' />
      </button>
      {open && (
        <div
          className='absolute z-10 flex w-[120px] flex-col items-start rounded bg-white py-[4px] shadow-04 focus:outline-none'
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {Object.entries(options as NumberFormattingOptions).map(([key, value], index) => (
            <button
              type='button'
              tabIndex={activeIndex === index ? 0 : -1}
              className='flex w-full cursor-pointer items-center justify-between px-[12px] py-[10px] focus:bg-gray-100 focus:outline-none'
              ref={(node) => {
                elementRef.current[index] = node;
              }}
              {...getItemProps({
                onClick: () => {
                  onClick(key);
                  onOpenChange(false);
                },
              })}
            >
              {value}
              {options[selected] === value && <Icons.Check />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export const RoundingOptions: NumberFormattingOptions = {
  ceil: 'Up',
  floor: 'Down',
  round: 'Rounded',
};

export const DecimalOptions: NumberFormattingOptions = {
  0: 'Integer',
  1: '1 Decimal',
  2: '2 Decimal',
};

const VARIABLE_TYPES: TiptapVariableTypes[] = [
  {
    id: 'choice_variable',
    title: 'Multiple Choice',
  },
  {
    id: 'multi_variable',
    title: 'Checkboxes',
  },
  {
    id: 'gptbox_variable',
    title: 'GPT Box',
  },
  {
    id: 'text_input_variable',
    title: 'Text',
  },
  {
    id: 'message_variable',
    title: 'Message Variable',
  },
  {
    id: 'formula',
    title: 'Formula',
  },
  {
    id: 'number',
    title: 'Numbers',
  },
  {
    id: 'ambient_variable',
    title: 'AI Scribe',
  },
  {
    id: SuggestionTypeEnum.DATA_EXTRACTOR,
    title: 'Data Extractor',
  },
  {
    id: 'apibox_variable',
    title: 'API Box',
  },
];
