import { Box, MenuItem, MenuList, Popover, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Component } from 'react';
import { CategoricalOverrideProps } from './types';
interface CategoricalOverrideState {
  choiceEl: any;
  currentIndex: any;
}
export default class CategoricalOverride extends Component<
  CategoricalOverrideProps,
  CategoricalOverrideState
> {
  constructor(props: CategoricalOverrideProps) {
    super(props);
    this.state = {
      choiceEl: null,
      currentIndex: 0,
    };
  }

  render() {
    return (
      <div
        className='item-to-hover'
        style={{
          background: '#FFFFFF',
          border: '1px solid #E6E6E6',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
          borderRadius: '10px',
          width: '100%',
          //  height: 227,
          paddingLeft: 28,
          paddingBottom: 28,
          paddingRight: 28,
          paddingTop: 13,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '36px',
              textTransform: 'uppercase',
              color: '#8C94A1',
            }}
          >
            Categorical output (severity)
          </Typography>
          <Close
            className='item-to-show'
            style={{
              color: '#C7C7C7',
              marginLeft: 'auto',
              fontSize: 20,
              cursor: 'pointer',
            }}
            onClick={() => this.props.removeIntegration(this.props.choicePanel.choice.id)}
          />
        </Box>
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            wordBreak: 'break-word',
            color: '#545454',
          }}
        >
          Current CHOICE item ‘{this.props.choicePanel.choice.title}’ has{' '}
          {this.props.choicePanel.choice.choices.length} buttons,{' '}
          {this.props.choicePanel.choice.choices.map(
            (choice, index) =>
              choice.name + (this.props.choicePanel.choice.choices[index + 1] ? ', ' : ' ')
          )}
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            marginTop: 15,
            alignItems: 'center',
          }}
        >
          <Typography className='categoric-label'>{this.props.choicePanel.choice.title}</Typography>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '16px',
              marginLeft: '15px',
              width: 320,
              color: '#191919',
              wordBreak: 'break-word',
            }}
          >
            {this.props.choicePanel.choice.choices.map(
              (choiceObj, index) =>
                `${choiceObj.name} ${this.props.choicePanel.choice.choices[index + 1] ? ', ' : ''}`
            )}
          </Typography>
        </Box>
        {this.props.choicePanel.mappings.map((choice, index) => (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography className='categoric-label'>
              {' '}
              {choice.output_category_label}
            </Typography>{' '}
            <Box
              style={{
                background: '#FFFFFF',
                border: '1px solid #CCCCCC',
                display: 'flex',
                marginLeft: 20,
                alignItems: 'center',
                boxSizing: 'border-box',
                borderRadius: '5px',
                width: '389px',
                paddingLeft: 18,
                //   paddingTop: 10,
                //   paddingBottom: 10,
                paddingRight: 10,
                height: '37px',
                cursor: 'pointer',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                this.setState({
                  choiceEl: e.target,
                  currentIndex: index,
                } as Pick<CategoricalOverrideState, any>);
              }}
            >
              {choice.choice_name ? choice.choice_name : 'Not Selected'}

              <KeyboardArrowDown style={{ marginLeft: 'auto', color: '#A6A6A6' }} />
            </Box>
          </Box>
        ))}
        <Popover
          disableScrollLock
          // disableEnforceFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          anchorEl={this.state.choiceEl}
          open={Boolean(this.state.choiceEl)}
          onClose={() => this.setState({ choiceEl: null })}
        >
          <MenuList autoFocus>
            {this.props.choicePanel.choice.choices &&
              this.props.choicePanel.choice.choices.map((choice, index) => (
                <MenuItem
                  divider
                  style={{ width: '288px' }}
                  onClick={() => {
                    this.props.insertCategoricalMapping(
                      this.props.choicePanel.choice.id,
                      choice.id,
                      this.props.choicePanel.mappings[this.state.currentIndex].output_category_id,
                      choice.name
                    );
                    this.setState({ choiceEl: null });
                  }}
                >
                  {choice.name}
                </MenuItem>
              ))}
            <MenuItem
              style={{ width: '288px' }}
              onClick={() => {
                this.props.insertCategoricalMapping(
                  this.props.choicePanel.choice.id,
                  null,
                  this.props.choicePanel.mappings[this.state.currentIndex].output_category_id,
                  null
                );
                this.setState({ choiceEl: null });
              }}
            >
              Not Selected
            </MenuItem>
          </MenuList>
        </Popover>
      </div>
    );
  }
}
