import { QueryFunctionContext } from '@tanstack/react-query';
import { JSONContent } from '@tiptap/react';
import axios from 'axios';
import { sprintf } from 'sprintf-js';
import {
  CONTAINER_CARD_API_URL,
  DESCRIPTION_PANEL_API_URL,
  MODULE_API_URL,
  MODULE_API_V1_URL,
  MODULE_TYPES,
  UI_ELEMENTS_API_URL,
  UI_ELEMENT_DUPLICATE_API_URL,
} from '../constants';
import { DraftJSRawType } from '../v2/types/draftJSType';

// TODO: make any type interface
interface Container {
  id: number;
  position: number;

  [key: string]: any;
}

export interface UIElement extends Container {
  resourcetype: (typeof ResourceTypes)[keyof typeof ResourceTypes];
}

export enum ResourceTypes {
  multipleChoice = 'ChoicePanel',
  group = 'Container',
  numbers = 'ValuePanel',
  checkboxes = 'MultiChoicePanel',
  message = 'DescriptionPanel',
  sectionDivider = 'SectionDivider',
  textInput = 'TextInput',
  gptBox = 'GPTBox',
  ambientListening = 'AmbientListening',
  dataExtractor = 'DataExtractor',
  apiBox = 'APIBox',
}

export interface DuplicateRequest {
  moduleId: number;
  elementId: number;
  queryParam: string;
  moduleType: MODULE_TYPES;
}

interface ChangePositionRequest {
  moduleType: string;
  moduleId: string;
  positions: Positions;
}
export interface Positions {
  [key: number]: number;
}

interface MessageCard {
  calculator: null | number;
  container: null | number;
  desc_json_tiptap: any;
  description_text: string;
  description_text_content: any;
  description_text_content_long: any;
  description_text_content_short: any;
  description_text_long: string;
  description_text_short: string;
  embeded_image_ids: any[];
  examples: any[];
  id: number;
  is_action: boolean;
  is_introduction: boolean;
  is_long_desc_enabled: boolean;
  long_desc_json_tiptap: any;
  module: null | number;
  new_description_text_content: any;
  new_description_text_content_long: any;
  new_description_text_content_short: any;
  panel_type: string;
  position: number;
  short_desc_json_tiptap: any;
  title: string;
  tools: any[];
  trigger: number;
  variable_item: any;
}

/** Answer data interface definition */
interface TriggerDetail {
  id?: number;
  title: string;
  unique_code?: string;
}

interface AnswerElementTriggerDetail extends TriggerDetail {
  formula?: any[];
  numeric?: any[];
  variable?: any[];
}

interface AnswerCard {
  card_summary: string;
  card_text_json_tiptap: JSONContent | null;
  card_title: string;
  /** text card / media card */
  card_type: 'T' | 'M';
  has_mirrors: boolean;
  id: number;
  media_items: any[];
  new_card_text_content: DraftJSRawType;
  original_copy: number | null;
  position: number;
  /** TextCard / Image(?) */
  resourcetype: 'TextCard' | any;
  status: '0' | '1';
  trigger_detail: TriggerDetail;
}

export interface AnswerElementSimple {
  id: number;
  module: number;
  status: '0' | '1';
  title: string;
  trigger: number | null;
  triggerDetail: AnswerElementTriggerDetail;
  answerCards: AnswerCard[];
}

interface AnswerElement {
  id: number;
  module: number;
  status: '0' | '1';
  title: string;
  trigger: number | null;
  trigger_detail: AnswerElementTriggerDetail;
  answer_cards: AnswerCard[];
  position: number;
  input_reviews: string[];
}

export const moduleElementAPI = {
  getContainers: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<Container[]>(`${sprintf(MODULE_API_V1_URL, queryKey[0], queryKey[1])}container_cards`)
      .then((res) => res.data),
  getUIElements: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<UIElement[]>(`${sprintf(MODULE_API_V1_URL, queryKey[0], queryKey[1])}ui_elements`)
      .then((res) => res.data),

  deleteUIElement: (elementId: number) =>
    axios.delete(UI_ELEMENTS_API_URL + elementId + '/').then((res) => res.data),
  duplicateUIElement: ({ moduleId, elementId, queryParam, moduleType }: DuplicateRequest) => {
    const payload = {
      [moduleType === MODULE_TYPES.CALCULATOR ? MODULE_TYPES.CALCULATOR : 'module']: moduleId,
    };
    return axios
      .post(`${UI_ELEMENT_DUPLICATE_API_URL}${elementId}?type=${queryParam}`, payload)
      .then((res) => res.data);
  },

  deleteContainer: (elementId: number) =>
    axios.delete(CONTAINER_CARD_API_URL + elementId + '/').then((res) => res.data),

  saveUIElementPosition: ({ moduleType, moduleId, positions }: ChangePositionRequest) =>
    axios
      .put(`${sprintf(MODULE_API_V1_URL, moduleType, moduleId)}ui_element_position/`, {
        positions,
      })
      .then((res) => res.data),

  getMessageCard: ({ queryKey }: QueryFunctionContext) =>
    axios.get<MessageCard>(`${DESCRIPTION_PANEL_API_URL}${queryKey[1]}/`).then((res) => res.data),

  getAnswers: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<AnswerElement[]>(`${MODULE_API_URL}${queryKey[1]}/answer_pages`)
      .then((res) => res.data),
};
