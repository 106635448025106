import { StyledInput } from 'components/utils/styled-components/FormStyle';
import { onlyAllowNumbers } from 'utils/utilityFunctions';
import CharacterLimit from './fieldCharLimit';
import FieldLabel from './fieldLabel';

interface FormInputProps {
  name: string;
  label: string;
  value: string;

  unit?: string;
  detail?: string;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  addToggle?: boolean;
  placeholder?: string;
  toggleValue?: boolean;
  type?: 'text' | 'number' | 'password' | undefined;
  dataTestId?: string;

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInputField: React.FC<FormInputProps> = ({
  dataTestId,
  addToggle = false,
  disabled = false,
  required = false,
  toggleValue = false,
  type = 'text',
  unit = 'characters',
  placeholder = '',
  detail,
  label,
  name,
  maxLength,
  value,
  handleToggle,
  onChange,
}) => {
  return (
    <>
      <FieldLabel
        label={label}
        detail={detail}
        required={required}
        addToggle={addToggle}
        toggleValue={toggleValue}
        handleToggle={handleToggle}
      />

      <StyledInput
        data-testid={dataTestId}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyDown={(e) => type === 'number' && onlyAllowNumbers(e)}
        onWheel={(e) => type === 'number' && (e.target as HTMLInputElement).blur()}
      />

      {type === 'text' && maxLength && (
        <CharacterLimit currentCount={value?.length || 0} totalCount={maxLength || 0} unit={unit} />
      )}
    </>
  );
};

export default FormInputField;
