import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import Button from 'components/utils/Button';
import { Modal } from 'components/utils/modals/Modal';
import RequiredMark from 'components/utils/requiredMark';
import { Fragment, useState } from 'react';
import { IAuthor } from './AddAuthorController';
import AddNewAuthorModal from './AddNewAuthorModal';
import AddNewAuthorTitleModal from './AddNewAuthorTitleModal';
import { useTeam } from '../../hooks/useTeam';
import { Author, authorAPI } from '../../api/author';

const predefinedTitles = [
  'First Author',
  'Second Author',
  'Third Author',
  'Fourth Author',
  'Fifth Author',
  'Corresponding Author',
  'Co-author',
  'Senior Author',
  'Author',
];

interface AddAuthorModalProps {
  open: boolean;
  selectedAuthorIds: number[];
  onClose: () => void;
  onAdd: (author: IAuthor) => void;
}

const AddAuthorModal = ({ open, selectedAuthorIds, onClose, onAdd }: AddAuthorModalProps) => {
  const { team } = useTeam();
  const { data: teamAuthors } = useQuery(['teamAuthors', team.id], authorAPI.getAuthors);
  const [title, setTitle] = useState<string>();
  const [openNewAuthorModal, setOpenNewAuthorModal] = useState(false);
  const [openNewTitleModal, setOpenNewTitleModal] = useState(false);
  const [author, setAuthor] = useState<Author>();

  const onClickAdd = () => {
    if (!author || !title) return;
    onAdd({ ...author, title });
    setTitle(undefined);
    setAuthor(undefined);
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className='!overflow-visible'>
        <Modal.Head onClose={onClose}>Add Author</Modal.Head>
        <Modal.Body>
          <div className='space-y-4'>
            <div className='space-y-2'>
              <div className='flex space-x-1'>
                <div>Select Author</div>
                <RequiredMark />
              </div>
              <Listbox as='div' className='relative w-3/4' onChange={setAuthor}>
                {({ open }) => (
                  <>
                    <Listbox.Button className='flex w-full items-center justify-between rounded border border-gray-500 px-[12px] py-[10px]'>
                      {author ? author.name : <div className='text-gray-500'>Select Author</div>}
                      <div className={clsx('transition-all', { 'rotate-180': open })}>
                        <DropdownArrowIcon />
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      enter='transition duration-100 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-75 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 max-h-[300px] w-full overflow-y-auto rounded border border-gray-500 bg-white'>
                        {teamAuthors
                          ?.filter((author) => !selectedAuthorIds.includes(author.id))
                          .map((author, idx) => (
                            <Listbox.Option
                              key={idx}
                              value={author}
                              className={({ active }) =>
                                clsx({
                                  'bg-primary-200': active,
                                })
                              }
                            >
                              {({ selected }) => (
                                <div className='flex cursor-pointer items-center justify-between px-[12px] py-[10px]'>
                                  <div>{author.name}</div>
                                  {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        <div
                          className='cursor-pointer px-[12px] py-[10px] font-bold hover:bg-primary-200'
                          onClick={() => setOpenNewAuthorModal(true)}
                        >
                          + Add new author
                        </div>
                      </Listbox.Options>
                    </Transition>
                  </>
                )}
              </Listbox>
            </div>
            <div className='space-y-2'>
              <div className='flex space-x-1'>
                <div>Select Title</div>
                <RequiredMark />
              </div>
              <Listbox as='div' className='relative w-3/4' onChange={setTitle}>
                {({ open }) => (
                  <>
                    <Listbox.Button className='flex w-full items-center justify-between rounded border border-gray-500 px-[12px] py-[10px]'>
                      {title ?? <div className='text-gray-500'>Select Title</div>}
                      <div className={clsx('transition-all', { 'rotate-180': open })}>
                        <DropdownArrowIcon />
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      enter='transition duration-100 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-75 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 max-h-[300px] w-full overflow-y-auto rounded border border-gray-500 bg-white'>
                        {predefinedTitles.map((title, idx) => (
                          <Listbox.Option
                            key={idx}
                            value={title}
                            className={({ active }) =>
                              clsx({
                                'bg-primary-200': active,
                              })
                            }
                          >
                            {({ selected }) => (
                              <div className='flex cursor-pointer items-center justify-between px-[12px] py-[10px]'>
                                <div>{title}</div>
                                {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                        <div
                          className='cursor-pointer px-[12px] py-[10px] font-bold hover:bg-primary-200'
                          onClick={() => setOpenNewTitleModal(true)}
                        >
                          + Add new author title
                        </div>
                      </Listbox.Options>
                    </Transition>
                  </>
                )}
              </Listbox>
            </div>
            <div className='flex justify-end space-x-1'>
              <div>
                <Button.Reverse type='button' className='px-4 py-2' onClick={onClose}>
                  Cancel
                </Button.Reverse>
              </div>
              <div>
                <Button
                  type='button'
                  className='px-8 py-2'
                  onClick={onClickAdd}
                  disabled={!author || !title}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <AddNewAuthorModal open={openNewAuthorModal} onClose={() => setOpenNewAuthorModal(false)} />
        <AddNewAuthorTitleModal
          open={openNewTitleModal}
          onClose={() => setOpenNewTitleModal(false)}
          onEnter={(newTitle: string) => setTitle(newTitle)}
        />
      </Modal>
    </>
  );
};

export default AddAuthorModal;
