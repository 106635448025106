export const DOMAIN_URL = process.env.REACT_APP_API_DOMAIN;

export const FETCH_VARIABLE_NAME = DOMAIN_URL + '/api/v1/generate_variable_name/';
export const MODULE_API_URL = DOMAIN_URL + '/api/v1/modules/';
export const MODULE_ICON_PRESIGNED_POST = MODULE_API_URL + '%d/s3-presigned-post/';
export const USER_API_URL = DOMAIN_URL + '/api/v1/user';
export const TRIGGER_API_URL = DOMAIN_URL + '/api/v1/trigger/';
export const NUMERICS_API_URL = DOMAIN_URL + '/api/v1/numeric/';
export const SECTION_DIVIDER_API_URL = DOMAIN_URL + '/api/v1/section_divider/';
export const VALUE_PANEL_API_URL = DOMAIN_URL + '/api/v1/value_panel/';
export const RETRIEVE_VALUE_PANEL_API_URL = DOMAIN_URL + '/api/v1/retrieve_value_panel/';
export const CHOICE_PANEL_API_URL = DOMAIN_URL + '/api/v1/choice_panel/';
export const DESCRIPTION_PANEL_API_URL = DOMAIN_URL + '/api/v1/description_panel/';
export const DESCRIPTION_PANEL_CONVERSION_API_URL =
  DOMAIN_URL + '/api/v1/description_panel_convert/';

export const MULTI_CHOICE_PANEL_API_URL = DOMAIN_URL + '/api/v1/multi_choice_panel/';
export const UI_ELEMENTS_API_URL = DOMAIN_URL + '/api/v1/ui_elements/';
export const FIREBASE_RELEASE_SYNC_API_URL = DOMAIN_URL + '/api/v1/firebase_sync/sync_for_release/';
export const KNOWLEDGE_BASE_SYNC_API_URL = DOMAIN_URL + '/api/v1/firebase_sync/sync_knowledgebase/';
export const CONTAINER_CARD_API_URL = DOMAIN_URL + '/api/v1/container_card/';
export const RETRIEVE_CHOICE_ITEM_API_URL = DOMAIN_URL + '/api/v1/choice/';
export const REFERENCE_MANAGER_API_URL = DOMAIN_URL + '/api/v1/reference_manager/';
export const INFOBOX_API_URL = DOMAIN_URL + '/api/v1/infobox/';
export const ANSWER_PAGE_API_URL = DOMAIN_URL + '/api/v1/answer_page';
export const ANSWER_CARD_API_URL = DOMAIN_URL + '/api/v1/text_card/';
export const CHOICE_CARD_API_URL = DOMAIN_URL + '/api/v1/choice_card/';
export const FORMULA_API_URL = DOMAIN_URL + '/api/v1/formula/';
export const USER_INFO_API_URL = DOMAIN_URL + '/api/v1/user_info';
export const TEAM_API_URL = DOMAIN_URL + '/api/v1/teams/';
export const TEAM_MATE_API_URL = DOMAIN_URL + '/api/v1/team_mates/';
export const TEAM_INVITATION_API_URL = DOMAIN_URL + '/api/v1/team-invitation';
export const ACCEPT_INVITATION_API_URL = DOMAIN_URL + '/api/v1/accept_invitation';
export const REACT_APP_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '241855441634-rlioodme307rsgl6l2fmrdf07s1c0ruq.apps.googleusercontent.com';
export const CHANNEL_API_URL = DOMAIN_URL + '/api/v1/channels/';
export const CHANNEL_LIST_API_URL = DOMAIN_URL + '/api/v1/channels_list/';
export const SET_USER_TEAM_API_URL = DOMAIN_URL + '/api/v1/set_team';
export const SIMULATION_TOKEN_API_URL = DOMAIN_URL + '/api/v1/get_simulation_token/';
export const INFOBOX_IMAGE_API_URL = DOMAIN_URL + '/api/v1/infobox_image/';
export const UNIQUE_CODE_API_URL = DOMAIN_URL + '/api/v1/get_module_unique_codes/';
export const AUTHOR_PROFILE_API_URL = DOMAIN_URL + '/api/v1/author_profile/';
export const DUPLICATE_MODULE_API_URL = DOMAIN_URL + '/api/v1/duplicate_module/';
// TODO: not using anymore, delete once backend api delete this
export const CUSTOM_NUMERIC_API_URL = DOMAIN_URL + '/api/v1/custom_numeric/';
export const VERIFY_MODULE_API_URL = DOMAIN_URL + '/api/v1/verify_module/';
export const BUTTON_CUSTOMIZER_API_URL = DOMAIN_URL + '/api/v1/button_customizer/';
export const SYNC_WARNINGS_API_URL = DOMAIN_URL + '/api/v1/firebase_sync/sync_rules/';
export const GET_INVITE_EMAIL_API_URL = DOMAIN_URL + '/api/v1/check_invite';
export const SEARCH_TAG_API_URL = DOMAIN_URL + '/api/v1/search_tags/';

// Resources
export const API_RESOURCE_API_URL = DOMAIN_URL + '/api/v1/resources/api_resources/';

// Calculators constants
export const CALCULATOR_API_URL = DOMAIN_URL + '/api/v1/calculators/';
export const CALCULATOR_UNIQUE_CODE_API_URL = DOMAIN_URL + '/api/v1/calculator_unique_codes/';
export const LEGACY_CALCULATOR_API_URL = DOMAIN_URL + '/api/v1/legacy_calculators';
export const VERIFY_CALCULATOR_API_URL = DOMAIN_URL + '/api/v1/verify_calculator/';
export const DUPLICATE_CALC_API_URL = DOMAIN_URL + '/api/v1/duplicate_calculator/';
export const OUTPUT_API_URL = DOMAIN_URL + '/api/v1/output/';
export const CALC_SYNC_FOR_RELEASE_API =
  DOMAIN_URL + '/api/v1/firebase_sync/calc_sync_for_release/';
export const CALC_SYNC_WARNINGS_API_URL = DOMAIN_URL + '/api/v1/firebase_sync/calc_sync_rules/';
export const CALC_INTEGRATION_API_URL = DOMAIN_URL + '/api/v1/calculator_integration/';
export const UI_ELEMENT_DUPLICATE_API_URL = DOMAIN_URL + '/api/v1/duplicate_ui_element/';
export const SYNC_TAG_TREES_API_URL = DOMAIN_URL + '/api/v1/firebase_sync/sync_tag_trees/';
export const CONDITIONAL_TEXT_API_URL = DOMAIN_URL + '/api/v1/conditional_text/';
export const MIRROR_API_URL = DOMAIN_URL + '/api/v1/mirror/';
export const UPLOAD_IMAGE_URL = DOMAIN_URL + '/api/v1/embeded_image/';
export const UNIVERSAL_CALCULATORS_API_URL = DOMAIN_URL + '/api/v1/universal_calculators/';
export const NOTE_GEN_SIMULATOR_VARIABLES_API_URL = DOMAIN_URL + '/api/v1/notegenerator_simulator/';
export const TEXT_INPUT_API_URL = DOMAIN_URL + '/api/v1/text_input/';
export const GPT_BOX_API_URL = DOMAIN_URL + '/api/v1/gpt_box/';
export const API_BOX_API_URL = DOMAIN_URL + '/api/v1/api_box/';
export const AMB_LISTENING_API_URL = DOMAIN_URL + '/api/v1/ambient_listening/';
export const LAST_SELECTED_TEAM_API_URL = DOMAIN_URL + '/api/v1/last_selected_team/';
export const SOURCE_API_URL = DOMAIN_URL + '/api/v1/source/';
export const AI_KNOWLEDGE_BASE_API_URL = DOMAIN_URL + '/api/v1/knowledge_bases/';
export const UNVERIFY_MODULE_API_URL = DOMAIN_URL + '/api/v1/un_verify_module/';
export const UNVERIFY_CALCULTAOR_API_URL = DOMAIN_URL + '/api/v1/un_verify_calculator/';
export const DUPLICATE_AI_KNOWLEDGE_BASE_API_URL = DOMAIN_URL + '/api/v1/duplicate_knowledge_base/';
export const VERIFY_AI_KB_API_URL = DOMAIN_URL + '/api/v1/verify_knowledge_base/';
export const UNVERIFY_AI_KB_API_URL = DOMAIN_URL + '/api/v1/unverify_knowledge_base/';
export const HEALTH_CHECK_API_URL = DOMAIN_URL + '/health-check';

/**
 * v2
 */

/** User */
export const LOGIN_API_V2_URL = DOMAIN_URL + '/api/v2/users/signin/';
export const SIGNUP_API_V2_URL = DOMAIN_URL + '/api/v2/users/signup/';
export const SIGNOUT_API_V2_URL = DOMAIN_URL + '/api/v2/users/signout/';
export const MY_INFO_API_URL = DOMAIN_URL + '/api/v2/users/me/';
export const PASSWORD_CHANGE_API_V2_URL = DOMAIN_URL + '/api/v2/users/change-password';
export const PASSWORD_RESET_LINK_API_URL = DOMAIN_URL + '/api/v2/users/reset-password';

/** Module */
export const MODULE_API_V1_URL = DOMAIN_URL + '/api/v1/%s/%d/';

/** KnowledgeBase */
export const KNOWLEDGE_BASE_URL = DOMAIN_URL + '/api/v2/knowledge-bases/%d/';
export const KNOWLEDGE_BASE_CONTENT_URL = KNOWLEDGE_BASE_URL + 'content/';
export const KB_PDF_PRESIGNED_POST = KNOWLEDGE_BASE_URL + 's3-presigned-post/';
export const KB_PDF_JOB_QUEUE = KNOWLEDGE_BASE_URL + 'pdf-job-queue/';
export const KB_PDF_JOB_CANCEL = KNOWLEDGE_BASE_URL + 'pdf-job-cancel/';

/** tags */

export const TAGS = DOMAIN_URL + '/api/v2/tags';
export const POST_AUTO_TAGS = DOMAIN_URL + '/api/v2/auto-tagging';
export const PROMPT_TEMPLATES = DOMAIN_URL + '/api/v2/cards/prompts/';

/** modules */
export const MODULES_SUGGESTIONS_URL = DOMAIN_URL + '/api/v2/modules/suggestions/';
export const MODULES_API_URL = `${DOMAIN_URL}/api/v2/modules?team_id=`;

/** teams */
export const TEAM_URL = DOMAIN_URL + '/api/v2/teams/';
export const TEAM_AUTHORS = DOMAIN_URL + '/api/v1/teams/%d/author_profiles';
export const EHR_CONFIG_URL = DOMAIN_URL + '/api/v2/teams/ehr-configs/';
export const TEAM_STAFF_JOIN_API = DOMAIN_URL + '/api/v2/teams/staff-join';
export const LAST_SELECTED_TEAM_URL = DOMAIN_URL + '/api/v2/teams/last-selected-team';

/** team channels */
export const TEAM_CHANNELS_API = DOMAIN_URL + '/api/v2/teams/channels/';
export const CHANNEL_API = DOMAIN_URL + '/api/v2/teams/channels/%d/';
export const TEAM_CAHNNELS_BULK_DELETE = DOMAIN_URL + '/api/v2/teams/channels/bulk-delete/';

/** resources */
export const RESOURCES_SUGGESTIONS_URL = DOMAIN_URL + '/api/v2/resources/suggestions/';
export const PRESIGNED_POST_URL = DOMAIN_URL + '/api/v2/resources/generate-presigned-post-url/';

/** notifications */
export const SEND_PUSH_NOTIFICATIONS_URL = DOMAIN_URL + '/api/v2/notifications/';
export const GET_NOTIFICATIONS = DOMAIN_URL + '/api/v2/notifications/histories';

/** ehr */
export const EHR_MEDICATIONS = DOMAIN_URL + '/api/v2/ehr/medications/';

/** cards */
export const DATA_EXTRACTOR_API_URL = DOMAIN_URL + '/api/v2/cards/data-extractor/';
export const DATA_EXTRACTOR_GENERATE_VARIABLE_API_URL =
  DATA_EXTRACTOR_API_URL + 'generated_variables/';

/** conversion */
export const CONVERSION_TO_TIPTAP_URL = DOMAIN_URL + '/api/v2/modules/conversion/tiptap/';
export const CONVERSION_TO_DRAFTJS_URL = DOMAIN_URL + '/api/v2/modules/conversion/draftjs/';
