import React from 'react';

export default function SyncInfoIcon(props) {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9365 8C16.9365 12.4183 13.3548 16 8.93652 16C4.51825 16 0.936523 12.4183 0.936523 8C0.936523 3.58172 4.51825 0 8.93652 0C13.3548 0 16.9365 3.58172 16.9365 8ZM7.98652 5L7.93652 3.5H9.93652L9.88652 5L9.63652 9H8.23652L7.98652 5ZM8.93652 12.5C9.48881 12.5 9.93652 12.0523 9.93652 11.5C9.93652 10.9477 9.48881 10.5 8.93652 10.5C8.38424 10.5 7.93652 10.9477 7.93652 11.5C7.93652 12.0523 8.38424 12.5 8.93652 12.5Z'
        fill='#6C7C83'
      />
    </svg>
  );
}
