const QuestionMark = () => {
  return (
    <svg width='18' height='26' viewBox='0 0 18 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.75637 2.97415C4.08845 1.38242 6.2095 0.399902 9.00008 0.399902C11.4944 0.399902 13.5627 1.21817 14.9738 2.64367C16.3849 4.06917 17.1 6.05948 17.1 8.19222C17.1 9.77779 16.543 11.094 15.7669 12.1058C14.9908 13.1177 14.0291 13.851 13.1175 14.4713C12.206 15.0917 11.3406 15.6067 10.7888 16.0715C10.237 16.5364 10.0816 16.8227 10.0801 17.0977V18.7675C10.0821 18.915 10.0557 19.0615 10.0023 19.1984C9.94892 19.3352 9.86969 19.4598 9.7692 19.5649C9.66871 19.6699 9.54897 19.7533 9.41694 19.8103C9.2849 19.8672 9.1432 19.8965 9.00008 19.8965C8.85695 19.8965 8.71525 19.8672 8.58322 19.8103C8.45118 19.7533 8.33144 19.6699 8.23096 19.5649C8.13047 19.4598 8.05124 19.3352 7.99787 19.1984C7.9445 19.0615 7.91806 18.915 7.92008 18.7675V17.0977V17.0803C7.9267 15.8911 8.65596 14.9949 9.42195 14.3495C10.1879 13.7042 11.078 13.1828 11.9194 12.6102C12.7609 12.0376 13.5419 11.4325 14.0794 10.7317C14.617 10.0308 14.94 9.27605 14.94 8.19222C14.94 6.56393 14.419 5.20029 13.455 4.22649C12.4911 3.25269 11.0454 2.62628 9.00008 2.62628C6.67847 2.62628 5.29308 3.34257 4.39323 4.41782C3.49338 5.49306 3.06012 7.03888 3.06012 8.74881C3.06214 8.89632 3.0357 9.04278 2.98233 9.17966C2.92896 9.31655 2.84973 9.44113 2.74925 9.54618C2.64876 9.65123 2.52902 9.73465 2.39698 9.79158C2.26495 9.84852 2.12325 9.87784 1.98012 9.87784C1.837 9.87784 1.6953 9.84852 1.56327 9.79158C1.43123 9.73465 1.31149 9.65123 1.211 9.54618C1.11052 9.44113 1.03129 9.31655 0.977919 9.17966C0.924552 9.04278 0.89811 8.89632 0.900132 8.74881C0.900132 6.65521 1.42429 4.56588 2.75637 2.97415Z'
        fill='#08A88E'
      />
      <path
        d='M7.38009 23.9301C7.38009 23.0079 8.1054 22.2603 9.00008 22.2603C9.89476 22.2603 10.6201 23.0079 10.6201 23.9301C10.6201 24.8523 9.89476 25.5999 9.00008 25.5999C8.1054 25.5999 7.38009 24.8523 7.38009 23.9301Z'
        fill='#08A88E'
      />
      <path
        d='M2.75637 2.97415C4.08845 1.38242 6.2095 0.399902 9.00008 0.399902C11.4944 0.399902 13.5627 1.21817 14.9738 2.64367C16.3849 4.06917 17.1 6.05948 17.1 8.19222C17.1 9.77779 16.543 11.094 15.7669 12.1058C14.9908 13.1177 14.0291 13.851 13.1175 14.4713C12.206 15.0917 11.3406 15.6067 10.7888 16.0715C10.237 16.5364 10.0816 16.8227 10.0801 17.0977V18.7675C10.0821 18.915 10.0557 19.0615 10.0023 19.1984C9.94892 19.3352 9.86969 19.4598 9.7692 19.5649C9.66871 19.6699 9.54897 19.7533 9.41694 19.8103C9.2849 19.8672 9.1432 19.8965 9.00008 19.8965C8.85695 19.8965 8.71525 19.8672 8.58322 19.8103C8.45118 19.7533 8.33144 19.6699 8.23096 19.5649C8.13047 19.4598 8.05124 19.3352 7.99787 19.1984C7.9445 19.0615 7.91806 18.915 7.92008 18.7675V17.0977V17.0803C7.9267 15.8911 8.65596 14.9949 9.42195 14.3495C10.1879 13.7042 11.078 13.1828 11.9194 12.6102C12.7609 12.0376 13.5419 11.4325 14.0794 10.7317C14.617 10.0308 14.94 9.27605 14.94 8.19222C14.94 6.56393 14.419 5.20029 13.455 4.22649C12.4911 3.25269 11.0454 2.62628 9.00008 2.62628C6.67847 2.62628 5.29308 3.34257 4.39323 4.41782C3.49338 5.49306 3.06012 7.03888 3.06012 8.74881C3.06214 8.89632 3.0357 9.04278 2.98233 9.17966C2.92896 9.31655 2.84973 9.44113 2.74925 9.54618C2.64876 9.65123 2.52902 9.73465 2.39698 9.79158C2.26495 9.84852 2.12325 9.87784 1.98012 9.87784C1.837 9.87784 1.6953 9.84852 1.56327 9.79158C1.43123 9.73465 1.31149 9.65123 1.211 9.54618C1.11052 9.44113 1.03129 9.31655 0.977919 9.17966C0.924552 9.04278 0.89811 8.89632 0.900132 8.74881C0.900132 6.65521 1.42429 4.56588 2.75637 2.97415Z'
        stroke='#08A88E'
        strokeWidth='0.75'
      />
      <path
        d='M7.38009 23.9301C7.38009 23.0079 8.1054 22.2603 9.00008 22.2603C9.89476 22.2603 10.6201 23.0079 10.6201 23.9301C10.6201 24.8523 9.89476 25.5999 9.00008 25.5999C8.1054 25.5999 7.38009 24.8523 7.38009 23.9301Z'
        stroke='#08A88E'
        strokeWidth='0.75'
      />
    </svg>
  );
};

export default QuestionMark;
