import { useQuery } from '@tanstack/react-query';
import { invitationAPI } from 'api/invitation';
import VerticalDivider from 'components/utils/VerticalDivider';
import { CustomToast } from 'components/utils/toast-message';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HOME_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from '../../../constants';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { useManualButton } from '../../../hooks/useManualButton';
import AccountLayout from '../AccountLayout';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';

export interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  institution: string;
  role: string[];
  medicalSpecialties: string[];
  newsletter: boolean;
}

const Signup = () => {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const { inviteCode } = Object.fromEntries(urlSearchParams.entries());

  const { data, isError } = useQuery([inviteCode], invitationAPI.getInvitation, {
    enabled: !!inviteCode,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isError) {
      toast.error(CustomToast, { data: 'Not valid inviteCode' });
      setTimeout(() => history.replace(SIGNUP_ROUTE), 0);
    }
  }, [isError]);

  const [page, setPage] = useState<1 | 2>(1);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors, defaultValues },
    setValue,
    watch,
  } = useForm<SignupFormData>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      institution: '',
      role: [],
      medicalSpecialties: [],
      newsletter: true,
    },
    mode: 'all',
  });

  useEffect(() => {
    if (data?.data.email) {
      setValue('email', data.data.email);
    }
    if (data?.data.user_exists) {
      toast.info(CustomToast, { data: 'Account already exists. Please Login' });
      history.replace(`${LOGIN_ROUTE}?inviteCode=${inviteCode}`);
    }
  }, [data]);

  const { onNewMember } = useManualButton();

  const { isLoggedIn, signUp } = useAuthentication();
  const onSubmit = async (data: SignupFormData) => {
    if (page === 1) {
      setPage(2);
    } else {
      setLoading(true);

      /** this logic will be used after account integration done. */
      // const user = await createUserWithEmailAndPassword(
      //   auth,
      //   data.email.toLocaleLowerCase(),
      //   data.password
      // )
      //   .then((credential) => credential.user)
      //   .catch((error) => toast.error(CustomToast, { data: error.message }));

      await signUp({
        email: data.email.toLocaleLowerCase(),
        password: data.password,
        first_name: data.firstName,
        last_name: data.lastName,
        job_title: data.jobTitle,
        institution: data.institution,
        role: data.role,
        medical_specialties: data.medicalSpecialties,
        subscribed_to_newsletter: data.newsletter,
        invite_code: inviteCode,
      })
        .then(() => {
          onNewMember();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(inviteCode ? `/invitation/${inviteCode}` : HOME_ROUTE);
    }
  }, [isLoggedIn, history, inviteCode]);

  return (
    <AccountLayout>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className='w-full space-y-[12px]'>
          {page === 1 ? (
            <FirstPage
              done={
                (!!dirtyFields.email || !!inviteCode) &&
                !!dirtyFields.password &&
                !!dirtyFields.confirmPassword &&
                !!dirtyFields.lastName &&
                !!dirtyFields.firstName
              }
              email={data?.data.email}
              register={register}
              errors={errors}
              watch={watch}
            />
          ) : (
            <SecondPage
              prevPage={() => setPage(1)}
              register={register}
              done={!!dirtyFields.jobTitle && !!dirtyFields.institution}
              defaultNewsletter={defaultValues?.newsletter || false}
              setValue={setValue}
              loading={loading}
            />
          )}
          <VerticalDivider />
          <div className='flex justify-center'>
            <div className='py-[8px] text-body-2 text-gray-700'>Already have an account?</div>

            <Link to={`${LOGIN_ROUTE}${location.search}`} tabIndex={-1}>
              <div className='px-[8px] py-[9px] text-heading-5 font-semibold text-primary-600'>
                Sign in
              </div>
            </Link>
          </div>
        </div>
      </form>
    </AccountLayout>
  );
};

export default Signup;
