import { MainLayout } from 'components/layout/MainLayout';
import { NotificationHistoryList } from '../components/notification/NotificationHistoryList';

export const Notification = () => {
  return (
    <MainLayout>
      <NotificationHistoryList />
    </MainLayout>
  );
};
