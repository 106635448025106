import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import axios from 'axios';
import AnswerCardForm from 'components/answer-board/answerCardForm';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Component } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ANSWER_CARD_API_URL } from '../../constants';

const Footer = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: '1px solid #E4E4E4',
    marginTop: '20px',
  },
}))(Box);
interface AnswerCardProps {
  resetAnswerPage: Function;
  answerCard: {
    id: any;
    status: any;
    trigger_detail: any;
    original_copy: any;
    card_summary: any;
    card_title: any;
    new_card_text_content: any;
    has_mirrors: boolean;
    card_text_json_tiptap: any;
  };
  moduleId: any;
}
interface AnswerCardState {
  editModal: boolean;
  deleteModal: boolean;
  textCard: boolean;
  duplicateModal: boolean;
}
interface AnswerCardState {}
class AnswerCard extends Component<AnswerCardProps, AnswerCardState> {
  constructor(props: AnswerCardProps) {
    super(props);

    this.state = {
      editModal: false,
      deleteModal: false,
      textCard: false,
      duplicateModal: false,
    };
  }

  toggleEditModal = () => {
    this.setState((previous) => ({
      editModal: !previous.editModal,
      textCard: false,
    }));
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  closeTextCard = () => {
    this.setState((previous) => ({
      textCard: !previous.textCard,
    }));
  };

  deleteAnswerCard = () => {
    axios.delete(ANSWER_CARD_API_URL + this.props.answerCard.id + '/').then(() => {
      this.props.resetAnswerPage();
    });
  };

  createMirror = () => {
    this.toggleDuplicateModal();

    axios.get(ANSWER_CARD_API_URL + this.props.answerCard.id + '/').then((res) => {
      let payload = {
        card_text: res.data.card_text,
        card_text_content: res.data.card_text_content,
        new_card_text_content: res.data.new_card_text_content,
        card_summary: res.data.card_summary,
        card_title: res.data.card_title,
        answer_page: res.data.answer_page,
        trigger: res.data.trigger,
        original_copy: res.data.id,
        position: res.data.position + 1,
        card_type: res.data.card_type,
        status: res.data.status,
      };

      axios.post(ANSWER_CARD_API_URL, payload).then(() => {
        this.props.resetAnswerPage();
      });
    });
  };

  createDuplicate = () => {
    this.toggleDuplicateModal();

    axios.get(ANSWER_CARD_API_URL + this.props.answerCard.id + '/').then((res) => {
      let payload = {
        card_text: res.data.card_text,
        card_text_content: res.data.card_text_content,
        new_card_text_content: res.data.new_card_text_content,
        card_summary: res.data.card_summary,
        card_title: res.data.card_title,
        answer_page: res.data.answer_page,
        trigger: res.data.trigger,
        position: res.data.position + 1,
        card_type: res.data.card_type,
        status: res.data.status,
      };
      axios.post(ANSWER_CARD_API_URL, payload).then(() => {
        this.props.resetAnswerPage();
      });
    });
  };

  toggle = () => {
    this.setState({
      editModal: true,
    });
  };

  toggleDuplicateModal = () => {
    this.setState((previous) => ({
      duplicateModal: !previous.duplicateModal,
    }));
  };

  render() {
    let cardStyle = {
      borderRadius: '25px',
      backgroundColor: this.props.answerCard.status === '0' ? 'red' : 'white',
      width: '440px',
      marginLeft: '2px',
      marginBottom: '10px',
    };
    let cardTitle = {
      color: 'black',
      fontWeight: 'bold',
      fontSize: 'large',
    };
    let summaryTitle = {
      fontWeight: 'bold',
      fontSize: 'small',
    };
    let summaryCard = {
      backgroundColor: '#54C46E',
      padding: '10px',
      color: 'white',
      borderRadius: '20px',
    };
    let buttonStyle = {
      color: '#FD9954',
      float: 'right',
      textTransform: 'none',
      fontSize: 15,
    } as React.CSSProperties;
    let triggerChipStyle = {
      float: 'right',
      color: 'whitesmoke',
      marginTop: '2px',
      marginBottom: '5px',
      marginRight: '5px',
    };

    const trigger = this.props.answerCard.trigger_detail;

    return (
      <div
        onDoubleClick={
          hasViewOnlyPermission() || this.props.answerCard.original_copy ? undefined : this.toggle
        }
      >
        <Paper elevation={5} style={{ borderRadius: '30px' }}>
          <Typography
            component={'div'}
            color='textPrimary'
            style={{ padding: '5px', fontWeight: '600' }}
          >
            {trigger ? (
              trigger.title !== 'Always On' && (
                <Chip
                  style={{ ...triggerChipStyle, backgroundColor: '#08A88E' } as React.CSSProperties}
                  size='small'
                  label={trigger.title}
                />
              )
            ) : (
              <Chip
                style={{ ...triggerChipStyle, backgroundColor: 'red' } as React.CSSProperties}
                size='small'
                label='Not Assigned'
              />
            )}
          </Typography>

          <Card style={cardStyle}>
            <div
              style={{
                opacity: this.props.answerCard.original_copy ? '0.7' : '1',
              }}
            >
              <CardContent style={{ whiteSpace: 'pre-wrap' }}>
                {this.props.answerCard.card_summary && (
                  <div>
                    <Paper elevation={3} style={{ borderRadius: '20px' }}>
                      <Card style={summaryCard}>
                        <Typography style={summaryTitle} gutterBottom>
                          SUMMARY
                        </Typography>
                        <Typography variant='body1'>
                          {this.props.answerCard.card_summary}
                        </Typography>
                      </Card>
                    </Paper>{' '}
                    <br />
                  </div>
                )}

                <Typography style={cardTitle} gutterBottom>
                  {this.props.answerCard.card_title}
                </Typography>

                <Typography variant='body2' component='p'>
                  <AvoEditorPreview
                    contentTiptap={this.props.answerCard.card_text_json_tiptap}
                    contentDraftJS={this.props.answerCard.new_card_text_content}
                  />
                </Typography>
              </CardContent>

              {!hasViewOnlyPermission() && (
                <CardActions>
                  <Button
                    style={buttonStyle}
                    className='util-icons right'
                    size='small'
                    onClick={this.toggleDeleteModal}
                  >
                    Delete
                  </Button>
                  {!this.props.answerCard.original_copy ? (
                    // answer-card-button
                    <div>
                      <Button
                        style={buttonStyle}
                        className='util-icons right'
                        size='small'
                        onClick={this.toggleEditModal}
                      >
                        Edit
                      </Button>
                      <Button
                        style={buttonStyle}
                        className='util-icons right'
                        size='small'
                        onClick={this.toggleDuplicateModal}
                      >
                        Duplicate
                      </Button>
                    </div>
                  ) : (
                    <LinkIcon
                      fontSize='large'
                      style={{
                        float: 'right',
                        marginInlineStart: '320px',
                        transform: 'rotate(140deg)',
                      }}
                    />
                  )}
                </CardActions>
              )}
            </div>
          </Card>
        </Paper>

        <Modal
          open={this.state.duplicateModal}
          onClose={this.toggleDuplicateModal}
          className='d-flex align-items-center justify-content-center'
          closeAfterTransition
        >
          <Fade in={this.state.duplicateModal}>
            <Box
              style={{
                background: '#FFFFFF',
                width: ' 300px',
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '40px',
              }}
            >
              <Typography>Link to the original dialog</Typography>
              <Footer>
                <Button
                  style={{ color: '#FD9954' }}
                  className='answer-card-button util-icons'
                  onClick={this.createDuplicate}
                >
                  Not Linked
                </Button>
                <Button
                  style={{ color: '#FD9954' }}
                  className='answer-card-button util-icons'
                  onClick={this.createMirror}
                >
                  Link
                </Button>
              </Footer>
            </Box>
          </Fade>
        </Modal>

        <SlidingPane
          isOpen={this.state.editModal}
          onRequestClose={() => this.setState({ textCard: true })}
          from='right'
          hideHeader
          className='no-padding sliding-panel-shadow'
          width='620px'
        >
          <div className='h-full overflow-visible bg-white'>
            <AnswerCardForm
              ansCardId={this.props.answerCard.id}
              toggleModal={this.toggleEditModal}
              moduleId={this.props.moduleId}
              resetAnsCards={this.props.resetAnswerPage}
              modal={this.state.textCard}
              closeUiElementPane={this.toggleEditModal}
            />
          </div>
        </SlidingPane>

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content={
            this.props.answerCard.has_mirrors
              ? 'There are mirrored copies of the current text item. Are you really going to delete this item?'
              : 'Are you sure you want to delete this Answer Card?'
          }
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.deleteAnswerCard();
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  conditionalTextState: state.conditionalTextState,
});

export default connect(mapStateToProps, {})(AnswerCard);
