import { Box } from '@material-ui/core';
import { getTriggers } from 'actions/resources/getTriggers';
import axios from 'axios';
import AppearingSituation from 'components/utils/AppearingSituation';
import ListItem from 'components/utils/ListItem';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton, Examples } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import { convertCodeToText } from 'utils/utilityFunctions';
import {
  ANSWER_PAGE,
  ANSWER_PAGE_API_URL,
  CANCEL_BUTTON,
  MODULE_API_URL,
  MODULE_TYPES,
  SAVE_BUTTON,
} from '../../constants';
interface AnswerPageFormProps {
  getTriggers: Function;
  formState: any;
  moduleId: any;
  customNumericState: any;
  answerPageId: any;
  toggleModal: () => void;
  resetState: () => void;
  formulaState: {
    formulas: Array<{ unique_code: string }>;
  };
  triggerState: any;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}
interface AnswerPageFormState {
  title: any;
  trigger: any;
  triggerObj: any;
  module: any;
  triggerOptions: any[];
  formulae: any[];
  prevTrigFormulae: any[];
  inputReviews: any[];
  shortenedTitleSync: boolean;
}
class AnswerPageForm extends Component<AnswerPageFormProps, AnswerPageFormState> {
  constructor(props) {
    super(props);
    // state element name should be same as the name of input elements in form
    this.state = {
      title: '',
      trigger: '',
      triggerObj: {},
      module: '',

      triggerOptions: [],
      formulae: [],
      prevTrigFormulae: [],
      inputReviews: [],
      shortenedTitleSync: false,
    };
  }

  helpInfoData = getHelpInfoData('AnswerPage');

  async componentDidMount() {
    this.props.getTriggers(MODULE_TYPES.ALGO, null, true, this.props.moduleId);

    let f: Array<{ code: string }> = [];

    this.props.formulaState?.formulas?.forEach((item) => {
      f.push({ code: item.unique_code });
    });

    let res = await axios.get(MODULE_API_URL + this.props.moduleId + '/numerics');
    res.data.forEach((item) => {
      f.push({ code: item.unique_code });
    });

    /* add custom numerics in the add input review dropdown 26 May 2020 */
    this.props.customNumericState?.customNumerics?.forEach((item) => {
      f.push({ code: item.unique_code });
    });
    /* end of custom numerics in the add input review dropdown 26 May 2020 */

    res = await axios.get(MODULE_API_URL + this.props.moduleId + '/variables?for_text_editor=true');
    res.data.forEach((item) => {
      f.push({ code: item.unique_code });
    });

    this.setState({ formulae: f });

    if (this.props.answerPageId) {
      axios.get(ANSWER_PAGE_API_URL + '/' + this.props.answerPageId + '/').then((res) => {
        let selectedItems: any[] = [];
        if (res.data.input_reviews) {
          res.data.input_reviews.forEach((item) => {
            selectedItems.push(JSON.parse(item));
          });
        }

        let trigFormulae = this.getTriggerFormulae(res.data.trigger_detail);
        this.setState({
          title: res.data.title ? res.data.title : '',
          trigger: res.data.trigger,
          triggerObj: res.data.trigger_detail,
          module: res.data.module,
          inputReviews: selectedItems,
          prevTrigFormulae: trigFormulae,
        });
      });
    }
  }

  getTriggerFormulae = (trigger) => {
    if (!trigger) return [];

    let formulae: Array<{ code: string }> = [];
    let numerics = trigger.numeric || [];
    let formulas = trigger.formula || [];
    let variables = trigger.variable || [];
    // eslint-disable-next-line
    numerics.map((num) => {
      formulae.push({
        code: num.unique_code,
      });
    });
    // eslint-disable-next-line
    formulas.map((formula) => {
      formulae.push({
        code: formula.unique_code,
      });
    });
    // eslint-disable-next-line
    variables.map((variable) => {
      formulae.push({
        code: variable.unique_code,
      });
    });

    return formulae;
  };

  // updates the state on field input
  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value } as Pick<AnswerPageFormState, any>);

  getPayload = () => {
    let inputReviews: string[] = [];
    this.state.inputReviews.forEach((item) => {
      inputReviews.push(JSON.stringify({ code: item.code }));
    });

    return {
      title: this.state.title,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      input_reviews: inputReviews,
    };
  };

  createAnswerPage = () => {
    let payload = this.getPayload();
    if (!payload.trigger) {
      toast.error(CustomToast, { data: 'You need to set up a trigger for the page' });
      return;
    }

    this.props.toggleModal();
    axios.post(ANSWER_PAGE_API_URL + '/', payload).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  editAnswerPage = () => {
    let payload = this.getPayload();
    if (!payload.trigger) {
      toast.error(CustomToast, { data: 'You need to set up a trigger for the page' });
      return;
    }

    this.props.toggleModal();
    axios.put(ANSWER_PAGE_API_URL + '/' + this.props.answerPageId + '/', payload).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  getSelectedTrigger = (data, triggerObj) => {
    // remove previous trigger's formula/numeric/variables etc from input reviews
    let res = this.state.inputReviews.filter(
      (review) => !this.state.prevTrigFormulae.some((prevReview) => prevReview.code === review.code)
    );

    // add new trigger's formula/numeric/variables etc to input reviews
    let newTrigFormulae = this.getTriggerFormulae(triggerObj);
    newTrigFormulae.forEach((newReview) => {
      const find = res.find((inputReview) => inputReview.code === newReview.code);
      if (!find) res.push(newReview);
    });

    this.setState({
      inputReviews: res,
      prevTrigFormulae: newTrigFormulae,
      trigger: data,
      triggerObj: triggerObj,
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  addInputReview = (e) => {
    const selectedFormulae = this.state.formulae[e.target.value];
    const find = this.state.inputReviews.find(
      (inputReview) => inputReview.code === selectedFormulae.code
    );

    if (!find) {
      const concatedArray = this.state.inputReviews.concat(selectedFormulae);
      this.setState({ inputReviews: concatedArray });
    }
  };

  updateInputReviews = (updatedReviews) => {
    this.setState({ inputReviews: updatedReviews });
  };

  deleteInputReviews = (deletedReview) => {
    let inputReviews = this.state.inputReviews.filter((review) => review !== deletedReview);
    this.setState({ inputReviews });
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { title } = this.state;
    const { answerPageId, modal, closeUiElementPane } = this.props;
    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the answer page title field.' });
    } else if (answerPageId) {
      this.editAnswerPage();
    } else {
      this.createAnswerPage();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    let triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];
    triggers = triggers.filter((trigger) => trigger.title !== 'Always On');

    return (
      <div className='row mt-14 h-full'>
        <div className='side-panel-form' style={{ height: '100%' }}>
          <Header
            title={this.props.answerPageId ? `Updat ${ANSWER_PAGE}` : `Create ${ANSWER_PAGE}`}
            toggleModal={this.props.toggleModal}
          />
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '0px',
              height: '100%',
            }}
            autoComplete='off'
            onKeyPress={this.onKeyPress}
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: 25 }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <div>
              <Box style={{ marginTop: 25 }}>
                {helpInfoData['add_input_reviews'] && (
                  <FormGroup>
                    <FieldLabel
                      detail={helpInfoData?.add_input_reviews?.detail}
                      label={helpInfoData?.add_input_reviews?.label}
                    />
                    <Examples
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginBottom: 10,
                          background: '#ffffff',
                          border: '1px solid #cccccc',
                          width: '95%',
                          height: '47px',
                          paddingRight: '20px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderRadius: '5px',
                        }}
                      >
                        <Input
                          style={{
                            border: 'none',
                            background: 'transparent',
                          }}
                          type='select'
                          name='input_review'
                          onChange={this.addInputReview}
                          defaultValue='---'
                        >
                          <option disabled value='---'>
                            {' '}
                            ----{' '}
                          </option>
                          {this.state.formulae.map((formula, index) => (
                            <option key={formula.code} value={index}>
                              {convertCodeToText(formula.code)}
                            </option>
                          ))}
                        </Input>
                      </div>
                      {helpInfoData['add_input_reviews'] && (
                        <div className='new-textBox' style={{ width: '95%', marginBottom: 20 }}>
                          <ListItem
                            list={this.state.inputReviews}
                            updateListItems={this.updateInputReviews}
                            delete={this.deleteInputReviews}
                          />
                        </div>
                      )}
                    </Examples>
                  </FormGroup>
                )}
              </Box>
            </div>

            <div
              style={{
                marginTop: '107px',

                marginLeft: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CancelButton
                style={{ marginRight: 35, marginBottom: '40px' }}
                onClick={() => this.props.toggleModal()}
              >
                {CANCEL_BUTTON}
              </CancelButton>
              <CreateButton type='submit' style={{ marginBottom: '40px' }}>
                {SAVE_BUTTON}
              </CreateButton>
            </div>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch, AnswerPageFormProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AnswerPageForm);
