import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PDFIcon from 'assets/images/pdf.png';
import axios from 'axios';
import MediaCardForm from 'components/answer-board/mediaCardForm';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ANSWER_CARD_API_URL } from '../../constants';

const styles = (theme: Theme) =>
  createStyles({
    cardStyle: {
      borderRadius: '25px',
      width: '440px',
      marginLeft: '2px',
      marginBottom: '10px',
    },
    title: {
      color: 'black',
      fontWeight: 'bold',
      fontSize: 'large',
      paddingLeft: 6,
    },
    img: {
      height: 255,
      display: 'block',
      width: 400,
      overflow: 'hidden',
      marginLeft: 5,
    },
    buttonStyle: {
      color: '#FD9954',
      float: 'right',
      textTransform: 'none',
      fontSize: 15,
      '&:hover': {
        color: '#FD9954',
      },
    },
    triggerChipStyle: {
      float: 'right',
      color: 'whitesmoke',
      marginTop: '2px',
      marginBottom: '5px',
      marginRight: '5px',
    },
  });

const StyledButton = withStyles((theme) => ({
  root: {
    color: '#FD9954',
    backgroundColor: '#fafafa',
    '&:hover': {
      color: '#FD9954',
      backgroundColor: '#fafafa',
    },
  },
}))(Button);

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};
interface MediaCardProps extends WithStyles<typeof styles> {
  resetAnswerPage: Function;
  //classes: any;
  //theme: any;
  theme: Theme;
  mediaCard: any;
  moduleId: any;
}
interface MediaCardState {
  editModal: boolean;
  deleteModal: boolean;
  closeMediaForm: boolean;
  activeStep: any;
}
class MediaCard extends Component<MediaCardProps, MediaCardState> {
  constructor(props) {
    super(props);

    this.state = {
      editModal: false,
      deleteModal: false,
      closeMediaForm: false,
      activeStep: 0,
    };
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  toggleEditModal = () => {
    this.setState((previous) => ({
      editModal: !previous.editModal,
    }));
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  deleteMediaCard = () => {
    axios.delete(ANSWER_CARD_API_URL + this.props.mediaCard.id + '/').then(() => {
      this.props.resetAnswerPage();
    });
  };

  toggle = () => this.setState({ editModal: true });

  closeMediaFormPane = () => {
    this.setState((previous) => ({
      closeMediaForm: !previous.closeMediaForm,
    }));
  };

  render() {
    const { classes, theme } = this.props;
    const trigger = this.props.mediaCard.trigger_detail;
    let elements = this.props.mediaCard.media_items || [];
    let media_items: Array<{ representation_phrase: any; imgPath: any; videoURL: any }> = [];

    if (elements !== undefined) {
      for (let i = 0; i < elements.length; i++) {
        let item = elements[i];
        if (item && 'image' in item) {
          media_items.push({
            representation_phrase: item['image']['representation_phrase'],
            imgPath: item['image']['image'],
            videoURL: item['image']['video_url'],
          });
        }
      }
    }
    const maxSteps = media_items.length;

    return (
      <div onDoubleClick={!hasViewOnlyPermission() ? this.toggle : undefined}>
        <Paper elevation={5} style={{ borderRadius: '30px' }}>
          <Typography
            component={'div'}
            color='textPrimary'
            style={{ padding: '5px', fontWeight: '600' }}
          >
            {trigger ? (
              trigger.title !== 'Always On' && (
                <Chip
                  className={classes.triggerChipStyle}
                  style={{ backgroundColor: '#08A88E' }}
                  size='small'
                  label={trigger.title}
                />
              )
            ) : (
              <Chip
                className={classes.triggerChipStyle}
                style={{ backgroundColor: 'red' }}
                size='small'
                label='Not Assigned'
              />
            )}
          </Typography>

          <Card
            className={classes.cardStyle}
            style={{
              backgroundColor: this.props.mediaCard.status === '0' ? 'red' : 'white',
            }}
          >
            <CardContent style={{ whiteSpace: 'pre-wrap' }}>
              <Typography className={classes.title} gutterBottom>
                {this.props.mediaCard.card_title}
              </Typography>

              {media_items.length > 0 && (
                <React.Fragment>
                  {media_items[this.state.activeStep].imgPath ? (
                    <a href={media_items[this.state.activeStep].imgPath} target='_blank' download>
                      <img
                        className={classes.img}
                        src={
                          media_items[this.state.activeStep].imgPath.includes('.pdf') === true
                            ? PDFIcon
                            : media_items[this.state.activeStep].imgPath
                        }
                        alt={media_items[this.state.activeStep].representation_phrase}
                      />
                    </a>
                  ) : media_items[this.state.activeStep].videoURL ? (
                    <iframe
                      width='400'
                      height='255'
                      id='iframe'
                      src={
                        '//www.youtube.com/embed/' +
                        getId(media_items[this.state.activeStep].videoURL)
                      }
                      frameBorder='0'
                    ></iframe>
                  ) : (
                    <p style={{ color: 'red' }}>
                      {' '}
                      No media found for {
                        media_items[this.state.activeStep].representation_phrase
                      }{' '}
                    </p>
                  )}

                  <MobileStepper
                    steps={maxSteps}
                    position='static'
                    variant='text'
                    activeStep={this.state.activeStep}
                    nextButton={
                      <StyledButton
                        size='small'
                        onClick={this.handleNext}
                        disabled={this.state.activeStep === maxSteps - 1}
                      >
                        Next
                        {theme && theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </StyledButton>
                    }
                    backButton={
                      <StyledButton
                        size='small'
                        onClick={this.handleBack}
                        disabled={this.state.activeStep === 0}
                      >
                        {theme && theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </StyledButton>
                    }
                  />
                </React.Fragment>
              )}

              <Typography variant='body2' component='p' style={{ marginTop: 10 }}>
                <AvoEditorPreview
                  contentTiptap={this.props.mediaCard.card_text_json_tiptap}
                  contentDraftJS={this.props.mediaCard.new_card_text_content}
                />
              </Typography>
            </CardContent>

            {!hasViewOnlyPermission() && (
              <CardActions>
                <Button
                  className={`${classes.buttonStyle} util-icons right`}
                  size='small'
                  onClick={this.toggleDeleteModal}
                >
                  Delete
                </Button>
                <Button
                  className={`${classes.buttonStyle} util-icons right`}
                  size='small'
                  onClick={this.toggleEditModal}
                >
                  Edit
                </Button>
              </CardActions>
            )}
          </Card>
        </Paper>

        <SlidingPane
          isOpen={this.state.editModal}
          onRequestClose={this.closeMediaFormPane}
          from='right'
          overlayClassName='light-overlay'
          className='sliding-panel-shadow'
          width='620px'
        >
          <div style={{ backgroundColor: 'white' }}>
            <MediaCardForm
              mediaCardId={this.props.mediaCard.id}
              toggleModal={this.toggleEditModal}
              moduleId={this.props.moduleId}
              resetAnsCards={this.props.resetAnswerPage}
            />
          </div>
        </SlidingPane>

        <ConfirmModal
          preset='unsaved'
          open={this.state.closeMediaForm}
          toggleModal={this.closeMediaFormPane}
          performAction={() => {
            this.closeMediaFormPane();
            this.toggleEditModal();
          }}
        />

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this Media Card?'
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.deleteMediaCard();
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  conditionalTextState: state.conditionalTextState,
});

export default connect(mapStateToProps, {})(withStyles(styles)(MediaCard));
