import { Box, MenuItem, MenuList, Popover, withStyles } from '@material-ui/core';

import SearchIcon from 'assets/icons/searchIcon';
import { MODULE_STATUSES } from 'constants';
import { Component } from 'react';
import InfoPopup from './InfoPopup';

export const StyledMenuItem = withStyles(() => ({
  root: {
    whiteSpace: 'initial',
    wordBreak: 'break-word',
  },
}))(MenuItem);

export const StyledPopover = withStyles((theme) => ({
  root: {
    pointerEvents: 'none',
  },
  paper: {
    minWidth: '360px',
    maxWidth: '360px',
    minHeight: (props) => (props.trigger ? '50px' : '130px'),
    background: '#FFFFFF',
    border: '1px solid #E7E7E7',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
    borderRadius: '4px',
    padding: 23,
    paddingTop: 15,
  },
}))(Popover);

export default class SectionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      anchorEl: null,
      selectedObj: null,
    };
  }

  handleClose = () => this.setState({ anchorEl: null });

  setSearchText = (text) => this.setState({ searchText: text });

  render() {
    const universalCalculators = this.props.universalCalculators?.filter(
      (universalCalculator) => universalCalculator.status !== MODULE_STATUSES.notSynced
    );
    const sectionOneItems = this.props.sectionOneItems?.filter(
      (item) => item.status !== MODULE_STATUSES.notSynced
    );

    return (
      <div>
        <InfoPopup
          anchorEl={this.state.anchorEl}
          handleClose={() => this.handleClose()}
          selectedObj={this.state.selectedObj}
        />
        <Box
          style={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginbottom: '4px',
            width: this.props.dense ? 236 : 309,
            maxHeight: 500,
          }}
        >
          <div
            style={{
              maxWidth: this.props.dense ? 205 : 276,
              height: '45px',
              backgroundColor: '#f6f6f6',
              background: '#F6F6F6',
              borderRadius: '6px',
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'nowrap',
              paddingRight: '15px',
              paddingLeft: '17px',
            }}
          >
            <SearchIcon />
            <input
              autoFocus
              placeholder='Search...'
              style={{
                //textIndent: '10px',
                outline: 'none',
                borderBottom: 'none',
                boxShadow: 'none',
                display: 'inline-block',
                border: 'none',
                margin: '0',
                marginLeft: 10,
              }}
              value={this.state.searchText}
              onChange={(e) => this.setSearchText(e.target.value)}
              type='text'
            />
          </div>
        </Box>
        <Box style={{ maxHeight: 200, overflowY: 'scroll', maxWidth: 300 }}>
          <MenuList autoFocus>
            {this.props.sectionDropdown ? (
              <>
                <StyledMenuItem
                  style={{
                    pointerEvents: 'none',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '36px',
                    textTransform: 'uppercase',
                    color: '#8C94A1',
                    height: '25px',
                  }}
                >
                  {this.props.section1}
                </StyledMenuItem>
                {sectionOneItems
                  ?.filter((item) =>
                    item.title.toLowerCase().includes(this.state.searchText.toLowerCase())
                  )
                  ?.map((calc) => (
                    <StyledMenuItem
                      key={calc.id}
                      onClick={() => this.props.sectionOneClick(calc)}
                      onMouseEnter={(event) => {
                        this.setState({ anchorEl: event.currentTarget, selectedObj: calc });
                      }}
                      onMouseLeave={() => this.setState({ anchorEl: null, selectedObj: null })}
                    >
                      {calc.title}
                    </StyledMenuItem>
                  ))}
                <StyledMenuItem
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '36px',
                    textTransform: 'uppercase',
                    color: '#8C94A1',
                    height: '25px',
                    marginTop: 10,
                    pointerEvents: 'none',
                  }}
                >
                  {this.props.section2}
                </StyledMenuItem>
                {universalCalculators
                  ?.filter((item) =>
                    item.title.toLowerCase().includes(this.state.searchText.toLowerCase())
                  )
                  ?.map((calc) => (
                    <StyledMenuItem
                      key={calc.id}
                      onClick={() => this.props.sectionOneClick(calc)}
                      onMouseEnter={(event) => {
                        this.setState({ anchorEl: event.currentTarget, selectedObj: calc });
                      }}
                      onMouseLeave={() => this.setState({ anchorEl: null, selectedObj: null })}
                    >
                      {calc.title}
                    </StyledMenuItem>
                  ))}
                {this.props.sectionTwoItems
                  ?.filter((item) =>
                    item.title.toLowerCase().includes(this.state.searchText.toLowerCase())
                  )
                  .map((calc) => (
                    <StyledMenuItem key={calc.id} onClick={() => this.props.sectionTwoClick(calc)}>
                      {calc.title}
                    </StyledMenuItem>
                  ))}
              </>
            ) : this.props.items.length > 0 ? (
              this.props.items
                .filter((item) => {
                  return (item?.title || item?.name || item?.question)
                    ?.toLowerCase()
                    ?.includes(this.state.searchText?.toLowerCase());
                })
                .map((item) => (
                  <StyledMenuItem
                    key={item.id}
                    onClick={() => {
                      this.props.itemClick(item);
                    }}
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '15px',
                      paddingLeft: this.props.dense ? 25 : 30,
                      paddingTop: 0,
                      paddingBottom: 0,
                      lineHeight: '36px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      display: 'inline-block',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.title || item.shortened_name || item.name || item.question}
                  </StyledMenuItem>
                ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pointerEvents: 'none',
                }}
              >
                No Item Found
              </div>
            )}
          </MenuList>
        </Box>
      </div>
    );
  }
}
