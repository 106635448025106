import clsx from 'clsx';
import { MainHeader } from 'components/bars/MainHeader';
import { MainSidebar } from 'components/bars/MainSidebar';
import { HTMLAttributes } from 'react';

interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const MainLayout = ({ children, className }: MainLayoutProps) => {
  return (
    <div className='relative flex h-screen flex-col'>
      <MainHeader />
      <div className='flex grow overflow-hidden'>
        <MainSidebar />
        <div className={clsx('grow overflow-y-auto px-[48px] pb-[24px] pt-[32px]', className)}>
          {children}
        </div>
      </div>
    </div>
  );
};
