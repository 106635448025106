import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

export const StyledMenuItem = styled(MenuItem)`
  :hover {
    background: #e8fff1 !important;
    color: black !important;
  }
  :focus {
    background: white;
    color: black;
  }
`;
