import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItem from './AnswerListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 549,
    background: '#FBFBFB',
    border: '1px dashed #E4E3E3',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '16px',
  },
}));

export default function AnswerSection(props) {
  const classes = useStyles();

  const {
    answers,
    moduleId,
    calculatorId,
    type,
    infoboxes,
    addChoice,
    updateChoices,
    updateChoice,
    deleteChoice,
    handleChangeCheckBox,
  } = props;

  return (
    <div>
      <Card className={classes.root} variant='outlined'>
        <CardContent>
          <div
            style={{
              background: '#FFFFFF',
              border: '1px solid #DADADA',
              boxSizing: 'border-box',
              //   padding: '0px 0px 4px 0px',
              borderRadius: '10px',
              borderBottom: '2px solid rgb(218, 218, 218)',
            }}
          >
            <ListItem
              list={answers}
              moduleId={moduleId}
              calculatorId={calculatorId}
              type={type}
              infoboxes={infoboxes}
              handleChangeCheckBox={handleChangeCheckBox}
              handleDeleteClick={deleteChoice}
              handleListChange={updateChoice}
              updateListItems={updateChoices}
            />
          </div>
          <div style={{ marginTop: '1.5em' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '20px',
                /* identical to box height */
                textAlign: 'center',
                textTransform: 'uppercase',
                color: '#bdbdbd',
                marginRight: '6px',
              }}
            >
              Add
            </span>
            <Button
              variant='contained'
              style={{
                width: '115px',
                height: '40px',
                background: '#08A88E',
                borderRadius: '4px',
                border: 'none',
                color: 'white',
                boxShadow: 'none',
              }}
              className={classes.button}
              startIcon={<AddCircleOutlineIcon />}
              onClick={addChoice}
            >
              Answer
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
