import { GET_MODULE, GET_MODULES_LIST, SET_IS_MIRROR } from 'actions/actionsTypes';

const modulesReducer = (
  state = {
    module: {},
    modules: [],
  },
  action
) => {
  switch (action.type) {
    case GET_MODULE:
      return {
        ...state,
        module: {
          ...action.payload,
          isMirror: state.module.isMirror,
          mirrorId: state.module.mirrorId,
          mirrorName: state.module?.mirrorName,
        },
      };

    case SET_IS_MIRROR:
      return {
        ...state,
        module: {
          ...state.module,
          isMirror: action.isMirror,
          mirrorId: action.mirrorId,
          mirrorName: action.mirrorName,
        },
      };

    case GET_MODULES_LIST:
      return {
        ...state,
        modules: action.payload,
      };
    default:
      return state;
  }
};

export default modulesReducer;
