import { EHRConfig } from 'pages/ehr-config';
import { Numbers } from 'pages/numbers';
import { NumberUpsert } from 'pages/numbers/upsert';
import { EHR_CONFIG, NUMBER, NUMBERS, NUMBER_CREATE } from 'routes/urls';

export const staffRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: NUMBERS,
      component: Numbers,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: NUMBER_CREATE,
      component: NumberUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: NUMBER,
      component: NumberUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: EHR_CONFIG,
      component: EHRConfig,
    },
  },
];
