import { Box } from '@material-ui/core';
import { generateText } from '@tiptap/react';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getConditionalTexts } from 'actions/resources/getConditionalTexts';
import axios from 'axios';
import InputField from 'components/utils/form-input/field';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { getTiptapExtensions } from 'components/utils/tiptap/TiptapExtensions';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, CARD_CONDITIONAL_TEXT, SAVE_BUTTON } from 'constants/variables';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getNumerics } from 'utils/suggestions';
import { generateID } from 'utils/utilityFunctions';
import { CONDITIONAL_TEXT_API_URL, MODULE_TYPES } from '../../../../constants';
import ConditionalTextAccordion from './ConditionalTextAccordion';

interface ConditionalTextProps {
  formState: any;
  conditionalTextId?: any;
  toggleModal: () => void;
  savePosition: () => void;
  getConditionalTexts: (type: any, mirrorId, moduleId) => void;
  setIsFormDisabled: (formState: boolean) => void;
  addItem?: (data?: any) => void;
  resource?: any;
  modal?: boolean;
  closeUiElementPane?: () => void;

  //for withRouter
  match: any;
}

interface ConditionalTextState {
  id: any;
  name: any;
  conditionalTextConditions: any[];
  syncName: boolean;
  numerics: any;
  moduleType: any;
  moduleId: string;
}

/**
 * @deprecated use ConditionalTextForm for new component
 */
class ConditionalText extends Component<
  ConditionalTextProps & RouteComponentProps,
  ConditionalTextState
> {
  constructor(props: ConditionalTextProps & RouteComponentProps) {
    super(props);

    this.state = {
      id: null,
      name: 'TEXT: ',
      conditionalTextConditions: [
        {
          sortableId: generateID(),
          is_default: false,
          label: 'Condition 1',
          position: 0,
        },
        {
          sortableId: generateID(),
          is_default: true,
          label: 'None of the above (Default)',
          position: 1,
        },
      ],
      syncName: true,
      numerics: null,
      moduleType: this.props.location.pathname.split('/')[1],
      moduleId: this.props.match.params.moduleId,
    };
  }

  helpInfoData = getHelpInfoData('ConditionalText');

  componentDidMount() {
    const { formState, setIsFormDisabled } = this.props;
    !formState?.isFormDisabled && setIsFormDisabled(true);

    // if conditional text is opened in edit mode.
    if (this.props.conditionalTextId) {
      axios.get(`${CONDITIONAL_TEXT_API_URL}${this.props.conditionalTextId}/`).then((res) => {
        this.setState({
          id: res.data.id,
          name: res.data.name || '',
          syncName: res.data.is_name_optional,
          conditionalTextConditions: res.data.conditions || [],
        });
      });
    }
    this.populateSuggestions();
  }

  componentWillUnmount() {
    const { formState, setIsFormDisabled } = this.props;

    if (formState?.isFormDisabled) {
      setIsFormDisabled(false);
    }
  }

  populateSuggestions = async () => {
    const numerics = await getNumerics(this.state.moduleId, this.state.moduleType);
    this.setState({ numerics });
  };

  addConditionalCase = (condition) => {
    let stateConditions = [...this.state.conditionalTextConditions];

    // keep the "None Of The Above (Default)" condition at the end and insert new condition
    // above default condition.
    let defaultCondition = stateConditions[stateConditions.length - 1];
    defaultCondition['position'] += 1;

    stateConditions.splice(stateConditions.length - 1, 1, defaultCondition);
    stateConditions.splice(stateConditions.length - 1, 0, condition);
    this.setState({ conditionalTextConditions: stateConditions });
  };

  conditionalTextConditionsHandler = (indexNo, label) => {
    const arr = [...this.state.conditionalTextConditions];
    arr[indexNo].label = label;
    this.setState({ conditionalTextConditions: arr });
  };

  setCaseText = (index, text, textJson, textJsonTiptap) => {
    let data = {};
    if (textJsonTiptap) {
      data = { text: text, text_content_tiptap: textJsonTiptap };
    } else {
      data = {
        text: text,
        text_content: textJson,
      };
    }

    let copiedConditions = [...this.state.conditionalTextConditions];
    copiedConditions[index] = { ...copiedConditions[index], ...data };

    if (index === 0 && this.state.syncName === true) {
      this.setState({ name: generateTitle(copiedConditions[index]) });
    }

    this.setState({ conditionalTextConditions: copiedConditions });
  };

  setRawCondition = (caseIndex, condition, conditionLogic, conditionJson) => {
    this.setState((prevState) => {
      const conditionalTextConditions = [...prevState.conditionalTextConditions];
      conditionalTextConditions[caseIndex] = {
        ...conditionalTextConditions[caseIndex],
        condition,
        condition_logic: conditionLogic,
        builder_condition_json: conditionJson,
      };
      return { conditionalTextConditions };
    });
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if name is updated by user then don't update it automatically after that.
    if (e.target.name === 'name') {
      this.setState({ syncName: false });
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<ConditionalTextState, any>);
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (prevState) =>
        ({
          [modalName]: !prevState[modalName],
        }) as Pick<ConditionalTextState, any>
    );

    if (modalName === 'syncName') this.toggleTitle();
  };

  toggleTitle = () => {
    this.state.syncName
      ? this.setState({ name: '' })
      : this.setState({
          name: generateTitle(this.state.conditionalTextConditions[0]),
        });
  };

  deleteConditionalText = (id) => {
    const newList = this.state.conditionalTextConditions.filter((item) => item.sortable_id !== id);
    this.setState({ conditionalTextConditions: newList });
  };

  updateMultipleConditionalTexts = (newList) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }

    this.setState({ conditionalTextConditions: newList });
  };

  deleteConditionIndex = (conditionIndex) => {
    const copiedArray = [...this.state.conditionalTextConditions];
    copiedArray.splice(conditionIndex, 1);
    this.setState({ conditionalTextConditions: copiedArray });
  };

  getPayload = () => {
    return {
      id: this.state.id,
      name: this.state.name,
      conditions: this.state.conditionalTextConditions,
      is_name_optional: this.state.syncName,
      // TODO: integrate module & calculator
      module: this.state.moduleType === MODULE_TYPES.ALGO ? this.state.moduleId : undefined,
      calculator:
        this.state.moduleType === MODULE_TYPES.CALCULATOR ? this.state.moduleId : undefined,
    };
  };

  isAnyConditionEmpty = () => {
    const conditions = this.state.conditionalTextConditions;
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].is_default === false && (!conditions[i].text || !conditions[i].condition)) {
        return true;
      }
    }
    return false;
  };

  areConditionsSame = () => {
    const conditions = this.state.conditionalTextConditions;

    let conditionArray = conditions.map((condition) => {
      return condition.condition;
    });

    let isDuplicate = conditionArray.some((item, idx) => {
      return conditionArray.indexOf(item) !== idx;
    });

    return isDuplicate;
  };

  createConditionalText = async () => {
    if (this.isAnyConditionEmpty()) {
      toast.error(CustomToast, { data: 'Text or condition is empty in one of switch case' });
      return;
    } else if (this.areConditionsSame()) {
      toast.error(CustomToast, { data: 'Two switch cases cannot have same condition' });
      return;
    }

    // close modal
    await this.props.savePosition();

    axios.post(CONDITIONAL_TEXT_API_URL, this.getPayload()).then((res) => {
      this.props.getConditionalTexts(this.state.moduleType, null, this.state.moduleId);
      // toggleModal should be called before addItem inside addItem reset all states
      this.props.toggleModal();
      // if form is opened from text editor
      if (this.props.addItem) {
        this.props.addItem({ ...res.data, type: 'conditional_text' });
      }
    });
  };

  editConditionalText = async () => {
    if (this.isAnyConditionEmpty()) {
      toast.error(CustomToast, { data: 'Text or condition is empty in one of switch case' });
      return;
    } else if (this.areConditionsSame()) {
      toast.error(CustomToast, { data: 'Two switch cases cannot have same condition' });
      return;
    }

    await this.props.savePosition();

    axios
      .put(`${CONDITIONAL_TEXT_API_URL}${this.props.conditionalTextId}/`, this.getPayload())
      .then((res) => {
        this.props.getConditionalTexts(this.state.moduleType, null, this.state.moduleId);
        // if form is opened from text editor
        if (this.props.addItem) {
          this.props.addItem({ ...res.data, type: 'conditional_text' });
        }

        this.props.toggleModal();
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    /* There was a bug (AVONG-2333) in which certain modules had multiple default conditions. 
    Therefore, before saving such a module, if it has multiple conditions, one of them must be deleted. */

    const defaultConditionsCount = this.state.conditionalTextConditions.filter(
      (obj) => obj.is_default
    ).length;

    if (defaultConditionsCount > 1) {
      toast.error(CustomToast, { data: 'Delete one of the default conditions.' });
      return;
    }

    if (this.props.conditionalTextId) {
      this.props.modal && this.props.closeUiElementPane?.();
      this.editConditionalText();
    } else {
      this.props.modal && this.props.closeUiElementPane?.();
      this.createConditionalText();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    return (
      <Form
        className='flex flex-col'
        autoComplete='off'
        onKeyPress={this.onKeyPress}
        onSubmit={this.handleSubmit}
      >
        <Header
          className='px-0'
          title={CARD_CONDITIONAL_TEXT}
          toggleModal={this.props.toggleModal}
        />

        <ConditionalTextAccordion
          resourceSection={this.props.resource}
          setCaseText={this.setCaseText}
          setRawCondition={this.setRawCondition}
          conditions={this.state.conditionalTextConditions}
          conditionalTextConditionsHandler={this.conditionalTextConditionsHandler}
          // TODO: integrate moduleId & calculatorId
          moduleId={this.state.moduleId}
          calculatorId={this.state.moduleId}
          numerics={this.state.numerics}
          type={this.state.moduleType}
          addConditionalCase={this.addConditionalCase}
          deleteCondition={this.deleteConditionIndex}
          handleDeleteClick={this.deleteConditionalText}
          updateListItems={this.updateMultipleConditionalTexts}
        />

        <Box style={{ marginBottom: '25px' }}>
          <InputField
            name='name'
            required={true}
            addToggle={true}
            value={this.state.name}
            onChange={this.onChange}
            disabled={this.state.syncName}
            label={helpInfoData?.name?.label}
            toggleValue={!this.state.syncName}
            detail={helpInfoData?.name?.detail}
            placeholder={helpInfoData?.name?.placeholder}
            maxLength={helpInfoData?.name?.character_limit}
            handleToggle={(event) =>
              event.target.checked !== undefined && this.toggleModal('syncName')
            }
          />
        </Box>

        <div className='row' style={{ marginLeft: 'auto', marginTop: '60px' }}>
          <CancelButton
            style={{ color: 'black', marginRight: '10px' }}
            onClick={this.props.toggleModal}
          >
            {CANCEL_BUTTON}
          </CancelButton>
          <CreateButton type='submit' name='action'>
            {SAVE_BUTTON}
          </CreateButton>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.formState,
  conditionalTextState: state.conditionalTextState,
});

/**
 * @deprecated it(ConditionalText) will be replaced with ConditionalTextForm
 */
export default withRouter(
  connect(mapStateToProps, { getConditionalTexts, setIsFormDisabled })(ConditionalText)
);

// TODO: remove when draftjs is not used anymore
// or delete it when it migrates to ConditionalTextForm
function generateTitle(condition: any): string {
  const textContent = condition.text_content;
  const textContentTiptap = condition.text_content_tiptap;

  let title = 'TEXT: ';
  let summary = '';

  if (textContentTiptap) {
    summary = generateText(textContentTiptap, getTiptapExtensions()).substring(0, 30);
  } else if (textContent) {
    summary = textContent?.blocks?.[0]?.text?.substring(0, 30);
  }

  return title + summary + '...';
}
