import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const triggerStyle = {
  padding: '10px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '18px',
  color: '#FFFFFF',
};

const TRIGGER_ALWAYS_ON = 'Always On';
const TRIGGER_NOT_ASSIGNED = 'Not Assigned';

export const Trigger = ({ trigger, containerCard }) => {
  return (
    <Typography component={'div'} color='textPrimary' style={triggerStyle}>
      {!(containerCard && trigger?.title === TRIGGER_ALWAYS_ON) && (
        <Chip
          style={{
            backgroundColor: trigger ? '#08A88E' : 'red',
            color: 'white',
            marginLeft: '22px',
            height: '24px',
          }}
          size='small'
          label={trigger?.title || TRIGGER_NOT_ASSIGNED}
        />
      )}
    </Typography>
  );
};
