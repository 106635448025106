import { Divider } from '@material-ui/core';
import { dividerStyle } from './styles';
import { ToolbarButton } from './utils';

// TODO: refactor: can do consolidate 3 DividerAfterXXX components into one component
export const DividerAfterTextStyle = ({ richTextButtonShowList }) => {
  const searchStrings = [ToolbarButton.TEXT_STYLE];

  const isAnyIncluded = searchStrings.some((searchString) =>
    richTextButtonShowList.includes(searchString)
  );

  if (isAnyIncluded) {
    return (
      <Divider
        orientation='vertical'
        variant='middle'
        style={{ ...dividerStyle, marginLeft: 6, marginRight: 12 }}
      />
    );
  }

  return null;
};

export const DividerAfterHighlight = ({ richTextButtonShowList }) => {
  const searchStrings = [ToolbarButton.BOLD, ToolbarButton.ITALIC, ToolbarButton.HIGHLIGHT];

  const isAnyIncluded = searchStrings.some((searchString) =>
    richTextButtonShowList.includes(searchString)
  );

  if (isAnyIncluded) {
    return (
      <Divider
        orientation='vertical'
        variant='middle'
        style={{ ...dividerStyle, marginLeft: 6, marginRight: 12 }}
      />
    );
  }

  return null;
};

export const DividerAfterNumberedList = ({ richTextButtonShowList }) => {
  const searchStrings = [ToolbarButton.BULLETED_LIST, ToolbarButton.NUMBERED_LIST];

  const isAnyIncluded = searchStrings.some((searchString) =>
    richTextButtonShowList.includes(searchString)
  );

  if (isAnyIncluded) {
    return (
      <Divider
        orientation='vertical'
        variant='middle'
        style={{ ...dividerStyle, marginLeft: 12, marginRight: 7 }}
      />
    );
  }

  return null;
};
