import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { NUMERICS_API_URL } from '../constants';

interface GetNumberData {
  id: number;
  title: string;
  second_title: string | null;
  unit: string;
  upper_limit: number | null;
  lower_limit: number | null;
  validity_period_days: number;
  code: string;
  secondary_unit: string | null;
  primary_to_secondary: string | null;
  secondary_to_primary: string | null;
  two_decimal_places: boolean | null;
  terminology_code: string | null;
  term_loinc: string[];
  term_text: string[];
  term_regex: string[];
  unique_code: string;
  display_value: string;
  position: number;
  numeric_type: 'predefined';
}

export const numberAPI = {
  getNumber: ({ queryKey }: QueryFunctionContext) =>
    axios.get<GetNumberData>(`${NUMERICS_API_URL}${queryKey[1]}`).then((response) => response.data),
  postModule: (data) => axios.post(NUMERICS_API_URL, data).then((response) => response.data),
  patchModule: (data, numberId) =>
    axios.patch(`${NUMERICS_API_URL}${numberId}/`, data).then((response) => response.data),
  //   postSource: (data) =>
  //     axios.post<PostSourceData>(SOURCE_API_URL, data).then((response) => response.data),
};
