import {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DEMOGRAPHICS_TYPE,
  LAB_DATA_CUSTOM_RANGE_CODE,
  MEDICATION_TYPE,
  NOTE_CUSTOM_RANGE_CODE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_CUSTOM_RANGE_CODE,
} from '../../../../../hooks/useEHRVariables';
import { Menu, MenuItem, ROOT_NODE_ID } from '../../../dropdown/DropdownMenu';
import { Icons } from '../../../Icons';
import { MentionContext } from '../../../module/MentionContext';
import { MENTION_TYPES } from '../../../../../constants';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from 'components/utils/StaffOnlyWrapper';
import { FloatingNode } from '@floating-ui/react';
import { Suggestion } from '../../../../../hooks/useSuggestions';
import { SuggestionTypeEnum, SuggestionTypes } from '../../tiptapInterfaces';
import { SuggestionProps } from '@tiptap/suggestion';

export const MentionList = forwardRef((props: SuggestionProps, ref) => {
  const { headings } = useContext(MentionContext);

  const filteredSuggestions = useMemo<Suggestion[]>(
    () =>
      ((props.items as any).suggestions ?? ([] as Suggestion[]))
        .filter((item) => !modulesToExclude.includes(item.type))
        .filter(
          (item) =>
            ![
              NOTE_CUSTOM_RANGE_CODE,
              LAB_DATA_CUSTOM_RANGE_CODE,
              VITAL_SIGN_CUSTOM_RANGE_CODE,
            ].includes(item?.code ?? '')
        ),
    [props.items]
  );

  const filteredHeadings = useMemo(
    () =>
      headings.filter((heading) =>
        heading.type === 'variable'
          ? filteredSuggestions
              .map((item) => item.type)
              .some((type) => isTypeInSuggestionType(heading.type, type))
          : filteredSuggestions.map((item) => item.type).includes(heading.type)
      ),
    [headings, filteredSuggestions]
  );

  const selectChildItem = (index: number) => {
    const item = filteredSuggestions?.[index];
    if (item) props.command(item);
  };

  const getLabelElement = (item: Suggestion, nested: boolean = true): ReactNode => {
    return (
      <>
        {mentionIcons[item.type]}
        {StaffOnlyWrapIf({
          condition: [MEDICATION_TYPE, DEMOGRAPHICS_TYPE].includes(item.type),
          options: {
            type: StaffOnlyBadgeType.BETA,
            y: 3,
          },
          element: <span className='grow text-left text-caption-1'>{item.name}</span>,
        })}
        {nested && (
          <span aria-hidden className='justify-self-end'>
            <Icons.CaretDown className='-rotate-90' />
          </span>
        )}
      </>
    );
  };

  const floatingRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (floatingRef.current) {
        floatingRef.current.dispatchEvent(new event.constructor(event.type, event));
      }
      return false;
    },
  }));

  useEffect(() => {
    const handleEvent = (event: KeyboardEvent) => {
      if (event.key.length === 1 && event.key !== ' ') {
        if (!props.editor.isFocused) {
          props.editor.chain().focus().insertContent(event.key).run();
        }
      }
      if (event.key === 'Escape' || event.key === 'Backspace') {
        props.editor.commands.focus();
      }
    };
    if (floatingRef.current) {
      floatingRef.current.addEventListener('keydown', handleEvent);
    }
    return () => {
      floatingRef.current?.removeEventListener('keydown', handleEvent);
    };
  }, []);

  return (
    <FloatingNode id={ROOT_NODE_ID}>
      <Menu
        ref={floatingRef}
        className='!max-h-[256px] w-[170px] items-start overflow-y-auto !px-0 !py-2'
      >
        {filteredHeadings.length ? (
          filteredHeadings.map((parentItem, key) =>
            parentItem.type === MEDICATION_TYPE || parentItem.type === PROBLEM_LIST_TYPE ? (
              <MenuItem
                className='flex w-full items-center gap-[4px] px-[12px] py-[10px] hover:bg-gray-100 focus:bg-gray-100'
                label={getLabelElement(parentItem, false)}
                key={key}
                onClick={() =>
                  selectChildItem(
                    filteredSuggestions.findIndex((item) =>
                      parentItem.type === MEDICATION_TYPE
                        ? item.type === MEDICATION_TYPE
                        : item.type === PROBLEM_LIST_TYPE
                    )
                  )
                }
              />
            ) : (
              <Menu label={getLabelElement(parentItem)} key={key}>
                {filteredSuggestions.map((childItem, index) => (
                  <>
                    {isTypeInSuggestionType(parentItem.type, childItem.type) && (
                      <div className='max-w-[320px]'>
                        {StaffOnlyWrapIf({
                          condition:
                            childItem.type === MENTION_TYPES.MESSAGE_VARIABLE ||
                            childItem.type === SuggestionTypeEnum.DATA_EXTRACTOR,
                          options: {
                            type: StaffOnlyBadgeType.BETA,
                          },
                          element: (
                            <MenuItem
                              className='w-full px-[12px] py-[10px] text-left hover:bg-gray-100 focus:bg-gray-100'
                              label={childItem.name}
                              onClick={() => selectChildItem(index)}
                              key={index}
                            />
                          ),
                        })}
                      </div>
                    )}
                  </>
                ))}
              </Menu>
            )
          )
        ) : (
          <span className='w-[170px] px-[12px] py-[10px] text-caption-1'>No result</span>
        )}
      </Menu>
    </FloatingNode>
  );
});

const modulesToExclude = ['algo', 'calculator'];

type MentionIcons = Record<string, ReactNode>;

const mentionIcons: MentionIcons = {
  knowledge_base: <Icons.AiGeneration />,
  variable: <Icons.Variable />,
  formula: <Icons.Formula />,
  conditional_text: <Icons.ConditionalText />,
  infobox: <Icons.InfoBox />,
  media: <Icons.Media />,
  link: <Icons.Link />,
  reference: <Icons.Reference />,
  contact_number: <Icons.ContactNumber />,
  note: <Icons.EhrData />,
  lab_data: <Icons.EhrData />,
  vital_sign: <Icons.EhrData />,
  medication: <Icons.EhrData />,
  problem_list: <Icons.EhrData />,
  demographics: <Icons.EhrData />,
  ehr_order: <Icons.EhrOrder />,
};

function isTypeInSuggestionType(suggestionType: SuggestionTypes, typeToCompare: SuggestionTypes) {
  switch (true) {
    case typeToCompare.includes(suggestionType):
      return true;
    case suggestionType === SuggestionTypeEnum.VARIABLE &&
      typeToCompare === SuggestionTypeEnum.DATA_EXTRACTOR:
      return true;
    case suggestionType === SuggestionTypeEnum.VARIABLE &&
      typeToCompare === SuggestionTypeEnum.NUMBER:
      return true;
    default:
      return false;
  }
}
