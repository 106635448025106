import CloseIcon from 'assets/icons/closeIcon';

interface DeletableBadgeProps {
  label: string;
  value?: any;
  onDelete?: (value: any) => void;
}

const DeletableBadge = ({ label, value, onDelete }: DeletableBadgeProps) => {
  return (
    <div className='flex items-center space-x-1 text-sm text-gray-700 rounded bg-gray-100 px-2 py-0.5'>
      <span>{label}</span>
      <span className='cursor-pointer' onClick={() => onDelete?.(value)}>
        <CloseIcon />
      </span>
    </div>
  );
};

export default DeletableBadge;
