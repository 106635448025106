import { useMutation, useQuery } from '@tanstack/react-query';
import { invitationAPI } from 'api/invitation';
import NavBar from 'components/bars/NavBar';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Backdrop from 'components/utils/Backdrop';
import Button from 'components/utils/Button';
import { CustomToast } from 'components/utils/toast-message';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useHistory, useParams } from 'react-router-dom';
import { LOGIN_ROUTE, SIGNUP_ROUTE } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';

const Invitation = () => {
  const { inviteCode }: any = useParams();
  const history = useHistory();
  const { authentication, logout, refetch } = useAuthentication();
  const { refetchTeam, refetchTeamList } = useTeam();
  const { isLoading, data, isError } = useQuery([inviteCode], invitationAPI.getInvitation, {
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const {
    isLoading: isMutateLoading,
    isError: isMutateError,
    mutate: acceptInvite,
  } = useMutation(invitationAPI.acceptInvitation, {
    onSuccess: () => {
      refetchTeam();
      refetchTeamList();
      refetch();
      toast.success(CustomToast, { data: 'Invitation Accepted!' });
      setTimeout(() => history.replace('/'), 0);
    },
  });

  if (isError || isMutateError) {
    toast.error(CustomToast, { data: 'Not valid invitation' });
    setTimeout(() => history.replace('/'), 0);
  }

  const userEmail = authentication.user?.email;
  useEffect(() => {
    if (!isLoading && data) {
      if (!!userEmail && (!data.data.email || data.data.email === userEmail)) {
        acceptInvite(inviteCode);
      } else if (!userEmail && (!data.data.email || data.data.user_exists)) {
        setTimeout(() => history.replace(`${LOGIN_ROUTE}?inviteCode=${inviteCode}`), 0);
      } else if (!userEmail && data.data.email && !data.data.user_exists) {
        setTimeout(() => history.replace(`${SIGNUP_ROUTE}?inviteCode=${inviteCode}`), 0);
      }
    }
  }, [isLoading]);

  const signinup = () => {
    logout();
    history.push(`${data?.data.user_exists ? LOGIN_ROUTE : SIGNUP_ROUTE}?inviteCode=${inviteCode}`);
  };

  return (
    <Backdrop>
      {isLoading || isMutateLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='hidden'>
            <NavBar />
          </div>
          <div className='space-y-5 rounded border border-white bg-white p-4 text-center'>
            <div className='text-3xl text-red-500'>
              Oops! This invitation is not intended for you.
            </div>
            <div>
              {data?.data.user_exists ? 'Signin' : 'Signup'} with{' '}
              <strong>{data?.data.email}</strong> to accept this invitation.
            </div>
            <div className='flex justify-center space-x-4'>
              <Button className='uppercase' onClick={() => history.push('/')}>
                Go back to main menu
              </Button>
              <Button className='uppercase' onClick={signinup}>
                {data?.data.user_exists ? 'Sign in' : 'Sign up'}
              </Button>
            </div>
          </div>
        </>
      )}
    </Backdrop>
  );
};

export default Invitation;
