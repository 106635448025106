import { ITag, ITagNode } from 'api/tag';
import DeletableBadge from 'components/utils/DeletableBadge';
import { useEffect, useState } from 'react';

interface TagSelectionsProps {
  rootTag: ITagNode;
  selectedCodes: string[];
  onDelete?: (code: string) => void;
}

interface SelectedTagsWithCategory {
  category: string;
  order: number;
  tags: ITag[];
}

interface SelectedTagsWithCategoryObj {
  [category: string]: SelectedTagsWithCategory;
}

const TagSelections = ({ rootTag, selectedCodes, onDelete }: TagSelectionsProps) => {
  const [tagWithCategory, setTagWithCategory] = useState<SelectedTagsWithCategory[]>([]);
  const getTitleWithCategory = (
    category: string,
    tagNode: ITagNode,
    result: SelectedTagsWithCategoryObj
  ) => {
    if (tagNode.read_only) {
      category = tagNode.title;
    } else {
      if (selectedCodes.includes(tagNode.code)) {
        const newTag = { title: tagNode.title, code: tagNode.code };
        if (!result[category]) {
          result[category] = {
            category,
            order: Object.keys(result).length,
            tags: [newTag],
          };
        } else {
          result[category].tags.push(newTag);
        }
      }
    }
    tagNode.children.map((childNode) => getTitleWithCategory(category, childNode, result));
  };

  useEffect(() => {
    const result: SelectedTagsWithCategoryObj = {};
    getTitleWithCategory('', rootTag, result);
    setTagWithCategory(Object.values(result).sort((a, b) => a.order - b.order));
  }, [selectedCodes]);

  return (
    <div className='p-3 space-y-2'>
      {tagWithCategory.map((obj, idx) => (
        <div className='space-y-1' key={idx}>
          <div className='text-sm text-gray-900'>{obj.category}</div>
          <div className='flex gap-2 flex-wrap'>
            {obj.tags.map((tag) => (
              <DeletableBadge
                key={tag.code}
                label={tag.title}
                value={tag.code}
                onDelete={onDelete}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TagSelections;
