import { useMutation, useQuery } from '@tanstack/react-query';
import { IModuleList } from 'api/module';
import { ChannelData, teamChannelAPI } from 'api/teamchannel';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import { Icons } from 'components/utils/Icons';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import RequiredMark from 'components/utils/requiredMark';
import { Body1, Body2, H2, H3 } from 'components/utils/typo';
import { useAuthentication } from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { CHANNELS_ROUTE } from '../../constants';
import { ChannelModuleList } from './ChannelModuleList';
import { ModuleSelectionModal } from './ModuleSelectionModal';

interface ChannelFormParams {
  channelId: string | undefined;
}

interface ChannelFormVariables {
  channelCode: string;
  name: string;
  password?: string;
  description: string;
  isInstitutional: boolean;
  isGeneric: boolean;
  modules: IModuleList[];
}

const defaultValues: ChannelFormVariables = {
  channelCode: '',
  name: '',
  password: '',
  description: '',
  isInstitutional: false,
  isGeneric: false,
  modules: [],
};

export const ChannelForm = () => {
  const history = useHistory();
  const [moduleSelectionModal, setModuleSelectionModal] = useState(false);
  const [modules, setModules] = useState<IModuleList[]>([]);
  const { authentication } = useAuthentication();
  const { channelId } = useParams<ChannelFormParams>();
  const { data, isLoading: isQuerying } = useQuery(
    ['channel', channelId],
    () => teamChannelAPI.getChannel(Number(channelId)),
    { enabled: !!channelId }
  );

  const isLoading = !!channelId && isQuerying;

  useEffect(() => {
    if (!data) return;
    reset({
      channelCode: data.channel_code,
      name: data.name,
      password: data.password,
      description: data?.description,
      isInstitutional: data?.is_institutional,
      isGeneric: data?.is_generic,
      modules: data?.modules,
    });
    setModules(data?.modules);
  }, [data]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<ChannelFormVariables>({
    defaultValues,
  });

  const { mutateAsync, isLoading: isMutating } = useMutation(
    !channelId
      ? teamChannelAPI.postChannel
      : (data: ChannelData) => teamChannelAPI.patchChannel(data, parseInt(channelId))
  );

  const onSubmit = async (data: ChannelFormVariables) => {
    if (isLoading || isMutating) return;
    await mutateAsync({
      name: data.name,
      password: data.password,
      is_generic: data.isGeneric,
      description: data.description,
      is_institutional: data.isInstitutional,
      modules: modules,
      channel_code: data.channelCode,
    });
    history.push(CHANNELS_ROUTE);
  };

  const moduleListOnDelete = (modules: IModuleList[]) => {
    setModules(modules);
  };

  const newModulesOnSelect = (modules: IModuleList[]) => {
    setModules((prev) => [...prev, ...modules]);
  };

  useEffect(() => {
    setValue('modules', modules, { shouldDirty: true });
  }, [modules]);

  return (
    <div className='bg-gray min-h-full'>
      {isLoading ? (
        <div className='flex min-h-screen items-center justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='mx-auto max-w-[1040px] px-4 pb-10'>
          <div className='px-4'>
            <H2 className='pb-[32px] pt-[40px]'>{!channelId ? 'Create' : 'Update'} Channel</H2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='space-y-[28px]'>
                <div className='rounded-2xl bg-white px-[45px] py-[30px]'>
                  <div className='max-w-[600px] space-y-[24px]'>
                    <H3>Channel Setting</H3>
                    <div>
                      <Label className='space-y-[4px]'>
                        <div className='flex'>
                          <Body2>Code</Body2>
                          <RequiredMark />
                        </div>
                        <Input {...register('channelCode', { required: true })} required />
                      </Label>
                    </div>
                    <div>
                      <Label className='space-y-[4px]'>
                        <div className='flex'>
                          <Body2>Name of channel</Body2>
                          <RequiredMark />
                        </div>
                        <Input {...register('name', { required: true })} required />
                      </Label>
                    </div>
                    <div>
                      <Label className='space-y-[4px]'>
                        <Body2>Password</Body2>
                        <Input {...register('password')} />
                      </Label>
                    </div>
                    <div>
                      <Label className='space-y-[4px]'>
                        <Body2>Description</Body2>
                        <Input {...register('description')} />
                      </Label>
                    </div>
                    <div>
                      <Label className='flex items-center space-x-[8px]'>
                        <Checkbox {...register('isInstitutional')} />
                        <Body2>Is Channel Institutional?</Body2>
                      </Label>
                    </div>
                    {authentication.user?.is_staff && (
                      <div>
                        <Label className='flex items-center space-x-[8px]'>
                          <Checkbox {...register('isGeneric')} />
                          <Body2>Do you want to make the channel public globally?</Body2>
                        </Label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='rounded-2xl bg-white px-[45px] py-[30px]'>
                  <div className='max-w-[600px] space-y-[24px]'>
                    <H3>Module List</H3>
                    <Body1>Add ALGO, Calculator modules to this channel.</Body1>
                    <div className='max-w-[880px] space-y-[16px]'>
                      <div className='flex'>
                        <div className='flex'>
                          <Button.Outline
                            type='button'
                            onClick={() => setModuleSelectionModal(true)}
                          >
                            <div className='flex'>
                              <Icons.Plus />
                              <span>Add Modules</span>
                            </div>
                          </Button.Outline>
                        </div>
                      </div>
                      <ChannelModuleList modules={modules} onChange={moduleListOnDelete} />
                    </div>
                  </div>
                </div>
                <div className='flex justify-end gap-[8px]'>
                  <div className='flex'>
                    <Button.Reverse type='button' onClick={() => history.push(CHANNELS_ROUTE)}>
                      Cancel
                    </Button.Reverse>
                  </div>
                  <div className='flex w-[100px]'>
                    <Button disabled={isMutating || isLoading || !isDirty}>
                      {isMutating ? <LoadingSpinner size='small' /> : 'Save'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ModuleSelectionModal
            open={moduleSelectionModal}
            onClose={() => setModuleSelectionModal(false)}
            onSelect={newModulesOnSelect}
            selected={modules}
          />
        </div>
      )}
    </div>
  );
};
