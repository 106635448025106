import axios from 'axios';
import { LEGACY_CALCULATOR_API_URL } from '../../constants';
import { GET_CALCULATORS } from '../actionsTypes';

export const getCalculators = () => async (dispatch) => {
  axios.get(LEGACY_CALCULATOR_API_URL).then((res) => {
    dispatch({
      type: GET_CALCULATORS,
      payload: res.data,
    });
  });
};
