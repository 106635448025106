import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import getTeamCalculators from 'actions/calculators/getTeamCalculators';
import getTeamMirrors from 'actions/mirrors/mirrorList';
import modulesListAction from 'actions/modules/modulesAction';
import axios from 'axios';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import CustomModal from 'components/utils/modals/CustomModal';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import knowledgeBaseListAction from '../../actions/knowledge-base/getKnowledgeBase';
import { ROLES, TeamWithMates } from '../../api/team';
import {
  AI_KNOWLEDGE_BASE_API_URL,
  ALGO_ROUTE,
  CALCULATOR_API_URL,
  CALC_ROUTE,
  DUPLICATE_AI_KNOWLEDGE_BASE_API_URL,
  DUPLICATE_CALC_API_URL,
  DUPLICATE_MODULE_API_URL,
  HAS_EDIT_PERMISSIONS,
  HAS_VIEW_PERMISSIONS,
  KB_ROUTE,
  MIRROR_API_URL,
  MODULE_API_URL,
  MODULE_TYPES,
  Permissions,
  UNVERIFY_AI_KB_API_URL,
  UNVERIFY_CALCULTAOR_API_URL,
  UNVERIFY_MODULE_API_URL,
  VERIFY_AI_KB_API_URL,
  VERIFY_CALCULATOR_API_URL,
  VERIFY_MODULE_API_URL,
} from '../../constants';
import { withTeam } from '../../hooks/useTeam';
import TransferModuleTeam from './TransferModuleTeam';

const StyledButton = withStyles({
  root: {
    color: '#FFFFFF',
    minWidth: 20,
    alignItems: 'center',
    borderRadius: '50%',
    height: 30,
    width: 30,
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 7,
  },
})(Button);

interface ModuleSettingPopupProps {
  module: any;
  modulesListAction: () => void;
  getTeamMirrors: () => void;
  getTeamCalculators: () => void;
  knowledgeBaseListAction: () => void;
  isAIKnowledgeBase: boolean;
  isCalc: boolean;
  buttonColor: string;
  buttonShadow: string;
  team: TeamWithMates;
}

interface ModuleSettingPopupState {
  anchorEl: any;
  transferModal: boolean;
  deleteModal: boolean;
  mirrorCopyModal: boolean;
  duplicateModal: boolean;
  duplicating: boolean;
  unverify: boolean;
  reverify: boolean;
  redirectLink: string;
}
class ModuleSettingPopup extends Component<ModuleSettingPopupProps, ModuleSettingPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      anchorEl: null,
      transferModal: false,
      deleteModal: false,
      mirrorCopyModal: false,
      duplicateModal: false,
      duplicating: false,
      unverify: false,
      reverify: false,
      redirectLink: '',
    };
  }

  handleMenu = (event: any) => this.setState({ anchorEl: event.currentTarget });

  handleMenuClose = () => this.setState({ anchorEl: null });

  editModule = () => {
    if (this.props.module?.type === MODULE_TYPES.KNOWLEDGE_BASE) {
      this.setState({
        redirectLink: generatePath(KB_ROUTE, { moduleId: this.props.module.id }),
      });
    } else if (this.props.module?.type === MODULE_TYPES.CALCULATOR) {
      this.setState({
        redirectLink: generatePath(CALC_ROUTE, { moduleId: this.props.module.id }),
      });
    } else {
      this.setState({
        redirectLink: generatePath(ALGO_ROUTE, { moduleId: this.props.module.id }),
      });
    }
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  toggleDuplicateModal = () => {
    this.handleMenuClose();
    this.setState((previous) => ({
      duplicateModal: !previous.duplicateModal,
    }));
  };

  deleteModule = () => {
    this.toggleDeleteModal();
    axios.delete(MODULE_API_URL + this.props.module.id + '/').then(() => {
      this.props.modulesListAction();
      this.props.getTeamMirrors();
    });
  };

  deleteMirror = () => {
    this.toggleDeleteModal();
    axios.delete(MIRROR_API_URL + this.props.module?.id).then(() => {
      this.props.getTeamMirrors();
    });
  };

  deleteCalculator = () => {
    this.toggleDeleteModal();
    axios.delete(CALCULATOR_API_URL + this.props.module.id + '/').then(() => {
      this.props.getTeamCalculators();
      this.props.getTeamMirrors();
    });
  };

  deleteKnowledgeBase = () => {
    this.toggleDeleteModal();
    axios.delete(AI_KNOWLEDGE_BASE_API_URL + this.props.module.id + '/').then(() => {
      this.props.knowledgeBaseListAction();
      this.props.getTeamMirrors();
    });
  };

  openTransferModal = () => {
    this.setState((previous) => ({
      transferModal: !previous.transferModal,
    }));
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<ModuleSettingPopupState, any>
    );
  };

  verifyModule = () => {
    this.handleMenuClose();
    axios
      .patch(VERIFY_MODULE_API_URL + this.props.module.id)
      .then(() => {
        toast.success(CustomToast, { data: 'ALGO verified successfully!' });
        this.props.modulesListAction();
        this.props.getTeamMirrors();
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Verification unsuccessful!' });
      });
  };

  verifyCalculator = () => {
    this.handleMenuClose();
    axios
      .patch(VERIFY_CALCULATOR_API_URL + this.props.module.id)
      .then(() => {
        this.props.getTeamCalculators();
        toast.success(CustomToast, { data: 'Calculator verified successfully!' });
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Verification unsuccessful!' });
      });
  };

  verifyKnowledgeBase = () => {
    this.handleMenuClose();
    axios
      .patch(VERIFY_AI_KB_API_URL + this.props.module.id + '/')
      .then(() => {
        this.props.knowledgeBaseListAction();
        toast.success(CustomToast, { data: 'Knowledge Base verified successfully!' });
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Verification unsuccessful!' });
      });
  };

  unverifyModule = () => {
    this.handleMenuClose();
    axios
      .patch(UNVERIFY_MODULE_API_URL + this.props.module.id)
      .then(() => {
        toast.success(CustomToast, { data: 'ALGO unverified successfully!' });
        this.props.modulesListAction();
        this.props.getTeamMirrors();
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Cannot unverify ALGO!' });
      });
  };

  unverifyCalculator = () => {
    this.handleMenuClose();
    axios
      .patch(UNVERIFY_CALCULTAOR_API_URL + this.props.module.id)
      .then(() => {
        this.props.getTeamCalculators();
        toast.success(CustomToast, { data: 'Calculator unverified successfully!' });
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Cannot unverify calculator!' });
      });
  };

  unverifyKnowledgeBase = () => {
    this.handleMenuClose();
    axios
      .patch(UNVERIFY_AI_KB_API_URL + this.props.module.id + '/')
      .then(() => {
        this.props.knowledgeBaseListAction();
        toast.success(CustomToast, { data: 'Knowledge Base unverified successfully!' });
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Cannot unverified Knowledge Base!' });
      });
  };

  duplicateModule = () => {
    this.toggleDuplicateModal();
    this.setState({ duplicating: true });

    // passing module in payload here, because module is required in payload for permission classes
    // in post requests
    axios
      .post(DUPLICATE_MODULE_API_URL + this.props.module.id, {
        module: this.props.module.id,
      })
      .then(async () => {
        await this.props.modulesListAction();
        await this.props.knowledgeBaseListAction();
        this.setState({ duplicating: false });
        toast.success(CustomToast, { data: 'ALGO Duplicated!' });
      })
      .catch(() => {
        this.setState({ duplicating: false });
      });
  };

  duplicateCalculator = () => {
    this.toggleDuplicateModal();
    this.setState({ duplicating: true });

    // passing calculator in payload here, because calculator is required in payload
    // for permission classes in post requests
    axios
      .post(DUPLICATE_CALC_API_URL + this.props.module.id, {
        calculator: this.props.module.id,
      })
      .then(async () => {
        await this.props.getTeamCalculators();
        this.setState({ duplicating: false });
        toast.success(CustomToast, { data: 'Calculator Duplicated!' });
      })
      .catch(() => {
        this.setState({ duplicating: false });
      });
  };

  duplicateAIKnowledgeBase = () => {
    this.toggleDuplicateModal();
    this.setState({ duplicating: true });

    // passing calculator in payload here, because calculator is required in payload
    // for permission classes in post requests
    axios
      .post(DUPLICATE_AI_KNOWLEDGE_BASE_API_URL + this.props.module.id + '/', {
        ai_knowledge_base: this.props.module.id,
      })
      .then(async () => {
        await this.props.knowledgeBaseListAction();
        await this.props.getTeamMirrors();

        this.setState({ duplicating: false });
        toast.success(CustomToast, { data: 'Knowledge Base Duplicated!' });
      })
      .catch(() => {
        this.setState({ duplicating: false });
      });
  };

  createMirrorCopy = () => {
    this.toggleModal('mirrorCopyModal');
    const type = this.props.module?.type;
    const moduleId = this.props.module?.id;

    let payload = { team: this.props.team.id };
    switch (type) {
      case MODULE_TYPES.CALCULATOR:
        payload['calculator'] = moduleId;
        break;
      case MODULE_TYPES.KNOWLEDGE_BASE:
        payload['knowledge_base'] = moduleId;
        break;
      case MODULE_TYPES.ALGO:
        payload['module'] = moduleId;
        break;
      default:
        break;
    }

    axios.post(MIRROR_API_URL, payload).then(async () => {
      await this.props.getTeamMirrors();
      toast.success(CustomToast, { data: 'Mirror created successfully!' });
    });
  };

  verify = () => {
    this.state.reverify === true && this.toggleModal('reverify');

    this.props.isCalc
      ? this.verifyCalculator()
      : this.props.isAIKnowledgeBase
      ? this.verifyKnowledgeBase()
      : this.verifyModule();
  };

  unverify = () => {
    this.toggleModal('unverify');

    this.props.isCalc
      ? this.unverifyCalculator()
      : this.props.isAIKnowledgeBase
      ? this.unverifyKnowledgeBase()
      : this.unverifyModule();
  };

  render() {
    if (this.state.redirectLink) {
      return <Redirect push to={this.state.redirectLink} />;
    }

    const { module, isAIKnowledgeBase } = this.props;
    const isMirror = module?.type === MODULE_TYPES.MIRROR;

    const isTeamAdmin = this.props.team.current_teammate?.role === ROLES.Admin;

    const hasOwnerPermission = isTeamAdmin || module?.current_user_role === Permissions.OWNER;

    const hasEditPermission =
      !isMirror &&
      (isTeamAdmin ||
        HAS_EDIT_PERMISSIONS.includes(module?.current_user_role) ||
        (!module?.current_user_role &&
          !!this.props.team.current_teammate?.role &&
          HAS_EDIT_PERMISSIONS.includes(module.permission_type)));

    const hasViewPermissions =
      isMirror ||
      isTeamAdmin ||
      HAS_VIEW_PERMISSIONS.includes(module?.current_user_role) ||
      (!module?.current_user_role &&
        !!this.props.team.current_teammate?.role &&
        HAS_VIEW_PERMISSIONS.includes(module.permission_type));

    let buttonColor = this.props.buttonColor;
    let buttonShadow = this.props.buttonShadow;

    return (
      <div>
        <StyledButton
          style={{ backgroundColor: buttonColor, boxShadow: buttonShadow, alignSelf: 'center' }}
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={this.handleMenu}
        >
          ...
        </StyledButton>

        <Menu
          id='simple-menu'
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
        >
          {hasEditPermission && <MenuItem onClick={this.editModule}> Edit </MenuItem>}

          {hasOwnerPermission && (
            <MenuItem
              onClick={() => {
                this.handleMenuClose();
                this.toggleDeleteModal();
              }}
            >
              Delete
            </MenuItem>
          )}

          {!isMirror && hasViewPermissions && (
            <MenuItem onClick={this.toggleDuplicateModal}>Duplicate</MenuItem>
          )}

          {!isMirror && hasOwnerPermission && (
            <MenuItem
              onClick={() => {
                this.handleMenuClose();
                this.toggleModal('mirrorCopyModal');
              }}
            >
              Create Mirror Copy
            </MenuItem>
          )}

          {hasOwnerPermission && (
            <MenuItem
              onClick={() => {
                this.handleMenuClose();
                this.openTransferModal();
              }}
            >
              Transfer Team
            </MenuItem>
          )}

          {!isMirror && hasViewPermissions && module.last_verified && (
            <MenuItem
              onClick={() => {
                this.handleMenuClose();
                this.toggleModal('reverify');
              }}
            >
              Reverify Module
            </MenuItem>
          )}

          {!isMirror && hasViewPermissions && module.last_verified && (
            <MenuItem
              onClick={() => {
                this.toggleModal('unverify');
                this.handleMenuClose();
              }}
            >
              Unverify Module
            </MenuItem>
          )}
        </Menu>

        <CustomModal
          width={500}
          contentClass='center-modal'
          modalHeader='Transfer ALGO'
          modal={this.state.transferModal}
          toggleModal={this.openTransferModal}
        >
          <TransferModuleTeam
            moduleId={module.id}
            type={module?.type}
            toggleModal={this.openTransferModal}
          />
        </CustomModal>

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content={
            isMirror
              ? 'Are you sure you want to delete this mirror?'
              : this.props.isCalc
              ? 'Are you sure you want to delete this calculator?'
              : isAIKnowledgeBase
              ? 'Are you sure you want to delete this Knowledge Base? '
              : 'Are you sure you want to delete this ALGO?'
          }
          toggleModal={this.toggleDeleteModal}
          performAction={
            isMirror
              ? this.deleteMirror
              : this.props.isCalc
              ? this.deleteCalculator
              : isAIKnowledgeBase
              ? this.deleteKnowledgeBase
              : this.deleteModule
          }
        />
        <ConfirmModal
          preset='duplicate'
          open={this.state.duplicateModal}
          content={
            this.props.isCalc
              ? 'Are you sure you want to duplicate this calculator?'
              : isAIKnowledgeBase
              ? 'Are you sure you want to duplicate this Knowledge Base?'
              : 'Are you sure you want to duplicate this ALGO?'
          }
          toggleModal={this.toggleDuplicateModal}
          performAction={
            this.props.isCalc
              ? this.duplicateCalculator
              : isAIKnowledgeBase
              ? this.duplicateAIKnowledgeBase
              : this.duplicateModule
          }
        />
        <ConfirmModal
          open={this.state.mirrorCopyModal}
          content='Are you sure you want to create a mirror copy?'
          confirmText='Create Mirror'
          toggleModal={() => this.toggleModal('mirrorCopyModal')}
          performAction={this.createMirrorCopy}
        />
        <ConfirmModal
          open={this.state.unverify || this.state.reverify}
          content={
            this.state.unverify
              ? 'Are you sure you want to unverify this module?'
              : this.state.reverify && 'Are you sure you want to reverify this module?'
          }
          confirmText={
            this.state.unverify
              ? 'Unverify Module'
              : this.state.reverify
              ? 'Reverify Module'
              : undefined
          }
          toggleModal={() =>
            this.state.unverify ? this.toggleModal('unverify') : this.toggleModal('reverify')
          }
          performAction={this.state.unverify ? this.unverify : this.verify}
        />
      </div>
    );
  }
}

export default withTeam(
  connect(() => {}, {
    modulesListAction,
    getTeamCalculators,
    knowledgeBaseListAction,
    getTeamMirrors,
  })(ModuleSettingPopup)
);
