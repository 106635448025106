import NavBar from 'components/bars/NavBar';
import { ChannelForm } from './ChannelForm';

export const OldChannelFormPage = () => {
  return (
    <div>
      <NavBar />
      <ChannelForm />
    </div>
  );
};
