import NavBar from '../../bars/NavBar';
import ProfileLayout from './ProfileLayout';
import Footer from '../../layout/Footer';

const Profile = () => (
  <div className='ht-100'>
    <NavBar />
    <ProfileLayout />
    <Footer />
  </div>
);

export default Profile;
