import AvoMDLogo from 'assets/images/bigLogo.png';
import Button from 'components/utils/Button';
import DisabledInput from 'components/utils/DisabledInput';
import Input from 'components/utils/Input';
import PasswordInput from 'components/utils/PasswordInput';
import PasswordRule from 'components/utils/PasswordRule';
import { useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { emailPattern } from 'utils/utilityFunctions';
import { SignupFormData } from './Signup';
interface FirstPageProps {
  register: UseFormRegister<SignupFormData>;
  done: boolean;
  errors: FieldErrors<SignupFormData>;
  email?: string;
  watch: UseFormWatch<SignupFormData>;
}

const FirstPage = ({ register, done, errors, email, watch }: FirstPageProps) => {
  const [matched, setMatched] = useState<boolean>(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const password = watch('password');

  const confirmPassword = async (confirmPassword: string, formValue: SignupFormData) => {
    if (confirmPassword !== formValue.password) {
      return 'Passwords do not match';
    }
    return true;
  };
  const checkPasswordRules = async (password) => {
    if (!matched) {
      return 'Password rule do not match';
    }
    return true;
  };
  return (
    <div className='space-y-[12px] max-w-[336px] mx-auto'>
      <div>
        <img src={AvoMDLogo} alt='AvoMD Logo' className='mx-auto' />
      </div>
      <div className='space-y-[8px]'>
        {email ? (
          <DisabledInput>{email}</DisabledInput>
        ) : (
          <Input
            placeholder='Email'
            {...register('email', {
              required: true,
              pattern: { value: emailPattern, message: 'Email is not valid' },
            })}
          />
        )}
        {errors.email && <span className='text-sm text-red-500'>{errors.email.message}</span>}
        <PasswordInput
          placeholder='Password'
          {...register('password', {
            required: true,
            validate: { checkPasswordRules },
            onBlur: () => setShowPasswordValidation(false),
          })}
          onFocus={() => setShowPasswordValidation(true)}
        />
        {showPasswordValidation && <PasswordRule password={password} setMatched={setMatched} />}
        {!showPasswordValidation && errors.password && (
          <span className='text-sm text-red-500'>{errors.password.message}</span>
        )}
        <PasswordInput
          placeholder='Confirm Password'
          {...register('confirmPassword', {
            required: true,
            validate: { confirmPassword },
          })}
        />
        {errors.confirmPassword && (
          <span className='text-sm text-red-500'>{errors.confirmPassword.message}</span>
        )}
        <Input placeholder='First Name' {...register('firstName', { required: true })} />
        <Input placeholder='Last Name' {...register('lastName', { required: true })} />
      </div>
      <div>
        <Button disabled={!done || Object.keys(errors).length > 0}>Next</Button>
      </div>
    </div>
  );
};

export default FirstPage;
