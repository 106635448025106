function Infobox(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#E5EDF0' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M42.7999 30C42.7999 37.0692 37.0692 42.7999 30 42.7999C22.9307 42.7999 17.2 37.0692 17.2 30C17.2 22.9307 22.9307 17.2 30 17.2C37.0692 17.2 42.7999 22.9307 42.7999 30ZM31.4222 25.1644C31.4222 25.9499 30.7854 26.5866 30 26.5866C29.2145 26.5866 28.5777 25.9499 28.5777 25.1644C28.5777 24.3789 29.2145 23.7422 30 23.7422C30.7854 23.7422 31.4222 24.3789 31.4222 25.1644ZM28.9778 28.2933C28.7569 28.2933 28.5778 28.4724 28.5778 28.6933V36.9955C28.5778 37.2164 28.7569 37.3955 28.9778 37.3955H31.0222C31.2431 37.3955 31.4222 37.2164 31.4222 36.9955V28.6933C31.4222 28.4724 31.2431 28.2933 31.0222 28.2933H28.9778Z'
        fill='#2E3438'
      />
    </svg>
  );
}

export default Infobox;
