/* TODO: recheck if cloneDeep is necessary */
import _ from 'lodash';

// destination.index + 2
// +1 because first element is always introduction
// +1 because array index starts from zero and positions starts from 1
export const POSITION_IDX_ADJUSTMENT = 2;

// TODO: export is only for test
// may not need it later
export const getMaxPositionInContainerCard = (
  containerCard,
  posOfContainerCardInContainerBoard
) => {
  const cards = containerCard.ui_elements;
  if (cards.length === 0) {
    /* 
      TODO: currently, group card does not have position key so pass index value here
      and use it. the reason for -1 is the function calls this does +1
    */
    // return 0;
    return posOfContainerCardInContainerBoard - 1;
  }
  const lastCard = cards.reduce(function (prev, current) {
    return prev.position > current.position ? prev : current;
  });
  return lastCard.position;
};

// TODO: export is only for test
export const insert = (arr, index, newItem) => {
  let arr1 = [...arr];
  const idx = index < 0 || (!index && index !== 0) ? arr1.length : index;
  const newItem1 = Array.isArray(newItem) ? newItem : [newItem];

  arr1.splice(idx, 0, ...newItem1);
  return arr1;
};

export const findCardById = (cards, cardId) => {
  if (Array.isArray(cards)) {
    return cards.find((i) => i.id === cardId);
  } else {
    for (const key in cards) {
      if (cards[key].id === cardId) {
        return cards[key];
      }
    }
  }

  return null;
};

export const insertCardIntoContainerCard = (containerCard, card, position) => {
  if (position === null || position === undefined) {
    position = -1; // if no position parameter, insert last
  }
  if (containerCard.ui_elements) {
    const uiElements = insert(containerCard.ui_elements, position, card);
    return {
      ...containerCard,
      ui_elements: _.cloneDeep(uiElements),
    };
  } else if (containerCard.items) {
    const items = insert(containerCard.items, position, card);
    return {
      ...containerCard,
      items: _.cloneDeep(items),
    };
  }
};

export const deleteCardFromContainerCard = (containerCard1, card1) => {
  const card = JSON.parse(JSON.stringify(card1));
  const containerCard = JSON.parse(JSON.stringify(containerCard1));

  if (containerCard.ui_elements) {
    const uiElements = containerCard.ui_elements.filter((i) => i.id !== card.id);
    return {
      ...containerCard,
      ui_elements: uiElements,
    };
  } else if (containerCard.items) {
    const items = containerCard.items.filter((i) => i.id !== card.id);
    return {
      ...containerCard,
      items: items,
    };
  }
};

export const replaceCardContentInContainerCard = (containerCard, newCard) => {
  if (containerCard.ui_elements) {
    const uiElements = containerCard.ui_elements.map((card) =>
      card.id !== newCard.id ? card : newCard
    );
    const newContainerCard = {
      ...containerCard,
      ui_elements: uiElements,
    };
    return newContainerCard;
  } else if (containerCard.items) {
    const items = containerCard.items.map((card) => (card.id !== newCard.id ? card : newCard));
    const newContainerCard = {
      ...containerCard,
      items: _.cloneDeep(items),
    };
    return newContainerCard;
  }
};

export const initialValuesForCard = {
  id: 'initial_id',
  position: 0,
  resourcetype: '',
  title: '',
  trigger: {},
  editMode: false,
};

export const initialValuesForTextInputCard = {
  id: 'initial_id',
  position: 0,
  resourcetype: 'TextInput',
  title: '',
  trigger: {},
  editMode: true,
};

export const createDefaultTextInputCardForContainerCard = (
  containerCard,
  posOfContainerCardInContainerBoard
) => {
  const maxPosition = getMaxPositionInContainerCard(
    containerCard,
    posOfContainerCardInContainerBoard
  );

  return {
    ...initialValuesForTextInputCard,
    position: maxPosition + 1,
  };
};
