import * as React from 'react';

function InfoBoxIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0089 8.04444C10.4533 8.04444 10.7822 7.74222 10.7822 7.33333C10.7822 6.90667 10.4533 6.62222 10.0089 6.62222C9.55556 6.62222 9.22667 6.90667 9.22667 7.33333C9.22667 7.74222 9.55556 8.04444 10.0089 8.04444Z'
        fill='#FF8E3D'
      />
      <path d='M10.648 13.8222V8.84444H9.35103V13.8222H10.648Z' fill='#FF8E3D' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM9.99933 3.06667C6.17015 3.06667 3.06599 6.17083 3.06599 10C3.06599 13.8292 6.17015 16.9333 9.99933 16.9333C13.8285 16.9333 16.9327 13.8292 16.9327 10C16.9327 6.17083 13.8285 3.06667 9.99933 3.06667Z'
        fill='#FF8E3D'
      />
    </svg>
  );
}

export default InfoBoxIcon;
