import React, { useEffect } from 'react';
import { ExpandableList } from '../../../utils/form-input/ExpandableList';
import { Menu } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { Icons } from '../../../utils/Icons';
import { Body1, Body2 } from '../../../utils/typo';
import { CategoricalVariable } from '../../../../api/data-extractor';

interface CategoryListInputInputProps {
  item: CategoricalVariable;
  onChange: (data: CategoricalVariable) => void;
}

export const CategoryListInput = ({ item, onChange }: CategoryListInputInputProps) => {
  const handleAdd = () => {
    const categories = [...(item.categories ?? []), ''];
    onChange({ ...item, categories });
  };
  const handleChange = (index: number, value: string) => {
    const categories = item.categories!.map((category, categoryIndex) =>
      categoryIndex === index ? value : category
    );
    onChange({ ...item, categories });
  };
  const handleDuplicate = (index: number) => {
    const categories = [item.categories![index], ...item.categories!];
    onChange({ ...item, categories });
  };
  const handleDelete = (index: number) => {
    const categories = item.categories!.filter((_, categoryIndex) => categoryIndex !== index);
    onChange({ ...item, categories });
  };

  useEffect(() => {
    if (!item.categories || item.categories?.length < 1) {
      handleAdd();
    }
  }, []);

  return (
    <ExpandableList
      onAdd={handleAdd}
      setValue={(categories) => onChange({ ...item, categories })}
      addItemLabel='Add Category'
      items={item.categories ?? []}
    >
      <ExpandableList.Items>
        {item.categories &&
          item.categories.map((category, index) => (
            <ExpandableList.Item index={index}>
              <ExpandableList.Button>
                <div className='flex w-full cursor-pointer items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'>
                  <input
                    className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
                    placeholder='Category Name'
                    value={category}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <Menu as='div' className='relative' onClick={(e) => e.stopPropagation()}>
                    {({ open }) => (
                      <>
                        <Menu.Button
                          as='p'
                          className={twMerge(
                            'm-0 flex h-[20px] w-[20px] items-center justify-center rounded',
                            open && 'bg-gray-600'
                          )}
                        >
                          <Icons.ThreeDot className={open ? 'fill-white' : 'fill-gray-600'} />
                        </Menu.Button>
                        <Menu.Items
                          className={twMerge(
                            'absolute right-0 z-10 m-0 w-[100px] rounded bg-white shadow-md',
                            'outline-0' // due to material UI
                          )}
                        >
                          <Menu.Item
                            className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                            as='p'
                            onClick={() => handleDuplicate(index)}
                          >
                            <Body2>Duplicate</Body2>
                          </Menu.Item>
                          <Menu.Item
                            as='p'
                            className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                            onClick={() => handleDelete(index)}
                          >
                            <Body2>Delete</Body2>
                          </Menu.Item>
                        </Menu.Items>
                      </>
                    )}
                  </Menu>
                </div>
              </ExpandableList.Button>
            </ExpandableList.Item>
          ))}
      </ExpandableList.Items>
      <ExpandableList.Item>
        <ExpandableList.Button>
          <div className='flex w-full items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'>
            <Body1 className='text-gray-900'>Other/Not Applicable</Body1>
          </div>
        </ExpandableList.Button>
      </ExpandableList.Item>
    </ExpandableList>
  );
};
