import { Avatar, Backdrop, Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledBackdrop = withStyles(() => ({
  root: {
    zIndex: 2000,
    color: '#08A88E',
    background: 'rgba(214, 216, 219, 0.9)',
  },
}))(Backdrop);

export const StyledBox = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '30px',
  },
}))(Box);

export const Title = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '30px',
    color: '#474747',
  },
}))(Typography);

export const Initials = withStyles((theme) => ({
  root: {
    fontSize: '18px',
    width: '28px',
    height: '28px',
    backgroundColor: '#057575',
    color: '#FFFFFF',
    fontWeight: 600,
  },
}))(Avatar);

export const MainButton = withStyles((theme) => ({
  root: {
    minWidth: '210px',
    maxWidth: '250px',
    height: '56px',
    color: 'white',
    background: '#08A88E',
    boxShadow: '0px 5px 19px rgba(0, 0, 0, 0.1)',
    borderRadius: '100px',
    '&:hover': {
      background: '#08A88E',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': { background: '#08A88E' },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'none',
  },
}))(Button);
