import { MainLayout } from 'components/layout/MainLayout';
import { ModuleForm } from 'components/module-detail/ModuleForm';
import { MODULE_TYPES } from '../../constants';

export const AlgoUpsert = () => {
  return (
    <MainLayout className='bg-gray-100'>
      <ModuleForm moduleType={MODULE_TYPES.ALGO} />
    </MainLayout>
  );
};
