import { ModuleLayout } from 'components/layout/ModuleLayout';
import { ReferenceManagerResourceTable } from 'components/resources/reference-manager/ReferenceManagerTable';

export const References = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <ReferenceManagerResourceTable />
    </ModuleLayout>
  );
};
