import axios from 'axios';
import { AnswerSection } from '../answer-board/AnswerSection';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext, useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { MODULE_API_URL } from '../../constants';
import { hasViewOnlyPermission } from '../../utils/permissions';
import AnswerPageForm from '../answer-board/answerPageForm';
import { CustomToast } from '../utils/toast-message';
import { AnswerElementObject, UiElementContext } from 'components/utils/module/UiElementContext';

export const AnswerBoard = () => {
  //   const { moduleId } = useParams<{
  //     moduleId: string;
  //   }>();
  //   const { pathname } = useLocation();
  //   const moduleType = pathname.split('/')[1];
  const { module } = useContext(ModuleContext);
  const hasPermission = !hasViewOnlyPermission(module?.type);

  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  const { answerElements, refetchAnswerElements } = useContext(UiElementContext);
  const [answerCards, setAnswerCards] = useState<AnswerElementObject>({});
  useEffect(() => {
    setAnswerCards(answerElements);
  }, [answerElements]);

  const answerPageSavePosition = () => {
    let payload = {};
    for (let i in answerCards) {
      answerCards[i].answerCards.forEach((ansCard, index) => {
        payload[ansCard.id] = {
          position: index + 1,
          answer_page: answerCards[i].id,
        };
      });
    }

    axios
      .put(`${MODULE_API_URL}${module?.id}/ans_card_position/`, {
        positions: payload,
      })
      .then(() => refetchAnswerElements());
  };

  useEffect(() => {
    hasPermission && answerPageSavePosition();
  }, [answerCards]);

  const onAnswerBoardDragEnd = (result, columns) => {
    if (!result.destination) return;
    if (hasViewOnlyPermission()) {
      toast.error(CustomToast, { data: "You don't have permission to perform this action" });
      return;
    }
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.answerCards];
      const destItems = [...destColumn.answerCards];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setAnswerCards({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          answerCards: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          answerCards: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.answerCards];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setAnswerCards({
        ...columns,
        [source.droppableId]: {
          ...column,
          answerCards: copiedItems,
        },
      });
    }
  };

  if (Object.keys(answerCards).length < 1) {
    return null;
  }

  return (
    <>
      <div className='ml-3 md:max-w-[1000px] lg:max-w-[1100px] xl:w-full'>
        <AnswerSection
          answerPages={answerCards}
          onDragEnd={onAnswerBoardDragEnd}
          resetAnswerPages={refetchAnswerElements}
        />
      </div>
      <SlidingPane
        isOpen={panelOpen}
        onRequestClose={() => setPanelModalOpen(true)}
        from='right'
        className='sliding-panel-shadow mt-5'
        width='620px'
        hideHeader
      >
        <div className='h-full overflow-x-visible bg-white'>
          <AnswerPageForm
            moduleId={module?.id}
            toggleModal={() => setPanelOpen(false)}
            answerPageId={null}
            resetState={refetchAnswerElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidingPane>
    </>
  );
};
