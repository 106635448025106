import clsx from 'clsx';
import Button from 'components/utils/Button';
import { CustomPopover } from 'components/utils/popover/Popover';
import { twMerge } from 'tailwind-merge';

interface TiptapPopoverLayoutProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  classes?: string;
  isAddDisabled: boolean;
  children: React.ReactNode;
  direction: 'left' | 'right' | 'partial-left';
  onAdd: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// TODO: remove
// see more details at (https://github.com/avoMD/builder-frontend/issues/912)
export const TiptapPopoverLayout = ({
  open,
  title,
  onAdd,
  onClose,
  classes,
  children,
  direction,
  isAddDisabled,
}: TiptapPopoverLayoutProps) => {
  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      classes={twMerge(
        classes,
        direction === 'right' ? 'left-0' : direction === 'left' ? 'right-0' : '-right-[50px]',
        'flex flex-col items-center gap-4 !mb-10 bg-white !rounded shadow-06 w-[340px]'
      )}
    >
      {title && (
        <div className='flex h-11 w-full items-center justify-center bg-gray-100 !pl-2'>
          <p className='text-caption-1 text-gray-900'> {title} </p>
        </div>
      )}
      <div className={clsx('flex w-full flex-col gap-2 !px-4 !pb-4', !title && '!mt-4')}>
        {children}

        <div className='!mt-2 flex justify-end'>
          <Button.Reverse
            type='button'
            onClick={onClose}
            className='flex h-9 !w-20 items-center justify-center text-button-1 text-primary-600'
          >
            Cancel
          </Button.Reverse>
          <Button
            onClick={onAdd}
            disabled={isAddDisabled}
            className='flex h-9 !w-20 items-center justify-center text-button-1'
          >
            Add
          </Button>
        </div>
      </div>
    </CustomPopover>
  );
};
