import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

interface accordionProps {
  conditionalText?: any;
  expanded?: any;
}
export const Accordion = withStyles({
  root: {
    background: (props: accordionProps) =>
      props.conditionalText && props.expanded ? 'white' : '#F4F6F9',
  },
  '&:before': {
    display: 'none',
    boxSizing: 'border-box',
    borderRadius: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: (props) => (props.conditionalText ? 'none' : '2px solid #DADADA'),
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);
interface accordionSummaryProps {
  conditionalText?: any;
}
export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    marginBottom: (props: accordionSummaryProps) => (props.conditionalText ? 0 : -1),
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid #D3D3D3',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
