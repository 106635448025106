import axios from 'axios';
import { CALCULATOR_UNIQUE_CODE_API_URL, UNIQUE_CODE_API_URL } from 'constants';
import { GET_UNIQUE_CODE } from '../actionsTypes';

const getUniqueCode =
  (moduleID, module_type = '') =>
  async (dispatch) => {
    let API_URL;
    if (module_type === 'calculator') {
      API_URL = `${CALCULATOR_UNIQUE_CODE_API_URL}${moduleID}`;
    } else {
      API_URL = `${UNIQUE_CODE_API_URL}${moduleID}`;
    }

    const res = await axios.get(API_URL);
    dispatch({
      type: GET_UNIQUE_CODE,
      payload: res.data,
    });
  };

export default getUniqueCode;
