import { ReactElement, ReactNode } from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';

export interface WithOnlyStaffProps {
  type: StaffOnlyBadgeType;
  x?: number;
  y?: number;
  children?: ReactNode;
}

export enum StaffOnlyBadgeType {
  STAFF = 'STAFF',
  BETA = 'BETA',
}

export const StaffOnlyWrapper = ({ type, x = 0, y = 0, children }: WithOnlyStaffProps) => {
  const { authentication } = useAuthentication();
  const isStaff = authentication.user?.is_staff;

  return isStaff ? (
    <div className='relative select-none'>
      {children}
      <span
        className={`absolute rounded px-[2px] py-0 text-[6px] leading-normal text-gray-text-weak 
         ${type === StaffOnlyBadgeType.STAFF ? 'bg-amber-200' : 'bg-blue-300'}`}
        style={{ top: y, left: x }}
      >
        {type}
      </span>
    </div>
  ) : null;
};

interface StaffOnlyWrapIfProps {
  condition: boolean;
  options: WithOnlyStaffProps;
  element: ReactElement;
}

export const StaffOnlyWrapIf = ({ condition, options, element }: StaffOnlyWrapIfProps) => {
  return condition ? <StaffOnlyWrapper {...options}>{element}</StaffOnlyWrapper> : element;
};
