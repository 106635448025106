import { differenceInSeconds, format } from 'date-fns';
export function formattedDate(date: number | string) {
  let parsedDate: Date;
  try {
    parsedDate = new Date(date);
    return format(parsedDate, 'MM/dd/yyyy, HH:mm');
  } catch (error) {
    return '';
  }
}

export const getTimeDifference = (datetimeString: string, baseTime: number = 600) => {
  const difference = differenceInSeconds(new Date(), datetimeString);
  const percentagePassed = (difference / baseTime) * 100;
  return Math.min(99, percentagePassed);
};
