import { GET_MEDIA } from 'actions/actionsTypes';

const mediaReducer = (state = { images: [] }, action) => {
  switch (action.type) {
    case GET_MEDIA:
      return {
        ...state,
        images: action.payload,
      };

    default:
      return state;
  }
};

export default mediaReducer;
