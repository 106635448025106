import * as React from 'react';

function CloseFilled(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10' r='8' fill='#C5D1D8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6366 7.49009L12.6535 6.507L10.0729 9.08755L7.4927 6.5073L6.50961 7.49039L9.08986 10.0706L6.50918 12.6513L7.49227 13.6344L10.0729 11.0537L12.6539 13.6347L13.637 12.6516L11.056 10.0706L13.6366 7.49009Z'
        fill='white'
      />
    </svg>
  );
}

export default CloseFilled;
