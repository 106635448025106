import InputLabel from '@material-ui/core/InputLabel';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import { ModuleWithMirror, useModules } from '../../hooks/useModules';
import { useTeam } from '../../hooks/useTeam';

interface ModuleTransferProps {
  module: ModuleWithMirror;
}

const ModuleTransfer = ({ module }: ModuleTransferProps) => {
  const { team, teamList } = useTeam();
  const teams = useMemo(() => teamList.filter((item) => item.id !== team.id), [teamList, team]);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { transferModule } = useModules();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedTeamId === 0) {
      toast.error(CustomToast, { data: 'Please select a team' });
    }
    setIsLoading(true);
    transferModule(
      { module, teamId: selectedTeamId },
      {
        onSettled: () => setIsLoading(false),
      }
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSelectedTeamId(Number(event.target.value));

  return (
    <>
      {/* TODO: Remove the global style for materialize and bootstrap in future */}
      {globalStyles}
      <Form onSubmit={onSubmit}>
        <FormGroup style={{ marginBottom: 50 }}>
          <InputLabel>Select New Team</InputLabel>
          <Input type='select' value={selectedTeamId} onChange={onChange}>
            <option>-----</option>
            {teams.map((team: any) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <CreateButton style={{ width: 250 }} type='submit' disabled={isLoading}>
          Change Team
        </CreateButton>
      </Form>
    </>
  );
};

export default ModuleTransfer;
