import NavBar from 'components/bars/NavBar';
import Button from 'components/utils/Button';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { H3 } from 'components/utils/typo';
import { useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import EhrConfigForm from './EhrConfigForm';
import EhrConfigList from './EhrConfigList';

const EHRConfig = () => {
  const [isPaneOpen, setIsPaneOpen] = useState(false);

  return (
    <>
      {/* // TODO: Remove the global style for materialize and bootstrap in future */}
      {globalStyles}
      <div>
        <NavBar activeState='TeamManagement' />
        <div className='m-10 sm:w-1/2'>
          <div className='mb-4 flex items-center justify-between'>
            <H3>EHR Configs</H3>
            <Button
              className='!w-fit font-bold focus:bg-primary-500'
              onClick={() => setIsPaneOpen(true)}
            >
              Add New
            </Button>
          </div>
          <EhrConfigList />
        </div>

        <SlidingPane width='720px' isOpen={isPaneOpen} onRequestClose={() => setIsPaneOpen(false)}>
          <EhrConfigForm closeModal={() => setIsPaneOpen(false)} />
        </SlidingPane>
      </div>
    </>
  );
};

export default EHRConfig;
