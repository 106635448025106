export function MobileNotSupportPage() {
  return (
    <section className='h-[100vh] align-middle flex items-center flex-col justify-center text-center'>
      <h1 className='text-heading-1'>Sorry, Mobile Access Not Supported.</h1>
      <p className='pt-4 text-gray-500'>
        We apologize, this feature is not currently available on mobile devices. <br />
        For the best experience, please open this page on a desktop browser.
      </p>
    </section>
  );
}
