import { Divider, makeStyles, Typography } from '@material-ui/core';
import { dateFormattingOptions } from 'constants/date/formattingOptions';
const useStyles = makeStyles((theme) => ({
  rootStyle: {
    backgroundColor: 'transparent',
    borderRadius: '10px',

    // paddingTop:"8px",
    // paddingLeft:"5px"
  },
  paperStyles: {
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '20px 30px ',
    border: ' 1px solid #E5EDF0',
    boxShadow:
      '0px 7px 8px 0px rgba(0, 0, 0, 0.06), 0px 5px 8px 0px rgba(0, 0, 0, 0.04), 0px 7px 6px 0px rgba(0, 0, 0, 0.02), 0px -3px 4px 0px rgba(0, 0, 0, 0.04), 0px -8px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: 8,
    color: '#000000',
  },
  labelText: {
    fontStyle: 'normal',
    fontWeight: 600,
    marginBottom: 5,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
}));

interface VerifiedComponentProps {
  name: string;
  verifiedOn: any;
  onMouseLeave: () => void;
}
export const VerifiedComponent = ({ name, verifiedOn, onMouseLeave }: VerifiedComponentProps) => {
  const classes = useStyles();
  const lastVerified = new Date(verifiedOn);
  return (
    <div className={classes.rootStyle} onMouseLeave={onMouseLeave}>
      <div className={classes.paperStyles}>
        <Typography className={classes.titleText}>Verified By:</Typography>
        <Typography className={classes.labelText}>{name}</Typography>
        <Divider style={{ marginBottom: 20 }} />
        <Typography className={classes.titleText}>Verified on:</Typography>
        <Typography className={classes.labelText}>
          {lastVerified.toLocaleString('en-US', dateFormattingOptions)}
        </Typography>
      </div>
    </div>
  );
};
