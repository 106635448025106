import * as React from 'react';

function CreateIcon(props) {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#08A88E' />
      <path d='M17.8 10H15.2V15.2H10V17.8H15.2V23H17.8V17.8H23V15.2H17.8V10Z' fill='white' />
    </svg>
  );
}

export default CreateIcon;
