import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { getNoteGenerator } from '../../../actions/resources/getNoteGenerator';
import { SmartNote, resourcesAPI } from '../../../api/resources';
import { MODULE_TYPES } from '../../../constants';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';
import SmartNoteForm from './smartNoteForm';

interface RequiredResourceFormProps {
  noteGeneratorId?: string;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  isFullHeight: boolean;
  createModal: boolean;
  closeUiElementPane: () => void;
  modal: boolean;

  savePosition: () => void;
}

const labels = {
  title: 'Smart Notes',
  input: 'note',
  button: 'Note',
  data: 'notes',
};

export const SmartNoteResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const { data, refetch } = useQuery(
    [module?.type, module?.id, 'smart-notes'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getSmartNotes
      : resourcesAPI.getCalculatorSmartNotes,
    {
      enabled: !!module,
      initialData: [],
    }
  );

  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const onOpen = (resourceId: string | null) => {
    setOpen(true);
    setResourceId(resourceId);
  };
  const onClose = () => {
    setOpen(false);
    refetch();
  };

  const resourceFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      noteGeneratorId: resourceId ?? undefined,
      toggleModal: onClose,
      type: module?.type as ResourceFormModuleTypes,
      isFullHeight: true,
      createModal: !!resourceId,
      closeUiElementPane: () => setModal(false),
      modal: modal,

      savePosition: () => {},
    }),
    [open, modal]
  );

  // TODO: remove when redux resource data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    if (!module) return;
    dispatch(getNoteGenerator(module.type, null, module.id));
  }, [data, module]);

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={data}
        renderCard={(resource) => ResourceCard(resource, onOpen)}
        onOpen={onOpen}
      />
      <SlidingPane
        isOpen={open}
        onRequestClose={() => {
          setModal(true);
        }}
        from='right'
        hideHeader
        className='no-padding sliding-panel-shadow'
        width='1210px'
      >
        <div className='mt-14'>
          <SmartNoteForm {...resourceFormProps} />
        </div>
      </SlidingPane>
    </>
  );
};

const ResourceCard = (
  resource: SmartNote,
  onOpen: (resourceId: string | null) => void
): ReactNode => {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(resourcesAPI.deleteSmartNotes, {
    onSuccess: () => queryClient.invalidateQueries([module?.type, module?.id, 'smart-notes']),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.id);
  const onDelete = () => {
    mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none rounded-xl bg-white px-[20px] py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-full space-y-2'>
          <H4>{resource.title}</H4>
          <div>
            <AvoEditorPreview
              contentTiptap={resource.text_json_tiptap}
              contentDraftJS={resource.new_text_content}
              isListItem
            />
          </div>
        </div>
        {hasEditPermission && (
          <FloatingActionButton
            open={open}
            onClose={() => setOpen(false)}
            onEdit={onEdit}
            onDelete={toggleDelete}
          />
        )}
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this reference?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
