import { Listbox } from '@headlessui/react';
import Button from 'components/utils/Button';
import { addMonths, differenceInDays, differenceInMonths, format } from 'date-fns';
import { useEffect, useState } from 'react';
import DropdownArrowIcon from '../../../assets/icons/dropdownArrowIcon';
import { Icons } from '../../utils/Icons';
import { DatePickerModal } from '../../utils/modals/DatePickerModal';
import { Body2, Caption2 } from '../../utils/typo';

export enum Intervals {
  MONTH1 = '1_month',
  MONTH3 = '3_month',
  MONTH6 = '6_month',
  MONTH12 = '12_month',
  CUSTOM = 'custom',
}

interface VerificationIntervalListBoxProps {
  onIntervalChange: (interval: Intervals | null) => void;
  onExpireAtChange: (expireAt: Date | null) => void;
  reviewInterval: Intervals | null;
  reviewExpireAt: Date | null;
}

export function VerificationIntervalListBox({
  onIntervalChange,
  onExpireAtChange,
  reviewInterval,
  reviewExpireAt,
}: VerificationIntervalListBoxProps) {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [bannerOpen, setBannerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!datePickerOpen && !!reviewExpireAt && reviewInterval !== Intervals.CUSTOM) {
      onExpireAtChange(null);
    }
  }, [reviewExpireAt, reviewInterval, datePickerOpen, onExpireAtChange]);

  const [verificationIntervalAlertMessage, setVerificationIntervalAlertMessage] =
    useState<string>('');
  useEffect(() => {
    if (!reviewInterval || (reviewInterval === Intervals.CUSTOM && !reviewExpireAt)) {
      setBannerOpen(false);
      return;
    }
    const intervalDifferenceInMonths = !!reviewExpireAt
      ? differenceInMonths(reviewExpireAt, new Date())
      : differenceInMonths(VerificationIntervalOptions[reviewInterval].date, new Date());
    const intervalDifferenceInDay = !!reviewExpireAt
      ? differenceInDays(reviewExpireAt, new Date())
      : differenceInDays(VerificationIntervalOptions[reviewInterval].date, new Date());
    if (intervalDifferenceInMonths > 3) {
      setVerificationIntervalAlertMessage(VerificationIntervalAlertMessages.moreThanThreeMonths);
    } else if (intervalDifferenceInMonths > 1) {
      setVerificationIntervalAlertMessage(VerificationIntervalAlertMessages.oneToThreeMonths);
    } else if (intervalDifferenceInDay > 7) {
      setVerificationIntervalAlertMessage(VerificationIntervalAlertMessages.oneWeekToOneMonth);
    } else {
      setVerificationIntervalAlertMessage(VerificationIntervalAlertMessages.oneDayToOneWeek);
    }
    setBannerOpen(true);
  }, [reviewExpireAt, reviewInterval, setVerificationIntervalAlertMessage, setBannerOpen]);

  const onDeselect = () => {
    onExpireAtChange(null);
    onIntervalChange(null);
  };

  return (
    <div className='w-[600px] select-none'>
      <Listbox onChange={onIntervalChange}>
        <Listbox.Button
          className='mt-1 h-[40px] w-full rounded border border-gray-300 px-4 py-3'
          placeholder='Select verification interval'
        >
          <div className='flex items-center justify-between'>
            {!!reviewInterval ? (
              <p>
                {!!reviewExpireAt && reviewInterval === Intervals.CUSTOM
                  ? `${VerificationIntervalOptions[reviewInterval].optionText} (${format(
                      reviewExpireAt,
                      'MM/dd/yyyy'
                    )})`
                  : VerificationIntervalOptions[reviewInterval].optionText}
              </p>
            ) : (
              <p className='text-gray-300'>Select verification interval</p>
            )}
            <DropdownArrowIcon />
          </div>
        </Listbox.Button>
        <Listbox.Options className='relative'>
          <div className='absolute z-10 w-[600px] rounded bg-white pb-1 shadow-04'>
            {Object.values(Intervals).map((interval) => (
              <Listbox.Option key={interval} value={interval}>
                <div
                  className='flex cursor-pointer items-center justify-between gap-3 px-4 py-3 hover:bg-gray-200'
                  onClick={() => interval === Intervals.CUSTOM && setDatePickerOpen(true)}
                >
                  <Body2>{VerificationIntervalOptions[interval].optionText}</Body2>
                  {interval === reviewInterval && <Icons.Check className='fill-transparent' />}
                </div>
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Listbox>
      {(!!reviewInterval || !!reviewExpireAt) && (
        <div className='flex'>
          <div className='flex'>
            <Button.Reverse type='button' onClick={onDeselect}>
              Deselect
            </Button.Reverse>
          </div>
        </div>
      )}
      <AlertBanner
        message={verificationIntervalAlertMessage}
        open={bannerOpen}
        onClose={() => setBannerOpen(false)}
      />
      <DatePickerModal
        open={datePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        selectedDate={reviewExpireAt}
        onSelect={onExpireAtChange}
      />
    </div>
  );
}

interface AlertBannerProps {
  message: string;
  open: boolean;
  onClose: () => void;
}

function AlertBanner({ message, open, onClose }: AlertBannerProps) {
  return (
    <>
      {open && (
        <div className='mt-[12px] flex justify-between gap-[8px] bg-primary-100 p-[8px]'>
          <Icons.BannerWarningGreen />
          <Caption2 className='grow text-gray-700'>{message}</Caption2>
          <Icons.Close className='cursor-pointer' onClick={onClose} />
        </div>
      )}
    </>
  );
}

interface VerificationIntervalOption {
  optionText: string;
  date: Date;
}

const VerificationIntervalOptions: Record<Intervals, VerificationIntervalOption> = {
  '1_month': {
    optionText: 'Every month',
    date: addMonths(new Date(), 1),
  },
  '3_month': {
    optionText: 'Every 3 months',
    date: addMonths(new Date(), 3),
  },
  '6_month': {
    optionText: 'Every 6 months',
    date: addMonths(new Date(), 6),
  },
  '12_month': {
    optionText: 'Every year',
    date: addMonths(new Date(), 12),
  },
  custom: {
    optionText: 'On a specific date',
    date: new Date(),
  },
};

const VerificationIntervalAlertMessages = {
  moreThanThreeMonths:
    'We will send reminder emails to the verifier 3 months, 1 month, 1 week, and 1 day before the expiration date.',
  oneToThreeMonths:
    'We will send reminder emails to the verifier 1 month, 1 week, and 1 day before the expiration date.',
  oneWeekToOneMonth:
    'We will send reminder emails to the verifier 1 week and 1 day before the expiration date.',
  oneDayToOneWeek: 'We will send reminder emails to the verifier 1 day before the expiration date.',
};
