import NavBar from 'components/bars/NavBar';
import Footer from 'components/layout/Footer';
import { H1 } from 'components/utils/typo';
import TeamSettingForm from '../teamSettingForm';

const CreateTeam = () => {
  return (
    <div className='h-full'>
      <NavBar activeState='TeamManagement' />
      <div className='mx-auto my-[80px] max-w-[600px] space-y-[20px] px-[10px]'>
        <H1> Create Team </H1>
        <TeamSettingForm />
      </div>
      <Footer />
    </div>
  );
};

export default CreateTeam;
