import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { atom, useAtom } from 'jotai';
import { HTMLAttributes, useState } from 'react';
import { ExpandButton } from './ExpandButton';
import { SideMenu } from './SideMenu';

export const SIDEBAR_TRANSITION_DURATION = 0.2;

const SIDEBAR_EXPANDED_ATOM = atom(true);

interface MainSidebarProps extends HTMLAttributes<HTMLDivElement> {}

export const MainSidebar = ({}: MainSidebarProps) => {
  const [expanded, setExpanded] = useAtom(SIDEBAR_EXPANDED_ATOM);
  const [show, setShow] = useState(false);
  return (
    <div
      className='relative h-full shadow'
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <AnimatePresence>
        {show && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ExpandButton expanded={expanded} onClick={() => setExpanded((prev) => !prev)} />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={clsx(
          'h-full overflow-y-auto transition-all',
          expanded ? 'min-w-[180px]' : 'min-w-[0px]'
        )}
      >
        <SideMenu expanded={expanded} />
      </div>
    </div>
  );
};
