export enum MENTION_TYPES {
  APIBOX_VARIABLE = 'apibox_variable',
  CHOICE_VARIABLE = 'choice_variable',
  CUSTOM_NUMERIC = 'custom_numeric',
  MULTI_VARIABLE = 'multi_variable',
  GPTBOX_VARIABLE = 'gptbox_variable',
  TEXT_VARIABLE = 'text_input_variable',
  MESSAGE_VARIABLE = 'message_variable',
  CONDITIONAL_TEXT = 'conditional_text',
  KNOWLEDGE_BASE = 'knowledge_base',
  REFERENCE = 'reference',
  NUMERIC = 'numeric',
  INFOBOX = 'infobox',
  FORMULA = 'formula',
  MEDIA = 'media',
}

export const VARIABLE_TYPES = [
  MENTION_TYPES.APIBOX_VARIABLE,
  MENTION_TYPES.CHOICE_VARIABLE,
  MENTION_TYPES.CUSTOM_NUMERIC,
  MENTION_TYPES.MULTI_VARIABLE,
  MENTION_TYPES.GPTBOX_VARIABLE,
  MENTION_TYPES.TEXT_VARIABLE,
  MENTION_TYPES.MESSAGE_VARIABLE,
  MENTION_TYPES.CONDITIONAL_TEXT,
  MENTION_TYPES.NUMERIC,
];
