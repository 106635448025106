import axios from 'axios';
import { TEAM_API_URL } from '../../constants';
import { GET_TEAM_MIRRORS } from '../actionsTypes';
import { TEAM_QUERY_KEY } from '../../hooks/useTeam';
import { queryClient } from '../../queryClient';

const getTeamMirrors = () => async (dispatch, getState) => {
  const team = queryClient.getQueryData([TEAM_QUERY_KEY]);
  const res = await axios.get(TEAM_API_URL + team?.id + '/mirrors');

  dispatch({
    type: GET_TEAM_MIRRORS,
    payload: res?.data || [],
  });
};

export default getTeamMirrors;
