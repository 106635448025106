import { TrashIcon } from '@heroicons/react/24/outline';
import { ISource } from './AddSourceModal';

interface SourceItemProps {
  source: ISource;
  onDelete: (id: number) => void;
}

const SourceItem = ({ source, onDelete }: SourceItemProps) => {
  return (
    <div className='flex items-center px-[12px] py-[10px] border-b border-gray-300'>
      <div className='w-5/12'>{source.name}</div>
      <div className='w-5/12 ml-1'>{source.website_link || source.pdf_file.split('/').pop()}</div>
      <div className='w-2/12'>
        <TrashIcon
          className='h-[14px] w-[14px] ml-auto cursor-pointer'
          onClick={() => onDelete(source.id)}
        />
      </div>
    </div>
  );
};

export default SourceItem;
