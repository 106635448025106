import * as React from 'react';

function AVOMDIcon() {
  return (
    <svg
      width='119'
      height='27'
      viewBox='0 0 119 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1388_12258)'>
        <path
          d='M57.0624 0.777344C49.8112 0.777344 43.9353 6.59634 43.9353 13.7773C43.9353 20.9583 49.8112 26.7773 57.0624 26.7773C64.3136 26.7773 70.1895 20.9583 70.1895 13.7773C70.1895 6.59634 64.3136 0.777344 57.0624 0.777344ZM57.0624 21.748C52.6153 21.748 49.0138 18.1813 49.0138 13.7773C49.0138 9.37335 52.6153 5.8067 57.0624 5.8067C61.5094 5.8067 65.111 9.37335 65.111 13.7773C65.111 18.1813 61.5094 21.748 57.0624 21.748Z'
          fill='white'
        />
        <path
          d='M21.973 25.2615L13.8763 2.23459C13.7425 1.86361 13.3893 1.59863 12.9719 1.59863H9.03858C8.62117 1.59863 8.26263 1.86361 8.13419 2.23459L0.0321086 25.2615C0.0107029 25.3145 0 25.3781 0 25.4364C0 25.7226 0.235463 25.9557 0.524441 25.9557H4.71462C5.14809 25.9557 5.51198 25.6749 5.63507 25.2827L10.7725 8.38746C10.8046 8.29207 10.8955 8.22317 11.0026 8.22317C11.1096 8.22317 11.2006 8.29207 11.2327 8.38746L16.3701 25.2827C16.4931 25.6696 16.857 25.9557 17.2905 25.9557H21.4807C21.7697 25.9557 22.0051 25.7226 22.0051 25.4364C22.0051 25.3728 21.9944 25.3145 21.973 25.2615Z'
          fill='white'
        />
        <path
          d='M20.9884 2.29818L29.0851 25.3251C29.2189 25.6961 29.5721 25.961 29.9895 25.961H33.9228C34.3402 25.961 34.6988 25.6961 34.8272 25.3251L42.9293 2.29818C42.9507 2.24519 42.9614 2.18159 42.9614 2.1233C42.9614 1.83712 42.7259 1.60393 42.437 1.60393H38.2468C37.8133 1.60393 37.4494 1.88481 37.3263 2.27699L32.189 19.1722C32.1568 19.2676 32.0659 19.3365 31.9588 19.3365C31.8518 19.3365 31.7608 19.2676 31.7287 19.1722L26.586 2.27169C26.4629 1.88481 26.099 1.59863 25.6655 1.59863H21.4754C21.1864 1.59863 20.9509 1.83182 20.9509 2.118C20.9509 2.18159 20.9616 2.23989 20.983 2.29288L20.9884 2.29818Z'
          fill='white'
        />
        <path
          d='M117.657 6.87184C116.763 5.17596 115.516 3.87755 113.916 2.96601C112.316 2.05977 110.507 1.604 108.495 1.604H102.239C101.897 1.604 101.619 1.87958 101.619 2.21876V25.3464C101.619 25.6855 101.897 25.9611 102.239 25.9611H108.345C110.438 25.9611 112.295 25.5053 113.911 24.5885C115.527 23.677 116.779 22.3733 117.668 20.6933C118.556 19.0133 119 17.0789 119 14.9061V12.6643C119 10.5021 118.55 8.57302 117.657 6.87714V6.87184ZM116.94 14.763C116.94 17.6619 116.158 19.9672 114.59 21.6684C113.022 23.3696 110.946 24.2228 108.356 24.2228H103.695V3.33698H108.527C111.048 3.34758 113.081 4.19552 114.623 5.88081C116.164 7.56609 116.934 9.80784 116.934 12.6113V14.7524L116.94 14.763Z'
          fill='white'
        />
        <path
          d='M97.6478 2.21339V25.341C97.6478 25.6802 97.3695 25.9557 97.027 25.9557H96.1869C95.8444 25.9557 95.5661 25.6802 95.5661 25.341V5.52036C95.5661 5.45147 95.5072 5.39317 95.4377 5.39317C95.3842 5.39317 95.3413 5.42497 95.3199 5.46736L86.9182 25.235C86.9182 25.235 86.8968 25.2827 86.8861 25.3039L86.8004 25.5106C86.6827 25.7809 86.4151 25.9451 86.1315 25.9504H85.6552C85.3716 25.9504 85.104 25.7862 84.9917 25.5106L84.906 25.3039C84.906 25.3039 84.8846 25.2562 84.8739 25.235L76.4722 5.46736C76.4508 5.42497 76.4079 5.39317 76.3544 5.39317C76.2849 5.39317 76.226 5.45147 76.226 5.52036V25.341C76.226 25.6802 75.9477 25.9557 75.6052 25.9557H74.7651C74.4226 25.9557 74.1443 25.6802 74.1443 25.341V2.21339C74.1443 1.87421 74.4226 1.59863 74.7651 1.59863H76.6755C76.927 1.59863 77.1411 1.75232 77.2321 1.96961L85.698 21.8909C85.7302 21.9651 85.8051 22.0181 85.8907 22.0181C85.9763 22.0181 86.0512 21.9651 86.0834 21.8909L94.5493 1.96961C94.6403 1.75232 94.8544 1.59863 95.1059 1.59863H97.0163C97.3588 1.59863 97.6371 1.87421 97.6371 2.21339H97.6478Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1388_12258'>
          <rect width='119' height='26' fill='white' transform='translate(0 0.777344)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AVOMDIcon;
