import { makeStyles, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Tooltip from '@material-ui/core/Tooltip';
import { Input } from 'reactstrap';
import styled from 'styled-components';

export const exmapleNToolsStyles = makeStyles({
  icon: {
    marginRight: '10px',
    color: '#A6A6A6',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#969696',
  },
  remainingCharacters: {
    float: 'right',
    color: '#929292',
    fontSize: '14px',
    lineHeight: '16px',
    marginRight: '10px',
  },
  editBox: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '3px',
    cursor: 'pointer',
  },
  editButton: {
    fontStyle: 'normal',
    width: '32px',
    height: '18px',
    fontWeight: 600,
    fontSize: '18px',
  },
  createButton: {
    fontStyle: 'normal',
    width: '150px',
    fontWeight: 600,
    fontSize: '18px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#08A88E',
    marginTop: 3,
  },
  accordianTitle: {
    marginLeft: '5px',
    color: '#191919',
    width: 360,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  reloadButton: {
    height: 46,
    width: 115,
    padding: '10px 40px',
    marginLeft: '0.4em',
    background: '#08A88E',
    borderRadius: 5,
    boxSizing: 'border-box',
    color: 'white',
  },
});

export const StyledInput = styled(Input)`
  background: #ffffff !important ;
  color: #929292;
  border: 1px solid #cccccc !important;
  opacity: ${(props) => (props.nextButton === false ? '0.2 !important' : '1 !important')};
  pointer-events: ${(props) => (props.nextButton === false ? 'none' : 'all')};
  max-width: 462px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: 600;
  font-size: 14;
`;

export const Accordion = withStyles({
  root: {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '2px solid #DADADA',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    marginBottom: -1,
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    borderBottom: '1px solid #E4E4E4',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid #D3D3D3',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: 16,
    backgroundColor: '#F4F6F9',
  },
})(MuiAccordionDetails);

export const CustomTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: '#464C5C',
  },
})(Tooltip);
