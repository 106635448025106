import { useMutation, useQueryClient } from '@tanstack/react-query';
import { teamChannelAPI } from 'api/teamchannel';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import ConfirmationModal from 'components/utils/ConfirmationModal';
import { Icons } from 'components/utils/Icons';
import { useState } from 'react';

interface ChannelDeleteProps {
  channelId: number;
}

export const ChannelDelete = ({ channelId }: ChannelDeleteProps) => {
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(() => teamChannelAPI.deleteTeamChannel(channelId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['team', 'channels']);
    },
  });

  return isLoading ? (
    <LoadingSpinner size='small' />
  ) : (
    <div title='Delete' className='cursor-pointer' onClick={() => setShow(true)}>
      <Icons.TrashBin />
      <ConfirmationModal
        open={show}
        title='Delete ?'
        text='Are you sure you want to delete this Channel?'
        onClose={() => setShow(false)}
        action={mutate}
      />
    </div>
  );
};
