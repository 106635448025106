import { AppBar, Avatar, Box, Button, Menu, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { StyledButton } from 'components/utils/styled-components/FormStyle';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavButton = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(845)]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },

    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: 'none',

    marginLeft: 10,
    marginRight: 10,
    borderRadius: 0,
  },
}))(StyledButton);

export const ActionButton = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '13px',
      maxWidth: '170px',
      height: '36px',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '13px',
      maxWidth: '160px',
      height: '30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '9px',
      maxWidth: '90px',
      height: '25px',
      marginTop: 5,
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: '12px',
      maxWidth: '140px',
      height: '25px',
      marginTop: 5,
    },
    textTransform: 'none',
    backgroundColor: '#08A88E',
    fontStyle: 'normal',
    fontWeight: 'bold',

    color: 'white',
    borderRadius: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    WebkitBoxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    MozBoxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
  disabled: {
    backgroundColor: '#E5EDF0 !important',
    color: '#ffffff !important',
    boxShadow: 'none !important',
  },
}))(Button);

export const ModuleEditorNavLink = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '125px',
      maxHeight: '20px',
      fontSize: '13px',
    },

    [theme.breakpoints.up('lg')]: {
      minWidth: '155px',
      maxHeight: '30px',
      fontSize: 20,
    },
    [theme.breakpoints.down(1560)]: {
      minWidth: '135px',
      maxHeight: '26px',
      fontSize: 16,
    },
    textAlign: 'right',

    textTransform: 'none',

    borderRadius: 0,
    fontStyle: 'normal',
    fontWeight: 600,

    color: '#616262',
  },
  label: {
    justifyContent: 'flex-end',
    fontWeight: 600,
  },
  startIcon: {
    marginRight: '4px',
  },
}))(Button);

export const NumericsNavLink = withStyles((theme) => ({
  label: {
    justifyContent: 'flex-end',
    height: '33px',
    lineHeight: '18px',
    fontSize: '20px',
    color: '#616262',
    textTransform: 'capitalize',
    fontWeight: 600,
    paddingRight: '20px',
  },
  '&:hover': {
    backgroundColor: 'unset',
  },
}))(Button);

export const ModuleTitleBoxEditor = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down(1520)]: {
      marginBottom: 15,
    },
  },
}))(Box);

export const ModuleEditorTitle = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    marginRight: 0,
    maxWidth: 237,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    lineHeight: '27px',
    color: '#000000',
  },
}))(Typography);

export const Logo = withStyles((theme) => ({
  root: {
    color: 'white',
    marginRight: 20,
    paddingLeft: '10px',
    paddingRight: '10px',

    [theme.breakpoints.up('xl')]: {
      fontSize: '31px',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '31px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '27px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    textTransform: 'none',
    background: '#08A88E',
    marginLeft: '0 !important',
    width: '11.14vw',
    [theme.breakpoints.down('lg')]: {
      height: '65px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '85px',
    },
    borderRadius: 0,
    fontStyle: 'normal',

    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
}))(Button);

export const NavLink = styled(Link)({
  textTransform: 'none',
});

export const ModuleEditorButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',

  alignItems: 'center',
  flexWrap: 'wrap',
});

export const ModuleEditorWrapperAvatar = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 'auto',
  },
}))(Box);

export const ModuleEditorWrapper = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))(Box);

export const StyledMenu = withStyles((theme) => ({
  paper: {
    minWidth: '250px',
    background: '#FFFFFF',
    border: '1px solid #E9E9E9',
    boxSizing: 'border-box',
    boxShadow: '0px 16px 32px rgba(215, 215, 215, 0.29)',
    borderRadius: '23px',
    '&:active': {
      color: '#08A88E',
    },
  },
}))(Menu);

export const StyledBar = withStyles((theme) => ({
  regular: {
    minHeight: '10px',
    [theme.breakpoints.down('lg')]: {
      height: '65px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '85px',
    },
    padding: 0,
    boxShadow: 'none',
  },
}))(Toolbar);

export const StyledBox = styled(Box)`
  ${({ theme }) => `
  display:flex;
 
  box-shadow: none  ;
  padding: 0;
`}
`;

export const UserName = withStyles((theme) => ({
  root: {
    color: 'black',
    margin: 5,
    marginRight: 5,
    fontSize: '18px',
    [theme.breakpoints.down(1525)]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '15px',
    },

    fontWeight: 'bold',
    textTransform: 'none',

    textAlign: 'center',
  },
}))(Typography);

export const UserButton = withStyles((theme) => ({
  root: {
    width: 'auto',
    minWidth: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '45px',
      fontSize: '18px',
    },
    [theme.breakpoints.down(1100)]: {
      height: '45px',
      fontSize: '18px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '48px',
      fontSize: '18px',
    },
    marginLeft: '15px',
    marginRight: '24px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
    WebkitBoxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '100px',
    color: '#616262',

    textTransform: 'none',
    background: '#FFFFFF',
  },
}))(Button);

export const StyledAppBar = withStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
  },
}))(AppBar);

export const ProfileAvatar = withStyles((theme) => ({
  root: {
    float: 'left',

    [theme.breakpoints.down('lg')]: {
      height: '35px',
      width: '35px',
    },
    [theme.breakpoints.down('md')]: {
      height: '25px',
      width: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '40px',
      width: '40px',
    },
    marginRight: '6px',
  },
}))(Avatar);
