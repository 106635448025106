import Main from 'pages';
import { Profile } from 'pages/profile';
import { HOME, PROFILE } from 'routes/urls';

export const homeRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: HOME,
      component: Main,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: PROFILE,
      component: Profile,
    },
  },
];
