import LoadingSpinner from 'components/loader/LoadingSpinner';
import Backdrop from 'components/utils/Backdrop';
import React, { useState } from 'react';
import { MainSearchIcon } from '../../assets/icons/MainSearchIcon';
import EmptyStateIcon from '../../assets/icons/emptyStateIcon';
import { useModules } from '../../hooks/useModules';
import { SortableList } from '../utils/SortableList';
import { getUniqueModuleKey } from '../utils/module';
import { H2, H4 } from '../utils/typo';
import { ModuleCreate } from './ModuleCreate';
import { ModuleRow } from './ModuleRow';
import { twMerge } from 'tailwind-merge';
import { Skeleton } from '../utils/Skeleton';

export const ModuleList = () => {
  const [keyword, setKeyword] = useState('');
  const { teamModules, isLoading } = useModules();

  return (
    <>
      <div className='flex items-center pb-[32px]'>
        <H2>Your List</H2>
        <div className='ml-auto'>
          <div className='box-border flex gap-[8px]'>
            <div className='flex h-[40px] w-[336px] items-center gap-[4px] rounded border border-gray-300 px-[12px] py-[10px]'>
              <MainSearchIcon />
              <input
                className='m-0 !h-[20px] w-[288px] !border-none text-[14px] leading-[20px] text-gray-900 placeholder-gray-300 shadow-none focus:border-none focus:outline-none'
                type='text'
                placeholder='Search modules'
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <ModuleCreate moduleCount={teamModules.length} />
          </div>
        </div>
      </div>
      <div>
        <SortableList
          list={teamModules}
          keyword={keyword}
          emptyComponent={isLoading ? <SkeletonList /> : <EmptyList keyword={keyword} />}
          columns={{
            title: 'Title',
            ownerName: 'Owner',
            type: 'Type',
            status: 'Status',
            last_updated: 'Last Updated',
          }}
          renderItem={(item) => <ModuleRow key={getUniqueModuleKey(item)} module={item} />}
        />
      </div>
    </>
  );
};

const EmptyList = ({ keyword }) =>
  keyword ? (
    <div className='flex h-[60vh] w-full flex-col items-center justify-center'>
      <p className='text-center text-base font-semibold leading-6 text-gray-700'>
        No results found for '{`${keyword}`}'
      </p>
    </div>
  ) : (
    <div className='flex h-[60vh] w-full flex-col items-center justify-center'>
      <EmptyStateIcon />
      <p className='mb-3 mt-12 text-center text-2xl font-bold leading-8 text-gray-900'>
        You don’t have any modules yet!
      </p>
      <p className='text-base font-normal leading-5 text-gray-700'>
        To make a module, click Create in the top right corner
      </p>
    </div>
  );

const SkeletonList = () => (
  <table className='w-full table-auto'>
    <thead>
      <tr className='h-[81px] !border-b-2 !border-gray-300'>
        <th className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0' align='left'>
          <label className='flex cursor-pointer items-center'>
            <H4>Title</H4>
          </label>
        </th>
        <th className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0' align='left'>
          <label className='flex cursor-pointer items-center'>
            <H4>Owner</H4>
          </label>
        </th>
        <th className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0' align='left'>
          <label className='flex cursor-pointer items-center'>
            <H4>Type</H4>
          </label>
        </th>
        <th className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0' align='left'>
          <label className='flex cursor-pointer items-center'>
            <H4>Status</H4>
          </label>
        </th>
        <th className='select-none px-[12px] py-[16px] first:pl-0 last:pr-0' align='left'>
          <label className='flex cursor-pointer items-center'>
            <H4>LastUpdated</H4>
          </label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className='w-1 whitespace-nowrap py-[16px] pl-0 pr-[12px]' align='left'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
        <td className='w-1 whitespace-nowrap px-[12px] py-[16px]' align='left'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
        <td className='w-1 whitespace-nowrap px-[12px] py-[16px]' align='left'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
        <td className='w-1 whitespace-nowrap px-[12px] py-[16px]' align='left'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
        <td className='w-1 whitespace-nowrap py-[16px] pl-[12px] pr-0' align='left'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
      </tr>
    </tbody>
  </table>
);
