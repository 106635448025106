import { Box, Typography } from '@material-ui/core';
import { Check, Close, Delete, Edit } from '@material-ui/icons';
import { CustomToast } from 'components/utils/toast-message';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DEFAULT_CONDITIONS_COUNT_ATOM } from './sortableConditionalTexts';

import '../conditional_text.css';
import { conditionalTextStyles } from './styles';

interface InlineEditProps {
  label: string;
  width: number;
  isEditable?: boolean;
  showActionButtons?: boolean;
  saveChanges: (value: string) => void;
  deleteField: () => void;
}

const InlineEdit = ({
  width,
  label,
  isEditable = true,
  showActionButtons = false,
  saveChanges,
  deleteField,
}: InlineEditProps) => {
  const ref = useRef<any>(null);
  const classes = conditionalTextStyles();
  const [value, setValue] = useState(label);
  const [inlineEditMode, setInlineEditMode] = useState(false);
  const [isClickOutside, setIsClickOutside] = useState(false);
  const [selectedText, setSelectedText] = useState(false);
  const defaultConditionsCount = useAtomValue(DEFAULT_CONDITIONS_COUNT_ATOM);
  // Function to handle outside clicks
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      !selectedText && setIsClickOutside(true);
    }
  }
  useEffect(() => {
    // Add the click event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedText]);

  useEffect(() => {
    if (isClickOutside) {
      save(null);
      setIsClickOutside(false);
    }
  }, [isClickOutside]);

  const save = (e) => {
    e?.stopPropagation();
    if (!value) {
      toast.error(CustomToast, { data: 'Label cannot be empty' });
      return;
    }

    if (!inlineEditMode) return;
    setInlineEditMode(false);
    saveChanges(value);
  };

  const discardChanges = (e) => {
    e.stopPropagation();
    setValue(label);
    setInlineEditMode(false);
  };
  // The `showActionButtons` prop has a value opposite to that of `item.is_default`.
  const hasMultipleDefaultConditions = defaultConditionsCount > 1 && !showActionButtons;
  return (
    <div ref={ref} className={classes.summaryContainer}>
      {inlineEditMode ? (
        <Box style={{ width }} className={classes.inlineEditorWrapper}>
          <input
            type='text'
            value={value}
            onClick={(e) => e.stopPropagation()}
            className={classes.inlineEditorInput}
            onChange={(e) => setValue(e.target.value)}
            onSelect={(e) => {
              const input = e.target as HTMLInputElement;
              const selectionStart = input.selectionStart;
              const selectionEnd = input.selectionEnd;
              setSelectedText(selectionStart !== selectionEnd);
            }}
          />
          <div className={classes.buttonBox} style={{ marginLeft: 'auto' }}>
            <Check fontSize='small' onClick={save} />
          </div>
          <div className={classes.buttonBox} style={{ marginRight: 5, marginLeft: 5 }}>
            <Close fontSize='small' onClick={discardChanges} />
          </div>
        </Box>
      ) : (
        <Typography
          className={classes.conditionTitle}
          style={{ position: 'relative', zIndex: 99 }}
          onMouseDown={(e) => {
            e.stopPropagation();
            isEditable && setInlineEditMode(true);
          }}
        >
          {label}
        </Typography>
      )}
      {(showActionButtons || hasMultipleDefaultConditions) && (
        <Box className={`${classes.actionContainer} ct-action-btns`}>
          {!hasMultipleDefaultConditions && (
            <Edit
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                setInlineEditMode(true);
              }}
            />
          )}
          <Delete className={classes.deleteIcon} onClick={deleteField} />
        </Box>
      )}
    </div>
  );
};

export default InlineEdit;
