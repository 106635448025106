import { GET_CALCULATORS } from 'actions/actionsTypes';

const calculatorsReducer = (state = { calculators: [] }, action) => {
  switch (action.type) {
    case GET_CALCULATORS:
      return {
        ...state,
        calculators: action.payload,
      };

    default:
      return state;
  }
};

export default calculatorsReducer;
