import { Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ActionBox,
  AuthorDescription,
  AuthorName,
  StyledCard,
  StyledCardActionArea,
  StyledCardContent,
  StyledCardMedia,
  StyledLink,
} from './styles';

import ShowMore from 'react-show-more';

const AuthorCard = ({ author, toggleAuthorModal, onEdit }) => {
  return (
    <StyledCard key={author.id}>
      <StyledCardActionArea disableRipple>
        <Box>
          <StyledCardMedia
            image={author?.image || 'https://i.stack.imgur.com/l60Hf.png'}
            title={author?.title}
          />
        </Box>
        <Box>
          <StyledCardContent>
            <AuthorName> {author.name} </AuthorName>

            <ShowMore
              lines={1}
              more={
                <span style={{ color: 'grey', width: '100%' }}>
                  <ExpandMoreIcon />
                </span>
              }
              less={
                <span style={{ color: 'grey', width: '100%' }}>
                  <ExpandLessIcon />
                </span>
              }
            >
              <AuthorDescription> {author.description} </AuthorDescription>
            </ShowMore>

            <ActionBox>
              <StyledLink to={{}} onClick={() => onEdit(author.id)}>
                Edit
              </StyledLink>

              <StyledLink
                to={{}}
                style={{ marginLeft: '40px' }}
                onClick={() => toggleAuthorModal(author.id)}
              >
                Delete
              </StyledLink>
            </ActionBox>
          </StyledCardContent>
        </Box>
      </StyledCardActionArea>
    </StyledCard>
  );
};

export default AuthorCard;
