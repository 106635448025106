import { HEALTH_CHECK_API_URL } from '../constants';

export const healthcheckAPI = {
  checkHealth: async () => {
    const response = await fetch(HEALTH_CHECK_API_URL);
    if (!response.ok) {
      throw new Error('Server is not healthy');
    }
    const data = await response.text();
    return data;
  },
};
