function CloseIcon() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='9.08612'
        y='2.07129'
        width='1.00494'
        height='10'
        transform='rotate(45 9.08612 2.07129)'
        fill='#6C7C83'
      />
      <rect
        x='10.0198'
        y='9.10596'
        width='1.00494'
        height='10'
        transform='rotate(135 10.0198 9.10596)'
        fill='#6C7C83'
      />
    </svg>
  );
}

export default CloseIcon;
