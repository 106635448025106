import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import TextFormatter from 'components/utils/text-higlighter/textFormatter';

const styles = makeStyles({
  card: {
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 20,
  },

  title: {
    fontSize: 21,
    color: 'black',
    fontWeight: 700,
  },
});

const TextCardPreview = (props) => {
  const classes = styles();
  const { title, summary } = props;

  return (
    <Card className={classes.card} style={{ backgroundColor: '#fafafa' }}>
      <CardContent>
        {summary && (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} gutterBottom variant='h5' component='h2'>
                Summary
              </Typography>

              <TextFormatter text={summary} />
            </CardContent>
          </Card>
        )}
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} gutterBottom variant='h5' component='h2'>
              {title ? title : 'Text Card Title'}
            </Typography>

            <AvoEditorPreview
              contentTiptap={props.card_text_json_tiptap}
              contentDraftJS={props.card_text_json}
            />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default TextCardPreview;
