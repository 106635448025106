import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

export const StyledBadge = withStyles(() => ({
  root: {
    marginLeft: (props: any) => (props.increaseMargin ? 5 : 1),
    marginBottom: 17,
  },
  badge: {
    height: 4,
    minWidth: 4,
    backgroundColor: '#FF8E3D',
  },
}))(Badge);
