import { Disclosure } from '@headlessui/react';
import { Icons } from 'components/utils/Icons';
import { useToolOptions } from 'components/utils/example-n-tools/useToolOptions';
import { Fragment, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { twMerge } from 'tailwind-merge';
import { ComboBox } from '../ComboBox';
import { ToolType } from './ToolButtons';

interface ExampleNToolsProps {
  list: ToolType[];
  onChange: (list: ToolType[]) => void;
}

export const ExampleNTools = ({ list, onChange }: ExampleNToolsProps) => {
  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const deepCopiedlist = [...list];
    const [targetItem] = deepCopiedlist.splice(source.index, 1);
    deepCopiedlist.splice(destination.index, 0, targetItem);
    onChange(deepCopiedlist);
  };

  return (
    <div className='mb-3 w-full rounded border border-gray-200 bg-gray-bg-strong'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='ExampleNToolDroppable' key='ExampleNToolDroppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='select-none'>
              {list.map((item, index) => (
                <Draggable
                  draggableId={`example-n-tool-${item.id}`}
                  key={`example-n-tool-${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ExampleNToolsDisclosure
                        list={list}
                        index={index}
                        onChange={onChange}
                        key={`${item?.id}--${index}`}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

interface ExampleNToolsDisclosureProps {
  index: number;
  list: ToolType[];
  onChange: (list: ToolType[]) => void;
}

const ExampleNToolsDisclosure = ({ index, list, onChange }: ExampleNToolsDisclosureProps) => {
  const currentItem = list[index];
  const {
    calcOptions,
    infoboxOptions,
    smartNoteOptions,
    legacyCalcOptions,
    teamCalcOptions,
    universalCalcOptions,
  } = useToolOptions();

  const handleDelete = () => onChange(list.filter((_, key) => key !== index));

  const handleChange = (value: number | string, type: string) => {
    switch (type) {
      case 'label':
        list = list.map((item: any, itemIndex: number) =>
          itemIndex === index ? { ...item, label: value } : item
        );
        break;
      case 'text':
        list = list.map((item: any, itemIndex: number) =>
          itemIndex === index
            ? {
                ...item,
                text: {
                  ...item.text,
                  statement: value,
                },
              }
            : item
        );
        break;
      case 'infobox':
        if (currentItem?.infobox?.infobox_type === 'NG') {
          list = list.map((item: any, itemIndex: number) =>
            itemIndex === index
              ? { ...item, infobox: smartNoteOptions.find((smartNote) => smartNote.id === value) }
              : item
          );
        } else {
          list = list.map((item: any, itemIndex: number) =>
            itemIndex === index
              ? { ...item, infobox: infoboxOptions.find((infobox) => infobox.id === value) }
              : item
          );
        }
        break;
      case 'calculator':
        list = list.map((item: any, itemIndex: number) =>
          itemIndex === index
            ? { ...item, calculator: calcOptions.find((calc) => calc.id === value) }
            : item
        );
        break;
      case 'legacy_calculator':
        list = list.map((item: any, itemIndex: number) =>
          itemIndex === index
            ? { ...item, legacy_calculator: legacyCalcOptions.find((calc) => calc.id === value) }
            : item
        );
        break;
      default:
        break;
    }
    onChange(list);
  };

  const Icon = useMemo(() => {
    switch (currentItem.type) {
      case 'infobox':
        return Icons.ToolInfobox;
      case 'calculator':
      case 'legacy_calculator':
        return Icons.ToolCalc;
      default:
        return Icons.ToolInfobox;
    }
  }, [currentItem]);

  const selectedValue = useMemo(() => {
    switch (currentItem.type) {
      case 'infobox':
      case 'smart_note':
        return currentItem?.infobox?.id;
      case 'calculator':
        return currentItem?.calculator.id;
      case 'legacy_calculator':
        return currentItem?.legacy_calculator.id;
      default:
        return;
    }
  }, [currentItem]);

  const dropdownOptions = useMemo(() => {
    switch (currentItem.type) {
      case 'infobox':
        return currentItem?.infobox?.infobox_type === 'NG' ? smartNoteOptions : infoboxOptions;
      case 'calculator':
        return [...teamCalcOptions, ...universalCalcOptions];
      case 'legacy_calculator':
        return legacyCalcOptions;
      default:
        return [];
    }
  }, [currentItem, infoboxOptions, smartNoteOptions]);

  return (
    <>
      {currentItem.type === 'text' ? (
        <TextItem item={currentItem} onChange={handleChange} handleDelete={handleDelete} />
      ) : (
        <Disclosure as='div' className='m-[4px] rounded border !border-gray-border-strong bg-white'>
          {({ open }) => (
            <>
              <Disclosure.Button as={Fragment}>
                <div className='flex w-full cursor-pointer items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'>
                  <Icons.ChevronDown className={twMerge(open && 'rotate-180')} />
                  <Icon />
                  <input
                    value={currentItem.label || currentItem?.text?.statement}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => handleChange(e.target.value, 'label')}
                    className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
                  />
                  <Icons.TrashBin onClick={handleDelete} />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className='space-y-[20px]'>
                <div className='ml-[28px] space-y-[12px] px-[16px]'>
                  <div className='mb-3 flex space-x-[8px]'>
                    <ComboBox
                      options={dropdownOptions}
                      selectedValue={selectedValue}
                      onChange={(value) => handleChange(value, currentItem.type)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

const TextItem = ({ item, onChange, handleDelete }) => (
  <div className='flex w-full cursor-pointer items-center space-x-[8px] bg-white px-[16px] py-[14px] focus:bg-transparent'>
    <Icons.ToolInfobox />
    <input
      value={item?.text?.statement}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => onChange(e.target.value, item?.type)}
      className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
    />
    <Icons.TrashBin onClick={handleDelete} />
  </div>
);
