import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { CustomToast } from 'components/utils/toast-message';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';

import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { ANSWER_CARD_API_URL } from '../../constants';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#08A88E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#08A88E',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#08A88E',
      },
    },
  },
})(TextField);
interface InlineProps {
  answerPage: any;
  textEditorsuggestions: any;
  resetAnsCards: Function;
  hideInline: any;
  isVisible: any;
  moduleId: any;
  triggerState?: any;
}
interface InlineState {
  card_text: any;
  card_text_content: any;
  card_title: any;
  card_summary: any;
  card_type: any;
  textEditorsuggestions: any[];
  trigger: any;
  answer_page: any;

  titleVisibility: boolean;
  summaryVisibility: boolean;
}

class Inline extends React.Component<InlineProps, InlineState> {
  constructor(props: InlineProps) {
    super(props);

    this.state = {
      card_text: '',
      card_text_content: '',
      card_title: '',
      card_summary: '',
      card_type: 'T',
      textEditorsuggestions: [],
      trigger: '',
      answer_page: this.props.answerPage,

      titleVisibility: false,
      summaryVisibility: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.textEditorsuggestions !== prevProps.textEditorsuggestions) {
      this.setState({
        textEditorsuggestions: this.props.textEditorsuggestions,
      });
    }
  }

  // when editable is clicked
  onChange = (e) => this.setState({ [e.target.id]: e.target.value } as Pick<InlineState, any>);

  onTextChange = (e) => {
    let text = '';
    // eslint-disable-next-line
    e.blocks.map((block) => {
      if (block.text) text += block.text + '\n';
    });

    this.setState({
      card_text: text,
      card_text_content: e,
    });
  };

  textCardSubmit = (e) => {
    e.preventDefault();

    if (!this.state.card_text) {
      toast.error(CustomToast, { data: 'Text cannot be empty' });
      return;
    }

    // fetch always on trigger of module
    let alwayOnTrigger = this.props.triggerState.triggers.find(
      (data) => data.title === 'Always On'
    );

    this.setState({ trigger: alwayOnTrigger ? alwayOnTrigger.id : '' }, () => {
      axios.post(ANSWER_CARD_API_URL, this.state).then(() => {
        // reset answer cards
        this.props.resetAnsCards();
        this.resetState();
      });
    });

    this.props.hideInline();
  };

  hideSummary = () => {
    this.setState({
      summaryVisibility: true,
    });
  };

  hideTitle = () => {
    this.setState({
      titleVisibility: true,
    });
  };

  hideInline = () => {
    this.resetState();
    this.props.hideInline();
  };

  resetState = () => {
    this.setState({
      card_text: null,
      card_text_content: null,
      card_title: null,
      card_summary: '',
      trigger: '',
      answer_page: this.props.answerPage,

      titleVisibility: false,
      summaryVisibility: false,
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    let cardStyle = {
      padding: '15px',
      borderRadius: '10px',
      backgroundColor: 'white',
      width: '438px',
      marginBottom: '5px',
    };

    let buttonStyle = {
      color: '#08A88E',
      marginLeft: '10px',
    };

    let buttonText = {
      textTransform: 'none',
      fontSize: 15,
    } as React.CSSProperties;

    return (
      <div>
        <Card variant='outlined' style={cardStyle}>
          <Form onKeyPress={this.onKeyPress}>
            <div
              className='row'
              hidden={this.state.titleVisibility}
              style={{ alignItems: 'center' }}
            >
              <div className='col-md-10'>
                <FormControl fullWidth>
                  <CustomTextField
                    className='answer-card-form'
                    style={{ borderBottom: 'None', height: '1rem !important' }}
                    id='card_title'
                    name='card_title'
                    label='Title (Optional)'
                    size='small'
                    variant='outlined'
                    onChange={this.onChange}
                  />
                </FormControl>
              </div>
              <div className='col-md-2'>
                <Link to='#' className='util-icons' onClick={this.hideTitle}>
                  <i className='material-icons'>cancel</i>
                </Link>
              </div>
            </div>

            <div
              className='row'
              hidden={this.state.summaryVisibility}
              style={{ alignItems: 'center' }}
            >
              <div className='col-md-10'>
                <FormControl fullWidth>
                  <CustomTextField
                    className='answer-card-form'
                    id='card_summary'
                    name='card_summary'
                    label='Summary (Optional)'
                    variant='outlined'
                    onChange={this.onChange}
                  />
                </FormControl>
              </div>
              <div className='col-md-2'>
                <Link to='#' className='util-icons' onClick={this.hideSummary}>
                  <i className='material-icons'>cancel</i>
                </Link>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <AvoEditor
                  suggestions={this.state.textEditorsuggestions}
                  setValue={this.onTextChange}
                  moduleId={this.props.moduleId}
                  richTextButtonShowList={[
                    ToolbarButton.TEXT_STYLE,
                    ToolbarButton.BOLD,
                    ToolbarButton.ITALIC,
                    ToolbarButton.HIGHLIGHT,
                    ToolbarButton.BULLETED_LIST,
                    ToolbarButton.NUMBERED_LIST,
                    ToolbarButton.INSERT_LINK,
                    ToolbarButton.PHONE_NUMBER,
                    ToolbarButton.VARIABLES,
                    ToolbarButton.CONDITIONAL_TEXT,
                  ]}
                />
              </div>
            </div>
            <div className='row' style={{ alignItems: 'center', marginBottom: '0' }}>
              <Button
                style={{ ...buttonStyle, ...buttonText } as React.CSSProperties}
                className='util-icons'
                onClick={this.textCardSubmit}
                type='button'
              >
                Save
              </Button>
              <Button
                className='waves-effect waves-teal btn-flat'
                style={buttonText}
                onClick={this.hideInline}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(Inline);
