import React from 'react';
import { Input } from 'reactstrap';
import { FileName, SelectFile, StyledBox } from './styles';

const UploadFile = ({ fileName, handleFile, allowedFormats }) => {
  return (
    <>
      <StyledBox>
        <SelectFile for='fileInput'>Choose file</SelectFile>
        <FileName> {fileName} </FileName>
        <Input
          id='fileInput'
          type='file'
          style={{ width: 0, visibility: 'hidden' }}
          onChange={handleFile}
          accept={allowedFormats}
        />
      </StyledBox>
    </>
  );
};

export default UploadFile;
