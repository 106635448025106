import clsx from 'clsx';
import { HTMLAttributes } from 'react';

interface SectionCardProps extends HTMLAttributes<HTMLDivElement> {}

const SectionCard = ({ children, className }: SectionCardProps) => {
  return (
    <div className={clsx('mt-8 rounded-[24px] bg-white p-6 shadow-md', className)}>{children}</div>
  );
};

export default SectionCard;
