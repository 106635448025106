import { debounce } from 'lodash';
import { Tiptap } from '../../utils/tiptap/Tiptap';
import { MentionProvider } from '../../utils/module/MentionContext';

export const APIResourceBody = ({ initBody, onChange }) => {
  const onUpdate = debounce((editor) => {
    onChange(editor.getJSON());
  }, 300);

  return (
    <MentionProvider excludedSuggestionTypes={[]} performChoiceCodeSubstitution>
      <Tiptap onUpdate={onUpdate} initialContent={initBody} />
    </MentionProvider>
  );
};

export const APIResourceAuthorization = ({ initBody, onChange }) => {
  const onUpdate = debounce((editor) => {
    onChange(editor.getJSON());
  }, 300);

  return (
    <MentionProvider excludedSuggestionTypes={[]} performChoiceCodeSubstitution>
      <Tiptap onUpdate={onUpdate} initialContent={initBody} />
    </MentionProvider>
  );
};
