import { ModuleLayout } from 'components/layout/ModuleLayout';
import { FormulaResourceTable } from 'components/resources/formula-board/FormulaTable';

export const Formulas = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <FormulaResourceTable />
    </ModuleLayout>
  );
};
