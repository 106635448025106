import { Button, makeStyles, Popover } from '@material-ui/core';
import { CustomToast } from 'components/utils/toast-message';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LinkIcon from '../../../../assets/icons/linkSVG';
import TextIcon from '../../../../assets/icons/textIcon';
import ModuleDropdown from './ModuleDropdown';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    padding: '16px',
    gap: '8px',

    width: '432px',
    height: '168px',
    left: '124px',
    bottom: '40px',
    background: '#FFFFFF',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 12px',
    width: '400px',
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid #E5EDF0',
    borderRadius: '8px',
    outline: 'none',
  },
  inputField: {
    border: 'none',
    outline: 'none',
    marginLeft: 8,
    width: '100%',
  },
  confirmButton: {
    width: '88px',
    height: '40px',
    background: '#08A88E',
    borderRadius: '6px',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      background: '#08A88E',
    },
    '&:disabled': {
      background: '#E5EDF0',
    },
  },
}));

function InsertLink({ popperAnchor, handlePopperClose, insertLink }) {
  const classes = useStyles();
  const modulesState = useSelector((state) => state.modulesState);
  const mirrorState = useSelector((state) => state.mirrorState);
  const teamCalculatorState = useSelector((state) => state.teamCalculatorState);

  const [text, setText] = useState('');
  const [moduleDropdown, setModuleDropdown] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedCalc, setSelectedCalc] = useState(null);
  const [link, setLink] = useState('');
  const open = Boolean(popperAnchor);
  const id = open ? 'simple-popper' : undefined;

  const handleModuleInputClick = (event) => {
    setModuleDropdown(moduleDropdown ? null : event.currentTarget);
  };

  const handleInsert = () => {
    if (!link) {
      toast.error(CustomToast, { data: 'Link is required!' });
      return;
    }
    if (!selectedModule && !selectedCalc && text.length <= 0) {
      insertLink(link, link, 'unembedded', null);
    } else if (!selectedModule && !selectedCalc && text.length > 0 && link.length > 0) {
      insertLink(text, link, 'embedded', null);
    } else if (selectedModule && text.length <= 0) {
      insertLink(link, link, 'module', selectedModule?.code);
    } else if (selectedCalc && text.length <= 0) {
      insertLink(link, link, 'calculator', selectedCalc?.code);
    } else if (selectedCalc && text.length > 0 && link.length > 0) {
      insertLink(text, link, 'calculator', selectedCalc?.code);
    } else {
      insertLink(text, link, 'module', selectedModule?.code);
    }
  };

  const handleModuleCalcChange = (obj, type) => {
    if (type === 'module') {
      setSelectedModule(obj);
    } else {
      setSelectedCalc(obj);
    }
    setLink(obj.name);
    setText(obj.name);
  };

  useEffect(() => {
    return () => {
      setModuleDropdown(null);
    };
  }, []);

  let modulesNmirros = modulesState?.modules || [];
  let calcNmirrors = teamCalculatorState?.teamCalculators || [];

  const moduleCodes = modulesNmirros.map((module) => module.code);
  const calcCodes = calcNmirrors.map((calc) => calc.code);

  mirrorState?.mirrors?.forEach((mirror) => {
    const obj = mirror?.details || {};
    if (!mirror.is_mirror_of_calc && !moduleCodes.includes(obj.code)) {
      modulesNmirros.push(obj);
    } else if (mirror.is_mirror_of_calc && !calcCodes.includes(obj.code)) {
      calcNmirrors.push(obj);
    }
  });

  return (
    <Popover
      id={id}
      open={open}
      disableEnforceFocus
      disableScrollLock
      classes={{
        paper: classes.paper,
      }}
      anchorEl={popperAnchor}
      onClose={handlePopperClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.inputWrapper} onClick={handleModuleInputClick}>
        <LinkIcon width='17' height='17' color='#6C7C83' />
        <input
          className={classes.inputField}
          onChange={(e) => {
            setLink(e.target.value);
            setSelectedModule(null);
          }}
          value={link}
        />
        <ModuleDropdown
          text={link}
          moduleList={modulesNmirros}
          calcList={calcNmirrors}
          moduleAnchor={moduleDropdown}
          handleModuleClose={() => setModuleDropdown(false)}
          setSelectedModuleCalc={handleModuleCalcChange}
        />
      </div>
      <div className={classes.inputWrapper} onClick={() => setModuleDropdown(null)}>
        <TextIcon width='11' height='13' />

        <input
          value={text}
          className={classes.inputField}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
      </div>
      <Button className={classes.confirmButton} onClick={handleInsert}>
        {' '}
        Confirm
      </Button>
    </Popover>
  );
}

export default InsertLink;
