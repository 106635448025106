import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useState } from 'react';

export const AutoHidingTooltip = ({ message, seconds, open, children }) => {
  const [isVisible, setIsVisible] = useState(open);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 1000 * seconds);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible]);

  return (
    <Tooltip title={message} arrow disableHoverListener={true} open={isVisible}>
      {children}
    </Tooltip>
  );
};
