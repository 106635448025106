import { MenuList, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import AddBasicIcon from 'assets/icons/addBasicIcon';
import SituationBuilder from 'components/resources/triggers/SituationBuilder';
import TriggerPopup from 'components/resources/triggers/TriggerPopup';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import { toast } from 'react-toastify';

import SlidingPane from 'react-sliding-pane';
import './modals/modal.css';
import { TriggerDropdown, TriggerItem, TriggerMenu } from './styled-components/TriggerStyle';

const styles = (theme) => ({
  root: {
    paddingLeft: '5px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    marginRight: '5px',
    marginLeft: '15px',
    height: '10px',
    color: '#999999',
  },
  icon: {
    marginRight: '20px',
    color: '#A6A6A6',
  },
  closeIcon: {
    width: '30px !important',
    height: '20px',
    color: '#08A88E',
  },
});

class AppearingSituation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createModal: false,
      updateModal: false,
      triggerId: null,
      isAlwaysOn: false,
      triggerAnchor: null,
      popupAnchor: null,
      selectedObj: null,
      triggerSearch: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      let trigger = this.props.triggerOptions.find(
        (trigger) => trigger.id === this.props.defaultValue
      );

      this.setState({
        triggerId: this.props.defaultValue,
        isAlwaysOn: trigger && trigger.title === 'Always On' ? true : false,
        isCandidateTrigger: trigger && trigger.candidate_trigger,
      });
    }
  }

  onChange = (e) => {
    let trigger = this.props.triggerOptions.find((trigger) => trigger.id === e.id);
    this.props.onInputChange(e.id, trigger);
  };

  toggleModal = (modalName) => {
    this.setState((previous) => ({
      [modalName]: !previous[modalName],
    }));

    // TODO Opening the trigger modal in any panel was causing the flow section to move upward. Remove these lines of commented code after using the new slide panel.

    /*this.state.createModal
      ? (document.body.style.overflow =
          document.body.style.overflow === 'hidden' ? 'auto' : 'hidden')
      : (document.body.style.overflow =
          document.body.style.overflow === 'hidden' ? 'auto' : 'hidden');
    */
  };

  closeToggleModal = () => {
    this.setState({
      createModal: false,
      updateModal: false,
    });
  };

  editOrDelete = () => {
    // if candidate trigger is selected then on pressing delete button removed selected trigger
    if (
      this.state.triggerId &&
      !this.state.isAlwaysOn &&
      this.state.triggerId !== 'notAssigned' &&
      this.state.isCandidateTrigger === true
    ) {
      this.props.onInputChange('notAssigned');
    } else if (
      this.state.triggerId &&
      !this.state.isAlwaysOn &&
      this.state.triggerId !== 'notAssigned'
    ) {
      this.toggleModal('updateModal');
    }
  };

  handleClose = () => {
    this.setState({ popupAnchor: null });
  };

  render() {
    const triggers = this.props.triggerOptions;

    return (
      <div style={{ alignItems: 'center', marginBottom: 0 }}>
        <TriggerPopup
          anchorEl={this.state.popupAnchor}
          handleClose={() => this.handleClose()}
          selectedObj={this.state.selectedObj}
        />
        <FieldLabel
          required
          label={this.props.conditionLabel ? this.props.conditionLabel : 'Trigger '}
        />

        <Box className='d-flex align-items-center flex-row'>
          <div
            onClick={(e) =>
              this.setState({
                triggerAnchor: this.state.triggerAnchor ? null : e.currentTarget,
              })
            }
          >
            <TriggerDropdown open={Boolean(this.state.triggerAnchor)}>
              <input
                className='trigger-placeholder'
                style={{ outline: 'none', border: 'none', width: '90%' }}
                placeholder={
                  triggers.find((trigger) => trigger.id === this.props.defaultValue)?.title ||
                  'Not Assigned'
                }
                onChange={(e) =>
                  this.setState({ triggerSearch: e.target.value, popupAnchor: null })
                }
              />

              <KeyboardArrowDown
                style={{ float: 'right', color: '#A6A6A6', pointerEvents: 'none' }}
              />
            </TriggerDropdown>
          </div>
          <TriggerMenu
            style={{ zIndex: 9999 }}
            disableScrollLock
            transitionDuration={50}
            disableAutoFocus
            disableEnforceFocus
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorEl={this.state.triggerAnchor}
            open={Boolean(this.state.triggerAnchor)}
            onClose={(e) => this.setState({ triggerAnchor: null })}
          >
            <MenuList
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: 288,
                width: '100%',
                borderRadius: 5,
                boxShadow: 'none',
                maxWidth: 275,
                minWidth: 275,
              }}
            >
              {triggers &&
                triggers
                  .filter((item) => {
                    return item.title
                      .toLowerCase()
                      .includes(this.state.triggerSearch.toLowerCase());
                  })
                  .map((trigger) => (
                    <TriggerItem
                      key={trigger.id}
                      onClick={() => {
                        this.setState({ triggerAnchor: null }, () => {
                          this.setState({ popupAnchor: null });
                          this.onChange(trigger);
                        });
                      }}
                      onMouseEnter={(event) => {
                        trigger.title !== 'Always On' &&
                          this.setState({
                            popupAnchor: event.currentTarget,
                            selectedObj: trigger,
                          });
                      }}
                      onMouseLeave={() => this.setState({ popupAnchor: null, selectedObj: null })}
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {trigger.title || 'Not Found'}
                      </span>
                    </TriggerItem>
                  ))}
            </MenuList>
          </TriggerMenu>

          <Box
            onClick={() => {
              if (window.navigator.onLine) {
                this.editOrDelete();
              } else {
                toast.error(CustomToast, { data: 'Internet Connection Lost' });
              }
            }}
            style={{
              marginLeft: '21px',
              marginRight: '15px',
              cursor: 'pointer',
            }}
          >
            <Typography
              style={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '18px',
                color:
                  this.state.triggerId &&
                  !this.state.isAlwaysOn &&
                  this.state.triggerId !== 'notAssigned'
                    ? '#08A88E'
                    : '#CBCBCB',
              }}
            >
              {this.state.isCandidateTrigger ? 'Remove' : 'Edit'}
            </Typography>
          </Box>
          <Divider orientation='vertical' style={{ height: '20px', marginRight: '10px' }} />
          <Button
            variant='text'
            startIcon={
              <div style={{ marginBottom: '2px' }}>
                <AddBasicIcon />
              </div>
            }
            style={{
              fontStyle: 'normal',
              minWidth: '170px',
              fontWeight: 600,
              fontSize: '18px',
              textAlign: 'center',
              textTransform: 'none',
              color: '#08A88E',
            }}
            onClick={() => this.toggleModal('createModal')}
          >
            New Trigger
          </Button>
        </Box>

        <SlidingPane
          isOpen={this.state.createModal}
          onRequestClose={() => this.toggleModal('createModal')}
          from='bottom'
          hideHeader
          className='sliding-pan-modal  side-popup-shadow '
          overlayClassName='transparent-background z-50'
          width='580px'
        >
          <SituationBuilder
            toggleModal={() => this.toggleModal('createModal')}
            type={this.props.type ? this.props.type : null}
            savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
            selectTrigger={(value) => {
              let trigger = this.props.triggerOptions.find((trigger) => trigger.id === value);
              this.props.onInputChange(value, trigger);
            }}
          />
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.updateModal}
          onRequestClose={() => this.toggleModal('updateModal')}
          from='bottom'
          hideHeader
          className='sliding-pan-modal  side-popup-shadow'
          overlayClassName='sliding-panel-cards !z-50'
          width='580px'
        >
          <SituationBuilder
            toggleModal={() => this.toggleModal('updateModal')}
            type={this.props.type ? this.props.type : null}
            triggerId={this.state.triggerId}
            savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
            selectTrigger={(value) => {
              let trigger = this.props.triggerOptions.find((trigger) => trigger.id === value);
              this.props.onInputChange(value, trigger);
            }}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default withStyles(styles)(AppearingSituation);
