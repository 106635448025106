import { MainLayout } from 'components/layout/MainLayout';
import TeamSettingForm from 'components/teams/teamSettingForm';
import { H2 } from 'components/utils/typo';

export const Setting = () => {
  return (
    <MainLayout className='bg-gray-100'>
      <H2>Team Setting</H2>
      <TeamSettingForm isEditing />
    </MainLayout>
  );
};
