import { simulateInWebapp } from 'components/module-detail/ConversationBoardUtils';
import { dateFormattingOptions } from 'constants/date/formattingOptions';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  ALGO_FLOW_ROUTE,
  CALC_FLOW_ROUTE,
  HAS_VIEW_PERMISSIONS,
  KB_CONTENT_ROUTE,
  MODULE_TYPES,
  MODULE_TYPES_MAP,
  PermissionMap,
} from '../../constants';
import { ModuleWithMirror, getUniqueModuleKey } from '../utils/module';
import { CustomPopover } from '../utils/popover/Popover';
import ModuleSetting from './ModuleSetting';
import { VerifiedComponent } from './VerifiedComponent';

import clsx from 'clsx';
import { ROLES } from '../../api/team';
import { useTeam } from '../../hooks/useTeam';
import { VerifiedChip } from '../utils/styled-components/ModuleCalcRowStyle';
const commonTextClasses = 'text-lg text-black leading-[2.5vh] hover:text-black';
const commonTdClasses = 'p-2';
interface ModuleRowProps {
  module: ModuleWithMirror;
}
const ModuleRow: FC<ModuleRowProps> = ({ module }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const { team } = useTeam();

  const showIconHandler = () => setShowIcon(true);
  const hideIconHandler = () => setShowIcon(false);

  const setAnchorElHandler = (e) => setAnchorEl(e.target);
  const handleClose = () => setAnchorEl(null);

  const link = useMemo(() => {
    const isMirror = module?.type === MODULE_TYPES.MIRROR;
    const id = isMirror ? module.mirroredModuleId! : module?.id;
    const type = isMirror ? module.mirrorOf! : module?.type;
    switch (type) {
      case MODULE_TYPES.CALCULATOR:
        return generatePath(CALC_FLOW_ROUTE, { moduleId: id });
      case MODULE_TYPES.KNOWLEDGE_BASE:
        return generatePath(KB_CONTENT_ROUTE, { moduleId: id });
      case MODULE_TYPES.ALGO:
        return generatePath(ALGO_FLOW_ROUTE, { moduleId: id });
    }
    return '#';
  }, [module]);

  const openModule = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const isMirror = module?.type === MODULE_TYPES.MIRROR;
    const id = isMirror ? module.mirroredModuleId! : module?.id;
    const type = isMirror ? module.mirrorOf! : module?.type;

    if (isMirror && type !== MODULE_TYPES.KNOWLEDGE_BASE) {
      simulateInWebapp(id, type);
    } else {
      window.scrollTo(0, 0);
      history.push({
        pathname: link,
        state: {
          isMirror,
          mirrorId: isMirror && module?.id,
          mirrorName: isMirror && module?.name,
        },
      });
    }
  };

  const isMirror = module?.type === MODULE_TYPES.MIRROR;
  const type = isMirror ? module.mirrorOf! : module.type;

  const isCalc = type === MODULE_TYPES.CALCULATOR;
  const isAIKnowledgeBase = type === MODULE_TYPES.KNOWLEDGE_BASE;

  const isTeamAdmin = team.current_teammate?.role === ROLES.Admin;

  const hasPermission =
    isTeamAdmin ||
    isMirror ||
    (module?.current_user_role && HAS_VIEW_PERMISSIONS.includes(module.current_user_role)) ||
    (!!team.current_teammate?.role &&
      module?.permission_type &&
      HAS_VIEW_PERMISSIONS.includes(module.permission_type));

  const last_updated = new Date(module.last_updated).toLocaleString('en-US', dateFormattingOptions);

  const editor = module.last_updated_by ? ` by ${module.last_updated_by}` : '';
  return (
    <>
      <tr
        className={clsx(
          `mr-10 min-h-[65px] cursor-pointer border-b border-gray-200 transition duration-300 ease-in-out hover:shadow-md`,
          isMirror ? 'opacity-60' : 'opacity-100'
        )}
        data-testid={getUniqueModuleKey(module)}
        key={module.id}
        onMouseEnter={showIconHandler}
        onMouseLeave={hideIconHandler}
      >
        <td className={clsx(commonTdClasses, 'pl-12')} align='left'>
          <a
            className={clsx(
              commonTextClasses,
              'font-bold',
              'flex-no-wrap flex flex-row items-center'
            )}
            href={link}
            onClick={openModule}
          >
            <span> {module.name}</span>
            <CustomPopover open={Boolean(anchorEl)} onClose={handleClose}>
              <div className='w-[267px]'>
                <VerifiedComponent
                  name={module.reviewed_by?.name!}
                  verifiedOn={module.last_verified}
                  onMouseLeave={handleClose}
                />
              </div>
            </CustomPopover>
            {module.last_verified && <VerifiedChip onMouseOver={setAnchorElHandler} />}
          </a>
        </td>

        <td className={clsx(commonTdClasses, 'whitespace-nowrap')} align='left'>
          <a href={link} onClick={openModule} className={commonTextClasses}>
            {module?.owner}
          </a>
        </td>
        <td className={clsx(commonTdClasses)} align='left'>
          <a href={link} onClick={openModule} className={commonTextClasses}>
            {module?.current_user_role && PermissionMap?.[module?.current_user_role]?.label}
          </a>
        </td>
        <td className={clsx(commonTdClasses)}>
          <a href={link} onClick={openModule} className={commonTextClasses}>
            {module.status}
          </a>
        </td>
        <td className={clsx(commonTdClasses)}>
          <a href={link} onClick={openModule} className={commonTextClasses}>
            {MODULE_TYPES_MAP[type]}
          </a>
        </td>
        <td className={clsx(commonTdClasses)} align='left'>
          <a href={link} onClick={openModule} className={commonTextClasses}>
            <div className='flex flex-col'>
              <div className='mb-4 whitespace-nowrap leading-normal'>
                {module.last_updated && last_updated}
              </div>
              <div className='mb-4 leading-normal text-gray-600'>
                {module.last_updated && editor}
              </div>
            </div>
          </a>
        </td>
        <td className={clsx(commonTdClasses, 'text-left')} align='right'>
          <ModuleSetting
            module={module}
            isCalc={isCalc}
            isAIKnowledgeBase={isAIKnowledgeBase}
            buttonColor={showIcon && hasPermission ? '#08A88E' : 'transparent'}
            buttonShadow={showIcon && hasPermission ? ' 0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'}
          />
        </td>
      </tr>
    </>
  );
};

export default ModuleRow;
