import React from 'react';

export default function PhoneIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.84335 4.3926L5.13696 1.26879C4.82497 0.908811 4.25297 0.910411 3.89057 1.27359L1.66498 3.50306C1.00259 4.16622 0.812987 5.15096 1.19618 5.94051C3.48547 10.6802 7.30819 14.5081 12.0449 16.8039C12.8337 17.187 13.8177 16.9974 14.4801 16.3343L16.7265 14.084C17.0905 13.72 17.0913 13.1449 16.7281 12.8329L13.5921 10.1411C13.2641 9.85947 12.7545 9.89627 12.4257 10.2259L11.3345 11.3186C11.2787 11.3771 11.2051 11.4157 11.1252 11.4284C11.0453 11.4412 10.9634 11.4273 10.8921 11.389C9.1085 10.3619 7.62894 8.88052 6.60416 7.09564C6.56578 7.02425 6.55188 6.94223 6.5646 6.86218C6.57731 6.78213 6.61594 6.70846 6.67456 6.65247L7.76255 5.56373C8.09215 5.23255 8.12815 4.72058 7.84335 4.3918V4.3926Z'
        stroke='#1E1F20'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
