import { ChannelList } from 'components/channels/ChannelList';
import { MainLayout } from 'components/layout/MainLayout';

export const Channels = () => {
  return (
    <MainLayout>
      <ChannelList />
    </MainLayout>
  );
};
