import { HelpInfo } from 'v2/stores/slice/helpInfo';

interface HelpInfoData {
  name: string;
  help_text: string;
  image: null | string;
  model_identifier: string;
  field_identifier: string;
  label: string;
  placeholder: string;
  detail: string;
  character_limit: number;
}

export const getHelpInfoData = (key: string) => {
  const data: HelpInfoData[] = helpInfo[key];

  let helpData: any = {};

  for (var i = 0; i < data.length; i++) {
    helpData[data[i].field_identifier] = data[i];
  }

  return helpData;
};

const helpInfo: HelpInfo = {
  MultipleChoicePanel: [
    {
      name: 'Multiple Choice Panel/ Variable',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'variable',
      label: 'Variable',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Multiple Choice Panel/ Submission Button',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'is_submissable',
      label: 'Remove Submission Button',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Multiple Choice Panel/ Question Panel',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'question_panel',
      label: 'List of Questions',
      placeholder: 'Placeholder',
      detail: 'Question statement such as "Disease severity?",   "Any of following features?"',
      character_limit: null,
    },
    {
      name: 'Multiple Choice Panel/ Title',
      help_text: 'The tile displayed in the app',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'ARDS Management',
      detail: 'The tile displayed in the app',
      character_limit: 255,
    },
    {
      name: 'Multiple Choice Panel/ Rationale',
      help_text:
        "The clinical informatics literature supports telling users why they're being asked specific questions to further their use of clinical decision support systems.",
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'rationale',
      label: 'Rationale',
      placeholder: 'Initial Management of ARDS',
      detail: 'Why is this question being asked',
      character_limit: 500,
    },
    {
      name: 'Multiple Choice Panel/ Choices',
      help_text: 'Choices/answers',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'choices',
      label: 'Answers',
      placeholder: 'Placeholder',
      detail: 'Include the choices/answers',
      character_limit: null,
    },
    {
      name: 'Multiple Choice Panel/ Question',
      help_text:
        'Question statement such as "Disease severity?",   "Any of following features?" -- What question are you asking your users?',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'question',
      label: 'Question',
      placeholder: 'Initial Management of ARDS',
      detail: 'Question statement such as "Disease severity?",   "Any of following features?"',
      character_limit: 255,
    },
    {
      name: 'Multi Choice Panel/ Examples',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'examples',
      label: 'Examples',
      placeholder: 'Placeholder',
      detail:
        'You can add a list of text items. If the question statement is "any of following disease?" then you might want to add a list of "asthma, COPD, and lung cancer".',
      character_limit: null,
    },
    {
      name: 'Multi Choice Panel/ Tools',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'tools',
      label: 'Tools',
      placeholder: 'Placeholder',
      detail:
        'Click the buttons on the right to add Media, Calculators, or Infoboxes to your card.',
      character_limit: null,
    },
    {
      name: 'Multiple Choice Panel/ Shortened Title',
      help_text: 'The title displayed in the app',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'shortened_title',
      label: 'Variable Name',
      placeholder: '(E.g., ‘ comorbidities’, ‘drug history’)',
      detail: '(E.g., ‘ comorbidities’, ‘drug history’)',
      character_limit: 255,
    },
    {
      name: 'Multiple Choice Panel/ Subtitle',
      help_text: '',
      image: null,
      model_identifier: 'MultipleChoicePanel',
      field_identifier: 'subtitle',
      label: 'Subtitle',
      placeholder: 'e.g. severe illness requires a stronger antibiotic',
      detail: '',
      character_limit: 500,
    },
  ],
  DescriptionPanel: [
    {
      name: 'Description Panel/ Item List',
      help_text: '',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'item_list',
      label: 'Item List',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Description Panel/ Title',
      help_text: 'This is the title that is displayed in the app.',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Card Title Placeholder',
      detail: 'Name your description card',
      character_limit: 255,
    },
    {
      name: 'Description Panel/ Description',
      help_text:
        'You can always see what it will look like by looking at the "App Preview" at the top of the screen.',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'description_text',
      label: 'Text',
      placeholder: 'Placeholder',
      detail: 'Provide more details for your description card.',
      character_limit: 255,
    },
    {
      name: 'Description Panel/ Tools',
      help_text: '',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'tools',
      label: 'Tools',
      placeholder: 'Placeholder',
      detail:
        'Add Media, Calculators, or Infoboxes to your cards by clicking the buttons on the right',
      character_limit: null,
    },
    {
      name: 'Description Panel/ Examples',
      help_text: '',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'examples',
      label: 'Examples',
      placeholder: 'Placeholder',
      detail:
        'You can add a list of text items. If the question statement is "any of following disease?" then you might want to add a list of "asthma, COPD, and lung cancer".',
      character_limit: null,
    },
    {
      name: 'Description Panel/ Long Description',
      help_text: 'Enable long description for more than 200 characters',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'description_text_long',
      label: 'Details',
      placeholder: 'Long Description',
      detail: '',
      character_limit: 2000,
    },
    {
      name: 'Description Panel/ Short description',
      help_text: 'Short description for less than 450 characters',
      image: null,
      model_identifier: 'DescriptionPanel',
      field_identifier: 'description_text_short',
      label: 'Summary',
      placeholder: 'Summary',
      detail: '',
      character_limit: 1000,
    },
  ],
  ValuePanel: [
    {
      name: 'Value Panel/ Skippable',
      help_text: '',
      image: null,
      model_identifier: 'ValuePanel',
      field_identifier: 'is_skippable',
      label: 'Skippable',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Value Panel/ Panel Items',
      help_text: 'For values that are known use predefined inputs.',
      image: null,
      model_identifier: 'ValuePanel',
      field_identifier: 'panel_item',
      label: 'Numbers',
      placeholder: 'Placeholder',
      detail: 'Add the inputs (ex. Na or Glucose), or create custom inputs.',
      character_limit: null,
    },
    {
      name: 'Value Panel/ Name',
      help_text: 'This is the title of the numbers panel value input card.',
      image: null,
      model_identifier: 'ValuePanel',
      field_identifier: 'name',
      label: 'Title',
      placeholder: 'Basic Metabolic Stats',
      detail: 'Name the card.',
      character_limit: 255,
    },
    {
      name: 'Value Panel/ Shortened Name',
      help_text: 'Shorthand name for the value inputs.',
      image: null,
      model_identifier: 'ValuePanel',
      field_identifier: 'short_name',
      label: 'Shortened Name',
      placeholder: 'Vital Signs, ABG',
      detail: 'A shortened name for the value inputs.',
      character_limit: 255,
    },
  ],
  AnswerPage: [
    {
      name: 'Answer Page/ Shortened Title',
      help_text: '',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'shortened_title',
      label: 'Shortened Title',
      placeholder: 'Placeholder',
      detail: 'Title of the Answer Page displayed in the app',
      character_limit: 100,
    },
    {
      name: 'Answer Page/ Terminal Page Checkbox',
      help_text: 'This should be checked if it is an answer page.',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'is_terminal_page',
      label: 'Is Terminal Page',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Answer Page/ Title',
      help_text: 'Title displayed on the top of the answer page.',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Placeholder',
      detail: 'Name of answer page',
      character_limit: 250,
    },
    {
      name: 'Answer Page/ Input Reviews',
      help_text: '',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'input_reviews',
      label: 'Input Reviews',
      placeholder: 'Placeholder',
      detail:
        'Populating from your "representation phrases" and displaying to users what choices were made to arrive at this answer page.',
      character_limit: null,
    },
    {
      name: 'Answer Page/ Add Input Reviews',
      help_text: 'Select each of the inputs individually.',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'add_input_reviews',
      label: 'Add Input Reviews',
      placeholder: 'Add Input Reviews',
      detail:
        'Add each of the variable names of your dashboard cards to display as a summary of inputs.',
      character_limit: null,
    },
    {
      name: 'Answer Page/ Add in Should be Available',
      help_text: '',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'add_should_be_available',
      label: 'Add in Should be Available',
      placeholder: 'Add in Should be Available',
      detail: 'Please ignore this section as the system will handle it for you!',
      character_limit: null,
    },
    {
      name: 'Answer Page/ Should be Available',
      help_text: '',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'should_be_available',
      label: 'Should be Available (Optional)',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Answer Page/ Default Page Checkbox',
      help_text: '',
      image: null,
      model_identifier: 'AnswerPage',
      field_identifier: 'is_default_page',
      label: 'Is Default Page',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
  ],
  SectionDivider: [
    {
      name: 'Section Divider/ Sub-Title',
      help_text: 'Subheader.',
      image: null,
      model_identifier: 'SectionDivider',
      field_identifier: 'subtitle',
      label: 'Subtitle',
      placeholder: 'Vital Signs, ABG',
      detail: 'Details of the section.',
      character_limit: 255,
    },
    {
      name: 'Section Divider/ Title',
      help_text:
        'This is what you want to display, for example if you want to separate in-hospital from out of hospital in your algorithm you may want this section to be titled “Inpatient” to make it very clear which part of the algorithm the user is on.',
      image: null,
      model_identifier: 'SectionDivider',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Basic Metabolic Stats',
      detail: 'Name of the section.',
      character_limit: 255,
    },
  ],
  TextCard: [
    {
      name: 'Text Card/ Title',
      help_text: 'for example “Anticoagulation for AFib',
      image: null,
      model_identifier: 'TextCard',
      field_identifier: 'card_title',
      label: 'Title',
      placeholder: 'Enter Title',
      detail: 'The header/title of your recommendations',
      character_limit: 250,
    },
    {
      name: 'Text Card/ Summary',
      help_text:
        'Helpful hints: don’t include links or Infoboxes or references in the summary. Save that for the text below. Suggested format may be “In cases of native valve atrial fibrillation with an elevated CHADSVASC and low HASBLED, the ACC recommends anticoagulantion with a DOAC.”',
      image: null,
      model_identifier: 'TextCard',
      field_identifier: 'card_summary',
      label: 'Summary',
      placeholder: 'Enter Summary',
      detail: 'Summarize the recommendations here',
      character_limit: 250,
    },
    {
      name: 'Text Card/ Text',
      help_text: 'Here you can include infoboxes, references, etc',
      image: null,
      model_identifier: 'TextCard',
      field_identifier: 'card_text',
      label: 'Text',
      placeholder: 'Enter Text',
      detail: 'The bulk of the recommendations, the details',
      character_limit: 1000,
    },
    {
      name: 'Text Card/ Media',
      help_text: '',
      image: null,
      model_identifier: 'TextCard',
      field_identifier: 'media',
      label: 'Media',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
  ],
  Formula: [
    {
      name: 'Formula/ Formula',
      help_text: 'This is where you type in the formula itself!',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'formula',
      label: 'Formula',
      placeholder: 'Placeholder',
      detail: 'The formula itself.',
      character_limit: 500,
    },
    {
      name: 'Formula/ Changeable',
      help_text: 'Use symbols to manually write your formula here.',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'isChangeAble',
      label: 'Is Changeable',
      placeholder: 'Placeholder',
      detail: 'Manually Write the Formula',
      character_limit: null,
    },
    {
      name: 'Formula/ Name',
      help_text: 'Only used internally. The users do not see this name',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Enter Formula Name',
      detail: 'Name the formula.',
      character_limit: 250,
    },
    {
      name: 'Formula/ Shortened Name',
      help_text: 'For internal use only. The users do not see this',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'shortened_name',
      label: 'Shortened Name',
      placeholder: 'Enter Shortened Name',
      detail: 'Shortened name for the formula.',
      character_limit: 100,
    },
    {
      name: 'Formula/ Formula Code',
      help_text: 'Enter the code here.',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'formula_code',
      label: 'Code',
      placeholder: 'Placeholder',
      detail: 'Enter the code here.',
      character_limit: null,
    },
    {
      name: 'Formula/ Text',
      help_text: 'Some info about the Formula.',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'text',
      label: 'Description',
      placeholder: 'Enter Text',
      detail: 'Text about your Formula.',
      character_limit: 2000,
    },
    {
      name: 'Formula/ Formula Code',
      help_text: '',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'code',
      label: 'Code',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'Formula/ Unit',
      help_text: 'Final output unit.',
      image: null,
      model_identifier: 'Formula',
      field_identifier: 'unit',
      label: 'Unit',
      placeholder: 'Enter Unit',
      detail: 'Unit for your formula output.',
      character_limit: 39,
    },
  ],
  ChoiceCard: [
    {
      name: 'Choice Card/ Variable',
      help_text: '',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'variable',
      label: 'Variable',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Choice Card/ Choices',
      help_text: 'You can drag and drop answer choices to reorder them.',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'choices',
      label: 'Answers',
      placeholder: 'Placeholder',
      detail:
        'Create your answer choices either by double clicking on an answer choice, or clicking the button on the right',
      character_limit: null,
    },
    {
      name: 'Choice Card/ Rationale',
      help_text:
        'This is an optional input, if it would be helpful to tell the users why you are asking the question.',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'rationale',
      label: 'Rationale',
      placeholder: 'Rationale (e.g. severe illness requires stronger antibiotic)',
      detail: 'Why is this question relevant?',
      character_limit: 500,
    },
    {
      name: 'Choice Card/ Question',
      help_text: '',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'question',
      label: 'Question Statement',
      placeholder: 'What question should this card answer?',
      detail: 'Question that is presented to the user.',
      character_limit: 255,
    },
    {
      name: 'Choice Card/ Question Panel',
      help_text:
        'Input your question statement: This is what you are asking your users. We recommend using a question format.',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'question_panel',
      label: 'List of Questions',
      placeholder: 'Placeholder',
      detail: 'Question statement such as "Disease severity?",   "Any of following features?"',
      character_limit: null,
    },
    {
      name: 'Choice Card/ Title',
      help_text:
        'This is how the Question will be referenced when the user inputs are displayed. For example, if you can name your variable "Previous Antibiotics?"',
      image: null,
      model_identifier: 'ChoiceCard',
      field_identifier: 'title',
      label: 'Variable Name (< 4 words)',
      placeholder: 'question should this card',
      detail: '',
      character_limit: 255,
    },
  ],
  ChoicePanel: [
    {
      name: 'Choice Panel/ Choices',
      help_text: 'Answer choices can be rearranged by dragging and dropping them!',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'choices',
      label: 'Answers',
      placeholder: 'Placeholder',
      detail:
        'Create your answer choices either by double clicking on an answer choice, or clicking the button on the right',
      character_limit: null,
    },
    {
      name: 'Choice Panel/ Rationale',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'rationale',
      label: 'Rationale',
      placeholder: 'Rationale (e.g. severe illness requires stronger antibiotic)',
      detail:
        'Users might want to know why you ask this question so that they do not feel like their time/clicks are not wasted. “Why is that this question is relevant?”. Can skip is too obvious.',
      character_limit: 500,
    },
    {
      name: 'Choice Panel/ Question',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'question',
      label: 'Question',
      placeholder: 'What question should this card answer?',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Choice Panel/ Tools',
      help_text:
        'Media: Allows you to input media with descriptions. Calculator: Allows you to link a calculator, which can then feed back its input into this card. Infobox: Allows you to give a description (non-media) of further information.',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'tools',
      label: 'Tools',
      placeholder: 'Placeholder',
      detail:
        'The buttons on the right allow you to add Media, Calculators, or Infoboxes to your card.',
      character_limit: null,
    },
    {
      name: 'Choice Panel/ Question Panel',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'question_panel',
      label: 'List of Questions',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Choice Panel/ Examples',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'examples',
      label: 'Examples',
      placeholder: 'Placeholder',
      detail:
        'Can add a list of text items. For example, if the question is "Does the patient have any of the following conditions?", you can then add a disease list (e.g. "asthma, COPD, or lung cancer").',
      character_limit: null,
    },
    {
      name: 'Choice Panel/ Variable',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'variable',
      label: 'Variable',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Choice Panel/ Title',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'title',
      label: 'Variable Name',
      placeholder: 'question should this card',
      detail:
        'Describe the variable name within 4 words - you can use spaces. (E.g., “disease severity”, “possible etiology”, “goal of management”)',
      character_limit: 255,
    },
    {
      name: 'Choice Panel/ Subtitle',
      help_text: '',
      image: null,
      model_identifier: 'ChoicePanel',
      field_identifier: 'subtitle',
      label: 'Subtitle',
      placeholder: 'e.g. severe illness requires a stronger antibiotic',
      detail: '',
      character_limit: 500,
    },
  ],
  IntroductionCard: [
    {
      name: 'Opening Card/ Title',
      help_text: 'Introduction',
      image: null,
      model_identifier: 'IntroductionCard',
      field_identifier: 'title',
      label: null,
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Opening Card/ Description',
      help_text: 'Custom text will be populated',
      image: null,
      model_identifier: 'IntroductionCard',
      field_identifier: 'description_text',
      label: null,
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
  ],
  Numeric: [
    {
      name: 'Numeric/ Code',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'code',
      label: 'Code',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Numeric/ Terminology Code',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'terminology_code',
      label: 'Terminology Code',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Numeric/ Terminology',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'terminology',
      label: 'Terminology',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 20,
    },
    {
      name: 'Numeric/ Secondary Unit',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'secondary_unit',
      label: 'Secondary Unit',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Numeric/ P to S',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'primary_to_secondary',
      label: 'Primary to Secondary',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'Numeric/ S to P',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'secondary_to_primary',
      label: 'Secondary to Primary',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'Numeric/ 2 Decimal Places',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'two_decimal_places',
      label: 'Decimal Places',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Numeric/ Title',
      help_text: 'This is how the numeric is referred to.',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Placeholder',
      detail: 'Name of custom numeric value.',
      character_limit: 255,
    },
    {
      name: 'Numeric/ Unit',
      help_text: 'Ex. mg/dL',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'unit',
      label: 'Unit',
      placeholder: 'Placeholder',
      detail: 'Unit of the numeric.',
      character_limit: 255,
    },
    {
      name: 'Numeric/ Upper Limit',
      help_text: 'Ex. 10',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'upper_limit',
      label: 'Upper Limit',
      placeholder: 'Placeholder',
      detail: 'Maximum value of normal for the numeric.',
      character_limit: null,
    },
    {
      name: 'Numeric/ Lower Limit',
      help_text: 'Ex. 3.5',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'lower_limit',
      label: 'Lower Limit',
      placeholder: 'Placeholder',
      detail: 'Minimum value of normal for the numeric',
      character_limit: null,
    },
    {
      name: 'Numeric/ Second Title',
      help_text: '',
      image: null,
      model_identifier: 'Numeric',
      field_identifier: 'second_title',
      label: 'Second Title',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 255,
    },
  ],
  Pathfinder: [
    {
      name: 'Pathfinder/ Co-Authors',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'coauthors',
      label: 'Co-Authors',
      placeholder: 'Select Co-Authors',
      detail:
        'You can invite other teams members to allow them to access and edit the current AvoAssistant.',
      character_limit: null,
    },
    {
      name: 'Pathfinder/ Reviewers',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'reviewers',
      label: 'Reviewers',
      placeholder: 'Select Reviewers',
      detail: 'You can invite experts to review your AvoAssistant.',
      character_limit: null,
    },
    {
      name: 'Pathfinder/ Description',
      help_text:
        "The description will be exposed to the users. We recommend that the description include (1) when to use the Pathfinder (indication), (2) what it offers (e.g., initial approach to diagnosing chronic diarrhea) and (3) the source of information (e.g., 'based on CDC guidelines.') You can change the description any time.",
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'description',
      label: 'Description',
      placeholder: 'Enter Description',
      detail: 'Describes the indication, use (and source) of the content in the pathway',
      character_limit: 250,
    },
    {
      name: 'Pathfinder/ Name',
      help_text:
        "Name your pathfinder (e.g., initial management of ARDS). This 'name' is not shown to the users, and you can consider it as your personal description of the Pathfinder.",
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Enter Name',
      detail: 'Name of module',
      character_limit: 255,
    },
    {
      name: 'Pathfinder/ Title',
      help_text:
        'Display name is the title displayed in the app. The display name can be the same as the name or can be customized by unchecking the box on the right.',
      image: 'http://127.0.0.1:8000/media/Untitled.jpg',
      model_identifier: 'Pathfinder',
      field_identifier: 'title',
      label: 'Display Name',
      placeholder: 'Enter Title',
      detail: 'Title of the module displayed in the app',
      character_limit: 255,
    },
    {
      name: 'Pathfinder/ Detailed Description',
      help_text:
        'Users will have access to a more detailed description by clicking the description above. This is optional.',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'detailed_description',
      label: 'Note (Optional)',
      placeholder: 'Placeholder',
      detail: 'Notes about the module. (NOT viewable by users.)',
      character_limit: 140,
    },
    {
      name: 'Pathfinder/ Add Members',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'add_members',
      label: 'Add Members',
      placeholder: 'Add Members',
      detail: 'Add co-authors or reviewers.',
      character_limit: null,
    },
    {
      name: 'Pathfinder/ Description of Featured Authors',
      help_text:
        'The names and the titles of these authors will be exposed to users. Edit the authors in author management under the team management section.',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'description_of_featured_authors',
      label: 'Description: Featured Authors',
      placeholder: 'Description: Featured Authors',
      detail:
        'The names and the titles of these authors will be exposed to users. Edit the authors in author management under the team management section.',
      character_limit: null,
    },
    {
      name: 'Pathfinder/ Password',
      help_text:
        'The password can be used by any co-authors (or administrators of the channel) to sync the module.',
      image: 'http://127.0.0.1:8000/media/avoMD.jpg',
      model_identifier: 'Pathfinder',
      field_identifier: 'password',
      label: 'Password',
      placeholder: 'Enter Password',
      detail: 'Please assign a password to be able to release your module.',
      character_limit: 255,
    },
    {
      name: 'Pathfinder/ Security Level',
      help_text: 'Security Level of AvoAssistant',
      image: null,
      model_identifier: 'Pathfinder',
      field_identifier: 'security_level',
      label: 'Security Level',
      placeholder: 'Security Level',
      detail: 'Security Level of AvoAssistant',
      character_limit: 8,
    },
  ],
  InfoBox: [
    {
      name: 'InfoBox/ Shortened Title',
      help_text:
        'This is how the info button is named in the dashboard question flow. You can always double check it once you are looking at the flow, or in simulator',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'shortened_title',
      label: 'Display Name (in the algorithm)',
      placeholder: 'Placeholder',
      detail: 'Display Name of the infobox below the question.',
      character_limit: 100,
    },
    {
      name: 'InfoBox/ Text',
      help_text:
        'some helpful hints include: use ** if you want only some bullet points,\r\nuse :: after a line to bold it',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'text',
      label: 'Text',
      placeholder: 'Placeholder',
      detail:
        'All the info that goes in your info box. Please review your formatting in the app as it can get funky.',
      character_limit: 3000,
    },
    {
      name: 'InfoBox/ References',
      help_text:
        'Add references. If you don\'t see them here, they can always be created by going to "Resources" -> Reference Manager and creating a new reference.',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'references',
      label: 'References',
      placeholder: 'Placeholder',
      detail: 'Add references.',
      character_limit: null,
    },
    {
      name: 'InfoBox/ Title',
      help_text: 'this is the title as displayed on the infobox preview.',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Placeholder',
      detail: 'Title of the info box paragraph (In the Info card itself)',
      character_limit: 250,
    },
    {
      name: 'InfoBox/ URL Links',
      help_text: '',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'url_links',
      label: 'URL Links',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'InfoBox/ Subtitle',
      help_text: '',
      image: null,
      model_identifier: 'InfoBox',
      field_identifier: 'subtitle',
      label: 'Subtitle',
      placeholder: 'Enter Subtitle',
      detail: '',
      character_limit: 250,
    },
  ],
  Channel: [
    {
      name: 'Channel/ Algorithm',
      help_text: 'Add any modules that belong in the channel. Remove any irrelevant modules.',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'modules',
      label: 'ALGOs',
      placeholder: 'Placeholder',
      detail: 'Add or remove any relevant modules.',
      character_limit: null,
    },
    {
      name: 'Channel/ Invitation Code',
      help_text: 'Choose a password.',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'password',
      label: 'Password (Optional)',
      placeholder: 'Enter Password',
      detail:
        'To further protect your channel from outsiders accessing it, there is not only a code but also and password.',
      character_limit: 255,
    },
    {
      name: 'Channel/ Code',
      help_text: 'Lower case, single word/numbers with no spaces are recommended.',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'channel_code',
      label: 'Code',
      placeholder: 'Enter Code',
      detail:
        'Enter a code you can remember. This is how people will sign up for your channel manually.',
      character_limit: 100,
    },
    {
      name: 'Channel/ Name',
      help_text: 'This is the display title of your channel that will be visible to users.',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Enter Name',
      detail: 'The Title of your channel.',
      character_limit: 255,
    },
    {
      name: 'Channel/ Description',
      help_text:
        'Describe your channel, for example, "Inpatient Internal Medicine Guidelines for Avocados Medical Center".',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'description',
      label: 'Description',
      placeholder: 'Placeholder',
      detail: 'A few words about your channel.',
      character_limit: 250,
    },
    {
      name: 'Channel/ Calculators',
      help_text: '',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'calculators',
      label: 'Calculators',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Channel/ Is Institutional',
      help_text:
        'If you are writing on behalf of an institution check this off. If it is a personal or non-institutional channel, leave it unchecked.',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'is_institutional',
      label: 'Is Channel Institutional?',
      placeholder: 'Placeholder',
      detail: 'Check if channel is part of institutional content.',
      character_limit: null,
    },
    {
      name: 'Channel/ Is Generic',
      help_text: '',
      image: null,
      model_identifier: 'Channel',
      field_identifier: 'is_generic',
      label: 'Do you want to make the channel public globally?',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
  ],
  Team: [
    {
      name: 'Team/ Name',
      help_text:
        'This is the name of your builder team. You can invite collaborators to your team to join you in creating modules.',
      image: null,
      model_identifier: 'Team',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Placeholder',
      detail: 'Name of your Builder Team.',
      character_limit: 255,
    },
    {
      name: 'Team/ Invite Code',
      help_text: '',
      image: null,
      model_identifier: 'Team',
      field_identifier: 'invitation_code',
      label: 'Invitation  Code',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'Team/ Member',
      help_text: '',
      image: null,
      model_identifier: 'Team',
      field_identifier: 'member',
      label: 'Members',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Team/ Display Name',
      help_text: '',
      image: null,
      model_identifier: 'Team',
      field_identifier: 'display_name',
      label: 'Display Name',
      placeholder: 'Display name',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Team/ Icon',
      help_text: '',
      image: null,
      model_identifier: 'Team',
      field_identifier: 'icon',
      label: 'Team Icon',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
  ],
  Container: [
    {
      name: 'Container/ Title',
      help_text: '',
      image: null,
      model_identifier: 'Container',
      field_identifier: 'name',
      label: 'Title',
      placeholder: 'Enter Title',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Container/ Items',
      help_text: '',
      image: null,
      model_identifier: 'Container',
      field_identifier: 'items',
      label: 'Items',
      placeholder: 'Select Conversation Items',
      detail: '',
      character_limit: null,
    },
  ],
  Reference: [
    {
      name: 'Reference/ Source',
      help_text: 'This is the full citation in your desired format.',
      image: null,
      model_identifier: 'Reference',
      field_identifier: 'source',
      label: 'Source Description',
      placeholder: 'Enter Source Description',
      detail: 'The full citation.',
      character_limit: 1000,
    },
    {
      name: 'Reference/ Shortened Source',
      help_text: 'Consider a shortened source like JAMA, 2010',
      image: null,
      model_identifier: 'Reference',
      field_identifier: 'shortened_source',
      label: 'Display Text',
      placeholder: 'Enter Display Text',
      detail: 'How the reference is cited in the module',
      character_limit: 12,
    },
    {
      name: 'Reference/ URL',
      help_text: 'This can be a link to pubmed or to the original article',
      image: null,
      model_identifier: 'Reference',
      field_identifier: 'url',
      label: 'URL',
      placeholder: 'Enter URL',
      detail: 'Paste a direct link',
      character_limit: 250,
    },
  ],
  Trigger: [
    {
      name: 'Trigger/ Code',
      help_text: '',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'situation_code',
      label: 'Code',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'Trigger/ Shortened Name',
      help_text: 'Also only seen internally.',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'shortened_name',
      label: 'Shortened Name',
      placeholder: 'Placeholder',
      detail: 'A shortened name for your trigger.',
      character_limit: 100,
    },
    {
      name: 'Trigger/ Condition',
      help_text: 'Some key terms: == means “equivalent to”&& means “and” || means “or”.',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'condition',
      label: 'Condition',
      placeholder: 'Placeholder',
      detail: 'Use this if you want to manually change the trigger code.',
      character_limit: 255,
    },
    {
      name: 'Trigger/ Title',
      help_text: 'This is for your own internal use only.',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Placeholder',
      detail: 'Name your trigger.',
      character_limit: 255,
    },
    {
      name: 'Trigger/ Manual Condition',
      help_text: 'Some key terms: == means “equivalent to”&& means “and” || means “or”',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'mannual_condition',
      label: 'Manual Condition',
      placeholder: 'Placeholder',
      detail: 'Use this if you want to manually change the trigger code.',
      character_limit: null,
    },
    {
      name: 'Trigger/ Query Builder Condition',
      help_text: 'Some key terms: == means “equivalent to”&& means “and” || means “or”.',
      image: null,
      model_identifier: 'Trigger',
      field_identifier: 'query_builder_condition',
      label: 'Query Builder Condition',
      placeholder: 'Placeholder',
      detail: 'Use this if you want to manually change the trigger code.',
      character_limit: null,
    },
  ],
  Image: [
    {
      name: 'Image/ References',
      help_text: 'Create references in Reference manager and add them here',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'references',
      label: 'References',
      placeholder: 'Placeholder',
      detail: 'Add relevant references',
      character_limit: null,
    },
    {
      name: 'Image/ Caption',
      help_text: 'If you want text with your media',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'caption',
      label: 'Caption',
      placeholder: 'Placeholder',
      detail: 'A caption under your Media',
      character_limit: 1000,
    },
    {
      name: 'Image/ Representation Phrase',
      help_text:
        'You can double check this by navigating back to the dashboard flow or in simulator',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'representation_phrase',
      label: 'Representation Phrase',
      placeholder: 'Placeholder',
      detail: 'The title of your media card in the questions dashboard',
      character_limit: 100,
    },
    {
      name: 'Image/ Title',
      help_text:
        'This is the title of your card as displayed above your media (NOT the way it is displayed on your dashboard of questions.',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Placeholder',
      detail: 'Name your card (visible once you click the link)',
      character_limit: 250,
    },
    {
      name: 'Image/ Video URL',
      help_text: 'Upload videos as needed',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'video_url',
      label: 'YouTube URL',
      placeholder: 'Placeholder',
      detail: 'If a video is needed',
      character_limit: 500,
    },
    {
      name: 'Image/ Image',
      help_text:
        'Upload your media. Troubleshooting tips: If it is too large, it may not work. If so, try taking a screenshot and repeating the upload',
      image: null,
      model_identifier: 'Image',
      field_identifier: 'image',
      label: 'Media',
      placeholder: 'Placeholder',
      detail: 'Add your media here',
      character_limit: 100,
    },
  ],
  CustomNumeric: [
    {
      name: 'Custom Numeric/ Lower Limit',
      help_text: 'Ex. "4.5" for WBC',
      image: null,
      model_identifier: 'CustomNumeric',
      field_identifier: 'lower_limit',
      label: 'Lower Limit',
      placeholder: 'Lower Limit',
      detail: 'Lower value of normal',
      character_limit: null,
    },
    {
      name: 'Custom Numeric/ Unit',
      help_text: 'Ex. "× 10^9/L" or "mg/dL"',
      image: null,
      model_identifier: 'CustomNumeric',
      field_identifier: 'unit',
      label: 'Unit',
      placeholder: 'Unit',
      detail: 'The unit for the value',
      character_limit: 255,
    },
    {
      name: 'Custom Numeric/ Name',
      help_text: 'Ex. "WBC"',
      image: null,
      model_identifier: 'CustomNumeric',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Name',
      detail: 'Internal name of the numeric',
      character_limit: 255,
    },
    {
      name: 'Custom Numeric/ Upper Limit',
      help_text: 'Ex. "10.5" for WBC',
      image: null,
      model_identifier: 'CustomNumeric',
      field_identifier: 'upper_limit',
      label: 'Upper Limit',
      placeholder: 'Upper Limit',
      detail: 'Upper value of normal',
      character_limit: null,
    },
    {
      name: 'Custom Numeric/ Terminology Code',
      help_text: 'This allows for standardization as well as EHR integration',
      image: null,
      model_identifier: 'CustomNumeric',
      field_identifier: 'terminology_code',
      label: 'Terminology Code',
      placeholder: 'Terminology Code',
      detail: 'Insert the LOINC terminology code if known',
      character_limit: 255,
    },
  ],
  trigger_content: [
    {
      name: 'Team/ Name',
      help_text: '',
      image: null,
      model_identifier: 'trigger_content',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: null,
    },
  ],
  AuthorProfile: [
    {
      name: 'Featured Author/ Name',
      help_text: 'Name of Featured Author.',
      image: null,
      model_identifier: 'AuthorProfile',
      field_identifier: 'name',
      label: 'Featured Author Name',
      placeholder: 'Enter Featured Author Name',
      detail: 'Name of Author.',
      character_limit: 250,
    },
    {
      name: 'Featured Author/ Description',
      help_text: 'More details about the featured author.',
      image: null,
      model_identifier: 'AuthorProfile',
      field_identifier: 'description',
      label: 'Featured Author Description',
      placeholder: 'Enter Featured Author Description',
      detail: 'More info on the Author.',
      character_limit: 1000,
    },
    {
      name: 'Featured Author/ Image',
      help_text: '',
      image: null,
      model_identifier: 'AuthorProfile',
      field_identifier: 'image',
      label: 'Featured Author Image',
      placeholder: 'Add Featured Author Image',
      detail: '',
      character_limit: 100,
    },
  ],
  Calculator: [
    {
      name: 'Calculator/ Name',
      help_text:
        "Name your calculator (e.g., initial management of ARDS). This 'name' is not shown to the users, and you can consider it as your personal description of the Pathfinder.",
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'name',
      label: 'Name',
      placeholder: 'Enter Name',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Calculator/ Note (Optional)',
      help_text: '',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'detailed_description',
      label: 'Note (Optional)',
      placeholder: 'Placeholder',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'Calculator/ Co-Authors',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'coauthors',
      label: 'Co-Authors',
      placeholder: 'Select Co-Authors',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Calculator/ Reviewers',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'reviewers',
      label: 'Reviewers',
      placeholder: 'Select Reviewers',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Calculator/ Description of Featured Authors',
      help_text:
        'The names and the titles of these authors will be exposed to users. Edit the authors in author management under the team management section.',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'description_of_featured_authors',
      label: 'Description: Featured Authors',
      placeholder: 'Description: Featured Authors',
      detail:
        'The names and the titles of these authors will be exposed to users. Edit the authors in author management under the team management section.',
      character_limit: null,
    },
    {
      name: 'Calculator/ Title',
      help_text:
        'The public title is the Calculator’s name exposed to the users. The title can be the same with the name. No worries. You can change the title anytime.',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Enter Title',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Calculator/ Password',
      help_text: '',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'password',
      label: 'Password',
      placeholder: 'Enter Password',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Calculator/ Description',
      help_text: '',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'description',
      label: 'Description',
      placeholder: 'Enter Description',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'Calculator/ Add Members',
      help_text:
        'The authors/reviewers should be part of your team. No worries. You can update this later.',
      image: null,
      model_identifier: 'Calculator',
      field_identifier: 'add_members',
      label: 'Add Members',
      placeholder: 'Add Members',
      detail: '',
      character_limit: null,
    },
  ],
  ConditionalText: [
    {
      name: 'ConditionalText / Default Text',
      help_text: '',
      image: null,
      model_identifier: 'ConditionalText',
      field_identifier: 'default_text',
      label: 'Otherwise, the following text will be added (Optional)',
      placeholder: 'Default Text',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'ConditionalText / Condition Text',
      help_text: '',
      image: null,
      model_identifier: 'ConditionalText',
      field_identifier: 'conditional_text',
      label: 'Following text will be added:',
      placeholder: 'Enter Text',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'ConditionalText / Condition',
      help_text: '',
      image: null,
      model_identifier: 'ConditionalText',
      field_identifier: 'condition',
      label: 'If the following condition is met:',
      placeholder: 'Condition',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'ConditionalText / Name',
      help_text: '',
      image: null,
      model_identifier: 'ConditionalText',
      field_identifier: 'name',
      label: 'Conditional Text Name',
      placeholder: 'Conditional Text Name',
      detail: '',
      character_limit: 255,
    },
  ],
  Output: [
    {
      name: 'Output / Name',
      help_text: '',
      image: null,
      model_identifier: 'Output',
      field_identifier: 'name',
      label: 'Output Name',
      placeholder: 'Output Name',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'Output / Condition',
      help_text: '',
      image: null,
      model_identifier: 'Output',
      field_identifier: 'condition',
      label: 'Mandatory Condition (Optional)',
      placeholder: 'Type # for commands',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'Output / Output Type',
      help_text: '',
      image: null,
      model_identifier: 'Output',
      field_identifier: 'output_type',
      label: 'Output Type',
      placeholder: 'Output Type',
      detail: '',
      character_limit: 2,
    },
    {
      name: 'Output / Output Value',
      help_text: '',
      image: null,
      model_identifier: 'Output',
      field_identifier: 'output_value',
      label: 'Output Value (Numerical)',
      placeholder: 'Output Value (Numerical)',
      detail: '',
      character_limit: null,
    },
    {
      name: 'Output / Output Category',
      help_text: '',
      image: null,
      model_identifier: 'Output',
      field_identifier: 'output_categories',
      label: 'Output Category',
      placeholder: 'Output Category',
      detail: '',
      character_limit: null,
    },
  ],
  TextInput: [
    {
      name: 'TextInput / Variable Name',
      help_text: '',
      image: null,
      model_identifier: 'TextInput',
      field_identifier: 'variable_name',
      label: 'Variable Name',
      placeholder: 'Variable Name',
      detail: '',
      character_limit: 100,
    },
    {
      name: 'TextInput / Title',
      help_text: '',
      image: null,
      model_identifier: 'TextInput',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Title',
      detail: '',
      character_limit: 255,
    },
    {
      name: 'TextInput / Subtitle',
      help_text: '',
      image: null,
      model_identifier: 'TextInput',
      field_identifier: 'subtitle',
      label: 'Subtitle',
      placeholder: 'e.g. severe illness requires a stronger antibiotic',
      detail: '',
      character_limit: 500,
    },
  ],
  GPTBox: [
    {
      name: 'GPTBox / Prompt',
      help_text: '',
      image: null,
      model_identifier: 'GPTBox',
      field_identifier: 'prompt',
      label: 'Prompt',
      placeholder: 'Prompt',
      detail: '',
      character_limit: 1000,
    },
    {
      name: 'GPTBox / Title',
      help_text: '',
      image: null,
      model_identifier: 'GPTBox',
      field_identifier: 'title',
      label: 'Title',
      placeholder: 'Title',
      detail: '',
      character_limit: 255,
    },
  ],
};
