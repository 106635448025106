import { ResourceTypes, UIElement } from '../../../../../api/moduleElement';
import { AnswerElementObject } from '../../../module/UiElementContext';
import { ConditionalText, Formula, Infobox, Media, Trigger } from '../../../../../api/resources';
import { convertFromJSON } from '../convertFromJSON';
import { DraftJSRawType } from '../../../../../v2/types/draftJSType';
import { JSONContent } from '@tiptap/react';

interface Id {
  id: number;
}

interface UielementToTiptapDefault extends Id {
  type: 'IntroductionPanel' | 'DescriptionPanel' | 'GPTBox' | 'TextInput';
}

interface IntroductionPanelToTiptap extends UielementToTiptapDefault {
  desc_json_tiptap: JSONContent | null;
}

interface DescriptionPanelToTiptap extends UielementToTiptapDefault {
  short_desc_json_tiptap: JSONContent | null;
  long_desc_json_tiptap: JSONContent | null;
}

interface TextInputToTiptap extends UielementToTiptapDefault {
  default_value_tiptap: JSONContent | null;
}

interface GPTBoxToTiptap extends UielementToTiptapDefault {
  prompt_json_tiptap: JSONContent | null;
}

type UiElementToTiptap =
  | IntroductionPanelToTiptap
  | TextInputToTiptap
  | DescriptionPanelToTiptap
  | GPTBoxToTiptap;

interface AnswerElementToTiptap extends Id {
  card_text_json_tiptap: JSONContent | null;
}

interface TriggerToTiptap extends Id {
  trigger_content_tiptap: JSONContent | null;
}

interface InfoBoxToTiptap extends Id {
  text_json_tiptap: JSONContent | null;
}

interface ImageToTiptap extends Id {
  caption_json_tiptap: JSONContent | null;
}

interface FormulaToTiptap extends Id {
  text_json_tiptap?: JSONContent | null;
  formula_json_tiptap?: JSONContent | null;
  conditional_formulas?: {
    id: number;
    formula_text_json_tiptap: JSONContent | null;
  }[];
}

interface ConditionalTextInputToTiptap extends Id {
  conditions: {
    id: number;
    text_content_tiptap: JSONContent | null;
  }[];
}

export interface ConversionPayload {
  module: number;
  ui_elements: UiElementToTiptap[];
  answer_elements: AnswerElementToTiptap[];
  triggers: TriggerToTiptap[];
  infoboxes: InfoBoxToTiptap[];
  images: ImageToTiptap[];
  formulas: FormulaToTiptap[];
  conditional_texts: ConditionalTextInputToTiptap[];
}

export interface ConvertingItems {
  moduleId: number;
  uiElements: UIElement[];
  answerElements: AnswerElementObject;
  triggers: Trigger[];
  infoboxes: Infobox[];
  formulas: Formula[];
  conditionalTexts: ConditionalText[];
  images: Media[];
}

export function getPayload({
  moduleId,
  uiElements,
  answerElements,
  triggers,
  infoboxes,
  formulas,
  conditionalTexts,
  images,
}: {
  moduleId: number;
  uiElements: UIElement[];
  answerElements: AnswerElementObject;
  triggers: Trigger[];
  infoboxes: Infobox[];
  formulas: Formula[];
  conditionalTexts: ConditionalText[];
  images: Media[];
}): ConversionPayload {
  const convertedUiElements: UiElementToTiptap[] = makeUiElementConvertingArray(uiElements);
  const convertedAnswerElements = Object.keys(answerElements).reduce(
    (result: AnswerElementToTiptap[], index) => {
      const convertedArray = answerElements[index].answerCards.map((answerCard) => ({
        id: answerCard.id,
        card_text_json_tiptap: convertFromJSON(answerCard.new_card_text_content).document,
      }));
      return [...result, ...convertedArray];
    },
    []
  );
  const convertedTriggers = triggers.reduce((result: TriggerToTiptap[], trigger) => {
    if (trigger.new_trigger_content) {
      result.push({
        id: Number(trigger.id),
        trigger_content_tiptap: convertFromJSON(trigger.new_trigger_content as DraftJSRawType)
          .document,
      });
    }
    return result;
  }, []);
  const convertedInfoboxes = infoboxes.reduce((result: InfoBoxToTiptap[], infobox) => {
    if (infobox.new_text_content) {
      result.push({
        id: Number(infobox.id),
        text_json_tiptap: convertFromJSON(infobox.new_text_content as DraftJSRawType).document,
      });
    }
    return result;
  }, []);
  const convertedFormulas = formulas.reduce((result: FormulaToTiptap[], formula) => {
    if (
      formula.new_formula_content ||
      formula.new_text_content ||
      (formula.conditional_formulas && formula.conditional_formulas.length > 0)
    ) {
      result.push({
        id: Number(formula.id),
        formula_json_tiptap: formula.new_formula_content
          ? convertFromJSON(formula.new_formula_content as DraftJSRawType).document
          : undefined,
        text_json_tiptap: formula.new_text_content
          ? convertFromJSON(formula.new_text_content as DraftJSRawType).document
          : undefined,
        conditional_formulas:
          formula.conditional_formulas && formula.conditional_formulas.length > 0
            ? formula.conditional_formulas.map((conditionalFormula) => ({
                id: conditionalFormula.id as number,
                formula_text_json_tiptap: convertFromJSON(
                  conditionalFormula.new_formula_text_json as DraftJSRawType
                ).document,
              }))
            : undefined,
      });
    }
    return result;
  }, []);

  const convertedConditionalTexts = conditionalTexts.reduce(
    (result: ConditionalTextInputToTiptap[], conditionalText) => {
      const convertedConditions = conditionalText.conditions.map((condition) => ({
        id: condition.id as number,
        text_content_tiptap: convertFromJSON(condition.text_content as DraftJSRawType).document,
      }));

      result.push({
        id: Number(conditionalText.id),
        conditions: convertedConditions,
      });

      return result;
    },
    []
  );

  const convertedImages = images.reduce((result: ImageToTiptap[], image) => {
    if (image.new_caption_content) {
      result.push({
        id: Number(image.id),
        caption_json_tiptap: convertFromJSON(image.new_caption_content).document,
      });
    }
    return result;
  }, []);

  return {
    module: moduleId,
    ui_elements: convertedUiElements,
    answer_elements: convertedAnswerElements,
    triggers: convertedTriggers,
    infoboxes: convertedInfoboxes,
    formulas: convertedFormulas,
    conditional_texts: convertedConditionalTexts,
    images: convertedImages,
  };
}

function makeUiElementConvertingArray(uiElements: UIElement[]) {
  return uiElements.reduce((result: UiElementToTiptap[], uiElement) => {
    if (!!uiElement.ui_elements && uiElement.ui_elements.length > 0) {
      const convertedData = makeUiElementConvertingArray(uiElement.ui_elements);
      return [...result, ...convertedData];
    }
    const convertedData = getTiptapConvertingData(uiElement);
    if (!!convertedData) {
      result.push(convertedData);
    }
    return result;
  }, []);
}

function getTiptapConvertingData(uiElement: UIElement): UiElementToTiptap | undefined {
  switch (uiElement.resourcetype) {
    case ResourceTypes.message:
      if (uiElement.panel_type === 'O') {
        return {
          id: uiElement.id,
          type: 'IntroductionPanel',
          desc_json_tiptap: convertFromJSON(uiElement.new_description_text_content).document,
        };
      } else {
        return {
          id: uiElement.id,
          type: ResourceTypes.message,
          short_desc_json_tiptap: convertFromJSON(uiElement.new_description_text_content_short)
            .document,
          long_desc_json_tiptap: convertFromJSON(uiElement.new_description_text_content_long)
            .document,
        };
      }
    case ResourceTypes.gptBox:
      return {
        id: uiElement.id,
        type: ResourceTypes.gptBox,
        prompt_json_tiptap: convertFromJSON(uiElement.prompt_json).document,
      };
    case ResourceTypes.textInput:
      return {
        id: uiElement.id,
        type: ResourceTypes.textInput,
        default_value_tiptap: convertFromJSON(uiElement.default_value).document,
      };
  }
}
