import Button from '../Button';
import LoadingSpinner from '../../loader/LoadingSpinner';
import { SAVE_BUTTON } from '../../../constants';
import { twMerge } from 'tailwind-merge';

interface FormFooterProps {
  onClose: () => void;
  disabled: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  className?: string;
  saveButtonLabel?: string;
}

// TODO: z-index to constant
// TODO: need to change name or something? (because it might cause confusion in use)
export const Footer = ({
  onClose,
  disabled,
  isLoading,
  onClick,
  className,
  saveButtonLabel,
}: FormFooterProps) => {
  return (
    <div
      className={twMerge(
        'sticky bottom-0 right-0 z-[9] flex w-full items-center justify-end bg-white px-[12px] pb-[22px]',
        className
      )}
    >
      <div className='flex gap-3'>
        <Button.Reverse onClick={onClose} className='!px-2 !py-2.5 font-bold' type='button'>
          Cancel
        </Button.Reverse>
        <Button
          type={!!onClick ? 'button' : 'submit'}
          className='!px-6 !py-2.5 font-bold'
          disabled={disabled}
          onClick={onClick}
        >
          {isLoading ? <LoadingSpinner size='small' /> : saveButtonLabel ?? SAVE_BUTTON}
        </Button>
      </div>
    </div>
  );
};
