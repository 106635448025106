import { compareCardPosition } from 'components/utils/general/compareCardPosition';

export const calculatePositionOfNewChildCard = (uiElements, uiElemCount) => {
  if (Array.isArray(uiElements) && uiElements.length > 0) {
    let childCards = [...uiElements];
    childCards.sort(compareCardPosition);
    return childCards[childCards.length - 1].position + 1;
  } else {
    return uiElemCount;
  }
};

export const selectOrRemoveGroupItem = (uiElementOptions, selectedItem, isRemoved = false) => {
  if (isRemoved) {
    const existingIds = new Set(uiElementOptions.map((item) => item.id));
    // Add the removed item back to uiElementOptions state if not exit already
    if (!existingIds.has(selectedItem.id)) {
      uiElementOptions = [
        ...uiElementOptions,
        {
          title: selectedItem.title,
          id: selectedItem.id,
        },
      ];
    }
  }
  return uiElementOptions;
};

export const getGroupVariables = (uiElements: any[]) => {
  uiElements.sort((a, b) => a.position - b.position);
  return uiElements
    .flatMap((uiElement) => {
      let variable = { name: uiElement.variable_item?.name ?? uiElement.variable_name };
      switch (uiElement.resourcetype) {
        case 'ChoicePanel':
          variable['type'] = 'choice';
          break;
        case 'MultiChoicePanel':
          variable['type'] = 'checkbox';
          break;
        case 'TextInput':
          variable['type'] = 'text';
          break;
        case 'GPTBox':
          variable['type'] = 'gpt';
          break;
        case 'DescriptionPanel':
          variable['type'] = 'message';
          break;
        case 'AmbientListening':
          variable['type'] = 'Scribe';
          break;
        case 'DataExtractor':
          variable['type'] = 'Data Extractor';
          break;
        case 'ValuePanel':
          return uiElement?.panel_item?.map((item) => ({
            type: 'number',
            name: item.title || item.name,
          }));
        default:
          break;
      }
      return variable;
    })
    .filter((variable) => !!variable);
};
