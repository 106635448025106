import { Container, Divider, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledContainer = withStyles((theme) => ({
  root: {
    paddingTop: '10px',
    borderRadius: '10px',
    width: '70%',

    marginTop: '40px',
    color: 'black',
  },
}))(Container);

export const StyledTable = withStyles((theme) => ({
  root: { width: '100%', display: 'table' },
}))(Container);

export const StyledDivider = withStyles((theme) => ({
  root: {
    padding: '0px',
    marginTop: '20px',
    marginBottom: '20px',
  },
}))(Divider);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    textAlign: 'center',
    backgroundColor: '#08A88E',
    color: 'white',
    width: 130,
    fontSize: 18,

    '&:hover': {
      background: 'grey',
    },
  },
  body: {
    textAlign: 'center',
    fontSize: 15,
    width: 130,
  },
}))(TableCell);

export const StyledTableCellIcon = withStyles((theme) => ({
  body: {
    fontSize: 16,
    textAlign: 'center',
  },
  root: {
    '&:hover': {
      background: 'white',
      border: '1px solid white',
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#D8D8D8',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#E4E4E4',
    },
    '&:hover': {
      background: '#F5F5F5	',
      color: 'white',
    },
  },
}))(TableRow);
