import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

export const DropdownIcon = (props) => (
  <ArrowDropDown
    style={{ color: '#6C7C83', fill: '#6C7C83', marginRight: 10, marginLeft: 'auto' }}
    {...props}
  />
);

export const DropUpIcon = (props) => (
  <ArrowDropUp
    style={{ color: '#6C7C83', fill: '#6C7C83', marginRight: 10, marginLeft: 'auto' }}
    {...props}
  />
);
