import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { LOGOUT_ACTION, RESET_STATE } from '../actions/actionsTypes';
import knowledgeBaseReducer from './main/knowledgeBaseReducer';
import mirrorReducer from './main/mirrorReducer';
import modulesReducer from './main/modulesReducer';
import teamCalculatorReducer from './main/teamCalculatorReducer';
import formHelperReducer from './miscellaneous/formHelperReducer';
import pathReducer from './miscellaneous/pathReducer';
import calculatorsReducer from './resources/calculatorsReducer';
import conditionalTextReducer from './resources/conditionalTextReducer';
import customNumericReducer from './resources/customNumericReducer';
import formulasReducer from './resources/formulasReducer';
import infoboxReducer from './resources/infoboxReducer';
import mediaReducer from './resources/mediaReducer';
import noteGeneratorReducer from './resources/noteGeneratorReducer';
import refManagerReducer from './resources/refmanagerReducer';
import triggerReducer from './resources/triggerReducer';
import uniqueCodesReducer from './resources/uniqueCodesReducer';

const appReducer = combineReducers({
  knowledgeBaseState: knowledgeBaseReducer,
  modulesState: modulesReducer,
  prevpathState: pathReducer,
  infoboxState: infoboxReducer,
  noteGeneratorState: noteGeneratorReducer,
  triggerState: triggerReducer,
  refManagerState: refManagerReducer,
  formulaState: formulasReducer,
  mediaState: mediaReducer,
  calculatorState: calculatorsReducer,
  formState: formHelperReducer,
  customNumericState: customNumericReducer,
  teamCalculatorState: teamCalculatorReducer,
  conditionalTextState: conditionalTextReducer,
  uniqueCodesState: uniqueCodesReducer,
  mirrorState: mirrorReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_ACTION || action.type === RESET_STATE) {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
