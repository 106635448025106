import { MainLayout } from 'components/layout/MainLayout';
import { EHROrderIndividualTable } from '../../components/ehr/ehr-order';

export const EHROrderIndividual = () => {
  return (
    <MainLayout className='bg-gray-100'>
      <EHROrderIndividualTable />
    </MainLayout>
  );
};
