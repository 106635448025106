export default function () {
  return (
    <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.9168 3.46107e-08L0.125 0.838357L5 6L9.875 0.838358L9.0832 3.91575e-07L5 4.32302L0.9168 3.46107e-08Z'
        fill='#6C7C83'
      />
    </svg>
  );
}
