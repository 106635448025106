import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import {
  LOGIN_API_V2_URL,
  MY_INFO_API_URL,
  SIGNOUT_API_V2_URL,
  SIGNUP_API_V2_URL,
  USER_INFO_API_URL,
} from '../constants';
import { TinyUser, User } from '../hooks/useAuthentication';

interface UserInfo {
  id: number;
  email: string;
  name: string;
  profile_picture: string | null;
  is_staff: boolean;
  user_type: string;
}

interface SignInRequest {
  email?: string;
  password?: string;
  googleAuthObject?: GoogleAuthObject;
}

interface GoogleAuthObject {
  profileObj: unknown;
}

export interface SignUpRequest {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  job_title: string;
  institution: string;
  role: string[];
  medical_specialties: string[];
  subscribed_to_newsletter: boolean;
  invite_code?: string;
}

export const userAPI = {
  getUserInfo: ({ queryKey }: QueryFunctionContext) =>
    axios.get<UserInfo>(USER_INFO_API_URL).then((response) => response.data),
  getUserFullInfo: ({ queryKey }: QueryFunctionContext): Promise<User> =>
    axios.get<Omit<User, 'teams'>>(USER_INFO_API_URL).then((response) => response.data),
  getMe: () => axios.get<TinyUser>(MY_INFO_API_URL).then((response) => response.data),
  signIn: (request: SignInRequest) =>
    axios.post(LOGIN_API_V2_URL, request).then((response) => response.data),
  signUp: (request: SignUpRequest) =>
    axios.post(`${SIGNUP_API_V2_URL}`, request).then((response) => response.data),
  logout: () => axios.post(SIGNOUT_API_V2_URL),
};
