import { ITagNode } from 'api/tag';
import ArrowDownSleek from 'assets/icons/ArrowDownSleek';
import clsx from 'clsx';
import Checkbox from 'components/utils/Checkbox';
import Label from 'components/utils/Label';
import { useState } from 'react';

interface TagNodeProps {
  tag: ITagNode;
  onChange?: (code: string, checked: boolean) => void;
  selectedTags?: string[];
}
const TagSelectionDropdownItem = ({ tag, onChange, selectedTags }: TagNodeProps) => {
  const hasChildren = tag.children.length > 0;
  const [open, setOpen] = useState(false);
  const openChildren = () => {
    if (!hasChildren) return;
    setOpen((prev) => !prev);
  };

  return (
    <>
      {!tag.hidden && (
        <div className='ml-6 relative'>
          {hasChildren && (
            <div
              className={clsx(
                'py-2 px-2 top-1/2 left-0 -translate-y-1/2 cursor-pointer -ml-7 transition-all absolute',
                {
                  '-rotate-90': !open,
                }
              )}
              onClick={openChildren}
            >
              <ArrowDownSleek />
            </div>
          )}
          <div className={clsx({ 'font-bold': tag.read_only })}>
            {tag.read_only ? (
              <span className='cursor-pointer' onClick={openChildren}>
                {tag.title}
              </span>
            ) : (
              <Label className='inline-flex space-x-3 items-center'>
                {!tag.read_only && (
                  <Checkbox
                    onChange={(e) => onChange?.(tag.code, e.currentTarget.checked)}
                    defaultChecked={selectedTags?.includes(tag.code)}
                  />
                )}
                <span>{tag.title}</span>
              </Label>
            )}
          </div>
        </div>
      )}
      {(open || tag.hidden) && (
        <div className={clsx({ 'ml-4': !tag.hidden })}>
          {tag.children.map((child, idx) => (
            <TagSelectionDropdownItem
              tag={child}
              key={child.code}
              onChange={onChange}
              selectedTags={selectedTags}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TagSelectionDropdownItem;
