import clsx from 'clsx';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Select from 'react-select';
import { ReactComponent as ArrowBack } from '../../../assets/icons/arrowBack.svg';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { SignupFormData } from './Signup';
import { MultiSelectOption, ROLE_OPTIONS, SPECIALTIES_OPTIONS } from '../../../constants';

const DropdownIndicator = (open = false) => {
  return (
    <motion.div animate={{ rotate: open ? 180 : 360 }}>
      <Caret />
    </motion.div>
  );
};

interface MultiSelectorProps {
  options: MultiSelectOption[];
  name: keyof SignupFormData;
  setValue: UseFormSetValue<SignupFormData>;
}
const MultiSelector = ({ options, name, setValue }: MultiSelectorProps) => {
  const [open, setOpen] = useState(false);
  const onChange = (list) => {
    const selectValue = list.map((ele) => ele.value);
    setValue(name, selectValue);
  };
  return (
    <Select
      onMenuOpen={() => setOpen(true)}
      onMenuClose={() => setOpen(false)}
      options={options}
      isMulti
      placeholder=''
      closeMenuOnSelect={false}
      isClearable={false}
      components={{
        DropdownIndicator: () => DropdownIndicator(open),
        IndicatorSeparator: (d) => null,
      }}
      classNames={{
        control: () => '!rounded py-[4px] px-[12px] !border-gray-300',
        input: () => 'h-[24px] overflow-hidden m-0 p-0 [&>input]:h-auto',
        valueContainer: (props) => 'p-0 m-0',
        option: (state) =>
          clsx('text-gray-900 text-body-2', state.isFocused ? 'bg-primary-200' : ''),
        multiValue: () => 'text-body-2 !bg-gray-100 text-gray-700',
      }}
      onChange={onChange}
    />
  );
};

interface SecondPageProps {
  prevPage: () => void;
  register: UseFormRegister<SignupFormData>;
  done: boolean;
  defaultNewsletter: boolean;
  setValue: UseFormSetValue<SignupFormData>;
  loading: boolean;
}

const SecondPage = ({
  prevPage,
  register,
  done,
  defaultNewsletter,
  setValue,
  loading,
}: SecondPageProps) => {
  return (
    <div className='space-y-[12px]'>
      <div>
        <div className='flex cursor-pointer items-center space-x-[8px]' onClick={prevPage}>
          <ArrowBack />
          <span className='text-heading-5 font-semibold'>Previous Step</span>
        </div>
        <div className='mt-[24px] space-y-[8px]'>
          <Label className='w-full space-y-[4px]'>
            <span className='text-body-2 text-gray-700'>Job Title</span>
            <Input {...register('jobTitle', { required: true })} />
          </Label>
          <Label className='w-full space-y-[4px]'>
            <span className='text-body-2 text-gray-700'>Institution</span>
            <Input {...register('institution', { required: true })} />
          </Label>
          <Label className='w-full space-y-[4px]'>
            <span className='text-body-2 text-gray-700'>Role</span>
            <MultiSelector options={ROLE_OPTIONS} setValue={setValue} name='role' />
          </Label>
          <Label className='w-full space-y-[4px]'>
            <span className='text-body-2 text-gray-700'>Medical Specialities</span>
            <MultiSelector
              options={SPECIALTIES_OPTIONS}
              setValue={setValue}
              name='medicalSpecialties'
            />
          </Label>
        </div>
      </div>
      <Label className='flex cursor-pointer items-center space-x-[8px]'>
        <Checkbox type='checkbox' defaultChecked={defaultNewsletter} {...register('newsletter')} />
        <span className='text-sm text-gray-500'>Subscribe to our newsletter</span>
      </Label>
      <Button disabled={!done || loading}>{loading ? 'Signing up...' : 'Sign up'}</Button>
      <div className='text-caption-2 text-gray-700'>
        By clicking Sign Up, you agree to our{' '}
        <a href='https://www.avomd.io/termsofuse' target='_blank'>
          <span className='underline'>Terms of Use</span>
        </a>{' '}
        and acknowledge the{' '}
        <a href='https://www.avomd.io/privacypolicy' target='_blank'>
          <span className='underline'>Privacy Policy.</span>
        </a>
      </div>
    </div>
  );
};

export default SecondPage;
