import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import axios from 'axios';
import { Component } from 'react';
import '../modals/modal.css';

import { CALC_SYNC_WARNINGS_API_URL, SYNC_WARNINGS_API_URL } from 'constants';

const StyledCardHeader = withStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: '25px',
    marginBottom: '15px',
  },
  subheader: {
    color: '#636363',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '25px',
    marginBottom: '25px',
  },
}))(CardHeader);

const Warning = withStyles((theme) => ({
  root: {
    color: ' #C49853;',
    boxShadow: 'none',
    borderBottom: '1px solid #EDEDED',
  },
}))(Card);

const Success = withStyles((theme) => ({
  root: {
    color: '#08A88E',
    boxShadow: 'none',
    borderBottom: '1px solid #EDEDED',
  },
}))(Card);

const Error = withStyles((theme) => ({
  root: {
    color: '#FF0000',
    boxShadow: 'none',
    borderBottom: '1px solid #EDEDED',
  },
}))(Card);

class SyncWarnings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      syncWarnings: {},
      noError: false,
    };
  }

  componentWillMount() {
    // no sync warnings for AI knowledge base for now
    if (this.props.isAIKnowledgeBase) {
      this.props.triggerSuccess();
      return;
    }

    const API_URL = this.props.isCalc ? CALC_SYNC_WARNINGS_API_URL : SYNC_WARNINGS_API_URL;
    axios.get(API_URL + this.props.moduleId).then((res) => {
      this.setState({ syncWarnings: res.data });
      if (res.data.success.length === 1) {
        this.props.triggerSuccess();
      }
      if (res.data.errors.length !== 0) {
        this.props.errorOccurred();
      }
    });
  }

  render() {
    const success = this.state.syncWarnings.success || [];
    const warnings = this.state.syncWarnings.warnings || [];
    const errors = this.state.syncWarnings.errors || [];

    return (
      <div
        style={{ maxHeight: 450, overflowY: 'scroll', paddingBottom: '130px' }}
        className='m-body mt-5'
      >
        {/* success messages */}

        {success.map((item, index) => (
          <Fade in={true}>
            <Success key={index} style={{ marginBottom: 10, marginRight: 8 }}>
              <StyledCardHeader
                avatar={<CheckCircleOutlineIcon />}
                title={item.title}
                subheader={item.message}
              ></StyledCardHeader>
            </Success>
          </Fade>
        ))}

        {/* warnings */}
        {warnings.map((item, index) => (
          <Fade in={true}>
            <Warning
              key={index}
              style={{ marginBottom: '30px', marginRight: 8 }}
              // variant="outlined"
            >
              <StyledCardHeader
                avatar={<ErrorOutlineIcon />}
                title={item.title}
                subheader={item.message}
              ></StyledCardHeader>
            </Warning>
          </Fade>
        ))}

        {/* error */}
        {errors.map((item, index) => (
          <Fade in={true}>
            <Error key={index} style={{ marginBottom: 10, marginRight: 8 }}>
              <StyledCardHeader
                avatar={<WarningIcon />}
                title={item.title}
                subheader={item.message}
              ></StyledCardHeader>
            </Error>
          </Fade>
        ))}
      </div>
    );
  }
}

export default SyncWarnings;
