import {
  GET_CALCULATOR,
  GET_TEAM_CALCULATORS,
  GET_UNIVERSAL_CALCULATORS,
  SET_IS_CALC_MIRROR,
} from 'actions/actionsTypes';

const teamCalculatorReducer = (
  state = {
    calculator: {},
    teamCalculators: [],
    universalCalculators: [],
  },
  action
) => {
  switch (action.type) {
    case GET_TEAM_CALCULATORS:
      return {
        ...state,
        teamCalculators: action.payload,
      };

    case GET_UNIVERSAL_CALCULATORS:
      return {
        ...state,
        universalCalculators: action.payload,
      };

    case GET_CALCULATOR:
      return {
        ...state,
        calculator: {
          ...action.payload,
          isMirror: state.calculator.isMirror,
          mirrorId: state.calculator.mirrorId,
          mirrorName: state.calculator?.mirrorName,
        },
      };

    case SET_IS_CALC_MIRROR:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isMirror: action.isMirror,
          mirrorId: action.mirrorId,
          mirrorName: action.mirrorName,
        },
      };

    default:
      return state;
  }
};

export default teamCalculatorReducer;
