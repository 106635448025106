// import { Backdrop, Modal } from '@material-ui/core';
import TeamSettingForm from 'components/teams/teamSettingForm';
import { Modal } from 'components/utils/modals/Modal';

interface ITeamSettingModal {
  open: boolean;
  onClose: () => void;
}

const TeamSettingModal = ({ open, onClose }: ITeamSettingModal) => {
  return (
    <Modal open={open} onClose={onClose} className=''>
      <Modal.Head onClose={onClose}>Team Setting</Modal.Head>
      <Modal.Body>
        <TeamSettingForm onCancel={onClose} onSuccess={onClose} isEditing />
      </Modal.Body>
    </Modal>
  );
};

export default TeamSettingModal;
