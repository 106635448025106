import { SVGProps } from 'react';

export const AlgoIcon = ({ fill = '#08A88E', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 8L12 12' stroke='#C5D1D8' strokeLinejoin='round' />
      <path d='M5 16V12H19V16' stroke='#C5D1D8' strokeLinejoin='round' />
      <path
        d='M6.00778 6L12 2.57588L17.9922 6L12 9.42412L6.00778 6ZM18.1442 6.08682C18.1441 6.08678 18.144 6.08673 18.1439 6.08668L18.1442 6.08682ZM12.0494 2.54762L12.0496 2.54752L12.0494 2.54762Z'
        fill='#6C7C83'
        stroke='#6C7C83'
      />
      <mask id='path-4-inside-1_4280_67678' fill='white'>
        <rect x='1' y='15' width='10' height='7' rx='0.2' />
      </mask>
      <rect
        x='1'
        y='15'
        width='10'
        height='7'
        rx='0.2'
        fill='#6C7C83'
        stroke='#6C7C83'
        strokeWidth='2'
        mask='url(#path-4-inside-1_4280_67678)'
      />
      <mask id='path-5-inside-2_4280_67678' fill='white'>
        <rect x='13' y='15' width='10' height='7' rx='0.2' />
      </mask>
      <rect
        x='13'
        y='15'
        width='10'
        height='7'
        rx='0.2'
        fill='#6C7C83'
        stroke='#6C7C83'
        strokeWidth='2'
        mask='url(#path-5-inside-2_4280_67678)'
      />
    </svg>
  );
};
