import Input from 'components/utils/Input';
import { useEffect, useState } from 'react';
import { SuggestionHandlerProps } from '../MentionPopover';

export const ContactNumberHandler = ({ onChange, onDisabledChange }: SuggestionHandlerProps) => {
  const [contactNo, setContactNo] = useState('');

  useEffect(() => {
    if (!contactNo) {
      onDisabledChange(true);
      return;
    }
    onChange({ code: 'phoneNumber', name: contactNo, type: 'phone_number' });
    onDisabledChange(false);
  }, [contactNo]);

  return (
    <>
      <Input
        type='number'
        value={contactNo}
        onChange={(event) => setContactNo(event.target.value)}
        placeholder='Insert phone number'
        className='text-body-2 text-gray-900 focus:outline-none'
      />
    </>
  );
};
