import { useMutation, useQuery } from '@tanstack/react-query';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { SlidePanel } from 'components/utils/panels/SlidePanel';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInfoBoxes } from '../../../actions/resources/infoBoxes';
import { Infobox, Media, resourcesAPI } from '../../../api/resources';
import { MODULE_TYPES } from '../../../constants';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import ImageForm from '../image-Infobox/imageForm';
import { FloatingActionButton } from '../util/FloatingActionButton';
import InfoBoxForm from './infoBoxForm';

interface RequiredResourceFormProps {
  infoBoxId?: string;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  isFullHeight: boolean;
  closeUiElementPane: () => void;
  modal: boolean;
  savePosition: () => void;
}

interface ResourceMediaFormProps {
  imageId?: string;
  isFullHeight: boolean;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  closeUiElementPane: () => void;
  modal: boolean;
  savePosition: () => void;
}

const ResourceType = {
  INFOBOX: 'infobox',
  MEDIA: 'media',
};

const labels = {
  title: 'Infoboxes/Media',
  input: 'infobox/media',
  button: 'Infobox',
  data: 'infoboxes',
};

export const InfoboxResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const { data: infoboxes, refetch: refetchInfoboxes } = useQuery(
    [module?.type, module?.id, 'infoboxes'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getInfoboxes
      : resourcesAPI.getCalculatorInfoboxes,
    {
      enabled: !!module,
      initialData: [],
    }
  );

  const { data: medias, refetch: refetchMedia } = useQuery(
    [module?.type, module?.id, 'medias'],
    module?.type === MODULE_TYPES.ALGO ? resourcesAPI.getMedia : resourcesAPI.getCalculatorMedia,
    {
      initialData: [],
    }
  );

  const { mutate: deleteInfobox } = useMutation(resourcesAPI.deleteInfobox, {
    onSuccess: () => refetchInfoboxes(),
  });

  const { mutate: deleteMedia } = useMutation(resourcesAPI.deleteMedia, {
    onSuccess: () => refetchMedia(),
  });

  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const [resourceType, setResourceType] = useState<string | undefined>(undefined);
  const onOpen = (resourceId: string | null, resourceType?: string) => {
    setOpen(true);
    setResourceId(resourceId);
    setResourceType(resourceType);
  };
  const onClose = () => {
    setOpen(false);
    resourceType === ResourceType.INFOBOX || resourceType === undefined // undefined is for creation case
      ? refetchInfoboxes()
      : resourceType === ResourceType.MEDIA && refetchMedia();
  };

  const infoboxFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      infoBoxId: resourceId ?? undefined,
      toggleModal: onClose,
      type: module?.type as ResourceFormModuleTypes,
      isFullHeight: true,
      createModal: !!resourceId,
      closeUiElementPane: () => setModal(false),
      modal: modal,
      savePosition: () => {},
    }),
    [open, modal]
  );

  const imageFormProps: ResourceMediaFormProps = useMemo(
    () => ({
      type: module?.type as ResourceFormModuleTypes,
      modal: modal,
      isFullHeight: true,
      toggleModal: onClose,
      savePosition: () => {},
      imageId: resourceId ?? undefined,
      closeUiElementPane: () => setModal(false),
    }),
    [open, modal]
  );

  // TODO: remove when redux resource data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    if (!module) return;
    dispatch(getInfoBoxes(module.type, null, module.id));
  }, [infoboxes, module]);

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={[...infoboxes, ...medias]}
        renderCard={(resource) =>
          ResourceCard(
            resource,
            onOpen,
            resource.type === ResourceType.INFOBOX ? deleteInfobox : deleteMedia
          )
        }
        onOpen={onOpen}
      />
      <SlidePanel
        open={open && (resourceType === ResourceType.INFOBOX || resourceType === undefined)}
        onClose={() => setModal(true)}
      >
        <div className='mt-2 h-full w-[1210px] overflow-x-visible bg-white px-[16px]'>
          <InfoBoxForm {...infoboxFormProps} />
        </div>
      </SlidePanel>
      <SlidePanel open={open && resourceType === ResourceType.MEDIA} onClose={() => setModal(true)}>
        <div className='mt-2 h-full w-[620px] overflow-x-visible bg-white px-[16px]'>
          <ImageForm {...imageFormProps} />
        </div>
      </SlidePanel>
    </>
  );
};

const ResourceCard = (
  resource: Infobox | Media,
  onOpen: (resourceId: string | null, resourceType: string) => void,
  deleteResource: (infoboxId: string) => void
): ReactNode => {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.id, resource.type);
  const onDelete = () => {
    deleteResource(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none rounded-xl bg-white px-[20px] py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-full space-y-2'>
          <H4>{resource.title}</H4>
          <div>
            <AvoEditorPreview
              contentTiptap={
                (resource as Infobox).text_json_tiptap || (resource as Media).caption_json_tiptap
              }
              contentDraftJS={
                (resource as Infobox).new_text_content || (resource as Media).new_caption_content
              }
              isListItem
            />
          </div>
        </div>
        {hasEditPermission && (
          <FloatingActionButton
            open={open}
            onClose={() => setOpen(false)}
            onEdit={onEdit}
            onDelete={toggleDelete}
          />
        )}
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this infobox?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
