import { GET_REF_MANAGER } from 'actions/actionsTypes';

const refManagerReducer = (state = { refmanagers: [] }, action) => {
  switch (action.type) {
    case GET_REF_MANAGER:
      return {
        ...state,
        refmanagers: action.payload,
      };

    default:
      return state;
  }
};

export default refManagerReducer;
