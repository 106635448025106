import { MainLayout } from 'components/layout/MainLayout';
import NumericList from 'components/numerics/NumericsList';

export const Numbers = () => {
  return (
    <MainLayout>
      <div>
        <NumericList variantStyle />
      </div>
    </MainLayout>
  );
};
