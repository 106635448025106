import { MainLayout } from 'components/layout/MainLayout';
import NumberForm from 'components/numbers/NumberForm';

export const NumberUpsert = () => {
  return (
    <MainLayout>
      <NumberForm />
    </MainLayout>
  );
};
