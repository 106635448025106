import Divider from 'assets/icons/tiptap/divider';
import clsx from 'clsx';
import { Fragment } from 'react';
import { TextStylesButton } from './TextStylesButton';
import {
  TiptapButtonBold,
  TiptapButtonBulletList,
  TiptapButtonHighlight,
  TiptapButtonItalic,
  TiptapButtonOrderedList,
  TiptapButtonTable,
} from './ToolbarButtons';
import { TiptapExpandButton } from './tiptap-expand-options/ExpandButton';
import { useEditorContext } from './Tiptap';

export interface RichTextMenuBarProps {
  showList?: string[];
}

export function RichTextMenuBar({ showList = [] }: RichTextMenuBarProps) {
  const editor = useEditorContext();
  if (!editor) return null;

  const buttonsArray: JSX.Element[] = [
    <TextStylesButton editor={editor} data-key='textStyles' key='textStyles' />,
    <TiptapButtonBold editor={editor} data-key='bold' key='bold' />,
    <TiptapButtonItalic editor={editor} data-key='italic' key='italic' />,
    <TiptapButtonHighlight editor={editor} data-key='highlight' key='highlight' />,
    <button
      key='strike'
      onClick={() => editor.chain().focus().toggleStrike().run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      className={editor.isActive('strike') ? 'is-active' : ''}
      data-key='strike'
    >
      strike
    </button>,
    <button
      key='code'
      onClick={() => editor.chain().focus().toggleCode().run()}
      disabled={!editor.can().chain().focus().toggleCode().run()}
      className={editor.isActive('code') ? 'is-active' : ''}
      data-key='code'
    >
      code
    </button>,
    <button
      key='clearMarks'
      onClick={() => editor.chain().focus().unsetAllMarks().run()}
      data-key='clearMarks'
    >
      clear marks
    </button>,
    <button
      key='clearNodes'
      onClick={() => editor.chain().focus().clearNodes().run()}
      data-key='clearNodes'
    >
      clear nodes
    </button>,
    <button
      key='paragraph'
      onClick={() => editor.chain().focus().setParagraph().run()}
      className={`${editor.isActive('paragraph') ? 'is-active' : ''}`}
      data-key='paragraph'
    >
      paragraph
    </button>,
    <TiptapButtonBulletList editor={editor} data-key='bulletList' key='bulletList' />,
    <TiptapButtonOrderedList editor={editor} data-key='orderedList' key='orderedList' />,
    <button
      key='codeBlock'
      onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      className={editor.isActive('codeBlock') ? 'is-active' : ''}
      data-key='codeBlock'
    >
      code block
    </button>,
    <button
      key='blockquote'
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
      className={editor.isActive('blockquote') ? 'is-active' : ''}
      data-key='blockquote'
    >
      blockquote
    </button>,
    <button key='hr' onClick={() => editor.chain().focus().setHorizontalRule().run()} data-key='hr'>
      horizontal rule
    </button>,
    <button
      key='hardBreak'
      onClick={() => editor.chain().focus().setHardBreak().run()}
      data-key='hardBreak'
    >
      hard break
    </button>,
    <button
      key='undo'
      onClick={() => editor.chain().focus().undo().run()}
      disabled={!editor.can().chain().focus().undo().run()}
      data-key='undo'
    >
      undo
    </button>,
    <button
      key='redo'
      onClick={() => editor.chain().focus().redo().run()}
      disabled={!editor.can().chain().focus().redo().run()}
      data-key='redo'
    >
      redo
    </button>,
    <TiptapExpandButton key='more' data-key='more' />,
    <TiptapButtonTable editor={editor} data-key='table' key='table' />,
  ];

  const filteredBtns = !showList
    ? buttonsArray
    : buttonsArray.filter((button) => showList.includes(button.props['data-key']));

  const renderingBtnsWithDivider = filteredBtns.map((btn) => {
    const dataKey = btn.props['data-key'];

    if (['textStyles', 'highlight', 'orderedList'].includes(dataKey)) {
      return addDivider(btn, dataKey);
    } else {
      return btn;
    }
  });

  return (
    <div
      className={clsx('flex items-center p-2', 'pr-1 [&>button]:inline-flex [&>button]:pl-1')}
      role='none'
    >
      {renderingBtnsWithDivider}
    </div>
  );
}

const addDivider = (btn: JSX.Element, key: string) => {
  return (
    <Fragment key={key}>
      {btn}
      <div className='pl-2 pr-2'>
        <Divider />
      </div>
    </Fragment>
  );
};
