import axios from 'axios';
import { GptBoxFormVariable } from 'components/module/cards/GPTBoxCard';
import { GPT_BOX_API_URL } from '../constants';

export const GptBoxApi = {
  get: () => {},
  post: (data: GptBoxFormVariable) => axios.post(GPT_BOX_API_URL, data).then((res) => res.data),
  patch: (gptboxId: Number, data: GptBoxFormVariable) =>
    axios.patch(`${GPT_BOX_API_URL}${gptboxId}/`, data).then((res) => res.data),
  delete: () => {},
};
