import { SET_IS_FORM_DISABLED } from 'actions/actionsTypes';

const formHelperReducer = (state = { isFormDisabled: false }, action) => {
  switch (action.type) {
    case SET_IS_FORM_DISABLED:
      return {
        ...state,
        isFormDisabled: action.payload.isFormDisabled,
      };

    default:
      return state;
  }
};

export default formHelperReducer;
