import { ModuleLayout } from 'components/layout/ModuleLayout';
import { ModuleBoard } from 'components/module/ModuleBoard';

export const Flow = () => {
  return (
    <ModuleLayout>
      <ModuleBoard />
    </ModuleLayout>
  );
};
