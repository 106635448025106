import { SuggestionTypeEnum } from 'components/utils/tiptap/tiptapInterfaces';
import { SuggestionHandlerProps } from '../MentionPopover';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MentionContext } from '../../../module/MentionContext';
import { ComboBox } from '../../../ComboBox';
import { AddNewItem } from '../AddNewItem';
import { useEditorContext } from '../../Tiptap';

export const ConditionalTextHandler = ({ onChange, onDisabledChange }: SuggestionHandlerProps) => {
  const editor = useEditorContext();
  const { suggestions } = useContext(MentionContext);

  const filteredSuggestions = useMemo(() => {
    const suggestionType = SuggestionTypeEnum.CONDITIONAL_TEXT;

    return suggestions
      .filter((suggestion) => suggestion.type.includes(suggestionType))
      .map((option) => ({
        value: option.code,
        label: option.name,
      }));
  }, [suggestions]);

  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    const option = suggestions.find((option) => option.code === selectedOption);
    if (!option) {
      onDisabledChange(true);
      return;
    }
    onChange({ ...option });
    onDisabledChange(false);
  }, [suggestions, selectedOption]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <ComboBox
        options={filteredSuggestions}
        onCreate={() => setOpen(true)}
        selectedValue={selectedOption}
        createButtonLabel='Create New Conditional Text'
        onChange={(value) => setSelectedOption(value as string)}
      />
      <AddNewItem
        editor={editor}
        open={open}
        itemType={SuggestionTypeEnum.CONDITIONAL_TEXT}
        toggleModal={() => setOpen((prev) => !prev)}
      />
    </>
  );
};
