export const colors = [
  '#F34336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#607D8B',
  // '#b39ddb',
  // '#4dd0e1',
  // '#5d4037',
  // '#69f0ae',
  // '#80deea',
  // '#f48fb1',
  // '#ef9a9a',
  // '#ff8a80',
  // '#bcaaa4',
  // '#e0e0e0',
  // '#FFCBE7',
  // '#EFEBC4',
  // '#CCDCFA',
  // '#F8CFCF',
  // '#b39ddb',
  // '#4dd0e1',
  // '#5d4037',
  // '#69f0ae',
  // '#80deea',
  // '#f48fb1',
  // '#ef9a9a',
  // '#ff8a80',
  // '#bcaaa4',
  // '#e0e0e0',
  // '#FFCBE7',
  // '#EFEBC4',
  // '#CCDCFA',
  // '#F8CFCF',
  // '#b39ddb',
  // '#4dd0e1',
  // '#5d4037',
  // '#69f0ae',
  // '#80deea',
  // '#f48fb1',
  // '#ef9a9a',
  // '#ff8a80',
  // '#bcaaa4',
  // '#e0e0e0',
  // '#FFCBE7',
  // '#EFEBC4',
  // '#CCDCFA',
  // '#F8CFCF',
  // '#b39ddb',
  // '#4dd0e1',
  // '#5d4037',
  // '#69f0ae',
  // '#80deea',
  // '#f48fb1',
  // '#ef9a9a',
  // '#ff8a80',
  // '#bcaaa4',
  // '#e0e0e0',
  // '#FFCBE7',
  // '#EFEBC4',
  // '#CCDCFA',
  // '#F8CFCF',
];
