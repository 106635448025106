import clsx from 'clsx';
import Backdrop from 'components/utils/Backdrop';
import Button from 'components/utils/Button';
import { Icons } from 'components/utils/Icons';
import { ProgressBar } from 'components/utils/ProgressBar';
import { H4 } from 'components/utils/typo';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { getTimeDifference } from './AiKnowledgeBaseUtils';

interface ParsingModalProps {
  started?: string;
  expectedEnd: number;
  onCancel: () => void;
}

const fadeInOutVariant = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const ParsingStatus = {
  UPLOADING: 'Uploading PDF...',
  INQUEUE: 'Parsing job in queue...',
  INPROGRESS: 'Parsing job in progress...',
  FAIL: 'Something went wrong. Please try again.',
};

export const ParsingModal = ({ started, expectedEnd, onCancel }: ParsingModalProps) => {
  const [percentage, setPercentage] = useState(
    started && expectedEnd ? Math.floor(getTimeDifference(started, expectedEnd)) : undefined
  );

  const fail = percentage != undefined && percentage >= 99;
  const getStatus = () => {
    if (fail) return ParsingStatus.FAIL;
    else if (!expectedEnd) return ParsingStatus.UPLOADING;
    else if (!started) return ParsingStatus.INQUEUE;
    return ParsingStatus.INPROGRESS;
  };

  useEffect(() => {
    if (!started || !expectedEnd) return;
    const interval = setInterval(() => {
      setPercentage(Math.floor(getTimeDifference(started, expectedEnd)));
    }, 1000);
    return () => clearInterval(interval);
  }, [started, expectedEnd]);

  return (
    <Backdrop>
      <AnimatePresence>
        <motion.div
          className='z-10 w-[384px] rounded-lg bg-white'
          variants={fadeInOutVariant}
          initial='init'
          animate='anim'
          exit='exit'
        >
          <H4 className='border-b border-gray-200 px-[16px] py-[12px]'>Converting PDF to Text</H4>
          <div className='p-4 text-caption-2'>
            This may take a few minutes. You can leave the page, conversion will continue. Click
            'Cancel' anytime to stop.
          </div>
          <div className='space-y-2 px-4'>
            <ProgressBar percentage={percentage} fail={fail} />
            <div
              className={clsx(
                'flex justify-between text-caption-2',
                fail ? 'text-red-500' : 'text-gray-700'
              )}
            >
              <span>{getStatus()}</span>
              {fail ? <Icons.Error /> : percentage && <span>{percentage}%</span>}
            </div>
          </div>
          <div className='flex justify-end px-4 py-3'>
            <div className='flex'>
              <Button.Reverse
                disabled={!expectedEnd}
                className='!text-gray-700'
                onClick={() => !!expectedEnd && onCancel()}
              >
                Cancel
              </Button.Reverse>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </Backdrop>
  );
};
