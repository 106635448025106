import { Typography } from '@material-ui/core';
import AddIcon from 'assets/icons/addIcon';
import { AddButton, Examples } from 'components/utils/styled-components/FormStyle';
import { generateID } from 'utils/utilityFunctions';
import SortableConditionalTextList from './sortableConditionalTexts';
import { conditionalTextStyles } from './styles';

export default function ConditionalTextAccordion(props) {
  const {
    conditions,
    moduleId,
    numerics,
    calculatorId,
    type,
    addConditionalCase,
    setCaseText,
    setRawCondition,
    deleteCondition,
    handleDeleteClick,
    handleListChange,
    updateListItems,
    resourceSection,
    conditionalTextConditionsHandler,
  } = props;

  const emptyCondition = {
    sortableId: generateID(),
    position: 0,
    label: `Condition ${conditions.length}`,
    is_default: false,
  };

  const classes = conditionalTextStyles();

  return (
    <Examples
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      style={{ padding: 10, marginBottom: 20 }}
    >
      <SortableConditionalTextList
        conditionalTextConditionsHandler={conditionalTextConditionsHandler}
        resourceSection={resourceSection}
        items={conditions}
        deleteCondition={deleteCondition}
        setCaseText={setCaseText}
        type={type}
        moduleId={moduleId}
        calculatorId={calculatorId}
        setRawCondition={setRawCondition}
        numerics={numerics}
        handleDeleteClick={handleDeleteClick}
        handleListChange={handleListChange}
        updateListItems={updateListItems}
      />
      <div className={classes.accordianContainer}>
        <Typography className={classes.addBtnTitle}> Add </Typography>

        <AddButton
          style={{ minWidth: 130, display: 'inline-flex' }}
          disableRipple
          disableFocusRipple
          startIcon={<AddIcon />}
          className=' waves-effect waves-light  mr-2'
          onClick={() => addConditionalCase(emptyCondition)}
        >
          Condition
        </AddButton>
      </div>
    </Examples>
  );
}
