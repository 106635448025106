import axios from 'axios';
import { TEAM_API_URL } from '../../constants';
import { GET_TEAM_CALCULATORS } from '../actionsTypes';
import { TEAM_QUERY_KEY } from '../../hooks/useTeam';
import { queryClient } from '../../queryClient';

const getTeamCalculators = () => async (dispatch, getState) => {
  const team = queryClient.getQueryData([TEAM_QUERY_KEY]);
  const res = await axios.get(TEAM_API_URL + team?.id + '/calculators');
  const data = res?.data || [];

  dispatch({
    type: GET_TEAM_CALCULATORS,
    payload: data.map((item) => ({ ...item, title: item.name })),
  });
};

export default getTeamCalculators;
