import { Switch } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';

interface AvoSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const AvoSwitch = ({ checked, onChange }: AvoSwitchProps) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={twJoin(
        checked ? 'bg-primary-500 focus:bg-primary-500' : 'bg-gray-300 focus:bg-gray-300',
        'relative inline-flex h-[26px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
      )}
    >
      <span
        aria-hidden='true'
        className={twJoin(
          checked ? 'translate-x-[18px]' : 'translate-x-0',
          'pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};
