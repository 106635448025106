import { ModuleLayout } from 'components/layout/ModuleLayout';
import { CalculatorIntegrationResourceTable } from 'components/resources/calculator-integration/CalculatorIntegrationTable';

export const Integrations = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <CalculatorIntegrationResourceTable />
    </ModuleLayout>
  );
};
