import {
  NodeType,
  createNode,
  createText,
  isDraftJSContent,
  isEntityRange,
} from '@tiptap/draftjs-to-tiptap';
import { RawDraftContentState, RawDraftEntityRange, RawDraftInlineStyleRange } from 'draft-js';
import { isArray } from 'lodash';
import { AvoNodeMapping, ExpandedDraftInlineStyleType, MentionType, Options } from './types';

/**
 * Add a mark to a node.
 * @returns The node with the mark added.
 */
export function addAttribute<TNodeType extends keyof AvoNodeMapping>(
  node: AvoNodeMapping[TNodeType],
  attr: NonNullable<AvoNodeMapping[TNodeType]['attrs']> | null
): AvoNodeMapping[TNodeType] {
  if (!node && !attr) {
    throw new Error('Cannot add a null attr to a null node.');
  }

  if (!attr) {
    return node;
  }

  node.attrs = attr;

  return node;
}

export function isHeading(ranges: RawDraftInlineStyleRange[]) {
  return ranges.some((range) => (range.style as ExpandedDraftInlineStyleType) === 'HEADER');
}

function findAvoMentionRangeIfExists(
  entityMap: RawDraftContentState['entityMap'],
  ranges: (RawDraftEntityRange | RawDraftInlineStyleRange)[]
) {
  return ranges.find(
    (range) =>
      isEntityRange(range) && range.key in entityMap && entityMap[range.key].type === '#mention'
  );
}

function getAvoMentionType(
  options: Options,
  ranges: (RawDraftEntityRange | RawDraftInlineStyleRange)[]
): keyof AvoNodeMapping {
  const range = findAvoMentionRangeIfExists(options.entityMap, ranges);
  if (!range || !typeof isDraftJSContent(options)) return 'text';
  const entity = options.entityMap[(range as RawDraftEntityRange).key];

  switch (entity.data.mention.type) {
    case MentionType.embedded:
    case MentionType.unembedded:
      return 'linkPreview';
    default:
      return 'mention';
  }
}

export function createAvoNode(
  text: string,
  ranges: (RawDraftEntityRange | RawDraftInlineStyleRange)[],
  options: Options
) {
  const nodeType = getAvoMentionType(options, ranges);
  return nodeType === 'text' ? createText(text) : (createNode(nodeType) as any);
}

// Remove `marks: []` option in content item
// https://github.com/ueberdosis/draft-js-to-tiptap/blob/main/src/utils.ts#L131
export const removeEmptyMarks = (node: NodeType) => {
  for (const item of node.content) {
    if ('content' in item) {
      removeEmptyMarks(item);
    }
    if (isArray(item.marks) && item.marks.length === 0) {
      delete item.marks;
    }
  }
};

// Adding additional attributes because it is used internally.
export const decorateListAttributes = (node: NodeType) => {
  if (node.type === 'orderedList') {
    node.attrs = {
      tight: true,
      start: 1,
    };
  } else if (node.type === 'bulletList') {
    node.attrs = {
      tight: true,
    };
  } else if (node.type === 'listItem') {
    node.attrs = {
      color: null,
    };
  }

  for (const item of node.content) {
    if ('content' in item) {
      decorateListAttributes(item);
    }
  }
};

const unusedAvoMentionTypes = [
  'VARIABLES',
  'INFOBOXES',
  'REFERENCES',
  'FORMULAE',
  'NUMERICS',
  'MEDIA',
  'CUSTOM NUMERICS',
  'KNOWLEDGE_BASES',
  'heading',
];

export function checkAvoMentionValidity(
  options: Options,
  ranges: (RawDraftEntityRange | RawDraftInlineStyleRange)[]
): boolean {
  const range = findAvoMentionRangeIfExists(options.entityMap, ranges);
  if (!range || !typeof isDraftJSContent(options)) return true;
  const entity = options.entityMap[(range as RawDraftEntityRange).key];

  return !unusedAvoMentionTypes.includes(entity.data.mention.code);
}
