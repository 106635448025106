import { Editor } from '@tiptap/core';
import clsx from 'clsx';
import { TableActionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { useState } from 'react';

interface TableActionButtonProps {
  'data-key': TableActionTypes;
  onClick: () => void;
  className: string;
  role: string;
}

interface TiptapTableActionButtonsProps {
  editor: Editor;
  showList?: TableActionTypes[];
}

export const TiptapTableActionButtons: React.FC<TiptapTableActionButtonsProps> = ({
  editor,
  showList,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const buttonsArray: React.ReactElement<TableActionButtonProps>[] = [
    <button
      key='AddColumnBefore'
      data-key='AddColumnBefore'
      onClick={() => handleButtonClick('Add Column Before')}
      role='menuitem'
    >
      Add Column Before
    </button>,
    <button
      key='AddColumnAfter'
      data-key='AddColumnAfter'
      onClick={() => handleButtonClick('Add Column After')}
      role='menuitem'
    >
      Add Column After
    </button>,
    <button
      key='DeleteColumn'
      data-key='DeleteColumn'
      onClick={() => handleButtonClick('Delete Column')}
      role='menuitem'
    >
      Delete Column
    </button>,
    <button
      key='AddRowBefore'
      data-key='AddRowBefore'
      onClick={() => handleButtonClick('Add Row Before')}
      role='menuitem'
    >
      Add Row Before
    </button>,
    <button
      key='AddRowAfter'
      data-key='AddRowAfter'
      onClick={() => handleButtonClick('Add Row After')}
      role='menuitem'
    >
      Add Row After
    </button>,
    <button
      key='DeleteRow'
      data-key='DeleteRow'
      onClick={() => handleButtonClick('Delete Row')}
      role='menuitem'
    >
      Delete Row
    </button>,
    <button
      key='DeleteTable'
      data-key='DeleteTable'
      onClick={() => handleButtonClick('Delete Table')}
      role='menuitem'
    >
      Delete Table
    </button>,
    <button
      key='MergeCells'
      data-key='MergeCells'
      onClick={() => handleButtonClick('Merge Cells')}
      role='menuitem'
    >
      Merge Cells
    </button>,
    <button
      key='SplitCell'
      data-key='SplitCell'
      onClick={() => handleButtonClick('Split Cell')}
      role='menuitem'
    >
      Split Cell
    </button>,
    <button
      key='ToggleHeaderColumn'
      data-key='ToggleHeaderColumn'
      onClick={() => handleButtonClick('Toggle Header Column')}
      role='menuitem'
    >
      Toggle Header Column
    </button>,
    <button
      key='ToggleHeaderRow'
      data-key='ToggleHeaderRow'
      onClick={() => handleButtonClick('Toggle Header Row')}
      role='menuitem'
    >
      Toggle Header Row
    </button>,
    <button
      key='ToggleHeaderCell'
      data-key='ToggleHeaderCell'
      onClick={() => handleButtonClick('Toggle Header Cell')}
      role='menuitem'
    >
      Toggle Header Cell
    </button>,
    <button
      key='MergeSplit'
      data-key='MergeSplit'
      onClick={() => handleButtonClick('Merge/Split')}
      role='menuitem'
    >
      Merge/Split
    </button>,
    <button
      key='SetCellAttribute'
      data-key='SetCellAttribute'
      onClick={() => handleButtonClick('Set Cell Attribute')}
      role='menuitem'
    >
      Set Cell Attribute
    </button>,
    <button
      key='FixTables'
      data-key='FixTables'
      onClick={() => handleButtonClick('Fix Tables')}
      role='menuitem'
    >
      Fix Tables
    </button>,
    <button
      key='GoToNextCell'
      data-key='GoToNextCell'
      onClick={() => handleButtonClick('Go to Next Cell')}
      role='menuitem'
    >
      Go to Next Cell
    </button>,
    <button
      key='GoToPreviousCell'
      data-key='GoToPreviousCell'
      onClick={() => handleButtonClick('Go to Previous Cell')}
      role='menuitem'
    >
      Go to Previous Cell
    </button>,
  ];

  const handleButtonClick = (action) => {
    editor.chain().focus();

    switch (action) {
      case 'Add Column Before':
        editor.chain().addColumnBefore().run();
        break;
      case 'Add Column After':
        editor.chain().addColumnAfter().run();
        break;
      case 'Delete Column':
        editor.chain().deleteColumn().run();
        break;
      case 'Add Row Before':
        editor.chain().addRowBefore().run();
        break;
      case 'Add Row After':
        editor.chain().addRowAfter().run();
        break;
      case 'Delete Row':
        editor.chain().deleteRow().run();
        break;
      case 'Delete Table':
        editor.chain().deleteTable().run();
        break;
      case 'Merge Cells':
        editor.chain().mergeCells().run();
        break;
      case 'Split Cell':
        editor.chain().splitCell().run();
        break;
      case 'Toggle Header Column':
        editor.chain().toggleHeaderColumn().run();
        break;
      case 'Toggle Header Row':
        editor.chain().toggleHeaderRow().run();
        break;
      case 'Toggle Header Cell':
        editor.chain().toggleHeaderCell().run();
        break;
      case 'Merge/Split':
        editor.chain().mergeOrSplit().run();
        break;
      case 'Set Cell Attribute':
        editor.chain().setCellAttribute('colspan', 2).run();
        break;
      case 'Fix Tables':
        editor.chain().fixTables().run();
        break;
      case 'Go to Next Cell':
        editor.chain().goToNextCell().run();
        break;
      case 'Go to Previous Cell':
        editor.chain().goToPreviousCell().run();
        break;
      default:
        break;
    }

    closeDropdown();
  };

  if (!editor) {
    return null;
  }

  return (
    <div className='relative inline-block text-left'>
      <button
        type='button'
        className='inline-flex items-center justify-center rounded-full border border-gray-300 bg-white p-2'
        onClick={toggleDropdown}
      >
        Table Actions
      </button>

      {isOpen && (
        <div
          className='absolute left-0 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
        >
          <div
            className={clsx(
              'py-1',
              '[&>button:hover]:bg-gray-100 [&>button]:block [&>button]:w-full [&>button]:px-4 [&>button]:py-2 [&>button]:text-left [&>button]:text-sm [&>button]:text-gray-700'
            )}
            role='none'
          >
            {!showList
              ? buttonsArray
              : buttonsArray.filter((button) => showList.includes(button.props['data-key']))}
          </div>
        </div>
      )}
    </div>
  );
};
