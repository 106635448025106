function Link(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#E5EDF0' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M40.3512 19.6488C39.081 18.3815 37.36 17.6698 35.5657 17.6698C33.7714 17.6698 32.0503 18.3815 30.7801 19.6488L28.499 21.9288C28.2834 22.1608 28.1663 22.4674 28.1721 22.784C28.1779 23.1006 28.3062 23.4026 28.5302 23.6266C28.7541 23.8505 29.0561 23.9788 29.3727 23.9847C29.6894 23.9905 29.9959 23.8733 30.2279 23.6577L32.509 21.3777C33.3203 20.5683 34.4196 20.1137 35.5657 20.1137C36.7117 20.1137 37.811 20.5683 38.6223 21.3777C39.0251 21.7781 39.3444 22.2545 39.5619 22.7792C39.7793 23.3039 39.8905 23.8664 39.889 24.4344C39.8905 25.0023 39.7794 25.565 39.5619 26.0896C39.3445 26.6143 39.0251 27.0907 38.6223 27.4911L34.6212 31.4922C32.989 33.1255 30.1412 33.1255 28.5079 31.4922C28.2375 31.2226 28.004 30.9185 27.8134 30.5877C27.652 30.3066 27.3854 30.1011 27.0724 30.0165C26.7594 29.9319 26.4257 29.9751 26.1446 30.1366C25.8634 30.2981 25.658 30.5647 25.5734 30.8776C25.4888 31.1906 25.532 31.5244 25.6934 31.8055C25.9901 32.3199 26.3546 32.7966 26.779 33.2211C27.4061 33.8513 28.1519 34.351 28.9733 34.6913C29.7947 35.0315 30.6755 35.2055 31.5646 35.2033C32.4537 35.2057 33.3344 35.0317 34.1559 34.6914C34.9773 34.3512 35.7231 33.8514 36.3501 33.2211L40.3512 29.2211C40.9818 28.5937 41.4816 27.8475 41.8219 27.0257C42.1621 26.2039 42.336 25.3227 42.3334 24.4333C42.3358 23.5442 42.1619 22.6634 41.8216 21.842C41.4814 21.0205 40.9816 20.2747 40.3512 19.6477'
        fill='#2E3438'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M29.7723 36.3422L27.4912 38.6233C26.6796 39.4322 25.5804 39.8864 24.4345 39.8864C23.2887 39.8864 22.1895 39.4322 21.3779 38.6233C20.9754 38.2227 20.6562 37.7463 20.4388 37.2217C20.2214 36.697 20.11 36.1345 20.1112 35.5666C20.1112 34.4122 20.5612 33.3266 21.3779 32.5111L25.379 28.5088C25.8489 28.0399 26.4205 27.6853 27.0494 27.4726C27.6784 27.2599 28.3478 27.1947 29.0059 27.2822C29.6641 27.3696 30.2933 27.6073 30.8448 27.9768C31.3964 28.3464 31.8556 28.8379 32.1868 29.4133C32.2667 29.5525 32.3733 29.6746 32.5005 29.7726C32.6276 29.8706 32.7728 29.9426 32.9278 29.9845C33.0828 30.0264 33.2445 30.0373 33.4037 30.0167C33.5629 29.9961 33.7165 29.9444 33.8557 29.8644C33.9949 29.7844 34.1169 29.6778 34.215 29.5507C34.313 29.4235 34.385 29.2783 34.4269 29.1234C34.4687 28.9684 34.4797 28.8067 34.4591 28.6475C34.4385 28.4883 34.3867 28.3347 34.3068 28.1955C33.788 27.2951 33.0691 26.5261 32.2057 25.9479C31.3423 25.3697 30.3574 24.9978 29.3273 24.861C28.2973 24.7242 27.2495 24.8261 26.2651 25.1588C25.2807 25.4916 24.3859 26.0462 23.6501 26.7799L19.649 30.7811C19.0187 31.4082 18.519 32.154 18.1788 32.9754C17.8385 33.7968 17.6645 34.6775 17.6668 35.5666C17.6668 37.3744 18.3712 39.0744 19.649 40.3522C20.2768 40.9812 21.0228 41.4799 21.8441 41.8196C22.6654 42.1592 23.5458 42.333 24.4345 42.3311C26.1679 42.3311 27.9001 41.6711 29.2201 40.3533L31.5012 38.0722C31.7168 37.8402 31.834 37.5336 31.8281 37.217C31.8223 36.9004 31.694 36.5984 31.47 36.3744C31.2461 36.1505 30.9441 36.0222 30.6275 36.0163C30.3109 36.0105 30.0043 36.1277 29.7723 36.3433'
        fill='#2E3438'
      />
    </svg>
  );
}

export default Link;
