import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

const variants = {
  init: { rotate: -45 },
  anim: { rotate: 0 },
  exit: { rotate: -45 },
};

const Unchecked = () => {
  return (
    <motion.div
      variants={variants}
      initial='init'
      animate='anim'
      exit='exit'
      className='m-[4px] h-[16px] w-[16px] rounded border'
    />
  );
};

const Checked = () => {
  return (
    <motion.div
      variants={variants}
      initial='init'
      animate='anim'
      exit='exit'
      className='relative m-[4px] h-[16px] w-[16px] rounded bg-primary-500'
    >
      <div
        className={clsx(
          'flex h-[16px] w-[16px] items-center justify-center',
          'after:absolute after:h-[5px] after:w-[10px]',
          'after:-rotate-45 after:border-b-2 after:border-l-2 after:border-white'
        )}
      />
    </motion.div>
  );
};

const Indeterminate = () => {
  return (
    <motion.div
      variants={variants}
      initial='init'
      animate='anim'
      exit='exit'
      className='relative m-[4px] h-[16px] w-[16px] rounded bg-primary-500'
    >
      <div
        className={clsx(
          'flex h-[16px] w-[16px] items-center justify-center',
          'after:absolute after:top-1/2 after:w-[10px]',
          'after:-translate-y-1/2 after:border-b-2 after:border-white'
        )}
      />
    </motion.div>
  );
};

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, indeterminate, onChange, checked, defaultChecked, disabled, ...rest }, ref) => {
    const [check, setCheck] = useState(defaultChecked || false);
    const onCheckChanged = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      setCheck(e.target.checked);
    };
    useEffect(() => {
      if (typeof checked === 'boolean') {
        setCheck(checked);
      }
    }, [checked]);

    return (
      <label
        // TODO: '!mb-0' due to materialize css and bootstrap
        className={twJoin(!disabled && 'cursor-pointer', '!mb-0')}
      >
        <AnimatePresence>
          {check ? <Checked /> : indeterminate ? <Indeterminate /> : <Unchecked />}
        </AnimatePresence>
        <input
          ref={ref}
          type='checkbox'
          disabled={disabled}
          className={clsx(
            className,
            'invisible m-0 hidden'
            // 'relative left-0 visible', // because of material UI. need to remove later
            // 'accent-primary-500 before:border-primary-500'
          )}
          onChange={onCheckChanged}
          checked={check}
          {...rest}
        />
      </label>
    );
  }
);

export default Checkbox;
