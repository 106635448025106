import { useMutation } from '@tanstack/react-query';
import { getConditionalTexts } from 'actions/resources/getConditionalTexts';
import { getCustomNumerics } from 'actions/resources/getCustomNumerics';
import { getFormulas } from 'actions/resources/getFormulas';
import { getTriggers } from 'actions/resources/getTriggers';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import { getRefManagers } from 'actions/resources/refManager';
import { PostAmbListeningData, msgCardAPI } from 'api/panels';
import { Trigger } from 'api/resources';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { MsgCardFormValues } from 'components/module/cards/MessageBetaCard';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { ExampleNTools } from 'components/utils/example-n-tools/ExampleNTools';
import { ExampleNToolButtons } from 'components/utils/example-n-tools/ToolButtons';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { Header } from 'components/utils/panels/Header';
import RequiredMark from 'components/utils/requiredMark';
import { RichTextMenuButtonTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { Body2 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CARD_INTRODUCTION, CARD_MESSAGE } from 'constants/variables';
import { useDraftJSSuggestions } from 'hooks/useSuggestions';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getVariableName } from 'utils/gptUtils';
import { SAVE_BUTTON } from '../../constants';
import { TriggerComboBox } from './utils/TriggerComboBox';
import { DEMOGRAPHICS_TYPE, PROBLEM_LIST_TYPE } from '../../hooks/useEHRVariables';

interface MessageFormProps {
  id: string;
  modal?: boolean;
  onSave: () => void;
  closeModal?: (type?: any) => void;
  closePanel: (command?: string) => void;
}

export const MessageForm = ({ id, modal, onSave, closeModal, closePanel }: MessageFormProps) => {
  const { module } = useContext(ModuleContext);
  const moduleId = module?.id;
  const moduleType = module?.type;
  const { watch, setValue, register, handleSubmit } = useFormContext<MsgCardFormValues>();
  const title = watch('title');
  const tools = watch('tools');
  const examples = watch('examples');
  const isDetailEnabled = watch('is_long_desc_enabled');
  const is_introduction = watch('is_introduction');
  const [prevDetail, setPrevDetail] = useState('');
  const [prevSummary, setPrevSummary] = useState('');
  const [prevIntroductionDescription, setPrevIntroductionDescription] = useState('');
  const selectedTrigger = (watch('trigger') as Trigger)?.id || watch('trigger');

  const [openDetail, setOpenDetail] = useState(false);
  const [isVarLoading, setVarLoading] = useState(false);

  const [detailCharLength, setDetailCharLength] = useState(0);
  const [summaryCharLength, setSummaryCharLength] = useState(0);

  const {
    suggestions: textEditorsuggestions,
    moduleNumerics,
    moduleVariables,
  } = useDraftJSSuggestions();
  const triggerState = useSelector((state: any) => state.triggerState);
  const alwayOnTrigger = triggerState.triggers.find((data) => data.title === 'Always On');
  const suggestionsToExclude = useMemo(
    () =>
      is_introduction
        ? [
            'knowledge_base',
            'variable',
            'conditional_text',
            'link',
            'contact_number',
            'reference',
            'ehr_order',
            PROBLEM_LIST_TYPE,
            DEMOGRAPHICS_TYPE,
          ]
        : ['knowledge_base', PROBLEM_LIST_TYPE, DEMOGRAPHICS_TYPE],
    [is_introduction]
  );
  const richTextButtonsShowListTiptap: RichTextMenuButtonTypes[] = useMemo(
    () =>
      is_introduction
        ? ['textStyles', 'italic', 'highlight', 'bulletList', 'orderedList', 'more']
        : ['textStyles', 'bold', 'italic', 'highlight', 'bulletList', 'orderedList', 'more'],
    [is_introduction]
  );
  const richTextButtonShowList = useMemo(
    () =>
      is_introduction
        ? [
            ToolbarButton.TEXT_STYLE,
            ToolbarButton.ITALIC,
            ToolbarButton.HIGHLIGHT,
            ToolbarButton.BULLETED_LIST,
            ToolbarButton.NUMBERED_LIST,
            ToolbarButton.INFOBOX,
            ToolbarButton.MEDIA,
          ]
        : [
            ToolbarButton.TEXT_STYLE,
            ToolbarButton.BOLD,
            ToolbarButton.ITALIC,
            ToolbarButton.HIGHLIGHT,
            ToolbarButton.BULLETED_LIST,
            ToolbarButton.NUMBERED_LIST,
            ToolbarButton.INFOBOX,
            ToolbarButton.INSERT_LINK,
            ToolbarButton.MEDIA,
            ToolbarButton.PHONE_NUMBER,
            ToolbarButton.VARIABLES,
            ToolbarButton.CONDITIONAL_TEXT,
          ],
    [is_introduction]
  );

  const { mutate, isLoading: isMutating } = useMutation<
    PostAmbListeningData,
    null,
    MsgCardFormValues
  >(!id ? (data) => msgCardAPI.postMsgCard(data) : (data) => msgCardAPI.putMsgCard(data, id), {
    onSuccess: onSave,
  });

  useEffect(() => {
    if (alwayOnTrigger && !selectedTrigger) {
      setValue('trigger', alwayOnTrigger.id);
    }
  }, [alwayOnTrigger, selectedTrigger]);

  // TODO: remove when redux data is depracated
  const dispatch = useDispatch();

  useEffect(() => {
    setPrevSummary(watch('new_description_text_content_short'));
    setPrevDetail(watch('new_description_text_content_long'));
    setPrevIntroductionDescription(watch('new_description_text_content'));
    dispatch(getTriggers(moduleType, null, undefined, moduleId));
    dispatch(getCustomNumerics(moduleType, null, moduleId));
    dispatch(getInfoBoxes(moduleType, null, moduleId));
    dispatch(getFormulas(moduleType, null, moduleId));
    dispatch(getRefManagers(moduleType, null, moduleId));
    dispatch(getConditionalTexts(moduleType, null, moduleId));
  }, []);

  const onSummaryChange = debounce((e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');
    setValue('description_text_short', text);
    setValue('new_description_text_content_short', e);
  }, 500);

  const onDetailChange = debounce((e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');
    setValue('description_text_long', text);
    setValue('new_description_text_content_long', e);
  }, 500);

  const onIntroductionDescriptionChange = debounce((e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');
    setValue('new_description_text_content', e);
    setValue('description_text', text);
  }, 500);

  const onSummaryTiptapChange = debounce((editor) => {
    setValue('description_text_short', editor.getText());
    setValue('short_desc_json_tiptap', editor.getJSON());
  }, 500);

  const onDetailTiptapChange = debounce((editor) => {
    setValue('description_text_long', editor.getText());
    setValue('long_desc_json_tiptap', editor.getJSON());
  }, 500);

  const onIntroductionDescriptionTiptapChange = debounce((editor) => {
    setValue('desc_json_tiptap', editor.getJSON());
  }, 500);

  const closeDetailPopup = (action: string) => {
    if (isDetailEnabled === true) {
      setOpenDetail(false);
    } else {
      setOpenDetail(false);
      setValue('is_long_desc_enabled', action === 'expand' ? true : false);
    }
  };

  const showWarning = () => {
    if (isDetailEnabled === false) {
      setOpenDetail(true);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  const onSubmit = async (data: MsgCardFormValues) => {
    if (isMutating) return;

    const title = watch('title');
    const variable = watch('variable');

    if (!is_introduction) {
      if (title === '') {
        toast.error(CustomToast, { data: 'Please fill out the title field.' });
        return;
      }
      setVarLoading(true);
      if (!variable) {
        const variable = await getVariableName(title);
        data['variable'] = variable;
      }
    }

    data['question_items'] = [...data['examples'], ...data['tools']];
    data['trigger'] = (data?.trigger as Trigger)?.id || data?.trigger;
    mutate(data);
  };

  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
    candidate_trigger: trigger.candidate_trigger,
  }));

  return (
    <div className='h-full pt-10'>
      <div className='h-full'>
        <form
          autoComplete='off'
          onKeyDown={onKeyDown}
          onSubmit={handleSubmit(onSubmit)}
          className='relative flex h-full flex-col pb-0'
        >
          <Header
            className='!px-0'
            title={is_introduction ? CARD_INTRODUCTION : CARD_MESSAGE}
            toggleModal={closePanel}
          />
          <div className='grow space-y-8'>
            {!is_introduction && (
              <>
                <div className='space-y-[5px]'>
                  <div className='flex gap-1'>
                    <Body2 className='text-gray-900'>Trigger</Body2>
                    <RequiredMark />
                  </div>
                  <TriggerComboBox
                    options={triggerOptions}
                    selectedValue={selectedTrigger as string}
                    onChange={(value) => setValue('trigger', value)}
                  />
                </div>

                <div>
                  <div className='space-y-[5px]'>
                    <div className='flex gap-1'>
                      <Body2 className='text-gray-900'>Title</Body2>
                      <RequiredMark />
                    </div>
                    <Input
                      tabIndex={1}
                      required
                      maxLength={200}
                      placeholder='Enter title'
                      {...register('title', {
                        maxLength: 200,
                        required: true,
                      })}
                    />
                  </div>
                  <div className='mt-2 text-right text-caption-2 text-gray-700'>
                    {title.length}/200
                  </div>
                </div>
              </>
            )}
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                {is_introduction ? (
                  <>
                    <Body2 className='text-gray-900'>Text</Body2>
                    <RequiredMark />
                  </>
                ) : (
                  <Body2 className='text-gray-900'>Description</Body2>
                )}
              </div>
              <div className='space-y-1'>
                {is_introduction ? (
                  <AvoEditor
                    // common props
                    placeholder='This is card description'
                    // draft js props
                    fullWidth
                    moduleId={moduleId}
                    MAX_TEXT_LIMIT={255}
                    prevValue={prevIntroductionDescription}
                    setValue={onIntroductionDescriptionChange}
                    suggestions={[]}
                    characterLimitTotalCount={summaryCharLength}
                    richTextButtonShowList={richTextButtonShowList}
                    getCharactersLength={setSummaryCharLength}
                    // tiptap props
                    maxCharacters={255}
                    initialContent={watch('desc_json_tiptap')}
                    onUpdate={onIntroductionDescriptionTiptapChange}
                    suggestionsToExclude={suggestionsToExclude}
                    characterLimitCurrentCount={watch('description_text')?.length}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    richTextButtonsShowListTiptap={richTextButtonsShowListTiptap}
                  />
                ) : (
                  <AvoEditor
                    // common props
                    placeholder='This is card description'
                    // draft js props
                    fullWidth
                    moduleId={moduleId}
                    MAX_NO_OF_LINES={15}
                    MAX_TEXT_LIMIT={1000}
                    prevValue={prevSummary}
                    numerics={moduleNumerics}
                    setValue={onSummaryChange}
                    variables={moduleVariables}
                    showWarning={showWarning}
                    suggestions={textEditorsuggestions}
                    characterLimitTotalCount={summaryCharLength}
                    richTextButtonShowList={richTextButtonShowList}
                    getCharactersLength={setSummaryCharLength}
                    // tiptap props
                    maxLines={15}
                    maxCharacters={3000}
                    initialContent={watch('short_desc_json_tiptap')}
                    onUpdate={onSummaryTiptapChange}
                    onLimitReached={showWarning}
                    suggestionsToExclude={suggestionsToExclude}
                    characterLimitCurrentCount={watch('description_text_short')?.length}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    richTextButtonsShowListTiptap={richTextButtonsShowListTiptap}
                    characterLimitUnit=''
                  />
                )}
              </div>
            </div>
            {!is_introduction && (
              <div className='space-y-[5px]'>
                <div className='flex justify-between'>
                  <Body2 className='text-gray-900'>Add More Details</Body2>
                  <AvoSwitch
                    checked={isDetailEnabled}
                    onChange={(val) => setValue('is_long_desc_enabled', val)}
                  />
                </div>
                {isDetailEnabled && (
                  <div className='space-y-[5px]'>
                    <div className='flex gap-1'>
                      <Body2 className='text-gray-900'>Details</Body2>
                    </div>
                    <div className='space-y-1'>
                      <AvoEditor
                        // common props
                        placeholder='These are the card details. This section is optional and only needed if card contents requires more details than the description section allows'
                        // draft js props
                        fullWidth
                        moduleId={moduleId}
                        prevValue={prevDetail}
                        MAX_TEXT_LIMIT={2000}
                        MAX_NO_OF_LINES={99999}
                        numerics={moduleNumerics}
                        variables={moduleVariables}
                        setValue={onDetailChange}
                        isReadOnly={!isDetailEnabled}
                        suggestions={textEditorsuggestions}
                        getCharactersLength={setDetailCharLength}
                        richTextButtonShowList={richTextButtonShowList}
                        // tiptap props
                        maxLines={99999}
                        maxCharacters={2000}
                        initialContent={watch('long_desc_json_tiptap')}
                        onUpdate={onDetailTiptapChange}
                        suggestionsToExclude={suggestionsToExclude}
                        characterLimitTotalCount={detailCharLength}
                        characterLimitCurrentCount={watch('description_text_long')?.length}
                        wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                        richTextButtonsShowListTiptap={richTextButtonsShowListTiptap}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {!!id && examples.length > 0 && (
              <div>
                <div className='space-y-[5px]'>
                  <div className='flex gap-1'>
                    <Body2 className='text-gray-900'>Examples</Body2>
                  </div>
                </div>
                <div className='mt-2'>
                  <ExampleNToolButtons
                    section='E'
                    list={examples}
                    buttonsToShow={['text', 'infobox']}
                    onChange={(value) => setValue('examples', value)}
                  />
                </div>
                <div className='mt-2'>
                  <ExampleNTools
                    list={examples}
                    onChange={(value) => setValue('examples', value)}
                  />
                </div>
              </div>
            )}
            <div>
              <div className='space-y-[5px]'>
                <div className='flex gap-1'>
                  <Body2 className='text-gray-900'>Tools</Body2>
                </div>
              </div>
              <div className='mt-2'>
                <ExampleNToolButtons
                  section='T'
                  list={tools}
                  buttonsToShow={['infobox', 'calculator']}
                  onChange={(value) => setValue('tools', value)}
                />
              </div>
              {tools.length > 0 && (
                <div className='mt-2'>
                  <ExampleNTools list={tools} onChange={(value) => setValue('tools', value)} />
                </div>
              )}
            </div>
            {!is_introduction && (
              <VariableAutoGenerator
                question={title}
                tagTitle='Message'
                variable={watch('variable')}
                setVariableName={(variable) => setValue('variable', variable)}
              />
            )}
          </div>

          <div className='sticky bottom-0 left-0 z-[9] flex w-full shrink-0 items-center bg-white pb-[22px] pt-3'>
            <div className='flex w-full items-center justify-end'>
              <div className='flex gap-3'>
                <Button.Reverse
                  onClick={() => closePanel()}
                  className='!px-2 !py-2.5 font-bold'
                  type='button'
                >
                  Cancel
                </Button.Reverse>
                <Button
                  type='submit'
                  className='!px-6 !py-2.5 font-bold'
                  disabled={!is_introduction && !title}
                >
                  {isVarLoading || isMutating ? <LoadingSpinner size='small' /> : SAVE_BUTTON}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ConfirmModal
        preset='unsaved'
        open={modal!}
        toggleModal={closeModal!}
        toggleModalPanel={closePanel}
        handleSubmit={handleSubmit(onSubmit)}
        panelForm
      />
      <ConfirmModal
        open={openDetail}
        content='The summary description area has the character limit 
        of 1000. Instead, you can add more text in an extra
        text area, which users can click to expand and read.
        Do you want to add the text area?'
        confirmText='Expand'
        cancelText='Ignore'
        toggleModal={closeDetailPopup}
        performAction={() => closeDetailPopup('expand')}
      />
    </div>
  );
};
