import { SET_PATH } from '../actionsTypes';

const setPrevPath = (prevPath) => async (dispatch) => {
  dispatch({
    type: SET_PATH,
    prevPath: prevPath,
  });
};

export default setPrevPath;
