import NavBar from 'components/bars/NavBar';
import NumberForm from './NumberForm';

export const OldNumberFormWrapper = () => {
  return (
    <div>
      <NavBar />
      <NumberForm />
    </div>
  );
};
