import Menu from '@material-ui/core/Menu';
import { ChevronRight } from '@material-ui/icons';
import { CustomToast } from 'components/utils/toast-message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Popover } from '@material-ui/core';
import ArrowIcon from 'assets/icons/dropdownArrowIcon';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import { withFeaturePermission } from '../../../hooks/useAuthentication';
import { withEHRVariables } from '../../../hooks/useEHRVariables';
import { VariableButton } from '../styled-components/FormStyle';
import SearchableDropdown from './searchableDropdown';
import { StyledTooltip } from './styles';
import { MentionContext } from '../module/MentionContext';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from '../StaffOnlyWrapper';
import { SuggestionTypeEnum } from '../tiptap/tiptapInterfaces';

class VariableOptions extends Component {
  static contextType = MentionContext;

  constructor(props) {
    super(props);
    this.state = this.defaultState();
  }

  defaultState = () => {
    return {
      variablesDropDownEl: null,
      variableDropDownEl: null,
      multiVariableDropDownEl: null,
      formulaDropDownEl: null,
      numericsDropDownEl: null,
      customNumericsDropDownEl: null,
      textVariableDropDownEl: null,
      knowledgeBasesDropDownEl: null,
      notesDropDownEl: null,
      labDataDropDownEl: null,
      vitalSignsDropDownEl: null,
      medicationsDropDownEl: null,
      problemListDropDownEl: null,
      demographicsDropDownEl: null,
      ehrOrdersDropDownEl: null,
      msgVariableDropDownEl: null,
      dataExtractorVariableDropDownEl: null,
    };
  };

  toggleModal = (modalName) => {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
    }));
  };

  handleClick = (e, variableName) => {
    const formulas = this.props.formulaState.formulas || [];
    const numerics = this.props.numerics || [];

    const variables = this.props.variables || [];
    const choiceVariables = variables.filter((variable) => variable.type === 'choice_variable');
    const multiVariables = variables.filter((variable) => variable.type === 'multi_variable');
    const messageVariable = variables.filter((variable) => variable.type === 'message_variable');
    const dataExtractorVariables = variables.filter(
      (variable) => variable.type === SuggestionTypeEnum.DATA_EXTRACTOR
    );
    const textVariables = variables.filter(
      (variable) =>
        (variable.type === 'text_input_variable' ||
          variable.type === 'gptbox_variable' ||
          variable.type === 'apibox_variable') &&
        variable.option_type === 'text'
    );

    const knowledgeBases = this.props.knowledgeBases || [];
    const notes = this.props.notes;
    const labData = this.props.labData;
    const vitalSigns = this.props.vitalSigns;
    const demographics = this.props.demographics;

    if (variableName === 'variableDropDownEl' && choiceVariables.length === 0) {
      toast.warning(CustomToast, { data: 'There is no choice variable in this module' });
    } else if (variableName === 'multiVariableDropDownEl' && multiVariables.length === 0) {
      toast.warning(CustomToast, { data: 'There is no multi variable in this module' });
    } else if (variableName === 'msgVariableDropDownEl' && messageVariable.length === 0) {
      toast.warning(CustomToast, { data: 'There is no message variable in this module' });
    } else if (variableName === 'formulaDropDownEl' && formulas.length === 0) {
      toast.warning(CustomToast, { data: 'There is no formula in this module' });
    } else if (variableName === 'numericsDropDownEl' && numerics.length === 0) {
      toast.warning(CustomToast, { data: 'There is no numeric in this module' });
    } else if (variableName === 'textVariableDropDownEl' && textVariables.length === 0) {
      toast.warning(CustomToast, { data: 'There is no text variable in this module' });
    } else if (variableName === 'knowledgeBasesDropDownEl' && knowledgeBases.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available knowledge base in the team' });
    } else if (variableName === 'notesDropDownEl' && notes.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available Notes' });
    } else if (variableName === 'labDataDropDownEl' && labData.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available lab data' });
    } else if (variableName === 'vitalSignsDropDownEl' && vitalSigns.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available vital signs' });
    } else if (variableName === 'demographicsDropDownEl' && demographics.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available demographics' });
    } else if (variableName === 'ehrOrdersDropDownEl' && vitalSigns.length === 0) {
      toast.warning(CustomToast, { data: 'There is no available EHR orders' });
    } else if (
      variableName === 'dataExtractorVariableDropDownEl' &&
      dataExtractorVariables.length === 0
    ) {
      toast.warning(CustomToast, { data: 'There is no data extractor variable in this module' });
    } else {
      this.setState({ [variableName]: e.target });
    }
  };

  addVariable = (obj) => {
    this.setState(this.defaultState);
    this.props.addMention(obj);
  };

  render() {
    const formulas = this.props.formulaState?.formulas || [];
    const customNumerics = this.props.customNumericState?.customNumerics || [];
    const numerics = this.props.numerics || [];

    const variables = this.props.variables || [];
    const choiceVariables = variables.filter((variable) => variable.type === 'choice_variable');
    const multiVariables = variables.filter((variable) => variable.type === 'multi_variable');
    const messageVariables = variables.filter((variable) => variable.type === 'message_variable');
    const dataExtractorVariables = variables.filter(
      (variable) => variable.type === SuggestionTypeEnum.DATA_EXTRACTOR
    );
    const textVariables = variables.filter(
      (variable) =>
        (variable.type === 'text_input_variable' ||
          variable.type === 'gptbox_variable' ||
          variable.type === 'apibox_variable') &&
        variable.option_type === 'text'
    );

    const knowledgeBases = (this.props.knowledgeBases || []).filter((kb) => kb.code !== 'heading');
    const notes = this.props.notes;
    const labData = this.props.labData;
    const vitalSigns = this.props.vitalSigns;
    const medications = this.props.medications;
    const problemList = this.props.problemList;
    const demographics = this.props.demographics;
    const gptBox = this.props.gptBox ?? false;
    const textInput = this.props.textInput ?? false;
    const conditionalText = !!this.props.conditionalText;
    const hasEHRVariables = this.props.hasEHRVariables;

    return (
      <React.Fragment>
        <StyledTooltip title='Add Variable' placement='top'>
          <span>
            <VariableButton
              disabled={this.props.isDisabled}
              endIcon={<ArrowIcon />}
              type='button'
              onClick={(e) => this.setState({ variablesDropDownEl: e.target })}
              data-testid='variable-button'
            >
              {'{x}'}
            </VariableButton>
          </span>
        </StyledTooltip>

        <div data-testid='variable-menu'>
          <Menu
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableScrollLock
            disableEnforceFocus
            getContentAnchorEl={null}
            variant='menu'
            anchorEl={this.state.variablesDropDownEl}
            keepMounted
            open={Boolean(this.state.variablesDropDownEl)}
            onClose={() => this.setState({ variablesDropDownEl: null })}
          >
            {!textInput && (
              <>
                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'variableDropDownEl')}
                >
                  Choice Variable
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>

                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'multiVariableDropDownEl')}
                >
                  Multi Variable
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>

                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'textVariableDropDownEl')}
                >
                  Text Variable
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>

                <FeaturedStyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'msgVariableDropDownEl')}
                >
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-5}>
                    Message Variable
                  </StaffOnlyWrapper>
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </FeaturedStyledMenuItem>

                <FeaturedStyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'dataExtractorVariableDropDownEl')}
                >
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-5}>
                    Data Extractor Variable
                  </StaffOnlyWrapper>
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </FeaturedStyledMenuItem>

                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'formulaDropDownEl')}
                >
                  Formulae
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>

                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'numericsDropDownEl')}
                >
                  Numerics
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>

                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'customNumericsDropDownEl')}
                >
                  Custom Numerics
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>
                {(gptBox || conditionalText) && (
                  <StyledMenuItem
                    style={{ display: 'flex', fontSize: 15 }}
                    onClick={(e) => this.handleClick(e, 'knowledgeBasesDropDownEl')}
                  >
                    Knowledge Bases
                    <ChevronRight style={{ marginLeft: 'auto' }} />
                  </StyledMenuItem>
                )}
              </>
            )}
            {hasEHRVariables && (
              <>
                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'notesDropDownEl')}
                >
                  Notes (EHR)
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>
                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'labDataDropDownEl')}
                >
                  Lab Data (EHR)
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>
                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'vitalSignsDropDownEl')}
                >
                  Vital Signs (EHR)
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>
                <FeaturedStyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'medicationsDropDownEl')}
                >
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-5}>
                    Medications (EHR)
                  </StaffOnlyWrapper>
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </FeaturedStyledMenuItem>
                <StyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'problemListDropDownEl')}
                >
                  Problem List (EHR)
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </StyledMenuItem>
                <FeaturedStyledMenuItem
                  style={{ display: 'flex', fontSize: 15 }}
                  onClick={(e) => this.handleClick(e, 'demographicsDropDownEl')}
                >
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-5}>
                    Demographics (EHR)
                  </StaffOnlyWrapper>
                  <ChevronRight style={{ marginLeft: 'auto' }} />
                </FeaturedStyledMenuItem>
              </>
            )}
            {!this.context.excludedSuggestionTypes.includes('ehr_order') && (
              <FeaturedStyledMenuItem
                style={{ display: 'flex', fontSize: 15 }}
                onClick={(e) => this.handleClick(e, 'ehrOrdersDropDownEl')}
              >
                EHR Orders
                <ChevronRight style={{ marginLeft: 'auto' }} />
              </FeaturedStyledMenuItem>
            )}
          </Menu>
        </div>

        {formulas && formulas.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.formulaDropDownEl}
            open={Boolean(this.state.formulaDropDownEl)}
            onClose={() => this.setState({ formulaDropDownEl: null })}
          >
            <SearchableDropdown items={formulas} addItem={this.addVariable} />
          </Popover>
        )}

        {choiceVariables && choiceVariables.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.variableDropDownEl}
            open={Boolean(this.state.variableDropDownEl)}
            onClose={() => this.setState({ variableDropDownEl: null })}
          >
            <SearchableDropdown isVariable items={choiceVariables} addItem={this.addVariable} />
          </Popover>
        )}

        {multiVariables && multiVariables.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.multiVariableDropDownEl}
            open={Boolean(this.state.multiVariableDropDownEl)}
            onClose={() => this.setState({ multiVariableDropDownEl: null })}
          >
            <SearchableDropdown isVariable items={multiVariables} addItem={this.addVariable} />
          </Popover>
        )}

        {textVariables && textVariables.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.textVariableDropDownEl}
            open={Boolean(this.state.textVariableDropDownEl)}
            onClose={() => this.setState({ textVariableDropDownEl: null })}
          >
            <SearchableDropdown isVariable items={textVariables} addItem={this.addVariable} />
          </Popover>
        )}

        {messageVariables && messageVariables.length > 0 && (
          <FeaturedPopover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.msgVariableDropDownEl}
            open={Boolean(this.state.msgVariableDropDownEl)}
            onClose={() => this.setState({ msgVariableDropDownEl: null })}
          >
            <SearchableDropdown isVariable items={messageVariables} addItem={this.addVariable} />
          </FeaturedPopover>
        )}

        {dataExtractorVariables && dataExtractorVariables.length > 0 && (
          <FeaturedPopover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.dataExtractorVariableDropDownEl}
            open={Boolean(this.state.dataExtractorVariableDropDownEl)}
            onClose={() => this.setState({ dataExtractorVariableDropDownEl: null })}
          >
            <SearchableDropdown
              isVariable
              items={dataExtractorVariables}
              addItem={this.addVariable}
            />
          </FeaturedPopover>
        )}

        {numerics && numerics.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant='selected'
            disableAutoFocus
            disableAutoFocusItem
            disableRestoreFocus
            disableEnforceFocus
            getContentAnchorEl={null}
            anchorEl={this.state.numericsDropDownEl}
            open={Boolean(this.state.numericsDropDownEl)}
            onClose={() => this.setState({ numericsDropDownEl: null })}
          >
            <SearchableDropdown items={numerics} addItem={this.addVariable} />
          </Popover>
        )}

        {customNumerics && customNumerics.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.customNumericsDropDownEl}
            open={Boolean(this.state.customNumericsDropDownEl)}
            onClose={() => this.setState({ customNumericsDropDownEl: null })}
          >
            <SearchableDropdown items={customNumerics} addItem={this.addVariable} />
          </Popover>
        )}

        {knowledgeBases && knowledgeBases.length > 0 && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.knowledgeBasesDropDownEl}
            open={Boolean(this.state.knowledgeBasesDropDownEl)}
            onClose={() => this.setState({ knowledgeBasesDropDownEl: null })}
          >
            <SearchableDropdown isVariable items={knowledgeBases} addItem={this.addVariable} />
          </Popover>
        )}

        {hasEHRVariables && (
          <>
            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.notesDropDownEl}
              open={Boolean(this.state.notesDropDownEl)}
              onClose={() => this.setState({ notesDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={notes} addItem={this.addVariable} />
            </Popover>

            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.labDataDropDownEl}
              open={Boolean(this.state.labDataDropDownEl)}
              onClose={() => this.setState({ labDataDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={labData} addItem={this.addVariable} />
            </Popover>
            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.vitalSignsDropDownEl}
              open={Boolean(this.state.vitalSignsDropDownEl)}
              onClose={() => this.setState({ vitalSignsDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={vitalSigns} addItem={this.addVariable} />
            </Popover>
            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.medicationsDropDownEl}
              open={Boolean(this.state.medicationsDropDownEl)}
              onClose={() => this.setState({ medicationsDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={medications} addItem={this.addVariable} />
            </Popover>
            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.problemListDropDownEl}
              open={Boolean(this.state.problemListDropDownEl)}
              onClose={() => this.setState({ problemListDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={problemList} addItem={this.addVariable} />
            </Popover>
            <Popover
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              anchorEl={this.state.demographicsDropDownEl}
              open={Boolean(this.state.demographicsDropDownEl)}
              onClose={() => this.setState({ demographicsDropDownEl: null })}
            >
              <SearchableDropdown isVariable items={demographics} addItem={this.addVariable} />
            </Popover>
          </>
        )}
        {!this.context.excludedSuggestionTypes.includes('ehr_order') && (
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            anchorEl={this.state.ehrOrdersDropDownEl}
            open={Boolean(this.state.ehrOrdersDropDownEl)}
            onClose={() => this.setState({ ehrOrdersDropDownEl: null })}
          >
            <SearchableDropdown
              isVariable
              items={this.context.suggestions.filter(
                (suggestion) => suggestion.type === 'ehr_order'
              )}
              addItem={this.addVariable}
            />
          </Popover>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formulaState: state.formulaState,
  customNumericState: state.customNumericState,
});

export default withEHRVariables(connect(mapStateToProps, {})(VariableOptions));

const FeaturedStyledMenuItem = withFeaturePermission(StyledMenuItem);
const FeaturedPopover = withFeaturePermission(Popover);
