import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext } from 'react';
import { UiElementContext } from '../../../module/UiElementContext';
import { moduleConversionAPI } from '../../../../../api/module-conversion';
import { toast } from 'react-toastify';
import { CustomToast } from '../../../toast-message';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { Caption2 } from '../../../typo';
import { AvoSwitch } from '../../../AvoSwitch';
import Button from '../../../Button';
import { useAuthentication } from '../../../../../hooks/useAuthentication';
import { Infobox, resourcesAPI } from '../../../../../api/resources';
import { MODULE_TYPES } from '../../../../../constants';
import { ConvertingItems, getPayload } from './utils';

/** draft to tiptap converting button */
export function ConvertingButton({
  isConverted,
  setIsConverted,
}: {
  isConverted: boolean;
  setIsConverted: (bool: boolean) => void;
}) {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { uiElements, answerElements } = useContext(UiElementContext);
  const resources = useDraftJSResoureces();
  const { mutate: convertToTiptap } = useMutation(moduleConversionAPI.convertToTiptap, {
    onSuccess: () => {
      setTimeout(() => queryClient.resetQueries([module?.type, module?.id]), 1000);
      toast.success(CustomToast, { data: 'Successfully converted' });
    },
  });
  const { mutate: convertToDraftJS } = useMutation(moduleConversionAPI.convertToDraftJS, {
    onSuccess: async () => {
      await queryClient.resetQueries([module?.type, module?.id]);
      toast.success(CustomToast, { data: 'Successfully reverted' });
    },
  });
  const handleClick = () => {
    if (
      window.confirm(
        'Are you sure you want to convert?\nWarning: This button affects the entire algo. For authorized personnel only.'
      )
    ) {
      const convertingItems: ConvertingItems = {
        moduleId: module!.id,
        uiElements,
        answerElements,
        ...resources,
      };
      convertToTiptap(getPayload(convertingItems));
    }
  };

  const handleRemove = () => {
    if (module?.use_new_editor && !module.has_converted) {
      toast.error(CustomToast, { data: 'This module uses only Tiptap and cannot be converted.' });
      return;
    }
    if (
      window.confirm(
        'Are you sure you want to revert?\nWarning: This button affects the entire algo. For authorized personnel only.'
      )
    ) {
      convertToDraftJS({ moduleId: module!.id });
    }
  };

  if (module?.type === MODULE_TYPES.CALCULATOR) {
    return null;
  }

  if (module?.use_new_editor && !module?.has_converted) {
    return null;
  }

  return (
    <AuthorizedUserOnlyWrapper>
      <div className='max-w-[164px] p-3'>
        {!module?.use_new_editor && (
          <>
            <Caption2>Convert DraftJS to Tiptap</Caption2>
            <AvoSwitch checked={isConverted} onChange={setIsConverted} />
          </>
        )}
        <div className='pt-2'>
          {!module?.use_new_editor ? (
            <Button onClick={handleClick} type='button' className='px-0 text-[10px]'>
              Convert to Tiptap
            </Button>
          ) : (
            <Button.Reverse onClick={handleRemove} type='button' className='px-0 text-[10px]'>
              Revert to DraftJS
            </Button.Reverse>
          )}
        </div>
      </div>
    </AuthorizedUserOnlyWrapper>
  );
}

export function AuthorizedUserOnlyWrapper({ children }: { children: React.ReactNode }) {
  const { authentication } = useAuthentication();
  const allowedUsers = ['dahun@avomd.io', 'sj@avomd.io', 'stella@avomd.io'];

  return (
    <>
      {allowedUsers.includes(authentication.user?.email || '') && (
        <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>{children}</StaffOnlyWrapper>
      )}
    </>
  );
}

function useDraftJSResoureces() {
  const { module } = useContext(ModuleContext);
  const { authentication } = useAuthentication();

  const { data: triggers } = useQuery(
    [module?.type, module?.id, 'triggers'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getTriggers
      : resourcesAPI.getCalculatorTriggers,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
      select: (data) =>
        data.filter((trigger) => trigger.title !== 'Always On' && !trigger.candidate_trigger),
    }
  );

  const { data: images } = useQuery(
    [module?.type, module?.id, 'medias'],
    module?.type === MODULE_TYPES.ALGO ? resourcesAPI.getMedia : resourcesAPI.getCalculatorMedia,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
    }
  );

  const { data: infoboxes } = useQuery(
    [module?.type, module?.id, 'infoboxes'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getInfoboxes
      : resourcesAPI.getCalculatorInfoboxes,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
    }
  );

  const { data: smartNotes } = useQuery(
    [module?.type, module?.id, 'smart-notes'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getSmartNotes
      : resourcesAPI.getCalculatorSmartNotes,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
    }
  );

  const { data: formulas } = useQuery(
    [module?.type, module?.id, 'formulas'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getFormulas
      : resourcesAPI.getCalculatorFormulas,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
    }
  );

  const { data: conditionalTexts } = useQuery(
    [module?.type, module?.id, 'conditional-texts'],
    module?.type === MODULE_TYPES.ALGO
      ? resourcesAPI.getConditionalTexts
      : resourcesAPI.getCalculatorConditionalTexts,
    {
      enabled: !!module && authentication.user?.is_staff,
      initialData: [],
    }
  );

  return {
    triggers,
    images,
    infoboxes: [...infoboxes, ...smartNotes] as Infobox[],
    formulas,
    conditionalTexts,
  };
}
