import clsx from 'clsx';
import { LabelHTMLAttributes } from 'react';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

const Label = ({ children, className }: LabelProps) => {
  return (
    <label
      className={clsx(
        className,
        'select-none cursor-pointer',
        'text-base text-gray-900 mb-0' // Override Material UI
      )}
    >
      {children}
    </label>
  );
};

export default Label;
