import { AlgoUpsert } from 'pages/algo/upsert';
import { CalculatorUpsert } from 'pages/calculator/upsert';
import { KnowledgeBaseUpsert } from 'pages/knowledge-base/upsert';
import { Flow } from 'pages/module/Flow';
import { Output } from 'pages/module/Output';
import { Apis } from 'pages/module/resources/Apis';
import { ConditionalTexts } from 'pages/module/resources/ConditionalTexts';
import { CustomNumbers } from 'pages/module/resources/CustomNumbers';
import { Formulas } from 'pages/module/resources/Formulas';
import { Infoboxes } from 'pages/module/resources/Infoboxes';
import { Integrations } from 'pages/module/resources/Integrations';
import { References } from 'pages/module/resources/References';
import { SmartNotes } from 'pages/module/resources/SmartNotes';
import { Triggers } from 'pages/module/resources/Triggers';
import {
  ALGO,
  ALGO_CREATE,
  CALCULATOR,
  CALCULATOR_CREATE,
  KNOWLEDGE_BASE,
  KNOWLEDGE_BASE_CREATE,
  MODULE_APIS,
  MODULE_CONDITIONAL_TEXTS,
  MODULE_CUSTOM_NUMBERS,
  MODULE_FLOW,
  MODULE_FORMULAS,
  MODULE_INFOBOXES,
  MODULE_INTEGRATIONS,
  MODULE_OUTPUT,
  MODULE_REFERENCES,
  MODULE_SMART_NOTES,
  MODULE_TRIGGERS,
} from 'routes/urls';

export const moduleRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: ALGO_CREATE,
      component: AlgoUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: ALGO,
      component: AlgoUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_FLOW,
      component: Flow,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_OUTPUT,
      component: Output,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_TRIGGERS,
      component: Triggers,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_INFOBOXES,
      component: Infoboxes,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_FORMULAS,
      component: Formulas,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_REFERENCES,
      component: References,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_SMART_NOTES,
      component: SmartNotes,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_CUSTOM_NUMBERS,
      component: CustomNumbers,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_INTEGRATIONS,
      component: Integrations,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_CONDITIONAL_TEXTS,
      component: ConditionalTexts,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: MODULE_APIS,
      component: Apis,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALCULATOR_CREATE,
      component: CalculatorUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALCULATOR,
      component: CalculatorUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: KNOWLEDGE_BASE_CREATE,
      component: KnowledgeBaseUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: KNOWLEDGE_BASE,
      component: KnowledgeBaseUpsert,
    },
  },
];
