import axios from 'axios';
import { store } from 'store';
import { CALCULATOR_API_URL, MODULE_API_URL } from '../constants';

export const getVariables = async (id, type = '', forTextEditor = true) => {
  let API_URL =
    type === 'calculator'
      ? `${CALCULATOR_API_URL}${id}/variables`
      : `${MODULE_API_URL}${id}/variables`;

  API_URL = forTextEditor ? `${API_URL}?for_text_editor=True` : API_URL;
  const res = await axios.get(API_URL);
  return res.data;
};

export const getNumerics = async (moduleId, type = '') => {
  let res;
  if (type === 'calculator') {
    res = await axios.get(CALCULATOR_API_URL + moduleId + '/numerics');
  } else {
    res = await axios.get(MODULE_API_URL + moduleId + '/numerics');
  }

  return res.data;
};

export const getCustomNumericSuggestions = () => {
  const customNumerics = store.getState().customNumericState.customNumerics;
  let suggestions = [{ name: 'CUSTOM NUMERICS', code: 'CUSTOM NUMERICS' }];

  customNumerics.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.name,
    });
  });

  return suggestions;
};

export const getFormulaSuggestions = (formulaId = undefined) => {
  const formulas = store.getState().formulaState.formulas;
  let suggestions = [{ name: 'FORMULAE', code: 'FORMULAE' }];

  formulas.forEach((obj) => {
    if (obj.id !== formulaId) {
      suggestions.push({
        code: obj.unique_code,
        name: obj.shortened_name,
      });
    }
  });

  return suggestions;
};

export const getInfoboxSuggestions = () => {
  const infoBoxes = store.getState().infoboxState.infoBoxes;
  let suggestions = [{ name: 'INFOBOXES', code: 'INFOBOXES' }];

  infoBoxes.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.shortened_title,
    });
  });

  return suggestions;
};

export const getReferenceSuggestions = () => {
  const refmanagers = store.getState().refManagerState.refmanagers || [];
  let suggestions = [{ name: 'REFERENCES', code: 'REFERENCES' }];

  refmanagers.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.shortened_source,
    });
  });

  return suggestions;
};

export const getMediaSuggestions = () => {
  const images = store.getState().mediaState.images;
  let suggestions = [{ name: 'MEDIA', code: 'MEDIA' }];

  images.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.representation_phrase,
    });
  });

  return suggestions;
};

export const getNumericSuggestions = async (moduleId, type = '') => {
  const numerics = await getNumerics(moduleId, type);
  let suggestions = [{ name: 'NUMERICS', code: 'NUMERICS' }];

  numerics.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.title,
    });
  });

  return suggestions;
};

export const getVariableSuggestions = async (moduleId, type = '', forTextEditor = true) => {
  const variables = await getVariables(moduleId, type, forTextEditor);
  let suggestions = [{ name: 'VARIABLES', code: 'VARIABLES' }];

  variables.forEach((obj) => {
    suggestions.push({
      code: obj.unique_code,
      name: obj.name,
      type: obj.type,
    });
  });

  return suggestions;
};

export const getKB = () => {
  return store.getState().knowledgeBaseState.knowledgeBases || [];
};

const getKBSuggestions = () => {
  const knowledgeBases = getKB();
  let suggestions = [{ name: 'KNOWLEDGE_BASES', code: 'KNOWLEDGE_BASES' }];

  knowledgeBases.forEach((obj) => {
    suggestions.push({
      code: obj.code,
      name: obj.name,
      type: 'knowledge_base',
    });
  });

  return suggestions;
};

export const getSuggestions = async (
  moduleId,
  listKB = false,
  type = '',
  listInfoboxes = true,
  formulaId = undefined,
  forTextEditor = true,
  fromFormula = false
) => {
  const formulaSuggestions = getFormulaSuggestions(formulaId);
  const variableSuggestions = await getVariableSuggestions(moduleId, type, forTextEditor);
  const numericSuggestions = await getNumericSuggestions(moduleId, type);
  const customNumericSuggestions = getCustomNumericSuggestions();
  const referenceSuggestions = getReferenceSuggestions();
  const mediaSuggestions = getMediaSuggestions();

  let textEditorsuggestions = [
    ...formulaSuggestions,
    ...variableSuggestions,
    ...numericSuggestions,
    ...customNumericSuggestions,
  ];

  if (!fromFormula) {
    textEditorsuggestions = [
      ...textEditorsuggestions,
      ...referenceSuggestions,
      ...mediaSuggestions,
    ];
  }

  if (listInfoboxes === true) {
    const infoboxSuggestions = getInfoboxSuggestions();
    textEditorsuggestions = [...textEditorsuggestions, ...infoboxSuggestions];
  }

  if (listKB === true) {
    const KBSuggestions = getKBSuggestions();
    textEditorsuggestions = [...textEditorsuggestions, ...KBSuggestions];
  }

  return textEditorsuggestions;
};
