import { Divider } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Trigger } from 'api/resources';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { twJoin } from 'tailwind-merge';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ResourceTypes, UIElement } from '../../../api/moduleElement';
import {
  ADD_ITEM_BUTTON_IN_GROUP_CARD,
  CARD_AMBIENT_LISTENING,
  CARD_API_BOX,
  CARD_CHECKBOXES,
  CARD_DATA_EXTRACTOR,
  CARD_DELETE_BUTTON,
  CARD_EDIT_BUTTON,
  CARD_GPT_BOX,
  CARD_MESSAGE,
  CARD_MULTIPLE_CHOICE,
  CARD_NUMBERS,
  CARD_TEXT_INPUT,
  MODULE_TYPES,
} from '../../../constants';
import { Trigger as TriggerComponent } from '../../app-previews/Trigger';
import { VariablesPreview } from '../../app-previews/common/variable';
import ContainerCardForm from '../../module-detail/container/ContainerCardForm';
import { getGroupVariables } from '../../module-detail/container/GroupCardUtil';
import { StyledMenuItem } from '../../module-detail/container/styles';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { CreateButton } from '../../utils/styled-components/FormStyle';
import { CardBoard } from '../CardBoard';
import { CONTAINER_CARD, ModuleElementContext } from '../ModuleBoard';

interface FormValues {
  id?: string;
  name: string;
  variable: string;
  trigger?: Trigger;
  module?: number;
  position?: number;
  ui_elements: UIElement[];
}

interface ContainerCardProps {
  element: UIElement;
}

export const ContainerCard = ({ element }: ContainerCardProps) => {
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, deleteUIElement, clearModuleElements, addModuleElement } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorCreateEl, setAnchorCreateEl] = useState<Element | null>(null);

  const nextChildPosition = useMemo(
    () =>
      element.ui_elements?.length > 0
        ? element.ui_elements[element.ui_elements.length - 1].position + 1
        : 0,
    [element]
  );

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo(
    () => ({
      name: element.name ?? '',
      variable: element.variable ?? '',
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      position: element.position,
      ui_elements: element.ui_elements ?? [],
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handleDelete = (id) => {
    deleteUIElement(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePanelClose = () => {
    form.reset(defaultValues);
    setPanelOpen(false);
    clearModuleElements();
  };

  const onSave = () => {
    refetchModuleElements();
    setPanelModalOpen(false);
    setPanelOpen(false);
  };

  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  return (
    <div className={twJoin(panelOpen && 'relative z-30', 'container-row max-w-[796px]')}>
      <TriggerComponent trigger={form.watch('trigger')} containerCard={false} />
      <div className='container-card col-md-12 '>
        <div className='card-content text-justify'>
          <div>
            <ConfirmModal
              preset='delete'
              open={deleteModal}
              content='Are you sure you want to delete this container?'
              toggleModal={() => setDeleteModal(false)}
              performAction={() => {
                handleDelete(element.id);
                setDeleteModal(false);
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                height: '100px',
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
              }}
              className='form-to-hover'
            >
              {!hasViewOnlyPermission() && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    marginRight: 31,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right form-to-show'
                >
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
            </div>
            <Typography
              style={{
                paddingLeft: '25px',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '26px',
                lineHeight: '30px',
                color: '#000000',
              }}
            >
              {form.watch('name')}
              <Menu
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    handleClose();
                  }}
                >
                  {CARD_EDIT_BUTTON}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteModal(true);
                    handleClose();
                  }}
                >
                  {CARD_DELETE_BUTTON}
                </MenuItem>
              </Menu>
            </Typography>
            {form.watch('name') && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <Divider
                  style={{
                    marginTop: '30px',
                  }}
                />
              </div>
            )}

            {element.ui_elements && (
              <CardBoard
                droppableId={`${CONTAINER_CARD}-${element.id}`}
                moduleElements={element.ui_elements}
                containerId={element.id}
              />
            )}

            {!hasViewOnlyPermission() && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  marginTop: '20px',
                }}
              >
                <CreateButton onClick={(e) => setAnchorCreateEl(e.target as Element)}>
                  {ADD_ITEM_BUTTON_IN_GROUP_CARD}
                </CreateButton>
                <Menu
                  anchorEl={anchorCreateEl}
                  open={Boolean(anchorCreateEl)}
                  onClose={() => setAnchorCreateEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null} // to prevent warning message
                >
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.multipleChoice,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_MULTIPLE_CHOICE}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.gptBox,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_GPT_BOX}
                  </StyledMenuItem>
                  {false && (
                    <StyledMenuItem
                      onClick={() => {
                        addModuleElement({
                          parentId: element.id,
                          position: nextChildPosition,
                          resourcetype: ResourceTypes.apiBox,
                        });
                        setAnchorCreateEl(null);
                      }}
                    >
                      {CARD_API_BOX}
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.checkboxes,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_CHECKBOXES}
                  </StyledMenuItem>
                  {/* TODO: delete below comment as Group Card is not used in Calculator */}
                  {/* {!props.calculatorId && ( */}
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.message,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_MESSAGE}
                  </StyledMenuItem>
                  {/* )} */}
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.numbers,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_NUMBERS}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.textInput,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_TEXT_INPUT}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.ambientListening,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_AMBIENT_LISTENING}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      addModuleElement({
                        parentId: element.id,
                        position: nextChildPosition,
                        resourcetype: ResourceTypes.dataExtractor,
                      });
                      setAnchorCreateEl(null);
                    }}
                  >
                    {CARD_DATA_EXTRACTOR}
                  </StyledMenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
      <VariablesPreview variables={getGroupVariables(element.ui_elements ?? [])} />

      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='h-full w-[620px] overflow-x-visible bg-white'>
          <ContainerCardForm
            setTitle={(title: string) => form.setValue('name', title)}
            setTriggerId={(triggerId: string) => setTriggerId(triggerId)}
            moduleId={module?.id}
            containerCardId={element.id}
            position={element.position}
            toggleModal={handlePanelClose}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
            onSave={onSave}
          />
        </div>
      </SlidePanel>
    </div>
  );
};
