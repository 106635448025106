import clsx from 'clsx';
import { Icons } from 'components/utils/Icons';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium';
}

const LoadingSpinner = ({ size = 'medium' }: LoadingSpinnerProps) => {
  return (
    <div
      className={clsx('aspect-square', { 'h-4': size === 'small' }, { 'h-8': size === 'medium' })}
    >
      <Icons.LoadingSpinnerGreen />
    </div>
  );
};

export default LoadingSpinner;
