import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {}

const Tooltip = ({ className, children }: TooltipProps) => {
  return (
    <div
      className={twMerge(
        className,
        'group relative',
        '!border-0 !bg-transparent !p-0' // due to material ui
      )}
    >
      {children}
    </div>
  );
};

export default Tooltip;

const TooltipIcon = ({ className, children }: TooltipProps) => {
  return <div className={twMerge(className, 'peer')}>{children}</div>;
};

const TooltipContent = ({ className, children }: TooltipProps) => {
  return (
    <div
      className={twMerge(
        className,
        'invisible absolute left-full top-1/2 -translate-y-1/2 translate-x-3 opacity-0 transition-all peer-hover:!visible peer-hover:!opacity-100', // for tooltip functionality
        'whitespace-nowrap rounded bg-stone-600 p-2 text-white', // for tooltip style
        'before:absolute before:left-0 before:top-3 before:h-3 before:-translate-x-1 before:rotate-45 before:bg-stone-600' // for tooltip triangle
      )}
    >
      {children}
    </div>
  );
};

Tooltip.Icon = TooltipIcon;
Tooltip.Content = TooltipContent;
