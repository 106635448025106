import { Box, Button, CircularProgress, Popover, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const PrimaryButton = withStyles((theme) => ({
  root: {
    padding: '8px 10px',
    background: '#08A88E',
    color: '#FFFFFF',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 14,

    '&:focus': {
      background: '#08A88E',
    },
    '&:hover': {
      background: '#08A88E',
      color: '#FFFFFF',
    },
  },
}))(Button);

export const SecondaryButton = withStyles((theme) => ({
  root: {
    padding: '8px 10px',
    color: '#08A88E',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 14,
    marginRight: 10,

    '&:hover': {
      color: '#08A88E',
      background: '#ffffff',
    },
  },
}))(Button);

export const ShareLinkMessage = withStyles((theme) => ({
  root: {
    color: '#1E1F20',
    fontWeight: 400,
    fontSize: 14,
  },
}))(Typography);

export const ButtonContainer = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: 20,
  },
}))(Box);

export const LinkField = withStyles((theme) => ({
  root: {
    background: '#ffffff',
    minWidth: '520px',
    color: '#566267',
    border: '1px solid #E5EDF0',
    borderRadius: '4px',
    padding: '10px 12px',
    marginRight: 4,
    fontWeight: 400,
    fontSize: 14,
  },
}))(Typography);

export const Loader = withStyles((theme) => ({
  root: {
    color: '#08A88E',
    position: 'absolute',
    top: '30%',
    left: '45%',
  },
}))(CircularProgress);

export const StyledPopover = withStyles((theme) => ({
  paper: {
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
  },
}))(Popover);
