import { useEffect } from 'react';
import {
  BooleanVariable,
  ListVariable,
  NumberVariable,
  TextVariable,
  VariablesToGenerateTypes,
} from '../../../../api/data-extractor';
import Label from '../../../utils/Label';
import { RadioButton } from '../../../utils/RadioButton';
import Textarea from '../../../utils/textarea';
import { Body1 } from '../../../utils/typo';

interface DefaultValueInputProps {
  item: TextVariable | NumberVariable | BooleanVariable | ListVariable;
  onChange: (data: TextVariable | NumberVariable | BooleanVariable | ListVariable) => void;
}

type VariableTypes = TextVariable | NumberVariable | BooleanVariable | ListVariable;

export const DefaultValueInput = ({ item, onChange }: DefaultValueInputProps) => {
  useEffect(() => {
    // Set the default value of 'default_value' if its value is not set.
    const defaultValues = {
      [VariablesToGenerateTypes.LIST]: [],
      [VariablesToGenerateTypes.BOOLEAN]: false,
    };

    if (item.default_value === undefined) {
      onChange({ ...item, default_value: defaultValues[item.variable_type] });
    }
  }, []);

  const handleChange = (default_value?: [] | string | number | false | null): void => {
    switch (item.variable_type) {
      case VariablesToGenerateTypes.BOOLEAN:
        onChange({ ...item, default_value: default_value === null ? null : false });
        break;
      case VariablesToGenerateTypes.LIST:
        onChange({ ...item, default_value: default_value === null ? null : [] });
        break;
      default:
        onChange({ ...item, default_value } as VariableTypes);
        break;
    }
  };

  return (
    <div className='w-full rounded border border-gray-200 bg-gray-bg-strong'>
      <Label className='flex gap-[10px] rounded bg-gray-100 px-[12px] py-[10px]'>
        <div className='flex h-[24px] w-[24px] items-center justify-center'>
          <RadioButton
            name='default_value'
            onChange={() => handleChange(null)}
            defaultChecked={item.default_value === null}
          />
        </div>
        <Body1 className='text-gray-900'>N/A</Body1>
      </Label>
      <Label className='flex gap-[10px] rounded bg-gray-100 px-[12px] py-[10px]'>
        <div className='h-full w-[24px]'>
          <div className='flex h-[24px] items-center justify-center'>
            <RadioButton
              name='default_value'
              onChange={() => handleChange(undefined)}
              defaultChecked={item.default_value !== null}
            />
          </div>
        </div>
        <div className='grow space-y-[8px]'>
          <Body1 className='text-gray-900'>
            {item.variable_type === VariablesToGenerateTypes.BOOLEAN
              ? 'Default as False'
              : item.variable_type === VariablesToGenerateTypes.LIST
              ? 'Empty Array'
              : 'Default Value'}
          </Body1>
          {(() => {
            switch (item.variable_type) {
              case 'number':
                return (
                  <Label className='w-full rounded border border-gray-300 bg-white px-[16px] py-[14px]'>
                    <input
                      type='number'
                      className='m-0 !h-[24px] border-b !border-gray-300 text-body-1 shadow-none outline-none'
                      placeholder='Enter number'
                      value={!!item.default_value ? item.default_value.toString() : ''}
                      onChange={(e) => handleChange(e.target.valueAsNumber)}
                      disabled={item.default_value === null}
                    />
                  </Label>
                );
              case 'text':
                return (
                  <Textarea
                    className='h-[154px] w-full rounded border border-gray-300 bg-white px-[12px] py-[10px] outline-none'
                    placeholder='Type text. e.g., Following information [VARIABLE NAME] is not available.'
                    onChange={(e) => handleChange(e.currentTarget.value)}
                    value={(item.default_value as string) ?? ''}
                    readOnly={item.default_value === null}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </Label>
    </div>
  );
};
