import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import { useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';

interface EditContactNoProps {
  prevContactNo: string;
  editor: Editor;
}

export const EditContactNo = ({ prevContactNo, editor }: EditContactNoProps) => {
  const [contactNo, setContactNo] = useState('');

  useEffect(() => {
    setContactNo(prevContactNo || '');
  }, [prevContactNo]);

  const onEdit = () => {
    editor.chain().focus().updateAttributes('mention', { name: contactNo, code: contactNo }).run();
  };

  return (
    <div className='flex w-[340px] flex-col rounded bg-white shadow-04'>
      <div className='flex h-11 w-full items-center justify-center bg-gray-100 !pl-2'>
        <p className='text-caption-1 text-gray-900'> Edit Phone Number </p>
      </div>

      <div className='flex w-full flex-col gap-4 !p-4'>
        <Input
          type='number'
          value={contactNo}
          className='text-body-2 text-gray-900'
          onChange={(event) => setContactNo(event.target.value)}
        />
        <div className='flex justify-end'>
          <Button
            type='button'
            onClick={onEdit}
            disabled={!contactNo}
            className='flex h-9 !w-20 items-center justify-center text-button-1'
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
