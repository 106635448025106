import React from 'react';

export default function PhoneIconForText(props) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.487 14.1383L13.422 10.4423C13.2299 10.2676 12.9774 10.1745 12.7178 10.1825C12.4583 10.1905 12.212 10.2991 12.031 10.4853L9.63804 12.9463C9.06204 12.8363 7.90404 12.4753 6.71204 11.2863C5.52004 10.0933 5.15904 8.93229 5.05204 8.36029L7.51104 5.96629C7.69745 5.78541 7.80617 5.53911 7.8142 5.27949C7.82223 5.01988 7.72892 4.76733 7.55404 4.57529L3.85904 0.511286C3.68408 0.318643 3.44092 0.20179 3.18119 0.185546C2.92146 0.169301 2.66564 0.254944 2.46804 0.424286L0.298038 2.28529C0.125149 2.4588 0.0219574 2.68974 0.00803782 2.93429C-0.00696218 3.18429 -0.292962 9.10629 4.29904 13.7003C8.30504 17.7053 13.323 17.9983 14.705 17.9983C14.907 17.9983 15.031 17.9923 15.064 17.9903C15.3086 17.9766 15.5394 17.8729 15.712 17.6993L17.572 15.5283C17.7421 15.3313 17.8283 15.0757 17.8124 14.816C17.7966 14.5563 17.6798 14.3131 17.487 14.1383Z'
        fill='#08A88E'
      />
    </svg>
  );
}
