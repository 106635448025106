import { InputHTMLAttributes, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

const CircleButton = () => {
  return <div className='h-[17px] w-[17px] rounded-full border !border-gray-400 bg-white' />;
};

export default CircleButton;

const SelectedCircleButton = () => {
  return (
    <div className='mx-[4px] my-[4px] h-[9px] w-[9px] rounded-full bg-primary-500 ring-[2px] ring-primary-500 ring-offset-[2px]' />
  );
};

CircleButton.Selected = SelectedCircleButton;

interface RadioButtonProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'className'> {}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ disabled, ...props }, ref) => {
    return (
      <label
        className={twJoin(
          'flex items-center',
          'm-0', // due to material UI
          !disabled && 'cursor-pointer'
        )}
      >
        <input ref={ref} className='peer hidden' type='radio' disabled={disabled} {...props} />
        <div className='hidden peer-checked:block'>
          <CircleButton.Selected />
        </div>
        <div className='peer-checked:hidden'>
          <CircleButton />
        </div>
      </label>
    );
  }
);
