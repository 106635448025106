import { ModuleLayout } from 'components/layout/ModuleLayout';
import { APIResourceContainer } from 'components/resources/api/APIResourceContainer';

export const Apis = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <APIResourceContainer />
    </ModuleLayout>
  );
};
