import { useState } from 'react';
import ProfileForm from './ProfileForm';
import PasswordChangeForm from './PasswordChangeForm';

const ProfileLayout = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className='w-full max-w-[1024px] mx-auto mb-[110px]'>
      <div className='flex flex-wrap'>
        <div className='w-full p-x-[15px]'>
          <div className='mt-[40px] font-extrabold text-[32px] leading-[36px] tracking-[-0.4px] text-center'>
            Profile
          </div>
        </div>
        <div className='w-full p-x-[15px]'>
          <div className='mt-[10px] text-[14px] leading-[20px] text-center'>
            Setup your account details below
          </div>
        </div>
      </div>
      <div className='max-w-[629px] mb-[20px] mx-auto pt-[7px] px-[10px]'>
        <ProfileForm onOpen={() => setOpen(true)} />
        <PasswordChangeForm open={open} onClose={() => setOpen(false)} />
      </div>
    </div>
  );
};

export default ProfileLayout;
