import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../constants/colors';

export const useStyles = makeStyles({
  button: {
    alignContent: 'center',
    padding: '8px 10px',
    fontWeight: 600,
    fontSize: '14px',
    height: '44px',
    borderRadius: '4px',
    //padding: '12px 16px',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.green,
    },
    '&:active': { backgroundColor: colors.darkGreen },
    '&:focus': { backgroundColor: colors.darkGreen },
    '&:disabled': { backgroundColor: colors.grayishBlue, color: 'white' },
  },
  text: {
    minWidth: '107px',
    color: colors.green,

    '&:hover': {
      backgroundColor: '#DFF5EF',
      color: colors.darkGreen,
    },
    '&:active': {
      backgroundColor: '#A5E5D7',
      color: '#01525E',
    },
    '&:focus': {
      backgroundColor: '#A5E5D7',
      color: '#01525E',
    },
    '&:disabled': {
      color: '#C5D1D8',
      backgroundColor: 'transparent',
    },
  },
  contained: {
    minWidth: '240px',
    backgroundColor: colors.lightGreen,
    '&:hover': {
      backgroundColor: colors.green,
    },
    '&:active': { backgroundColor: colors.darkGreen },
    '&:focus': { backgroundColor: colors.darkGreen },
    '&:disabled': { backgroundColor: colors.grayishBlue, color: 'white' },
  },

  outlined: {
    minWidth: '342px',
    border: `1px solid ${colors.grayishBlue}`,
    color: '#1E1F20',
    '&:hover': {
      backgroundColor: '#F5F8F9',
      border: '1px solid #B0BDC4',
    },
    '&:active': {
      backgroundColor: colors.grayishBlue,
      border: '1px solid #85959D',
    },
    '&:focus': {
      backgroundColor: colors.grayishBlue,
      border: '1px solid #85959D',
    },
    '&:disabled': {
      backgroundColor: '#F5F8F9',
      border: `1px solid ${colors.grayishBlue}`,
      color: colors.grayishBlue,
    },
  },
  small: {
    minWidth: '106px',
    height: '36px',
    padding: '8px 10px',
    fontSize: '14px',
    lineHeight: '20px',
  },
});
