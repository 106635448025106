import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import GifTooltip from 'components/utils/helpInfo/GifTooltip';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const SubButton = withStyles(() => ({
  root: {
    width: 125,
    minHeight: 125,
    textTransform: 'none',
    color: 'black',
    backgroundSize: 'contain',
    border: '2px solid #08A88E',
    //float: 'left',
    marginRight: 10,
    marginBottom: 15,
    marginTop: 10,
    marginLeft: 10,
  },
}))(Button);

interface CreateSubButtonProps {
  buttonText: string;
  buttonImage: string;
  buttonHref: string;
  disabled: boolean;
  toolTipImage: string;
  tootTipHelpText: string;
}

class CreateSubButton extends Component<CreateSubButtonProps> {
  render() {
    const { buttonText, buttonImage, buttonHref, disabled, toolTipImage, tootTipHelpText } =
      this.props;

    return (
      <Link to={buttonHref}>
        <Tooltip
          title={
            <React.Fragment>
              <GifTooltip helpText={tootTipHelpText} image={toolTipImage} />
            </React.Fragment>
          }
          placement='left'
        >
          <span>
            <SubButton
              variant='contained'
              disabled={disabled}
              style={disabled ? { backgroundColor: '#979797', opacity: 0.7 } : {}}
            >
              <div style={{ textAlign: 'center', lineHeight: '15px' }}>
                <div style={{ marginLeft: 15 }}>
                  <img src={buttonImage} alt={buttonText} width='60' height='60' />
                </div>
                <div style={{ color: 'black', marginTop: '5px' }}> {buttonText} </div>
                {disabled && <div style={{ color: 'black', fontSize: 10 }}> (Coming Soon) </div>}
              </div>
            </SubButton>
          </span>
        </Tooltip>
      </Link>
    );
  }
}

export default CreateSubButton;
