import axios from 'axios';
import { CALCULATOR_API_URL, MODULE_API_URL } from 'constants';
import { GET_CALCULATOR, GET_MODULE } from '../actionsTypes';
import getUniqueCode from '../unique-code/getUniqueCodeAction';

const getModule =
  (moduleID, moduleType = '', mirrorId = '') =>
  async (dispatch) => {
    let despatchType = moduleType === 'calculator' ? GET_CALCULATOR : GET_MODULE;

    let API_URL =
      moduleType === 'calculator'
        ? `${CALCULATOR_API_URL}${moduleID}/?from_flow_screen=true`
        : `${MODULE_API_URL}${moduleID}/?from_flow_screen=true`;

    if (mirrorId) {
      API_URL = `${API_URL}&mirror_id=${mirrorId}`;
    }

    const res = await axios.get(API_URL);
    dispatch({
      type: despatchType,
      payload: res.data,
    });

    dispatch(getUniqueCode(res.data?.id, moduleType));
  };

export default getModule;
