import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const Heading = withStyles((theme) => ({
  root: {
    fontSize: '32px',
    lineHeight: '36px',
    fontWeight: 600,
    color: '#2E3438',

    '@media (max-width: 375px)': {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
}))(Typography);

export const Small = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 300,
    color: '#85959D',
    marginTop: '20px',

    '@media (max-width: 375px)': {
      marginTop: '16px',
    },
  },
}))(Typography);

export const MainButton = withStyles((theme) => ({
  root: {
    width: '196px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#00CA94',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#FFFFFF',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '45px',
    paddingBottom: '1px',
    '&:hover': {
      background: '#00CA94',
    },

    '@media (max-width: 375px)': {
      marginTop: '36px',
    },
  },
}))(Button);

export const ErrorPage = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 auto',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',

    '@media (max-width: 1339px)': {
      display: 'inline-flex',
      paddingLeft: '32px',
      justifyContent: 'flex-start',
    },

    '@media (max-width: 375px)': {
      display: 'inline-flex',
      paddingLeft: '16px',
      justifyContent: 'flex-start',
    },
  },
}))(Box);

export const ImageContainer = withStyles((theme) => ({
  root: {
    marginLeft: '58px',
    '@media (max-width: 1339px)': {
      display: 'none',
    },

    '@media (max-width: 375px)': {
      display: 'none',
    },
  },
}))(Box);

export const ErrorText = withStyles((theme) => ({
  root: {
    width: '380px',
    marginRight: '58px',
  },
}))(Box);
