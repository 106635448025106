import React from 'react';

export default function TextIcon(props) {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '16'}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0 4H2V2H5.252L2.68 14H0V16H8V14H5.748L8.32 2H12V4H14V0H0V4Z' fill='#8B95A6' />
    </svg>
  );
}
