import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IModuleList } from 'api/module';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import { Icons } from 'components/utils/Icons';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { MODULE_LABELS, MODULE_TYPES } from '../../constants';

interface ChannelModuleListProps {
  modules: IModuleList[];
  onChange?: (modules: IModuleList[]) => void;
}

export const ChannelModuleList = ({ modules, onChange }: ChannelModuleListProps) => {
  const [data, setData] = useState(modules);
  useEffect(() => {
    setData(modules);
  }, [modules]);
  const columns = useMemo<ColumnDef<IModuleList>[]>(
    () => [
      {
        id: 'select-col',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        id: 'moduleName',
        header: 'Module Name',
        accessorKey: 'title',
      },
      {
        id: 'isUniversal',
        accessorKey: 'is_universal',
      },
      {
        id: 'isInCurrentTeam',
        accessorKey: 'is_in_current_team',
      },
      {
        id: 'type',
        header: 'Type',
        accessorKey: 'type',
        cell: (cell) => {
          const isUniversal = cell.row.getValue('isUniversal');
          const isInCurrentTeam = cell.row.getValue('isInCurrentTeam');

          let type = '';
          switch (cell.getValue()) {
            case MODULE_TYPES.MIRROR:
              type = MODULE_LABELS.MIRROR;
              break;
            case MODULE_TYPES.CALCULATOR:
              type = MODULE_LABELS.CALCULATOR;
              break;
            case MODULE_TYPES.KNOWLEDGE_BASE:
              type = MODULE_LABELS.KNOWLEDGE_BASE;
              break;
            default:
            case MODULE_TYPES.ALGO:
              type = MODULE_LABELS.ALGO;
              break;
          }
          return (
            <>
              <div>{type}</div>
              {!isInCurrentTeam && isUniversal && (
                <div className='text-caption-2 text-gray-700'>(Global)</div>
              )}
            </>
          );
        },
      },
      {
        id: 'action',
        header: 'Action',
        accessorKey: 'id',
        cell: (cell) => {
          return (
            <div className='flex'>
              <div
                title='Delete'
                className='cursor-pointer'
                onClick={() => deleteRow(cell.getValue() as number)}
              >
                <Icons.TrashBin />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  const { getHeaderGroups, getRowModel, getSelectedRowModel, reset } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id + row.type,
    initialState: {
      columnVisibility: { password: false, isInCurrentTeam: false, isUniversal: false },
    },
  });

  const selectedRows = getSelectedRowModel();

  const deleteSelectedRows = () => {
    const selectedIds = Object.keys(selectedRows.rowsById);
    setData((prev) => prev.filter((item) => !selectedIds.includes(item.id + item.type)));
  };

  const deleteRow = (id: number) => {
    setData((prev) => prev.filter((item) => id !== item.id));
  };

  useEffect(() => {
    onChange?.(data);
    reset();
  }, [data]);

  return (
    <div className='space-y-[12px]'>
      {data.length > 0 && (
        <div className='flex justify-between'>
          <div>{selectedRows.rows.length} selected</div>
          <div className='flex'>
            <Button.Reverse
              type='button'
              disabled={selectedRows.rows.length === 0}
              onClick={deleteSelectedRows}
            >
              <div className='flex gap-[2px]'>
                <Icons.TrashBin />
                <span>Delete</span>
              </div>
            </Button.Reverse>
          </div>
        </div>
      )}
      <table className='w-full'>
        {getHeaderGroups().map((headerGroup, idx) => (
          <thead className='border-b-2 border-gray-300' key={idx}>
            {headerGroup.headers.map((header) =>
              header.isPlaceholder ? null : (
                <th key={header.id} className='px-[8px] py-[16px] text-left'>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              )
            )}
          </thead>
        ))}
        <tbody>
          {getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <tr className='border-b border-gray-300'>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className='px-[8px] py-[12px]'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      {getRowModel().rows.length === 0 && (
        <div className='col-span-4 py-[40px] text-center'>
          No modules have been added to the list.
        </div>
      )}
    </div>
  );
};
