import { Channels } from 'pages/channels';
import { ChannelUpsert } from 'pages/channels/upsert';
import { FeaturedAuthor } from 'pages/featured-author';
import { Notification } from 'pages/notification';
import { Setting } from 'pages/setting';
import { CustomTags } from 'pages/tags/customs';
import { Tags } from 'pages/tags/default';
import { TeamCreate } from 'pages/teams/create';
import {
  CHANNEL,
  CHANNELS,
  CHANNEL_CREATE,
  CREATE_TEAM,
  CUSTOM_TAGS,
  FEATURED_AUTHOR,
  MEMBERS,
  NOTIFICATION,
  SETTING,
  TAGS,
  EHR_ORDER_INDIVIDUAL,
} from 'routes/urls';
import Members from '../../../pages/members';
import { EHROrderIndividual } from '../../../pages/ehr-order/individual';

export const teamRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: MEMBERS,
      component: Members,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: FEATURED_AUTHOR,
      component: FeaturedAuthor,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: TAGS,
      component: Tags,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CUSTOM_TAGS,
      component: CustomTags,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: SETTING,
      component: Setting,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CREATE_TEAM,
      component: TeamCreate,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CHANNELS,
      component: Channels,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CHANNEL_CREATE,
      component: ChannelUpsert,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CHANNEL,
      component: ChannelUpsert,
    },
  },

  {
    type: 'PRIVATE',
    props: {
      path: NOTIFICATION,
      component: Notification,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: EHR_ORDER_INDIVIDUAL,
      component: EHROrderIndividual,
    },
  },
];
