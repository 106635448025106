import { makeStyles } from '@material-ui/core';

export const conditionalTextStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 20,
  },
  buttonBox: {
    background: '#F5F8F9',
    border: '0.5px solid #C5D1D8',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '3px',
    width: '24px',
    height: '24px',

    display: 'grid',
    placeItems: 'center',
    '&:hover': {
      boxShadow: '-6px 0px 14px rgba(0, 0, 0, 0.03)',
    },
  },
  inlineEditorWrapper: {
    height: '33.39px',
    border: '1px solid #08A88E',
    borderRadius: '3px',
    display: 'flex',
    backgroundColor: 'white !important',
    alignItems: 'center',
  },
  inlineEditorInput: {
    width: '80% !important',
    height: '100% !important',
    margin: '0px !important',

    outline: 'none !important',
    borderBottom: 'none !important',
    textIndent: 10,
    '&:focus': {
      boxShadow: 'none !important',
    },
  },
  conditionTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#191919',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'grab',
    },
  },
  actionContainer: {
    display: 'flex',
    fontSize: 17,
    marginLeft: 'auto',
    cursor: 'pointer',
    zIndex: 200,
  },
  summaryContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    zIndex: 0,
  },
  editIcon: {
    color: '#08A88E',
    marginRight: 5,
  },
  deleteIcon: {
    color: 'red',
  },
  buildBtn: {
    padding: '10px 40px',
    marginLeft: '0.4em',

    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',

    color: 'white',

    width: '112px',
    height: '46px',

    background: '#08A88E !important',
    borderRadius: '5px',

    '&:hover': {
      background: '#08A88E !important ',
    },
    '&:focus': {
      background: '#08A88E !important',
    },
  },
  accordianContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  addBtnTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#BDBDBD',
    marginRight: 14,
  },
});
