import Image from '@tiptap/extension-image';

export const CustomImage = Image.extend({
  inline() {
    return true;
  },

  group() {
    return 'inline';
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: 160,
        parseHTML: (element) => {
          const width = element.style.width || element.getAttribute('width') || null;
          return width == null ? null : parseInt(width, 10);
        },
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: 108,
        parseHTML: (element) => {
          const height = element.style.height || element.getAttribute('height') || null;
          return height == null ? null : parseInt(height, 10);
        },
        renderHTML: (attributes) => {
          return {
            style: `height: ${attributes.height}px`,
          };
        },
      },
      float: {
        default: 'left',
        parseHTML: (element) => {
          return element.getAttribute('float') || null;
        },
        renderHTML: (attributes) => {
          return {
            float: attributes.float,
            class:
              attributes.float === 'left'
                ? '!float-left !mr-2'
                : attributes.float === 'right'
                ? '!float-right !ml-2'
                : attributes.float === 'center' && '!mx-auto block',
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },
});
