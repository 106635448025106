import draftToMarkdown from 'components/utils/draftJS/draftjs-to-markdown';

const hashConfig = {
  trigger: '#',
  separator: '',
};

const customEntityTransform = {
  inlineStyleMapping: {
    HEADER: '##',
  },
};

const config = {
  blockTypesMapping: {
    'unordered-list-item': '* ',
  },
  emptyLineBeforeBlock: false,
};

export const convertToMarkdown = (draftJson: any) => {
  return draftToMarkdown(draftJson, hashConfig, customEntityTransform, config);
};

// TODO: refactor with RichTextMenuButtonTypes
export const ToolbarButton = {
  CONDITIONAL_TEXT: 'conditional text',
  VARIABLES: 'variables',
  MEDIA: 'media',
  PHONE_NUMBER: 'phone number',
  INSERT_LINK: 'insert link',
  INFOBOX: 'infobox',
  BULLETED_LIST: 'bulleted list',
  NUMBERED_LIST: 'numbered list',
  HIGHLIGHT: 'highlight',
  BOLD: 'bold',
  TEXT_STYLE: 'text style',
  ITALIC: 'italic',
};

export const include = (richTextButtonShowList: string[], tooltipText) => {
  if (!Array.isArray(richTextButtonShowList)) return false;

  if (richTextButtonShowList.includes(tooltipText)) {
    return true;
  } else {
    return false;
  }
};
