import { ClickAwayListener, Divider, MenuItem, MenuList, Popper } from '@material-ui/core';

export const TextStyleMenu = (props) => {
  const { anchorEl, close, applyTextStyle } = props;

  return (
    <>
      <Popper
        id='text-style-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        style={{ marginTop: 10, zIndex: 999 }}
      >
        <ClickAwayListener onClickAway={close}>
          <MenuList
            autoFocusItem
            style={{ background: 'white', border: '1px solid #E7E7E7', borderRadius: 4 }}
          >
            <MenuItem onClick={applyTextStyle}>Heading</MenuItem>
            <Divider />
            <MenuItem onClick={applyTextStyle}>Normal Text</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
