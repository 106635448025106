import NavBar from 'components/bars/NavBar';
import FeaturedAuthors from 'components/featured-authors';
import Footer from 'components/layout/Footer';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { StyledContainer } from './styles';
import TeamDetail from './team-detail';

const MemberManagement = () => (
  <>
    {/* // TODO: Remove the global style for materialize and bootstrap in future */}
    {globalStyles}
    <style>{'body { background-color: #FFFFFF; }'}</style>
    <NavBar activeState='TeamManagement' />
    <StyledContainer>
      <TeamDetail />
      <FeaturedAuthors />
    </StyledContainer>
    <Footer />
  </>
);

export default MemberManagement;
