import EditIcon from 'assets/icons/grayEditIcon';
import { clsx } from 'clsx';
import { Icons } from 'components/utils/Icons';
import Tooltip from '../Tooltip';
import React, { ReactNode } from 'react';

export interface TiptapNodePreviewProps {
  title: ReactNode;
  isInvalid?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const TiptapNodePreview = ({
  Icon,
  title,
  onEdit,
  onDelete,
  isInvalid,
}: TiptapNodePreviewProps) => {
  const nodePreview = (
    <div className='group inline-flex h-5 cursor-pointer items-center gap-1 rounded-sm bg-gray-100 pr-1 hover:border hover:border-solid hover:border-primary-800'>
      <div
        className={clsx(
          'flex h-full w-4 items-center justify-center',
          isInvalid ? 'bg-gray-200' : 'bg-primary-200'
        )}
      >
        <Icon
          className={clsx(
            'h-2.5 w-2.5 group-hover:text-primary-800',
            isInvalid ? 'text-gray-300' : 'fill-primary-600 text-primary-600'
          )}
        />
      </div>
      <span
        className={clsx(
          'max-w-[108px] select-none truncate text-caption-2',
          isInvalid ? 'text-gray-300' : 'text-gray-700'
        )}
      >
        {title}
      </span>
      <div className='hidden items-center gap-1 pl-1 group-hover:flex'>
        {!isInvalid && onEdit && (
          <>
            <EditIcon className='h-3 w-3.5 cursor-pointer' onClick={onEdit} />
            <div className='h-3 w-[1px] bg-gray-300' />
          </>
        )}
        <Icons.TrashBin className='h-3.5 w-3.5 cursor-pointer' onClick={onDelete} />
      </div>
    </div>
  );

  if (isInvalid) {
    return (
      <Tooltip className='inline'>
        <Tooltip.Icon className='inline'>{nodePreview}</Tooltip.Icon>
        <Tooltip.Content className='!left-[-11px] !top-[38px] z-10 inline'>
          Not available
        </Tooltip.Content>
      </Tooltip>
    );
  }
  return nodePreview;
};

interface TiptapReadOnlyNodeProps {
  title: string;
  isInvalid?: boolean;
  Icon: React.FC<React.SVGProps<SVGSVGElement>> | null;
}

export const TiptapReadOnlyNode = ({ Icon, title, isInvalid }: TiptapReadOnlyNodeProps) => {
  return (
    <div
      className={clsx(
        'mr-1 inline-flex h-5 cursor-pointer items-center gap-1 border-b border-primary-600 text-body-2',
        isInvalid ? '!text-gray-300' : 'text-primary-600'
      )}
    >
      {Icon && (
        <Icon
          className={
            isInvalid ? 'fill-gray-300 text-gray-300' : 'fill-primary-600 text-primary-600'
          }
        />
      )}
      <span>{title}</span>
    </div>
  );
};
