import NavBar from 'components/bars/NavBar';
import NumericsList from './NumericsList';

const OldNumericList = () => (
  <div className='h-full'>
    <NavBar />
    <NumericsList />
  </div>
);

export default OldNumericList;
