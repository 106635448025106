import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Trigger } from 'api/resources';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import { MessageForm } from 'components/panels/MessageForm';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ResourceTypes, UIElement } from '../../../api/moduleElement';
import calIcon from '../../../assets/images/cal.png';
import mediaIcon from '../../../assets/images/media.png';
import { MODULE_TYPES } from '../../../constants';
import { withFeaturePermission } from '../../../hooks/useAuthentication';
import { Trigger as TriggerComponent } from '../../app-previews/Trigger';
import { VariablesPreview } from '../../app-previews/common/variable';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { InfoBoxChip } from '../../utils/styled-components/FormStyle';
import { ModuleElementContext } from '../ModuleBoard';

const StyledChip = withStyles({
  root: {
    marginTop: '17px',
    backgroundColor: 'transparent',
    color: '#08A88E',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    alignItems: 'center',
    lineHeight: '22px',
  },
})(Chip);

export interface MsgCardFormValues {
  id?: string;
  description_text_long: string;
  description_text_short: string;
  description_text: string;
  is_action: boolean;
  is_introduction: boolean;
  is_long_desc_enabled: boolean;
  long_desc_json_tiptap: any;
  module?: number;
  calculator?: number;
  new_description_text_content_long: any;
  new_description_text_content_short: any;
  new_description_text_content: any;
  panel_type: string;
  position: number;
  question_items: any[];
  examples: any[];
  tools: any[];
  short_desc_json_tiptap: any;
  desc_json_tiptap: any;
  title: string;
  trigger?: Trigger | number | string;
  variable: string;
  container?: number;
}

interface MessageBetaCardProps {
  element: UIElement;
  containerId?: number;
}

export const MessageBetaCard = ({ element, containerId }: MessageBetaCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const hasPermission = !hasViewOnlyPermission(module?.type);
  const { refetchModuleElements, duplicateUIElement, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [showMore, setShowMore] = useState(true);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const defaultValues = useMemo<MsgCardFormValues>(
    () => ({
      description_text_long: element?.description_text_long ?? '',
      description_text_short: element?.description_text_short ?? '',
      description_text: element?.description_text ?? '',
      is_action: !!element.is_action,
      is_introduction: !!element.is_introduction,
      is_long_desc_enabled: !!element.is_long_desc_enabled,
      long_desc_json_tiptap: element.long_desc_json_tiptap ?? {},
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      calculator: module?.type === MODULE_TYPES.CALCULATOR ? module.id : undefined,
      new_description_text_content_long: element.new_description_text_content_long,
      new_description_text_content_short: element.new_description_text_content_short,
      new_description_text_content: element.new_description_text_content,
      panel_type: element.panel_type ?? 'B',
      position: element.position,
      question_items: element.question_items ?? [],
      examples: element.examples ?? [],
      tools: element.tools ?? [],
      short_desc_json_tiptap: element.short_desc_json_tiptap,
      desc_json_tiptap: element.desc_json_tiptap,
      title: element.title ?? '',
      trigger: element.trigger,
      variable: element.variable_item?.name ?? '',
      container: element.container,
    }),
    [element, module]
  );

  const form = useForm<MsgCardFormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handleDuplicate = (id) => {
    duplicateUIElement({
      elementId: id,
      moduleId: module?.id as number,
      queryParam: ResourceTypes.message,
      moduleType: module?.type as MODULE_TYPES,
    });
  };

  // In the Message form, onIntroductionDescriptionChange uses debounce to apply changes with a delay, resetting the form after that delay.
  const debouncedFormReset = debounce(() => {
    form.reset(defaultValues);
  }, 600);

  const handlePanelClose = () => {
    clearModuleElements();
    debouncedFormReset();
    setPanelOpen(false);
  };

  const onSave = () => {
    refetchModuleElements();
    setPanelModalOpen(false);
    setPanelOpen(false);
  };

  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const [noteGenerators, setNoteGenerators] = useState<any[]>([]);
  const questionItem = form.watch('question_items');
  const examples = form.watch('examples');
  const tools = form.watch('tools');

  useEffect(() => {
    if (!panelOpen) {
      const noteGenerators = tools?.filter(
        (item) => item?.section === 'T' && item?.infobox?.infobox_type === 'NG'
      );

      setNoteGenerators(noteGenerators);
    }
  }, [panelOpen, questionItem]);

  const variable = form.watch('variable');
  const variables = useMemo(
    () => [
      {
        type: 'message',
        name: variable,
      },
    ],
    [variable]
  );

  // TODO: need refactoring
  //choices trigger element
  let cardStyle = {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '10px',
    marginBottom: '0px',
    paddingBottom: '10px',
    width: isContainer ? '100%' : '576px',
    borderRadius: '30px',
    backgroundColor: isContainer ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
    border: 'none',
    boxShadow: isContainer ? 'none' : '0px 4px 25px rgba(0, 0, 0, 0.15)',
  };
  let questionStyle = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '30px',
    color: '#000000',
    marginBottom: '15px',
  };

  const is_introduction = !!form.watch('is_introduction');

  return (
    <>
      <div
        data-testid='description-panel__test'
        className='!mb-5'
        style={
          {
            position: panelOpen ? 'relative' : '',
            zIndex: panelOpen ? '30' : '',
            maxWidth: isContainer ? '100%' : '580px',
          } as React.CSSProperties
        }
      >
        {!is_introduction && (
          <TriggerComponent trigger={form.watch('trigger')} containerCard={isContainer} />
        )}
        <Card
          className='item-to-hover'
          onDoubleClick={() => hasPermission && setPanelOpen(true)}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '13px',
              }}
            >
              <Typography component={'div'} color='textPrimary' style={questionStyle}>
                {is_introduction ? (
                  'Introduction'
                ) : (
                  <>
                    {form.watch('is_action') && (
                      <ErrorOutlineIcon
                        style={{
                          fill: 'orange',
                          marginRight: '9px',
                          marginBottom: '5px',
                        }}
                      />
                    )}{' '}
                    {form.watch('title')}
                  </>
                )}
              </Typography>
              {hasPermission && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                {!is_introduction && (
                  <>
                    <MenuItem
                      onClick={() => {
                        setDeleteModal(true);
                        setAnchorEl(null);
                      }}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setDuplicateModal(true);
                        setAnchorEl(null);
                      }}
                    >
                      Duplicate
                    </MenuItem>
                  </>
                )}
              </Menu>
            </Box>
            <Typography component={'span'} className='pb-2' color='textSecondary'>
              <AvoEditorPreview
                contentTiptap={
                  is_introduction
                    ? form.watch('desc_json_tiptap')
                    : form.watch('short_desc_json_tiptap')
                }
                contentDraftJS={
                  is_introduction
                    ? form.watch('new_description_text_content')
                    : form.watch('new_description_text_content_short')
                }
              />
              {!!form.watch('is_long_desc_enabled') && showMore && (
                <React.Fragment>
                  <div
                    style={{
                      marginTop: 16,
                      fontSize: '14px',
                      lineHeight: '18.9px',
                      fontWeight: 400,
                      color: '#BFBFBF',
                    }}
                  >
                    Details
                    <Divider
                      style={{
                        marginBottom: 20,
                        color: '#E4E4E4',
                      }}
                    />{' '}
                  </div>
                  <AvoEditorPreview
                    contentTiptap={form.watch('long_desc_json_tiptap')}
                    contentDraftJS={form.watch('new_description_text_content_long')}
                  />
                </React.Fragment>
              )}
            </Typography>

            {examples.length > 0 && (
              <Box
                style={{
                  border: '0.75px solid #B3B3B3',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '58px',
                  marginTop: 10,
                  marginBottom: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                {examples.map((example, index) => {
                  return !!example.text ? (
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#000000',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {example.label || example.text?.statement}

                      {examples[index + 1] && <span style={{ marginRight: 4 }}>,</span>}
                    </Typography>
                  ) : !!example.infobox ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {example.label || example.infobox?.shortened_title}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : !!example.image ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label || example.image?.representation_phrase}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : null;
                })}
              </Box>
            )}

            {form.watch('is_long_desc_enabled') ? (
              showMore && (
                <React.Fragment>
                  {tools.map((tool) => {
                    return !!tool.infobox ? (
                      <StyledChip
                        key={tool.id}
                        style={{ textOveflow: 'ellipsis' } as React.CSSProperties}
                        size='small'
                        icon={<InfoBoxIcon />}
                        label={tool.label || tool.infobox?.shortened_title}
                      />
                    ) : !!tool.image ? (
                      <StyledChip
                        key={tool.id}
                        size='small'
                        label={tool.label || tool.image?.representation_phrase}
                        icon={
                          <img
                            src={mediaIcon}
                            alt='media-icon'
                            style={{
                              width: '42px',
                              height: '43px',
                              paddingBottom: '5px',
                            }}
                          />
                        }
                      />
                    ) : !!tool.legacy_calculator ? (
                      <StyledChip
                        key={tool.id}
                        size='small'
                        label={tool.label || tool.legacy_calculator?.title}
                        icon={
                          <img
                            src={calIcon}
                            alt='calculator-icon'
                            style={{
                              width: '18px',
                              height: '24px',
                              marginRight: '2px',
                            }}
                          />
                        }
                      />
                    ) : !!tool.calculator ? (
                      <StyledChip
                        key={tool.id}
                        label={tool.label || tool.calculator?.name}
                        size='small'
                        icon={
                          <img
                            src={calIcon}
                            alt='calculator-icon'
                            style={{
                              width: '18px',
                              height: '24px',
                              marginRight: '2px',
                            }}
                          />
                        }
                      />
                    ) : null;
                  })}
                  {noteGenerators.length > 0 && (
                    <React.Fragment>
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          lineHeight: '18px',
                          margin: '20px 0px 5px 5px',
                        }}
                      >
                        Smart Links
                      </Typography>

                      {noteGenerators.map((item, index) => (
                        <InfoBoxChip
                          style={{
                            backgroundColor: 'transparent',
                            color: '#08A88E',
                            fontSize: '16px',
                          }}
                          className='infobox-chip'
                          key={index}
                          icon={<InfoBoxIcon />}
                          label={item?.label || item?.infobox?.shortened_title}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                {tools.map((tool) => {
                  return !!tool.infobox ? (
                    <StyledChip
                      key={tool.id}
                      style={{ textOveflow: 'ellipsis' } as React.CSSProperties}
                      size='small'
                      icon={<InfoBoxIcon />}
                      label={tool.label || tool.infobox?.shortened_title}
                    />
                  ) : !!tool.image ? (
                    <StyledChip
                      key={tool.id}
                      size='small'
                      label={tool.label || tool.image?.representation_phrase}
                      icon={
                        <img
                          src={mediaIcon}
                          alt='media-icon'
                          style={{
                            width: '42px',
                            height: '43px',
                            paddingBottom: '5px',
                          }}
                        />
                      }
                    />
                  ) : !!tool.legacy_calculator ? (
                    <StyledChip
                      key={tool.id}
                      size='small'
                      label={tool.label || tool.legacy_calculator?.title}
                      icon={
                        <img
                          src={calIcon}
                          alt='calculator-icon'
                          style={{
                            width: '18px',
                            height: '24px',
                            marginRight: '2px',
                          }}
                        />
                      }
                    />
                  ) : !!tool.calculator ? (
                    <StyledChip
                      key={tool.id}
                      label={tool.label || tool.calculator?.name}
                      size='small'
                      icon={
                        <img
                          src={calIcon}
                          alt='calculator-icon'
                          style={{
                            width: '18px',
                            height: '24px',
                            marginRight: '2px',
                          }}
                        />
                      }
                    />
                  ) : null;
                })}
                {noteGenerators.length > 0 && (
                  <React.Fragment>
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        lineHeight: '18px',
                        margin: '20px 0px 5px 5px',
                      }}
                    >
                      Smart Links
                    </Typography>

                    {noteGenerators.map((item, index) => (
                      <InfoBoxChip
                        style={{
                          backgroundColor: 'transparent',
                          color: '#08A88E',
                          fontSize: '16px',
                        }}
                        className='infobox-chip'
                        key={index}
                        icon={<InfoBoxIcon />}
                        label={item?.label || item?.infobox?.shortened_title}
                      />
                    ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {form.watch('is_long_desc_enabled') && showMore && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                  cursor: 'pointer',
                }}
              >
                <ExpandLessIcon onClick={() => setShowMore((prev) => !prev)} />
              </div>
            )}

            {form.watch('is_long_desc_enabled') && !showMore && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',

                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <ExpandMoreIcon onClick={() => setShowMore((prev) => !prev)} />
              </div>
            )}
          </CardContent>
        </Card>

        {!isContainer && <FeaturedVariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
        <ConfirmModal
          preset='duplicate'
          open={duplicateModal}
          content='Are you sure you want to duplicate this card?'
          toggleModal={() => setDuplicateModal(false)}
          performAction={() => {
            handleDuplicate(element.id);
            setDuplicateModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='h-full w-[620px] overflow-x-visible bg-white px-[16px]'>
          <FormProvider {...form}>
            <MessageForm
              onSave={onSave}
              modal={panelModalOpen}
              closePanel={handlePanelClose}
              id={!!element.id ? String(element.id) : ''}
              closeModal={() => setPanelModalOpen(false)}
            />
          </FormProvider>
        </div>
      </SlidePanel>
    </>
  );
};

const FeaturedVariablesPreview = withFeaturePermission(VariablesPreview);
