import appbarLogo from 'assets/images/appbarLogo.png';
import { MobileNotSupportPage } from 'components/layout/MobileNotSupportPage';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SIGNUP_ROUTE } from '../../constants';

const bardVariant = {
  start: { opacity: 0 },
  end: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariant = {
  start: { opacity: 0 },
  end: { opacity: 1 },
};

const Title = () => {
  return (
    <motion.div
      className='flex flex-wrap text-gray-text-weak font-bold text-[40px] leading-[60px]'
      variants={bardVariant}
      initial='start'
      animate='end'
    >
      <motion.span variants={itemVariant}>Unleash&nbsp;</motion.span>
      <motion.span variants={itemVariant}>your&nbsp;</motion.span>
      <motion.span variants={itemVariant}>creativity&nbsp;</motion.span>
      <motion.span variants={itemVariant}>and&nbsp;</motion.span>
      <motion.span variants={itemVariant}>reimagine&nbsp;</motion.span>
      <motion.span variants={itemVariant}>clinical&nbsp;</motion.span>
      <motion.span variants={itemVariant}>tools&nbsp;</motion.span>
      <motion.span variants={itemVariant}>with&nbsp;</motion.span>
      <motion.span variants={itemVariant}>GPT-powered&nbsp;</motion.span>
      <motion.span variants={itemVariant} className='text-primary-600'>
        AvoBuilder
      </motion.span>
    </motion.div>
  );
};

const TryAvoBuilder = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(
    () => window.matchMedia('only screen and (max-width: 980px)').matches
  );

  const handleResize = () => {
    setIsMobile(window.matchMedia('only screen and (max-width: 980px)').matches);
  };

  const [trigger, setTrigger] = useState(true);

  useEffect(() => {
    setInterval(() => setTrigger((prev) => !prev), 4000);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? (
    <MobileNotSupportPage />
  ) : (
    <div className='relative flex h-screen px-[11px] items-center'>
      <div className='absolute top-0 left-0 w-full px-[11px] py-[3px]'>
        <img src={appbarLogo} alt={'logo'} width={140} height={50} />
      </div>
      <div className='max-w-[863px] mx-auto'>
        {trigger ? (
          <div>
            <Title />
          </div>
        ) : (
          <span>
            <Title />
          </span>
        )}
        <div className='mt-[60px] text-gray-text-weak text-heading-3'>
          Harness the power of AI to automate the creation of interactive clinical
          guidelines/pathways, patient notes, and even patient education tools. Join us in
          revolutionizing how clinicians engage with the healthcare system and help us shape a
          better tomorrow.
        </div>
        <div className='w-[240px] ml-auto'>
          <Link to={`${SIGNUP_ROUTE}${location.search}`}>
            <button className='w-full py-[12px] px-[16px] rounded-[4px] mt-[40px] text-heading-5 text-white bg-primary-500'>
              Try AvoBuilder
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TryAvoBuilder;
