import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { IAuthor } from 'components/featured-authors/AddAuthorController';
import { IMemberPermission } from 'components/module-detail/member-permission/MemberPermissionDropdown';
import { ISource } from 'components/module-detail/module-source/AddSourceModal';
import { sprintf } from 'sprintf-js';
import { Intervals } from '../components/module-detail/verification/VerificationIntervalListBox';
import {
  AI_KNOWLEDGE_BASE_API_URL,
  CALCULATOR_API_URL,
  DUPLICATE_AI_KNOWLEDGE_BASE_API_URL,
  DUPLICATE_CALC_API_URL,
  DUPLICATE_MODULE_API_URL,
  LEGACY_CALCULATOR_API_URL,
  MIRROR_API_URL,
  MODULES_API_URL,
  MODULE_API_URL,
  MODULE_ICON_PRESIGNED_POST,
  MODULE_TYPES,
  Permissions,
  SOURCE_API_URL,
  TEAM_API_URL,
  UNIVERSAL_CALCULATORS_API_URL,
  UNVERIFY_AI_KB_API_URL,
  UNVERIFY_CALCULTAOR_API_URL,
  UNVERIFY_MODULE_API_URL,
  VERIFY_AI_KB_API_URL,
  VERIFY_CALCULATOR_API_URL,
  VERIFY_MODULE_API_URL,
} from '../constants';
import { Module, ModuleWithMirror } from '../hooks/useModules';
import { Author } from './author';
import { PresignedPostResponse } from './knowledgebase';

export interface PostSourceData {
  id: number;
  name: string;
  website_link: string;
  pdf_file: string;
  fileName: string;
  pdfRadio: boolean;
}

export interface IModuleList {
  id: number;
  code: string;
  title: string;
  type: MODULE_TYPES;
  published: boolean;
  is_universal: boolean;
  mirrorOf: MODULE_TYPES;
  is_in_current_team: boolean;
  last_synced: string | null;
}

interface GetModuleData {
  id: number;
  code: string;
  name: string;
  description: string;
  type: MODULE_TYPES;
  isMirror?: boolean;
  members: {
    name: string;
    email: string;
    user_id: number;
    team_member_id: number;
    permission_type: string;
  }[];
  authors: IAuthor[];
  last_synced: string; //TODO The module interface does not match the value, to make it optional has to set optional in the module interface.
  last_verified?: string;
  last_updated: string;
  reviewed_by: any; //TODO The module interface does not match the value, so temporarily set it to any.
  review_requested_at: [];
  last_updated_by: any; //TODO The module interface does not match the value, so temporarily set it to any.
  is_google_searchable: boolean;
  tags: { code: string; title: string }[];
  hidden_tags: { code: string; title: string }[];
  is_auto_tagged: boolean;
  team: {
    id: number;
    name: string;
  };
  permission_type: Permissions;
  status: string;
  owner: any; //TODO The module interface does not match the value, so temporarily set it to any.
  current_user_role: Permissions;
  sources: ISource[];
  featured_by_admin_at?: string;
  reviewer: number | null;
  review_interval: Intervals | null;
  review_expire_at: Date | null;
  is_universal: boolean;
  use_new_editor: boolean;
  icon: string | null;
  /** Field to verify if draftJS has been converted to tiptap. */
  // TODO: This field should be removed once all modules have converted to tiptap.
  has_converted: boolean;
}

export interface PostModuleVariable {
  id?: number;
  name: string;
  description: string;
  is_google_searchable: boolean;
  featured_by_admin_at: Date | null;
  tags: string[];
  hidden_tags: string[];
  is_auto_tagged: boolean;
  members: IMemberPermission[];
  permission_type: string;
  team: number;
  authors: Author[];
  module_sources: number[];
  is_universal: boolean;
  icon?: string | null;
  reviewer: number | null;
  review_interval: Intervals | null;
  review_expire_at: Date | null;
}

export interface PostModuleData {
  id: number;
}

export interface CopyModuleRequest {
  module: ModuleWithMirror;
  teamId: number;
}

// TODO: move to calculator API, clear typing
interface Calculator {
  id: string;
  team: number;
  name: string;
}

interface RegistIconRequest {
  moduleId: number;
  payload: RegistIconPayload;
}

interface RegistIconPayload {
  file_name: string;
}

const getDeleteUrlByType = (module: ModuleWithMirror) => {
  switch (module.type) {
    case MODULE_TYPES.ALGO:
      return MODULE_API_URL + module.id + '/';
    case MODULE_TYPES.CALCULATOR:
      return CALCULATOR_API_URL + module.id + '/';
    case MODULE_TYPES.KNOWLEDGE_BASE:
      return AI_KNOWLEDGE_BASE_API_URL + module.id + '/';
    case MODULE_TYPES.MIRROR:
    default:
      return MIRROR_API_URL + module.id + '/';
  }
};
const getDuplicateUrlByType = (module: ModuleWithMirror) => {
  switch (module.type) {
    case MODULE_TYPES.ALGO:
      return DUPLICATE_MODULE_API_URL + module.id;
    case MODULE_TYPES.CALCULATOR:
      return DUPLICATE_CALC_API_URL + module.id;
    case MODULE_TYPES.KNOWLEDGE_BASE:
    default:
      return DUPLICATE_AI_KNOWLEDGE_BASE_API_URL + module.id + '/';
  }
};
const getVerifyUrlByType = (module: Module) => {
  switch (module.type) {
    case MODULE_TYPES.ALGO:
      return VERIFY_MODULE_API_URL + module.id;
    case MODULE_TYPES.CALCULATOR:
      return VERIFY_CALCULATOR_API_URL + module.id;
    case MODULE_TYPES.KNOWLEDGE_BASE:
    default:
      return VERIFY_AI_KB_API_URL + module.id + '/';
  }
};

const getUnverifyUrlByType = (module: ModuleWithMirror) => {
  switch (module.type) {
    case MODULE_TYPES.ALGO:
      return UNVERIFY_MODULE_API_URL + module.id;
    case MODULE_TYPES.CALCULATOR:
      return UNVERIFY_CALCULTAOR_API_URL + module.id;
    case MODULE_TYPES.KNOWLEDGE_BASE:
    default:
      return UNVERIFY_AI_KB_API_URL + module.id;
  }
};

const getTransferUrlByType = (module: ModuleWithMirror) => {
  switch (module.type) {
    case MODULE_TYPES.ALGO:
      return MODULE_API_URL + module.id + '/transfer_team';
    case MODULE_TYPES.CALCULATOR:
      return CALCULATOR_API_URL + module.id + '/transfer_team';
    case MODULE_TYPES.KNOWLEDGE_BASE:
      return AI_KNOWLEDGE_BASE_API_URL + module.id + '/transfer_team/';
    case MODULE_TYPES.MIRROR:
    default:
      return MIRROR_API_URL + module.id + '/transfer_team/';
  }
};

const getModuleUrlByType = (moduleType: MODULE_TYPES) => {
  let url = '';
  switch (moduleType) {
    case MODULE_TYPES.ALGO:
      url = MODULE_API_URL;
      break;
    case MODULE_TYPES.CALCULATOR:
      url = CALCULATOR_API_URL;
      break;
    case MODULE_TYPES.KNOWLEDGE_BASE:
    default:
      url = AI_KNOWLEDGE_BASE_API_URL;
      break;
  }
  return url;
};

export const moduleAPI = {
  getModules: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${MODULES_API_URL}${queryKey[1]}`).then((response) => response.data),
  getModule: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<GetModuleData>(getModuleUrlByType(queryKey[0] as MODULE_TYPES) + queryKey[1])
      .then((response) => response.data),
  postModule: (data, moduleType) =>
    axios.post(getModuleUrlByType(moduleType), data).then((response) => response.data),
  patchModule: (data, moduleType, moduleId) =>
    axios
      .patch(getModuleUrlByType(moduleType) + moduleId + '/', data)
      .then((response) => response.data),
  getIconPresignedPost: (data: RegistIconRequest) =>
    axios
      .post<PresignedPostResponse>(sprintf(MODULE_ICON_PRESIGNED_POST, data.moduleId), data.payload)
      .then((res) => res.data),
  uploadIconPresignedPost: async ({ url, formData }: { url: string; formData: FormData }) => {
    const tmpAxiosInstance = axios.create();
    return await tmpAxiosInstance
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  },
  postSource: (data) =>
    axios.post<PostSourceData>(SOURCE_API_URL, data).then((response) => response.data),
  deleteModule: (module: ModuleWithMirror) => axios.delete(getDeleteUrlByType(module)),
  duplicateModule: (module: ModuleWithMirror) => {
    const duplicateKeys = {
      [MODULE_TYPES.ALGO]: 'module',
      [MODULE_TYPES.CALCULATOR]: 'calculator',
      [MODULE_TYPES.KNOWLEDGE_BASE]: 'ai_knowledge_base',
    };
    return axios.post(getDuplicateUrlByType(module), {
      [duplicateKeys[module.type]]: module.id,
    });
  },
  transferModule: ({ module, teamId }: CopyModuleRequest) =>
    axios.put(getTransferUrlByType(module), { team: teamId }),
  verifyModule: (module: Module) => axios.patch(getVerifyUrlByType(module)),
  unverifyModule: (module: ModuleWithMirror) => axios.patch(getUnverifyUrlByType(module)),
  copyModule: ({ module, teamId }: CopyModuleRequest) => {
    const copyKeys = {
      [MODULE_TYPES.ALGO]: 'module',
      [MODULE_TYPES.CALCULATOR]: 'calculator',
      [MODULE_TYPES.KNOWLEDGE_BASE]: 'knowledge_base',
    };
    return axios.post(MIRROR_API_URL, {
      team: teamId,
      [copyKeys[module.type]]: module.id,
    });
  },
  getIntegrationCalculators: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Calculator[]>(MODULE_API_URL + `${queryKey[1]}/calculators`).then((res) => res.data),
};

export const calculatorAPI = {
  getLegacyCalculators: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${LEGACY_CALCULATOR_API_URL}`).then((response) => response.data),
  getUniversalCalculators: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${UNIVERSAL_CALCULATORS_API_URL}`).then((response) => response.data),
  getTeamCalculators: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${TEAM_API_URL}${queryKey[1]}/calculators`).then((response) => response.data),
};
