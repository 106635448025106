import axios from 'axios';
import { GET_NOTIFICATIONS, SEND_PUSH_NOTIFICATIONS_URL } from '../constants';

export interface NotificationRequest {
  team: number;
  title: string;
  message: string;
  channels: number[];
}

export interface NotificationHistory {
  id: number;
  team: number;
  title: string;
  message: string;
  channels: string;
  created: string;
}

export const notificationAPI = {
  getNotificationHistories: () =>
    axios.get<NotificationHistory[]>(GET_NOTIFICATIONS).then((res) => res.data),
  postNotification: (data: NotificationRequest) =>
    axios.post(SEND_PUSH_NOTIFICATIONS_URL, data).then((res) => res.data),
};
