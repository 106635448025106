import { useMutation } from '@tanstack/react-query';
import { auth } from 'api/firebase';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { CustomToast } from 'components/utils/toast-message';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { useAuthentication } from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { profileApi } from '../../../api/profile';
import Button from '../../utils/Button';
import Input from '../../utils/Input';
import PasswordInput from '../../utils/PasswordInput';
import PasswordRule from '../../utils/PasswordRule';
import { Modal } from '../../utils/modals/Modal';

interface PasswordFormProps {
  open: boolean;
  onClose: () => void;
}

// union types for password validation
const MATCHEDRULES = {
  SUCCESS: 0,
  FOCUS: 1,
  FAILURE: 2,
};
type MatchedRules = (typeof MATCHEDRULES)[keyof typeof MATCHEDRULES];

interface MatchOptions {
  matchedRule: MatchedRules;
  matchedConfirm: boolean;
}

interface PasswordChangeVariable {
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

const PasswordChangeForm = ({ open, onClose }: PasswordFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty },
    reset,
  } = useForm<PasswordChangeVariable>({
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: '',
    },
  });

  const { authentication } = useAuthentication();

  const [matched, setMatched] = useState<boolean>(false);
  const [matchOptions, setMatchOptions] = useState<MatchOptions>({
    matchedRule: MATCHEDRULES.SUCCESS,
    matchedConfirm: true,
  });
  const [isChanging, setIsChanging] = useState(false);
  const newPassword = watch('new_password');
  const confirmPassword = watch('confirm_new_password');

  useEffect(() => {
    const matchedConfirm = confirmPassword === newPassword;
    setMatchOptions((prevState) => ({ ...prevState, matchedConfirm }));
  }, [newPassword, confirmPassword]);

  const validate = () =>
    matchOptions.matchedRule === MATCHEDRULES.SUCCESS && matchOptions.matchedConfirm;

  const { mutateAsync, isLoading } = useMutation(profileApi.putPassword, {
    onSuccess: () => {
      toast.success(CustomToast, { data: 'Changed password' });
      reset();
      onClose();
    },
  });

  const onSubmit = async (data: PasswordChangeVariable) => {
    if (
      isLoading ||
      isChanging ||
      !isDirty ||
      !matchOptions.matchedConfirm ||
      matchOptions.matchedRule !== MATCHEDRULES.SUCCESS ||
      authentication.providerId === 'google.com'
    )
      return;

    setIsChanging(true);

    if (authentication.auth_type === 'firebase') {
      const user = auth.currentUser;
      if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, data.old_password);

        await reauthenticateWithCredential(user, credential)
          .then(async () => {
            await updatePassword(user, newPassword)
              .then(() => {
                toast.success(CustomToast, { data: 'Password changed' });
                reset();
                onClose();
              })
              .catch((error) => toast.error(CustomToast, { data: 'Password change failed' }));
          })
          .catch((error) => {
            toast.error(CustomToast, { data: 'Wrong credential' });
          });
      }
    } /* knox */ else {
      await mutateAsync(data);
    }
    setIsChanging(false);
  };

  const onInvalid = (errors) => {
    const { new_password: newPasswordError, confirm_new_password: confirmPasswordError } = errors;
    if (newPasswordError) {
      toast.error(CustomToast, { data: 'Password rule do not match' });
    }
    if (confirmPasswordError) {
      toast.error(CustomToast, { data: 'Passwords do not match' });
    }
  };

  const handleNewPassword = (event) => {
    const { type } = event;
    const { SUCCESS, FOCUS, FAILURE } = MATCHEDRULES;
    let matchedRule: MatchedRules = MATCHEDRULES.SUCCESS;
    if (type === 'focus') {
      matchedRule = FOCUS;
    } else if (type === 'blur') {
      matchedRule = matched ? SUCCESS : FAILURE;
    }

    setMatchOptions({ ...matchOptions, matchedRule });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Head onClose={onClose} className='border-b py-4'>
        <div className='text-[18px] font-bold leading-[24px] tracking-tight'>Change Password</div>
      </Modal.Head>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)} className='space-y-[15px]'>
          <div className='w-full space-y-2'>
            <span className='text-heading-5'>Current Password</span>
            <Input
              data-testid='input-old-password'
              type='password'
              className='!h-12 !border-gray-200 !px-[15px] !py-[15px] focus:!border-primary-500'
              required
              {...register('old_password', { required: true })}
            />
          </div>
          <div className='w-full space-y-2'>
            <span className='text-heading-5'>New Password</span>
            <div className='text-body-2'>
              Password should be a minimum of 8 characters. Avoid using a too obvious password, such
              as your pet's name.
            </div>
            <PasswordInput
              data-testid='input-new-password'
              type='password'
              className='!h-12 !border-gray-200 !px-[15px] !py-[15px] focus:!border-primary-500 focus:!border-b-transparent'
              required
              {...register('new_password', { required: true, validate })}
              onBlur={handleNewPassword}
              onFocus={handleNewPassword}
            />
            <div>
              {matchOptions.matchedRule === MATCHEDRULES.FOCUS && (
                <PasswordRule password={newPassword} setMatched={setMatched} />
              )}
              {matchOptions.matchedRule === MATCHEDRULES.FAILURE && (
                <span className='text-sm text-red-500'>Password rule do not match</span>
              )}
            </div>
          </div>
          <div className='w-full space-y-2'>
            <span className='text-heading-5'>Re-enter New Password</span>
            <PasswordInput
              data-testid='input-confirm-password'
              type='password'
              className='!h-12 !border-gray-200 !px-[15px] !py-[15px] focus:!border-primary-500 focus:!border-b-transparent'
              required
              {...register('confirm_new_password', { required: true, validate })}
            />
            <div>
              {!matchOptions.matchedConfirm && (
                <span className='text-sm text-red-500'>Passwords do not match</span>
              )}
            </div>
          </div>
          <div className='flex justify-center'>
            <Button
              data-testid='button-change-password'
              className='flex  justify-center py-[8px] font-bold'
              disabled={
                isLoading ||
                isChanging ||
                !isDirty ||
                !matchOptions.matchedConfirm ||
                matchOptions.matchedRule !== MATCHEDRULES.SUCCESS
              }
            >
              {isLoading || isChanging ? <LoadingSpinner size='small' /> : 'Change Password'}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordChangeForm;
