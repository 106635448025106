import { Box, MenuItem, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const TriggerItem = withStyles((theme) => ({
  root: {
    minWidth: 275,
    height: 36,
    background: 'white',
    color: '#1E1F20',
    '&:hover': { color: '#1E1F20', backgroundColor: '#E8FFF1 !important' },
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
}))(MenuItem);

export const TriggerMenu = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    zIndex: 9999,
    maxWidth: 275,
    minWidth: 275,
  },
  paper: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 288,
    width: '100%',
    // padding: 0,
    boxShadow: 'none',
    borderColor: 'transparent',
    maxWidth: 275,
    minWidth: 275,
    filter:
      'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.02)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))',
  },
}))(Popover);

export const TriggerDropdown = withStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 275,
    minHeight: 44,
    maxHeight: 44,
    border: (props) => (props.open ? '1px solid #08A88E' : '1px solid #cccccc'),
    borderRadius: 5,

    paddingLeft: 16,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#989898',
  },
}))(Box);
