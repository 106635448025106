import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';

const styles = makeStyles({
  card: {
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 20,
  },
  title: {
    fontSize: 21,
    color: 'black',
    fontWeight: 700,
  },
  img: {
    height: 255,
    width: 255,
  },
});

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const MediaCardPreview = (props) => {
  const classes = styles();
  const { title, media } = props;

  return (
    <Card className={classes.card} style={{ backgroundColor: '#fafafa' }}>
      <CardContent>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} gutterBottom variant='h5' component='h2'>
              {title ? title : 'Media Card Title'}
            </Typography>

            {media.map((item) => {
              return (
                <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
                  {item.image && (
                    <Grid item>
                      <img className={classes.img} src={item.image} alt='Not Found' />{' '}
                    </Grid>
                  )}
                  {item.video_url && (
                    <Grid item>
                      <iframe
                        width='255'
                        height='255'
                        id='iframe'
                        src={'//www.youtube.com/embed/' + getId(item.video_url)}
                        frameBorder='0'
                      ></iframe>
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <AvoEditorPreview
              contentTiptap={props.media_text_json_tiptap}
              contentDraftJS={props.media_text_json}
            />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default MediaCardPreview;
