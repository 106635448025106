import BulletList from '@tiptap//extension-bullet-list';
import { Color } from '@tiptap/extension-color';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import ListItem from '@tiptap/extension-list-item';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { ReactRenderer, wrappingInputRule } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import 'css/tiptap-default.css';
import 'css/tiptap-table.css';
import { Markdown } from 'tiptap-markdown';
import { CharacterNLineCount } from './custom-extensions/charcter-count';
import { CustomImage } from './custom-extensions/image/index';
import { CustomLinkPreview } from './custom-extensions/link-preview';
import TiptapSuggestion from './custom-extensions/mention/TiptapSuggestion';
import { CustomMention } from './custom-extensions/mention/index';
import { MentionContext } from '../module/MentionContext';
import { Extension } from '@tiptap/core';
import { MutableRefObject } from 'react';

export const getTiptapExtensions = (
  rendererRef?: MutableRefObject<ReactRenderer | null>,
  maxLines?: number,
  maxCharacters?: number,
  placeholder?: string,
  charCountMode: 'textSize' | 'nodeSize' | 'tokenSize' = 'textSize',
  minimalEditor?: boolean,
  allowNullSuggestionPrefix: boolean = false
) => {
  const extensions = [
    TextStyle,
    Markdown,
    TableRow,
    TableHeader,
    TableCell,
    Highlight,
    Underline,

    CustomImage.configure({ allowBase64: true }),
    Color.configure({ types: [TextStyle.name, ListItem.name] }),

    CharacterNLineCount.configure({
      lineLimit: maxLines,
      mode: charCountMode,
      charLimit: maxCharacters,
    }),
    Placeholder.configure({
      placeholder: placeholder || '',
    }),
    Table.configure({
      resizable: true,
    }),
    StarterKit.configure({
      bulletList: false,
      heading: false,
    }),
    Heading.extend({
      levels: [1, 2, 3, 4, 5, 6],
      addInputRules() {
        return [
          wrappingInputRule({
            find: /^(#{2,6})\\s$/,
            type: this.type,
          }),
        ];
      },
    }),
    BulletList.extend({
      addInputRules() {
        return [
          wrappingInputRule({
            find: /^\s*([+*])\s$/,
            type: this.type,
          }),
        ];
      },
    }),
    Extension.create<{}, MentionContext>({
      name: 'MentionStorage',
      addStorage() {
        return {
          headings: [],
          suggestions: [],
          excludedSuggestionTypes: [],
        };
      },
    }),
  ];
  // Conditionally include mentions and links if minimalEditor is false
  if (!minimalEditor) {
    extensions.push(
      CustomMention.configure({
        suggestion: TiptapSuggestion(rendererRef, allowNullSuggestionPrefix),
      }),
      CustomLinkPreview
    );
  }
  return extensions;
};
