import { Modal } from '../utils/modals/Modal';
import { Body1, Body2, H3, H4 } from '../utils/typo';
import { Icons } from 'components/utils/Icons';
import Label from '../utils/Label';
import Input from '../utils/Input';
import Textarea from '../utils/textarea';
import Button from '../utils/Button';
import LoadingSpinner from '../loader/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Checkbox from '../utils/Checkbox';
import { useTeam } from '../../hooks/useTeam';
import { notificationAPI, NotificationRequest } from '../../api/notification';
import { teamChannelAPI } from '../../api/teamchannel';
import { toast } from 'react-toastify';
import { CustomToast } from '../utils/toast-message';
import { AxiosError } from 'axios';

interface SendNotificationModalProps {
  open: boolean;
  onClose: () => void;
  channels?: number[];
}

interface NotificationContents {
  title: string;
  message: string;
}

enum Steps {
  SELECT_CHANNELS,
  WRITE_MESSAGE,
  CONFIRM_AND_SEND,
}

export const SendNotificationModal = ({ open, onClose, channels }: SendNotificationModalProps) => {
  const { team } = useTeam();
  const [step, setStep] = useState<Steps>(0);
  const [notificationRequest, setNotificationRequest] = useState<NotificationRequest>({
    team: team.id,
    channels: [],
    title: '',
    message: '',
  });

  useEffect(() => {
    if (!open || !team.id) {
      return;
    }
    channels && channels.length > 0 ? setStep(Steps.WRITE_MESSAGE) : setStep(Steps.SELECT_CHANNELS);
    setNotificationRequest({ team: team.id, channels: channels ?? [], title: '', message: '' });
  }, [open, team.id, channels]);

  const handleClose = () => {
    setStep(Steps.SELECT_CHANNELS);
    onClose();
  };

  const handleChangeContents = (contents: NotificationContents) =>
    setNotificationRequest({ ...notificationRequest, ...contents });

  const handleChangeChannel = (selectedChannel: number) => {
    const channels = notificationRequest.channels.includes(selectedChannel)
      ? notificationRequest.channels.filter((channel) => channel !== selectedChannel)
      : [...notificationRequest.channels, selectedChannel];

    setNotificationRequest({ ...notificationRequest, channels });
  };

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(notificationAPI.postNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
      onClose();
      toast.success(CustomToast, { data: 'Notification Sent!' });
    },
    onError: (error: AxiosError) => {
      if (error.status === 403) {
        toast.error(CustomToast, { data: 'Only team admin is allowed to perform this action.' });
      }
    },
  });

  const handleNext = () => {
    if (step !== Steps.CONFIRM_AND_SEND) {
      setStep(step + 1);
    } else {
      mutate(notificationRequest);
    }
  };

  const isDisabled = (): boolean => {
    switch (step) {
      case Steps.SELECT_CHANNELS:
        return notificationRequest.channels.length < 1;
      case Steps.WRITE_MESSAGE:
        return !notificationRequest.message || !notificationRequest.title;
      case Steps.CONFIRM_AND_SEND:
        return isLoading;
    }
  };

  return (
    <Modal open={open} onClose={handleClose} size='large' className='overflow-y-hidden !px-0'>
      <div className='border-b border-gray-200 px-4 pb-4 shadow-04'>
        <Modal.Head onClose={handleClose}>
          <div className='space-y-4'>
            <H3>Send Notification</H3>
          </div>
        </Modal.Head>
        <StepBar step={step} />
      </div>
      <Modal.Body className='flex h-[368px] flex-col overflow-y-scroll !py-0'>
        {step === Steps.SELECT_CHANNELS && (
          <SelectChannelsBody
            channels={notificationRequest.channels}
            onChange={handleChangeChannel}
          />
        )}
        {step === Steps.WRITE_MESSAGE && (
          <WriteMessageBody
            notificationRequest={notificationRequest}
            onChange={handleChangeContents}
          />
        )}
        {step === Steps.CONFIRM_AND_SEND && (
          <ConfirmBody notificationRequest={notificationRequest} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='flex justify-end space-x-3 border-t px-5 py-4 shadow-04'>
          <div>
            <Button
              type='button'
              className='!w-28 px-8 py-2'
              onClick={() => !isLoading && handleNext()}
              disabled={isDisabled()}
            >
              {isLoading ? (
                <LoadingSpinner size='small' />
              ) : step !== Steps.CONFIRM_AND_SEND ? (
                'Next'
              ) : (
                'Send'
              )}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

interface StepBarProps {
  step: Steps;
}

interface StepIconProps {
  currentStep: Steps;
  targetStep: Steps;
}

const StepIcon = ({ currentStep, targetStep }: StepIconProps) => {
  if (targetStep > currentStep) {
    return <Icons.CheckEmpty className='st fill-transparent stroke-gray-400' />;
  } else if (targetStep === currentStep) {
    return <Icons.CheckEmpty className='fill-transparent stroke-primary-500' />;
  } else {
    return <Icons.CheckCircle />;
  }
};

const StepBar = ({ step }: StepBarProps) => {
  return (
    <div className='flex items-center gap-1'>
      <StepIcon currentStep={step} targetStep={Steps.SELECT_CHANNELS} />
      <span
        className={
          step === Steps.SELECT_CHANNELS ? 'text-button-1 text-primary-500' : 'text-body-2'
        }
      >
        Select Channels
      </span>
      <Icons.StepBar />
      <StepIcon currentStep={step} targetStep={Steps.WRITE_MESSAGE} />
      <span
        className={step === Steps.WRITE_MESSAGE ? 'text-button-1 text-primary-500' : 'text-body-2'}
      >
        Write Message
      </span>
      <Icons.StepBar />
      <StepIcon currentStep={step} targetStep={Steps.CONFIRM_AND_SEND} />
      <span
        className={
          step === Steps.CONFIRM_AND_SEND ? 'text-button-1 text-primary-500' : 'text-body-2'
        }
      >
        Confirm & Send
      </span>
    </div>
  );
};

interface SelectChannelsBodyProps {
  channels: number[];
  onChange: (channel: number) => void;
}

const SelectChannelsBody = ({ channels, onChange }: SelectChannelsBodyProps) => {
  const { data } = useQuery(['team', 'channels'], teamChannelAPI.getTeamChannels, {
    initialData: [],
  });

  return (
    <div className='w-[660px] grow space-y-4 p-8'>
      <Body2>{channels.length} Channel Selected</Body2>
      {data.map((channel) => (
        <Label className='flex gap-2' key={channel.id}>
          <Checkbox onChange={() => onChange(channel.id)} checked={channels.includes(channel.id)} />
          <Body1>{channel.name}</Body1>
        </Label>
      ))}
    </div>
  );
};

interface WriteMessageBodyProps {
  notificationRequest: NotificationRequest;
  onChange: (contents: NotificationContents) => void;
}

const WriteMessageBody = ({ notificationRequest, onChange }: WriteMessageBodyProps) => {
  return (
    <div className='w-[660px] grow space-y-10 p-8'>
      <div>
        <Label className='w-full space-y-1'>
          <Body2 className='text-gray-900'>Title</Body2>
          <Input
            className='rounded border border-gray-200 px-4 py-3'
            type='text'
            name='title'
            value={notificationRequest.title}
            onChange={(e) =>
              onChange({ message: notificationRequest.message, title: e.target.value })
            }
          />
        </Label>
      </div>
      <div>
        <Label className='w-full space-y-1'>
          <Body2 className='text-gray-900'>Message</Body2>
          <Textarea
            className='h-[160px] rounded border border-gray-200 px-4 py-3'
            defaultValue={notificationRequest.message}
            onChange={(e) =>
              onChange({ title: notificationRequest.title, message: e.currentTarget.value })
            }
          />
        </Label>
      </div>
    </div>
  );
};

interface ConfirmBodyProps {
  notificationRequest: NotificationRequest;
}

const ConfirmBody = ({ notificationRequest }: ConfirmBodyProps) => {
  const { data } = useQuery(['team', 'channels'], teamChannelAPI.getTeamChannels, {
    initialData: [],
  });

  return (
    <div className='w-[660px] grow space-y-[44px] p-8'>
      <div className='space-y-4'>
        <H4>{notificationRequest.channels.length} Selected Channels</H4>
        <div>
          {notificationRequest.channels.map((channel, index) => (
            <Body1 className='inline-block' key={index}>
              {data.find((item) => item.id === channel)?.name}
              {notificationRequest.channels.length > index + 1 && ','}
            </Body1>
          ))}
        </div>
      </div>
      <div className='space-y-4'>
        <H4>Send via Email</H4>
        <div className='flex gap-2 rounded-3xl border border-gray-200 p-4 shadow-04'>
          <Icons.Email className='h-8' />
          <div>
            <H4 className='break-all'>{notificationRequest.title}</H4>
            <Body2 className='break-all'>{notificationRequest.message}</Body2>
          </div>
        </div>
      </div>
    </div>
  );
};
