import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { StyledPopover } from './SectionDropdown';

function InfoPopup(props) {
  const { anchorEl, handleClose, selectedObj } = props;
  return (
    <StyledPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      disableEnforceFocus
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box flexDirection='column'>
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '36px',
            textTransform: 'uppercase',
            color: '#8C94A1',
          }}
        >
          {selectedObj?.title}
        </Typography>
        <Divider style={{ marginBottom: 10 }} light />
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '19px',
            color: '#353535',
            marginTop: 15,
          }}
        >
          {selectedObj?.description}
        </Typography>
        {selectedObj?.output && (
          <React.Fragment>
            <Typography
              style={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '36px',
                textTransform: 'uppercase',
                color: '#8C94A1',
                marginTop: 5,
              }}
            >
              {`Output Type (${selectedObj?.output?.name})`}
            </Typography>
            <Divider style={{ marginBottom: 10 }} light />
            {selectedObj?.output?.output_value_detail?.name && (
              <Box style={{ display: 'flex', alignItems: 'self-start' }}>
                <div style={{ marginRight: 5, marginTop: '-3px' }}>•</div>
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#353535',
                  }}
                >
                  {`Numerical (${
                    selectedObj?.output?.output_value_detail?.name +
                    ' ' +
                    selectedObj?.output?.output_value_detail?.unit
                  })`}
                </Typography>
              </Box>
            )}
            {selectedObj?.output?.output_category?.length > 0 && (
              <Box style={{ display: 'flex', alignItems: 'self-start' }}>
                <div style={{ marginRight: 5, marginTop: 2 }}>•</div>
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#353535',
                    wordBreak: 'break-word',
                    marginTop: 5,
                  }}
                >
                  {`Categorical (${selectedObj?.output?.output_category?.map(
                    (obj) => `${obj.label}`
                  )})`}
                </Typography>
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </StyledPopover>
  );
}

export default InfoPopup;
