import { OldChannelFormPage } from 'components/channels/OldChannelFormPage';
import { OldChannelPage } from 'components/channels/OldChannelPage';
import { CHANNELS_ROUTE, CHANNEL_CREATE_ROUTE, CHANNEL_ROUTE } from '../../constants';

const ChannelRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: CHANNELS_ROUTE,
      component: OldChannelPage,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CHANNEL_CREATE_ROUTE,
      // component: ChannelsForm,
      component: OldChannelFormPage,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CHANNEL_ROUTE,
      component: OldChannelFormPage,
    },
  },
];

export default ChannelRoutes;
