import axios from 'axios';
import { AUTHOR_PROFILE_API_URL, TEAM_AUTHORS } from '../constants';
import { QueryFunctionContext } from '@tanstack/react-query';
import { sprintf } from 'sprintf-js';

export interface Author {
  id: number;
  name: string;
  description: string;
  image: string;
  team: number;
}

export const authorAPI = {
  getAuthors: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Author[]>(sprintf(TEAM_AUTHORS, queryKey[1])).then((response) => response.data),
  deleteAuthor: (authorId: number) => axios.delete(`${AUTHOR_PROFILE_API_URL}${authorId}/`),
  upsertAuthor: (request: FormData, authorId: number | undefined = undefined) =>
    (authorId
      ? axios.put(`${AUTHOR_PROFILE_API_URL}${authorId}/`, request)
      : axios.post(AUTHOR_PROFILE_API_URL, request)
    ).then((response) => response.data),
};
