import { CircularProgress } from '@material-ui/core';
import React from 'react';
import buttonProgresStyles from './styles';

const ButtonProgress = ({ isLoading }) => {
  const classes = buttonProgresStyles();
  if (isLoading) return <CircularProgress size={24} className={classes.buttonProgress} />;

  return null;
};

export default ButtonProgress;
