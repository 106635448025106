import { ModuleLayout } from 'components/layout/ModuleLayout';
import { ConditionalTextResourceTable } from 'components/resources/conditional-texts/ConditionalTextTable';

export const ConditionalTexts = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <ConditionalTextResourceTable />
    </ModuleLayout>
  );
};
