import * as React from 'react';

function PushNotificationsIcon(props) {
  return (
    <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1852 11.2648V9.44502H14.7778V11.5561C14.7778 11.7428 14.852 11.9217 14.984 12.0537L16.8889 13.9586V15.0747H1.40741V13.9586L3.31233 12.0537C3.44431 11.9217 3.51848 11.7428 3.51852 11.5561V8.74132C3.51656 7.75247 3.77558 6.78062 4.26943 5.92391C4.76328 5.06721 5.47446 4.356 6.33115 3.86212C7.18784 3.36824 8.15968 3.10919 9.14853 3.11111C10.1374 3.11304 11.1082 3.37588 11.963 3.8731V2.30032C11.2931 2.00372 10.5806 1.81468 9.85185 1.74017V0.296875H8.44444V1.73947C6.70952 1.91605 5.10168 2.72966 3.93185 4.02297C2.76202 5.31629 2.11329 6.99743 2.11111 8.74132V11.2648L0.206185 13.1697C0.074206 13.3017 3.9856e-05 13.4806 0 13.6672V15.7784C0 15.965 0.07414 16.144 0.20611 16.276C0.33808 16.4079 0.51707 16.4821 0.703704 16.4821H5.62963V17.1858C5.62963 18.1189 6.00033 19.0139 6.66018 19.6737C7.32003 20.3336 8.21498 20.7043 9.14815 20.7043C10.0813 20.7043 10.9763 20.3336 11.6361 19.6737C12.296 19.0139 12.6667 18.1189 12.6667 17.1858V16.4821H17.5926C17.7792 16.4821 17.9582 16.4079 18.0902 16.276C18.2222 16.144 18.2963 15.965 18.2963 15.7784V13.6672C18.2963 13.4806 18.2221 13.3017 18.0901 13.1697L16.1852 11.2648ZM11.2593 17.1858C11.2593 17.7457 11.0368 18.2826 10.6409 18.6785C10.245 19.0745 9.70805 19.2969 9.14815 19.2969C8.58825 19.2969 8.05128 19.0745 7.65537 18.6785C7.25946 18.2826 7.03704 17.7457 7.03704 17.1858V16.4821H11.2593V17.1858Z'
        fill='black'
      />
      <path
        d='M16.1852 8.03588C17.7398 8.03588 19 6.77564 19 5.22106C19 3.66649 17.7398 2.40625 16.1852 2.40625C14.6306 2.40625 13.3704 3.66649 13.3704 5.22106C13.3704 6.77564 14.6306 8.03588 16.1852 8.03588Z'
        fill='black'
      />
    </svg>
  );
}

export default PushNotificationsIcon;
