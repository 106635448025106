function EmptyStateIcon(props) {
  return (
    <svg
      width='219'
      height='114'
      viewBox='0 0 219 114'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3'
        y='3'
        width='60'
        height='60'
        stroke='#C5D1D8'
        strokeWidth='5'
        strokeLinecap='square'
        strokeLinejoin='round'
        strokeDasharray='7 13'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.9999 24C34.9999 23.4477 34.5522 23 33.9999 23H31.9999C31.4476 23 30.9999 23.4477 30.9999 24V30C30.9999 30.5523 30.5522 31 29.9999 31L24 31C23.4477 31 23 31.4477 23 32V34C23 34.5523 23.4477 35 24 35H29.9999C30.5522 35 30.9999 35.4477 30.9999 36V42C30.9999 42.5523 31.4476 43 31.9999 43H33.9999C34.5522 43 34.9999 42.5523 34.9999 42V36C34.9999 35.4477 35.4476 35 35.9999 35H42C42.5523 35 43 34.5523 43 34V32C43 31.4477 42.5523 31 42 31L35.9999 31C35.4476 31 34.9999 30.5523 34.9999 30V24Z'
        fill='#C5D1D8'
      />
      <rect x='55' y='34' width='164' height='80' rx='4' fill='#E5EDF0' />
      <rect
        x='191'
        y='54'
        width='8'
        height='120'
        rx='2'
        transform='rotate(90 191 54)'
        fill='#C5D1D8'
      />
      <rect
        x='135'
        y='70'
        width='8'
        height='64'
        rx='2'
        transform='rotate(90 135 70)'
        fill='#C5D1D8'
      />
      <rect
        x='147'
        y='86'
        width='8'
        height='76'
        rx='2'
        transform='rotate(90 147 86)'
        fill='#C5D1D8'
      />
    </svg>
  );
}

export default EmptyStateIcon;
