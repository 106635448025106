import { Container, MenuItem, withStyles } from '@material-ui/core';

export const StyledContainer = withStyles((theme) => ({
  root: {
    paddingTop: '10px',
    borderRadius: '10px',
    width: '856px',
    marginLeft: '65px',
    color: 'black',
  },
}))(Container);

export const StyledMenuItemV2 = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#DFF5EF  !important',
      color: 'black',
    },
  },
  selected: {
    backgroundColor: '#08A88E',
    color: 'white',
  },
}))(MenuItem);
