function MySVGIcon(props) {
  return (
    <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 37137'>
        <rect id='Rectangle 3463337' x='3' y='0.5' width='9' height='1.4' fill='#2E3438' />
        <rect id='Rectangle 3463338' y='12' width='9' height='1.4' fill='#2E3438' />
        <path
          id='Rectangle 3463339'
          d='M6.85672 1.5H8.99958L5.14286 12.5H3L6.85672 1.5Z'
          fill='#2E3438'
        />
      </g>
    </svg>
  );
}

export default MySVGIcon;
