function KnowledgeBase(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#B0BDC4' />
      <path
        d='M18 39.3333C19.8242 38.2801 21.8936 37.7256 24 37.7256C26.1064 37.7256 28.1758 38.2801 30 39.3333C31.8242 38.2801 33.8936 37.7256 36 37.7256C38.1064 37.7256 40.1758 38.2801 42 39.3333'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18 22C19.8242 20.9468 21.8936 20.3923 24 20.3923C26.1064 20.3923 28.1758 20.9468 30 22C31.8242 20.9468 33.8936 20.3923 36 20.3923C38.1064 20.3923 40.1758 20.9468 42 22'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23 27C23.304 27 23.6489 27 24 27C24.3511 27 24.696 27 25 27'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M35 27C35.304 27 35.6489 27 36 27C36.3511 27 36.696 27 37 27'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23 31C23.304 31 23.6489 31 24 31C24.3511 31 24.696 31 25 31'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M35 31C35.304 31 35.6489 31 36 31C36.3511 31 36.696 31 37 31'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18 22V39.3333'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M30 22V39.3333'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M42 22V39.3333'
        stroke='#1E1F20'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default KnowledgeBase;
