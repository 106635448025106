import { createContext, useContext, useEffect, useState } from 'react';
import {
  AnswerElementSimple,
  moduleElementAPI,
  ResourceTypes,
  UIElement,
} from '../../../api/moduleElement';
import { ModuleContext } from './ModuleContext';
import { MODULE_TYPES } from '../../../constants';
import { useQuery } from '@tanstack/react-query';

export interface AnswerElementObject {
  [key: number]: AnswerElementSimple;
}

interface Context {
  uiElements: UIElement[];
  refetch: () => void;
  isLoading: boolean;
  answerElements: AnswerElementObject;
  refetchAnswerElements: () => void;
}

export const UiElementContext = createContext<Context>({
  uiElements: [],
  refetch: () => null,
  isLoading: true,
  answerElements: [],
  refetchAnswerElements: () => null,
});

export function UiElementProvider({ children }) {
  const { module } = useContext(ModuleContext);
  const moduleType =
    module?.type === MODULE_TYPES.ALGO
      ? 'modules'
      : module?.type === MODULE_TYPES.CALCULATOR
      ? 'calculators'
      : null;
  const { data: uiElements, refetch: refetchUiElements } = useQuery(
    [moduleType, module?.id, 'uiElements'],
    moduleElementAPI.getUIElements,
    {
      enabled: !!module?.id,
      initialData: [],
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const { data: containers, refetch: refetchContainers } = useQuery(
    [moduleType, module?.id, 'containers'],
    moduleElementAPI.getContainers,
    {
      enabled: !!module?.id,
      initialData: [],
      staleTime: Infinity,
      cacheTime: Infinity,
      select: (containers): UIElement[] =>
        containers.map((container) => ({
          ...container,
          resourcetype: ResourceTypes.group,
        })),
    }
  );

  const { data: answerElements, refetch: refetchAnswerElements } = useQuery(
    [moduleType, module?.id, 'answers'],
    moduleElementAPI.getAnswers,
    {
      select: (data): AnswerElementObject =>
        data.reduce(
          (answers, element, idx) => ({
            ...answers,
            [(element.position ?? idx).toString()]: {
              id: element.id,
              title: element.title,
              trigger: element.trigger,
              module: element.module,
              triggerDetail: element.trigger_detail,
              answerCards: element.answer_cards,
              status: element.status,
            },
          }),
          {}
        ),
      enabled: !!module?.id && module?.type === MODULE_TYPES.ALGO,
      initialData: [],
      initialDataUpdatedAt: 0,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  // to check if both queries have been fetched (ui element, container)
  const [isLoading, setIsLoading] = useState(true);
  const refetchModuleElements = () => {
    setIsLoading(true);
    Promise.allSettled([refetchUiElements(), refetchContainers()]).then(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!module?.id) return;
    refetchModuleElements();
  }, [module?.id]);

  const contextValue = {
    uiElements: [...uiElements, ...containers],
    refetch: refetchModuleElements,
    isLoading,
    answerElements,
    refetchAnswerElements,
  };

  return <UiElementContext.Provider value={contextValue}>{children}</UiElementContext.Provider>;
}
