import {
  CaptionProps,
  DayPicker,
  useNavigation,
  useDayRender,
  CaptionLabel,
  Button as DatePickerButton,
  DayProps,
  useSelectSingle,
} from 'react-day-picker';
import { format, isSameDay, Locale } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../common/Button';
import { twMerge } from 'tailwind-merge';

interface DatePickerProps {
  selected: Date | null;
  onDayClick: (date: Date | null) => void;
}

function DatePicker({ selected, onDayClick }: DatePickerProps) {
  return (
    <DayPicker
      mode='single'
      components={{ Caption, Day }}
      showOutsideDays
      formatters={{ formatWeekdayName, formatCaption }}
      classNames={{
        caption_label: 'text-heading-4',
        head_row: 'text-caption-1 text-gray-700',
        head: 'border-b-0',
        head_cell: 'text-center text-body-1 py-1.5',
        cell: 'text-center text-body-1 py-0',
        table: 'w-full',
        root: 'select-none',
      }}
      selected={selected ?? undefined}
      fromYear={new Date().getFullYear()}
      onDayClick={onDayClick}
    />
  );
}

function formatWeekdayName(weekday: Date, options?: { locale?: Locale }): string {
  return format(weekday, 'ccc', options);
}

function formatCaption(displayMonth: Date, options?: { locale?: Locale }): string {
  return format(displayMonth, 'LLL yyyy', options);
}

function Caption(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className='mb-3 flex justify-between'>
      <button
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className='focus:bg-transparent disabled:text-gray-300'
      >
        <ChevronLeftIcon className='h-6 w-6' />
      </button>
      {/* TODO: modify CaptionDropdown if needed */}
      <CaptionLabel
        displayMonth={props.displayMonth}
        displayIndex={props.displayIndex}
        id={props.id}
      />
      <button
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className='focus:bg-transparent disabled:text-gray-300'
      >
        <ChevronRightIcon className='h-6 w-6' />
      </button>
    </div>
  );
}

function Day(props: DayProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

  const isDifferentMonth = props.displayMonth.getMonth() !== props.date.getMonth();

  const { selected } = useSelectSingle();

  if (dayRender.isHidden) {
    return <div role='gridcell'></div>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }
  return (
    <DatePickerButton
      name='day'
      ref={buttonRef}
      {...dayRender.buttonProps}
      disabled={isDifferentMonth}
      className={twMerge(
        'h-9 w-9 rounded-full hover:bg-primary-300 focus:bg-primary-300 disabled:text-gray-300',
        !!selected && isSameDay(props.date, selected) && '!bg-primary-500 !text-white',
        isSameDay(props.date, new Date()) && 'text-primary-600'
      )}
    />
  );
}

interface DatePickerModalProps {
  open: boolean;
  onClose: () => void;
  selectedDate: Date | null;
  onSelect: (selected: Date | null) => void;
}

export function DatePickerModal({ open, onClose, selectedDate, onSelect }: DatePickerModalProps) {
  const [selected, setSelected] = useState<Date | null>(selectedDate);

  useEffect(() => {
    if (!!selectedDate) {
      setSelected(selectedDate);
    }
  }, [selectedDate, setSelected]);

  const handleClick = () => {
    onSelect(selected ?? null);
    onClose();
  };

  return (
    <Modal className='!px-0' open={open} onClose={onClose}>
      <Modal.Head onClose={onClose} className='border-b px-3'>
        Select Date
      </Modal.Head>
      <Modal.Body className='px-3'>
        <>
          <DatePicker selected={selected} onDayClick={(date) => setSelected(date)} />
          <div className='flex flex-row-reverse gap-x-2 pt-3'>
            <Button
              className='bg-primary-500 px-8 text-white disabled:bg-gray-200'
              disabled={!selected}
              onClick={handleClick}
            >
              Save
            </Button>
            <Button className='bg-white px-6 text-primary-500' onClick={onClose}>
              Cancel
            </Button>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}
