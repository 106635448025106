export const MODULE_STATUSES = {
  notSynced: 'Draft',
  underRevision: 'Under Revision',
  synced: 'Published',
};

export enum MODULE_TYPES {
  ALGO = 'algo',
  CALCULATOR = 'calculator',
  KNOWLEDGE_BASE = 'knowledge_base',
  MIRROR = 'mirror',
}

export enum MODULE_LABELS {
  ALGO = 'ALGO',
  CALCULATOR = 'Calculator',
  KNOWLEDGE_BASE = 'Knowledge Base',
  MIRROR = 'Mirror',
}

export const MODULE_TYPES_MAP = {
  [MODULE_TYPES.ALGO]: 'ALGO',
  [MODULE_TYPES.CALCULATOR]: 'Calculator',
  [MODULE_TYPES.KNOWLEDGE_BASE]: 'Knowledge Base',
  [MODULE_TYPES.MIRROR]: 'Mirror',
};
