import React from 'react';

interface IconProps {
  className?: string;
}

export const MainSearchIcon = ({ className }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75 2.5C12.2018 2.5 15 5.29822 15 8.75C15 10.2508 14.471 11.628 13.5893 12.7055L17.1783 16.2944L16.2944 17.1783L12.7055 13.5893C11.628 14.471 10.2508 15 8.75 15C5.29822 15 2.5 12.2018 2.5 8.75C2.5 5.29822 5.29822 2.5 8.75 2.5ZM8.74921 3.75C5.98778 3.75 3.74921 5.98858 3.74921 8.75C3.74921 11.5114 5.98778 13.75 8.74921 13.75C11.5106 13.75 13.7492 11.5114 13.7492 8.75C13.7492 5.98858 11.5106 3.75 8.74921 3.75Z'
      fill='#6C7C83'
    />
  </svg>
);
