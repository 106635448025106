import { Dispatch, HTMLAttributes, SetStateAction, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface Rule {
  label: string;
  checked: boolean;
}

interface RuleOptions {
  isLengthValid: boolean;
  hasSpecialChar: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
}

interface PasswordRuleProps {
  password: string;
  setMatched: Dispatch<SetStateAction<boolean>>;
}

const PasswordRule = ({ password, setMatched }: PasswordRuleProps) => {
  const ruleOptions = useMemo<RuleOptions>(() => checkPasswordCreationRules(password), [password]);

  const rules = useMemo<Rule[]>(
    () => [
      { label: 'One lower case character', checked: ruleOptions.hasLowerCase },
      { label: 'One special character', checked: ruleOptions.hasSpecialChar },
      { label: 'One upper case character', checked: ruleOptions.hasUpperCase },
      { label: '8 characters minimum', checked: ruleOptions.isLengthValid },
      { label: 'One number', checked: ruleOptions.hasNumber },
    ],
    [ruleOptions]
  );

  useEffect(() => {
    setMatched(Object.keys(ruleOptions).every((target) => ruleOptions[target]));
  }, [ruleOptions]);

  return (
    <div className='my-2 ml-1 w-full text-xs font-normal leading-4 text-gray-700'>
      {rules.map((rule, index) => (
        <div key={index} className='!mb-0 flex h-6 items-center'>
          <CheckBoxIcon className={rule.checked ? 'text-primary-600' : 'text-gray-400'} />
          <span
            style={{
              // add inline style becuse of tailwind classes not reflecting in modal
              color: '#566267',
            }}
            className='pl-1'
          >
            {rule.label}
          </span>
        </div>
      ))}
    </div>
  );
};

const CheckBoxIcon = ({ className }: HTMLAttributes<HTMLSpanElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    className={twMerge(className, 'h-5 w-5')}
  >
    <path
      fillRule='evenodd'
      d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
      clipRule='evenodd'
    />
  </svg>
);

const checkPasswordCreationRules = (password: string): RuleOptions => {
  // Password creation rules
  const isLengthValid = password.length >= 8;
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);

  return {
    isLengthValid,
    hasSpecialChar,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
  };
};

export default PasswordRule;
