function Media(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#E5EDF0' />
      <path
        d='M41.2 20.8H31.44L28.56 18L18.7999 18C17.2159 18 15.9199 19.26 15.9199 20.8L15.9199 38.4C15.9199 39.94 17.2159 41.2 18.7999 41.2L41.2 41.2C42.784 41.2 44.08 39.94 44.08 38.4V23.6C44.08 22.06 42.784 20.8 41.2 20.8ZM41.2 38.4L18.7999 38.4V20.8L27.408 20.8L30.288 23.6H41.2V38.4ZM38.96 36.4L33.2 29.2L28.4 34.8L24.4 30.8L20.4 36.4H38.96ZM27.12 26.5C27.12 25.38 26.112 24.4 24.96 24.4C23.808 24.4 22.8 25.38 22.8 26.5C22.8 27.62 23.808 28.6 24.96 28.6C26.112 28.6 27.12 27.62 27.12 26.5Z'
        fill='#2E3438'
      />
    </svg>
  );
}

export default Media;
