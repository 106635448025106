export function MaintenancePage() {
  return (
    <div className='text-center font-sans p-20'>
      <h1 className='text-3xl mb-6'>Website Maintenance</h1>
      <p className='text-lg mb-4'>
        We're currently undergoing scheduled maintenance. Please check back soon.
      </p>
      <p className='text-lg'>We apologize for any inconvenience and appreciate your patience.</p>
    </div>
  );
}
