import { Popover, Transition } from '@headlessui/react';
import Button from 'components/utils/Button';
import { Icons } from 'components/utils/Icons';
import { H4 } from 'components/utils/typo';
import { Fragment, useState } from 'react';

interface ChannelShareProps {
  channelCode: string;
  password?: string;
}

export const ChannelShare = ({ channelCode, password }: ChannelShareProps) => {
  const shareLink =
    process.env.REACT_APP_WEB_APP_URL +
    '/web/subscribeChannel?code=' +
    channelCode +
    (password ? '&pwd=' + password : '');

  const [copied, setCopied] = useState(false);
  const copyToClipBoard = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  return (
    <>
      <Popover className='relative'>
        <Popover.Button>
          <div title='Link'>
            <Icons.Share />
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-200'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'
        >
          <Popover.Panel className='absolute right-0 z-10 mt-3 w-screen max-w-[576px] transform bg-white'>
            <div className='overflow-hidden rounded p-[24px] shadow-lg ring-1 ring-black/5'>
              <div className='space-y-[16px]'>
                <H4>Copy Link</H4>
                <div className='flex items-stretch space-x-[8px]'>
                  <div className='grow overflow-x-auto rounded border border-gray-200 px-[12px] py-[10px]'>
                    <span className='whitespace-nowrap'>{shareLink}</span>
                  </div>
                  <div className='w-[80px] shrink-0'>
                    <Button className='h-full' onClick={copyToClipBoard}>
                      {copied ? 'Copied!' : 'Copy'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};
