import { Check } from '@material-ui/icons';
import { useState } from 'react';

import EditIcon from 'assets/icons/grayEditIcon';
import clsx from 'clsx';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { twJoin } from 'tailwind-merge';
import { getVariableName } from 'utils/gptUtils';
import { Icons } from '../Icons';

function VariableAutoGenerator({ question, tagTitle, variable, setVariableName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isNameEditing, setIsNameEditing] = useState(false);

  const generateVariableName = async () => {
    if (!question) {
      setVariableName('');
      return;
    }
    setVariableName('');
    setIsLoading(true);
    const variableName = await getVariableName(question);
    setVariableName(variableName);
    setIsLoading(false);
  };

  return (
    <div className='space-y-2'>
      <div className='flex items-center gap-2'>
        <div className='text-body-2'>Save responses as</div>
        <Icons.Refresh
          onClick={generateVariableName}
          className={clsx(
            'mt-1',
            isLoading || question?.length <= 0 ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
        />
      </div>

      <div className='flex'>
        <div className='flex !h-7 items-center justify-center rounded-l-sm bg-gray-200 !px-2'>
          <span className='text-caption-2 text-gray-700'>{`{x} ${tagTitle}`}</span>
        </div>
        {isLoading ? (
          <div className='ml-3 flex items-center'>
            <LoadingSpinner size='small' />
          </div>
        ) : (
          <div className='flex !h-7 items-center justify-center gap-1 rounded-r-sm bg-gray-100 !px-2'>
            {isNameEditing ? (
              <>
                <input
                  value={variable}
                  className='shadow-none outline-none'
                  onChange={(e) => setVariableName(e.target.value)}
                />
                <Check
                  className='!ml-2 cursor-pointer bg-primary-600'
                  onClick={() => setIsNameEditing(false)}
                />
              </>
            ) : (
              <>
                <span
                  className={twJoin('text-caption-2', variable ? 'text-gray-900' : 'text-gray-600')}
                >
                  {variable ? variable : 'Unnamed'}
                </span>
                <EditIcon className='!ml-2 cursor-pointer' onClick={() => setIsNameEditing(true)} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default VariableAutoGenerator;
