import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

interface ProgressBarProps {
  percentage?: number;
  fail?: boolean;
}

export const ProgressBar = ({ percentage, fail }: ProgressBarProps) => {
  const controls = useAnimation();
  const [fast, setFast] = useState(false);

  useEffect(() => {
    if (!!percentage) return;
    controls.start({
      width: ['0%', '60%', '0%'],
      left: ['0%', '40%', '100%'],
      transition: {
        repeat: 0,
        duration: fast ? 0.6 : 1.2,
        times: fast ? [0, 0.6, 1] : [0, 0.7, 1],
        ease: 'linear',
        onComplete: () => {
          setFast(!fast);
        },
      },
    });
  }, [fast, controls, percentage]);

  return (
    <div className='relative h-1 overflow-hidden rounded-full bg-gray-200'>
      {fail ? (
        <div className='absolute left-0 right-0 top-0 h-1 rounded-full bg-red-500' />
      ) : percentage ? (
        <div
          className='absolute left-0 top-0 h-1 rounded-full bg-primary-500'
          style={{ width: `${Math.max(0, Math.min(100, percentage))}%` }}
        />
      ) : (
        <motion.div className='absolute h-1 rounded-full bg-primary-500' animate={controls} />
      )}
    </div>
  );
};
