import { PlusIcon } from '@heroicons/react/20/solid';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import { useToolOptions } from 'components/utils/example-n-tools/useToolOptions';
import { generate_ID } from 'components/utils/general/generateId';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { SlidePanel } from 'components/utils/panels/SlidePanel';
import { useContext, useState } from 'react';
import { AddItem } from '../AddItem';
import Button from '../Button';

export interface ToolType {
  id: string;
  type: string;
  label?: string;
  section: 'E' | 'T';
  infobox?: any;
  calculator?: any;
  legacy_calculator?: any;
  text?: { statement: string };
}

interface ExampleNToolButtonsProps {
  list: ToolType[];
  section: 'E' | 'T';
  buttonsToShow?: string[];
  onChange: (list: ToolType[]) => void;
}

export const ExampleNToolButtons = ({
  list,
  section,
  onChange,
  buttonsToShow,
}: ExampleNToolButtonsProps) => {
  const { module } = useContext(ModuleContext);
  const moduleType = module?.type;
  const { infoboxOptions, calcOptions, refetchInfoboxes } = useToolOptions();
  const [infoboxIdToEdit, setInfoboxIdToEdit] = useState('');
  const [createNewInfobox, setCreateNewInfobox] = useState(false);

  const addText = () => {
    const textItem = {
      id: generate_ID(),
      section,
      type: 'text',
      text: { statement: 'Text' },
    };
    onChange([...list, textItem]);
  };

  const addInfobox = (item) => {
    const infoboxItem = {
      id: generate_ID(),
      section,
      infobox: item,
      type: 'infobox',
      label: item?.textToDisplay || item?.label || item?.title,
    };
    onChange([...list, infoboxItem]);
  };

  const addCalculator = (item) => {
    let calculatorItem = {
      id: generate_ID(),
      section: 'T' as 'T' | 'E',
      type: item?.type,
      label: item?.label,
    };

    if (item.type === 'calculator') {
      calculatorItem['calculator'] = item;
    } else if (item.type === 'legacy_calculator') {
      calculatorItem['legacy_calculator'] = item;
    }
    onChange([...list, calculatorItem]);
  };

  const buttonsArray: JSX.Element[] = [
    <Button.Outline
      type='button'
      data-key='text'
      onClick={addText}
      className='flex h-9 !w-fit items-center gap-1 !text-button-1 !text-gray-700'
    >
      <PlusIcon className='h-4 w-4' />
      <span>Text</span>
    </Button.Outline>,
    <ToolButton
      addTextField
      data-key='infobox'
      addItem={addInfobox}
      buttonLabel='Infobox'
      options={infoboxOptions}
      onEdit={(id) => setInfoboxIdToEdit(id)}
      onCreate={() => setCreateNewInfobox(true)}
      createButtonLabel='Create New Infobox/Media'
    />,
    <ToolButton
      data-key='calculator'
      options={calcOptions}
      addItem={addCalculator}
      buttonLabel='Calculator'
    />,
  ];

  const filteredBtns = !buttonsToShow
    ? buttonsArray
    : buttonsArray.filter((button) => buttonsToShow.includes(button.props['data-key']));

  return (
    <>
      <div className='flex gap-1'>{filteredBtns}</div>
      <SlidePanel
        className='pl-6'
        open={createNewInfobox}
        onClose={() => setCreateNewInfobox(false)}
      >
        <InfoBoxForm
          isFullHeight
          type={moduleType}
          savePosition={() => []}
          selectValue={addInfobox}
          toggleModal={() => setCreateNewInfobox(false)}
        />
      </SlidePanel>
      <SlidePanel className='pl-6' open={!!infoboxIdToEdit} onClose={() => setInfoboxIdToEdit('')}>
        <InfoBoxForm
          isFullHeight
          type={moduleType}
          savePosition={() => []}
          infoBoxId={infoboxIdToEdit}
          toggleModal={() => setInfoboxIdToEdit('')}
          selectValue={refetchInfoboxes}
        />
      </SlidePanel>
    </>
  );
};

interface ToolButtonProps {
  options: any;
  buttonLabel: string;
  onCreate?: () => void;
  addTextField?: boolean;
  createButtonLabel?: string;
  addItem: (item: any) => void;
  onEdit?: (id: string) => void;
}

const ToolButton = ({
  options,
  onEdit,
  addItem,
  onCreate,
  buttonLabel,
  addTextField,
  createButtonLabel,
}: ToolButtonProps) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className='relative'>
      <Button.Outline
        type='button'
        onClick={() => setOpenPopup(true)}
        className='flex h-9 !w-fit items-center gap-1 !text-button-1 !text-gray-700'
      >
        <PlusIcon className='h-4 w-4' />
        <span>{buttonLabel}</span>
      </Button.Outline>
      <AddItem
        direction='right'
        classes='bottom-0'
        open={openPopup}
        options={options}
        onEdit={onEdit}
        addItem={addItem}
        onCreate={onCreate}
        addTextField={addTextField}
        onClose={() => setOpenPopup(false)}
        createButtonLabel={createButtonLabel}
      />
    </div>
  );
};
