import { PermissionMap } from '../../../constants';
import DefaultPermissionItem from './DefaultPermissionItem';
import { IMemberPermission } from './MemberPermissionDropdown';
import MemberPermissionItem from './MemberPermissionItem';
import { useTeam } from '../../../hooks/useTeam';
import { ROLES } from '../../../api/team';

interface MemberPermissionListProps {
  members: IMemberPermission[];
  defaultPermission?: string;
  onChange?: (members: IMemberPermission[]) => void;
  onDefaultPermissionChange: (permission: string) => void;
}

const MemberPermissionList = ({
  members,
  defaultPermission,
  onChange,
  onDefaultPermissionChange,
}: MemberPermissionListProps) => {
  const { team } = useTeam();
  const isAdmin = team.current_teammate?.role === ROLES.Admin;
  const onDelete = (team_member_id: number) => {
    onChange?.(members.filter((member) => member.team_member_id !== team_member_id));
  };

  const onPermissionChange = (team_member_id: number, permission: string) => {
    const newMembers = members.map((member) =>
      member.team_member_id === team_member_id
        ? { ...member, permission_type: permission }
        : permission === PermissionMap.owner.value &&
          member.permission_type === PermissionMap.owner.value
        ? { ...member, permission_type: PermissionMap.can_edit.value }
        : member
    );
    onChange?.(newMembers);
  };

  return (
    <div>
      {members.map((member, idx) => (
        <MemberPermissionItem
          key={idx}
          member={member}
          onPermissionChange={onPermissionChange}
          onDelete={onDelete}
        />
      ))}
      {isAdmin && (
        <DefaultPermissionItem
          onDefaultPermissionChange={onDefaultPermissionChange}
          defaultPermission={defaultPermission}
        />
      )}
    </div>
  );
};

export default MemberPermissionList;
