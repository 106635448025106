import 'css/custom.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DataExtractorFormVariable, VariablesToGenerateTypes } from '../../../api/data-extractor';
import { UIElement } from '../../../api/moduleElement';
import { DataExtractorForm } from '../../panels/data-extractor/DataExtractorForm';
import { DefaultDataToConsume } from '../../panels/data-extractor/data-to-consume/DataToConsumeListInput';
import { MentionProvider } from '../../utils/module/MentionContext';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { Slide } from '../../utils/panels/FloatingSlidePanel';
import { TiptapPreview } from '../../utils/tiptap/Tiptap';
import { Body2, H4 } from '../../utils/typo';
import { DraggableContext, ModuleElementContext } from '../ModuleBoard';
import { CardContainer } from './utils/CardContainer';

export const useDataExtractorFormContext = useFormContext<DataExtractorFormVariable>;

const VariablesToGerateTypesToVariableType: Record<VariablesToGenerateTypes, string> = {
  [VariablesToGenerateTypes.CATEGORICAL]: 'Category',
  [VariablesToGenerateTypes.TEXT]: 'Text',
  [VariablesToGenerateTypes.NUMBER]: 'Number',
  [VariablesToGenerateTypes.BOOLEAN]: 'Boolean',
  [VariablesToGenerateTypes.LIST]: 'List',
};

interface DataExtractorCardProps {
  element: UIElement;
  containerId?: number;
}

export const DataExtractorCard = ({ element, containerId }: DataExtractorCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, clearModuleElements } = useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(!element.id);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const alwayOnTrigger = triggerList.find((data) => data.title === 'Always On');

  const defaultValues: DataExtractorFormVariable = {
    id: element.id ?? undefined,
    title: element.title ?? '',
    description: element.description ?? {},
    variable_name: element.variable_name,
    trigger: element.trigger ? element.trigger : alwayOnTrigger?.id,
    module: module!.id,
    data_to_consume: element.data_to_consume ?? [{ ...DefaultDataToConsume }],
    variables_to_generate: element.variables_to_generate ?? [],
    position: element.position,
    container: containerId,
  };

  const form = useForm<DataExtractorFormVariable>({
    defaultValues,
  });

  const { watch, register, handleSubmit, setValue, reset, control } = form;
  const dataToConsume = watch('data_to_consume');
  const variableToGenerate = watch('variables_to_generate');
  const title = watch('title');
  const description = watch('description');

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const onSave = () => {
    refetchModuleElements();
    setPanelModalOpen(false);
    setPanelOpen(false);
  };

  const variable = form.watch('variable_name');
  const variables = useMemo(
    () => [
      ...(!!variable
        ? [
            {
              type: 'Data Extractor',
              name: variable,
            },
          ]
        : []),
      ...variableToGenerate.map((item) => ({
        type: VariablesToGerateTypesToVariableType[item.variable_type],
        name: item.name,
      })),
    ],
    [variable, variableToGenerate]
  );

  const { setIsDragDisabled } = useContext(DraggableContext);
  useEffect(() => {
    setIsDragDisabled(panelOpen);
  }, [panelOpen]);

  return (
    <FormProvider {...form}>
      <CardContainer
        elementId={element.id}
        onOpenPanel={() => setPanelOpen(true)}
        isPreviewing={panelOpen}
        isContainer={isContainer}
        variables={variables}
      >
        <H4 className='text-gray-900'>{title}</H4>
        <div className='space-y-3'>
          {Object.keys(description).length !== 0 && (
            <Body2 className='text-gray-900'>
              <MentionProvider excludedSuggestionTypes={[]} performChoiceCodeSubstitution>
                <TiptapPreview content={description} />
              </MentionProvider>
            </Body2>
          )}
          {dataToConsume.length > 0 && dataToConsume.some((item) => !!item.label) && (
            <div className='flex gap-1'>
              <p className='m-0 text-button-1 text-gray-700'>Data to consume: </p>
              <Body2 className='text-gray-700'>
                {dataToConsume.map((item) => item.label).join(', ')}
              </Body2>
            </div>
          )}
        </div>
      </CardContainer>

      <Slide open={panelOpen} onOpenChange={() => setPanelModalOpen(true)}>
        <Slide.Panel className='w-[424px]'>
          <DataExtractorForm
            onSave={onSave}
            closeModal={() => setPanelModalOpen(false)}
            closePanel={handlePanelClose}
            modal={panelModalOpen}
          />
        </Slide.Panel>
      </Slide>
    </FormProvider>
  );
};
