import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import '../simulator.styles.css';

function NotePreview({ previewContent, textJsonTiptap }) {
  return (
    <div className='preview-box'>
      <AvoEditorPreview
        contentTiptap={textJsonTiptap}
        contentDraftJS={previewContent}
        isSimulator={true}
      />
    </div>
  );
}

export default NotePreview;
