import { StyledPopover } from 'components/utils/SectionDropdown';
import TextHighlighter from 'components/utils/text-higlighter/textHighlighter';
import { convertTriggerCondition } from 'utils/utilityFunctions';

function TriggerPopup(props) {
  const { anchorEl, handleClose, selectedObj } = props;
  return (
    <StyledPopover
      disableScrollLock
      isTrigger={true}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      disableEnforceFocus
      disableAutoFocus
      transitionDuration={0}
      closeAfterTransition
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div data-testid='trigger-popup'>
        {selectedObj?.condition && (
          <TextHighlighter triggerPopup text={convertTriggerCondition(selectedObj.condition)} />
        )}
      </div>
    </StyledPopover>
  );
}

export default TriggerPopup;
