import { useRef, useState } from 'react';
import {
  autoUpdate,
  flip,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import {
  BooleanVariable,
  CategoricalVariable,
  ListVariable,
  NumberVariable,
  TextVariable,
  VariablesToGenerateTypes,
} from '../../../../api/data-extractor';
import { twJoin } from 'tailwind-merge';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ExpandableList } from '../../../utils/form-input/ExpandableList';
import { Icons } from '../../../utils/Icons';
import { Body1, Caption2 } from '../../../utils/typo';
import { Slide } from '../../../utils/panels/FloatingSlidePanel';
import { VariablesToGeneratePanel } from './VariableToGeneratePanel';
import { useDataExtractorFormContext } from '../../../module/cards/DataExtractorCard';
import Button from '../../../utils/Button';

type VariableTypes =
  | CategoricalVariable
  | TextVariable
  | NumberVariable
  | BooleanVariable
  | ListVariable;

export const DefaultVariableToGenerate: VariableTypes = {
  name: '',
  description: {},
  variable_type: VariablesToGenerateTypes.TEXT,
};

const VariablesToGerateTypesToLabel: Record<VariablesToGenerateTypes, string> = {
  [VariablesToGenerateTypes.CATEGORICAL]: 'Categorical',
  [VariablesToGenerateTypes.TEXT]: 'Text',
  [VariablesToGenerateTypes.NUMBER]: 'Number',
  [VariablesToGenerateTypes.BOOLEAN]: 'Boolean',
  [VariablesToGenerateTypes.LIST]: 'List (Array)',
};

const VariablesToGerateTypesToShortLabel: Record<VariablesToGenerateTypes, string> = {
  [VariablesToGenerateTypes.CATEGORICAL]: 'Category',
  [VariablesToGenerateTypes.TEXT]: 'Text',
  [VariablesToGenerateTypes.NUMBER]: 'Number',
  [VariablesToGenerateTypes.BOOLEAN]: 'Boolean',
  [VariablesToGenerateTypes.LIST]: 'List',
};

export const VariablesToGenerateController = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [panelOpen, onPanelOpenChange] = useState(false);
  // floating ui settings
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, onOpenChange] = useState(false);
  const listRef = useRef<(HTMLButtonElement | null)[]>([]);
  const { refs, context, floatingStyles } = useFloating({
    open,
    onOpenChange,
    placement: 'bottom-start',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    referencePressEvent: 'click',
  });
  const role = useRole(context, { role: 'listbox' });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    focus,
    dismiss,
    role,
    listNavigation,
  ]);

  // hook form settings
  const [index, setIndex] = useState<number>(0);
  const { watch, setValue } = useDataExtractorFormContext();
  const variableToGenerates = watch('variables_to_generate');
  const handleCreate = (item: VariableTypes) => {
    onOpenChange(false);
    onPanelOpenChange(true);
    setIndex(variableToGenerates.length);
    setValue('variables_to_generate', [...variableToGenerates, item]);
  };

  const handleEdit = (index: number) => {
    onOpenChange(false);
    onPanelOpenChange(true);
    setIndex(index);
  };

  const handleDelete = (index: number) => {
    setValue(
      'variables_to_generate',
      variableToGenerates.filter((_, itemIndex) => itemIndex !== index)
    );
  };

  return (
    <>
      <div>
        <Button.Reverse
          type='button'
          ref={refs.setReference}
          onClick={() => {}}
          className={twJoin(
            'w-full rounded border  px-[10px] py-[8px] text-button-1',
            'focus:bg-transparent',
            '!border-primary-500 text-primary-500 transition-all hover:bg-gray-50 hover:text-primary-600',
            'flex h-9 !w-fit items-center gap-1 !text-button-1 !text-gray-700'
          )}
          {...getReferenceProps()}
        >
          <PlusIcon className='h-4 w-4' />
          <span>Variable</span>
        </Button.Reverse>
        {open && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className='z-10 w-[173px] bg-white py-[4px] shadow-04'
          >
            {Object.keys(VariablesToGerateTypesToLabel).map((type, index) => (
              <button
                type='button'
                className='w-full px-[12px] py-[10px] text-left hover:bg-primary-200 focus:bg-primary-200'
                tabIndex={activeIndex === index ? 0 : -1}
                ref={(ref) => {
                  listRef.current[index] = ref;
                }}
                {...getItemProps()}
                onClick={() =>
                  handleCreate({
                    ...DefaultVariableToGenerate,
                    variable_type: type as VariablesToGenerateTypes,
                  } as VariableTypes)
                }
              >
                {VariablesToGerateTypesToLabel[type]}
              </button>
            ))}
          </div>
        )}
      </div>
      {variableToGenerates.length > 0 && (
        <ExpandableList
          setValue={(value) => setValue('variables_to_generate', value)}
          items={variableToGenerates}
        >
          <ExpandableList.Items>
            {variableToGenerates.map((item, index) => (
              <ExpandableList.Item index={index} onDoubleClick={() => handleEdit(index)}>
                <ExpandableList.Button className='flex items-center gap-[4px] px-[16px] py-[12px]'>
                  <div className='flex grow flex-col gap-[2px]'>
                    <div className='flex items-center'>
                      <span className='flex items-center gap-[2px] rounded-[2px] bg-gray-100 p-[2px] pr-[4px]'>
                        <Icons.Variable className='fill-gray-600 stroke-gray-600' />
                        <Caption2 className='text-gray-700'>
                          {VariablesToGerateTypesToShortLabel[item.variable_type]}
                        </Caption2>
                      </span>
                    </div>
                    <Body1 className='line-clamp-1 h-[24px] text-gray-900'>{item.name}</Body1>
                  </div>
                  <Icons.Edit
                    className='cursor-pointer fill-gray-600 hover:fill-primary-600'
                    onClick={() => handleEdit(index)}
                  />
                  <Icons.TrashBin
                    className='cursor-pointer fill-gray-600 hover:fill-primary-600'
                    onClick={() => handleDelete(index)}
                  />
                </ExpandableList.Button>
              </ExpandableList.Item>
            ))}
          </ExpandableList.Items>
        </ExpandableList>
      )}
      <Slide open={panelOpen} onOpenChange={() => setModalOpen(true)}>
        <Slide.Panel className='w-[424px]' lockScroll>
          <VariablesToGeneratePanel
            modalOpen={modalOpen}
            onModalClose={() => setModalOpen(false)}
            onClose={() => onPanelOpenChange(false)}
            index={index}
          />
        </Slide.Panel>
      </Slide>
    </>
  );
};
