import axios from 'axios';
import { TEAM_API_URL } from '../../constants';
import { GET_MODULES_LIST } from '../actionsTypes';
import { TEAM_QUERY_KEY } from '../../hooks/useTeam';
import { queryClient } from '../../queryClient';

const modulesListAction = () => async (dispatch, getState) => {
  const team = queryClient.getQueryData([TEAM_QUERY_KEY]);
  const res = await axios.get(TEAM_API_URL + team?.id + '/modules');

  dispatch({
    type: GET_MODULES_LIST,
    payload: res?.data || [],
  });
};

export default modulesListAction;
