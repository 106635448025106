import { GET_NOTE_GENERATOR } from 'actions/actionsTypes';

const noteGeneratorReducer = (state = { noteGenerators: [] }, action) => {
  switch (action.type) {
    case GET_NOTE_GENERATOR:
      return {
        ...state,
        noteGenerators: action.payload,
      };

    default:
      return state;
  }
};

export default noteGeneratorReducer;
