import { Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOff } from '@material-ui/icons';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import GalleryIcon from 'assets/icons/galleryIcon';
import axios from 'axios';
import NavBar from 'components/bars/NavBar';
import Footer from 'components/layout/Footer';
import { MemberInput } from 'components/panels/ChoicePanelForm';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import InputField from 'components/utils/form-input/field';
import FieldCharLimit from 'components/utils/form-input/fieldCharLimit';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { AUTHOR_PROFILE_API_URL, MEM_MANGEMENT_ROUTE } from 'constants';
import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import { withTeam } from '../../hooks/useTeam';

const AuthorPopup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '1500px',
    minHeight: '782px',
    height: 'auto',
    overflow: 'auto',

    [theme.breakpoints.down('1500')]: {
      // marginTop: '40px',
      width: '1250px',
      minHeight: '750px',
    },
    [theme.breakpoints.down('md')]: {
      // marginTop: '40px',
      width: '950px',
      minHeight: '652px',
    },

    marginTop: '50px',
    marginBottom: '100px',
    background: '#FFFFFF',
    borderRadius: '30px',
  },
}))(Box);

const DragBox = withStyles((theme) => ({
  root: {
    width: '495px',
    height: '407px',

    [theme.breakpoints.down('1500')]: {
      // marginTop: '40px',
      width: '400px',
      height: '382px',
    },
    [theme.breakpoints.down('md')]: {
      // marginTop: '40px',
      width: '350px',
      height: '282px',
    },
  },
}))(Box);
const MarginBox = withStyles((theme) => ({
  root: {
    marginRight: '90px',

    [theme.breakpoints.down('1500')]: {
      // marginTop: '40px',
      marginRight: '40px',
    },
    [theme.breakpoints.down('md')]: {
      // marginTop: '40px',
      marginRight: '10px',
    },
  },
}))(Box);
const DoubleMarginBox = withStyles((theme) => ({
  root: {
    marginRight: '90px',
    marginLeft: '90px',

    [theme.breakpoints.down('1500')]: {
      // marginTop: '40px',
      marginLeft: '90px',
      marginRight: '40px',
    },
    [theme.breakpoints.down('md')]: {
      // marginTop: '40px',
      marginRight: '10px',
      marginLeft: '90px',
    },
  },
}))(Box);

class AuthorForm extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.submitButtonRef = React.createRef();
    // state element name should be same as the name of input elements in form
    this.state = {
      id: '',
      name: '',
      description: '',
      image: null,
      imagePreviewUrl: null,
      dragging: false,

      isRedirect: false,
    };
  }

  helpInfoData = getHelpInfoData('AuthorProfile');

  componentDidMount() {
    // if form is opened in edit mode
    if (this.props.location.state) {
      let author = this.props.location.state.object;
      let img = '';

      // get the image after /media/ because Django
      // automatically place /media/ before image path
      if (author.image) {
        let img_path = author.image.split('/media/');
        if (img_path.length > 1) img = img_path[1];
      }

      this.setState({
        id: author.id,
        name: author.name,
        description: author.description,
        image: img,
        imagePreviewUrl: author.image,
      });
    }
  }
  onBrowseClick = () => {
    this.fileInputRef.current.click();
  };
  handleFiles = (files, event) => {
    let reader = new FileReader();
    //this.handleImage;
    reader.onloadend = () => {
      this.setState({
        image: files[0],
        imagePreviewUrl: reader.result,
        dragging: false,
      });
    };
    reader.readAsDataURL(files[0]);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
        dragging: false,
      });
    };
    reader.readAsDataURL(file);
  };

  discardImage = () => {
    this.setState({ imagePreviewUrl: null, image: null });
  };
  createAuthor = (e) => {
    this.submitButtonRef.current.disabled = true;

    e.preventDefault();

    let formdata = new FormData();
    formdata.set('name', this.state.name);
    formdata.set('description', this.state.description);
    formdata.set('team', this.props.team.id);
    if (this.state.image && typeof this.state.image === 'object') {
      formdata.set('image', this.state.image);
    }

    let payload = {
      url: AUTHOR_PROFILE_API_URL,
      method: 'POST',
      data: formdata,
    };

    axios(payload).then(() => {
      this.setState({ isRedirect: true });
    });
  };

  editAuthor = (e) => {
    this.submitButtonRef.current.disabled = true;
    e.preventDefault();

    let formdata = new FormData();
    formdata.set('name', this.state.name);
    formdata.set('description', this.state.description);
    formdata.set('team', this.props.team.id);
    if (this.state.image && typeof this.state.image === 'object') {
      formdata.set('image', this.state.image);
    }

    let payload = {
      url: AUTHOR_PROFILE_API_URL + this.state.id + '/',
      method: 'PUT',
      data: formdata,
    };

    axios(payload).then(() => {
      this.setState({ isRedirect: true });
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    if (this.state.isRedirect) {
      return <Redirect to={MEM_MANGEMENT_ROUTE} />;
    }

    return (
      <>
        {/* // TODO: Remove the global style for materialize and bootstrap in future */}
        {globalStyles}
        <div>
          <NavBar />
          <form
            onKeyPress={this.onKeyPress}
            className='d-flex justify-content-center align-items-center'
            onSubmit={this.props.location.state ? this.editAuthor : this.createAuthor}
          >
            <AuthorPopup>
              <MarginBox
                style={{
                  marginTop: '40px',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '50px',
                    alignItems: 'center',
                  }}
                >
                  <Link to={MEM_MANGEMENT_ROUTE}>
                    <KeyboardBackspaceIcon
                      style={{
                        marginRight: '20px',
                        color: '#08A88E',
                        cursor: 'pointer',
                      }}
                    />
                  </Link>
                  <Typography
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '24px',
                      lineHeight: '33px',
                      color: '#474747',
                    }}
                  >
                    {this.props.location.state
                      ? `Update Featured Author`
                      : `Create Featured Author`}
                  </Typography>
                </Box>
              </MarginBox>
              <Divider
                style={{
                  marginTop: '30px',
                  marginLeft: '90px',
                  marginRight: '90px',
                }}
              />
              <DoubleMarginBox
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  style={{
                    width: '850px',
                    height: '300px',
                    marginTop: '80px',
                    marginRight: '30px',
                  }}
                >
                  <InputField
                    name='name'
                    required={true}
                    value={this.state.name}
                    onChange={this.onChange}
                    label={helpInfoData?.name?.label}
                    detail={helpInfoData?.name?.detail}
                    placeholder={helpInfoData?.name?.placeholder}
                    maxLength={helpInfoData?.name?.character_limit}
                  />

                  {helpInfoData['description'] && (
                    <Box style={{ marginTop: '40px' }}>
                      <FieldLabel
                        label={helpInfoData?.description?.label}
                        detail={helpInfoData?.description?.detail}
                      />
                      <Box style={{ maxWidth: '850px' }}>
                        <MemberInput
                          name='description'
                          type='textarea'
                          rows='8'
                          style={{
                            height: '250px',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                          }}
                          onChange={this.onChange}
                          value={this.state.description}
                          placeholder={helpInfoData['description'].placeholder}
                          maxLength={helpInfoData['description'].character_limit}
                        />
                        <Box style={{ marginTop: '5px' }}>
                          <FieldCharLimit
                            currentCount={this.state.description?.length}
                            totalCount={helpInfoData['description'].character_limit}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  style={{
                    marginTop: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {helpInfoData['image'] && (
                    <FormGroup>
                      <FieldLabel
                        label={helpInfoData?.image?.label}
                        detail={helpInfoData?.image?.detail}
                      />
                      <DragBox>
                        <FileDrop
                          targetClassName='file-drag-drop'
                          className='file-drag-drop'
                          onDragOver={() => {
                            this.setState({ dragging: true });
                          }}
                          onDragLeave={() => {
                            this.setState({ dragging: false });
                          }}
                          onDrop={this.handleFiles}
                        >
                          <Box
                            style={{
                              background: 'rgba(244, 246, 249, 0.15)',
                              border: this.state.dragging
                                ? '1px dashed #08A88E'
                                : '1px dashed #CCCCCC',
                              boxSizing: 'border-box',
                              borderRadius: '20px',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            {!this.state.imagePreviewUrl && (
                              <React.Fragment>
                                <GalleryIcon />
                                <Typography
                                  style={{
                                    marginTop: '30px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    textAlign: 'center',
                                    color: '#191919',
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  Drop your image here or{' '}
                                  <Box
                                    style={{
                                      color: '#08A88E',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      lineHeight: '16px',
                                      textAlign: 'center',
                                      marginLeft: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => this.onBrowseClick()}
                                  >
                                    Browse
                                  </Box>
                                </Typography>
                                <Typography
                                  style={{
                                    marginTop: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    textAlign: 'center',
                                    color: '#C6C6C6',
                                  }}
                                >
                                  Supports: JPG, PNG
                                </Typography>
                              </React.Fragment>
                            )}
                            {this.state.imagePreviewUrl && (
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: '50%',
                                    width: '140px',
                                    height: '140px',
                                  }}
                                  src={this.state.imagePreviewUrl}
                                  alt='preview broken'
                                />
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '40px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      lineHeight: '16px',
                                      textAlign: 'center',
                                      color: '#191919',
                                    }}
                                  >
                                    {this.state.image.name}
                                  </Typography>
                                  <HighlightOff
                                    style={{
                                      marginLeft: '8px',
                                      color: '#C7C7C7',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => this.discardImage()}
                                  />
                                </Box>
                              </Box>
                            )}
                            <input
                              style={{ display: 'none' }}
                              ref={this.fileInputRef}
                              type='file'
                              name='image'
                              onChange={(e) => this.handleImage(e)}
                              accept='image/*'
                            />
                          </Box>
                        </FileDrop>
                      </DragBox>
                    </FormGroup>
                  )}

                  <Button
                    style={{
                      fontSize: '20px',
                      width: '170px',
                      height: '62px',
                      textTransform: 'none',
                      color: 'white',
                      marginLeft: 'auto',
                      marginTop: '40px',
                      marginBottom: '40px',
                      background: '#08A88E',
                      boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
                      borderRadius: '100px',
                    }}
                    type='submit'
                    name='action'
                    ref={this.submitButtonRef}
                  >
                    {this.props.location.state ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </DoubleMarginBox>
            </AuthorPopup>
          </form>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.formState,
});

export default withTeam(connect(mapStateToProps, {})(AuthorForm));
