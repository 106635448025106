import { store } from 'store';
import XRegExp from 'xregexp';
import { MODULE_TYPES } from '../constants';

export const convertCodeToText = (condition) => {
  const unique_codes = store.getState().uniqueCodesState.uniqueCodes;

  let regex = {
    choice: /\bchoice_\w+_code\b/g,
    formula: /\bformula_\w+_code\b/g,
    infobox: /\binfobox_\w+_code\b/g,
    reference: /\breference_\w+_code\b/g,
    trigger: /\btrigger_\w+_code\b/g,
    variable: /\bvariable_\w+_code\b/g,
    numeric: /\bnumeric_\w+_code\b/g,
    custom_numeric: /\bcustomnumeric_\w+_code\b/g,
  };

  for (let key in regex) {
    let matches = [...condition.matchAll(regex[key])];
    // eslint-disable-next-line
    matches.map((match) => {
      if (
        match.hasOwnProperty('0') &&
        unique_codes.hasOwnProperty(key) &&
        unique_codes[key].hasOwnProperty(match['0'])
      ) {
        condition = condition.replace(match['0'], unique_codes[key][match['0']]);
      }
    });
  }

  return condition;
};

export const getInitials = (string, count) => {
  var names = string?.split(' '),
    initials = names[0]?.substring(0, 1).toUpperCase();

  if (names.length > 1 && count !== 1) {
    initials += names[names.length - 1]?.substring(0, 1).toUpperCase();
  }
  return initials;
};

const hashCode = (str: string): number => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
};

export const getRandomNumberFromHashCode = (name: string): number => {
  const hash = hashCode(name);
  const decimalNumber = Math.abs(hash);
  return decimalNumber;
};

export const convertTriggerCondition = (condition) => {
  condition = condition
    .replaceAll('== null', 'is not available')
    .replaceAll('!= null', 'is available')
    .replaceAll('== Not null', 'is available');

  // replace 1/0 to True/False of trigger and multi choice conditions only
  let regex = {
    trigger: /\btrigger_\w+_code (==|!=) \d\b/g,
    choice: /\bvariable_\w+_code\/choice_\w+_code (==|!=) \d\b/g,
  };

  for (let key in regex) {
    let matches = [...condition.matchAll(regex[key])];

    // eslint-disable-next-line
    matches.map((match) => {
      if (match.hasOwnProperty('0')) {
        let replacedValue = match[0]
          .replace('== 0', '= FALSE')
          .replace('== 1', '= TRUE')
          .replace('!= 1', '≠ TRUE')
          .replace('!= 0', '≠ FALSE');

        condition = condition.replace(match['0'], replacedValue);
      }
    });
  }

  // replace operators with human readable notations
  let condition_parts = condition.split(' ');
  let mapping = {
    '==': '=',
    '!=': '≠',
    '<=': '≤',
    '>=': '≧',
    '&&': 'AND',
    '||': 'OR',
  };

  for (let i = 0; i < condition_parts.length; i++) {
    if (mapping.hasOwnProperty(condition_parts[i])) {
      condition_parts[i] = condition_parts[i].replace(
        condition_parts[i],
        mapping[condition_parts[i]]
      );
    }
  }
  return condition_parts.join(' ');
};

export const getRepresentationPhrase = (
  text,
  character_limit,
  applyFiltering = false,
  word_limit = 4
) => {
  if (applyFiltering === true) {
    const blackList = [
      'what',
      'is',
      'the',
      'of',
      'if',
      'an',
      'a',
      'does',
      'do',
      'following',
      'there',
      'any',
      'have',
      'has',
      'with',
      'your',
      'his',
      'her',
      'was',
      'is',
      'based',
      'in',
      'patient',
      'patients',
    ];

    // regex to allow characters of all languages but to remove all special characters
    const regex = XRegExp('[^\\pL\\pN ]+', 'g');
    let words = XRegExp.replace(text, regex, '').split(' ');
    let filteredWords = words.filter((word) => word && blackList.indexOf(word.toLowerCase()) < 0);

    // pick only words given by wor_limit and their character limit should be equal to
    // the limit defined in db and form fields
    return filteredWords.slice(0, word_limit).join(' ').substring(0, character_limit);
  } else {
    return text.split(' ').slice(0, word_limit).join(' ').substring(0, character_limit);
  }
};

export const emailPattern: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const checkEmail = (email) => {
  return emailPattern.test(email);
};

export const generateID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const onlyAllowNumbers = (event) => {
  // Don't allow e, + and - signs in number field
  // 69 for e; 107/109 for +/- in Num Pad; 187/189 for +/- in alhpa numeric pad
  if (
    event.which === 69 ||
    event.which === 107 ||
    event.which === 109 ||
    event.which === 187 ||
    event.which === 189
  ) {
    event.preventDefault();
  }
};

export const isOnboardingModule = (moduleCode) => moduleCode === 'onboarding-module';

export const getCharNumOnly = (code: string) => code.replace(/[^a-zA-Z0-9]/g, '');

export const getReadableURL = (title: string) =>
  title
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-');

const addQueryParam = (url: string, paramName: string, paramValue: string | null | undefined) => {
  if (paramValue) {
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${paramName}=${paramValue}`;
  }
  return url;
};

export const addMirrorId = (url: string, type: string) => {
  let object = { mirrorId: null };
  switch (type) {
    case MODULE_TYPES.CALCULATOR:
      object = store.getState().teamCalculatorState?.calculator;
      break;
    case MODULE_TYPES.KNOWLEDGE_BASE:
      object = store.getState().knowledgeBaseState?.knowledgeBase;
      break;
    case MODULE_TYPES.ALGO:
      // TODO: need to improve
      const moduleId = window.location.pathname.split('/')[2];
      const modules = store.getState().modulesState?.modules;
      object = modules.find((module) => module.id === Number(moduleId));
      break;
    default:
      break;
  }

  return addQueryParam(url, 'mirror_id', object?.mirrorId);
};

export const isValidURL = (url: string) => {
  let pattern = RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.(~)+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~(+=)-]*)?' + // query string
      '(\\#[;&a-z\\d%_.~(+=)-]*)?',
    // '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return pattern.test(url);
};
