import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core/styles';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';

import infoCircleFill from '@iconify/icons-bi/info-circle-fill';
import questionCircleFill from '@iconify/icons-bi/question-circle-fill';
import calculatorIcon from '@iconify/icons-mdi/calculator';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from 'assets/icons/editIcon';
import { onSortEnd } from 'components/utils/ListItemUtil';
import { convertCodeToText } from 'utils/utilityFunctions';

const Items = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;
  /* identical to box height */
`;

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    color: '#000000',
    borderLeft: '0px solid transparent',
    borderBottom: '1px solid #D7D7D7',
    backgroundColor: 'white',
    boxShadow: 'none',
    zIndex: 2000,
    '&:hover': {
      color: '#08A88E',
    },
  },
  content: {
    paddingTop: 15,
    paddingBottom: '15px !important',
    paddingLeft: 15,
    paddingRight: 15,
  },
});

const SortableItem = SortableElement(({ item, toggleModal, deleteItem }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Items component='p' className=''>
          {item.type === 'calculator' && (
            <Icon style={{ verticalAlign: 'text-top', marginRight: '5px' }} icon={calculatorIcon} />
          )}
          {item.type === 'question' && (
            <Icon
              style={{ verticalAlign: 'text-top', marginRight: '5px' }}
              icon={questionCircleFill}
            />
          )}
          {item.type === 'infobox' && (
            <Icon style={{ verticalAlign: 'text-top', marginRight: '5px' }} icon={infoCircleFill} />
          )}

          {item.type === 'image' && <ImageIcon />}

          {item.infobox && (
            <Icon style={{ verticalAlign: 'text-top', marginRight: '5px' }} icon={infoCircleFill} />
          )}

          {item.display_value ||
            item.name ||
            item.statement ||
            item.representation_phrase ||
            item.shortened_title ||
            item.title ||
            convertCodeToText(item.code)}

          {toggleModal && item.type !== 'calculator' && (
            <EditIcon
              className='right ml-1 mr-1'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleModal(item);
              }}
              style={{
                backgroundColor: 'transparent',
                verticalAlign: 'sub',
                marginLeft: '0',
                color: ' #08A88E',
              }}
            />
          )}
          {toggleModal && item.type !== 'calculator' && (
            <Divider
              variant='middle'
              light
              orientation='vertical'
              style={{
                height: '21px',
                float: 'right',
                width: '1px',
                color: '#E6E6E6',
              }}
            />
          )}

          <DeleteIcon
            className=' right'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteItem(item);
            }}
            style={{
              backgroundColor: 'transparent',
              verticalAlign: 'sub',
              marginLeft: '0',
              color: ' red',
            }}
          />
        </Items>
      </CardContent>
    </Card>
  );
});

const SortableList = SortableContainer(({ items, toggleModal, deleteItem }) => {
  return (
    <div
      style={{
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '10px',
      }}
    >
      {items.map((item, index) => (
        <SortableItem
          key={index}
          index={index}
          item={item}
          toggleModal={toggleModal}
          deleteItem={deleteItem}
        />
      ))}
    </div>
  );
});

function ListItem(props) {
  return (
    <SortableList
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
      toggleModal={props.toggleModal}
      deleteItem={props.delete}
    />
  );
}

export default ListItem;
