const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#1E1F20',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1E1F20',
  },
  resetBtn: {
    marginLeft: 'auto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.25px',
    color: '#08A88E',
  },
}));
