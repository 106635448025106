import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { sprintf } from 'sprintf-js';
import { SortingMethod } from '../components/teams/teamSettingForm';
import {
  EHR_CONFIG_URL,
  LAST_SELECTED_TEAM_API_URL,
  LAST_SELECTED_TEAM_URL,
  SET_USER_TEAM_API_URL,
  TEAM_API_URL,
  TEAM_INVITATION_API_URL,
  TEAM_MATE_API_URL,
  TEAM_STAFF_JOIN_API,
  TEAM_URL,
} from '../constants';

export interface EHRConfig {
  id: number;
  title: string;
  unique_code: string;
  json_config: any;
  team: number;
}

export interface TeamMemberRoleRequest {
  id: number;
  role: (typeof ROLES)[keyof typeof ROLES];
}

export interface MemberInvitationRequest {
  role: (typeof ROLES)[keyof typeof ROLES];
  emails: string[];
  team: number;
}

export interface LinkInvitationRequest {
  team: number;
  sender: string;
  role: (typeof ROLES)[keyof typeof ROLES];
}

export interface InviteResponse {
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  id: number;
  invite_code: string;
  role: string;
  team: number;
  user_type: string;
}

export interface StaffJoinRequest {
  team: number;
  user?: number;
  role: (typeof ROLES)[keyof typeof ROLES];
  status: (typeof ROLES)[keyof typeof ROLES];
}

export interface TeamListItem {
  id: number;
  unique_code: string;
  name: string;
  display_name: string;
  is_team_member: boolean;
}

export interface TeamWithMates extends Team {
  teammates: TeamMate[];
}

interface Team {
  id: number;
  name: string;
  unique_code?: string;
  display_name: string;
  icon: string | null;
  icon_name?: string | null;
  team_type: string | null;
  current_teammate?: CurrentTeamMate;
  default_sorting: SortingMethod;
  ambient_listening_enabled: boolean;
  askavo_link_enabled: boolean;
}

interface CurrentTeamMate {
  id: number;
  role: (typeof ROLES)[keyof typeof ROLES];
}

export interface TeamMate {
  id: number;
  user: TeamMateUser;
  role: (typeof ROLES)[keyof typeof ROLES];
  role_display_value: string;
  status: string;
}

interface TeamMateUser {
  id: number;
  email: string;
  name: string;
}

export const ROLES = {
  Admin: 'A',
  Member: 'M',
} as const;

export const teamAPI = {
  setSelectedTeam: (teamId: number) =>
    axios.put(SET_USER_TEAM_API_URL, { team_id: teamId }).then((response) => response.data),
  getSelectedTeam: ({ queryKey }: QueryFunctionContext) =>
    axios.get<TeamWithMates>(LAST_SELECTED_TEAM_API_URL).then((response) => response.data),
  getTeam: ({ queryKey }: QueryFunctionContext) =>
    axios.get<TeamWithMates>(LAST_SELECTED_TEAM_URL).then((response) => response.data),
  getEHRConfig: ({ queryKey }: QueryFunctionContext) =>
    axios.get<EHRConfig>(`${EHR_CONFIG_URL}${queryKey[1]}/`).then((response) => response.data),
  getTeamEHRConfigs: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<EHRConfig[]>(sprintf(`${EHR_CONFIG_URL}?team_id=${queryKey[1]}`))
      .then((response) => response.data),
  postEHRConfig: (data) => axios.post(EHR_CONFIG_URL, data).then((response) => response.data),
  patchEHRConfig: (data, id) =>
    axios.patch(`${EHR_CONFIG_URL}${id}/`, data).then((response) => response.data),
  deleteEHRConfig: (id) => axios.delete(`${EHR_CONFIG_URL}${id}/`),
  getTeams: () => axios.get<TeamListItem[]>(TEAM_URL).then((response) => response.data),
  postTeam: (data) => axios.post(TEAM_API_URL, data).then((response) => response.data),
  putTeam: (data, teamId) =>
    axios.put(`${TEAM_API_URL}${teamId}/`, data).then((response) => response.data),
  deleteTeam: (teamId) =>
    axios.delete(`${TEAM_API_URL}${teamId}/`).then((response) => response.data),
  patchTeamMemberRole: (data: TeamMemberRoleRequest) =>
    axios.patch(`${TEAM_MATE_API_URL}${data.id}/`, data),
  deleteTeamMember: (memberId: number) => axios.delete(TEAM_MATE_API_URL + memberId + '/'),
  inviteMembers: (data: MemberInvitationRequest | LinkInvitationRequest) =>
    axios.post(TEAM_INVITATION_API_URL, data).then((response) => response.data as InviteResponse),
  postStaffJoin: (data: StaffJoinRequest) =>
    axios.post(TEAM_STAFF_JOIN_API, data).then((response) => response.data),
};
