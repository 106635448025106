function OrderedList(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='texteditor/numbered list'>
        <g id='Vector'>
          <path
            d='M4.51423 6.23032H3.39724V4.13831C3.39724 4.09973 3.39724 4.02932 3.39724 3.92708C3.39724 3.82292 3.39817 3.70814 3.40003 3.58275C3.40374 3.45737 3.40838 3.34452 3.41395 3.24421C3.36567 3.30401 3.32017 3.35513 3.27746 3.39757C3.23661 3.44001 3.19668 3.47955 3.15769 3.5162L2.69529 3.91262L2.12426 3.18345L3.52259 2H4.51423V6.23032Z'
            fill='#2E3438'
          />
          <path
            d='M5.01562 12.0741H2.06019V11.3218L3.05462 10.2772C3.26075 10.0534 3.42045 9.87404 3.53373 9.739C3.64887 9.60397 3.72965 9.4892 3.77607 9.39468C3.8225 9.30015 3.84571 9.20177 3.84571 9.09954C3.84571 8.97415 3.80671 8.88349 3.72872 8.82755C3.65072 8.77161 3.5588 8.74363 3.45295 8.74363C3.32482 8.74363 3.19483 8.78221 3.06298 8.85938C2.93299 8.93461 2.78536 9.04649 2.62008 9.19502L2.01562 8.46007C2.13819 8.34626 2.26818 8.23823 2.4056 8.136C2.54302 8.03183 2.7055 7.94695 2.89306 7.88137C3.08062 7.81578 3.30903 7.78299 3.5783 7.78299C3.85499 7.78299 4.09455 7.83314 4.29696 7.93345C4.50123 8.03376 4.65908 8.17168 4.7705 8.34722C4.88192 8.52083 4.93763 8.71856 4.93763 8.94039C4.93763 9.18538 4.89399 9.40432 4.80671 9.59722C4.72129 9.78819 4.59223 9.98013 4.41952 10.173C4.24682 10.364 4.03141 10.581 3.77329 10.8241L3.47524 11.1019V11.1308H5.01562V12.0741Z'
            fill='#2E3438'
          />
          <path
            d='M4.87913 14.6291C4.87913 14.8297 4.83735 15.0033 4.75379 15.1499C4.67208 15.2946 4.56251 15.4132 4.4251 15.5058C4.28953 15.5984 4.14097 15.6678 3.97941 15.7141V15.7315C4.30439 15.7739 4.55509 15.8771 4.7315 16.0411C4.90978 16.2031 4.99891 16.4336 4.99891 16.7326C4.99891 16.9641 4.93949 17.1763 4.82064 17.3692C4.70179 17.5602 4.51702 17.7135 4.26632 17.8293C4.01562 17.9431 3.69343 18 3.29975 18C3.03605 18 2.81135 17.9797 2.62565 17.9392C2.43995 17.8987 2.25982 17.8428 2.08526 17.7714V16.8223C2.26168 16.9169 2.44274 16.9873 2.62844 17.0336C2.816 17.0779 2.98684 17.1001 3.14097 17.1001C3.40838 17.1001 3.59037 17.0606 3.68693 16.9815C3.78536 16.9024 3.83457 16.7963 3.83457 16.6632C3.83457 16.5667 3.81135 16.4848 3.76493 16.4172C3.7185 16.3497 3.63215 16.2976 3.50588 16.261C3.38146 16.2243 3.20225 16.206 2.96827 16.206H2.71757V15.3466H2.97384C3.18183 15.3466 3.34617 15.3274 3.46688 15.2888C3.58944 15.2502 3.67765 15.1981 3.7315 15.1325C3.78536 15.065 3.81228 14.9878 3.81228 14.901C3.81228 14.7892 3.7705 14.7062 3.68693 14.6522C3.60337 14.5982 3.49288 14.5712 3.35546 14.5712C3.2329 14.5712 3.11776 14.5885 3.01005 14.6233C2.90235 14.658 2.80857 14.6966 2.72872 14.739C2.64887 14.7814 2.58851 14.8162 2.54766 14.8432L2.09083 14.0793C2.25982 13.9597 2.45295 13.8584 2.67022 13.7755C2.88935 13.6925 3.17533 13.651 3.52816 13.651C3.94784 13.651 4.27746 13.7378 4.51702 13.9115C4.75843 14.0851 4.87913 14.3243 4.87913 14.6291Z'
            fill='#2E3438'
          />
        </g>
        <rect
          id='Rectangle 3463334'
          x='7.01562'
          y='4'
          width='12'
          height='2'
          rx='0.2'
          fill='#2E3438'
        />
        <rect
          id='Rectangle 3463335'
          x='7.01562'
          y='9'
          width='12'
          height='2'
          rx='0.2'
          fill='#2E3438'
        />
        <rect
          id='Rectangle 3463336'
          x='7.01562'
          y='15'
          width='12'
          height='2'
          rx='0.2'
          fill='#2E3438'
        />
      </g>
    </svg>
  );
}

export default OrderedList;
