import ModuleForm from 'components/module-detail/OldModuleForm';
import Simulate from 'components/module-detail/Simulate';
import AiKnowledgeBase from '../../components/module-detail/knowledgebase/AiKnowledgeBase';
import {
  KB_CONTENT_ROUTE,
  KB_CREATE_ROUTE,
  KB_FLOW_ROUTE,
  KB_ROUTE,
  MODULE_TYPES,
} from '../../constants';

const AiKnowledgeBaseRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: KB_CREATE_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.KNOWLEDGE_BASE,
      },
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: KB_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.KNOWLEDGE_BASE,
      },
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: KB_FLOW_ROUTE,
      to: KB_CONTENT_ROUTE,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: KB_CONTENT_ROUTE,
      component: AiKnowledgeBase,
    },
  },

  {
    type: 'PUBLIC',
    props: {
      path: '/simulate',
      component: Simulate,
    },
  },
];

export default AiKnowledgeBaseRoutes;
