import { ROLES } from '../api/team';
import { MODULE_TYPES, Permissions } from '../constants';
import { TEAM_QUERY_KEY } from '../hooks/useTeam';
import { queryClient } from '../queryClient';

export const hasViewOnlyPermission = (type = '', isMirror = false) => {
  const moduleId = window.location.pathname.split('/')[2];
  const team = queryClient.getQueryData([TEAM_QUERY_KEY]);
  const moduleType =
    window.location.pathname.split('/')[1] === 'calculator' ? MODULE_TYPES.CALCULATOR : type;
  const object = queryClient.getQueryData([moduleType || 'algo', parseInt(moduleId)]);

  const permissionType = object?.current_user_role;
  const teamRole = team?.current_teammate?.role;
  const isTeamAdmin = teamRole === ROLES.Admin;

  const hasOnlyViewPermission =
    object?.isMirror ||
    (!isTeamAdmin &&
      (permissionType === Permissions.CAN_VIEW ||
        (!permissionType && !!teamRole && object?.permission_type === Permissions.CAN_VIEW)));

  return hasOnlyViewPermission;
};
