import InputLabel from '@material-ui/core/InputLabel';
import getTeamCalculators from 'actions/calculators/getTeamCalculators';
import knowledgeBaseListAction from 'actions/knowledge-base/getKnowledgeBase';
import getTeamMirrors from 'actions/mirrors/mirrorList';
import modulesListAction from 'actions/modules/modulesAction';
import axios from 'axios';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import {
  AI_KNOWLEDGE_BASE_API_URL,
  CALCULATOR_API_URL,
  MIRROR_API_URL,
  MODULE_API_URL,
  MODULE_TYPES,
} from '../../constants';
import { Authentication, withAuthentication } from '../../hooks/useAuthentication';
import { TeamListItem, TeamWithMates } from '../../api/team';
import { withTeam } from '../../hooks/useTeam';

interface TransferModuleTeamProps {
  moduleId: string;
  type: string;
  toggleModal: () => void;
  modulesListAction: () => void;
  getTeamMirrors: () => void;
  getTeamCalculators: () => void;
  knowledgeBaseListAction: () => void;
  authentication: Authentication;
  team: TeamWithMates;
  teamList: TeamListItem[];
}

interface TransferModuleTeamState {
  selectedValue: string;
  isButtonDisabled: boolean;
}

class TransferModuleTeam extends React.Component<TransferModuleTeamProps, TransferModuleTeamState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedValue: '',
      isButtonDisabled: false,
    };
  }

  changeTeam = (e: any) => {
    e.preventDefault();
    if (!this.state.selectedValue || this.state.selectedValue === '-----') {
      toast.error(CustomToast, { data: 'Please select a team' });
      this.setState({ isButtonDisabled: true });
      setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);
      return;
    }

    this.changeTeamInDB();
  };

  changeTeamInDB = () => {
    let API_URL;
    let refreshList;

    switch (this.props.type) {
      case MODULE_TYPES.MIRROR:
        API_URL = `${MIRROR_API_URL}${this.props.moduleId}/transfer_team/`;
        refreshList = this.props.getTeamMirrors;
        break;
      case MODULE_TYPES.CALCULATOR:
        API_URL = `${CALCULATOR_API_URL}${this.props.moduleId}/transfer_team`;
        refreshList = this.props.getTeamCalculators;
        break;
      case MODULE_TYPES.KNOWLEDGE_BASE:
        API_URL = `${AI_KNOWLEDGE_BASE_API_URL}${this.props.moduleId}/transfer_team/`;
        refreshList = this.props.knowledgeBaseListAction;
        break;
      case MODULE_TYPES.ALGO:
        API_URL = `${MODULE_API_URL}${this.props.moduleId}/transfer_team`;
        refreshList = this.props.modulesListAction;
      default:
        break;
    }

    axios
      .put(API_URL, {
        team: this.state.selectedValue,
      })
      .then(() => {
        this.props.toggleModal();
        refreshList();
        toast.success(CustomToast, { data: 'Team changed successfully!' });
      });
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ selectedValue: e.target.value });

  render() {
    const teams = this.props.teamList;
    const filteredTeams = teams.filter((team) => team.id !== this.props.team.id);

    return (
      <>
        {/* TODO: Remove the global style for materialize and bootstrap in future */}
        {globalStyles}
        <Form onSubmit={this.changeTeam}>
          <FormGroup style={{ marginBottom: 50 }}>
            <InputLabel>Select New Team</InputLabel>
            <Input type='select' value={this.state.selectedValue} onChange={this.onChange}>
              <option>-----</option>
              {filteredTeams.map((team: any) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <CreateButton style={{ width: 250 }} type='submit' disabled={this.state.isButtonDisabled}>
            Change Team
          </CreateButton>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default withTeam(
  withAuthentication(
    connect(mapStateToProps, {
      modulesListAction,
      getTeamCalculators,
      knowledgeBaseListAction,
      getTeamMirrors,
    })(TransferModuleTeam)
  )
);
