import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.JSX.Element;
}

export const Button = ({ className, children, icon, ...props }: ButtonProps) => {
  return (
    <button className={clsx('button', className)} {...props}>
      {icon && <span className='mr-[2px] h-5 w-5'>{icon}</span>}
      {children}
    </button>
  );
};
