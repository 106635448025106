import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import ArrowIcon from 'assets/icons/dropdownArrowIcon';
import HighlightIcon from 'assets/icons/highlightIcon';
import IfIcon from 'assets/icons/ifIcon';
import PhoneIcon from 'assets/icons/phoneIcon';
import ToolbarInfoboxIcon from 'assets/icons/toolbarInfoboxIcon';
import axios from 'axios';
import ConditionalTextForm from 'components/resources/conditional-texts/form';
import ImageForm from 'components/resources/image-Infobox/imageForm';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import { TextStyleMenu } from 'components/utils/draftJS/menus/text-style/TextStyleMenu';
import { UPLOAD_IMAGE_URL } from 'constants';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import LinkIcon from '../../../assets/icons/linkSVG';
import PicIcon from '../../../assets/icons/pictureIcon';
import InfoboxDropdown from '../InfoboxDropdown';
import Dropdown from '../dropdown';
import CustomModal from '../modals/CustomModal';
import {
  PhoneNoConfirmButton,
  StyledInput,
  ToolInfoboxButton,
  ToolMediaButton,
} from '../styled-components/FormStyle';
import {
  DividerAfterHighlight,
  DividerAfterNumberedList,
  DividerAfterTextStyle,
} from './ToolbarButtonsDivider';
import InsertLink from './insert-link';
import ConditionalTextMenu from './menus/conditional-text';
import { HeadingButton, StyledTooltip, toolbarStyle } from './styles';
import { ToolbarButton, include } from './utils';
import VariableButton from './variableOptions';

class ToolbarButtons extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.fileInputRef = React.createRef();
  }

  defaultState = () => {
    return {
      infoDropdownEl: null,
      conditionalTextEl: null,
      imageAnchor: null,
      textStyleAnchorEl: null,
      linkButtonClicked: false,
      phoneNoModal: false,
      createCTModal: false,
      editCTModal: false,
      headerBtnTitle: 'Normal Text',
      phoneNo: '',
      searchInput: '',
      text: '',
      selectedItem: '',
      isInfoMedia: false,
      createInfoboxModal: false,
      createMediaModal: false,
      positionModified: false,
      editInfoboxModal: false,
      editMediaModal: false,
      image: null,
    };
  };

  toggleModal = (modalName) => {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
    }));
  };

  toggleInfoboxCreateModal = () => {
    this.setState((previous) => ({
      createInfoboxModal: !previous.createInfoboxModal,
      infoDropdownEl: null,
    }));
  };

  toggleCTCreateModal = () => {
    this.setState((previous) => ({
      createCTModal: !previous.createCTModal,
      conditionalTextEl: null,
    }));
    this.props.setIsFormDisabled(!this.props.isFormDisabled);
  };

  toggleMediaEditModal = (id) => {
    this.setState((previous) => ({
      editMediaModal: !previous.editMediaModal,
      selectedItem: id,
      infoDropdownEl: null,
    }));
  };

  toggleInfoEditModal = (id) => {
    this.setState((previous) => ({
      editInfoboxModal: !previous.editInfoboxModal,
      selectedItem: id,
      infoDropdownEl: null,
    }));
  };

  toggleCTEditModal = (id) => {
    this.setState((previous) => ({
      editCTModal: !previous.editCTModal,
      selectedItem: id,
      conditionalTextEl: null,
    }));
    this.props.setIsFormDisabled(!this.props.isFormDisabled);
  };

  setPhoneNo = (e) => {
    // only allow numbers and dashes
    let regex = RegExp(/^(?=.*[0-9])[-0-9]+$/);
    if (regex.test(e.target.value)) {
      this.setState({ phoneNo: e.target.value });
    }
  };

  handleInfoboxClick = (e) => {
    if (this.state.infoDropdownEl === null) {
      this.setState({ infoDropdownEl: e.target, text: this.getSelectedText() });
    } else {
      this.setState({ infoDropdownEl: null, text: this.getSelectedText() });
    }
  };

  handleConditionalTextClick = (e) => {
    e.preventDefault();
    this.setState({ conditionalTextEl: e.target });
  };

  handleClick = (e, variableName) => this.setState({ [variableName]: e.target });

  closeMenu = () => {
    this.setState({
      selectedItem: '',
      text: '',
      infoDropdownEl: null,
      conditionalTextEl: null,
      editCTModal: false,
      editInfoboxModal: false,
      createInfoboxModal: false,
      createMediaModal: false,
      createCTModal: false,
    });
  };

  addItem = (obj) => {
    this.props.addMention(obj);
    this.setState(this.defaultState());
  };

  addNewItem = (obj) => {
    this.props.addMention({ name: obj.name || obj.title, code: obj.unique_code });
    this.setState(this.defaultState());
  };

  insertLink = (text, link, type, id) => {
    if (type === 'module' || type === 'calculator') {
      this.props.addMention({
        code: id,
        name: text,
        type: type,
      });
    } else {
      this.props.addMention({
        code: link,
        name: text,
        type: type,
      });
    }
    this.setState(this.defaultState());
  };

  getSelectedText = () => {
    const selectionState = this.props.editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = this.props.editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    return selectedText;
  };

  applyTextStyle = (e) => {
    const btnText = e.target.innerText.trim();
    const currentStyle = this.props.editorState.getCurrentInlineStyle();

    this.setState({ headerBtnTitle: btnText, textStyleAnchorEl: null }, () => {
      if (btnText === 'Heading' && !currentStyle.has('HEADER')) {
        this.props.applyHeader(e);
      } else if (btnText === 'Normal Text' && currentStyle.has('HEADER')) {
        this.props.applyHeader(e);
      }
    });
  };

  onBrowseClick = () => {
    this.fileInputRef.current.click();
  };

  handleImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState(
        {
          image: file,
        },
        () => {
          this.uploadImage();
        }
      );
    };
    reader.readAsDataURL(file);
  };

  getMeta = (url, cb) => {
    const img = new Image();
    console.log('getmeta func');
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  uploadImage = async () => {
    let formdata = new FormData();

    formdata.append('image', this.state.image);
    try {
      let width = 0,
        height = 0;
      console.log('calling api');
      const response = await axios.post(UPLOAD_IMAGE_URL, formdata);
      console.log({ response });
      this.getMeta(response.data.image, (err, img) => {
        width = img.naturalWidth;
        height = img.naturalHeight;
        console.log('getmeta cb');
        this.props.onChange(
          this.props.imageModifier(this.props.editorState, response.data.image, {
            pocWidth: width,
            pocHeight: height,
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
    const isMAC = platform.toUpperCase().indexOf('MAC') >= 0;

    const {
      addMention,
      conditionalText,
      draftJsToolbarProps,
      editorState,
      highlightText,
      isIntroductionCard,
      isReadOnly,
      infoboxes,
      media,
      numerics,
      variables,
      knowledgeBases,
      gptBox,
      isTextInput,
      richTextButtonShowList,
    } = this.props;

    const variableToolbarStyle = {
      justifyContent: conditionalText || isIntroductionCard ? 'start' : 'flex-start',
    };

    return (
      <div style={{ ...toolbarStyle, ...variableToolbarStyle }}>
        <>
          {include(richTextButtonShowList, ToolbarButton.TEXT_STYLE) && (
            <StyledTooltip title='Text style' placement='top'>
              <span>
                <HeadingButton
                  type='button'
                  endIcon={<ArrowIcon />}
                  disabled={isReadOnly}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.textStyleAnchorEl === null) {
                      this.setState({ textStyleAnchorEl: e.target });
                    } else {
                      this.setState({ textStyleAnchorEl: null });
                    }
                  }}
                  style={{ background: this.state.textStyleAnchorEl && '#E5EDF0' }}
                >
                  {this.state.headerBtnTitle}
                </HeadingButton>
              </span>
            </StyledTooltip>
          )}
          <DividerAfterTextStyle richTextButtonShowList={richTextButtonShowList} />
          {include(richTextButtonShowList, ToolbarButton.BOLD) && (
            <StyledTooltip
              placement='top'
              title={
                isMAC ? (
                  <p>
                    {' '}
                    Bold <span style={{ color: '#C5D1D8' }}> ⌘+B </span>{' '}
                  </p>
                ) : (
                  <p>
                    {' '}
                    Bold <span style={{ color: '#C5D1D8' }}> CTRL+B </span>{' '}
                  </p>
                )
              }
            >
              <span>
                <IconButton disabled={isReadOnly} style={{ width: 24, height: 24, marginRight: 8 }}>
                  <BoldButton {...draftJsToolbarProps} />
                </IconButton>
              </span>
            </StyledTooltip>
          )}
          {include(richTextButtonShowList, ToolbarButton.ITALIC) && (
            <StyledTooltip
              placement='top'
              title={
                isMAC ? (
                  <p>
                    {' '}
                    Italic <span style={{ color: '#C5D1D8' }}> ⌘+I </span>{' '}
                  </p>
                ) : (
                  <p>
                    {' '}
                    Italic <span style={{ color: '#C5D1D8' }}> CTRL+I </span>{' '}
                  </p>
                )
              }
            >
              <span>
                <IconButton disabled={isReadOnly} style={{ width: 24, height: 24 }}>
                  <ItalicButton {...draftJsToolbarProps} />
                </IconButton>
              </span>
            </StyledTooltip>
          )}
          {include(richTextButtonShowList, ToolbarButton.HIGHLIGHT) && (
            <StyledTooltip title='Highlight' placement='top'>
              <span>
                <Button
                  type='button'
                  disabled={isReadOnly}
                  className='inlineStyle-btn'
                  style={{
                    backgroundColor: editorState.getCurrentInlineStyle().has('HIGHLIGHT')
                      ? '#efefef'
                      : 'transparent',
                    borderRadius: '0px',
                  }}
                  onMouseDown={highlightText}
                >
                  <HighlightIcon />
                </Button>
              </span>
            </StyledTooltip>
          )}
          <DividerAfterHighlight richTextButtonShowList={richTextButtonShowList} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {include(richTextButtonShowList, ToolbarButton.BULLETED_LIST) && (
              <StyledTooltip title='Bulleted List' placement='top'>
                <span>
                  <IconButton
                    disabled={isReadOnly}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                  >
                    <UnorderedListButton {...draftJsToolbarProps} />
                  </IconButton>
                </span>
              </StyledTooltip>
            )}
            {include(richTextButtonShowList, ToolbarButton.NUMBERED_LIST) && (
              <StyledTooltip title='Numbered List' placement='top'>
                <span>
                  <IconButton disabled={isReadOnly} style={{ width: 24, height: 24 }}>
                    <OrderedListButton {...draftJsToolbarProps} />
                  </IconButton>
                </span>
              </StyledTooltip>
            )}
            <DividerAfterNumberedList richTextButtonShowList={richTextButtonShowList} />
          </div>
          {include(richTextButtonShowList, ToolbarButton.INFOBOX) && (
            <StyledTooltip title='Add Infobox' placement='top'>
              <span>
                <ToolInfoboxButton
                  type='button'
                  disabled={isReadOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleInfoboxClick(e);
                  }}
                >
                  <ToolbarInfoboxIcon />
                </ToolInfoboxButton>
              </span>
            </StyledTooltip>
          )}
          {include(richTextButtonShowList, ToolbarButton.INSERT_LINK) && (
            <StyledTooltip title='Insert Link' placement='top'>
              <div
                onClick={(e) => this.handleClick(e, 'linkButtonClicked')}
                style={{ cursor: 'pointer' }}
              >
                <LinkIcon />
              </div>
            </StyledTooltip>
          )}
          {include(richTextButtonShowList, ToolbarButton.MEDIA) && (
            <StyledTooltip title='Add Media' placement='top'>
              <span>
                <ToolMediaButton disabled={isReadOnly} onClick={this.onBrowseClick}>
                  <PicIcon />
                </ToolMediaButton>
              </span>
            </StyledTooltip>
          )}
          <Dropdown
            items={this.props.mediaState.images}
            dropdownAnchorEl={this.state.imageAnchor}
            setDropdownAnchorEl={() => this.setState({ imageAnchor: null })}
            performAction={(media) => {
              this.props.onChange(this.props.imageModifier(this.props.editorState, media.image));
              this.setState({ imageAnchor: null });
            }}
          />
          {include(richTextButtonShowList, ToolbarButton.PHONE_NUMBER) && (
            <StyledTooltip title='Add Phone Number' placement='top'>
              <span>
                <Button
                  type='button'
                  className='inlineStyle-btn'
                  disabled={isReadOnly}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    this.toggleModal('phoneNoModal');
                  }}
                >
                  <PhoneIcon />
                </Button>
              </span>
            </StyledTooltip>
          )}
          <input
            style={{ display: 'none' }}
            ref={this.fileInputRef}
            type='file'
            name='image'
            onChange={(e) => this.handleImage(e)}
            accept='image/*'
          />{' '}
        </>
        {include(richTextButtonShowList, ToolbarButton.CONDITIONAL_TEXT) && (
          <StyledTooltip title='Add Conditional Text' placement='top'>
            <span>
              <Button
                type='button'
                disabled={isReadOnly}
                className='inlineStyle-btn'
                onMouseDown={this.handleConditionalTextClick}
              >
                <IfIcon />
              </Button>
            </span>
          </StyledTooltip>
        )}

        {include(richTextButtonShowList, ToolbarButton.VARIABLES) && (
          <VariableButton
            hasEHRVariables={this.props.hasEHRVariables ?? true}
            conditionalText={this.props.conditionalText}
            isDisabled={isReadOnly}
            variables={variables}
            numerics={numerics}
            addMention={addMention}
            knowledgeBases={knowledgeBases}
            gptBox={gptBox}
            textInput={isTextInput}
          />
        )}

        <InfoboxDropdown
          dropDownEl={this.state.infoDropdownEl}
          closeMenu={this.closeMenu}
          searchInput={this.state.searchInput}
          setSearchInput={(searchInput) => this.setState({ searchInput })}
          selectedItem={this.state.selectedItem}
          setSelectedItem={(selectedItem) => this.setState({ selectedItem })}
          text={this.state.text}
          setText={(text) => this.setState({ text })}
          items={[...infoboxes, ...media]}
          addItem={this.addItem}
          isInfoMedia={true}
          toggleCreateModal={this.toggleInfoboxCreateModal}
          toggleEditInfoboxModal={this.toggleInfoEditModal}
          toggleEditMediaModal={this.toggleMediaEditModal}
        />

        {this.state.linkButtonClicked && (
          <InsertLink
            popperAnchor={this.state.linkButtonClicked}
            handlePopperClose={() => this.setState({ linkButtonClicked: false })}
            insertLink={this.insertLink}
          />
        )}

        <ConditionalTextMenu
          dropDownEl={this.state.conditionalTextEl}
          closeMenu={() => this.setState({ conditionalTextEl: null })}
          selectedItem={this.state.selectedItem}
          addItem={this.addItem}
          toggleCreateModal={this.toggleCTCreateModal}
          toggleEditModal={this.toggleCTEditModal}
        />

        {this.state.textStyleAnchorEl && (
          <TextStyleMenu
            anchorEl={this.state.textStyleAnchorEl}
            close={() => this.setState({ textStyleAnchorEl: null })}
            applyTextStyle={this.applyTextStyle}
          ></TextStyleMenu>
        )}

        <CustomModal
          modal={this.state.phoneNoModal}
          toggleModal={() => this.toggleModal('phoneNoModal')}
          modalHeader='Enter Phone Number'
        >
          <StyledInput
            placeholder='Phone Number'
            autoFocus={true}
            value={this.state.phoneNo}
            style={{ width: 305, height: 46 }}
            onChange={this.setPhoneNo}
          />
          <PhoneNoConfirmButton
            onMouseDown={() => {
              this.setState({ phoneNoModal: false });
              addMention({ code: 'phoneNumber', name: this.state.phoneNo });
            }}
          >
            Confirm
          </PhoneNoConfirmButton>
        </CustomModal>

        {/* Sliding Pane for Create Infobox*/}
        <SlidingPane
          isOpen={this.state.createInfoboxModal}
          onRequestClose={this.toggleInfoboxCreateModal}
          overlayClassName={
            /* 
            The ConditionalText props inside ToolbarButtons help identify 
            whether it's called from the message panel or from inside the 
            conditional text form, assisting in setting the index on opening
            up any sliding panel like the infobox from the 
            conditional text or from a normal panel.
            */
            conditionalText ? 'conditional-text-overlay-for-zindex' : 'infobox-overlay-for-zindex'
          }
          from='right'
          className='no-padding add-info-box sliding-panel-shadow'
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              selectedText={this.getSelectedText()}
              conditionalText={conditionalText}
              isFullHeight={true}
              createModal={true}
              type={this.props.type}
              toggleModal={this.toggleInfoboxCreateModal}
              selectValue={this.addNewItem}
              // no need to call save position as we didn't modify the position of
              // infobox in flow section while editing panel. savePosition returns a
              // array of promises. That's why returning empty array here.
              savePosition={() => []}
              populateSuggestions={this.populateSuggestions}
            />
          </div>
        </SlidingPane>

        {/* Sliding Pane for Edit Infobox */}
        <SlidingPane
          isOpen={this.state.editInfoboxModal}
          onRequestClose={this.toggleConfirmationModal}
          from='right'
          // hideHeader
          overlayClassName={
            /* 
            The ConditionalText props inside ToolbarButtons help identify 
            whether it's called from the message panel or from inside the 
            conditional text form, assisting in setting the index on opening
            up any sliding panel like the infobox from the 
            conditional text or from a normal panel.
            */
            conditionalText ? 'conditional-text-overlay-for-zindex' : 'infobox-overlay-for-zindex'
          }
          className='no-padding sliding-panel-shadow'
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              conditionalText={conditionalText}
              type={this.props.type}
              toggleModal={this.toggleInfoEditModal}
              infoBoxId={this.state.selectedItem}
              savePosition={() => []}
              isFullHeight={true}
            />
          </div>
        </SlidingPane>

        {/* Sliding Pane for Edit Media */}
        <SlidingPane
          isOpen={this.state.editMediaModal}
          onRequestClose={this.toggleMediaEditModal}
          from='right'
          className='no-padding sliding-panel-shadow'
          width='622px'
          hideHeader
        >
          <div className='mt-14'>
            <ImageForm
              toggleModal={() => this.toggleMediaEditModal(this.state.selectedItem)}
              type={this.props.type}
              imageId={this.state.selectedItem}
              savePosition={() => []}
              isFullHeight={true}
            />
          </div>
        </SlidingPane>

        {/* Sliding pane for Creating new conditional text */}
        <SlidingPane
          isOpen={this.state.createCTModal}
          onRequestClose={this.toggleCTCreateModal}
          from='bottom right'
          className='no-padding sliding-panel-shadow conditional-text-popup-infobox'
          overlayClassName='sliding-panel-cards conditional-text-popup-overlay conditional-text-overlay-for-zindex'
          width='627px'
          hideHeader
        >
          <div className='mt-14'>
            <ConditionalTextForm
              toggleModal={this.toggleCTCreateModal}
              addItem={this.addNewItem}
              savePosition={() => []}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.editCTModal}
          onRequestClose={this.toggleCTEditModal}
          from='bottom right'
          className='no-padding sliding-panel-shadow conditional-text-popup-infobox'
          overlayClassName='sliding-panel-cards conditional-text-popup-overlay conditional-text-overlay-for-zindex'
          width='627px'
          hideHeader
        >
          <div className='mt-14'>
            <ConditionalTextForm
              conditionalTextId={this.state.selectedItem}
              toggleModal={() => this.toggleCTEditModal(this.state.selectedItem)}
              savePosition={() => []}
            />
          </div>
        </SlidingPane>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mirrorState: state.mirrorState,
  mediaState: state.mediaState,
  isFormDisabled: state.formState.isFormDisabled,
});

ToolbarButtons.propTypes = {
  hasEHRVariables: PropTypes.bool,
  fromAiKnowledgeBase: PropTypes.bool,
  isTextInput: PropTypes.bool,
  gptBox: PropTypes.bool,
  addMention: PropTypes.func.isRequired,
  addConditionalText: PropTypes.func.isRequired,
  applyHeader: PropTypes.func.isRequired,
  conditionalText: PropTypes.bool,
  draftJsToolbarProps: PropTypes.object,
  editorState: PropTypes.object.isRequired,
  highlightText: PropTypes.func.isRequired,
  isIntroductionCard: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  infoboxes: PropTypes.array,
  media: PropTypes.array,
  conditionalTexts: PropTypes.array,
  numerics: PropTypes.array,
  type: PropTypes.string,
  variables: PropTypes.array,
  knowledgeBases: PropTypes.array,
  onExtractData: PropTypes.func.isRequired,
  pasteData: PropTypes.func.isRequired,
  imageModifier: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  richTextButtonShowList: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps, { setIsFormDisabled })(ToolbarButtons);
