import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { MouseEvent, ReactNode } from 'react';
import { useStyles } from './buttonStyles';

interface CustomButtonProps {
  text: string;
  variant?: 'contained' | 'outlined' | 'text';
  color?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  type?: string;
  children?: ReactNode;
}
const CustomButton: React.FC<CustomButtonProps & ButtonProps> = ({
  text,
  variant = 'contained',
  color,
  size = 'medium',
  onClick,
  className,
  style,
  disabled,
  type,
  ...rest
}) => {
  const classes = useStyles();

  let buttonClassName = `${classes.button} ${classes[variant]}`;

  if (size === 'small') {
    buttonClassName = `${buttonClassName} ${classes.small}`;
  }

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      className={`${buttonClassName} ${className}`}
      style={style}
      type={type}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
