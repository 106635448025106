import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import axios from 'axios';
import AnswerCardForm from 'components/answer-board/answerCardForm';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { getSuggestions } from 'utils/suggestions';
import { ANSWER_PAGE_API_URL } from '../../constants';
import AnswerCard from './AnswerCard';
import ChoiceAnswerCard from './ChoiceAnswerCard';
import MediaCard from './MediaCard';
import ChoiceAnswerForm from './choiceCardForm';
import Inline from './inlineTextCard';
import MediaCardForm from './mediaCardForm';

interface AnswerPageProps {
  answerPage: any;
  resetAnswerPage: Function;
  moduleId: any;
  toggleModal: Function;
  provided: any;
}
interface AnswerPageState {
  deleteModal: boolean;
  inlineVisible: boolean;
  createChoiceCard: boolean;
  createMediaCard: boolean;
  createTextCard: boolean;
  open: boolean;
  anchorEl: any;
  closeChoiceForm: boolean;
  textEditorsuggestions: any[];
}

class AnswerPage extends Component<AnswerPageProps, AnswerPageState> {
  anchorRef: HTMLElement | null = null;
  constructor(props: AnswerPageProps) {
    super(props);

    this.state = {
      deleteModal: false,
      inlineVisible: false,
      createChoiceCard: false,
      createMediaCard: false,
      createTextCard: false,
      open: false,
      anchorEl: null,
      closeChoiceForm: false,
      textEditorsuggestions: [],
    };

    this.anchorRef = null;
  }

  handleMenuClose = (event: any) => {
    if (this.anchorRef && this.anchorRef.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleChoiceClick = () => {
    this.setState((prevState) => ({
      createChoiceCard: !prevState.createChoiceCard,
    }));
  };

  handleMediaClick = () => {
    this.setState((prevState) => ({
      createMediaCard: !prevState.createMediaCard,
    }));
  };

  handleTextClick = () => {
    this.setState((prevState) => ({
      createTextCard: !prevState.createTextCard,
    }));
  };

  // for open/close delete page modal
  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  deleteAnswerPage = () => {
    axios.delete(ANSWER_PAGE_API_URL + '/' + this.props.answerPage.id + '/').then(() => {
      this.props.resetAnswerPage();
    });
  };

  populateSuggestions = async () => {
    const suggestions = await getSuggestions(this.props.moduleId);
    this.setState({ textEditorsuggestions: suggestions });
  };

  // for open/close answer card create modal
  showInline = () => {
    this.populateSuggestions();
    this.setState((prevState) => ({
      inlineVisible: !prevState.inlineVisible,
    }));
  };

  // for open/close answer card create modal
  hideInline = () => {
    this.setState({
      inlineVisible: false,
    });
  };

  handleToggle = () => {
    this.setState((previous) => ({
      open: !previous.open,
    }));
  };

  closeChoiceFormPane = () => {
    this.setState((previous) => ({
      closeChoiceForm: !previous.closeChoiceForm,
    }));
  };

  closeModals = () => {
    if (this.state.createChoiceCard === true) this.handleChoiceClick();
    else if (this.state.createMediaCard === true) this.handleMediaClick();

    this.closeChoiceFormPane();
  };

  render() {
    // Props defined explicitly as files in JS are not accepting props without the spread operator.
    const inLineProps = {
      answerPage: this.props.answerPage.id,
      textEditorsuggestions: this.state.textEditorsuggestions,
      moduleId: this.props.moduleId,
      resetAnsCards: this.props.resetAnswerPage,
      isVisible: this.state.inlineVisible,
      hideInline: this.hideInline,
    };

    const choiceFormProps = {
      answerPageId: this.props.answerPage.id,
      ansCardId: '',
      moduleId: this.props.moduleId,
      resetAnsCards: this.props.resetAnswerPage,
      toggleModal: this.handleChoiceClick,
    };
    const mediaCardFormProps = {
      answerPageId: this.props.answerPage.id,
      mediaCardId: '',
      moduleId: this.props.moduleId,
      resetAnsCards: this.props.resetAnswerPage,
      toggleModal: this.handleMediaClick,
    };

    let answerCards: React.ReactNode[] = [];
    let answerCardItems = this.props.answerPage.answerCards;

    for (let i in answerCardItems) {
      if ('resourcetype' in answerCardItems[i] && answerCardItems[i].resourcetype === 'TextCard') {
        if (answerCardItems[i].card_type === 'T') {
          answerCards.push(
            <div className='answer'>
              <AnswerCard
                resetAnswerPage={this.props.resetAnswerPage}
                moduleId={this.props.moduleId}
                answerCard={answerCardItems[i]}
              />
            </div>
          );
        } else if (answerCardItems[i].card_type === 'M') {
          answerCards.push(
            <div className='answer'>
              <MediaCard
                resetAnswerPage={this.props.resetAnswerPage}
                moduleId={this.props.moduleId}
                mediaCard={answerCardItems[i]}
              />
            </div>
          );
        }
      } else if (
        'resourcetype' in answerCardItems[i] &&
        answerCardItems[i].resourcetype === 'ChoiceCard'
      ) {
        answerCards.push(
          <div className='answer'>
            <ChoiceAnswerCard
              resetAnswerPage={this.props.resetAnswerPage}
              moduleId={this.props.moduleId}
              answerCard={answerCardItems[i]}
            />
          </div>
        );
      }
    }

    return (
      <div>
        <a className=' btn-small trigger-title'>
          {this.props.answerPage.triggerDetail
            ? [
                this.props.answerPage.triggerDetail.title
                  ? this.props.answerPage.triggerDetail.title === 'Always On'
                    ? 'With the Current Page'
                    : this.props.answerPage.triggerDetail.title
                  : 'Not Assigned',
              ]
            : ''}
        </a>
        <div
          className='container-card col-md-12'
          style={
            {
              padding: '8px',
              borderRadius: '25px',
              backgroundColor: this.props.answerPage.status === '0' && 'red',
            } as React.CSSProperties
          }
        >
          <div className='card-content text-justify'>
            <h4 className='flex items-center pl-2 pt-3 font-bold'>
              {this.props.answerPage.title}
              {!hasViewOnlyPermission() && (
                <div className='ml-auto'>
                  <i
                    className='material-icons'
                    onClick={() => this.props.toggleModal(this.props.answerPage.id)}
                  >
                    edit
                  </i>
                  <button className='util-icons' onClick={this.toggleDeleteModal}>
                    <i className='material-icons'>delete</i>
                  </button>
                </div>
              )}
            </h4>
            {/* <button className="util-icons right" onClick={this.showInline}><i className="material-icons">add_circle</i></button> */}
            <br /> <br />
            {answerCardItems.map((answerCard, index) => {
              return (
                <Draggable
                  key={answerCard.id}
                  draggableId={answerCard.id.toString()}
                  index={index}
                  isDragDisabled={hasViewOnlyPermission()}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {answerCards[index]}
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {this.props.provided.placeholder}
            {!hasViewOnlyPermission() && (
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{ color: '#08A88E', fontSize: '13px' }}
                  onClick={this.showInline}
                  //type='button'
                >
                  Add Text
                </Button>

                <Button
                  ref={(inputRef) => {
                    this.anchorRef = inputRef;
                  }}
                  style={{
                    color: '#08A88E',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 20,
                    padding: '6px 8px',
                    fontSize: '13px',
                  }}
                  size='small'
                  aria-controls={this.state.open ? 'split-button-menu' : undefined}
                  aria-expanded={this.state.open ? 'true' : undefined}
                  aria-label='select merge strategy'
                  aria-haspopup='menu'
                  onClick={this.handleToggle}
                >
                  Add Other
                  <ArrowDropUpIcon style={{ marginBottom: 2 }} />
                </Button>

                <Popper
                  open={this.state.open}
                  anchorEl={this.anchorRef}
                  style={{ zIndex: '11' }}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleMenuClose}>
                          <MenuList
                            autoFocus
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            id='split-button-menu'
                          >
                            <MenuItem
                              key={2}
                              onClick={() => {
                                this.handleTextClick();
                                this.handleToggle();
                              }}
                            >
                              Text Card
                            </MenuItem>
                            <MenuItem
                              key={1}
                              onClick={() => {
                                this.handleChoiceClick();
                                this.handleToggle();
                              }}
                            >
                              Choice Card
                            </MenuItem>
                            <MenuItem
                              key={2}
                              onClick={() => {
                                this.handleMediaClick();
                                this.handleToggle();
                              }}
                            >
                              Media Card
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
            <Inline {...inLineProps} />
            <ConfirmModal
              preset='unsaved'
              open={this.state.closeChoiceForm}
              toggleModal={this.closeChoiceFormPane}
              performAction={this.closeModals}
            />
            {/* Modal for Text Card Form */}
            <SlidingPane
              isOpen={this.state.createTextCard}
              onRequestClose={this.handleTextClick}
              from='right'
              overlayClassName='light-overlay'
              width='620px'
              title={<div>Create Answer Card</div>}
            >
              <AnswerCardForm
                answerPageId={this.props.answerPage.id}
                toggleModal={this.handleTextClick}
                moduleId={this.props.moduleId}
                resetAnsCards={this.props.resetAnswerPage}
              />
            </SlidingPane>
            {/* Sliding Panel for Choice Card Form */}
            <SlidingPane
              isOpen={this.state.createChoiceCard}
              onRequestClose={this.closeChoiceFormPane}
              from='right'
              overlayClassName='light-overlay'
              width='650px'
            >
              <div style={{ backgroundColor: 'white', overflowX: 'visible' }}>
                <ChoiceAnswerForm {...choiceFormProps} />
              </div>
            </SlidingPane>
            {/* Sliding Panel for Media Card Form */}
            <SlidingPane
              isOpen={this.state.createMediaCard}
              onRequestClose={this.closeChoiceFormPane}
              from='right'
              overlayClassName='light-overlay'
              className='sliding-panel-shadow'
              width='620px'
            >
              <div style={{ backgroundColor: 'white' }}>
                <MediaCardForm {...mediaCardFormProps} />
              </div>
            </SlidingPane>
          </div>
        </div>

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this Answer Page?'
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.deleteAnswerPage();
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps, {})(AnswerPage);
