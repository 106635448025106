import * as React from 'react';

function ArrowDownSleek(props) {
  return (
    <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 5.99999L0 0.999988L0.7 0.299988L5 4.59999L9.3 0.299988L10 0.999988L5 5.99999Z'
        fill='#6C7C83'
      />
    </svg>
  );
}

export default ArrowDownSleek;
