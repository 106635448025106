import { SVGProps } from 'react';
export const ErrorIcon = ({ fill = '#F94B50', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={fill}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9355 10C17.9355 14.4183 14.3538 18 9.93555 18C5.51727 18 1.93555 14.4183 1.93555 10C1.93555 5.58172 5.51727 2 9.93555 2C14.3538 2 17.9355 5.58172 17.9355 10ZM8.98555 7L8.93555 5.5H10.9355L10.8855 7L10.6355 11H9.23555L8.98555 7ZM9.93555 14.5C10.4878 14.5 10.9355 14.0523 10.9355 13.5C10.9355 12.9477 10.4878 12.5 9.93555 12.5C9.38326 12.5 8.93555 12.9477 8.93555 13.5C8.93555 14.0523 9.38326 14.5 9.93555 14.5Z'
        fill={fill}
      />
    </svg>
  );
};
