import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EHRConfig, teamAPI } from 'api/team';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { CustomToast } from 'components/utils/toast-message';
import { useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { useTeam } from '../../hooks/useTeam';
import EhrConfigForm from './EhrConfigForm';

const ConfigList = () => {
  const { team } = useTeam();

  const { data: ehrConfigs } = useQuery(['teamEHRConfigs', team.id], teamAPI.getTeamEHRConfigs);

  return (
    <div className='flex flex-col space-y-4'>
      {ehrConfigs?.map((config: EHRConfig) => (
        <ConfigCard config={config} key={config.unique_code} />
      ))}
    </div>
  );
};

const ConfigCard = ({ config }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const { team } = useTeam();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(teamAPI.deleteEHRConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries(['teamEHRConfigs', team.id]);
      toast.success(CustomToast, { data: 'Config deleted successfully' });
    },
  });

  const deleteConfig = () => {
    setDeleteModal(false);
    mutate(config.id);
  };

  return (
    <>
      <div className='flex items-center justify-between bg-white p-4 shadow'>
        <p>{config.title}</p>
        <div className='flex gap-4'>
          <PencilIcon
            className='h-6 w-6 cursor-pointer text-primary-600'
            onClick={() => setIsPaneOpen(true)}
          />
          <TrashIcon
            className='h-6 w-6 cursor-pointer text-error'
            onClick={() => setDeleteModal(true)}
          />
        </div>
      </div>

      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this config?'
        performAction={deleteConfig}
        toggleModal={() => setDeleteModal(false)}
      />

      <SlidingPane width='720px' isOpen={isPaneOpen} onRequestClose={() => setIsPaneOpen(false)}>
        <EhrConfigForm configId={config.id} closeModal={() => setIsPaneOpen(false)} />
      </SlidingPane>
    </>
  );
};

export default ConfigList;
