import clsx from 'clsx';

interface VerticalDividerProps {
  text?: string;
  className?: string;
}

const VerticalDivider = ({ text, className }: VerticalDividerProps) => {
  return (
    <div className={clsx('relative flex justify-center w-full', className)}>
      <div className='absolute top-1/2 w-full border-t border-gray-200' />
      {text && (
        <div className='px-[10px] py-[4px] bg-white text-gray-700 text-body-2 z-10'>{text}</div>
      )}
    </div>
  );
};

export default VerticalDivider;
