import clsx from 'clsx';
import { H4 } from 'components/utils/typo';
import React from 'react';
import { Icon } from '../common/Icon';

interface HeaderProps {
  title: string;
  toggleModal: () => void;
  className?: string;
}

export const Header: React.FC<HeaderProps> = ({ title, toggleModal, className }) => {
  const hasPxClass = className && className.includes('px-');
  return (
    <div
      className={clsx(
        'mb-2 flex w-full flex-row items-center pb-2',
        {
          'px-3': !hasPxClass, // Apply px-3 only if className does not contain 'px any value'
        },
        className
      )}
    >
      <H4>{title}</H4>
      <Icon.Close className='ml-auto cursor-pointer' onClick={toggleModal} />
    </div>
  );
};
