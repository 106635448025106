import * as React from 'react';

function DragIcon(props) {
  return (
    <svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.25 8C19.6642 8 20 8.33579 20 8.75C20 9.16421 19.6642 9.5 19.25 9.5H0.75C0.335786 9.5 0 9.16421 0 8.75C0 8.33579 0.335786 8 0.75 8H19.25ZM19.25 4C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H0.75C0.335786 5.5 0 5.16421 0 4.75C0 4.33579 0.335786 4 0.75 4H19.25ZM19.25 0C19.6642 0 20 0.335786 20 0.75C20 1.16421 19.6642 1.5 19.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H19.25Z'
        fill='#CCD0D7'
      />
    </svg>
  );
}

export default DragIcon;
