import AlgoDemo from 'assets/gifs/algo-demo.gif';
import KnowledgeBaseDemo from 'assets/gifs/knowledge-base-demo.gif';
import { Popover, PopoverContent, PopoverTrigger } from 'components/utils/AvoPopover';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/utils/AvoTooltip';
import Button from 'components/utils/Button';
import { Icons } from 'components/utils/Icons';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { ALGO_CREATE, CALCULATOR_CREATE, KNOWLEDGE_BASE_CREATE } from 'routes/urls';
import { useTeam } from '../../hooks/useTeam';

const buttons = [
  {
    title: 'ALGO',
    summary: 'Conversational agent for clinical decision-making.',
    icon: <Icons.AlgoGreen />,
    gif: AlgoDemo,
    detail: 'Build a conversational agent to guide in their clinical decision-making.',
    href: ALGO_CREATE,
  },
  {
    title: 'Calculator',
    summary: 'Compute scores and formulas with discrete data.',
    icon: <Icons.CalculatorGreen />,
    gif: null,
    detail: 'Calculate scores and formulas based upon discrete input data.',
    href: CALCULATOR_CREATE,
  },
  {
    title: 'Knowledge Base',
    summary: 'Import text from PDFs for AI outputs in ALGOs.',
    icon: <Icons.KnowledgeBaseGreen />,
    gif: KnowledgeBaseDemo,
    detail:
      'Allow for the dissemination of information in free-text format. In the future, this data will be able to be fed into GPT box.',
    href: KNOWLEDGE_BASE_CREATE,
  },
];

const CreateButton = ({ title, summary, icon, gif, detail, href }: (typeof buttons)[number]) => {
  return (
    <Tooltip placement='left-start'>
      <TooltipTrigger>
        <Link
          className='flex gap-1 rounded-lg border border-primary-600 bg-white p-3 text-left hover:bg-gray-50'
          to={href}
        >
          <div className='space-y-0.5'>
            <div className='text-button-1 text-gray-900'>{title}</div>
            <div className='text-caption-2 text-gray-600'>{summary}</div>
          </div>
          <div>{icon}</div>
        </Link>
      </TooltipTrigger>
      <TooltipContent>
        <div className='flex w-[172px] flex-col gap-4 rounded bg-gray-800 px-2 py-3 text-caption-2 text-white shadow'>
          {/* {gif && <img src={gif} className='h-[200px]' />} */}
          <div>{detail}</div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
interface ModuleCreateProps {
  moduleCount: number;
}

export const ModuleCreate = ({ moduleCount }: ModuleCreateProps) => {
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  const { team } = useTeam();

  const handleCreateClick = useCallback(() => {
    const isTrialTeam = team.team_type === 'trial';

    if (isTrialTeam && moduleCount >= 5) {
      setIsLimitExceeded(true);
    }
  }, [team, moduleCount]);
  return (
    <>
      <Popover placement='bottom-end'>
        <PopoverTrigger>
          <div className='w-[120px]' onClick={handleCreateClick}>
            <Button className='h-full'>+ Create</Button>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className='w-[268px] rounded-lg bg-gray-100 px-3 py-4 shadow-08'>
            <div className='space-y-2'>
              <div>Select a type.</div>
              {buttons.map((button, idx) => (
                <CreateButton {...button} key={idx} />
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <ConfirmModal
        confirmText='Close'
        content='You have reached the limit of creating 5 modules in your trial account.'
        open={isLimitExceeded}
        toggleModal={() => setIsLimitExceeded(false)}
        performAction={() => setIsLimitExceeded(false)}
      />
    </>
  );
};
