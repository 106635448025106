import { Button, Tab, Tabs, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { StyledInput, StyledInputTextArea } from '../utils/styled-components/FormStyle';

export const characterLimitStyle = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'right',
  letterSpacing: '-0.2px',
  color: '#566267',
};
export const classes = {
  highZindex: {
    zIndex: 9999,
  },
  moduleDropdown: {
    //padding: '0px 0px 4px',
    width: '400px',
    maxHeight: '284px',
    overflow: 'scroll',
    background: '#FFFFFF',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
  },
  icon: {
    borderRadius: 2,
    border: '1px solid #C5D1D8',
    minWidth: 16,
    height: 16,
  },
  container: {
    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
  },
  viewportLayout: {
    width: '78vw',
    //   minHeight: '1970px',

    display: 'flex',
    flexDirection: 'column',
  },
  pageTitle: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '36px',
    letterSpacing: '-0.4px',
    color: '#000000',
    marginBottom: '7px',
    marginTop: '10px',
    marginLeft: '2vw',
  },
  pageSubtitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#4F4F4F',
    marginLeft: '2vw',
    marginBottom: '35px',
  },
  triggerDropdown: {
    maxHeight: '500px',
    position: 'relative',
    minWidth: '600px',
    maxWidth: '600px',
    border: '1px solid #E5EDF0',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#C5D1D8',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: 12,
    paddingBottom: 12,
    cursor: 'default',
  },
  tagDropdown: {
    position: 'relative',
    // minWidth: '600px',
    // maxWidth: '600px',
    border: '1px solid #E5EDF0',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#C5D1D8',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: 12,
    paddingBottom: 12,
    cursor: 'default',
  },
  tagChip: {
    height: '24px',
    background: '#F5F8F9',
    borderRadius: '2px',
    cursor: 'normal',
    display: 'flex',
    padding: 3,
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 5,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#566267',
    marginBottom: 2,
    marginTop: 2,
  },
  paperProps: {
    overflow: 'scroll',
    minWidth: '600px',
    background: '#FFFFFF',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.02), 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    padding: 0,
  },
  iconMenuItem: {
    height: 56,
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#1E1F20',
  },
  permissionItem: {
    cursor: 'pointer',
    padding: 12,
    display: 'block',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#1E1F20',
  },
  closeIcon: {
    fontSize: 18,
    marginLeft: 3,
    color: '#6C7C83',
    cursor: 'pointer',
    width: 14,
    height: 14,
  },
  cancelIcon: {
    float: 'right',
    cursor: 'pointer',
    marginLeft: 'auto',
    width: 20,
    height: 20,
  },
  pageCard: {
    background: '#FFFFFF',
    borderRadius: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '45px',
    paddingTop: '30px',
    paddingBottom: '30px',
    position: 'relative',
    paddingRight: '45px',
  },
  securityLevel: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '43px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  securityText: {
    maxWidth: '7px ',
    minWidth: '7px',
    height: '7px',
    background: '#C4C4C4',
    marginLeft: 4,
    marginTop: 8,
    marginRight: 10,
  },
  authorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingRight: '0px',
    maxWidth: '600px',
  },
};
export const styles = createStyles({
  root: {
    paddingLeft: '1.5vh !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',

    color: '#999999',
  },
  icon: {
    marginRight: '20px',
    color: '#A6A6A6',
  },
});

export const FieldLabel = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#566267',
    marginBottom: 5,
  },
}))(Typography);

export const StyledInputV2 = styled(StyledInput)`
  min-width: 600px;
  max-width: 600px;
  border: 1px solid #e5edf0 !important;
  height: 40px !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  ::-webkit-input-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::-moz-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
`;

export const StyledSmallInputV2 = styled(StyledInput)`
  min-width: 288px;
  max-width: 288px;
  border: 1px solid #e5edf0 !important;
  height: 40px !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  ::-webkit-input-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::-moz-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
`;
export const StyledInputTextAreaV2 = styled(StyledInputTextArea)`
  min-width: 600px;
  max-width: 600px;
  border: 1px solid #e5edf0 !important;
  padding-top: 10px !important;
  border-radius: 4px !important;
  margin-bottom: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* or 143% */

  /* grayscale/text-900 */

  color: #1e1f20 !important;

  ::-webkit-input-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::-moz-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
  ::placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c5d1d8 !important;
  }
`;

export const SectionTitle = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    color: '#1E1F20',
    marginBottom: 24,
  },
}))(Typography);

export const Caption = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#566267',
    marginBottom: 24,
  },
}))(Typography);

export const AddAuthorButton = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 10px',
    gap: '10px',
    width: '72px',
    height: '40px',
    background: '#23C29D',
    borderRadius: '4px',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      background: '#23C29D',
    },
    '&:focus': {
      background: '#23C29D',
    },
  },
  disabled: {
    background: '#E5EDF0',
    color: 'white !important',
  },
}))(Button);

export const FlowResourcesTab = withStyles({
  root: {
    maxWidth: '246px',
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  textColorPrimary: {
    textTransform: 'none',
    color: '#616262',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
  },
  wrapper: {
    alignItems: 'end',
    marginRight: '20px',
  },
  selected: {
    color: (props) => (props.color ? props.color : '#08A88E !important'),
  },
})(Tab);

export const FlowResourcesTabs = withStyles({
  indicator: {
    width: 5,
  },
  root: {
    marginTop: 20,
  },
})(Tabs);
