import { GET_CUSTOM_NUMERICS } from 'actions/actionsTypes';

const customNumericReducer = (state = { customNumerics: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOM_NUMERICS:
      return {
        ...state,
        customNumerics: action.payload,
      };

    default:
      return state;
  }
};

export default customNumericReducer;
