import { Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomNumericForm from 'components/resources/custom-numerics/CustomNumericForm';
import ImageForm from 'components/resources/image-Infobox/imageForm';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import 'components/utils/modals/modal.css';
import { CreateButton, PanelLabel2 } from 'components/utils/styled-components/FormStyle';
import { Multiselect } from 'multiselect-react-dropdown';
import React from 'react';
import SlidingPane from 'react-sliding-pane';
import { Form, FormGroup } from 'reactstrap';

class AddValueItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: [],
      createInfobox: false,
      createImage: false,
      createCustomNumeric: false,
    };
  }

  onValueSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedValue: selectedList,
    });
  };

  addNewItem = (newItem) => {
    this.setState((previous) => ({
      selectedValue: [...previous.selectedValue, newItem],
    }));
  };

  onValueRemove = (selectedList, removedItem) => {
    this.setState({
      selectedValue: selectedList,
    });
  };

  addItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.toggleModal();
    this.props.addItem(this.state.selectedValue);
  };

  toggleCreateModal = (modal) => {
    this.setState((previous) => ({
      [modal]: !previous[modal],
    }));
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    let multiSelectstyle = {
      chips: {
        background: '#08A88E',
        color: 'white',
      },
      multiselectContainer: {
        border: 'none',
        maxWidth: '100%',
        height: '100%',
        overflow: 'visible',
        cursor: 'pointer',
      },
      searchBox: {
        background: '#ffffff',
        border: '1px solid #cccccc',
        overflow: 'visible',
        maxWidth: '100%',
        height: '100%',
        borderRadius: '5px',
        paddingTop: 0,
        marginTop: '0px !important',
      },
      inputField: {
        textIndent: '10px',
        paddingTop: 0,
        marginTop: '-4px',
        display: 'inline-block',
        borderBottom: 'none',
        boxShadow: 'none',
      },
      optionContainer: {
        paddingRight: '20px',
      },
      optionListContainer: {
        border: 'none',
        width: '106%',
        paddingTop: '20px',
        left: '-1%',
        boxShadow: 'none',
      },
    };
    return (
      <Form
        style={{
          display: 'flex',
          minHeight: '100%',
          flexDirection: 'column',
          overflow: 'show',
        }}
        onKeyPress={this.onKeyPress}
        onSubmit={this.addItem}
      >
        <Box>
          <FormGroup>
            <PanelLabel2 for='name' style={{ fontSize: '16px !important' }}>
              Select Items
            </PanelLabel2>

            <Multiselect
              selectedValues={this.state.selectedValue}
              options={this.props.dropdownOptions}
              onSelect={this.onValueSelect}
              onRemove={this.onValueRemove}
              selectionLimit={null}
              displayValue={this.props.valuePanelNumeric ? 'display_value' : 'title'}
              style={multiSelectstyle}
            />
          </FormGroup>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 'auto',
            marginBottom: '40px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <CreateButton type='submit' style={{ marginLeft: 'auto', marginRight: '20px' }}>
            Add Item
          </CreateButton>
          {this.props.createInfobox && (
            <CreateButton
              onClick={() => this.toggleCreateModal('createInfobox')}
              style={{ width: '190px' }}
            >
              Create Infobox
            </CreateButton>
          )}
          {this.props.createImage && (
            <CreateButton
              onClick={() => this.toggleCreateModal('createImage')}
              style={{ width: '190px' }}
            >
              Create Media
            </CreateButton>
          )}
          {this.props.refreshCalculators && (
            <CreateButton
              onClick={() => this.props.refreshCalculators()}
              style={{ width: '215px' }}
            >
              Reload Calculators
            </CreateButton>
          )}
          {this.props.createCustomNumeric && (
            <CreateButton
              onClick={() => this.toggleCreateModal('createCustomNumeric')}
              style={{ width: '250px' }}
            >
              Create Custom Numeric
            </CreateButton>
          )}
        </Box>

        <SlidingPane
          isOpen={this.state.createInfobox}
          onRequestClose={() => this.toggleCreateModal('createInfobox')}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          // hideHeader
          className='no-padding add-info-box sliding-panel-shadow'
          width='1210px'
        >
          <InfoBoxForm
            type={this.props.type}
            toggleModal={() => this.toggleCreateModal('createInfobox')}
            savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
            selectValue={this.addNewItem}
            isFullHeight={true}
            createModal={true}
          />
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.createImage}
          onRequestClose={() => this.toggleCreateModal('createImage')}
          from='bottom'
          // hideHeader
          className='sliding-pan-modal side-popup-shadow'
          title={
            <div>
              Create Media
              <HighlightOffIcon
                className='backIcon'
                onClick={() => this.toggleCreateModal('createImage')}
              ></HighlightOffIcon>
            </div>
          }
          //   subtitle='Build a Trigger'
          width='565px'
          closeIcon={
            <div>
              <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
            </div>
          }
        >
          <ImageForm
            toggleModal={() => this.toggleCreateModal('createImage')}
            type={this.props.type}
            savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
            selectValue={this.addNewItem}
          />
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.createCustomNumeric}
          onRequestClose={() => this.toggleCreateModal('createCustomNumeric')}
          from='right'
          // hideHeader
          className='no-padding side-popup-shadow'
          width='622px'
        >
          <CustomNumericForm
            type={this.props.type}
            toggleModal={() => this.toggleCreateModal('createCustomNumeric')}
            savePosition={() => []}
            selectValue={this.addNewItem}
            isFullHeight={true}
            createModal={true}
          />
        </SlidingPane>
      </Form>
    );
  }
}

export default AddValueItem;
