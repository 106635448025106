import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef } from 'react';

export const CustomPopover = ({ open, children, onClose, classes = '' }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <Popover className='relative' ref={popoverRef}>
      <Transition
        show={open}
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <Popover.Panel className={`absolute z-10 bg-white ${classes}`}>{children}</Popover.Panel>
      </Transition>
    </Popover>
  );
};
