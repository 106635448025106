import { SVGProps } from 'react';

export const ShareLinkIcon = ({ fill = '#08A88E', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6512 4.52184C10.6512 2.94569 11.9289 1.66797 13.505 1.66797C15.0812 1.66797 16.3589 2.94569 16.3589 4.52184C16.3589 6.09798 15.0812 7.3757 13.505 7.3757C12.774 7.3757 12.1072 7.10087 11.6023 6.64889L7.26396 9.35901C7.33421 9.60686 7.3718 9.86843 7.3718 10.1388C7.3718 10.4092 7.33419 10.6708 7.26393 10.9187L11.6018 13.6292C12.1068 13.1769 12.7738 12.9019 13.505 12.9019C15.0812 12.9019 16.3589 14.1796 16.3589 15.7558C16.3589 17.3319 15.0812 18.6096 13.505 18.6096C11.9289 18.6096 10.6512 17.3319 10.6512 15.7558C10.6512 15.4854 10.6888 15.2238 10.759 14.9759L6.4212 12.2653C5.91622 12.7176 5.24919 12.9926 4.51793 12.9926C2.94178 12.9926 1.66406 11.7149 1.66406 10.1388C1.66406 8.56263 2.94178 7.28491 4.51793 7.28491C5.24923 7.28491 5.91629 7.55998 6.42129 8.01229L10.7592 5.30242C10.6889 5.05433 10.6512 4.79248 10.6512 4.52184ZM13.505 3.2566C12.8063 3.2566 12.2398 3.82307 12.2398 4.52184C12.2398 5.22061 12.8063 5.78707 13.505 5.78707C14.2038 5.78707 14.7703 5.22061 14.7703 4.52184C14.7703 3.82307 14.2038 3.2566 13.505 3.2566ZM13.505 14.4905C12.8063 14.4905 12.2398 15.057 12.2398 15.7558C12.2398 16.4545 12.8063 17.021 13.505 17.021C14.2038 17.021 14.7703 16.4545 14.7703 15.7558C14.7703 15.057 14.2038 14.4905 13.505 14.4905ZM3.2527 10.1388C3.2527 9.44001 3.81916 8.87354 4.51793 8.87354C5.2167 8.87354 5.78316 9.44001 5.78316 10.1388C5.78316 10.8375 5.2167 11.404 4.51793 11.404C3.81916 11.404 3.2527 10.8375 3.2527 10.1388Z'
        fill={fill}
      />
    </svg>
  );
};
