import { Box, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getTriggers } from 'actions/resources/getTriggers';
import axios from 'axios';
import { selectOrRemoveGroupItem } from 'components/module-detail/container/GroupCardUtil';
import { FixedRow, UnderlyingElement } from 'components/panels/ChoicePanelForm';
import AppearingSituation from 'components/utils/AppearingSituation';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { MODULE_TYPES } from 'constants';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import { Multiselect } from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import { CONTAINER_CARD_API_URL, MODULE_API_URL } from '../../../constants';

export const multiSelectstyle = {
  chips: {
    background: '#08A88E',
    color: 'white',
  },
  multiselectContainer: {
    border: 'none',
    maxWidth: '100%',
    height: '100%',
    overflow: 'visible',
    cursor: 'pointer',
  },
  option: {
    padding: 0,
    paddingTop: 9,
    paddingBottom: 9,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    color: '#1E1F20',
  },
  searchBox: {
    background: '#ffffff',
    border: 'none',
    overflow: 'visible',
    maxWidth: '100%',
    height: '100%',
    borderRadius: '5px',
    paddingTop: 0,
    marginTop: '0px !important',
  },
  inputField: {
    textIndent: '10px',
    paddingTop: 0,
    marginTop: '-4px',
    display: 'inline-block',
    borderBottom: 'none',
    boxShadow: 'none',
  },

  optionListContainer: {
    border: 'none',
    width: '106%',
    paddingTop: '20px',
    paddingRight: '0px !important',
    left: '-1%',
    boxShadow: 'none',
  },
};

class ContainerCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uiElementOptions: [],
      isRedirect: false,

      name: '',
      trigger: '',
      module: '',
      variable: '',
      conversationItems: [],
    };

    this.originalChildCards = [];
  }

  helpInfoData = getHelpInfoData('Container');

  componentDidMount() {
    //TODO: Remove Redux when redux is no longer used.
    this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    if (!this.isEditMode(this.props.containerCardId)) {
      // set default trigger to Alway On
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({ trigger: alwayOnTrigger ? alwayOnTrigger.id : '' });
    }

    // get ui_elements of a specific module that are not the part of any container
    axios.get(MODULE_API_URL + this.props.moduleId + '/conversation_items').then((res) => {
      let conversationItemList = [];
      res.data.forEach(function (item, index) {
        // section divider should also not be in the selectable list of items
        if (
          item.resourcetype !== 'AnswerPage' &&
          !item.is_introduction &&
          item.resourcetype !== 'SectionDivider'
        )
          conversationItemList.push({
            title:
              (item.question || item.title || item.name || item.primary_string || 'No Title') +
              ' '.repeat(index),
            id: item.id,
          });
      });
      // populate the ui elements for the dropdown list
      this.setState({ uiElementOptions: conversationItemList });
    });

    if (this.isEditMode(this.props.containerCardId)) {
      // get given container info
      axios.get(CONTAINER_CARD_API_URL + this.props.containerCardId + '/').then((res) => {
        this.originalChildCards = res.data.ui_elements;
        let containerUiElements = [];
        res.data.ui_elements.forEach((item) => {
          containerUiElements.push({
            title: item.title ? item.title : item.name ? item.name : item.question,
            id: item.id,
          });
        });

        // populate the ui elements of given container
        this.setState({
          name: res.data.name,
          trigger: res.data.trigger,
          module: res.data.module,
          variable: res.data.variable,
          conversationItems: containerUiElements,
        });
      });
    }
  }

  // TODO: consolidate for all modules
  isEditMode = (containerCardId) => {
    if (containerCardId && containerCardId !== 'initial_id') {
      return true;
    }

    return false;
  };

  onChange = (e) => {
    this.props.setTitle?.(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  getPayload = () => {
    return {
      name: this.state.name,
      variable: this.state.variable,
      trigger: this.state.trigger,
      module: this.props.moduleId,
      ui_elements: this.state.conversationItems,
      position: this.props.position,
    };
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.trigger) {
      toast.error(CustomToast, { data: 'Trigger cannot be empty' });
      return;
    }
    if (this.state.name === '') {
      toast.error(CustomToast, { data: 'Please fill out the group title field.' });
      this.props.modal && this.props.closeUiElementPane();
      return;
    }

    this.setState({ isLoading: true });
    if (!this.state.variable) {
      const variable = await getVariableName(this.state.name);
      this.setState({ variable });
    }

    if (this.isEditMode(this.props.containerCardId)) {
      this.props.modal && this.props.closeUiElementPane();
      this.editContainerCard();
    } else {
      this.props.modal && this.props.closeUiElementPane();
      this.createContainerCard();
    }
  };

  handleCancel = () => {
    if (this.isEditMode(this.props.containerCardId)) {
      this.props.toggleModal(this.props.containerCardId, 'cancel_from_edit');
    } else {
      this.props.toggleModal(this.props.containerCardId, 'cancel_from_create');
    }
  };

  createContainerCard = () => {
    axios.post(CONTAINER_CARD_API_URL, this.getPayload()).then(() => {
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);

      this.props.onSave();
    });
  };

  editContainerCard = () => {
    const API_URL = CONTAINER_CARD_API_URL + this.props.containerCardId + '/';

    axios.put(API_URL, this.getPayload()).then(async () => {
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);

      this.props.onSave();
    });
  };

  getSelectedTrigger = (data) => {
    this.props.setTriggerId?.(data);
    this.setState({ trigger: data });
  };

  onSelect = (stateName, selectedList, selectedItem, isRemoved = false) => {
    const { uiElementOptions, conversationItems } = this.state;
    if (typeof selectedItem === 'number') {
      const selectedElement = conversationItems[selectedItem];
      selectedItem = {
        id: selectedElement.id,
        title: selectedElement.title
          ? selectedElement.title
          : selectedElement.name
          ? selectedElement.name
          : selectedElement.question,
      };
    }
    let finalUiElements = selectOrRemoveGroupItem(uiElementOptions, selectedItem, isRemoved);
    this.setState({ uiElementOptions: finalUiElements, [stateName]: selectedList });
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div style={{ height: '97%' }}>
        <div className='row mt-14 h-full'>
          <div className='side-panel-form h-full'>
            <Header title='Group' toggleModal={this.handleCancel} />
            <Form className='container_card flex h-full flex-col' onSubmit={this.handleSubmit}>
              <div className='relative'>
                <Box className=' mt-2'>
                  <AppearingSituation
                    onInputChange={this.getSelectedTrigger}
                    triggerOptions={triggers}
                    defaultValue={this.state.trigger}
                    moduleId={this.props.moduleId}
                  />
                </Box>

                <Box style={{ marginTop: '25px' }}>
                  <InputField
                    name='name'
                    required={true}
                    value={this.state.name}
                    onChange={this.onChange}
                    label={helpInfoData?.name?.label}
                    detail={helpInfoData?.name?.detail}
                    placeholder={helpInfoData?.name?.placeholder}
                    maxLength={helpInfoData?.name?.character_limit}
                  />
                </Box>

                <VariableAutoGenerator
                  tagTitle='Group'
                  variable={this.state.variable}
                  question={this.state.name}
                  setVariableName={(variable) => this.setState({ variable })}
                />

                <Box style={{ marginTop: '10px' }}>
                  <FieldLabel
                    label={helpInfoData?.items?.label}
                    detail={helpInfoData?.items?.detail}
                  />
                  <div
                    style={{
                      background: '#ffffff',
                      border: '1px solid #cccccc',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      maxWidth: '550px',
                      height: this.state.conversationItems.length > 0 ? 'auto' : '47px',
                      paddingRight: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '5px',
                      paddingTop: this.state.conversationItems.length > 0 ? '15px' : '5px',
                    }}
                  >
                    <Multiselect
                      avoidHighlightFirstOption
                      options={this.state.uiElementOptions}
                      selectedValues={this.state.conversationItems}
                      onSelect={(selectedList, selectedItem) =>
                        this.onSelect('conversationItems', selectedList, selectedItem)
                      }
                      onRemove={(selectedList, removedItem) =>
                        this.onSelect('conversationItems', selectedList, removedItem, true)
                      }
                      displayValue='title'
                      placeholder={helpInfoData?.items?.placeholder || ''}
                      style={{
                        ...multiSelectstyle,
                        optionContainer: {
                          // to add a scroll in the option dropdown
                          height: this.state.uiElementOptions.length > 2 ? '100px' : 'auto',
                        },
                      }}
                    />
                    <ExpandMoreIcon style={{ color: '#A6A6A6' }} />
                  </div>
                </Box>
                <UnderlyingElement style={{ height: '200px' }} />
                <FixedRow>
                  <div
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 30,
                      marginBottom: 10,
                    }}
                  >
                    <CancelButton
                      style={{ color: '#08A88E', marginRight: '20px' }}
                      onClick={this.handleCancel}
                    >
                      {CANCEL_BUTTON}
                    </CancelButton>
                    <CreateButton disabled={!this.state.name} type='submit'>
                      {this.state.isLoading ? (
                        <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                      ) : (
                        SAVE_BUTTON
                      )}
                    </CreateButton>
                  </div>
                </FixedRow>
              </div>
            </Form>
          </div>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal}
          toggleModal={this.props.closeUiElementPane}
          toggleModalPanel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

ContainerCardForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  setTriggerId: PropTypes.func.isRequired,
  moduleId: PropTypes.number,
  containerCardId: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  closeUiElementPane: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch, componentProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContainerCardForm);
