import {
  Button,
  Checkbox,
  Chip,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core';
import { styled as MaterialUIStyled, withStyles } from '@material-ui/core/styles';
import { FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';

export const TearDropMenu = withStyles((theme) => ({
  paper: {
    marginTop: '3.5vh',
    borderRadius: '25px',
    borderTopLeftRadius: '0px',
    boxShadow: '0px 16px 32px rgba(0, 91, 156, 0.29)',
    WebkitBoxShadow: '0px 16px 32px rgba(0, 91, 156, 0.29)',
    MozBoxShadow: '0px 16px 32px rgba(0, 91, 156, 0.29)',
  },
}))(Menu);

/* Dropdown menu item that gets green on hover */

export const StyledMenuItem = MaterialUIStyled(MenuItem)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '17px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  color: '#616262',
  '&:hover': { color: '#08A88E', backgroundColor: 'white !important' },
});

export const StyledButton = withStyles((theme) => ({
  root: {
    color: '#616262',

    textTransform: 'none',
    background: '#FFFFFF',
  },
}))(Button);

export const greenCheckbox = {
  color: '#08A88E',
};
export const StyledFormHelperText = styled(FormHelperText)`
  color: #7e7e7e;
  line-height: 19px;
  font-size: 14px;
  letter-spacing: 0.0325em;
`;
export const StyledCharacterLimitText = styled(Typography)`
  float: right;
  color: #929292;
  font-size: 14px;
  line-height: 16px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 20px;
`;

export const StyledFormGroupMB30 = styled(FormGroup)`
  margin-bottom: 30px;
`;

export const StyledFormGroupMB16 = styled(FormGroup)`
  margin-bottom: 16px;
`;

export const ToggleButton = withStyles((theme) => ({
  root: {
    width: '145px',
    height: '48px',
    //border: '1px solid lightgrey',
    borderRadius: '50px',
    fontWeight: 'regular',
    fontSize: '14px',
    marginRight: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    color: 'black',
    textTransform: 'none',
    paddingBottom: '1px',
  },
}))(Button);

export const InfoboxFormButton = withStyles((theme) => ({
  root: {
    background: '#08A88E',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    minWidth: '125px',
    height: '46px',
    fontSize: '14px',
    fontWeight: 'bold',
    boxShadow: 'none',

    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}))(Button);

export const InfoBoxChip = withStyles((theme) => ({
  label: {
    maxWidth: '300px',
  },
}))(Chip);

export const StyledInputTextArea = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 550px;
  height: 45px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;

  /* identical to box height */

  color: #000000 !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
`;
export const StyledInput = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  // max-width: 550px;
  height: 45px;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 8px !important;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  font-weight: 300;
`;
export const AddButton = withStyles(() => ({
  root: {
    background: '#08A88E !important',
    borderRadius: ['6px', '6px'],
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#ffffff !important',
    minWidth: '90px',
    maxWidth: '115px',
    height: '40px',
    '&:hover': {
      background: '#08A88E!important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      background: '#08A88E !important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
}))(Button);

export const CreateCustom = withStyles(() => ({
  root: {
    background: 'none !important',
    borderRadius: ['6px', '6px'],
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#08A88E !important',
    minWidth: '90px',
    height: '40px',
    maxWidth: '142px',
    paddingLeft: '14px',
    paddingRight: '16px',
  },
}))(Button);
export const CreateButton = withStyles(() => ({
  root: {
    width: '158px',
    height: '60px',
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: (props) => (props.color ? props.color : '#08A88E'),
    borderRadius: '100px',
    boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      background: (props) => (props.color ? props.color : '#08A88E'),
      boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    },
    '&:focus': {
      background: (props) => (props.color ? props.color : '#08A88E'),
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
  disabled: {
    background: (props) => (props.color ? props.color : '#E5EDF0'),
  },
}))(Button);
export const CancelButton = withStyles(() => ({
  root: {
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: 'transparent',
    color: (props) => (props.color ? props.color : '#08A88E'),
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))(Button);
export const PanelLabel = styled(Label)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #566267;
`;

export const PanelLabel2 = styled(Label)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #191919;
`;

export const AddLabel = styled(Label)`
  font-family: Roboto !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  text-transform: uppercase;

  color: #bdbdbd !important;
`;

export const Examples = styled(Grid)`
  width: 100%;
  background: #fbfbfb;
  border: 1px dashed #e4e3e3;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const StyledTextArea = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 680px;
  height: auto;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  padding-left: 5px;
  padding-top: 13px;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  font-weight: 300;
`;

export const ResourcesMenu = withStyles((theme) => ({
  paper: {
    width: '7.5vw',
    background: '#FFFFFF',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)',

    '&:active': {
      color: '#08A88E',
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(Menu);

export const ResourcesMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#08A88E !important',
      color: 'white',
    },
  },
}))(MenuItem);

export const CreateRefInfoboxButton = withStyles((theme) => ({
  root: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 15,
    borderRadius: 0,

    '&:hover': {
      backgroundColor: '#FFFFF',
    },
  },
}))(StyledButton);

export const InfoboxRefMenu = withStyles((theme) => ({
  paper: {
    width: '13vw',
    background: '#FFFFFF',
    border: '1px solid #E1E1E1',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.09)',
    borderRadius: '5px',

    '&:active': {
      color: '#08A88E',
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(Menu);

export const InfoboxRefMenuItem = styled(MenuItem)({
  whiteSpace: 'pre-wrap',
  color: '#08A88E',
  minHeight: '4vh',
  fontSize: 15,
  fontWeight: 600,
  paddingTop: 9,
  paddingBottom: 9,

  '&:hover': {
    backgroundColor: '#08A88E !important',
    color: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.09)',
  },
});

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBottom: '-5px',
    marginLeft: '1.5em',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#08A88E',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#08A88E',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const MentionInfoboxMenuItem = styled(MenuItem)({
  whiteSpace: 'pre-wrap',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 'normal',
  paddingBottom: 8,
  paddingTop: 8,
  paddingLeft: 30,

  '&:hover': {
    backgroundColor: '#F9FAFB !important',
    color: '#08A88E !important',
  },
  '&:focus': {
    color: '#08A88E',
  },
});

export const InfoboxSearchInput = styled(Input)`
  background: #ffffff !important;
  max-width: 550px;
  height: 55px !important;
  margin-bottom: 0px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #000000 !important;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: none !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  ::-webkit-input-placeholder {
    font-weight: normal;
    font-style: normal;
    color: #8b95a6;
    font-size: 16px;
    line-height: 21px;
  }
  ::-moz-placeholder {
    font-weight: normal;
    font-style: normal;
    color: #8b95a6;
    font-size: 16px;
    line-height: 21px;
  }
  ::placeholder {
    font-weight: normal;
    font-style: normal;
    color: #8b95a6;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const NumericsMenu = withStyles((theme) => ({
  paper: {
    width: '334px',
    background: '#FFFFFF',
    border: '1px solid #E7E7E7',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
    borderRadius: '4px',
    marginTop: 5,
    maxHeight: 600,
    overflowY: 'hidden',

    '&:active': {
      color: '#08A88E',
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(Popover);

export const NumericsMenuItem = styled(MenuItem)({
  whiteSpace: 'pre-wrap',
  color: '#172B4D',
  maxHeight: '300px',
  overflowY: 'scroll',
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 'normal',
  paddingLeft: 30,

  '&:hover': {
    backgroundColor: '#F9FAFB !important',
    color: '#08A88E !important',
  },
  '&:focus': {
    color: '#08A88E',
  },
});

export const GreenCheckbox = withStyles({
  root: {
    color: '#08A88E',
    '&$checked': {
      color: '#08A88E',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export const ConditionalFormulaButton = withStyles(() => ({
  root: {
    width: '171px',
    height: '48px',
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    textTransform: 'none',
    background: '#08A88E',
    borderRadius: '100px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    WebkitBoxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    MozBoxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#08A88E',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      background: '#08A88E',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
}))(Button);

export const VariableButton = withStyles((theme) => ({
  root: {
    fontSize: 18,
    textTransform: 'none',
    minWidth: 42,
    height: 24,
    paddingBottom: 5,
  },
  endIcon: {
    marginLeft: '2px',
  },
  disabled: {
    color: '#000000 !important',
  },
}))(Button);

export const PhoneNoConfirmButton = withStyles((theme) => ({
  root: {
    marginRight: '10px',
    textTransform: 'none',
    color: 'white',
    marginTop: 33,
    background: '#08A88E',
    width: '224px',
    height: '54px',
    boxShadow: '0px 20px 30px rgb(0 0 0 / 15%)',
    border: 'none',
    borderRadius: '100px',
    fontWeight: 'bold',
    fontSize: '20px',
    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
  endIcon: {
    marginLeft: '1px',
  },
}))(Button);

export const InfoboxLabelChangeButton = withStyles((theme) => ({
  root: {
    width: 113,
    height: 44,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#FFFFFF',
    backgroundColor: '#08A88E',
    textTransform: 'none',
    cursor: 'pointer',
    borderRadius: '6px',
    justifyContent: 'center',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#08A88E !important',
    },
  },
  endIcon: {
    marginLeft: '1px',
  },
}))(Button);

export const ToolInfoboxButton = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    fontSize: 15,
    textTransform: 'none',
    minWidth: 24,
    height: 24,
    color: 'black',
  },
}))(Button);

export const ToolMediaButton = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    fontSize: 15,
    textTransform: 'none',
    minWidth: 24,
    height: 24,
    color: 'black',
    paddingBottom: 4,
  },
}))(Button);

export const EditButton = withStyles((theme) => ({
  root: {
    fontWeight: '500',
    fontSize: 15,
    textTransform: 'none',
    width: 54,
    height: 28,
    minWidth: 54,
    color: '#696969',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid #08A88E',
    boxSizing: 'border-box',
    borderRadius: '3px',
    marginLeft: 'auto',
    marginRight: '10px',
    display: 'none',
    paddingTop: 2,
  },
}))(Button);

// TODO: all the below 3 button style components are very similar
// we need to consolidate when we refactor
// sorry for bad naming at the moment
export const StyledInputWithButton = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  height: 45px !important;
  max-width: 331px;
  border-radius: 5px !important ;
  text-indent: 15px;
  display: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: bold;
`;

export const StyledInputWithButton2 = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  height: 45px !important;
  max-width: 331px;
  border-radius: 5px !important ;
  text-indent: 15px;
  display: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  &:disabled {
    background: #f4f4f4 !important;
    color: #161616;
    opacity: 0.4;
  }
  font-weight: bold;
`;

export const StyledInputWithButton3 = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  width: 425px !important;
  height: 45px !important;
  border-radius: 5px !important ;
  text-indent: 15px;
  display: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  &:disabled {
    background: #f4f4f4 !important;
    color: #161616;
    opacity: 0.4;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: bold;
`;

export const StyledInputV2 = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 550px;
  height: 45px;
  width: 100%;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #25cd7c !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }
  font-weight: 300;
`;
