import { Box, Typography } from '@material-ui/core';
import ChoiceDropdown from './input-components/ChoiceDropdown';
import SimulatorMultiDropdown from './input-components/MultiDropdown';

import TypedInput from './input-components/TypedInput';

function VariableItem({
  name,
  type,
  values,
  code,
  optionType,
  setPreviewContent,
  previewContent,
  arrayIndex,
  entityKey,
}) {
  const renderSwitch = () => {
    switch (type) {
      case 'text':
        return (
          <TypedInput
            entityKey={entityKey}
            type='text'
            unit={values}
            code={code}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'boolean':
        return (
          <ChoiceDropdown
            entityKey={entityKey}
            name={name}
            values={values.length > 0 ? values : ['On', 'Off']}
            code={code}
            arrayIndex={arrayIndex}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'text_input_variable':
        return optionType === 'boolean' ? (
          <ChoiceDropdown
            entityKey={entityKey}
            name={name}
            values={values.length > 0 ? values : ['On', 'Off']}
            code={code}
            arrayIndex={arrayIndex}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        ) : (
          <TypedInput
            entityKey={entityKey}
            type='text'
            unit={values}
            code={code}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'choice_variable':
        return (
          <ChoiceDropdown
            entityKey={entityKey}
            name={name}
            values={values.length > 0 ? values : ['On', 'Off']}
            code={code}
            arrayIndex={arrayIndex}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'container_variable':
        return (
          <ChoiceDropdown
            entityKey={entityKey}
            name={name}
            values={['On', 'Off']}
            code={code}
            arrayIndex={arrayIndex}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'multi_variable':
        return (
          <SimulatorMultiDropdown
            entityKey={entityKey}
            name={name}
            values={values}
            code={code}
            arrayIndex={arrayIndex}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      case 'number':
        return (
          <TypedInput
            entityKey={entityKey}
            type='number'
            unit={values}
            code={code}
            setPreviewContent={setPreviewContent}
            previewContent={previewContent}
          />
        );
      default:
        return 'Variable Not Handled';
    }
  };

  return (
    <Box className='d-flex align-items-center flex-row' style={{ marginBottom: 2, minHeight: 45 }}>
      <Typography>{name}</Typography>
      {renderSwitch()}
    </Box>
  );
}

export default VariableItem;
