import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import Avatar from 'components/utils/Avatar';
import VerticalDivider from 'components/utils/VerticalDivider';
import { Fragment, useEffect, useState } from 'react';
import { PermissionMap, PermissionOptions } from '../../../constants';
import ConfirmationModal from './ConfirmationModal';
import { IMemberPermission } from './MemberPermissionDropdown';

interface MemberPermissionProps {
  member: IMemberPermission;
  onPermissionChange?: (team_member_id: number, permission: string) => void;
  onDelete?: (team_member_id: number) => void;
}

const MemberPermissionItem = ({ member, onPermissionChange, onDelete }: MemberPermissionProps) => {
  const [permission, setPermission] = useState(member.permission_type);
  useEffect(() => {
    setPermission(member.permission_type);
  }, [member.permission_type]);
  useEffect(() => {
    onPermissionChange?.(member.team_member_id, permission);
  }, [permission]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  return (
    <div className='flex gap-2 justify-between items-center border-b border-gray-300'>
      <div className='w-3/4 flex space-x-3 items-center px-[12px] py-[10px]'>
        <Avatar name={member.name} />
        <div>{member.name}</div>
      </div>
      {permission === PermissionMap.owner.value ? (
        <div className='w-1/4 px-[12px] py-[10px] rounded border !border-transparent text-gray-500'>
          Owner
        </div>
      ) : (
        <Listbox as='div' className='relative w-1/4' onChange={setPermission} value={permission}>
          {({ open }) => (
            <>
              <Listbox.Button className='flex justify-between items-center w-full px-[12px] py-[10px] rounded border !border-transparent'>
                <div className='text-gray-500'>{PermissionMap[permission].label}</div>
                <div className={clsx('transition-all', { 'rotate-180': open })}>
                  <DropdownArrowIcon />
                </div>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Listbox.Options className='absolute w-44 overflow-y-auto bg-white rounded z-10 border border-gray-500'>
                  {PermissionOptions.map((per, idx) => (
                    <Listbox.Option
                      key={idx}
                      value={per.value}
                      className={({ active }) =>
                        clsx('cursor-pointer px-[12px] py-[10px]', {
                          'bg-primary-200': active,
                        })
                      }
                    >
                      {({ selected }) => (
                        <div className='flex justify-between'>
                          <div>{per.label}</div>
                          {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                  <VerticalDivider />
                  <div
                    onClick={() => setShowOwnerModal(true)}
                    className='cursor-pointer px-[12px] py-[10px] text-primary-500 hover:bg-primary-200'
                  >
                    Transfer Ownership
                  </div>
                  <div
                    onClick={() => setShowDeleteModal(true)}
                    className='cursor-pointer px-[12px] py-[10px] text-red-500 hover:bg-primary-200'
                  >
                    Remove
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      )}
      <ConfirmationModal
        open={showOwnerModal}
        title='Transfer Ownership'
        text={`Are you sure you want to transfer ownership to ${member.name}?`}
        onClose={() => setShowOwnerModal(false)}
        action={() => setPermission(PermissionMap.owner.value)}
      />
      <ConfirmationModal
        open={showDeleteModal}
        title='Remove Permission'
        text={`Are you sure you want to remove ${member.name} from the permission settings?`}
        onClose={() => setShowDeleteModal(false)}
        action={() => onDelete?.(member.team_member_id)}
      />
    </div>
  );
};

export default MemberPermissionItem;
