import { GET_INFOBOXES } from 'actions/actionsTypes';

const infoboxReducer = (state = { infoBoxes: [] }, action) => {
  switch (action.type) {
    case GET_INFOBOXES:
      return {
        ...state,
        infoBoxes: action.payload,
      };

    default:
      return state;
  }
};

export default infoboxReducer;
