import Backdrop from 'components/utils/Backdrop';
import { Icons } from 'components/utils/Icons';

export const EntireScreenLoading = () => {
  return (
    <Backdrop>
      <div className='aspect-square w-[75px]'>
        <Icons.LoadingSpinnerWhite />
      </div>
    </Backdrop>
  );
};
