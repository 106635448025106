export const DEFAULT_NODE_WIDTH = 560;
export const DEFAULT_NODE_HEIGHT = 250;
export const PAN_DURATION = 300;
export const TIMEOUT = 100;
export const DEFAULT_CHOICES_COUNT = 3;
export const TRIGGER_ALWAYS_ON_CONDITION = '';
export const TRIGGER_ALWAYS_ON_TEXT = 'Always On';
// z-index
export const HEADER_Z_INDEX = 'z-50';
export const PANEL_Z_INDEX = 'z-30';
export const PREVIEW_CARD_Z_INDEX = 'z-30';
export const INNER_INFOBOX_Z_INDEX = 'z-[33]';
