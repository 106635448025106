import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextHighlighter from './textHighlighter';

const styles = makeStyles({
  singleCardItem: {
    marginRight: 10,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },

  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '135%',
    color: '#515151',
  },

  bullet: {
    fontSize: 17,
    color: '#000000',
    marginRight: '10px',
  },
});

const TextFormatter = (props) => {
  const classes = styles();

  let hasBullet = false;
  // eslint-disable-next-line
  if (props.text.includes('**')) {
    hasBullet = true;
  }

  // split string with multiple colons
  const cleanArray = (array) => {
    return array.filter((item) => item);
  };

  // bolding characters in the info box
  const boldCharacter = (data) => {
    let splititem = data.split(/(:)/);
    let splitedItemDeeply = [];

    for (let i = 0; i < splititem.length; i++) {
      const splitItemChild = splititem[i].split(' ');
      for (let j = 0; j < splitItemChild.length; j++) {
        if (splitItemChild[j] !== ':' && splitItemChild[j] !== '') {
          splitItemChild[j] = ' ' + splitItemChild[j] + ' ';
        }
        splitedItemDeeply.push(splitItemChild[j]);
      }
    }

    const itemInCleanArray = cleanArray(splitedItemDeeply);
    let copyItemInCleanArray = [...itemInCleanArray];

    for (let i = 0; i < itemInCleanArray.length; i++) {
      if (itemInCleanArray[i + 2]) {
        if (itemInCleanArray[i] !== ':') {
          if (itemInCleanArray[i + 1] === itemInCleanArray[i + 2]) {
            copyItemInCleanArray[i] = `<b class="boldItem">${copyItemInCleanArray[i]}</b>`;
            copyItemInCleanArray[i + 1] = '';
            copyItemInCleanArray[i + 2] = '';
          }
        }
      }
    }

    copyItemInCleanArray = cleanArray(copyItemInCleanArray);
    return copyItemInCleanArray.join('');
  };

  // const previewString = (singleLine, item) => {
  //   singleLine = singleLine.replace(/::*/, "::");

  //   singleLine =
  //     item === 1 ? singleLine.split("::")[0] : singleLine.split("::")[1];

  //   return singleLine;
  // };

  return (
    <div className={classes.singleCardItem}>
      {props.showMore ? (
        <div>
          {' '}
          {props.shortDescription &&
            props.shortDescription.split('\n').map((sentence, key) => {
              var singleLine = sentence;
              var theLineHasBullet = false;

              if (singleLine.includes('**')) {
                singleLine = singleLine.replaceAll('**', '');
                theLineHasBullet = true;
              }
              return (
                <Typography
                  key={key}
                  className={classes.text}
                  gutterBottom
                  variant='h5'
                  component='p'
                >
                  {/* {!hasBullet && (
              <Typography
                className={classes.bullet}
                gutterBottom
                variant='h5'
                component='span'
              >
                ●︎{' '}
              </Typography>
            )} */}

                  {hasBullet && theLineHasBullet && (
                    <Typography
                      className={classes.bullet}
                      gutterBottom
                      variant='h5'
                      component='span'
                    >
                      ●︎{' '}
                    </Typography>
                  )}

                  {sentence.split('::').length <= 1
                    ? [
                        theLineHasBullet || !hasBullet ? (
                          <Typography
                            key={`${key}-3`}
                            style={{ fontSize: 17 }}
                            gutterBottom
                            variant='h5'
                            component='span'
                          >
                            <TextHighlighter text={singleLine} />
                          </Typography>
                        ) : (
                          <Typography
                            key={`${key}-4`}
                            style={{ fontSize: 17 }}
                            gutterBottom
                            variant='h5'
                            component='span'
                          >
                            <TextHighlighter text={singleLine} />
                          </Typography>
                        ),
                      ]
                    : [
                        <Typography
                          gutterBottom
                          variant='h5'
                          component='span'
                          key={`${key}-2`}
                          style={{ fontSize: 17 }}
                        >
                          {/* {singleLine.split('::')[1]} */}
                          <div
                            style={{ display: 'inline-block' }}
                            dangerouslySetInnerHTML={{
                              __html: boldCharacter(singleLine),
                            }}
                          />
                        </Typography>,
                        // <Typography
                        //   gutterBottom
                        //   variant="h5"
                        //   component="span"
                        //   key={`${key}-1`}
                        //   style={{ fontWeight: "bold", fontSize: 17 }}
                        // >
                        //   <TextHighlighter text={previewString(singleLine, 1)} />
                        // </Typography>,

                        // <Typography
                        //   gutterBottom
                        //   variant="h5"
                        //   component="span"
                        //   key={`${key}-2`}
                        //   style={{ fontSize: 17 }}
                        // >
                        //   {/* {singleLine.split('::')[1]} */}
                        //   <TextHighlighter text={previewString(singleLine, 2)} />
                        // </Typography>,
                      ]}
                </Typography>
              );
            })}{' '}
          <Divider style={{ marginTop: 15, marginBottom: 20, color: '#E4E4E4' }} />{' '}
          {props.longDescription &&
            props.longDescription.split('\n').map((sentence, key) => {
              var singleLine = sentence;
              var theLineHasBullet = false;

              if (singleLine.includes('**')) {
                singleLine = singleLine.replaceAll('**', '');
                theLineHasBullet = true;
              }
              return (
                <Typography
                  key={key}
                  className={classes.text}
                  gutterBottom
                  variant='h5'
                  component='p'
                >
                  {/* {!hasBullet && (
              <Typography
                className={classes.bullet}
                gutterBottom
                variant='h5'
                component='span'
              >
                ●︎{' '}
              </Typography>
            )} */}

                  {hasBullet && theLineHasBullet && (
                    <Typography
                      className={classes.bullet}
                      gutterBottom
                      variant='h5'
                      component='span'
                    >
                      ●︎{' '}
                    </Typography>
                  )}

                  {sentence.split('::').length <= 1
                    ? [
                        theLineHasBullet || !hasBullet ? (
                          <Typography
                            key={`${key}-3`}
                            style={{ fontSize: 17 }}
                            gutterBottom
                            variant='h5'
                            component='span'
                          >
                            <TextHighlighter text={singleLine} />
                          </Typography>
                        ) : (
                          <Typography
                            key={`${key}-4`}
                            style={{ fontSize: 17 }}
                            gutterBottom
                            variant='h5'
                            component='span'
                          >
                            <TextHighlighter text={singleLine} />
                          </Typography>
                        ),
                      ]
                    : [
                        <Typography
                          gutterBottom
                          variant='h5'
                          component='span'
                          key={`${key}-2`}
                          style={{ fontSize: 17 }}
                        >
                          {/* {singleLine.split('::')[1]} */}
                          <div
                            style={{ display: 'inline-block' }}
                            dangerouslySetInnerHTML={{
                              __html: boldCharacter(singleLine),
                            }}
                          />
                        </Typography>,
                        // <Typography
                        //   gutterBottom
                        //   variant="h5"
                        //   component="span"
                        //   key={`${key}-1`}
                        //   style={{ fontWeight: "bold", fontSize: 17 }}
                        // >
                        //   <TextHighlighter text={previewString(singleLine, 1)} />
                        // </Typography>,

                        // <Typography
                        //   gutterBottom
                        //   variant="h5"
                        //   component="span"
                        //   key={`${key}-2`}
                        //   style={{ fontSize: 17 }}
                        // >
                        //   {/* {singleLine.split('::')[1]} */}
                        //   <TextHighlighter text={previewString(singleLine, 2)} />
                        // </Typography>,
                      ]}
                </Typography>
              );
            })}{' '}
        </div>
      ) : (
        props.text.split('\n').map((sentence, key) => {
          var singleLine = sentence;
          var theLineHasBullet = false;

          if (singleLine.includes('**')) {
            singleLine = singleLine.replaceAll('**', '');
            theLineHasBullet = true;
          }
          return (
            <React.Fragment>
              <Typography
                key={key}
                className={classes.text}
                gutterBottom
                variant='h5'
                component='p'
              >
                {/* {!hasBullet && (
              <Typography
                className={classes.bullet}
                gutterBottom
                variant='h5'
                component='span'
              >
                ●︎{' '}
              </Typography>
            )} */}

                {hasBullet && theLineHasBullet && (
                  <Typography className={classes.bullet} gutterBottom variant='h5' component='span'>
                    ●︎{' '}
                  </Typography>
                )}

                {sentence.split('::').length <= 1
                  ? [
                      theLineHasBullet || !hasBullet ? (
                        <Typography
                          key={`${key}-3`}
                          style={{ fontSize: 17 }}
                          gutterBottom
                          variant='h5'
                          component='span'
                        >
                          <TextHighlighter text={singleLine} />
                        </Typography>
                      ) : (
                        <Typography
                          key={`${key}-4`}
                          style={{ fontSize: 17 }}
                          gutterBottom
                          variant='h5'
                          component='span'
                        >
                          <TextHighlighter text={singleLine} />
                        </Typography>
                      ),
                    ]
                  : [
                      <Typography
                        gutterBottom
                        variant='h5'
                        component='span'
                        key={`${key}-2`}
                        style={{ fontSize: 17 }}
                      >
                        {/* {singleLine.split('::')[1]} */}
                        <div
                          style={{ display: 'inline-block' }}
                          dangerouslySetInnerHTML={{
                            __html: boldCharacter(singleLine),
                          }}
                        />
                      </Typography>,
                      // <Typography
                      //   gutterBottom
                      //   variant="h5"
                      //   component="span"
                      //   key={`${key}-1`}
                      //   style={{ fontWeight: "bold", fontSize: 17 }}
                      // >
                      //   <TextHighlighter text={previewString(singleLine, 1)} />
                      // </Typography>,

                      // <Typography
                      //   gutterBottom
                      //   variant="h5"
                      //   component="span"
                      //   key={`${key}-2`}
                      //   style={{ fontSize: 17 }}
                      // >
                      //   {/* {singleLine.split('::')[1]} */}
                      //   <TextHighlighter text={previewString(singleLine, 2)} />
                      // </Typography>,
                    ]}
              </Typography>
            </React.Fragment>
          );
        })
      )}
      {props.text && props.longDescription && props.longDescription.length > 1 && (
        <Button
          onClick={props.toggleShowMore}
          style={{
            color: '#08A88E',
            textTransform: 'none',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '16px',
            paddingLeft: '0px',
          }}
        >
          {' '}
          {props.showMore === false && '(read more)'}{' '}
        </Button>
      )}
    </div>
  );
};

export default TextFormatter;
