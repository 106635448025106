import { Extension } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { getEncoding } from 'js-tiktoken';

const encoding = getEncoding('cl100k_base');

interface CharacterNLineCountOptions {
  /**
   * The maximum number of characters that should be allowed. Defaults to `0`.
   */
  charLimit: number | null | undefined;
  /**
   * The maximum number of lines that should be allowed. Defaults to `0`.
   */
  lineLimit: number | null | undefined;
  /**
   * The mode by which the size is calculated. Defaults to 'textSize'.
   */
  mode: 'textSize' | 'nodeSize' | 'tokenSize';
}

interface CharacterNLineCountStorage {
  /**
   * Get the number of characters for the current document.
   */
  characters: (options?: {
    node?: ProseMirrorNode;
    mode?: 'textSize' | 'nodeSize' | 'tokenSize';
  }) => number;

  /**
   * Get the number of words for the current document.
   */
  words: (options?: { node?: ProseMirrorNode }) => number;
  /**
   * Get the number of lines for the current document.
   */
  lines: (options?: { node?: ProseMirrorNode }) => number;
}

export const CharacterNLineCount = Extension.create<
  CharacterNLineCountOptions,
  CharacterNLineCountStorage
>({
  name: 'characterNLineCount',

  addOptions() {
    return {
      charLimit: null,
      lineLimit: null,
      mode: 'textSize',
    };
  },

  addStorage() {
    return {
      lines: () => 0,
      words: () => 0,
      characters: () => 0,
    };
  },

  onBeforeCreate() {
    this.storage.characters = (options) => {
      const node = options?.node || this.editor.state.doc;
      const mode = options?.mode || this.options.mode;

      switch (mode) {
        case 'nodeSize':
          return node.nodeSize;
        case 'tokenSize':
          const edText = this.editor.getText();
          return encoding.encode(edText).length;
        case 'textSize':
        default:
          const text = node.textBetween(0, node.content.size, undefined, ' ');
          return text.length;
      }
    };

    this.storage.words = (options) => {
      const node = options?.node || this.editor.state.doc;
      const text = node.textBetween(0, node.content.size, ' ', ' ');
      const words = text.split(' ').filter((word) => word !== '');

      return words.length;
    };

    this.storage.lines = (options) => {
      const node = options?.node || this.editor.state.doc;
      return node.childCount;
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('characterCount'),
        filterTransaction: (transaction, state) =>
          filterTransaction(transaction, state, this.options.charLimit, this.storage.characters),
      }),
      new Plugin({
        key: new PluginKey('LineCount'),
        filterTransaction: (transaction, state) =>
          filterTransaction(transaction, state, this.options.lineLimit, this.storage.lines),
      }),
    ];
  },
});

const filterTransaction = (transaction, state, limit, storageFn) => {
  // Nothing has changed or no limit is defined. Ignore it.
  if (!transaction.docChanged || limit === 0 || limit === null || limit === undefined) {
    return true;
  }

  const oldSize = storageFn({ node: state.doc });
  const newSize = storageFn({ node: transaction.doc });

  // Everything is in the limit. Good.
  if (newSize <= limit) {
    return true;
  }

  // The limit has already been exceeded but will be reduced.
  if (oldSize > limit && newSize > limit && newSize <= oldSize) {
    return true;
  }

  // The limit has already been exceeded and will be increased further.
  if (oldSize > limit && newSize > limit && newSize > oldSize) {
    return false;
  }

  const isPaste = transaction.getMeta('paste');

  // Block all exceeding transactions that were not pasted.
  if (!isPaste) {
    return false;
  }

  // For pasted content, we try to remove the exceeding content.
  const pos = transaction.selection.$head.pos;
  const over = newSize - limit;
  const from = pos - over;
  const to = pos;

  // It’s probably a bad idea to mutate transactions within `filterTransaction`
  // but for now this is working fine.
  transaction.deleteRange(from, to);

  // In some situations, the limit will continue to be exceeded after trimming.
  // This happens e.g. when truncating within a complex node (e.g. table)
  // and ProseMirror has to close this node again.
  // If this is the case, we prevent the transaction completely.
  const updatedSize = storageFn({ node: transaction.doc });

  if (updatedSize > limit) {
    return false;
  }

  return true;
};
