import { Box, Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledBox = withStyles(() => ({
  root: {
    paddingLeft: '0px',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '50px',
    marginBottom: '30px',
    alignItems: 'center',
  },
}))(Box);

export const Title = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '30px',
    color: '#474747',
  },
}))(Typography);

export const StyledContainer = withStyles(() => ({
  root: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}))(Container);
