import { Button, Select, Switch } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const StyledExpandIcon = styled(ExpandMoreIcon)`
  position: initial;
  margin-right: 10px;
`;

export const StyledEditButton = styled(Label)`
  background: transparent !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #08a88e !important;
  cursor: pointer;
  margin-left: 12px !important;
`;

export const StyledButton = withStyles((theme) => ({
  root: {
    padding: '10px 40px',
    marginLeft: '0.4em',
    background: '#08A88E',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: '#ffffff !important',
    textTransform: 'none',

    '&:hover': {
      background: '#08A88E',
      color: '#ffffff !important',
    },
    '&:focus': {
      background: '#08A88E',
      color: '#ffffff',
    },
  },
  disabled: {
    background: '#F4F4F4',
    color: '#161616 !important',
    opacity: '0.4',
  },
}))(Button);

export const OutputTypeButton = withStyles((theme) => ({
  root: {
    padding: '12px 28px',
    borderRadius: '50px !important',
    fontWeight: 600,
    boxSizing: 'border-box',
    fontSize: '16px',
    marginRight: '1em',
    border: 0,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1) !important',
    textTransform: 'none',
  },
  disabled: {
    opacity: '0.4',
    boxShadow: 'none !important',
  },
}))(Button);

export const StyledSelect = withStyles((theme) => ({
  disabled: {
    background: '#F4F4F4 !important',
    color: '#161616 !important',
    opacity: '0.4 !important',
  },
}))(Select);
interface IOSSwitchProps {
  classes: {
    root: string;
    switchBase: string;
    thumb: string;
    track: string;
    checked: string;
    disabled: string;
    focusVisible: string;
  };
  // Add any other props that the Switch component accepts
  [key: string]: any;
}
export const IOSSwitch = withStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBottom: '-5px',
    marginLeft: '1.5em',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#08A88E',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#08A88E',
      border: '6px solid #fff',
    },
    '&$disabled + $track': {
      backgroundColor: '#DFF5EF !important',
      opacity: 0.5,
    },
  },
  disabled: {},
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: IOSSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={props.disabled}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
