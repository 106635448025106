export const DEFAULT_PROFILE_URL =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

export interface MultiSelectOption {
  value: any;
  label: string;
}

export const ROLE_OPTIONS: MultiSelectOption[] = [
  { label: 'Resident', value: 'Resident' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Attending Physician', value: 'Attending Physician' },
  { label: 'Hospital Admin', value: 'Hospital Admin' },
  { label: 'Medical Student', value: 'Medical Student' },
  { label: 'Non-Medical Student', value: 'Non-Medical Student' },
  { label: 'Registered Nurse', value: 'Registered Nurse' },
  { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
];

export const SPECIALTIES_OPTIONS: MultiSelectOption[] = [
  { label: 'Allergy & Immunology', value: 'Allergy & Immunology' },
  {
    label: 'Anatomic/Clinical Pathology',
    value: 'Anatomic/Clinical Pathology',
  },
  { label: 'Anesthesiology', value: 'Anesthesiology' },
  { label: 'Cardiovascular Disease', value: 'Cardiovascular Disease' },
  {
    label: 'Child & Adolescent Psychiatry',
    value: 'Child & Adolescent Psychiatry',
  },
  { label: 'Clinical Informatics ', value: 'Clinical Informatics ' },
  { label: 'Critical Care Medicine', value: 'Critical Care Medicine' },
  { label: 'Dermatology', value: 'Dermatology' },
  { label: 'Emergency Medicine', value: 'Emergency Medicine' },
  {
    label: 'Endocrinology, Diabetes & Metabolism',
    value: 'Endocrinology,Diabetes & Metabolism',
  },
  {
    label: 'Family Medicine/General Practice',
    value: 'Family Medicine/General Practice',
  },
  { label: 'Gastroenterology', value: 'Gastroenterology' },
  { label: 'General Surgery', value: 'General Surgery' },
  { label: 'Geriatric Medicine', value: 'Geriatric Medicine' },
  { label: 'Hematology & Oncology', value: 'Hematology & Oncology' },
  { label: 'Infectious Disease', value: 'Infectious Disease' },
  { label: 'Internal Medicine', value: 'Internal Medicine' },
  {
    label: 'Internal Medicine/Pediatrics',
    value: 'Internal Medicine/Pediatrics',
  },
  {
    label: 'Interventional Cardiology',
    value: 'Interventional Cardiology',
  },
  {
    label: 'Neonatal-Perinatal Medicine',
    value: 'Neonatal-Perinatal Medicine',
  },
  { label: 'Nephrology', value: 'Nephrology' },
  { label: 'Neurological Surgery', value: 'Neurological Surgery' },
  { label: 'Neurology', value: 'Neurology' },
  { label: 'Neuroradiology', value: 'Neuroradiology' },
  { label: 'Obstetrics & Gynecology', value: 'Obstetrics & Gynecology' },
  { label: 'Ophthalmology', value: 'Ophthalmology' },
  { label: 'Orthopedic Surgery', value: 'Orthopedic Surgery' },
  { label: 'Otolaryngology', value: 'Otolaryngology' },
  {
    label: 'Pain Medicine & Pain Management',
    value: 'Pain Medicine & Pain Management',
  },
  { label: 'Pediatric Cardiology', value: 'Pediatric Cardiology' },
  {
    label: 'Pediatric Hematology/Oncology',
    value: 'Pediatric Hematology/Oncology',
  },
  { label: 'Pediatrics', value: 'Pediatrics' },
  {
    label: 'Physical Medicine & Rehabilitation',
    value: 'Physical Medicine & Rehabilitation',
  },
  { label: 'Plastic Surgery', value: 'Plastic Surgery' },
  { label: 'Preventive Medicine', value: 'Preventive Medicine' },
  { label: 'Psychiatry', value: 'Psychiatry' },
  { label: 'Pulmonary Disease', value: 'Pulmonary Disease' },
  { label: 'Radiation Oncology', value: 'Radiation Oncology' },
  {
    label: 'Radiology & Diagnostic Radiology',
    value: 'Radiology & Diagnostic Radiology',
  },
  { label: 'Rheumatology', value: 'Rheumatology' },
  { label: 'Thoracic Surgery', value: 'Thoracic Surgery' },
  { label: 'Urology', value: 'Urology' },
  {
    label: 'Vascular & Interventional Radiology',
    value: 'Vascular & Interventional Radiology',
  },
  { label: 'Vascular Surgery', value: 'Vascular Surgery' },
  { label: 'Women’s Health', value: 'Women’s Health' },
  { label: 'Other', value: 'Other' },
];
