import { Box, MenuItem, TableCell, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const HeaderCell = withStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#A2A2A2',
    paddingLeft: '40px',
  },
}))(TableCell);

export const MemberName = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    color: '#081F37',
    marginLeft: '20px',
  },
}))(Typography);

export const MemberRole = withStyles(() => ({
  root: {
    fontSize: '18px',
    color: '#525D6A',
  },
}))(Typography);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#08A88E !important',
      color: 'white',
    },
  },
  selected: {
    backgroundColor: '#08A88E',
    color: 'white',
  },
}))(MenuItem);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    paddingLeft: '40px',
    height: 70,
  },
}))(TableCell);

export const RoleContainer = withStyles(() => ({
  root: {
    border: '1px solid #D2D2D2',
    boxSizing: 'border-box',
    borderRadius: '100px',
    minWidth: '115px',
    justifyContent: 'center',
    maxWidth: '130px',
    height: '48px',
    display: 'flex',
    padding: '15px',
    alignItems: 'center',
  },
}))(Box);

export const styles = (theme) => ({
  root: {
    paddingLeft: '0.5vh !important',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    outline: 'none',
    width: '100%',
    color: '#525D6A',
  },
  icon: {
    color: '#A6A6A6',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  test: {
    background: 'red !important',
  },
});
