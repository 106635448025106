import Home from 'components/home/Home';
import Profile from 'components/accounts/profile/Profile';
import { PROFILE_ROUTE } from 'constants';

const IndexcRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: '/',
      component: Home,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: PROFILE_ROUTE,
      component: Profile,
    },
  },
];

export default IndexcRoutes;
