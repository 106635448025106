import { OldNumberFormWrapper } from 'components/numbers/OldNumberFormWrapper';
import OldNumericList from 'components/numerics/OldNumericList';
import { NUMBER_ROUTE } from '../../constants';

const NumericRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: NUMBER_ROUTE,
      component: OldNumericList,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: NUMBER_ROUTE + '/create',
      component: OldNumberFormWrapper,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: NUMBER_ROUTE + '/:numberId',
      component: OldNumberFormWrapper,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: '/NumericForm',
      to: NUMBER_ROUTE + '/create',
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: '/NumericListing',
      to: NUMBER_ROUTE,
    },
  },
];

export default NumericRoutes;
