interface VariablePreviewType {
  type: string;
  variableName: string | undefined;
}

const VariablePreview = ({ type, variableName }: VariablePreviewType) => {
  if (!variableName) return null;

  return (
    <div className='flex w-56 max-w-fit items-center whitespace-nowrap !rounded-sm border border-solid border-gray-300 bg-gray-100 !px-2 !py-1 text-gray-text-weak'>
      <span className='text-caption-1'>{`{x} ${type === 'gpt' ? 'AI Output' : type}`}</span>
      <span className='!mx-1 h-4 w-px bg-gray-text-weak' />
      <span className='truncate text-caption-2'>{variableName}</span>
    </div>
  );
};

interface VariableType {
  type: string;
  name: string | undefined;
}

export const VariablesPreview = ({ variables }: { variables: VariableType[] }) => (
  <div className='!ml-4 !mt-2.5 flex flex-wrap gap-2'>
    {variables.map((variable, index) => (
      <VariablePreview
        type={variable.type}
        variableName={variable.name}
        key={`${variable.name}--${index}`}
      />
    ))}
  </div>
);
