import { ComboBox } from 'components/utils/ComboBox';
import Input from 'components/utils/Input';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { TiptapPopoverLayout } from './tiptap/tiptap-expand-options/TiptapPopoverLayout';

interface AddItemProps {
  open: boolean;
  title?: string;
  classes?: string;
  addTextField?: boolean;
  allowCustomValue?: boolean;
  options: any[];
  createButtonLabel?: string;
  onClose: () => void;
  onCreate?: () => void;
  direction: 'left' | 'right' | 'partial-left';
  onEdit?: (id: string) => void;
  addItem: (option: any) => void;
  addCustomItem?: (option: any) => void;
}

export const AddItem = ({
  open,
  title,
  options,
  classes,
  onEdit,
  addItem,
  onClose,
  onCreate,
  direction,
  addTextField,
  addCustomItem,
  createButtonLabel,
  allowCustomValue = false,
}: AddItemProps) => {
  const [textToDisplay, setTextToDisplay] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    setTextToDisplay('');
    setSelectedOption('');
  }, [open]);

  const optionObj = useMemo(() => {
    if (!selectedOption) return;
    return options.find((option) => option.value === selectedOption);
  }, [selectedOption]);

  const showTextField = addTextField || (allowCustomValue && isEmpty(optionObj));
  const isAddDisabled = !selectedOption || ((showTextField && !textToDisplay) as boolean);

  const onAdd = (event) => {
    event.preventDefault();
    onClose();
    const option = options.find((option) => option.value === selectedOption);
    if (option) {
      addItem({ ...option, textToDisplay });
    } else {
      addCustomItem?.({ selectedOption: selectedOption, textToDisplay });
    }
  };

  const onChange = (optionId: string | number) => {
    setSelectedOption(optionId as string);
    setTextToDisplay(options.find((option) => option.value === optionId).label);
  };

  return (
    <TiptapPopoverLayout
      open={open}
      title={title}
      onAdd={onAdd}
      classes={classes}
      onClose={onClose}
      direction={direction}
      isAddDisabled={isAddDisabled}
    >
      <ComboBox
        onEdit={onEdit}
        options={options}
        onCreate={onCreate}
        selectedValue={selectedOption}
        allowCustomValue={allowCustomValue}
        createButtonLabel={createButtonLabel}
        onChange={onChange}
      />
      {showTextField && (
        <Input
          type='text'
          value={textToDisplay}
          placeholder='Text to display'
          className='!px-3 !py-2.5 text-body-2 text-gray-900'
          onChange={(event) => setTextToDisplay(event.target.value)}
        />
      )}
    </TiptapPopoverLayout>
  );
};
