import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import AnswerPage from './AnswerPage';
import AnswerPageForm from './answerPageForm';

interface AnswerSectionProps {
  answerPages: any;
  onDragEnd: Function;
  resetAnswerPages: Function;
}

export function AnswerSection({ answerPages, onDragEnd, resetAnswerPages }: AnswerSectionProps) {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [answerPageModal, setAnswerPageModal] = useState<boolean>(false);
  const [answerPageId, setAnswerPageId] = useState<any>('');
  const [closeAnswerPage, setCloseAnswerPage] = useState<boolean>(false);

  function toggleAnswerPageModal(id?: any) {
    setAnswerPageModal((prev) => !prev);
    if (typeof id !== 'object') {
      setAnswerPageId(id);
    } else {
      setAnswerPageId(null);
    }
  }

  return (
    <>
      <div className='flex justify-between'>
        <h3 className='font-bold'>Answers</h3>
        {!hasViewOnlyPermission() && (
          <Button
            id='create-button'
            className='right'
            variant='contained'
            onClick={toggleAnswerPageModal}
          >
            Create Answer Page
          </Button>
        )}
      </div>

      <div
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className='wrapper d-flex align-items-stretch'
        style={{ minHeight: '56vh' }}
      >
        <div className='col-md-11'>
          {/* Sliding Panel for Answer Page Form */}
          <SlidingPane
            isOpen={answerPageModal}
            onRequestClose={() => setCloseAnswerPage((prev) => !prev)}
            from='right'
            overlayClassName='light-overlay'
            className='sliding-panel-shadow'
            width='620px'
            hideHeader
          >
            <div className='bg-white'>
              <AnswerPageForm
                moduleId={moduleId}
                answerPageId={answerPageId}
                toggleModal={toggleAnswerPageModal}
                resetState={resetAnswerPages}
                modal={closeAnswerPage}
                closeUiElementPane={() => setCloseAnswerPage((prev) => !prev)}
              />
            </div>
          </SlidingPane>

          <div
            className='board-lists mt-3 grid gap-1 overflow-scroll'
            style={{ gridAutoColumns: '480px', gridAutoFlow: 'column' }}
          >
            <DragDropContext onDragEnd={(result) => onDragEnd(result, answerPages)}>
              {Object.entries(answerPages).map(([columnId, answerPage], index) => {
                return (
                  <div
                    key={columnId}
                    className='board-list'
                    style={{ padding: '10px', borderRadius: '3px' }}
                  >
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <AnswerPage
                            moduleId={moduleId}
                            answerPage={answerPage}
                            toggleModal={toggleAnswerPageModal}
                            resetAnswerPage={resetAnswerPages}
                            provided={provided}
                          />
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
    </>
  );
}
