import { useMutation, useQuery } from '@tanstack/react-query';
import { getTriggers } from 'actions/resources/getTriggers';
import { PostAmbListeningData, PostAmbListeningVariable, ambListeningAPI } from 'api/panels';
import clsx from 'clsx';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { RadioButton } from 'components/utils/RadioButton';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import RequiredMark from 'components/utils/requiredMark';
import { CustomToast } from 'components/utils/toast-message';
import { Body2 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CARD_AMBIENT_LISTENING } from 'constants/variables';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getVariableName } from 'utils/gptUtils';
import { MODULE_TYPES, SAVE_BUTTON } from '../../constants';
import { TriggerComboBox } from './utils/TriggerComboBox';

const AMBIENT_LISTENING_TYPES = [
  { value: 'H_N_P', label: 'H&P', disabled: false },
  { value: 'SIGN_OUT', label: 'Signout', disabled: false },
] as const;

export type AmbientListeningTypeValue = (typeof AMBIENT_LISTENING_TYPES)[number]['value'];

interface AmbListeningFormVariable {
  title: string;
  subtitle: string;
  type: AmbientListeningTypeValue;
  variable: string;
  trigger: number | string;
  module?: number;
  container: number | undefined;
  position: number;
  is_optional: boolean;
}

interface AmbientListeningFormProps {
  ambId: string;
  position: number;
  handleAmbientListeningOptional: (isOptional: boolean) => void;
  handleAddTitle: (title: string) => void;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  handleAddSubtitle?: (subtitle: string) => void;
  handleAddVariable?: (variable: string) => void;
  startPreview: () => void;
  moduleId?: number;
  resetState: () => void;
  container?: number;
  toggleModal: (command?: string) => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}

const AmbientListeningForm = ({
  ambId,
  position,
  handleAmbientListeningOptional,
  handleAddTitle,
  handleSelectedItem,
  handleAddTrigger,
  handleAddSubtitle,
  handleAddVariable,
  startPreview,
  moduleId,
  resetState,
  container,
  toggleModal,
  modal,
  closeUiElementPane,
}: AmbientListeningFormProps) => {
  const [isVarLoading, setVarLoading] = useState(false);
  const triggerState = useSelector((state: any) => state.triggerState);
  const alwayOnTrigger = triggerState.triggers.find((data) => data.title === 'Always On');

  const { data: ambData, isLoading: isAmbLoading } = useQuery(
    ['ambientListening', ambId],
    ambListeningAPI.getAmbListening,
    {
      cacheTime: 0,
      enabled: !!ambId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const defaultValues: AmbListeningFormVariable = {
    title: '',
    subtitle: '',
    type: 'H_N_P',
    variable: '',
    trigger: alwayOnTrigger.id,
    module: moduleId,
    container,
    position,
    is_optional: false,
  };

  const { watch, register, handleSubmit, setValue, reset } = useForm<AmbListeningFormVariable>({
    defaultValues,
  });
  const title = watch('title');
  const isOptionalChecked = watch('is_optional', false);

  const { mutate, isLoading: isMutating } = useMutation<
    PostAmbListeningData,
    null,
    PostAmbListeningVariable
  >(
    !ambId
      ? (data) => ambListeningAPI.postAmbListening(data)
      : (data) => ambListeningAPI.putAmbListening(data, ambId),
    {
      onSuccess: (data) => {
        resetState();
        toggleModal();
        modal && closeUiElementPane?.();
      },
    }
  );
  // TODO: remove when redux data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTriggers(MODULE_TYPES.ALGO, null, undefined, moduleId));
  }, []);

  useEffect(() => {
    if (!ambId) {
      startPreview();
      handleSelectedItem();
      handleAddTrigger(alwayOnTrigger.id);
    }
  }, [ambId]);

  useEffect(() => {
    if (!ambData || isAmbLoading) return;
    reset({
      title: ambData.title,
      subtitle: ambData.subtitle,
      type: ambData.type,
      variable: ambData.variable_item?.name,
      module: ambData.module,
      container: ambData.container,
      trigger: ambData.trigger || '',
      position: ambData.position,
      is_optional: ambData.is_optional,
    });
    handleAmbientListeningOptional(ambData.is_optional);
    handleAddTitle(ambData.title);
    handleAddSubtitle?.(ambData.subtitle);
    handleAddVariable?.(ambData.variable_item?.name);
    handleAddTrigger(ambData.trigger || '');
  }, [ambData, isAmbLoading]);

  const setVariableName = (variable: string) => {
    setValue('variable', variable);
    handleAddVariable?.(variable);
  };

  const setTrigger = (value: string | number) => {
    setValue('trigger', value);
    handleAddTrigger(value);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  const onSubmit = async (data: AmbListeningFormVariable) => {
    if (isMutating) return;

    const title = watch('title');
    const variable = watch('variable');
    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the title field.' });
    } else {
      setVarLoading(true);
      if (!variable) {
        const variable = await getVariableName(title);
        data['variable'] = variable;
      }

      mutate(data);
    }
    modal && closeUiElementPane?.();
  };

  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
    candidate_trigger: trigger.candidate_trigger,
  }));

  return (
    <div className='mt-14 h-[97%] overflow-visible'>
      <div>
        <Header className='!px-0 !pr-3' title={CARD_AMBIENT_LISTENING} toggleModal={toggleModal} />
        <form
          autoComplete='off'
          onKeyDown={onKeyDown}
          onSubmit={handleSubmit(onSubmit)}
          className='flex h-full flex-col pb-0'
        >
          <div className='space-y-[5px]'>
            <div className='flex gap-1'>
              <Body2 className='text-gray-900'>Trigger</Body2>
              <RequiredMark />
            </div>
            <TriggerComboBox
              onChange={setTrigger}
              options={triggerOptions}
              selectedValue={watch('trigger')}
            />
          </div>

          <div className='!mt-8'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Title</Body2>
                <RequiredMark />
              </div>
              <Input
                tabIndex={1}
                required
                maxLength={250}
                placeholder='AI Scribe'
                {...register('title', {
                  maxLength: 255,
                  required: true,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => handleAddTitle(e.target.value),
                })}
              />
            </div>
            <div className='text-right text-[12px]'>{title.length}/250 characters</div>
          </div>

          <div className='!mt-3'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Subtitle</Body2>
              </div>
              <Input
                maxLength={250}
                {...register('subtitle', {
                  maxLength: 255,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    handleAddSubtitle?.(e.target.value);
                  },
                })}
                placeholder='e.g. Start recording to transcribe conversations.'
              />
            </div>
            <div className='text-right text-[12px]'>{watch('subtitle').length}/500 characters</div>
          </div>

          <div className='!mb-6 !mt-3'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Note Type</Body2>
                <RequiredMark />
              </div>
              <div className='space-y-1'>
                {AMBIENT_LISTENING_TYPES.map((item) => (
                  <Label
                    key={item.value}
                    className={clsx(
                      'flex gap-2 rounded bg-gray-100 px-2 py-3',
                      item.disabled && '!cursor-default'
                    )}
                  >
                    <RadioButton {...register('type')} {...item} />
                    <span className={clsx(item.disabled ? 'text-gray-300' : 'text-gray-900')}>
                      {item.label}
                    </span>
                  </Label>
                ))}
              </div>
            </div>
          </div>

          <VariableAutoGenerator
            question={title}
            tagTitle='Scribe'
            variable={watch('variable')}
            setVariableName={setVariableName}
          />
          <div className='!mt-3'>
            <Label className='ml-[-4px] flex w-full cursor-pointer items-center space-x-[8px] pt-[10px]'>
              <Checkbox
                type='checkbox'
                checked={isOptionalChecked}
                {...register('is_optional', {
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    handleAmbientListeningOptional(e.target.checked);
                  },
                })}
              />
              <span>Make this optional</span>
            </Label>
          </div>

          <div className='h-[100px] w-full bg-white' />
          <div className='sticky bottom-0 right-0 z-10 flex h-[100px] w-full items-center bg-white'>
            <div className='!mb-2 !mr-8 ml-auto flex items-center'>
              <div className='flex gap-3'>
                <Button.Reverse
                  onClick={() => toggleModal()}
                  className='!px-2 !py-2.5 font-bold'
                  type='button'
                >
                  Cancel
                </Button.Reverse>
                <Button type='submit' className='!px-6 !py-2.5 font-bold' disabled={!title}>
                  {isVarLoading || isMutating ? <LoadingSpinner size='small' /> : SAVE_BUTTON}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ConfirmModal
        preset='unsaved'
        open={modal!}
        toggleModal={closeUiElementPane!}
        toggleModalPanel={toggleModal}
        handleSubmit={handleSubmit(onSubmit)}
        panelForm
      />
    </div>
  );
};

export default AmbientListeningForm;
