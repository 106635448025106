import * as React from 'react';

function GalleryIcon(props) {
  return (
    <svg width='56' height='40' viewBox='0 0 56 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M52.5892 0C54.246 0 55.5892 1.34315 55.5892 3V37.0001C55.5892 38.657 54.246 40.0001 52.5892 40.0001H3.41113C1.75428 40.0001 0.411133 38.657 0.411133 37.0001V3C0.411133 1.34315 1.75428 0 3.41113 0H52.5892Z'
        fill='#C6F5D9'
      />
      <path
        d='M44.2233 5.30046C43.207 5.086 42.1475 5.24353 41.2375 5.74438C40.3276 6.24524 39.6277 7.05613 39.2652 8.0295C38.9028 9.00287 38.9018 10.074 39.2625 11.048C39.6232 12.0221 40.3216 12.8342 41.2306 13.3367C42.1397 13.8392 43.1989 13.9987 44.2156 13.7861C45.2322 13.5735 46.1388 13.0029 46.7703 12.1782C47.4017 11.3536 47.7162 10.3296 47.6563 9.29266C47.5965 8.25571 47.1663 7.27471 46.4442 6.52817C45.842 5.90773 45.0689 5.48039 44.2233 5.30046Z'
        fill='white'
      />
      <path
        d='M32.1604 28.08C28.5817 23.1034 25.0017 18.1276 21.4204 13.1523L5.49365 35.3044H26.9737C28.7026 32.8975 30.4328 30.4914 32.1644 28.0859'
        fill='white'
      />
      <path
        d='M39.9357 20C36.2663 25.1 32.5983 30.2007 28.9316 35.3021H50.9417C47.275 30.2007 43.6063 25.1 39.9357 20Z'
        fill='white'
      />
    </svg>
  );
}

export default GalleryIcon;
