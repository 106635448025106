import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { TinyUser, authAtom, useAuthentication } from 'hooks/useAuthentication';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { MY_INFO_API_URL } from '../constants';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  appId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const useOnAuthStateChanged = () => {
  const { reset, authentication } = useAuthentication();
  const setAuthentication = useSetAtom(authAtom);
  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (authentication.auth_type === 'knox' && authentication.isLoggedIn) return;
      if (user) {
        const idToken = await user.getIdToken(true);
        const userInfo = await axios
          .get<TinyUser>(MY_INFO_API_URL, { headers: { Authorization: `Bearer ${idToken}` } })
          .then((res) => res.data);
        setAuthentication({
          auth_type: 'firebase',
          google_login: true,
          token: 'avomd',
          token_expiry: '',
          user: userInfo,
          isLoggedIn: true,
          providerId: user.providerData.some((provider) => provider.providerId === 'password')
            ? 'password'
            : 'google.com',
        });
      } else {
        reset();
      }
    });
  }, []);
};
