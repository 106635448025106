import { MainLayout } from 'components/layout/MainLayout';
import { HTMLAttributes } from 'react';
import { ModuleList } from '../components/main/ModuleList';

interface MainProps extends HTMLAttributes<HTMLDivElement> {}

const Main = ({}: MainProps) => {
  return (
    <MainLayout>
      <ModuleList />
    </MainLayout>
  );
};

export default Main;
