import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import Avatar from 'components/utils/Avatar';
import Button from 'components/utils/Button';
import DeletableBadge from 'components/utils/DeletableBadge';
import { Fragment, useEffect, useState } from 'react';
import { PermissionMap, PermissionOptions } from '../../../constants';
import MemberPermissionList from './MemberPermissionList';
import { useTeam } from '../../../hooks/useTeam';

interface SelectedMember {
  team_member_id: number;
  name: string;
}

export interface IMemberPermission extends SelectedMember {
  permission_type: string;
}

interface PermissionDropdownProps {
  defaultMembers: IMemberPermission[];
  defaultPermission?: string;
  onChange?: (members: IMemberPermission[]) => void;
  onDefaultPermissionChange: (permission: string) => void;
}

const PermissionDropdown = ({
  defaultMembers,
  defaultPermission,
  onChange,
  onDefaultPermissionChange,
}: PermissionDropdownProps) => {
  const [ids, setIds] = useState<number[]>([]);
  const [permission, setPermission] = useState<string>(PermissionMap.can_view.value);
  const [selectedMembers, setSelectedMembers] = useState<SelectedMember[]>([]);
  const [members, setMembers] = useState<IMemberPermission[]>(defaultMembers);
  const [memberIds, setMemberIds] = useState<number[]>([]);
  const { team } = useTeam();

  useEffect(() => {
    const selectedIds = new Set(ids);
    const newMembers = team.teammates
      .filter((member) => selectedIds.has(member.id))
      .map((member) => ({ team_member_id: member.id, name: member.user.name }));
    setSelectedMembers(newMembers);
  }, [ids]);

  useEffect(() => {
    setMemberIds(members.map((member) => member.team_member_id));
    onChange?.(members);
  }, [members]);

  const addmember = () => {
    const payload: IMemberPermission[] = members.concat(
      selectedMembers.map((member) => ({
        ...member,
        permission_type: permission,
      }))
    );
    setMembers(payload);
    setIds([]);
    onChange?.(payload);
  };

  return (
    <div>
      <div className='relative flex gap-2'>
        <Listbox as='div' className='relative w-3/4' multiple onChange={setIds} value={ids}>
          {({ open }) => (
            <>
              <Listbox.Button
                className={clsx(
                  'focus:bg-transparent', // due to material ui
                  'flex w-full items-center justify-between rounded border border-gray-500 px-[12px] py-[10px]'
                )}
              >
                {selectedMembers.length > 0 ? (
                  <div className='flex flex-wrap gap-2'>
                    {selectedMembers.map((member, idx) => (
                      <DeletableBadge
                        key={idx}
                        label={member.name}
                        value={member.team_member_id}
                        onDelete={(value) => setIds((prev) => prev.filter((id) => id !== value))}
                      />
                    ))}
                  </div>
                ) : (
                  <div className='text-gray-500'>Add member by name or email</div>
                )}
                <div className={clsx('transition-all', { 'rotate-180': open })}>
                  <DropdownArrowIcon />
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Listbox.Options className='absolute z-10 max-h-[300px] w-full overflow-y-auto rounded border border-gray-500 bg-white'>
                  {team.teammates
                    .filter((member) => !memberIds.includes(member.id))
                    .map((member, idx) => (
                      <Listbox.Option
                        key={idx}
                        value={member.id}
                        className={({ active }) =>
                          clsx({
                            'bg-primary-200': active,
                          })
                        }
                      >
                        {({ selected }) => (
                          <div className='flex cursor-pointer items-center justify-between px-[12px] py-[10px]'>
                            <div className='flex items-center space-x-4'>
                              <Avatar name={member.user.name} />
                              <div>
                                <div className='font-bold text-gray-900'>{member.user.name}</div>
                                <div className='text-sm text-gray-500'>{member.user.email}</div>
                              </div>
                            </div>
                            {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        <Listbox as='div' className='relative w-1/4' onChange={setPermission}>
          {({ open }) => (
            <>
              <Listbox.Button
                className={clsx(
                  'focus:bg-transparent', // due to material ui
                  'flex w-full items-center justify-between rounded border border-gray-500 px-[12px] py-[10px]'
                )}
              >
                <div className='text-gray-500'>{PermissionMap[permission].label}</div>
                <div className={clsx('transition-all', { 'rotate-180': open })}>
                  <DropdownArrowIcon />
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Listbox.Options className='absolute z-10 max-h-[200px] w-[200%] overflow-y-auto rounded border border-gray-500 bg-white'>
                  {PermissionOptions.map((per, idx) => (
                    <Listbox.Option
                      key={idx}
                      value={per.value}
                      className={({ active }) =>
                        clsx('cursor-pointer px-[12px] py-[10px]', {
                          'bg-primary-200': active,
                        })
                      }
                    >
                      <div>{per.label}</div>
                      <div className='text-sm text-gray-500'>{per.subtitle}</div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        <div className='absolute right-0 h-full w-28 translate-x-full pl-3'>
          <Button className='h-full' type='button' onClick={addmember}>
            Add
          </Button>
        </div>
      </div>
      <MemberPermissionList
        members={members}
        onChange={setMembers}
        onDefaultPermissionChange={onDefaultPermissionChange}
        defaultPermission={defaultPermission}
      />
    </div>
  );
};

export default PermissionDropdown;
