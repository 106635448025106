import { Chip, Paper, Radio, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { ModalFooter, Modal, modalBody} from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import { default as FormGroupMUI } from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from 'assets/icons/linkSVG';
import axios from 'axios';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { Form, Input } from 'reactstrap';
import styled from 'styled-components';
import { checkEmail } from 'utils/utilityFunctions';
import { ROLES } from '../../api/team';
import { TEAM_INVITATION_API_URL } from '../../constants';
import { withAuthentication } from '../../hooks/useAuthentication';
import { withTeam } from '../../hooks/useTeam';

const StyledBox = withStyles((theme) => ({
  root: {
    width: '862px',
    minHeight: '500px',
    boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.08)',
    borderRadius: '20px',
    backgroundColor: 'white',
    //padding: '15px',
  },
}))(Box);
const Footer = withStyles((theme) => ({
  root: {
    minHeight: '137px',
    background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    //padding: '15px',
  },
}))(Box);
const StyledRadio = withStyles((theme) => ({
  checked: {
    color: ' #08A88E !important',

    //padding: '15px',
  },
}))(Radio);
const Email = withStyles((theme) => ({
  root: {
    border: '1px solid #E4E4E4',
    boxSizing: 'border-box',
    borderRadius: '3px',
    margin: '4px',
    background: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#5C5C5C',

    //padding: '15px',
  },
}))(Chip);

const InviteButton = styled(Button)({
  width: '138px',
  height: '46px',
  // textTransform: 'none',
  background: '#08A88E !important',
  borderRadius: '6px',
  color: 'white !important',
  fontSize: '14px',
  lineHeight: '20px',
  marginLeft: '10px',
  boxShadow: 'none',

  '&:hover': {
    color: 'white !important',
    boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    background: '#08A88E !important',
  },
  '&:focus': {
    color: 'white !important',
    background: '#08A88E !important',
  },
});
const StyledInput = styled(Input)`
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */

  color: #494949;

  ::placeholder {
    opacity: 0.2;
  }
`;
const InviteLabel = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '18px',
    color: '#494949',
    marginBottom: '15px',
    //padding: '15px',
  },
}))(Typography);
const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    marginBottom: '0px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#636363',
    //padding: '15px',
  },
}))(FormControlLabel);
class InviteToPlatformModal extends Component {
  constructor(props) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = this.getInitialState();
  }
  getInitialState = () => ({
    emails: [],
    value: '',
    selectedRole: 'M',
    url: this.props.platform ? `${window.location.host}/try-avo-builder` : '',
    inviteCode: '',
    buttonText: this.props.platform ? 'Copy' : 'Generate Link',
    currentButtonStatus: this.props.platform ? 2 : 1,
    isLoading: false,

    /* state props */
  });

  removeEmail = (email) => {
    let emails = this.state.emails;
    for (var i = 0; i < emails.length; i++) {
      if (emails[i] === email) {
        emails.splice(i, 1);
      }
      this.setState({ emails: emails });
    }
  };

  copySuccess = () => {
    this.setState({ currentButtonStatus: 3, buttonText: 'Copied!' });
    toast.success(CustomToast, { data: 'Link is copied into the clipboard' });
  };

  handleChange = (event) => this.setState({ selectedRole: event.target.value });

  handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      var email = this.state.value.trim().toLocaleLowerCase();

      if (email) {
        this.setState({
          emails: [...this.state.emails, email],
          value: '',
        });
      }
    }
  };

  appendLink = async (code) => {
    const link = this.props.platform
      ? window.location.host + '/platform-invitation/' + code
      : window.location.host + '/invitation/' + code;
    return link;
  };

  onChange = (e) => this.setState({ value: e.target.value.trim().toLocaleLowerCase() });

  generateGenericLink = () => {
    const user = this.props.authentication.user;

    let payload = this.props.platform
      ? {
          role: null,
          sender: user?.name,
          user_type: user?.user_type,
        }
      : {
          team: this.props.team.id,
          sender: user?.name,
          user_type: user?.user_type,
          role: this.state.selectedRole,
        };

    axios.post(TEAM_INVITATION_API_URL, payload).then((res) => {
      this.setState({
        inviteCode: res.data.invite_code,
        buttonText: 'Copy',
        currentButtonStatus: 2,
      });
      this.appendLink(res.data.invite_code).then((link) => this.setState({ url: link }));
    });
  };

  handleSingleEmail = async () => {
    if (this.state.value) {
      const email = this.state.value;

      if (email) {
        this.setState((prev) => ({
          emails: [...prev.emails, email],
          value: '',
        }));
      }
    }
  };

  inviteMembers = (e) => {
    e.preventDefault();

    this.handleSingleEmail().then(() => {
      let emails = this.state.emails;
      if (emails.length === 0) {
        toast.warning(CustomToast, {
          data: 'Please provide an email address to send the invitation.',
        });
        return;
      }

      for (let i = 0; i < emails.length; i++) {
        if (!checkEmail(emails[i])) {
          toast.error(CustomToast, { data: 'Invalid Email' + emails[i] });
          return;
        }
      }

      this.setState({ isLoading: true });
      const team = this.props.team.id;
      const platformInvite = this.props.platform;

      let payload = platformInvite
        ? {
            emails: this.state.emails,
            role: null,
          }
        : {
            emails: this.state.emails,
            team: team,
            role: this.state.selectedRole,
          };

      axios.post(TEAM_INVITATION_API_URL, payload).then(() => {
        this.setState({ isLoading: false, emails: [], value: '' });
        toast.success(CustomToast, { data: 'Invitation Sent!' });
      });
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  toggleModal = () => {
    this.setState(this.getInitialState());
    this.props.toggle();
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.toggleModal}
        className='d-flex align-items-center justify-content-center'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: 'light-overlay',
        }}
        closeAfterTransition
      >
        <Fade in={this.props.open}>
          <Form onKeyPress={this.handleKeyDown} className='trigger' onSubmit={this.inviteMembers}>
            <Backdrop
              style={{
                zIndex: 2000,
                color: '#08A88E',
                background: 'rgba(214, 216, 219, 0.9)',
              }}
              open={this.state.isLoading}
            >
              <CircularProgress style={{ color: '#08A88E' }} />
            </Backdrop>

            <Paper>
              <StyledBox className='d-flex flex-column'>
                <Box
                  style={{
                    marginLeft: '60px',
                    marginTop: '40px',
                  }}
                >
                  <Typography
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '32px',
                      lineHeight: '44px',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Invite Members
                    <HighlightOffIcon
                      style={{
                        marginLeft: 'auto',
                        marginRight: '60px',
                        color: '#C7C7C7',
                        float: 'right',
                        cursor: 'pointer',
                      }}
                      onClick={this.toggleModal}
                    />
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '15px',
                      lineHeight: '20px',
                      color: '#818181',
                      marginBottom: '46px',
                    }}
                  >
                    Send multiple invites using a COMMA seperated list of values
                  </Typography>

                  {!this.props.platform && (
                    <Box>
                      {' '}
                      <FormGroupMUI
                        row
                        className='align-items-center'
                        value={this.state.selectedRole}
                        onChange={this.handleChange}
                      >
                        <Typography
                          style={{
                            marginRight: '35px',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '18px',
                            lineHeight: '18px',
                            color: '#494949',
                          }}
                        >
                          Select Role
                        </Typography>
                        {this.props.team.current_teammate?.role === ROLES.Admin && (
                          <StyledFormControlLabel
                            control={<StyledRadio />}
                            value='A'
                            label='Admin'
                          />
                        )}
                        <StyledFormControlLabel
                          value='M'
                          control={<StyledRadio />}
                          label='Member'
                        />
                      </FormGroupMUI>{' '}
                    </Box>
                  )}
                  <Box className='d-flex flex-column' style={{ marginTop: '40px' }}>
                    {' '}
                    <InviteLabel for='limit' className='d-flex align-items-center'>
                      {' '}
                      <EmailOutlinedIcon style={{ marginRight: '10px' }} />
                      Invite With Email
                    </InviteLabel>
                    <Box className='d-flex align-items-center flex-row'>
                      <Box
                        style={{
                          width: '611px',
                          minHeight: '46px',
                          background: '#FFFFFF',
                          border: '1px solid #CCCCCC',
                          boxSizing: 'border-box',
                          borderRadius: '5px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {this.state.emails &&
                          this.state.emails.map((email) => (
                            <Email
                              key={email}
                              label={email}
                              onDelete={() => this.removeEmail(email)}
                              deleteIcon={<CloseIcon fontSize='small' />}
                            />
                          ))}
                        <Input
                          name='emails'
                          value={this.state.value}
                          type='text'
                          onChange={this.onChange}
                          style={{
                            display: 'inline-block',
                            border: 'none',
                            margin: '0',
                            marginLeft: '5px',
                          }}
                        />
                      </Box>
                      <InviteButton className='ml-2' variant='contained' type='submit'>
                        Invite
                      </InviteButton>
                    </Box>
                  </Box>
                </Box>
                <Footer style={{ marginTop: 'auto' }}>
                  <Box className='d-flex align-items-start flex-column'>
                    <InviteLabel
                      style={{
                        marginLeft: '60px',
                        marginTop: '25px',
                      }}
                    >
                      <span style={{ marginRight: '11px' }}>
                        <LinkIcon />
                      </span>
                      Invite with link
                    </InviteLabel>
                    <Box className='d-flex flex-row'>
                      <Box
                        style={{
                          width: '611px',
                          height: '46px',
                          background: '#FFFFFF',
                          border: 'none',
                          boxSizing: 'border-box',
                          borderRadius: '5px',
                          marginLeft: '60px',

                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <StyledInput
                          name='emails'
                          value={this.state.url}
                          type='text'
                          style={{
                            display: 'inline-block',
                            border: 'none',
                            marginLeft: '15px',
                            minHeight: '46px',
                            marginBottom: '0px',
                            fontSize: '15px',
                            color: '#000000',
                          }}
                          placeholder='Link will be generated here'
                        />
                      </Box>

                      {this.state.currentButtonStatus === 1 ? (
                        <InviteButton
                          style={{ width: '150px' }}
                          variant='contained'
                          className='ml-2'
                          onClick={this.generateGenericLink}
                        >
                          {this.state.buttonText}
                        </InviteButton>
                      ) : (
                        <CopyToClipboard
                          className='d-inline-block'
                          text={this.state.url}
                          onCopy={this.copySuccess}
                        >
                          <InviteButton style={{ width: '150px' }} variant='contained'>
                            {this.state.buttonText}
                          </InviteButton>
                        </CopyToClipboard>
                      )}
                    </Box>
                  </Box>
                </Footer>
              </StyledBox>
            </Paper>
          </Form>
        </Fade>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default withTeam(withAuthentication(connect(mapStateToProps)(InviteToPlatformModal)));
