import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import AddBasicIcon from 'assets/icons/addBasicIcon';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import { onSortEnd } from 'components/utils/ListItemUtil';
import { Accordion } from 'components/utils/example-n-tools/styles';
import SlidingPane from 'react-sliding-pane';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { onlyAllowNumbers } from 'utils/utilityFunctions';
import CharacterLimit from '../form-input/fieldCharLimit';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    marginBottom: -1,
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    borderBottom: '1px solid #E4E4E4',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid #D3D3D3',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#F4F6F9',
  },
}))(MuiAccordionDetails);

const StyledInput = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 462px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: normal;
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: '20px',
    color: '#A6A6A6',
  },
}));

const SortableItem = SortableElement(
  ({
    item,
    indexNo,
    handleChangeCheckBox,
    handleDeleteClick,
    handleListChange,
    infoboxes,
    moduleId,
    calculatorId,
    type,
  }) => {
    const [expanded, setExpanded] = React.useState('panel1');
    const [createInfobox, setCreateInfobox] = React.useState(false);
    const [updateInfoboxModal, setUpdateInfoboxModal] = React.useState(false);
    const classes = useStyles();

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <Accordion
        square
        expanded={expanded === `panel${indexNo + 1}`}
        onChange={handleChange(`panel${indexNo + 1}`)}
        key={indexNo}
      >
        <AccordionSummary
          aria-controls={`panel${indexNo + 1}d-content`}
          id={`panel${indexNo + 1}d-header`}
        >
          {expanded === `panel${indexNo + 1}` ? (
            <KeyboardArrowDownIcon style={{ color: '#A6A6A6' }} />
          ) : (
            <ChevronRightIcon style={{ color: '#A6A6A6' }} />
          )}
          <Typography
            id={`label${indexNo}`}
            style={{ marginLeft: '5px', color: '#191919', width: '200px', wordBreak: 'break-all' }}
          >
            {item.name}
          </Typography>

          <span style={{ position: 'absolute', right: '1em' }}>
            {'isSelected' in item && (
              <FormControlLabel
                style={{ height: '0px', marginBottom: '-4px' }}
                control={
                  <GreenCheckbox
                    key={indexNo}
                    checked={item.isSelected}
                    // TODO: to remove warning but we may have better way
                    // sortable_id is number but Checkbox props type is string
                    id={item.sortable_id.toString()}
                    onChange={(e) => handleChangeCheckBox(e, item.sortable_id)}
                    name='check-box-item'
                  />
                }
                label='Selected by Default'
              />
            )}
            <DeleteIcon
              style={{ color: 'red' }}
              onClick={(e) => handleDeleteClick(item.sortable_id)}
            />
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={'span'} style={{ width: '549px' }}>
            <label
              style={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: '#969696',
              }}
            >
              Label
            </label>
            <br />
            <StyledInput
              type='text'
              name={`answerInput${indexNo}`}
              key={item.sortable_id}
              value={item.name}
              maxLength={255}
              onChange={(e) => {
                const itemTarget = e.target;
                handleListChange(item.sortable_id, itemTarget.value, 'name');
                // globalStyles overwrite the border class of tailwind and inline styleling
                // therefore using this option to add a border
                if (itemTarget.value === '') {
                  itemTarget.style.setProperty('border', '1px solid #f94b50', 'important');
                } else {
                  itemTarget.style.removeProperty('border');
                }
              }}
            />
            {item.name === '' && (
              <p className='text-caption-2 text-[#f94b50]'>This field is required.</p>
            )}

            <CharacterLimit currentCount={item?.name?.length || 0} totalCount={255} />
            {'coefficient' in item && (
              <React.Fragment>
                <label
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#969696',
                  }}
                >
                  Assigned Value
                </label>
                <br />
                <StyledInput
                  type='number'
                  name='answer-assigned-value'
                  value={item.coefficient}
                  onKeyDown={(e) => onlyAllowNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  className={item.coefficient === '' ? 'empty-input' : ''}
                  onChange={(e) => {
                    const itemTarget = e.target;
                    handleListChange(item.sortable_id, itemTarget.value, 'coefficient');
                    // globalStyles overwrite the border class of tailwind and inline styleling
                    // therefore using this option to add a border
                    if (itemTarget.value === '') {
                      itemTarget.style.setProperty('border', '1px solid #f94b50', 'important');
                    } else {
                      itemTarget.style.removeProperty('border');
                    }
                  }}
                />
                {item.coefficient === '' && (
                  <p className='text-caption-2 text-[#f94b50]'>This field is required.</p>
                )}
              </React.Fragment>
            )}

            {infoboxes && (
              <React.Fragment>
                <label
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#969696',
                  }}
                >
                  Infobox
                </label>
                <br />
                <Box className='d-flex align-items-center flex-row'>
                  <Select
                    name='infobox'
                    value={item.infobox || '-----'}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => handleListChange(item.sortable_id, e.target.value, 'infobox')}
                    className='answerInbox'
                    classes={{ icon: classes.icon }}
                    label='Select Output Value'
                    disableUnderline
                  >
                    <StyledMenuItem value='-----'>Select</StyledMenuItem>
                    {infoboxes.map((infobox) => (
                      <StyledMenuItem key={infobox.id} value={infobox.id}>
                        {infobox.shortened_title}
                      </StyledMenuItem>
                    ))}
                  </Select>

                  <Box
                    onClick={() => {
                      item.infobox && setUpdateInfoboxModal(true);
                    }}
                    style={{
                      marginLeft: '15px',
                      marginRight: '15px',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        width: '32px',
                        height: '18px',
                        fontWeight: 600,
                        fontSize: '18px',
                        color: item.infobox ? '#08A88E' : '#CBCBCB',
                      }}
                    >
                      Edit
                    </Typography>
                  </Box>
                  <Divider orientation='vertical' style={{ height: '20px', marginRight: '10px' }} />
                  <Button
                    variant='text'
                    startIcon={
                      <div style={{ marginBottom: '2px' }}>
                        <AddBasicIcon />
                      </div>
                    }
                    style={{
                      fontStyle: 'normal',
                      width: '150px',
                      fontWeight: 600,
                      fontSize: '18px',
                      textAlign: 'center',
                      textTransform: 'none',
                      color: '#08A88E',
                      marginTop: 5,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCreateInfobox((prevState) => !prevState);
                    }}
                  >
                    New Infobox
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Typography>
        </AccordionDetails>

        <SlidingPane
          isOpen={createInfobox}
          onRequestClose={() => setCreateInfobox((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          className='no-padding add-info-box sliding-panel-shadow'
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              createModal={true}
              type={type}
              toggleModal={() => setCreateInfobox((prevState) => !prevState)}
              savePosition={() => []} // we don't need to call save position here but we have to send it as prop otherwise exception will be thrown, it should return an array
              selectValue={(infobox) => handleListChange(item.sortable_id, infobox.id, 'infobox')}
              isFullHeight={true}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={updateInfoboxModal}
          onRequestClose={() => setUpdateInfoboxModal((prevState) => !prevState)}
          overlayClassName='infobox-overlay-for-zindex'
          from='right'
          className='no-padding add-info-box'
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              isFullHeight={true}
              type={type}
              infoBoxId={item.infobox}
              toggleModal={() => setUpdateInfoboxModal((prevState) => !prevState)}
              savePosition={() => []}
              selectValue={(infobox) => handleListChange(item.id, infobox.id, 'infobox')}
            />
          </div>
        </SlidingPane>
      </Accordion>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    moduleId,
    calculatorId,
    type,
    infoboxes,
    handleChangeCheckBox,
    handleDeleteClick,
    handleListChange,
  }) => {
    return (
      <div>
        {items.map((val, index) => (
          <SortableItem
            key={index}
            index={index}
            indexNo={index}
            item={val}
            moduleId={moduleId}
            calculatorId={calculatorId}
            type={type}
            infoboxes={infoboxes}
            handleChangeCheckBox={handleChangeCheckBox}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

function ListItem(props) {
  const shouldCancelStart = (e) => {
    // e.target.tagName.toUpperCase() == 'INPUT' added for the avoiding drag and drop from input box
    return e.target.id === 'mui-component-select-infobox' ||
      e.target.tagName.toUpperCase() === 'INPUT'
      ? true
      : false;
  };

  return (
    <SortableList
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      infoboxes={props.infoboxes}
      moduleId={props.moduleId}
      calculatorId={props.calculatorId}
      type={props.type}
      handleChangeCheckBox={props.handleChangeCheckBox}
      handleDeleteClick={props.handleDeleteClick}
      handleListChange={props.handleListChange}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
      shouldCancelStart={(e) => shouldCancelStart(e)}
      lockAxis='y'
    />
  );
}

export default ListItem;
