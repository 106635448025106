export default function ToolbarInfoboxIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C17.9946 13.968 13.968 17.9946 9 18ZM1.8 9.1548C1.82046 11.0575 2.5933 12.8748 3.94963 14.2095C5.30595 15.5441 7.13546 16.2875 9.03828 16.2773C10.9411 16.2671 12.7625 15.504 14.1044 14.1549C15.4463 12.8057 16.1996 10.9803 16.1996 9.0774C16.1996 7.17455 15.4463 5.34906 14.1044 3.99993C12.7625 2.6508 10.9411 1.88773 9.03828 1.8775C7.13546 1.86728 5.30595 2.61072 3.94963 3.94535C2.5933 5.27998 1.82046 7.09726 1.8 9V9.1548ZM10.8 13.5H8.1V9.9H7.2V8.1H9.9V11.7H10.8V13.5ZM9.9 6.3H8.1V4.5H9.9V6.3Z'
        fill='#1E1F20'
      />
    </svg>
  );
}
