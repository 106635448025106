import { Box, Popover } from '@material-ui/core';
import { getCalculators } from 'actions/calculators/getCalculators';
import { getTriggers } from 'actions/resources/getTriggers';
import AddIcon from 'assets/icons/addIcon';
import axios from 'axios';
import AppearingSituation from 'components/utils/AppearingSituation';
import SectionDropdown from 'components/utils/SectionDropdown';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import ExampleNToolsList from 'components/utils/example-n-tools/ExampleNToolsList';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Header } from 'components/utils/panels/Header';
import {
  AddButton,
  AddLabel,
  CancelButton,
  CreateButton,
  Examples,
  ToggleButton,
} from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import {
  CANCEL_BUTTON,
  CARD_DESCRIPTION,
  CARD_INTRODUCTION,
  SAVE_BUTTON,
} from 'constants/variables';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup } from 'reactstrap';
import {
  DESCRIPTION_PANEL_API_URL,
  DESCRIPTION_PANEL_CONVERSION_API_URL,
  MODULE_TYPES,
} from '../../constants';
import { FixedRow, UnderlyingElement } from './ChoicePanelForm';

const selectedButton = {
  background: '#08A88E',
  color: 'white',
};

const richTextButtonsShowList = [
  'textStyles',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

// TODO: seems it's only used for intro. merge it with DescriptionPanelBetaForm
class DescriptionPanelForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      infoboxModal: false,
      updateImageModal: false,
      updateInfoboxModal: false,
      toolInfoboxModal: false,
      updateToolInfoboxModal: false,
      textModal: false,
      showCalcTypes: null,
      updateTextModal: false,
      pk: '',
      title: '',
      description_text: '',
      desc_json_tiptap: {},
      new_description_text_content: '',
      prev_text_content: '',
      is_introduction: false,
      imageModal: false,
      images: [],
      examples: [],
      tools: [],
      trigger: '',
      module: '',
      descriptionType: 'action',
      panel_type: 'O', //Panel type is 'O:Original' for beta description panel

      infoboxes: [],
      calculatorModal: false,
      position: null,
      convertModal: false,
    };
    this.debDescTiptapChange = debounce(this.onDescTipChange, 300);
  }

  helpInfoData = getHelpInfoData('DescriptionPanel');

  onDescTipChange = (editor) => this.setState({ desc_json_tiptap: editor.getJSON() });

  // checks url and updates state accordingly (edit or create)
  componentDidMount() {
    if (!this.props.descriptionPanelId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
        position: this.props.position,
      });
      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview('renderDescPreview');
    }

    if (this.props.descriptionPanelId) {
      axios
        .get(DESCRIPTION_PANEL_API_URL + this.props.descriptionPanelId + '/')
        .then((res) => this.updateState(res.data));
    }
  }

  updateState = (data) => {
    this.setState({
      pk: data.id,
      title: data.is_introduction ? 'Introduction' : data.title || '',
      description_text: data.description_text || '',
      desc_json_tiptap: data.desc_json_tiptap,
      new_description_text_content: data.new_description_text_content,
      prev_text_content: data.new_description_text_content,
      is_introduction: data.is_introduction,
      trigger: data.trigger ? data.trigger : '',
      module: data.module,
      descriptionType: data.is_action ? 'action' : 'clarification', // TODO: seems not being used anymore?
      infoboxes: data.infobox_detail,
      tools: data.tools,
      examples: data.examples ? data.examples : [],
      position: data.position,
    });
    //live preview handlers
    this.props.handleAddTitle(data.title);
    this.props.handleAddDescription(data.description_text, data.new_description_text_content);
    this.props.handleAddTrigger(data.trigger ? data.trigger : '');
    this.props.handleAddTools(data.tools);
    this.props.handleAddExamples(data.examples);
    this.props.handleAddDescriptionType(data.is_action ? 'action' : 'clarification');
  };

  convertToV2 = () => {
    this.toggleModal('convertModal');
    let payload = {
      panel_id: this.props.descriptionPanelId,
      module: this.props.moduleId,
    };

    axios.post(DESCRIPTION_PANEL_CONVERSION_API_URL, payload).then(() => {
      toast.success(CustomToast, { data: 'Description v1 converted to description v2' });
      this.props.resetState();
      this.props.toggleModal();
    });
  };

  deleteExample = (example) => {
    let examples = this.state.examples.filter((el) => el.id !== example.id);
    this.setState({
      examples: examples,
    });
    this.props.handleAddExamples(examples);
  };

  updateExamples = (newList) => {
    this.setState({
      examples: newList,
    });
    this.props.handleAddExamples(newList);
  };

  updateTools = (newList) => {
    this.setState({
      tools: newList,
    });
    this.props.handleAddTools(newList);
  };

  deleteTool = (tool) => {
    let tools = this.state.tools.filter((el) => el.id !== tool.id);
    this.setState({
      tools: tools,
    });
    this.props.handleAddTools(tools);
  };

  // updates the state on field input
  onChange = (e) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    } else if (e.target.name === 'description_text') {
      this.props.handleAddDescription(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  // updates the state on field input of short descriptio text
  onDescriptionTextChange = (e) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      new_description_text_content: e,
      description_text: text,
    });
    this.props.handleAddDescription(text, e);
  };

  // for open or close modal
  toggleModal = (modalName) => {
    this.setState((previous) => ({
      [modalName]: !previous[modalName],
    }));
  };

  // add text item to example
  addText = () => {
    let text = {
      id: generate_ID(),
      section: 'E',
      text: {
        statement: 'Text Item',
      },
    };

    let examples = this.state.examples.concat(text);
    this.setState({ examples: examples });
    this.props.handleAddExamples(examples);
  };

  // add infobox to example
  addInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'E',
      infobox: {},
    };

    let examples = this.state.examples.concat(infobox);
    this.setState({ examples: examples });
    this.props.handleAddExamples(examples);
  };

  addToolInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'T',
      infobox: {},
    };

    let tools = this.state.tools.concat(infobox);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addImage = () => {
    let image = {
      id: generate_ID(),
      label: '',
      section: 'T',
      image: {},
    };

    let tools = this.state.tools.concat(image);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addLegacyCalculator = (calculatorObj) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      legacy_calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addCalculator = (calculatorObj) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  updateExample = (id, value, inputName) => {
    const updatedExamples = this.state.examples.map((example) => {
      let updatedItem = example;

      if (example.id === id) {
        if (inputName === 'label') {
          // text item doesn't have label
          if (example.text) {
            updatedItem = {
              ...example,
              text: {
                statement: value,
              },
            };
          } else {
            updatedItem = {
              ...example,
              label: value,
            };
          }
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...example,
            infobox: infoboxObj || {},
            label: !example.label && infoboxObj ? infoboxObj.title : example.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...example,
            image: imageObj || {},
            label: !example.label && imageObj ? imageObj.title : example.label,
          };
          delete updatedItem.infobox;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ examples: updatedExamples });
    this.props.handleAddExamples(updatedExamples);
  };

  updateTool = (id, value, inputName) => {
    const updatedTools = this.state.tools.map((tool) => {
      let updatedItem = tool;

      if (tool.id === id) {
        if (inputName === 'label') {
          if (tool.text) {
            updatedItem = {
              ...tool,
              text: {
                statement: value,
              },
            };
          } else {
            updatedItem = {
              ...tool,
              label: value,
            };
          }
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...tool,
            infobox: infoboxObj || {},
            label: !tool.label && infoboxObj ? infoboxObj.title : tool.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...tool,
            image: imageObj || {},
            label: !tool.label && imageObj ? imageObj.title : tool.label,
          };
          delete updatedItem.infobox;
        } else if (inputName === 'legacy_calc') {
          let calcObj = this.props.calculatorState.calculators.find((image) => image.id === value);
          updatedItem = {
            ...tool,
            legacy_calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.title : tool.label,
          };
          delete updatedItem.calculator;
        } else if (inputName === 'calc') {
          const calcs = [
            ...(this.props.teamCalculatorState?.teamCalculators || []),
            ...(this.props.teamCalculatorState?.universalCalculators || []),
          ];
          let calcObj = calcs.find((calc) => calc.id === value);
          updatedItem = {
            ...tool,
            calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.name : tool.label,
          };
          delete updatedItem.legacy_calculator;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ tools: updatedTools });
    this.props.handleAddTools(updatedTools);
  };

  getPayload = () => {
    let payload = {
      title: this.state.title,
      description_text: this.state.description_text,
      new_description_text_content: this.state.new_description_text_content,
      is_introduction: this.state.is_introduction,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      panel_type: this.state.panel_type,
      is_action: this.state.descriptionType === 'action' ? true : false,
      question_items: this.state.examples.concat(this.state.tools),
      position: this.state.position,
      desc_json_tiptap: this.state.desc_json_tiptap,
    };

    return payload;
  };

  isExampleOrToolEmpty = () => {
    if (!this.state.is_introduction) {
      for (let i = 0; i < this.state.examples.length; i++) {
        const example = this.state.examples[i];

        if (
          (example.text && !example.text.statement) ||
          (example.infobox && (!example.label || Object.keys(example.infobox).length === 0))
        ) {
          return true;
        }
      }
    }

    for (let i = 0; i < this.state.tools.length; i++) {
      const tool = this.state.tools[i];

      if (
        (tool.text && !tool.text.statement) ||
        (tool.infobox && (!tool.label || Object.keys(tool.infobox).length === 0)) ||
        (tool.image && (!tool.label || Object.keys(tool.image).length === 0)) ||
        (tool.legacy_calculator &&
          (!tool.label || Object.keys(tool.legacy_calculator).length === 0)) ||
        (tool.calculator && (!tool.label || Object.keys(tool.calculator).length === 0))
      ) {
        return true;
      }
    }
    return false;
  };

  createDescriptionPanel = () => {
    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }

    this.props.toggleModal();
    axios.post(DESCRIPTION_PANEL_API_URL, this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  editDescriptionPanel = () => {
    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }
    this.props.toggleModal();
    axios.put(DESCRIPTION_PANEL_API_URL + this.state.pk + '/', this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  getSelectedTrigger = (data) => {
    this.setState({
      trigger: data,
    });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  toggleSelection = () => {
    this.setState({
      descriptionType: this.state.descriptionType === 'action' ? 'clarification' : 'action',
    });
    this.props.handleAddDescriptionType(
      this.state.descriptionType === 'action' ? 'clarification' : 'action'
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { title, is_introduction } = this.state;
    const { descriptionPanelId, modal, closeUiElementPane } = this.props;

    if (!is_introduction && title === '') {
      toast.error(CustomToast, { data: 'Please fill out the title field.' });
    } else if (descriptionPanelId) {
      this.editDescriptionPanel?.();
    } else {
      this.createDescriptionPanel?.();
    }
    modal && closeUiElementPane();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div className='row mt-14'>
        <div className='side-panel-form'>
          <Header
            title={this.state.is_introduction ? CARD_INTRODUCTION : CARD_DESCRIPTION}
            toggleModal={this.props.toggleModal}
          />
          <Form
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            className='flex h-full flex-col px-3'
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            {!this.state.is_introduction && (
              <Box className='d-flex flex-column ml-1 mt-4'>
                <FieldLabel label='Type' />
                <div>
                  <ToggleButton
                    onClick={this.toggleSelection}
                    style={
                      this.state.editMode || this.props.editMode
                        ? this.props.editorType
                        : this.state.descriptionType === 'action'
                        ? selectedButton
                        : {}
                    }
                  >
                    Action
                  </ToggleButton>
                  <ToggleButton
                    onClick={this.toggleSelection}
                    style={
                      this.state.editMode || this.props.editMode
                        ? this.props.editorType
                        : this.state.descriptionType === 'clarification'
                        ? selectedButton
                        : {}
                    }
                  >
                    Clarification
                  </ToggleButton>
                </div>
              </Box>
            )}

            {!this.state.is_introduction && (
              <Box className='ml-1 mt-4'>
                <InputField
                  name='title'
                  required={!this.state.is_introduction}
                  value={this.state.title}
                  onChange={this.onChange}
                  label={helpInfoData?.title?.label}
                  detail={helpInfoData?.title?.detail}
                  placeholder={helpInfoData?.title?.placeholder}
                  maxLength={helpInfoData?.title?.character_limit}
                />
              </Box>
            )}

            <Box className='ml-1 mt-3'>
              {helpInfoData['description_text'] && (
                <FormGroup className='mt-3'>
                  <FieldLabel
                    detail={helpInfoData?.description_text?.detail}
                    label={helpInfoData?.description_text?.label}
                  />
                  <AvoEditor
                    moduleId={this.props.moduleId}
                    suggestions={[]}
                    setValue={(e) => this.onDescriptionTextChange(e)}
                    prevValue={this.state.prev_text_content}
                    isIntroductionCard={this.state.is_introduction}
                    disableConditionalText={true}
                    MAX_TEXT_LIMIT={helpInfoData['description_text'].character_limit}
                    richTextButtonShowList={[
                      ToolbarButton.TEXT_STYLE,
                      ToolbarButton.ITALIC,
                      ToolbarButton.HIGHLIGHT,
                      ToolbarButton.BULLETED_LIST,
                      ToolbarButton.NUMBERED_LIST,
                      ToolbarButton.INFOBOX,
                      ToolbarButton.MEDIA,
                    ]}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    onUpdate={this.debDescTiptapChange}
                    initialContent={this.state.desc_json_tiptap}
                    richTextButtonsShowListTiptap={richTextButtonsShowList}
                    maxCharacters={helpInfoData?.description_text?.character_limit}
                    suggestionsToExclude={suggestionsToExclude}
                    characterLimitTotalCount={helpInfoData?.description_text?.character_limit}
                    characterLimitCurrentCount={this.state.description_text.length}
                  />
                </FormGroup>
              )}
            </Box>

            {!this.state.is_introduction && (
              <Box className='ml-1 mt-3'>
                <FieldLabel
                  detail={helpInfoData?.examples?.detail}
                  label={helpInfoData?.examples?.label}
                />
                <Examples
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <div className='example-n-tools-textBox m-4 mt-2'>
                    <ExampleNToolsList
                      moduleId={this.props.moduleId}
                      list={this.state.examples}
                      handleDeleteClick={this.deleteExample}
                      handleListChange={this.updateExample}
                      updateListItems={this.updateExamples}
                    />
                  </div>
                  <div className='row d-flex align-items-center ml-2  mt-2'>
                    <AddLabel className='ml-4 mr-2'>Add</AddLabel>
                    <AddButton
                      style={{
                        display: 'inline-flex',
                      }}
                      disableRipple
                      disableFocusRipple
                      startIcon={<AddIcon />}
                      className=' waves-effect waves-light  mr-2'
                      onClick={this.addText}
                    >
                      Text
                    </AddButton>
                    <AddButton
                      style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                      startIcon={<AddIcon />}
                      className=' waves-effect waves-light mr-2'
                      onClick={this.addInfoBox}
                    >
                      InfoBox / Media
                    </AddButton>
                  </div>
                </Examples>
              </Box>
            )}

            <Box className='ml-1  mt-3'>
              <FieldLabel detail={helpInfoData?.tools?.detail} label={helpInfoData?.tools?.label} />
              <Examples
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <div className='example-n-tools-textBox m-4 mt-2'>
                  <ExampleNToolsList
                    moduleId={this.props.moduleId}
                    list={this.state.tools}
                    handleDeleteClick={this.deleteTool}
                    handleListChange={this.updateTool}
                    updateListItems={this.updateTools}
                  />
                </div>
                <div className='row d-flex align-items-center ml-2  mt-2'>
                  <AddLabel className='ml-4 mr-2'>Add</AddLabel>
                  <AddButton
                    style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                    startIcon={<AddIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={this.addToolInfoBox}
                  >
                    InfoBox / Media
                  </AddButton>
                  <AddButton
                    style={{ width: '150px', maxWidth: '150px', display: 'inline-flex' }}
                    startIcon={<AddIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={(e) => this.setState({ showCalcTypes: e.target })}
                  >
                    Calculator
                  </AddButton>
                  <Popover
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id='simple-menu'
                    anchorEl={this.state.showCalcTypes}
                    // keepMounted
                    open={Boolean(this.state.showCalcTypes)}
                    onClose={(e) => this.setState({ showCalcTypes: null })}
                  >
                    <SectionDropdown
                      sectionDropdown
                      section1='Team Calculators'
                      section2='Universal Calculators'
                      sectionOneItems={this.props.teamCalculatorState.teamCalculators}
                      sectionTwoItems={this.props.calculatorState.calculators}
                      universalCalculators={this.props.teamCalculatorState?.universalCalculators}
                      sectionOneClick={(obj) => {
                        this.addCalculator(obj);
                        this.setState({ showCalcTypes: null });
                      }}
                      sectionTwoClick={(obj) => {
                        this.addLegacyCalculator(obj);
                        this.setState({ showCalcTypes: null });
                      }}
                    />
                  </Popover>
                </div>
              </Examples>
            </Box>

            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton
                  style={{ color: 'black', marginRight: '10px' }}
                  onClick={this.props.toggleModal}
                >
                  {CANCEL_BUTTON}
                </CancelButton>
                {this.props.descriptionPanelId && !this.props.introFormOpened && (
                  <CreateButton
                    style={{ marginLeft: '10px', marginRight: '5px' }}
                    onClick={() => this.toggleModal('convertModal')}
                  >
                    Convert To v2
                  </CreateButton>
                )}
                <CreateButton type='submit' name='action'>
                  {SAVE_BUTTON}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>

          <ConfirmModal
            open={this.state.convertModal}
            content='Are you sure you want to convert this panel to Beta?'
            contentClass='center-modal'
            confirmText='Convert'
            toggleModal={() => this.toggleModal('convertModal')}
            performAction={this.convertToV2}
          />
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal}
          toggleModal={this.props.closeUiElementPane}
          toggleModalPanel={this.props.toggleModal}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const suggestionsToExclude = [
  'knowledge_base',
  'variable',
  'conditional_text',
  'link',
  'contact_number',
  'reference',
  'ehr_order',
];

export default connect(mapStateToProps, {
  getCalculators,
  getTriggers,
})(DescriptionPanelForm);

DescriptionPanelForm.propTypes = {
  introFormOpened: PropTypes.bool.isRequired,
  handleSelectedItem: PropTypes.func.isRequired,
  startPreview: PropTypes.func.isRequired,
  handleAddTrigger: PropTypes.func.isRequired,
  handleAddTitle: PropTypes.func.isRequired,
  handleAddExamples: PropTypes.func.isRequired,
  handleAddDescription: PropTypes.func.isRequired,
  handleAddDescriptionType: PropTypes.func.isRequired,
  handleAddTools: PropTypes.func.isRequired,
  descriptionPanelId: PropTypes.number.isRequired,
  moduleId: PropTypes.number,
  position: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  closeUiElementPane: PropTypes.func.isRequired,
};
