import { MenuList, Popover } from '@material-ui/core';
import CreateIcon from 'assets/icons/createIcon';
import SearchIcon from 'assets/icons/searchIcon';
import TextIcon from 'assets/icons/textIcon';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { InputGroup } from 'reactstrap';
import {
  CreateCustom,
  EditButton,
  InfoboxLabelChangeButton,
  InfoboxSearchInput,
  MentionInfoboxMenuItem,
  NumericsMenuItem,
} from './styled-components/FormStyle';

class InfoboxDropdown extends Component {
  changeLabel = () => {
    if (!this.props.text || !this.props.selectedItem) {
      if (!this.props.text) {
        toast.error(CustomToast, { data: 'Please add label' });
        return;
      } else if (!this.props.selectedItem) {
        toast.error(CustomToast, { data: 'Please select an infobox/media' });
        return;
      }
    }

    this.props.addItem({ name: this.props.text, code: this.props.selectedItem });
  };

  editInfoOrMedia = (item) => {
    let regex = {
      image: /\bimage_\w+_code\b/g,
      infobox: /\binfobox_\w+_code\b/g,
    };
    if (item?.unique_code?.match(regex['infobox'])) {
      this.props.toggleEditInfoboxModal(item.id);
    } else if (item?.unique_code?.match(regex['image'])) {
      this.props.toggleEditMediaModal(item.id);
    }
  };

  render() {
    const items = this.props.items || [];
    let filteredItems = [];

    items.forEach((obj) => {
      let title = obj.representation_phrase || obj.shortened_title || '';
      if (title.toLowerCase().includes(this.props.searchInput.toLowerCase())) {
        filteredItems.push({
          id: obj.id,
          title: title,
          unique_code: obj.unique_code,
        });
      }
    });

    const filteredCustomNumerics =
      this.props.customNumericState?.customNumerics?.filter((customNumeric) =>
        customNumeric.name.toLowerCase().includes(this.props.searchInput.toLowerCase())
      ) || [];

    /* 
      TODO: some if statements are not needed as
      props.isNumeric is always null & props.isInfoMedia is always true
    */
    return (
      <div>
        <Popover
          id='text-style-menu'
          style={{ marginTop: 10, zIndex: 999 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={this.props.dropDownEl}
          open={Boolean(this.props.dropDownEl)}
          onClose={this.props.closeMenu}
        >
          <div
            style={{
              width: '334px',
              background: '#FFFFFF',
              border: '1px solid #E7E7E7',
              boxSizing: 'border-box',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
              borderRadius: '4px',
              marginTop: 5,
              maxHeight: 600,
              overflowY: 'hidden',
            }}
          >
            {this.props.isNumeric && (
              <div style={{ padding: '16px', paddingBottom: '0px' }}>
                <div className='search-ip-box'>
                  <input
                    placeholder='Search or type to create'
                    id='search-ip'
                    name='searchCustomNumeric'
                    value={this.props.searchInput}
                    onChange={(e) => this.props.setSearchInput(e.target.value)}
                    type='text'
                  />
                </div>
              </div>
            )}
            {(this.props.isInfoMedia || this.props.isEditInfoMedia) && (
              <div>
                <InputGroup className='ip-grp' style={{ alignItems: 'center' }}>
                  <SearchIcon style={{ width: 21, height: 21, marginLeft: ' 25px' }} />
                  <InfoboxSearchInput
                    autoFocus
                    placeholder='Search'
                    value={this.props.searchInput}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => this.props.setSearchInput(e.target.value)}
                  />
                </InputGroup>

                <InputGroup className='ip-grp' style={{ alignItems: 'center' }}>
                  <TextIcon style={{ marginLeft: '30px' }} />
                  <InfoboxSearchInput
                    placeholder='Text to display'
                    value={this.props.text}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => this.props.setText(e.target.value)}
                  />
                </InputGroup>
              </div>
            )}

            {(filteredItems.length > 0 || filteredCustomNumerics.length > 0) && (
              <MenuList
                autoFocus
                style={{
                  maxHeight: 200,
                  overflowY: 'scroll',
                  paddingBottom: 15,
                  backgroundColor: '#F9FAFB',
                  paddingTop: '13px',
                }}
              >
                {filteredItems.map((item) => (
                  <MentionInfoboxMenuItem
                    className='numericsMenuWrapper'
                    key={item.id}
                    style={{
                      color: item.unique_code === this.props.selectedItem ? '#08A88E' : '#172B4D',
                    }}
                    onClick={(e) => {
                      // e.preventDefault();
                      if (!this.props.text) this.props.setText(item.title);
                      this.props.setSelectedItem(item.unique_code);
                    }}
                  >
                    {item.title || 'No Title'}

                    <EditButton
                      className='editButton'
                      onClick={(e) => {
                        e.stopPropagation();
                        this.editInfoOrMedia(item);
                      }}
                    >
                      Edit
                    </EditButton>
                  </MentionInfoboxMenuItem>
                ))}
                {this.props.isNumeric &&
                  filteredCustomNumerics.map((item) => (
                    <NumericsMenuItem
                      className='numericsMenuWrapper'
                      key={item.id}
                      onClick={() => this.props.setSelectedItem(item)}
                    >
                      {item.display_value}
                      <EditButton
                        className='editButton'
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.toggleEditModal(item.id);
                        }}
                      >
                        Edit
                      </EditButton>
                    </NumericsMenuItem>
                  ))}
              </MenuList>
            )}
            <div
              style={{
                borderTop: '1px solid #EBECF0',
                width: '334px',
                textAlign: 'center',
                paddingTop: 19,
                paddingBottom: 18,
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <CreateCustom
                startIcon={<CreateIcon />}
                className='waves-effect waves-light mr-2'
                onClick={this.props.toggleCreateModal}
              >
                <p style={{ marginTop: '5px', fontSize: '18px', fontWeight: '500' }}> Create </p>
              </CreateCustom>

              {!(this.props.isInfoMedia || this.props.isEditInfoMedia) && (
                <InfoboxLabelChangeButton onClick={this.props.addItem}>
                  Confirm
                </InfoboxLabelChangeButton>
              )}

              {(this.props.isInfoMedia || this.props.isEditInfoMedia) && (
                <InfoboxLabelChangeButton onClick={this.changeLabel}>
                  Confirm
                </InfoboxLabelChangeButton>
              )}
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  customNumericState: state.customNumericState,
});

export default connect(mapStateToProps, {})(InfoboxDropdown);
