import { MainLayout } from 'components/layout/MainLayout';
import TeamSettingForm from 'components/teams/teamSettingForm';
import { H2 } from 'components/utils/typo';

export const TeamCreate = () => {
  return (
    <MainLayout className='bg-gray-100'>
      <H2>Create Team</H2>
      <TeamSettingForm />
    </MainLayout>
  );
};
