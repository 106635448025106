import axios from 'axios';
import { CALCULATOR_API_URL, MODULE_API_URL } from 'constants';
import { GET_MEDIA } from '../actionsTypes';

export const getMedia =
  (module_type = '', mirrorId = null, moduleId) =>
  async (dispatch, getState) => {
    let API_URL;
    switch (module_type) {
      case 'calculator':
        API_URL = `${CALCULATOR_API_URL}${moduleId}/images`;
        break;
      default:
        API_URL = `${MODULE_API_URL}${moduleId}/images`;
        break;
    }

    if (mirrorId) {
      API_URL = `${API_URL}?mirror_id=${mirrorId}`;
    }

    const res = await axios.get(API_URL);
    dispatch({
      type: GET_MEDIA,
      payload: res.data,
    });
  };
