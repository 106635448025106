import { Fragment, useMemo, useState } from 'react';
import { ROLES } from '../../api/team';
import { ReactComponent as Plus } from '../../assets/icons/v2/general/plus_m.svg';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';
import { SortableList } from '../utils/SortableList';
import { H2 } from '../utils/typo';
import MemberInvitationModal from './MemberInvitationModal';
import MemberRow from './MemberRow';

const MemberManagement = () => {
  const { team } = useTeam();
  const { authentication } = useAuthentication();
  const isAdmin = useMemo(
    () =>
      team.teammates.some(
        (member) => member.role === ROLES.Admin && member.user.id === authentication.user?.id
      ),
    [team, authentication]
  );
  const uniqueAdminNo = useMemo(() => {
    const adminMembers = team.teammates.filter((member) => member.role === ROLES.Admin);
    return adminMembers.length === 1 ? adminMembers.pop()?.id : undefined;
  }, [team]);
  const columns = {
    name: 'Members',
    role_display_value: 'Role',
  };

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className='mb-[56px] px-[48px]'>
        <div className='flex items-center justify-between pb-[32px] pt-[40px]'>
          <H2>Members Management</H2>
          <div>
            <button
              className='flex h-[40px] items-center justify-center whitespace-nowrap rounded-[4px] bg-primary-500 px-[10px] py-[8px] text-button-1 text-white focus:bg-primary-500'
              onClick={() => setOpen(true)}
            >
              <Plus className='w-[23px] fill-white p-[2px]' />
              Invite Members
            </button>
          </div>
        </div>
        <div>
          <SortableList
            list={team.teammates}
            columns={columns}
            renderItem={(item) => (
              <MemberRow
                key={item.id}
                member={item}
                isAdmin={isAdmin}
                uniqueAdminNo={uniqueAdminNo}
              />
            )}
            emptyComponent={<Fragment />}
          />
        </div>
      </div>
      <MemberInvitationModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default MemberManagement;
