import { Control, ControllerRenderProps, FieldValues, useController } from 'react-hook-form';
import { Fragment, HTMLAttributes, useMemo } from 'react';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import TagDeleteIcon from '../../assets/icons/tagCloseIcon';
import CloseFilledIcon from '../../assets/icons/CloseIconFilled';
import DropdownArrowIcon from '../../assets/icons/dropdownArrowIcon';
import _ from 'lodash';
import { MultiSelectOption } from '../../constants/userInfo';

interface ClassNames {
  box?: string;
  button?: string;
  options?: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  name: string;
  control: Control<any, string>;
  placeholder?: string;
  classNames?: ClassNames;
}

export const MultiSelect = ({
  options,
  name,
  placeholder,
  control,
  classNames,
}: MultiSelectProps) => {
  const { field } = useController({
    control,
    name: name,
  });

  return (
    <MultiSelectBox field={field} className={classNames?.box}>
      <MultiSelectButton
        options={options}
        field={field}
        placeholder={placeholder}
        className={classNames?.button}
      />
      <MultiSelectOptions options={options} value={field.value} className={classNames?.options} />
    </MultiSelectBox>
  );
};

interface MultiSelectBoxProps extends HTMLAttributes<HTMLDivElement> {
  field: ControllerRenderProps<FieldValues, string>;
}

const MultiSelectBox = ({ field, className, children }: MultiSelectBoxProps) => (
  <Listbox value={field.value || []} onChange={field.onChange} multiple>
    <div className={className}>{children}</div>
  </Listbox>
);
interface MultiSelectButtonProps extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  options: MultiSelectOption[];
  field: ControllerRenderProps<FieldValues, string>;
}

const MultiSelectButton = ({ placeholder, options, field, className }: MultiSelectButtonProps) => {
  const value = useMemo(() => field.value || [], [field]);
  //_.some for object type, _.includes for  primitive type
  const multiSelectValues = useMemo(
    () =>
      options.filter((option) => _.some(value, option.value) || _.includes(value, option.value)),
    [options, value]
  );

  const handleCancel = (event, cancelItem) => {
    event.preventDefault();
    field.onChange([...value].filter((item) => item !== cancelItem));
  };

  const handleAllCancel = (event) => {
    event.preventDefault();
    field.onChange([]);
  };

  return (
    <Listbox.Button as={Fragment}>
      {({ open }) => (
        <div
          className={clsx(
            className,
            'min-h-[45px] w-full rounded border !border-gray-200 px-[12px] py-[10px] focus:bg-transparent'
          )}
        >
          <div className='flex h-full justify-between'>
            {!value.length && placeholder && (
              <div className='pointer-events-none text-[14px] tracking-tighter text-gray-400'>
                {placeholder}
              </div>
            )}
            <div className='flex flex-wrap gap-[4px]'>
              {multiSelectValues.map((option, key) => (
                <div
                  key={key}
                  className='flex rounded-[2px] !bg-gray-100 text-body-2 text-gray-700'
                >
                  <div className='p-[4px] pl-[7px] text-[12px] font-medium tracking-tight'>
                    {option.label}
                  </div>
                  <div
                    onClick={(e) => handleCancel(e, option.value)}
                    className='flex cursor-pointer rounded-[2px] px-[5px] hover:bg-red-200 focus:bg-gray-100'
                  >
                    <div className='pointer-events-none self-center hover:text-red-500 '>
                      <TagDeleteIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='flex'>
              {value.length > 0 && (
                <div
                  className='cursor-pointer self-center px-[8px] focus:bg-transparent'
                  onClick={handleAllCancel}
                >
                  <CloseFilledIcon />
                </div>
              )}
              <div className={clsx('self-center transition-all', { 'rotate-180': open })}>
                <DropdownArrowIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </Listbox.Button>
  );
};

interface MultiSelectOptionsProps extends HTMLAttributes<HTMLDivElement> {
  options: MultiSelectOption[];
  value: any[];
}

const MultiSelectOptions = ({ options, value, className }: MultiSelectOptionsProps) => {
  const multiSelectOptions = useMemo(
    () => options.filter((option) => (Array.isArray(value) ? !value.includes(option.value) : true)),
    [options, value]
  );

  return (
    <Listbox.Options as={Fragment}>
      <div className='relative m-0'>
        <ul
          className={clsx(
            className,
            'absolute top-[5px] z-[1300] max-h-[310px] w-full overflow-y-auto rounded border border-gray-300 bg-white py-[5px]'
          )}
        >
          {multiSelectOptions.map((option, key) => (
            <Listbox.Option as={Fragment} key={key} value={option.value}>
              <li className='px-[10px] py-[8px] text-[16px] hover:bg-primary-200'>
                {option.label}
              </li>
            </Listbox.Option>
          ))}
        </ul>
      </div>
    </Listbox.Options>
  );
};
