import axios from 'axios';
import { CALCULATOR_API_URL, MODULE_API_URL } from '../../constants';
import { GET_CANDIDATE_TRIGGERS, GET_TRIGGERS } from '../actionsTypes';

export const getTriggers =
  (module_type = '', mirrorId = null, sendVariables = false, moduleId) =>
  async (dispatch, getState) => {
    let API_URL;
    switch (module_type) {
      case 'calculator':
        API_URL = `${CALCULATOR_API_URL}${moduleId}/triggers`;
        break;
      default:
        API_URL = `${MODULE_API_URL}${moduleId}/triggers`;
        break;
    }

    if (mirrorId) {
      API_URL = `${API_URL}?mirror_id=${mirrorId}`;
    } else if (sendVariables) {
      API_URL = `${API_URL}?send_variables=true`;
    }

    let triggers = [];
    let candidate_triggers = [];
    const res = await axios.get(API_URL);

    // eslint-disable-next-line
    res.data.map((trigger) => {
      if (trigger.candidate_trigger) candidate_triggers.push(trigger);
      else triggers.push(trigger);
    });

    dispatch({
      type: GET_CANDIDATE_TRIGGERS,
      payload: candidate_triggers,
    });

    dispatch({
      type: GET_TRIGGERS,
      payload: triggers,
    });
  };
