import { MainLayout } from 'components/layout/MainLayout';
import { FeaturedAuthorManagement } from '../components/members/featured-author/FeaturedAuthorManagement';

export const FeaturedAuthor = () => {
  return (
    <MainLayout>
      <FeaturedAuthorManagement />
    </MainLayout>
  );
};
