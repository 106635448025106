import { MainLayout } from 'components/layout/MainLayout';
import TagsTreeListing from 'components/tag-management/TagsTreeListing';
import { useTeam } from '../../hooks/useTeam';

export const Tags = () => {
  const { team } = useTeam();
  return (
    <MainLayout>
      <TagsTreeListing team={team} defaultTag />
    </MainLayout>
  );
};
