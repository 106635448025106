import { HTMLAttributes } from 'react';

interface BackdropProps extends HTMLAttributes<HTMLDivElement> {}

const Backdrop = ({ children }: BackdropProps) => {
  return (
    <div className='fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center'>
      <div className='absolute left-0 top-0 h-full w-full bg-black opacity-30'></div>
      <div className='z-10'>{children}</div>
    </div>
  );
};

export default Backdrop;
