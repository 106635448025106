import { SVGProps } from 'react';

export const CalculatorIcon = ({ fill = '#08A88E', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='22' height='22' rx='2' fill='#6C7C83' />
      <path
        d='M4 7.91182H11.1746V6.30862H4V7.91182ZM13.619 18.8778H20V17.3066H13.619V18.8778ZM13.619 15.4469H20V13.8437H13.619V15.4469ZM6.79365 20H8.38095V17.1784H11.1746V15.5752H8.38095V12.7535H6.79365V15.5752H4V17.1784H6.79365V20ZM14.7937 10.2204L16.7302 8.26453L18.6667 10.2204L19.8095 9.06613L17.873 7.11022L19.8095 5.15431L18.6667 4L16.7302 5.95591L14.7937 4L13.6508 5.15431L15.5873 7.11022L13.6508 9.06613L14.7937 10.2204Z'
        fill='white'
      />
    </svg>
  );
};
