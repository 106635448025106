import {
  GET_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE_LIST,
  SET_IS_KB_MIRROR,
} from 'actions/actionsTypes';

const knowledgeBaseReducer = (state = { knowledgeBases: [], knowledgeBase: {} }, action) => {
  switch (action.type) {
    case GET_KNOWLEDGE_BASE:
      return {
        ...state,
        knowledgeBase: {
          ...action.payload,
          isMirror: state?.knowledgeBase?.isMirror,
          mirrorId: state?.knowledgeBase?.mirrorId,
          mirrorName: state?.knowledgeBase?.mirrorName,
        },
      };
    case GET_KNOWLEDGE_BASE_LIST:
      return {
        ...state,
        knowledgeBases: action.payload || [],
      };

    case SET_IS_KB_MIRROR:
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          isMirror: action.isMirror,
          mirrorId: action.mirrorId,
          mirrorName: action.mirrorName,
        },
      };

    default:
      return state;
  }
};

export default knowledgeBaseReducer;
