import { ChannelForm } from 'components/channels/ChannelForm';
import { MainLayout } from 'components/layout/MainLayout';

export const ChannelUpsert = () => {
  return (
    <MainLayout className='bg-gray-100'>
      <ChannelForm />
    </MainLayout>
  );
};
