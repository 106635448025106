import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const FormTitle = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: 20,
  },
}))(Typography);

export const CreateTeamContainer = withStyles(() => ({
  root: {
    width: '800px',
    margin: '0 auto',
    marginTop: '50px',
    padding: '30px 70px',
    background: '#fff',
  },
}))(Box);

export const ActionContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginLeft: 'auto',
  },
}))(Box);

export const ActionButton = withStyles(() => ({
  root: {
    width: '178px',
    height: '60px',
    background: '#08A88E',
    boxShadow: '0px 12px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '60px',
    cursor: 'pointer',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',

    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
  disabled: {
    background: '#E5EDF0',
  },
}))(Button);

export const CancelButton = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#08A88E',
    border: 'none',
    background: 'transparent',
    textTransform: 'none',
    marginRight: 29,
  },
}))(Button);
