import calculatorIcon from '@iconify/icons-mdi/calculator';
import { Icon } from '@iconify/react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ImageIcon from '@material-ui/icons/Image';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkIcon from '@material-ui/icons/Link';
import axios from 'axios';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { CHOICE_CARD_API_URL } from '../../constants';
import ChoiceAnswerForm from './choiceCardForm';

const ChoiceButton = withStyles((theme) => ({
  root: {
    width: '350px',
    backgroundColor: '#F5F5F5',
    display: 'grid',
    position: 'inherit',
    margin: 'auto',
    textAlign: 'center',
    color: '#8E8E8E',
    borderRadius: '20px',
    height: '28px',
    marginBottom: '5px',
    textTransform: 'none',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#62E37D',
      color: 'white',
    },
    child: {
      backgroundColor: 'pink',
    },
  },
}))(Button);
interface ChoiceAnswerCardProps {
  resetAnswerPage: Function;
  answerCard: any;
  moduleId: any;
}
interface ChoiceAnswerCardState {
  editModal: boolean;
  deleteModal: boolean;
  createChoiceCard: boolean;
  duplicateModal: boolean;
  closeChoiceForm: boolean;
}
class ChoiceAnswerCard extends Component<ChoiceAnswerCardProps, ChoiceAnswerCardState> {
  constructor(props: ChoiceAnswerCardProps) {
    super(props);

    this.state = {
      editModal: false,
      deleteModal: false,
      createChoiceCard: false,
      duplicateModal: false,
      closeChoiceForm: false,
    };
  }

  toggleEditModal = () => {
    this.setState((previous) => ({
      createChoiceCard: !previous.createChoiceCard,
    }));
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  deleteAnswerCard = () => {
    axios.delete(CHOICE_CARD_API_URL + this.props.answerCard.id + '/').then(() => {
      this.props.resetAnswerPage();
    });
  };

  toggle = () => {
    this.setState({
      editModal: true,
    });
  };

  closeChoiceFormPane = () => {
    this.setState((previous) => ({
      closeChoiceForm: !previous.closeChoiceForm,
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      createChoiceCard: !prevState.createChoiceCard,
    }));
    this.closeChoiceFormPane();
  };

  render() {
    let cardStyle = {
      borderRadius: '25px',
      backgroundColor: 'WHITE',
      width: '440px',
      marginLeft: '2px',
      marginBottom: '10px',
    } as React.CSSProperties;
    let buttonStyle = {
      color: '#FD9954',
      float: 'right',
      textTransform: 'none',
      fontSize: 15,
    } as React.CSSProperties;
    let questionStyle = {
      fontSize: 'large',
      fontWeight: '600',
    } as React.CSSProperties;

    let triggerChipStyle = {
      float: 'right',
      color: 'whitesmoke',
      marginTop: '2px',
      marginBottom: '5px',
      marginRight: '5px',
    } as React.CSSProperties;

    const choices = this.props.answerCard.choices ? this.props.answerCard.choices : [];
    const trigger = this.props.answerCard.trigger_detail;

    let elements = this.props.answerCard.examples.concat(this.props.answerCard.tools);
    let questions: string[] = [],
      infoboxes: string[] = [],
      calculators: string[] = [],
      images: string[] = [];

    if (elements !== undefined) {
      for (let i = 0; i < elements.length; i++) {
        let item = elements[i];
        if (item && 'text' in item) {
          questions.push(item['text']['statement']);
        } else if (item && 'image' in item) {
          images.push(item.label || item['image']['representation_phrase']);
        } else if (item && 'infobox' in item) {
          infoboxes.push(item.label || item['infobox']['shortened_title']);
        } else if (item && 'legacy_calculator' in item) {
          calculators.push(item.label || item['legacy_calculator']['title']);
        }
      }
    }

    const ChoiceAnswerFormProps = {
      ansCardId: this.props.answerCard.id,
      moduleId: this.props.moduleId,
      resetAnsCards: this.props.resetAnswerPage,
      toggleModal: () =>
        this.setState((prevState) => ({
          createChoiceCard: !prevState.createChoiceCard,
        })),
    };

    return (
      <div>
        <div onDoubleClick={!hasViewOnlyPermission() ? this.toggleEditModal : undefined}>
          <Paper elevation={5} style={{ borderRadius: '30px' }}>
            <Typography
              component={'div'}
              color='textPrimary'
              style={{ padding: '5px', fontWeight: '600' }}
            >
              {trigger ? (
                trigger.title !== 'Always On' && (
                  <Chip
                    style={
                      { ...triggerChipStyle, backgroundColor: '#08A88E' } as React.CSSProperties
                    }
                    size='small'
                    label={trigger.title}
                  />
                )
              ) : (
                <Chip
                  style={{ ...triggerChipStyle, backgroundColor: 'red' } as React.CSSProperties}
                  size='small'
                  label='Not Assigned'
                />
              )}
            </Typography>
            <Card style={cardStyle}>
              <div
                style={{
                  opacity: this.props.answerCard.original_copy ? '0.7' : '1',
                }}
              >
                <CardContent>
                  <Typography className='pb-2' color='textSecondary'>
                    {this.props.answerCard.rationale}
                  </Typography>
                  <Typography
                    component={'div'}
                    color='textPrimary'
                    gutterBottom
                    style={questionStyle}
                  >
                    {this.props.answerCard.question}
                  </Typography>
                  {questions.map((item, index) => (
                    <Chip
                      style={{
                        backgroundColor: 'transparent',
                        color: '#62E37D',
                      }}
                      key={index}
                      icon={<HelpOutlineIcon style={{ color: '#62E37D' }} fontSize='small' />}
                      label={item}
                    />
                  ))}
                  {infoboxes.map((item, index) => (
                    <Chip
                      style={{
                        backgroundColor: 'transparent',
                        color: '#616161',
                      }}
                      key={index}
                      icon={<InfoOutlinedIcon style={{ color: '#62E37D' }} fontSize='small' />}
                      label={item}
                    />
                  ))}
                  {calculators.map((item, index) => (
                    <Chip
                      style={{
                        backgroundColor: 'transparent',
                        color: '#616161',
                      }}
                      key={index}
                      icon={<Icon icon={calculatorIcon} />}
                      label={item}
                    />
                  ))}
                  {images.map((item, index) => (
                    <Chip
                      style={{
                        backgroundColor: 'transparent',
                        color: '#616161',
                      }}
                      size='small'
                      key={index}
                      icon={<ImageIcon fontSize='small' />}
                      label={item}
                    />
                  ))}
                  <br />
                  <br />
                  {choices.map((choice, index) => (
                    <div key={index}>
                      <ChoiceButton>{choice.name}</ChoiceButton>
                    </div>
                  ))}
                </CardContent>
                {!hasViewOnlyPermission() && (
                  <CardActions>
                    <Button
                      style={buttonStyle}
                      className='util-icons right'
                      size='small'
                      onClick={this.toggleDeleteModal}
                    >
                      Delete
                    </Button>
                    {!this.props.answerCard.original_copy ? (
                      <div>
                        <Button
                          style={buttonStyle}
                          className='util-icons right'
                          size='small'
                          onClick={this.toggleEditModal}
                        >
                          Edit
                        </Button>
                      </div>
                    ) : (
                      <LinkIcon
                        fontSize='large'
                        style={{
                          float: 'right',
                          marginInlineStart: '320px',
                          transform: 'rotate(140deg)',
                        }}
                      />
                    )}
                  </CardActions>
                )}
              </div>
            </Card>
          </Paper>
        </div>

        <SlidingPane
          //type={'right'}
          isOpen={this.state.createChoiceCard}
          onRequestClose={this.closeChoiceFormPane}
          from='right'
          overlayClassName='light-overlay'
          width='620px'
        >
          <div style={{ backgroundColor: 'white', overflowX: 'visible' }}>
            <ChoiceAnswerForm {...ChoiceAnswerFormProps} />
          </div>
        </SlidingPane>

        {/* Sliding Panel for Choice Panel Form */}
        <ConfirmModal
          preset='unsaved'
          open={this.state.closeChoiceForm}
          toggleModal={this.closeChoiceFormPane}
          performAction={this.closeModal}
        />

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content={
            this.props.answerCard.has_mirrors
              ? 'There are mirrored copies of the current text item. Are you really going to delete this item?'
              : 'Are you sure you want to delete this Answer Card?'
          }
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.deleteAnswerCard();
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}

export default ChoiceAnswerCard;
