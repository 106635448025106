import AVOMDIconGreen from 'assets/icons/avomdIconGreen';
import { HTMLAttributes } from 'react';
import { useHistory } from 'react-router';
import { HOME } from 'routes/urls';
import { ProfileMenu } from './ProfileMenu';
import { TeamSelectComboBox } from './TeamSelectCombo';
import { twJoin } from 'tailwind-merge';
import { HEADER_Z_INDEX } from '../../../v2/utils/constant';

interface MainHeaderProps extends HTMLAttributes<HTMLDivElement> {}

export const MainHeader = ({}: MainHeaderProps) => {
  const history = useHistory();
  return (
    <div
      className={twJoin(
        'sticky left-0 top-0 flex w-full items-center justify-between border-b bg-white px-[16px] py-[3px]',
        HEADER_Z_INDEX
      )}
    >
      <div className='cursor-pointer' onClick={() => history.push(HOME)}>
        <AVOMDIconGreen />
      </div>
      <div className='flex items-center space-x-[16px]'>
        <div className='w-[320px]'>
          <TeamSelectComboBox />
        </div>
        <div>
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
};
