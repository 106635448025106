import axios from 'axios';
import { FETCH_VARIABLE_NAME } from '../constants';

const DEFAULT_VARIABLE_NAME = 'unknown-question';

export const getVariableName = async (question: string): Promise<string> => {
  let variableName: string;
  try {
    const res = await axios.post(FETCH_VARIABLE_NAME, { question });
    variableName = res.data.variable_name;
  } catch (err) {
    variableName = '';
    console.log(err);
  }
  if (!variableName) {
    variableName =
      question
        .toLowerCase()
        .trim()
        .replace(/\s/g, '-')
        .replace(/[^-\w]/g, '') || DEFAULT_VARIABLE_NAME;
  }

  return variableName;
};
