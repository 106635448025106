import {
  Box,
  Button,
  Paper,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { createTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#D0D0D0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#707070',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: '2px solid currentColor',
  },
  completed: {
    color: '#D2D2D2',
    zIndex: 1,
    fontSize: 22,
  },
});

export const QontoStepIcon = (props) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckCircleRoundedIcon className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export const theme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#D2D2D2',
        },
        '&$active': {
          color: '#707070',
        },
      },
    },
  },
});

export const SuccessBox = withStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))(Box);

export const CloseIcon = withStyles(() => ({
  root: {
    right: 0,
    top: 0,
    position: 'absolute',
    paddingRight: '36px',
    paddingTop: '29px',
    cursor: 'pointer',
    color: '#c7c7c7',
  },
}))(Box);

export const SyncSuccessText = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '10px',
    textAlign: 'center',
    color: '#000000',

    [theme.breakpoints.only('xl')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '17px',
    },
  },
}))(Typography);

export const SyncCaption = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',

    lineHeight: '25px',
    marginTop: '12px',
    textAlign: 'center',
    color: '#a9a3a3',
    [theme.breakpoints.only('xl')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
}))(Typography);

export const BackIcon = withStyles(() => ({
  root: {
    position: 'absolute',
    paddingLeft: '30px',
    cursor: 'pointer',
    color: '#08A88E',
  },
}))(Box);

export const StyledStepper = withStyles({
  root: {
    backgroundColor: 'transparent',
    '&$checked': {
      color: '#08A88E',
    },
  },
})(Stepper);

export const StyledStepConnector = withStyles({
  lineHorizontal: {
    borderTopStyle: 'dotted',
    borderTopWidth: '2px',
    borderLeft: '5px',
  },
})(StepConnector);

export const StyledStepLabel = withStyles({
  root: {
    fontStyle: 'normal',
    color: '#9E9E9E',
  },
  completed: {
    color: '#9E9E9E !important',
  },
  active: {
    color: '#707070 !important',
    fontWeight: 'bold !important',
  },
  label: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
    },
  },
})(StepLabel);

export const StyledPaper = withStyles((theme) => ({
  root: {
    padding: '0',
    //borderRadius: '40px',
    margin: '0',
    position: 'relative',
    width: '62%',
    height: '65%',
    [theme.breakpoints.down('1430')]: {
      height: '75%',
    },
    boxShadow:
      '0px -8px 20px rgba(0, 0, 0, 0.04), 0px -3px 4px rgba(0, 0, 0, 0.04), 0px 12px 17px rgba(0, 0, 0, 0.02), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '20px',
    paddingRight: '44px',
    paddingTop: '25px',
    // boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.08)',
  },
}))(Paper);

export const ChannelWrapper = withStyles((theme) => ({
  root: {
    height: '85%',
    [theme.breakpoints.down('1660')]: {
      height: '76%',
    },
    width: '100%',
  },
}))(Box);
export const SyncButton = withStyles({
  root: {
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    textTransform: 'none',
    width: '191px',
    height: '44px',
    position: 'absolute',
    right: '0',
    borderRadius: '4px !important',
    marginRight: '30px',
  },
})(Button);
