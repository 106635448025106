import setTeam from 'actions/team/selectedTeamAction';
import NavBar from 'components/bars/NavBar';
import ModuleList from 'components/home/ModuleList';
import Footer from 'components/layout/Footer';
import { EntireScreenLoading } from 'components/loader/EntireScreenLoading';
import 'css/custom.css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CREATE_TEAM_ROUTE } from '../../constants';
import { useManualButton } from '../../hooks/useManualButton';
import { useTeam } from '../../hooks/useTeam';

const Home = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);

  const { team } = useTeam();
  const { setVisible } = useManualButton();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadInfo = () => {
      setVisible(false);

      dispatch(setTeam());

      setLoading(false);
      setVisible(true);
    };
    loadInfo();
  }, []);

  useEffect(() => {
    if (!isLoading && !team.id) {
      history.push(CREATE_TEAM_ROUTE);
    }
  }, [isLoading, team, history]);

  return (
    <div className='ht-100'>
      <div className='second-container'>
        <NavBar />
        <div className='ml-auto mr-auto w-full pl-[15px] pr-[15px]'>
          {isLoading || !team.id ? <EntireScreenLoading /> : <ModuleList />}
        </div>
        <div className='separate' />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
