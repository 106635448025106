import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { auth } from 'api/firebase';
import { AxiosResponse } from 'axios';
import { SigninFormData } from 'components/accounts/signin/SigninForm';
import {
  sendEmailVerification,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { useAtom } from 'jotai';
import { RESET, atomWithStorage } from 'jotai/utils';
import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import { GET_MODULE } from '../actions/actionsTypes';
import { SignUpRequest, userAPI } from '../api/user';
import { useTeam } from './useTeam';

export const INITIAL_AUTHENTICATION_VALUE: Authentication = {
  auth_type: 'knox',
  token_expiry: '',
  token: '',
  google_login: false,
  isLoggedIn: false,
};

export const authAtom = atomWithStorage('authentication', INITIAL_AUTHENTICATION_VALUE, undefined, {
  getOnInit: true,
});

export interface User {
  email: string;
  first_name: string;
  id: number;
  institution: string;
  is_staff: boolean;
  job_title: string;
  last_name: string;
  last_selected_team: number;
  medical_specialties: string[];
  name: string;
  profile_picture: string | null;
  role: string[];
  subscribed_to_newsletter: boolean;
  user_type: string;
}

export interface TinyUser {
  id: number; // need to remove after refactoring
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  profile_picture: string | null;
  last_selected_team: number; // need to remove after refactoring
  user_type: 'trial' | 'basic'; // need to remove after refactoring
}

export interface Authentication {
  auth_type: 'firebase' | 'knox';
  google_login: boolean;
  token: string;
  token_expiry: string;
  user?: User | TinyUser;
  isLoggedIn: boolean;
  providerId?: 'google.com' | 'password';
}

interface UseAuthentication {
  signIn: (data: SigninFormData) => Promise<void>;
  signUp: UseMutateAsyncFunction<any, unknown, SignUpRequest, unknown>;
  logout:
    | UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
    | (() => Promise<void>);
  isLoggedIn: boolean;
  authentication: Authentication;
  reset: () => void;
  refetch: () => void;
}

export function useAuthentication(): UseAuthentication {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [authentication, setAuthentication] = useAtom(authAtom);
  const reset = () => {
    setAuthentication(RESET);
    queryClient.clear();
    // Module state must be deleted since the state of the previous module remains the same.
    dispatch({
      type: GET_MODULE,
      payload: null,
    });
  };
  const { refetch: refetchQuery } = useQuery(['authentication'], userAPI.getUserFullInfo, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: authentication.isLoggedIn,
  });
  const refetch = () => {
    refetchQuery()
      .then((result) =>
        setAuthentication({
          ...authentication,
          user: result.data ?? authentication.user,
        })
      )
      .catch(() => setAuthentication(INITIAL_AUTHENTICATION_VALUE));
  };
  const { mutateAsync: knox_signIn } = useMutation(userAPI.signIn, {
    onSuccess: async (data) => {
      setAuthentication({ ...data, isLoggedIn: true, auth_type: 'knox' });
    },
  });
  const { mutateAsync: signUp } = useMutation(userAPI.signUp, {
    onSuccess: async (data) => {
      await signInWithCustomToken(auth, data.custom_token);
      if (auth.currentUser) sendEmailVerification(auth.currentUser);
      // setAuthentication(data);
    },
  });
  const { mutate: knox_logout } = useMutation(userAPI.logout, {
    onSuccess: reset,
  });

  const signIn = async (data: SigninFormData) => {
    try {
      await signInWithEmailAndPassword(auth, data.email.toLocaleLowerCase(), data.password);
    } catch {
      await knox_signIn({
        ...data,
        email: data.email.toLocaleLowerCase(),
      });
    }
  };

  return {
    signIn,
    signUp,
    logout:
      authentication.auth_type == 'knox'
        ? knox_logout
        : () => {
            reset();
            signOut(auth);
          },
    reset,
    refetch,
    authentication,
    isLoggedIn: authentication.isLoggedIn,
  };
}

// TODO: Remove when replace classComponent to functionalComponent
// HOC for component with authentication
export function withAuthentication<P extends object>(WrappedComponent: ComponentType<P>) {
  return (props: P) => {
    const { signUp, logout, authentication, isLoggedIn } = useAuthentication();
    return (
      <WrappedComponent
        signUp={signUp}
        logout={logout}
        authentication={authentication}
        isLoggedIn={isLoggedIn}
        {...props}
      />
    );
  };
}

// HOC for component with superuser permission
export const withFeaturePermission = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const { authentication } = useAuthentication();
    return !!authentication.user?.is_staff ? <WrappedComponent {...props} /> : null;
  };
};

// HOC for component for staff users of tiptap team
export const withTiptapPermission = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const { team } = useTeam();
    const { authentication } = useAuthentication();
    return !!authentication.user?.is_staff && team.name === 'Tiptap Integration' ? (
      <WrappedComponent {...props} />
    ) : null;
  };
};
