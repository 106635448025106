import { MainLayout } from 'components/layout/MainLayout';
import Button from 'components/utils/Button';
import { SlidePanel } from 'components/utils/panels/SlidePanel';
import { H3 } from 'components/utils/typo';
import { useState } from 'react';
import EhrConfigForm from '../components/ehr/EhrConfigForm';
import EhrConfigList from '../components/ehr/EhrConfigList';

export const EHRConfig = () => {
  const [isPaneOpen, setIsPaneOpen] = useState(false);

  return (
    <MainLayout>
      <div>
        <div className='m-10 sm:w-1/2'>
          <div className='mb-4 flex items-center justify-between'>
            <H3>EHR Configs</H3>
            <Button
              className='!w-fit font-bold focus:bg-primary-500'
              onClick={() => setIsPaneOpen(true)}
            >
              Add New
            </Button>
          </div>
          <EhrConfigList />
        </div>

        <SlidePanel open={isPaneOpen} onClose={() => setIsPaneOpen(false)}>
          <div className='w-[620px] px-[20px]'>
            <EhrConfigForm closeModal={() => setIsPaneOpen(false)} />
          </div>
        </SlidePanel>
      </div>
    </MainLayout>
  );
};
