import { Component } from 'react';
import { connect } from 'react-redux';

class TextHighlighter extends Component {
  render() {
    const text = this.props.text.split(' ');
    let mainArray = [];
    let mainString = '';
    let orignalFormText = [];
    const unique_code_mapping = this.props.uniqueCodes;

    let regex = {
      choice: /\bchoice_\w+_code\b/g,
      formula: /\bformula_\w+_code\b/g,
      infobox: /\binfobox_\w+_code\b/g,
      reference: /\breference_\w+_code\b/g,
      trigger: /\btrigger_\w+_code\b/g,
      variable: /\bvariable_\w+_code\b/g,
      numeric: /\bnumeric_\w+_code\b/g,
      custom_numeric: /\bcustomnumeric_\w+_code\b/g,
    };

    // eslint-disable-next-line
    text.map((item, index) => {
      let flag = false;
      for (let key in regex) {
        let match = item.match(regex[key]);

        if (Array.isArray(match) && match.length) {
          flag = true;
          if (
            unique_code_mapping.hasOwnProperty(key) &&
            unique_code_mapping[key].hasOwnProperty(match[0])
          ) {
            mainArray.push(unique_code_mapping[key][match[0]]);
          } else {
            mainArray.push(match[0]);
          }
        }
      }
      // if any item is not among any of 7 codes then it's a simple text (Show as it is)
      if (!flag) {
        // Bold AND and OR text
        if (item === 'AND' || item === 'OR') {
          mainArray.push(item);
        } else {
          mainArray.push(item + '');
        }
      }
    });

    mainString = mainArray.join(' ');
    if (mainString.includes('AND') || mainString.includes('OR')) {
      orignalFormText = mainString.split(/(AND|OR)/);
    } else {
      orignalFormText.push(mainString);
    }
    for (let i = 0; i < orignalFormText.length; i++) {
      if (orignalFormText[i].includes('NOT')) {
        let b = orignalFormText[i];
        let c = b.split(/(NOT)/);
        orignalFormText.splice(i, 1);
        orignalFormText = c.concat(orignalFormText);
        orignalFormText = orignalFormText.filter((item) => item);
      }
    }
    return (
      <span>
        {
          // eslint-disable-next-line
          orignalFormText.map((item, index) => {
            if (item === ' ') {
              return '';
            }
            if (item === 'AND' || item === 'OR' || item === 'NOT') {
              return (
                <span
                  key={index}
                  style={{
                    fontWeight: 900,
                    color: '#A0A0A0',

                    background: '#F4F6F9',
                    padding: '5px 5px 25px 5px',
                    borderRadius: '3px',
                    display: this.props.triggerPopup ? 'grid' : 'inline-block',
                    placeItems: 'center',
                    marginTop: this.props.triggerPopup ? '5px' : '1px',
                    marginBottom: this.props.triggerPopup ? '5px' : '1px',
                    minWidth: '10px',
                    maxWidth: '40px',
                    marginRight: '3px',
                    marginLeft: '3px',
                    height: '26px',
                  }}
                >
                  {' '}
                  {item}{' '}
                </span>
              );
            } else {
              if (item.includes('is not available') || item.includes('is available')) {
                let x = [];
                let needChange = '';
                if (item.includes('is not available')) {
                  x = item.split(/(is not available)/);
                  for (let i = 0; i < x.length; i++) {
                    if (x[i] === 'is not available') {
                      needChange = i;
                      break;
                    }
                  }
                }
                if (item.includes('is available')) {
                  x = item.split(/(is available)/);
                  for (let i = 0; i < x.length; i++) {
                    if (x[i] === 'is available') {
                      needChange = i;
                      break;
                    }
                  }
                }
                return (
                  <span
                    id='output'
                    key={index}
                    style={{
                      fontWeight: 900,
                      color: '#000',
                      background: '#E4F4FE',
                      padding: '5px 5px 5px 5px',
                      borderRadius: '3px',
                      border: '1px solid #DAE6F1',
                      display: 'inline-block',
                      marginRight: '1px',
                      marginBottom: '1px',
                    }}
                  >
                    {x.map((item, index) => {
                      if (index === needChange) {
                        return (
                          <span
                            style={{
                              color: '#4D94D3',
                              fontWeight: '800',
                              borderColor: '#DAE6F1',
                            }}
                          >
                            {item}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              fontWeight: '400',
                            }}
                          >
                            {item}
                          </span>
                        );
                      }
                    })}
                  </span>
                );
              } else {
                let y = '';
                if (item.includes('≠')) {
                  y = item.split(/(≠)/);
                } else {
                  y = item.split(/(=)/);
                }
                let needChange = '';
                for (let i = 0; i < y.length; i++) {
                  if (y[i] === '=' || y[i] === '≠') {
                    needChange = i + 1;
                    break;
                  }
                }
                if (item.includes('_delet_code')) {
                  return (
                    <span
                      key={index}
                      style={{
                        fontWeight: 900,
                        color: '#A42D24',
                        background: '#F8D7DA',
                        padding: '5px 5px 25px 5px',
                        borderRadius: '3px',
                        border: '1px solid #F8D7DA',
                        display: 'inline-block',
                        marginRight: '1px',
                        marginBottom: '1px',
                        height: '26px',
                      }}
                    >
                      {item}
                    </span>
                  );
                }
                return (
                  <span
                    id='output'
                    key={index}
                    style={{
                      fontWeight: 900,
                      color: '#000',
                      background: '#E4F4FE',
                      padding: '5px 5px 5px 5px',
                      borderRadius: '3px',
                      border: '1px solid #DAE6F1',
                      display: 'inline-block',
                      marginRight: '1px',
                      marginBottom: '1px',
                      wordBreak: 'break-all',
                    }}
                  >
                    {y.map((item, index) => {
                      if (index === needChange) {
                        return (
                          <span
                            style={{
                              color: '#4D94D3',
                              fontWeight: '800',
                              borderColor: '#DAE6F1',
                            }}
                          >
                            {item}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              fontWeight: '400',
                            }}
                          >
                            {item}
                          </span>
                        );
                      }
                    })}
                  </span>
                );
              }
            }
          })
        }
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  uniqueCodes: state.uniqueCodesState.uniqueCodes,
});

export default connect(mapStateToProps)(TextHighlighter);
