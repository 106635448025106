import NavBar from '../../bars/NavBar';
import { EHROrderIndividualTable } from './index';

export const OldIndividualEHROrder = () => (
  <div>
    <NavBar />
    <div className='mx-auto max-w-[1280px] px-[40px] pt-[40px]'>
      <EHROrderIndividualTable />
    </div>
  </div>
);
