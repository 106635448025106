export const PermissionMap = {
  full_access: {
    label: 'Full access',
    value: 'full_access',
    subtitle: 'Can edit and publish to the channel.',
  },
  can_edit: {
    label: 'Can edit',
    value: 'can_edit',
    subtitle: 'Can edit, but not publish to the channel.',
  },
  can_view: {
    label: 'Can view',
    value: 'can_view',
    subtitle: 'Cannot edit or publish to the channel.',
  },
  owner: {
    label: 'Owner',
    value: 'owner',
    subtitle: 'Can edit, delete and publish to the channel.',
  },
  has_no_access: {
    label: 'Has no access',
    value: 'has_no_access',
    subtitle: 'Cannot edit or publish to the channel.',
  },
};

export const PermissionOptions = [
  PermissionMap.full_access,
  PermissionMap.can_edit,
  PermissionMap.can_view,
];

export enum Permissions {
  FULL_ACCESS = 'full_access',
  CAN_EDIT = 'can_edit',
  CAN_VIEW = 'can_view',
  OWNER = 'owner',
  HAS_NO_ACCESS = 'has_no_access',
}

export const HAS_PUBLISH_PERMISSIONS = [Permissions.FULL_ACCESS, Permissions.OWNER];

export const HAS_EDIT_PERMISSIONS = [...HAS_PUBLISH_PERMISSIONS, Permissions.CAN_EDIT];

export const HAS_VIEW_PERMISSIONS = [...HAS_EDIT_PERMISSIONS, Permissions.CAN_VIEW];
