import { useQueryClient } from '@tanstack/react-query';
import { Editor } from '@tiptap/core';
import ConditionalTextForm from 'components/resources/conditional-texts/form';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext } from 'react';
import SlidingPane from 'react-sliding-pane';

interface TiptapAddNewItemProps {
  id?: string;
  editor: Editor;
  open: boolean;
  itemType: string;
  toggleModal: () => void;
}

export const AddNewItem = ({ id, editor, open, itemType, toggleModal }: TiptapAddNewItemProps) => {
  const queryClient = useQueryClient();
  const { module: selectedModule } = useContext(ModuleContext);
  const addItem = (item) => {
    queryClient.invalidateQueries([
      'module',
      selectedModule?.type,
      selectedModule?.id,
      'resources',
    ]);
    if (id) {
      editor
        .chain()
        .focus()
        .updateAttributes('mention', { name: item?.title || item?.name || item?.source })
        .run();
    } else {
      editor.commands.insertContent({
        type: 'mention',
        attrs: {
          id: item?.id,
          type: item.type,
          code: item?.unique_code,
          name: item?.name || item?.title || item?.source,
        },
      });
    }
  };

  return (
    <>
      <SlidingPane
        from='right'
        width='1210px'
        onRequestClose={toggleModal}
        isOpen={open && itemType === 'infobox'}
        overlayClassName='!z-[40]'
        className='sliding-panel-shadow'
      >
        <InfoBoxForm
          infoBoxId={id}
          isFullHeight={true}
          type={selectedModule?.type}
          toggleModal={toggleModal}
          savePosition={() => []}
          selectValue={addItem}
        />
      </SlidingPane>

      <SlidingPane
        width='627px'
        from='bottom'
        isOpen={open && itemType === 'conditional_text'}
        onRequestClose={toggleModal}
        className='sliding-panel-shadow conditional-text-popup-infobox'
        overlayClassName='sliding-panel-cards conditional-text-popup-overlay conditional-text-overlay-for-zindex'
      >
        <ConditionalTextForm
          addItem={addItem}
          conditionalTextId={id}
          toggleModal={toggleModal}
          savePosition={() => []}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        width='622px'
        from='bottom'
        isOpen={open && itemType === 'reference'}
        onRequestClose={toggleModal}
        className='sliding-panel-shadow conditional-text-popup-infobox'
        overlayClassName='sliding-panel-cards pointer-events-none !z-[40]'
      >
        <RefManagerForm
          isFullHeight
          addItem={addItem}
          refManagerId={id}
          type={selectedModule?.type}
          toggleModal={toggleModal}
          savePosition={() => []}
        />
      </SlidingPane>
    </>
  );
};
