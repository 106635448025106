import * as Sentry from '@sentry/react';
import axios from 'axios';
import { SIMULATION_TOKEN_API_URL } from 'constants/index';
import { MODULE_TYPES } from 'constants/moduleStatuses';

export const simulateInWebapp = (moduleId: number | string, type: string) => {
  Sentry.withScope((scope) => {
    scope.setTag('moduleId', moduleId);
    scope.setTag('type', type);
    scope.setLevel('debug');
    let startTime: number = performance.now(); // Measure start time
    axios.get(SIMULATION_TOKEN_API_URL).then((result) => {
      let endTime: number = performance.now();
      Sentry.addBreadcrumb({
        category: 'log',
        message: 'Get simulation token',
        data: {
          startTime: startTime,
          endTime: endTime,
          elapsedTime: endTime - startTime,
        },
      });

      startTime = performance.now();
      let link = '';
      switch (type) {
        case MODULE_TYPES.CALCULATOR:
          link = `${process.env.REACT_APP_WEB_APP_URL}/calc_simulation?simulate=${result.data.simulation_token}&calculatorId=${moduleId}`;
          break;
        case MODULE_TYPES.ALGO:
          link = `${process.env.REACT_APP_WEB_APP_URL}/simulation?simulate=${result.data.simulation_token}&moduleId=${moduleId}`;
          break;
        default:
          break;
      }

      const url = `${window.origin}/simulate?link=${window.encodeURIComponent(link)}`;
      window.open(
        url,
        'avoWindow',
        `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=${window.screen.width},
        height=${window.screen.height}`
      );
      endTime = performance.now();
      Sentry.addBreadcrumb({
        category: 'log',
        message: 'Open simulator',
        data: {
          startTime: startTime,
          endTime: endTime,
          elapsedTime: endTime - startTime,
        },
      });
      Sentry.captureMessage('loading simulator', scope);
    });
  });
};

export const setDraggableId = (item) => {
  const id = item.editMode
    ? item.id
    : 'resourcetype' in item
      ? item.id
      : 'container_' + item.id.toString();

  return id ? id.toString() : ''; // draggableId should be string
};
