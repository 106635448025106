import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import { Modal } from 'components/utils/modals/Modal';
import { useState } from 'react';

interface AddNewAuthorTitleModalProps {
  open: boolean;
  onClose: () => void;
  onEnter: (title: string) => void;
}

const AddNewAuthorTitleModal = ({ open, onClose, onEnter }: AddNewAuthorTitleModalProps) => {
  const [newTitle, setNewTitle] = useState<string>();
  const onSubmit = () => {
    if (!newTitle) return;
    onEnter(newTitle);
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Head onClose={onClose}>Add New Author Title</Modal.Head>
      <Modal.Body className='space-y-2'>
        <div>Create Author Title</div>
        <Input
          placeholder='Enter new author title'
          autoFocus={true}
          onChange={(e) => setNewTitle(e.currentTarget.value)}
        />
        <div className='flex justify-end space-x-1 pt-4'>
          <div>
            <Button.Reverse type='button' className='px-4 py-2' onClick={onClose}>
              Cancel
            </Button.Reverse>
          </div>
          <div>
            <Button type='button' className='px-8 py-2' onClick={onSubmit} disabled={!newTitle}>
              Add
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewAuthorTitleModal;
