import { SET_PATH } from 'actions/actionsTypes';

const pathReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PATH:
      return {
        ...state,
        prevPath: action.prevPath,
      };

    default:
      return state;
  }
};

export default pathReducer;
