import getModules from 'actions/modules/modulesAction';
import NavBar from 'components/bars/NavBar';
import { connect } from 'react-redux';
import { MODULE_TYPES } from '../../constants';
import { ModuleForm as NewModuleForm } from 'components/module-detail/ModuleForm';

interface ModuleFormProps {
  moduleType: MODULE_TYPES;
}

const ModuleForm = ({ moduleType }: ModuleFormProps) => {
  return (
    <>
      <div className='min-h-full bg-[#f4f4f4]'>
        <NavBar />
        <NewModuleForm moduleType={moduleType} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps, { getModules })(ModuleForm);
