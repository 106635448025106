import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import Avatar from 'components/utils/Avatar';
import { Fragment, useEffect, useState } from 'react';
import { PermissionMap, PermissionOptions } from '../../../constants';
import { useTeam } from '../../../hooks/useTeam';

interface DefaultPermissionItemProps {
  onDefaultPermissionChange: (permission: string) => void;
  defaultPermission?: string;
}

const DefaultPermissionItem = ({
  onDefaultPermissionChange,
  defaultPermission,
}: DefaultPermissionItemProps) => {
  const { team } = useTeam();
  const teamName = team.name;
  const [permission, setPermission] = useState(defaultPermission || PermissionMap.can_view.value);
  useEffect(() => {
    onDefaultPermissionChange(permission);
  }, [permission]);
  return (
    <div className='flex items-center justify-between gap-2 border-b border-gray-300'>
      <div className='flex w-3/4 items-center space-x-3 px-[12px] py-[10px]'>
        <Avatar />
        <div>Everyone else in {teamName}</div>
      </div>

      <Listbox as='div' className='relative w-1/4' onChange={setPermission} value={permission}>
        {({ open }) => (
          <>
            <Listbox.Button className='flex w-full items-center justify-between rounded border !border-transparent px-[12px] py-[10px]'>
              <div className='text-gray-500'>{PermissionMap[permission]?.label}</div>
              <div className={clsx('transition-all', { 'rotate-180': open })}>
                <DropdownArrowIcon />
              </div>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Listbox.Options className='absolute z-10 w-44 overflow-y-auto rounded border border-gray-500 bg-white'>
                {PermissionOptions.concat(PermissionMap.has_no_access).map((per, idx) => (
                  <Listbox.Option
                    key={idx}
                    value={per.value}
                    className={({ active }) =>
                      clsx('cursor-pointer px-[12px] py-[10px]', {
                        'bg-primary-200': active,
                      })
                    }
                  >
                    {({ selected }) => (
                      <div className='flex justify-between'>
                        <div>{per.label}</div>
                        {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default DefaultPermissionItem;
