import Tooltip from '@material-ui/core/Tooltip';
import Bold from 'assets/icons/tiptap/bold';
import BulletList from 'assets/icons/tiptap/bulletList';
import Highlight from 'assets/icons/tiptap/highlight';
import Italic from 'assets/icons/tiptap/italic';
import OrderedList from 'assets/icons/tiptap/orderedList';
import TableInsert from 'assets/icons/tiptap/tableInsert';
import { twMerge } from 'tailwind-merge';

export const TiptapButtonBold = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Bold'} placement='top' key='bold'>
      <button
        key='bold'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBold().run();
        }}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={twMerge('mr-2 items-center', editor.isActive('bold') && 'bg-gray-bg-selected')}
      >
        <Bold />
      </button>
    </Tooltip>
  );
};

export const TiptapButtonItalic = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Italic'} placement='top' key='italic'>
      <button
        key='italic'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleItalic().run();
        }}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={twMerge(
          'mr-2 items-center pr-1',
          editor.isActive('italic') && 'bg-gray-bg-selected'
        )}
      >
        <Italic />
      </button>
    </Tooltip>
  );
};

export const TiptapButtonHighlight = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Highlight'} placement='top' key='highlight'>
      <button
        key='highlight'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHighlight().run();
        }}
        className={twMerge(
          'items-center pr-1',
          editor.isActive('highlight') && 'bg-gray-bg-selected'
        )}
      >
        <Highlight />
      </button>
    </Tooltip>
  );
};

export const TiptapButtonBulletList = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Bulleted List'} placement='top' key='bulletList'>
      <button
        key='bulletList'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBulletList().run();
        }}
        className={twMerge(
          'mr-1 items-center pr-1',
          editor.isActive('bulletList') && 'bg-gray-bg-selected'
        )}
      >
        <BulletList />
      </button>
    </Tooltip>
  );
};

export const TiptapButtonOrderedList = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Numbered List'} placement='top' key='orderedList'>
      <button
        key='orderedList'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleOrderedList().run();
        }}
        className={twMerge(
          'items-center pr-1',
          editor.isActive('orderedList') && 'bg-gray-bg-selected'
        )}
      >
        <OrderedList />
      </button>
    </Tooltip>
  );
};

export const TiptapButtonTable = (props) => {
  const { editor } = props;

  return (
    <Tooltip title={'Table'} placement='top' key='table'>
      <button
        key='table'
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
        }}
      >
        <TableInsert />
      </button>
    </Tooltip>
  );
};
