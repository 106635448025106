import { Box, Paper } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';

import { ShareLinkIcon } from 'assets/icons/shareLinkIcon';
import SyncInfoIcon from 'assets/icons/syncInfoIcon';
import Button from 'components/utils/Button';
import { StaffOnlyWrapper, StaffOnlyBadgeType } from 'components/utils/StaffOnlyWrapper';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { format, isBefore, isEqual, parseISO, setMilliseconds } from 'date-fns';
import { getCharNumOnly, getReadableURL } from 'utils/utilityFunctions';
import CopyLink from './copyLink';

import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  ShareLinkMessage,
  StyledPopover,
} from './styles';
import { MODULE_TYPES } from '../../../../constants';

interface ShareLinkProps {
  calculatorId?: string;
  openSyncModal: () => void;
}
const ShareLink: React.FC<ShareLinkProps> = (props) => {
  const { module } = useContext(ModuleContext);
  const displayedModuleName = module?.type === MODULE_TYPES.ALGO ? 'Algo' : 'Calculator';

  const [link, setLink] = useState<string | null | undefined>(null);
  const [newLink, setNewLink] = useState<string | null | undefined>(null);
  const [showLink, setShowLink] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const openLink = Boolean(anchor);
  const generateLink = link ? 'simple-popper' : undefined;

  const getModuleSharingLink = async (event: React.MouseEvent<HTMLElement>) => {
    const currentTarget = event.currentTarget;
    try {
      const link = `${process.env.REACT_APP_WEB_APP_URL}/dashboard/${module?.code}`;
      const newLink = `${process.env.REACT_APP_WEB_APP_URL}/algo/${getReadableURL(
        module?.title ?? ''
      )}-${getCharNumOnly(module?.code ?? '')}`;
      setAnchor(currentTarget);
      setLink(link);
      setNewLink(newLink);
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const getCalculatorShareLink = async (event: React.MouseEvent<HTMLElement>) => {
    const currentTarget = event.currentTarget;
    try {
      const link = module && `${process.env.REACT_APP_WEB_APP_URL}/calculator/${module.code}`;
      const newLink =
        module &&
        `${process.env.REACT_APP_WEB_APP_URL}/calc/${getReadableURL(module.title)}-${getCharNumOnly(
          module.code
        )}`;
      module && setLink(link);
      module && setNewLink(newLink);
      setAnchor(currentTarget);
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    if (anchor === null) {
      setTimeout(() => setShowLink(false), 200);
    }
  }, [anchor]);

  // The values of `last_synced` and `last_updated` are either strings, null, or undefined. Therefore, date conversion is necessary.
  // To avoid the need for date conversion, must pass the correct date type to enable date comparisons.

  const lastSynced = !!module?.last_synced
    ? setMilliseconds(parseISO(module?.last_synced), 0)
    : new Date(0);
  const lastUpdated = !!module?.last_updated
    ? setMilliseconds(parseISO(module?.last_updated), 0)
    : new Date(0);

  return (
    <div>
      <Button.Outline
        onClick={(e) => (props.calculatorId ? getCalculatorShareLink(e) : getModuleSharingLink(e))}
        className='!rounded-full px-[12px] py-[6px]'
      >
        <div className='flex items-center space-x-1'>
          <ShareLinkIcon />
          <span className='whitespace-nowrap'>Share Link</span>
        </div>
      </Button.Outline>

      <StyledPopover
        id={generateLink}
        open={openLink}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Paper style={{ minWidth: '580px' }}>
          <Box style={{ padding: 20 }}>
            {isEqual(lastSynced, new Date(0)) ? (
              <Box style={{ display: 'flex' }}>
                <SyncInfoIcon style={{ marginRight: 6, marginTop: 3 }} />
                <ShareLinkMessage>
                  Sync is required before sharing this {displayedModuleName}.
                </ShareLinkMessage>
              </Box>
            ) : isBefore(lastSynced, lastUpdated) && !showLink ? (
              <Box>
                <ShareLinkMessage>
                  {`This ${displayedModuleName} was last synced on ${format(
                    lastSynced,
                    'MMM d, y, HH:mm a'
                  )}.`}
                </ShareLinkMessage>
                <ShareLinkMessage>
                  To share the latest version of the {displayedModuleName}, sync is needed.
                </ShareLinkMessage>
              </Box>
            ) : (
              isEqual(lastSynced, lastUpdated) && (
                <>
                  <CopyLink link={link} />
                  <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                    <div className='mt-2 rounded border p-3'>
                      <div className='text-sm font-bold'>
                        Superuser-only view: Readable URL. Will be applied soon{' '}
                      </div>
                      <div>{newLink}</div>
                    </div>
                  </StaffOnlyWrapper>
                </>
              )
            )}

            {(isEqual(lastSynced, new Date(0)) ||
              (isBefore(lastSynced, lastUpdated) && !showLink)) && (
              <ButtonContainer>
                {isEqual(lastSynced, new Date(0)) ? (
                  <SecondaryButton onClick={() => setAnchor(null)}> Cancel </SecondaryButton>
                ) : (
                  isBefore(lastSynced, lastUpdated) &&
                  !showLink && (
                    <SecondaryButton onClick={() => setShowLink(true)}>
                      {' '}
                      Share without sync{' '}
                    </SecondaryButton>
                  )
                )}

                <PrimaryButton style={{ minWidth: 110 }} onClick={props.openSyncModal}>
                  Publish
                </PrimaryButton>
              </ButtonContainer>
            )}

            {showLink && <CopyLink link={link} />}
            {showLink && (
              <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                <div className='mt-2 rounded border p-3'>
                  <div className='text-sm font-bold'>
                    Superuser-only view: Readable URL. Will be applied soon
                  </div>
                  <div>{newLink}</div>
                </div>
              </StaffOnlyWrapper>
            )}
          </Box>
        </Paper>
      </StyledPopover>
    </div>
  );
};

export default ShareLink;
