import { format } from 'date-fns';
import { ReactNode, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ErrorIcon } from '../../assets/icons/error';
import { ModuleWithMirror, READ_ONLY_STATUS } from '../../hooks/useModules';
import { ModulePopover, SectionInfo } from './ModulePopover';

interface ModuleStatusProps {
  module: ModuleWithMirror;
}

interface StatusInfo {
  chip?: ReactNode;
  bgColor: string;
  textColor: string;
}

const PENDING_PUBLISH_STATUS = 'Pending publish';

const MODULE_STATUS_INFO: Record<string, StatusInfo> = {
  [PENDING_PUBLISH_STATUS]: {
    chip: <ErrorIcon fill='#FF8E3D' />,
    bgColor: 'bg-contents-02/10',
    textColor: 'text-contents-02',
  },
  [READ_ONLY_STATUS]: {
    bgColor: 'bg-gray-200',
    textColor: 'text-gray-700',
  },
  Draft: {
    bgColor: 'bg-contents-01/10',
    textColor: 'text-contents-01',
  },
  Published: {
    bgColor: 'bg-primary-600/10',
    textColor: 'text-primary-600',
  },
  // TODO: need update
  'Under Revision': {
    bgColor: 'bg-primary-200',
    textColor: 'text-primary-600',
  },
};

export const ModuleStatus = ({ module }: ModuleStatusProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const sectionInfoList: SectionInfo[] = useMemo(() => {
    switch (module.status) {
      case READ_ONLY_STATUS:
        return [
          {
            head: 'Duplicated',
            description: format(new Date(module.created), 'MMM d, y, HH:mm a'),
            owner: 'by ' + module.ownerName,
          },
          {
            head: 'Origin module',
            description: module.title,
            owner: module.team?.name,
          },
        ];
      case PENDING_PUBLISH_STATUS:
        return [
          {
            head: 'Last published',
            description: module.last_synced
              ? format(new Date(module.last_synced), 'MMM d, y, HH:mm a')
              : '',
            owner: 'by ' + module.ownerName,
          },
          {
            head: 'Last updated',
            additionalHead: 'not published',
            description: format(new Date(module.last_updated), 'MMM d, y, HH:mm a'),
            owner: 'by ' + module.last_updated_by?.name,
          },
        ];
    }
    return [];
  }, [module]);
  return (
    <>
      <div className='flex' onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <div
          className={twMerge(
            MODULE_STATUS_INFO[module.status].bgColor,
            'flex shrink items-center gap-[2px] rounded px-[8px] py-[4px]'
          )}
        >
          {MODULE_STATUS_INFO[module.status].chip}
          <p className={MODULE_STATUS_INFO[module.status].textColor}>{module.status}</p>
        </div>
      </div>
      {sectionInfoList.length > 0 && (
        <ModulePopover
          open={open}
          onClose={() => setOpen(false)}
          sectionInfoList={sectionInfoList}
        />
      )}
    </>
  );
};
