import { QueryBuilder } from 'components/resources/triggers/QueryBuilder';
import {
  convertCondition,
  removePrefixesInCondition,
} from 'components/resources/triggers/SituationBuilderUtils';
import { CreateButton } from 'components/utils/styled-components/FormStyle';
import { Component } from 'react';
import { Form } from 'reactstrap';

interface ConditionBuilderProps {
  condition: any;
  conditionLogic: any;
  builderJsonTree: any;
  toggleModal: () => void;
  setCondition: Function;
  calculatorId: any;
  type: any;
  moduleId?: any;
}
interface ConditionBuilderState {
  condition: any;
  conditionLogic: any;
  builderJsonTree: any;
}
class ConditionBuilder extends Component<ConditionBuilderProps, ConditionBuilderState> {
  constructor(props: ConditionBuilderProps) {
    super(props);

    this.state = {
      condition: '',
      conditionLogic: undefined,
      builderJsonTree: null,
    };
  }

  componentDidMount() {
    this.setState({
      condition: this.props.condition,
      conditionLogic: this.props.conditionLogic,
      builderJsonTree: this.props.builderJsonTree,
    });
  }

  setBuilderCondition = (e) => {
    let condition = removePrefixesInCondition(e);
    let index = condition.indexOf('IN');

    while (index !== -1) {
      condition = convertCondition(condition, index);
      index = condition.indexOf('IN');
    }

    this.setState({
      condition: condition,
    });
  };

  setConditionLogic = (e) => this.setState({ conditionLogic: e });

  setQueryValue = (e) => this.setState({ builderJsonTree: e });

  setChoiceMapping = (e) => this.setState({ choiceMapping: e } as Pick<ConditionBuilderState, any>);

  saveCondition = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.toggleModal();
    this.props.setCondition(
      this.state.condition,
      this.state.conditionLogic,
      this.state.builderJsonTree
    );
  };

  render() {
    const queryBuilderProps = {
      calculatorId: this.props.calculatorId,
      type: this.props.type,
      moduleId: this.props.moduleId,
      setCondition: this.setBuilderCondition,
      setConditionLogic: this.setConditionLogic,
      setQueryValue: this.setQueryValue,
      queryValue: this.state.builderJsonTree ? this.state.builderJsonTree : null,
      setChoiceMapping: this.setChoiceMapping,
    };
    return (
      <div className='row' style={{ height: '100%' }}>
        <div className='col-md-12' style={{ height: '100%' }}>
          <Form onSubmit={this.saveCondition} style={{ height: '100%', display: 'grid' }}>
            <QueryBuilder {...queryBuilderProps} />
            <CreateButton
              type='submit'
              name='action'
              style={{ alignSelf: 'flex-end', justifySelf: 'end', marginBottom: '20px' }}
            >
              {this.props.builderJsonTree ? 'Update' : 'Create'}
            </CreateButton>
          </Form>
        </div>
      </div>
    );
  }
}

export default ConditionBuilder;
