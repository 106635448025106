import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { POST_AUTO_TAGS, TAGS } from '../constants';

export interface ITag {
  code: string;
  title: string;
}
export interface ITagNode extends ITag {
  read_only?: boolean;
  hidden?: boolean;
  expanded: boolean;
  children: ITagNode[];
}

interface AutoTagVariable {
  title: string;
  description: string;
}

interface AutoTagData {
  tagging_success: boolean;
  tag_codes?: string[];
  hidden_tag_codes?: string[];
  fail_reason?: string;
}

export const tagAPI = {
  getTagTree: ({ queryKey }: QueryFunctionContext) =>
    axios.get<ITagNode>(TAGS + `?team_id=${queryKey[2]}`).then((response) => response.data),
  postAutoTag: (data: AutoTagVariable) =>
    axios.post<AutoTagData>(POST_AUTO_TAGS, data).then((response) => response.data),
  postHiddenTag: (data: AutoTagVariable) =>
    axios
      .post<AutoTagData>(POST_AUTO_TAGS + '?hidden_only=true', data)
      .then((response) => response.data),
};
