import { Box } from '@material-ui/core';
import { CustomToast } from 'components/utils/toast-message';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { LinkField, PrimaryButton, ShareLinkMessage } from './styles';

const CopyLink = ({ link }) => {
  const [buttonText, setButtonText] = useState('Copy');

  const copySuccess = () => {
    toast.success(CustomToast, { data: 'Link is copied into the clipboard' });
    setButtonText('Copied!');
  };

  return (
    <>
      <ShareLinkMessage style={{ fontWeight: 700, fontSize: 16 }}>Copy Link</ShareLinkMessage>

      <Box style={{ display: 'flex', marginTop: 20 }}>
        <LinkField> {link} </LinkField>
        <CopyToClipboard text={link} onCopy={copySuccess}>
          <PrimaryButton style={{ minWidth: 76 }}> {buttonText} </PrimaryButton>
        </CopyToClipboard>
      </Box>
    </>
  );
};

export default CopyLink;
