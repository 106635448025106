import Button from 'components/utils/Button';
import { useEffect, useState } from 'react';
import AddSourceModal, { ISource } from './AddSourceModal';
import SourceItem from './SourceItem';

interface SourceControllerProps {
  onChange: (sources: ISource[]) => void;
  defaultSources?: ISource[];
}

const SourceController = ({ onChange, defaultSources = [] }: SourceControllerProps) => {
  const [open, setOpen] = useState(false);
  const [sources, setSources] = useState<ISource[]>(defaultSources);

  useEffect(() => {
    onChange(sources);
  }, [sources]);
  return (
    <div>
      <div className='flex'>
        <div>
          <Button className='font-bold' type='button' onClick={() => setOpen(true)}>
            Add Source
          </Button>
        </div>
        <AddSourceModal
          open={open}
          onClose={() => setOpen(false)}
          onAdd={(source) => setSources((prev) => [...prev, source])}
        />
      </div>
      {sources.map((source) => (
        <SourceItem
          key={source.id}
          source={source}
          onDelete={(id) => setSources((prev) => prev.filter((s) => s.id !== id))}
        />
      ))}
    </div>
  );
};

export default SourceController;
