function Variable(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#B0BDC4' />
      <g clip-path='url(#clip0_4603_970)'>
        <path
          d='M26 26H27.3333C28.6667 26 28.6667 27.3333 30.0213 30.7027C31.3333 34 31.3333 35.3333 32.6667 35.3333H34'
          stroke='#1E1F20'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M24.6667 35.3333C26.6667 35.3333 28.6667 32.6667 30.0001 30.6667C31.3334 28.6667 33.3334 26 35.3334 26'
          stroke='#1E1F20'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.5377 19.3333H21.5055C20.2696 19.3333 19.3779 19.6633 18.8302 20.3233C18.2824 20.9974 18.0086 22.2403 18.0086 24.052V25.2948C18.0086 26.9099 17.7979 28.0334 17.3766 28.6653C16.9553 29.2973 16.2742 29.6133 15.3333 29.6133V31.7199C16.2742 31.7199 16.9553 32.0359 17.3766 32.6678C17.7979 33.3138 18.0086 34.4373 18.0086 36.0383V37.3023C18.0086 39.0999 18.2824 40.3357 18.8302 41.0098C19.3779 41.6699 20.2696 41.9999 21.5055 41.9999H22.5377V40.3147H22.1375C21.337 40.3147 20.7822 40.1391 20.4733 39.788C20.1643 39.451 20.0098 38.6294 20.0098 37.3233V36.5018C20.0098 34.7182 19.7992 33.4051 19.3779 32.5625C18.9706 31.7058 18.2684 31.0809 17.2713 30.6876C18.2684 30.2663 18.9706 29.6344 19.3779 28.7917C19.7992 27.9491 20.0098 26.636 20.0098 24.8525V24.0098C20.0098 22.7178 20.1643 21.8962 20.4733 21.5451C20.7822 21.1941 21.337 21.0185 22.1375 21.0185H22.5377V19.3333Z'
          fill='#1E1F20'
          stroke='#1E1F20'
          stroke-linejoin='round'
        />
        <path
          d='M36.6666 19.3333H37.6989C38.9347 19.3333 39.8265 19.6633 40.3742 20.3233C40.9219 20.9974 41.1958 22.2403 41.1958 24.052V25.2948C41.1958 26.9099 41.4064 28.0334 41.8277 28.6653C42.249 29.2973 42.9302 29.6133 43.8711 29.6133V31.7199C42.9302 31.7199 42.249 32.0359 41.8277 32.6678C41.4064 33.3138 41.1958 34.4373 41.1958 36.0383V37.3023C41.1958 39.0999 40.9219 40.3357 40.3742 41.0098C39.8265 41.6699 38.9347 41.9999 37.6989 41.9999H36.6666V40.3147H37.0669C37.8674 40.3147 38.4221 40.1391 38.7311 39.788C39.04 39.451 39.1945 38.6294 39.1945 37.3233V36.5018C39.1945 34.7182 39.4052 33.4051 39.8265 32.5625C40.2338 31.7058 40.9359 31.0809 41.9331 30.6876C40.9359 30.2663 40.2338 29.6344 39.8265 28.7917C39.4052 27.9491 39.1945 26.636 39.1945 24.8525V24.0098C39.1945 22.7178 39.04 21.8962 38.7311 21.5451C38.4221 21.1941 37.8674 21.0185 37.0669 21.0185H36.6666V19.3333Z'
          fill='#1E1F20'
          stroke='#1E1F20'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4603_970'>
          <rect width='32' height='32' fill='white' transform='translate(14 14)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Variable;
