import CancelIcon from '@material-ui/icons/Cancel';
import EmptyStateIcon from 'assets/icons/emptyStateIcon';
import SearchIcon from 'assets/icons/searchIcon';
import axios from 'axios';
import clsx from 'clsx';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ALGO_CREAT_ROUTE,
  BUTTON_CUSTOMIZER_API_URL,
  CALC_CREATE_ROUTE,
  KB_CREATE_ROUTE,
} from '../../constants';
import { useTeam } from '../../hooks/useTeam';
import { getUniqueModuleKey, organizeModules } from '../utils/module';
import { CustomPopover } from '../utils/popover/Popover';
import { StyledTableSortLabel } from '../utils/styled-components/ModuleCalcRowStyle';
import CreateSubButton from './CreateSubButton';
import ModuleCalcRow from './ModuleCalcRow';

const ModuleList = () => {
  const [createButton, setCreateButton] = useState(false);
  const [buttons, setButtons] = useState<any>(null);
  const [orderDirection, setOrderDirection] = useState<any>('desc');
  const [sortBy, setSortBy] = useState('last_updated');
  const [keyword, setKeyword] = useState('');
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const { team } = useTeam();
  const modulesState = useSelector((state: any) => state.modulesState);
  const teamCalculatorState = useSelector((state: any) => state.teamCalculatorState);
  const mirrorState = useSelector((state: any) => state.mirrorState);
  const knowledgeBaseState = useSelector((state: any) => state.knowledgeBaseState);

  const handleSortRequest = () => {
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    axios.get(BUTTON_CUSTOMIZER_API_URL).then((res) => {
      setButtons(res.data);
    });
  }, []);

  const openPopup = () => {
    setCreateButton(true);
  };

  const closePopup = () => {
    setCreateButton(false);
  };

  const handleCreateClick = (event) => {
    const isTrialTeam = team.team_type === 'trial';
    const modules = modulesState?.modules || [];
    const calculators = teamCalculatorState?.teamCalculators || [];
    const knowledgeBases = knowledgeBaseState?.knowledgeBases || [];
    const modulesNCalcs = modules.concat(calculators, knowledgeBases);

    if (isTrialTeam && modulesNCalcs.length >= 5) {
      setIsLimitExceeded(true);
    } else {
      openPopup();
    }
  };

  const modules = [
    ...(modulesState?.modules || []),
    ...(teamCalculatorState?.teamCalculators || []),
    ...(knowledgeBaseState?.knowledgeBases || []),
  ];
  const mirrors = mirrorState?.mirrors || [];
  const moduleWithMirrorList = organizeModules(modules, mirrors, keyword, sortBy, orderDirection);

  return (
    <React.Fragment>
      <div className='flex flex-col items-stretch justify-center'>
        <div className='mb-6 mt-14 flex items-center px-4'>
          <p className='leading-11 mb-0 ml-2 text-[32px] font-bold text-gray-700'>Your Lists</p>
          <div className='ml-auto mr-5 box-border flex h-12 w-96 items-center'>
            <input
              className='m-0 flex-1 !border-none p-0 pl-6 text-base leading-normal text-gray-900 placeholder-gray-300 shadow-none placeholder:text-lg focus:border-none focus:outline-none'
              type='text'
              placeholder='Search Module'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button className='hover:bg-opacity- px-3 hover:rounded-full hover:bg-gray-50'>
              <SearchIcon className='h-6 w-6' />
            </button>
          </div>
          <button
            onClick={handleCreateClick}
            className='grid h-12 w-40 place-items-center rounded-full bg-primary-bg-strongHover text-base font-semibold text-white hover:bg-primary-bg-strongHover focus:bg-primary-bg-strongHover'
          >
            Create
          </button>
          <div ref={buttonRef} className='relative'>
            <CustomPopover open={createButton} onClose={closePopup} classes='right-0 top-[25px]'>
              <div className='w-[340px] bg-white bg-opacity-25 px-6 shadow-lg'>
                <div className='flex h-[60px] items-center justify-between'>
                  <h3 style={{ float: 'left', color: 'black' }}>Pick a type </h3>
                  <button
                    onClick={closePopup}
                    className='hover:bg-opacity- ml-[100px] hover:rounded-full hover:bg-gray-50'
                  >
                    <CancelIcon color='disabled' />
                  </button>
                </div>
                <div>
                  {buttons &&
                    buttons.pathfinder_creation &&
                    Object.entries(buttons.pathfinder_creation).map(([index, button]: any) => (
                      <CreateSubButton
                        key={index}
                        buttonText={button.text}
                        buttonImage={button.image}
                        buttonHref={
                          index === 'avo_assistant'
                            ? ALGO_CREAT_ROUTE
                            : index === 'knowledge_base'
                            ? KB_CREATE_ROUTE
                            : index === 'medical_calc'
                            ? CALC_CREATE_ROUTE
                            : '#'
                        }
                        toolTipImage={button.tooltip}
                        tootTipHelpText={button.tooltip_helptext}
                        disabled={button.is_disabled}
                      />
                    ))}
                </div>
              </div>
            </CustomPopover>
          </div>
        </div>
        <div className='ml-4 mr-4'>
          {keyword.length > 0 && moduleWithMirrorList.length <= 0 ? (
            <div className='flex h-[60vh] w-full flex-col items-center justify-center'>
              <p className='text-center text-base font-semibold leading-6 text-gray-700'>
                No results found for '{`${keyword}`}'
              </p>
            </div>
          ) : !(moduleWithMirrorList.length > 0) ? (
            <div className='flex h-[60vh] w-full flex-col items-center justify-center'>
              {' '}
              <EmptyStateIcon />
              <p className='mb-3 mt-12 text-center text-2xl font-bold leading-8 text-gray-900'>
                You don’t have any modules yet!
              </p>
              <p className='text-base font-normal leading-5 text-gray-700'>
                To make a module, click Create in the top right corner
              </p>
            </div>
          ) : (
            <div
              className={clsx(
                'flex rounded-lg shadow-[0_1rem_3rem_rgba(0,0,0,.175)]',
                moduleWithMirrorList?.length > 5 ? 'mb-[30px]' : 'mb-0'
              )}
            >
              <table className='flex-1' aria-label='customized table'>
                <thead className='border-b-[1px] border-solid border-[#d0d0d0]'>
                  <tr>
                    <th
                      className='w-[50vh] rounded-none border-b-0 border-white bg-white py-[15px] pl-[50px] pr-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                      align='left'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'name'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('name');
                          handleSortRequest();
                        }}
                      >
                        Name
                      </StyledTableSortLabel>
                    </th>
                    <th
                      align='left'
                      className='w-[15px] rounded-none border-b-0 border-white bg-white py-[15px] pl-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'owner'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('owner');
                          handleSortRequest();
                        }}
                      >
                        Owner
                      </StyledTableSortLabel>
                    </th>
                    <th
                      className='w-[15px] rounded-none border-b-0 border-white bg-white py-[15px] pl-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                      align='left'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'current_user_role'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('current_user_role');
                          handleSortRequest();
                        }}
                      >
                        Your Role
                      </StyledTableSortLabel>
                    </th>
                    <th
                      className='w-[15px] rounded-none border-b-0 border-white bg-white py-[15px] pl-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                      align='left'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'status'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('status');
                          handleSortRequest();
                        }}
                      >
                        Status
                      </StyledTableSortLabel>
                    </th>
                    <th
                      align='left'
                      className='w-[15px] rounded-none border-b-0 border-white bg-white py-[15px] pl-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'type'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('type');
                          handleSortRequest();
                        }}
                      >
                        Type
                      </StyledTableSortLabel>
                    </th>

                    <th
                      className='w-[15px] rounded-none border-b-0 border-white bg-white py-[15px] pl-[5px] text-lg font-bold leading-[2.5vh] text-[#A2A2A2]'
                      align='left'
                    >
                      <StyledTableSortLabel
                        active={sortBy === 'last_updated'}
                        direction={orderDirection}
                        onClick={() => {
                          setSortBy('last_updated');
                          handleSortRequest();
                        }}
                      >
                        Last Updated
                      </StyledTableSortLabel>
                    </th>
                    <th align='center' className='w-[15px] border-b-0 border-white pr-6'></th>
                  </tr>
                </thead>
                <tbody>
                  {moduleWithMirrorList.map((module: any) => {
                    const key = getUniqueModuleKey(module);
                    return <ModuleCalcRow key={key} module={module} />;
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        open={isLimitExceeded}
        confirmText='Close'
        content='You have reached the limit of creating 5 modules in your trial account.'
        toggleModal={() => setIsLimitExceeded(false)}
        performAction={() => setIsLimitExceeded(false)}
      />
    </React.Fragment>
  );
};

export default ModuleList;
