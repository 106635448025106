import {
  addChild,
  blockToNodeMapping,
  createNode,
  createText,
  entityToNodeMapping,
} from '@tiptap/draftjs-to-tiptap';
import { isHeading } from './utils';
import { AvoNodeMapping } from './types';

declare module '@tiptap/draftjs-to-tiptap' {
  export function createNode<TNodeType extends keyof AvoNodeMapping>(
    type: TNodeType,
    options?: Partial<Omit<AvoNodeMapping[TNodeType], 'type'>>
  ): AvoNodeMapping[TNodeType];
}

// The current implementation is not suitable for some conversions.
// This has been modified to apply to 'unstyled' as well.
// https://github.com/ueberdosis/draft-js-to-tiptap/blob/main/src/mappings/blockToNode.ts#L175
blockToNodeMapping.unstyled = (context) => {
  const { doc, block, entityMap, converter } = context;
  const paragraph = createNode('paragraph');
  if (!block.text) {
    return paragraph;
  }
  if (block.inlineStyleRanges.length === 0) {
    if (block.entityRanges.length === 0) {
      // Plain text, fast path
      return addChild(paragraph, createText(block.text));
    }
  }

  if (isHeading(block.inlineStyleRanges)) {
    return blockToNodeMapping[block.type]({
      ...context,
      block: { ...block, type: 'header-three' },
    });
  }

  return addChild(
    paragraph,
    converter.splitTextByEntityRangesAndInlineStyleRanges({
      doc,
      block,
      entityMap,
    })
  );
};

// https://github.com/ueberdosis/draft-js-to-tiptap/blob/main/src/mappings/entityToNode.ts#L17
entityToNodeMapping.IMAGE = ({ entity }) => {
  return createNode('image', {
    attrs: {
      src: entity.data.src,
      alt: entity.data.alt || null,
      title: null,
      width: 160,
      height: 108,
      float:
        !entity.data.alignment || entity.data.alignment === 'default'
          ? 'left'
          : entity.data.alignment,
    },
  });
};
