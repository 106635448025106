import { GET_CONDITIONAL_TEXTS } from 'actions/actionsTypes';

const conditionalTextReducer = (state = { conditionalTexts: [] }, action) => {
  switch (action.type) {
    case GET_CONDITIONAL_TEXTS:
      return {
        ...state,
        conditionalTexts: action.payload,
      };

    default:
      return state;
  }
};

export default conditionalTextReducer;
