function BulletList(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='texteditor/bulleted list'>
        <rect id='Rectangle 3463334' x='6' y='4' width='12' height='2' rx='0.2' fill='#2E3438' />
        <rect id='Rectangle 3463335' x='6' y='9' width='12' height='2' rx='0.2' fill='#2E3438' />
        <rect id='Rectangle 3463336' x='6' y='15' width='12' height='2' rx='0.2' fill='#2E3438' />
        <circle id='Ellipse 1110' cx='3.41563' cy='4.9' r='1.4' fill='#2E3438' />
        <circle id='Ellipse 1111' cx='3.41563' cy='9.9' r='1.4' fill='#2E3438' />
        <circle id='Ellipse 1112' cx='3.4' cy='15.9' r='1.4' fill='#2E3438' />
      </g>
    </svg>
  );
}

export default BulletList;
