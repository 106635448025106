import QuestionMark from 'assets/icons/questionMark';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface ManualButtonEffectProps {
  showEffect: boolean;
}

const numEffect = 4;

const ManualButtonEffect = ({ showEffect = false }: ManualButtonEffectProps) => {
  const [effect, setEffect] = useState(showEffect);
  useEffect(() => {
    if (effect) {
      setEffect(false);
    }
  }, []);
  return (
    <AnimatePresence>
      {effect &&
        Array.from({ length: numEffect }, (_, idx) => (
          <motion.div
            key={idx}
            className='absolute top-0 left-0 w-full h-full bg-primary-500 rounded-full -z-10'
            initial={{ scale: 1, opacity: 1 }}
            exit={{ scale: 8, opacity: 0, transition: { duration: 1.5, delay: idx * 0.7 } }}
          />
        ))}
    </AnimatePresence>
  );
};

interface ManualButtonProps {
  effect?: boolean;
}

const ManualButton = ({ effect }: ManualButtonProps) => {
  const location = useLocation();
  return (
    <div
      className={twMerge(
        'fixed right-[45px]',
        location?.pathname === '/' ? 'bottom-[10px]' : 'bottom-[60px]'
      )}
    >
      <a
        href='https://avomd.notion.site/AvoMD-Manual-dd88da18941244559c4d76cbd5be67e9'
        target='_blank'
        rel='noreferrer noopener'
      >
        <div className='relative w-[54px] h-[54px] bg-white rounded-full flex justify-center items-center shadow'>
          <ManualButtonEffect showEffect={!!effect} />
          <QuestionMark />
        </div>
      </a>
    </div>
  );
};

export default ManualButton;
