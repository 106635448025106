import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { Fragment, ReactNode, useContext, useMemo, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { moduleAPI } from '../../../api/module';
import { Integration, resourcesAPI } from '../../../api/resources';
import { MainSearchIcon } from '../../../assets/icons/MainSearchIcon';
import { hasViewOnlyPermission } from '../../../utils/permissions';
import CalculatorIntegrationForm from '../../calculator/calculatorIntegrationForm';
import { Icons } from '../../utils/Icons';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { Caption2, H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';

interface RequiredResourceFormProps {
  integrations: Integration[];
  calculatorId: string | null;
  moduleId: string;
  editableIntegration?: Integration;
  editor: boolean;
  closePanel: () => void;
}

const labels = {
  title: 'Integrations',
  input: 'integration',
  button: 'Integration',
  data: 'integrations',
};

export const CalculatorIntegrationResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const { data, refetch } = useQuery(
    [module?.type, module?.id, 'integrations'],
    resourcesAPI.getIntegrations,
    {
      enabled: !!module,
      initialData: [],
    }
  );

  const [open, setOpen] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);

  const { data: calculators } = useQuery(
    [module?.type, module?.id, 'calculators'],
    moduleAPI.getIntegrationCalculators,
    {
      enabled: !hasViewOnlyPermission(module?.type),
      initialData: [],
    }
  );
  const [searchText, setSearchText] = useState<string>('');
  const filteredCalculators = useMemo(
    () =>
      calculators.filter((calculator) =>
        calculator.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    [calculators, searchText]
  );

  const onOpen = (resourceId: string | null) => {
    setResourceId(resourceId);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    refetch();
  };

  const resourceFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      integrations: data,
      calculatorId: resourceId,
      moduleId: module?.id + '',
      editableIntegration: data.find(
        (integration) => integration.integrated_calculator === resourceId
      ),
      editor: data.some((integration) => integration.integrated_calculator === resourceId),
      closePanel: onClose,
    }),
    [open, module]
  );

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={data}
        renderCard={(resource) => ResourceCard(resource, onOpen)}
        onOpen={() => {}}
      >
        <ResourceTable.PopoverPanel>
          <div className='mb-2'>
            <div className='m-3 flex w-[290px] items-center gap-[4px] rounded border border-gray-300 bg-white p-2'>
              <MainSearchIcon />
              <input
                className='m-0 !h-[20px] w-full !border-none text-[14px] leading-[20px] text-gray-900 placeholder-gray-300 shadow-none focus:border-none focus:outline-none'
                type='text'
                placeholder='Search Calculator'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className='select-none text-center'>
              {filteredCalculators.length > 0 ? (
                filteredCalculators.map((calculator) => (
                  <div
                    className='cursor-pointer px-3 py-2 hover:bg-primary-200'
                    onClick={() => onOpen(calculator.id)}
                    key={calculator.id}
                  >
                    {calculator.name}
                  </div>
                ))
              ) : (
                <Caption2 className='text-gray-700'>No item found</Caption2>
              )}
            </div>
          </div>
        </ResourceTable.PopoverPanel>
      </ResourceTable>
      <SlidingPane
        isOpen={open}
        onRequestClose={onClose}
        from='right'
        hideHeader
        className='no-padding sliding-panel-shadow'
        width='1145px'
      >
        <div className='mt-14'>
          <CalculatorIntegrationForm {...resourceFormProps} />
        </div>
      </SlidingPane>
    </>
  );
};

const ResourceCard = (
  resource: Integration,
  onOpen: (resourceId: string | null) => void
): ReactNode => {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(resourcesAPI.deleteIntegration, {
    onSuccess: () => queryClient.invalidateQueries([module?.type, module?.id, 'integrations']),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.integrated_calculator);
  const onDelete = () => {
    mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none divide-x divide-gray-200 rounded-xl bg-white py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-1/2 space-y-2 px-[20px]'>
          <Caption2 className='text-gray-700'>Title</Caption2>
          <H4>{resource.integrated_calculator_info.name}</H4>
        </div>
        <div className='flex w-1/2 px-[20px]'>
          <div className='w-full space-y-2'>
            {Array.isArray(resource.numerical_output_mappings) &&
              resource.numerical_output_mappings.length > 0 && (
                <div className='space-y-2'>
                  <Caption2 className='mb-2 text-gray-700'>Numerical</Caption2>
                  {resource.numerical_output_mappings.map((numericalOutput) => (
                    <Fragment key={numericalOutput.id}>
                      <span className='rounded bg-contents-01/10 p-1 text-contents-01'>
                        {numericalOutput.id}
                      </span>
                      <div className='flex border-b border-gray-300'>
                        <Caption2>{numericalOutput.numerical_output_name}</Caption2>
                        <Icons.ArrowRight className='h-4' />
                        <Caption2>
                          {numericalOutput.numeric_name || numericalOutput.custom_numeric_name}
                        </Caption2>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
            {Array.isArray(resource.categorical_output_mappings) &&
              resource.categorical_output_mappings.length > 0 && (
                <div className='space-y-2'>
                  <Caption2 className='mb-2 text-gray-700'>Numerical</Caption2>
                  {resource.categorical_output_mappings.map((categoricalOutput) => (
                    <Fragment key={categoricalOutput.id}>
                      <span className='rounded bg-contents-01/10 p-1 text-contents-01'>
                        {categoricalOutput.id}
                      </span>
                      <div className='flex'>
                        <Caption2>{categoricalOutput.output_category_label}</Caption2>
                        <Icons.ArrowRight className='h-4' />
                        <Caption2>{categoricalOutput.choice_name ?? 'Not Selected'}</Caption2>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
          </div>
          {hasEditPermission && (
            <FloatingActionButton
              open={open}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={toggleDelete}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this reference?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
