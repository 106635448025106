import { Menu, Transition } from '@headlessui/react';
import Avatar from 'components/utils/Avatar';
import { Icons } from 'components/utils/Icons';
import {
  AVOMD_CONTACT_US,
  AVOMD_PRIVACY_POLICY,
  AVOMD_TERMS_OF_USE,
} from 'constants/api-endpoints-external';
import { useAuthentication } from 'hooks/useAuthentication';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { PROFILE } from 'routes/urls';
import { twJoin } from 'tailwind-merge';

export const ProfileMenu = () => {
  const {
    authentication: { user },
    logout,
  } = useAuthentication();
  return (
    <Menu as='div' className='relative flex items-center justify-center'>
      <Menu.Button>
        <Avatar name={user?.name} size='lg' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 top-full mt-2 w-[240px] origin-top-right divide-y divide-gray-200 overflow-hidden rounded bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <Menu.Item>
            <Link to={PROFILE}>
              <button className='w-full'>
                <div className='flex items-center justify-between p-[16px]'>
                  <div className='flex items-center gap-[8px]'>
                    <Avatar name={user?.name} size='lg' />
                    <div>
                      <div className='text-left text-heading-4 text-gray-900'>{user?.name}</div>
                      <div className='text-left text-caption-2 text-gray-700'>{user?.email}</div>
                    </div>
                  </div>
                  <div>
                    <Icons.ChevronLeft className='rotate-180' />
                  </div>
                </div>
              </button>
            </Link>
          </Menu.Item>
          <div>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => window.open(AVOMD_CONTACT_US, '_blank', 'noopener,noreferrer')}
                  className={twJoin(
                    active ? 'bg-primary-500 text-white' : 'text-gray-900',
                    'flex w-full items-center px-[12px] py-[10px] text-body-2'
                  )}
                >
                  Contact Us
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => logout()}
                  className={twJoin(
                    active ? 'bg-primary-500 text-white' : 'text-gray-900',
                    'flex w-full items-center px-[12px] py-[10px] text-body-2'
                  )}
                >
                  Sign Out
                </button>
              )}
            </Menu.Item>
          </div>
          <div className='flex items-center space-x-[12px] px-[12px] py-[8px] text-caption-2 text-gray-700'>
            <Menu.Item>
              <a target='_blank' href={AVOMD_PRIVACY_POLICY} rel='noopener noreferrer'>
                Privacy Policy
              </a>
            </Menu.Item>
            <Menu.Item>
              <a target='_blank' href={AVOMD_TERMS_OF_USE} rel='noopener noreferrer'>
                Terms of Use
              </a>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
