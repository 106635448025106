import clsx from 'clsx';
import { ChangeEvent, InputHTMLAttributes, forwardRef, useState } from 'react';
import { ReactComponent as Eyeslash } from '../../assets/icons/eyeslash.svg';
interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {}
const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, onChange, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      setHasValue(e.currentTarget.value.length > 0);
    };

    return (
      <div className='relative'>
        <input
          {...rest}
          onChange={onInputChange}
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          className={clsx(
            className,
            'h-auto mb-0', // because of material UI. need to remove later
            'rounded !box-border w-full text-body-2 !px-[12px] !py-[10px] bg-white border !border-gray-300',
            'pr-[40px]'
          )}
        />
        {hasValue && (
          <button
            type='button'
            tabIndex={-1}
            className='absolute right-[12px] top-1/2 -translate-y-1/2'
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <Eyeslash /> : <Eyeslash />}
          </button>
        )}
      </div>
    );
  }
);

export default PasswordInput;
