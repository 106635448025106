import { GET_FORMULAS } from 'actions/actionsTypes';

const formulasReducer = (state = { formulas: [] }, action) => {
  switch (action.type) {
    case GET_FORMULAS:
      return {
        ...state,
        formulas: action.payload,
      };

    default:
      return state;
  }
};

export default formulasReducer;
