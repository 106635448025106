import { SVGProps } from 'react';

export const KnowledgeBaseIcon = ({ fill = '#08A88E', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 3.7439H21C21.8281 3.74472 22.4992 4.41581 22.5 5.2439V18.7439C22.4988 19.5718 21.8279 20.2427 21 20.2439H3C2.17192 20.2431 1.50083 19.572 1.5 18.7439V5.2439C1.50083 4.41581 2.17192 3.74472 3 3.7439ZM11.25 5.2439H3V18.7439H11.25V5.2439ZM12.75 18.7439V5.2439H21L21.0015 18.7439H12.75ZM19.5 7.4999H14.25V8.9999H19.5V7.4999ZM14.25 11.2499H19.5V12.7499H14.25V11.2499ZM19.5 14.9999H14.25V16.4999H19.5V14.9999Z'
        fill='#6C7C83'
      />
    </svg>
  );
};
