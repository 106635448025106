import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import { useEffect, useState } from 'react';
import { isValidURL } from 'utils/utilityFunctions';
import { Editor } from '@tiptap/core';

interface EditLinkProps {
  text: string;
  prevLink: string;
  editor: Editor;
}

export const EditLink = ({ text, prevLink, editor }: EditLinkProps) => {
  const [textToDisplay, setTextToDisplay] = useState<string>(text || '');
  const [link, setLink] = useState<string>(prevLink || '');
  const isSaveDisabled = !link || (!textToDisplay as boolean);

  useEffect(() => {
    setTextToDisplay(text || '');
    setLink(prevLink || '');
  }, [text, prevLink]);

  const onAdd = () => {
    if (isValidURL(link)) {
      editor
        .chain()
        .focus()
        .setCustomLink({
          href: link,
          title: textToDisplay,
        })
        .run();
    }
  };

  return (
    <div className='flex w-[340px] flex-col gap-4 rounded bg-white !p-4 shadow-04'>
      <Input
        type='url'
        value={link}
        onChange={(event) => setLink(event.target.value)}
        placeholder='https://example.com'
        className='text-body-2 text-gray-900'
      />
      <Input
        type='text'
        value={textToDisplay}
        onChange={(event) => setTextToDisplay(event.target.value)}
        placeholder='Text to display'
        className='text-body-2 text-gray-900'
      />
      <div className='flex justify-end gap-2'>
        {/* <Button.Reverse
          type='button'
          className='flex h-9 !w-20 items-center justify-center text-button-1'
        >
          Cancel
        </Button.Reverse> */}
        <Button
          type='button'
          onClick={onAdd}
          disabled={isSaveDisabled}
          className='flex h-9 !w-20 items-center justify-center text-button-1'
        >
          Save
        </Button>
      </div>
    </div>
  );
};
