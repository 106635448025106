import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Auxiliary from 'components/utils/hoc/Auxiliary';
import { useState } from 'react';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const Triggers = (props) => {
  const usedIns = props.trigger.used_in;
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Auxiliary>
      {props.runMore && Array.isArray(usedIns) && usedIns.length > 3 && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            placement='left-start'
            classes={{
              tooltip: {
                maxWidth: 500,
              },
            }}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <div
                style={{
                  padding: '35px 20px 20px 35px',
                }}
              >
                <p
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#C6C6C6',
                    marginBottom: '15px',
                  }}
                >
                  USED IN
                </p>
                <div>
                  {usedIns.map((usedIn, index) => {
                    return (
                      <div
                        style={{
                          width: '413px',
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            marginBottom: '10px',
                            display: 'inline-block',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            key={index}
                            style={{
                              border: '1px solid white',
                              backgroundColor: '#F0F0F0',
                              color: '#868686',
                              padding: '8px',
                              borderRadius: '3px',
                              textTransform: 'uppercase',
                              fontSize: '12px',
                              lineHeight: '16px',
                            }}
                          >
                            {usedIn.type}
                          </span>
                        </div>
                        <div
                          key={index}
                          style={
                            {
                              // display: 'inline-grid',
                            }
                          }
                        >
                          <span
                            style={{
                              color: '#464646',
                              height: '100%',
                            }}
                          >
                            {
                              <p
                                style={{
                                  whiteSpace: 'normal',
                                  color: '#464646',
                                  fontSize: '18px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  width: '320px',
                                }}
                              >
                                {usedIn.name || 'Untitled'}
                              </p>
                            }
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          >
            <span>
              <Button id='more-triggers-button' onClick={handleTooltipOpen}>
                {usedIns.length - 3} + more
              </Button>
            </span>
          </LightTooltip>
        </ClickAwayListener>
      )}
      {props.onlyThree && (
        <div>
          {Array.isArray(usedIns) && usedIns.length ? (
            usedIns.map((usedIn, index) => {
              return (
                index <= 2 && (
                  <div key={index}>
                    <div
                      style={{
                        marginBottom: '10px',
                        display: 'inline-block',
                        marginRight: '10px',
                      }}
                    >
                      <span
                        style={{
                          border: '1px solid white',
                          backgroundColor: '#F0F0F0',
                          color: '#868686',
                          padding: '8px',
                          borderRadius: '3px',
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          lineHeight: '16px',
                        }}
                      >
                        {usedIn.type}
                      </span>
                    </div>
                    <div style={{ display: 'inline-grid' }}>
                      <span
                        style={{
                          color: '#464646',
                          height: '100%',
                        }}
                      >
                        {
                          <p
                            style={{
                              whiteSpace: 'break-spaces',
                              color: '#464646',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '24px',
                            }}
                          >
                            {usedIn.name || 'Untitled'}
                          </p>
                        }
                      </span>
                    </div>
                  </div>
                )
              );
            })
          ) : (
            <span
              style={{
                border: '1px solid white',
                backgroundColor: '#F0F0F0',
                color: '#868686',
                marginRight: '5',
                marginBottom: '5',
                padding: '8px',
                borderRadius: '3px',
              }}
            >
              Not Used
            </span>
          )}
        </div>
      )}
    </Auxiliary>
  );
};

export default Triggers;
