function TableInsert(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 1024 1024'
      version='1.1'
      fill={props.fill || 'currentColor'}
    >
      <path d='M925.586 0H101.369C69.885 0 42.24 28.924 42.24 62.328v902.8c0 33.403 27.645 58.872 59.129 58.872h824.217c31.484 0 56.057-25.469 56.057-58.873V62.328C981.643 28.924 957.198 0 925.586 0zM373.719 735.908V543.932h276.445v191.976z m276.445 42.235v203.494H373.719V778.143z m287.964-276.446h-244.45V298.203h244.45z m-287.964 0H373.719V298.203h276.445z m-319.96 0H85.754V298.203h244.45z m-244.45 42.235h244.45v191.976H85.754z m607.925 0h244.449v191.976h-244.45zM101.369 42.235h824.217c7.807 0 12.542 10.366 12.542 20.093v193.64H85.755V62.328c0-9.727 7.807-20.093 15.614-20.093zM85.755 964.999V778.143h244.449v203.494H101.369c-7.807 0-15.614-6.91-15.614-16.51z m839.83 16.638H693.68V778.143h244.449v186.856c0 9.727-4.607 16.638-12.542 16.638z' />
    </svg>
  );
}

export default TableInsert;
