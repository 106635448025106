import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { numberAPI } from 'api/number';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import Tooltip from 'components/utils/Tooltip';
import { CustomToast } from 'components/utils/toast-message';
import { H3 } from 'components/utils/typo';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { CANCEL_BUTTON, CREATE, NUMBER_ROUTE, UPDATE } from '../../constants';
import TerminologyEditor from './TerminologyEditor';

interface InfoTooltipProps {
  info: string;
}

const InfoTooltip = ({ info }: InfoTooltipProps) => {
  return (
    <Tooltip>
      <Tooltip.Icon>
        <InformationCircleIcon className='h-[18px] w-[18px] text-primary-600' />
      </Tooltip.Icon>
      <Tooltip.Content className='before:w-3'>
        <div>{info}</div>
      </Tooltip.Content>
    </Tooltip>
  );
};

interface RouteParams {
  numberId?: string;
}

interface NumberFormVariable {
  title: string;
  second_title: string;
  unit: string;
  upper_limit: number | null;
  lower_limit: number | null;
  validity_period_days: number;
  terminology_code: string;
  terminology: string;
  term_loinc: string[];
  term_text: string[];
  term_regex: string[];
  secondary_unit: string;
  primary_to_secondary: string;
  secondary_to_primary: string;
  two_decimal_places: number | boolean | null;
}

const decimalPlaceOptions = [
  { value: 2, label: '2 Decimal' },
  { value: 1, label: '1 Decimal' },
  { value: 0, label: 'None' },
];

const NumberForm = () => {
  const history = useHistory();
  const { numberId } = useParams<RouteParams>();
  const [decimalPlaces, setDecimalPlaces] = useState(0);

  const { data: numberData, isLoading: isQuerying } = useQuery(
    ['numbers', numberId],
    numberAPI.getNumber,
    {
      enabled: !!numberId,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  const isLoading = isQuerying && !!numberId;

  const defaultValues = {
    title: '',
    second_title: '',
    unit: '',
    upper_limit: null,
    lower_limit: null,
    validity_period_days: 365,
    term_loinc: [],
    term_text: [],
    term_regex: [],
    secondary_unit: '',
    primary_to_secondary: '',
    secondary_to_primary: '',
    two_decimal_places: 0,
  };

  const { register, handleSubmit, setValue, reset, setFocus } = useForm<NumberFormVariable>({
    defaultValues,
  });

  useEffect(() => {
    if (!numberData || isQuerying) return;

    reset({
      title: numberData.title,
      second_title: numberData.second_title || '',
      unit: numberData.unit,
      upper_limit: numberData.upper_limit ?? null,
      lower_limit: numberData.lower_limit ?? null,
      validity_period_days: numberData.validity_period_days,
      term_loinc: numberData.term_loinc,
      term_text: numberData.term_text,
      term_regex: numberData.term_regex,
      secondary_unit: numberData.secondary_unit || '',
      primary_to_secondary: numberData.primary_to_secondary || '',
      secondary_to_primary: numberData.secondary_to_primary || '',
      two_decimal_places:
        numberData.two_decimal_places === true
          ? 2
          : numberData.two_decimal_places === false
          ? 1
          : 0,
    });
    setDecimalPlaces(
      numberData.two_decimal_places === true ? 2 : numberData.two_decimal_places === false ? 1 : 0
    );
  }, [reset, numberData, isQuerying]);

  const { mutate, isLoading: isMutating } = useMutation(
    !numberId
      ? numberAPI.postModule
      : (data: NumberFormVariable) => numberAPI.patchModule(data, numberId),
    {
      onSuccess: () => {
        history.push(NUMBER_ROUTE);
      },
    }
  );

  const validateLowerUpperLimit = (upper: number | null, lower: number | null) => {
    if (lower && upper && lower > upper) {
      toast.error(CustomToast, { data: 'Lower limit is bigger than upper limit.' });
      setFocus('upper_limit');
      return false;
    }
    return true;
  };

  const validateRegExps = (regexs: string[]) => {
    let validate = true;
    regexs.forEach((regex) => {
      try {
        new RegExp(regex);
      } catch {
        toast.error(CustomToast, { data: regex + ' is not valid Regex pattern. Please check it' });
        validate = false;
      }
    });
    return validate;
  };

  const validateValidationPeriod = (validationPeriod: number) => {
    if (validationPeriod < 0) {
      toast.warning(CustomToast, { data: 'Minimum value for validation period is 0' });
      return false;
    }
    return true;
  };

  const onSubmit = (data: NumberFormVariable) => {
    if (isMutating || isLoading) return;
    const upperLimit = data.upper_limit;
    const lowerLimit = data.lower_limit;
    if (!validateLowerUpperLimit(upperLimit, lowerLimit)) return;

    const regexs = data.term_regex;
    if (!validateRegExps(regexs)) return;

    if (!validateValidationPeriod(data.validity_period_days)) return;

    mutate({
      ...data,
      two_decimal_places:
        data.two_decimal_places === 2 ? true : data.two_decimal_places === 1 ? false : null,
    });
  };

  const decimalPlacesHandler = (select: number) => {
    setDecimalPlaces(select);
    setValue('two_decimal_places', select);
  };

  const onTerminologyChange = (loinc, text, regex) => {
    setValue('term_loinc', loinc);
    setValue('term_text', text);
    setValue('term_regex', regex);
  };

  return (
    <div className='mx-auto max-w-[800px] space-y-5 px-5 py-5'>
      <H3>{!numberId ? CREATE : UPDATE} Number</H3>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-10'>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Title</span>
                <InfoTooltip info='This is how the numeric is referred to' />
              </div>
              <Input
                {...register('title', {
                  required: true,
                  maxLength: 70,
                })}
                required
                maxLength={70}
                title="You can't use more than 70 characters."
              />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Second Title</span>
              </div>
              <Input
                {...register('second_title', {
                  maxLength: 255,
                })}
                maxLength={255}
              />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Unit</span>
                <InfoTooltip info='Ex. mg/dL' />
              </div>
              <Input
                {...register('unit', {
                  maxLength: 255,
                })}
                required
                maxLength={255}
              />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Upper Limit</span>
                <InfoTooltip info='Ex. 10' />
              </div>
              <Input
                {...register('upper_limit', { valueAsNumber: true })}
                type='number'
                step={0.001}
              />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Lower Limit</span>
                <InfoTooltip info='Ex. 3.5' />
              </div>
              <Input
                {...register('lower_limit', { valueAsNumber: true })}
                type='number'
                step={0.001}
              />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Validity Period</span>
                <InfoTooltip info='Validity period: [X] days.' />
              </div>
              <Input
                {...register('validity_period_days', { required: true })}
                type='number'
                required
              />
            </Label>
            <div className='space-y-2'>
              <div className='font-bold text-gray-800'>Terminology</div>
              <TerminologyEditor
                onChange={onTerminologyChange}
                defaultLoinc={numberData?.term_loinc}
                defaultText={numberData?.term_text}
                defaultRegex={numberData?.term_regex}
              />
            </div>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Secondary Unit</span>
              </div>
              <Input {...register('secondary_unit')} />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Primary to Secondary</span>
              </div>
              <Input {...register('primary_to_secondary', {})} />
            </Label>
            <Label className='block w-full'>
              <div className='flex items-center space-x-2'>
                <span className='font-bold text-gray-800'>Secondary to Primary</span>
              </div>
              <Input {...register('secondary_to_primary', {})} />
            </Label>
            <div className='space-y-2'>
              <div className='font-bold text-gray-800'>Decimal Places</div>
              <Listbox
                as='div'
                className='relative'
                onChange={decimalPlacesHandler}
                value={decimalPlaces}
              >
                {({ open }) => (
                  <>
                    <Listbox.Button
                      className={twMerge(
                        'flex w-full items-center justify-between rounded border border-gray-500 px-[12px] py-[10px]',
                        'focus:bg-transparent' // due to material
                      )}
                    >
                      {decimalPlaceOptions.find((option) => option?.value === decimalPlaces)?.label}
                      <div className={clsx('transition-all', { 'rotate-180': open })}>
                        <DropdownArrowIcon />
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      enter='transition duration-100 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-75 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 max-h-[300px] w-full overflow-y-auto rounded border border-gray-500 bg-white'>
                        {decimalPlaceOptions.map((option, idx) => (
                          <Listbox.Option
                            key={idx}
                            value={option.value}
                            className={({ active }) =>
                              clsx({
                                'bg-primary-200': active,
                              })
                            }
                          >
                            {({ selected }) => (
                              <div className='flex cursor-pointer items-center justify-between px-[12px] py-[10px]'>
                                <div>{option.label}</div>
                                {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className='mt-5 flex justify-end gap-2'>
            <div>
              <Button.Reverse type='button' onClick={() => history.push(NUMBER_ROUTE)}>
                {CANCEL_BUTTON}
              </Button.Reverse>
            </div>
            <div>
              <Button disabled={isMutating || isLoading}>
                {isMutating ? <LoadingSpinner /> : !numberId ? CREATE : UPDATE}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NumberForm;
