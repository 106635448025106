import { Box, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import SubTitleInput from 'components/utils/Input';
import Label from 'components/utils/Label';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import FilePreviewer from 'components/utils/file-handling/file-previewer';
import UploadFile from 'components/utils/file-handling/file-upload';
import InputField from 'components/utils/form-input/field';
import CharacterLimit from 'components/utils/form-input/fieldCharLimit';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import {
  CancelButton,
  CreateButton,
  InfoboxFormButton,
} from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { Body1 } from 'components/utils/typo';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import { debounce } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { getRepresentationPhrase } from 'utils/utilityFunctions';
import { getRefManagers } from '../../../actions/resources/refManager';
import { DEFAULT_EDITOR_JSON, INFOBOX_API_URL } from '../../../constants';
import InfoboxPreview from './infoboxPreview';

const StyledInput = styled(Input)`
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  // max-width: 550px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  ::-moz-placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  ::placeholder {
    font-weight: normal;
    color: #989898;
    font-size: 14px;
    line-height: 19px;
  }

  font-weight: 300;
`;

interface InfoBoxFormState {
  title: string;
  subtitle: string;
  image: any;
  imagePreviewUrl: string;
  video_url: string;
  isSubTitleEnabled: any;
  shortened_title: string;
  text: string;
  textJSONTiptap: any;
  newTextContent: any;
  tempContent: any;
  references: any;
  variables: any;
  numerics: any;
  module: string;

  urls: any;
  selectedReferences: any;
  textEditorsuggestions: any;
  refModal: boolean;
  onSelectHeight: boolean;
  fileType: any;

  moduleId: string;
}

interface InfoBoxFormProps {
  conditionalText?: any;
  formState: any;
  teamState: any;
  infoBoxId: any;
  isFullHeight: any;
  label: any;
  modal: boolean;
  refManagerState: any;
  selectedText: any;
  type: any;
  closeUiElementPane: () => void;
  getInfoBoxes: (type: any, mirrorId, moduleId) => void;
  getUniqueCode: (id?: any, type?: any) => void;
  getRefManagers: (type: string, mirrorId, moduleId) => void;
  populateSuggestions: () => void;
  savePosition: () => void;
  selectValue: (val?: any) => void;
  setIsFormDisabled: (val) => void;
  toggleModal: (modalName?: any) => void;

  match: any;
}

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

class InfoBoxForm extends Component<InfoBoxFormProps, InfoBoxFormState> {
  debOnTextChangeTiptap: any;

  constructor(props: any) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      title: this.props.selectedText ? this.props.selectedText : '',
      subtitle: '',
      image: '',
      imagePreviewUrl: '',
      video_url: '',
      isSubTitleEnabled: false,
      shortened_title: '',
      text: '',
      fileType: '',
      newTextContent: DEFAULT_EDITOR_JSON,
      textJSONTiptap: {},
      tempContent: DEFAULT_EDITOR_JSON,
      references: [],
      variables: [],
      numerics: [],
      module: '',

      urls: [],
      selectedReferences: [],
      textEditorsuggestions: [],
      refModal: false,
      onSelectHeight: false,

      // integrated algo & calculator id
      moduleId: this.props.match.params.moduleId,
    };
    this.debOnTextChangeTiptap = debounce(this.onTextChangeTiptap, 300);
  }

  addURL = () => {
    this.setState((prevState) => ({
      urls: [...prevState.urls, { name: '', url: '' }],
    }));
  };

  handleURLChange = (i: any, e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target ? e.target.name : '';
    let value = e.target ? e.target.value : '';
    let urls = [...this.state.urls];
    if (name === 'url' && !~value.indexOf('http')) {
      value = 'https://' + value;
    }
    urls[i] = { ...urls[i], [name]: value };
    this.setState({ urls });
  };

  removeURL = (i: any) => {
    let urls = [...this.state.urls];
    urls.splice(i, 1);
    this.setState({ urls });
  };

  setReferences = (references: Array<{ source: any; id: any; url: any }>) => {
    let selectedReferences: Array<{ source: any; id: any; url: any }> = [];
    references.forEach(function (item) {
      selectedReferences.push({
        source: item.source,
        id: item.id,
        url: item.url,
      });
    });
    return selectedReferences;
  };

  extractReferenceID = (references: Array<{ id: any }>) => {
    let selectedReferences: Array<{ id: any }> = [];
    references.forEach((ref) => {
      selectedReferences.push(ref.id);
    });
    return selectedReferences;
  };

  helpInfoData = getHelpInfoData('InfoBox');

  componentDidMount() {
    this.props.setIsFormDisabled(false);

    this.props.getRefManagers(this.props.type, null, this.state.moduleId);
    if (this.props.label) {
      this.setState({
        title: this.props.label.substring(0, this.helpInfoData['title'].character_limit),
        shortened_title: getRepresentationPhrase(
          this.props.label,
          this.helpInfoData['shortened_title'].character_limit
        ),
      });
    }

    if (this.props.infoBoxId) {
      axios.get(INFOBOX_API_URL + this.props.infoBoxId + '/').then((res) => {
        // eslint-disable-next-line
        this.setState({
          title: res.data.title || '',
          video_url: res.data.video_url || '',
          image: res.data.file_name || '',
          imagePreviewUrl: res.data.media,
          fileType: res.data.file_type,
          subtitle: res.data.subtitle || '',
          isSubTitleEnabled: res.data.is_subtitle_enabled,
          shortened_title: res.data.shortened_title || '',
          text: res.data.text || '',
          newTextContent: res.data.new_text_content,
          tempContent: res.data.new_text_content,
          textJSONTiptap: res.data.text_json_tiptap || {},
          urls: res.data.url_detail ? res.data.url_detail : [],
          references: res.data.reference_details
            ? this.extractReferenceID(res.data.reference_details)
            : [],
          selectedReferences: res.data.reference_details
            ? this.setReferences(res.data.reference_details)
            : [],
          module: res.data.moduleId,
          // eslint-disable-next-line
        });

        if (this.state.selectedReferences.length > 0) {
          this.setState({ onSelectHeight: true });
        }
      });
    }

    this.populateSuggestions();
  }

  componentWillUnmount() {
    // in this case, the infoboxform modal opens from 2nd modal and it covers 1st modal
    // so when closing it, it should disable 1st modal again
    this.props.conditionalText && this.props.setIsFormDisabled(true);
  }

  populateSuggestions = async () => {
    // suggestions for text field
    const suggestions = await getSuggestions(this.state.moduleId, false, this.props.type, false);
    const variables = await getVariables(this.state.moduleId, this.props.type);
    const numerics = await getNumerics(this.state.moduleId, this.props.type);
    this.setState({ textEditorsuggestions: suggestions, variables, numerics });
  };

  onSelect = (stateName, selectedList: Array<{ id: any }>, selectedItem) => {
    let selectedItemId: Array<{ id: any }> = [];

    selectedList.forEach(function (item) {
      selectedItemId.push(item.id);
    });

    this.setState({
      references: selectedItemId,
      selectedReferences: selectedList,
      onSelectHeight: true,
    });
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (prevState) =>
        ({
          [modalName]: !prevState[modalName],
        }) as Pick<InfoBoxFormState, any>
    );
  };

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'title') {
      this.setState({
        [e.target.name]: e.target.value,
        shortened_title: getRepresentationPhrase(
          e.target.value,
          this.helpInfoData['shortened_title'].character_limit
        ),
      });
    } else this.setState({ [e.target.name]: e.target.value } as Pick<InfoBoxFormState, any>);
  };

  handleImage = (e: any) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileInput = document.getElementById('fileInput') as HTMLInputElement | null;
    if (file && file.size > 25000000) {
      toast.error(CustomToast, { data: 'File size must be less than 25MB' });
      if (fileInput) fileInput.value = '';
    } else {
      reader.onloadend = () => {
        this.setState({
          image: file,
          imagePreviewUrl: URL.createObjectURL(file),
          fileType: file?.type,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // updates the state on field input
  onTextChange = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      text: text,
      newTextContent: e,
    });
  };

  onTextChangeTiptap = (editor) =>
    this.setState({
      text: editor.getText(),
      textJSONTiptap: editor.getJSON(),
    });

  getPayload = () => {
    let formdata = new FormData();
    formdata.set('title', this.state.title);
    formdata.set('subtitle', this.state.subtitle);
    if (this.state.image && typeof this.state.image === 'object') {
      formdata.set('media', this.state.image);
    }

    formdata.set('video_url', this.state.video_url || '');
    formdata.set('is_subtitle_enabled', this.state.isSubTitleEnabled);
    formdata.set('shortened_title', this.state.shortened_title);
    formdata.set('text', this.state.text);
    formdata.set('new_text_content', JSON.stringify(this.state.newTextContent));
    formdata.set('references', JSON.stringify(this.state.references));
    formdata.set('text_json_tiptap', JSON.stringify(this.state.textJSONTiptap));
    formdata.set('urls', this.state.urls ? JSON.stringify(this.state.urls) : JSON.stringify([]));
    if (this.props.type === 'calculator') {
      formdata.set('calculator', this.state.moduleId);
    } else {
      formdata.set('module', this.state.moduleId);
    }

    return formdata;
  };

  createInfoBox = async () => {
    if (this.state.text.length <= 1) {
      toast.error(CustomToast, { data: 'Text cannot be empty' });
      return;
    }

    let payload = this.getPayload();
    await this.props.savePosition();

    axios({
      url: INFOBOX_API_URL,
      method: 'POST',
      data: payload,
    }).then(async (res) => {
      // reset infobox list
      await this.props.getInfoBoxes(this.props.type, null, this.state.moduleId);

      // toggleModal should be called before selectValue inside select value reset all states
      await this.props.toggleModal();

      if (this.props.selectValue) {
        this.props.selectValue({ ...res.data, type: 'infobox' });
      }

      // update mention suggestion if user creates a reference from formula/ answer text card form
      if (this.props.populateSuggestions) this.props.populateSuggestions();

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      }
    });
  };

  editInfoBox = async () => {
    if (this.state.text.length <= 1) {
      toast.error(CustomToast, { data: 'Text cannot be empty' });
      return;
    }

    let payload = this.getPayload();
    await this.props.savePosition();

    axios.put(INFOBOX_API_URL + this.props.infoBoxId + '/', payload).then(async (res) => {
      await this.props.getInfoBoxes(this.props.type, null, this.state.moduleId);

      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      }

      // close modal
      await this.props.toggleModal(this.props.infoBoxId);
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.infoBoxId) {
      this.props.modal && this.props.closeUiElementPane();
      this.editInfoBox();
    } else {
      this.props.modal && this.props.closeUiElementPane();
      this.createInfoBox();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const refMangerProps = {
      type: this.props.type,
      toggleModal: () => this.toggleModal('refModal'),
      savePosition: () => [],
    };

    const filePreviewProps = {
      fileName: this.state.image?.name || this.state.image,
      fileType: this.state.fileType,
      fileURL: this.state.imagePreviewUrl,
      previewWidth: '500px', // Provide a default value or adjust as needed
      previewHeight: '500px', // Provide a default value or adjust as needed
    };
    let multiSelectstyle = {
      chips: {
        background: '#08A88E',
      },
      searchBox: {
        width: '500px',
        height: this.state.onSelectHeight ? 'auto' : '46px',
        minHeight: '46px',
        background: '#ffffff',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        borderRadius: '5px',
        margin: '0px',
      },
      multiselectContainer: {
        color: '#2bbbad',
      },
      inputField: {
        textIndent: '10px',
        paddingTop: 0,
        marginTop: '-4px',
        display: 'inline-block',
        borderBottom: 'none',
        boxShadow: 'none',
      },
    };

    return (
      <div>
        <Overlay show={isFormDisabled} />
        <div className='row'>
          <div className='col-md-5'>
            <InfoboxPreview
              title={this.state.title}
              subtitle={this.state.subtitle}
              isSubTitleEnabled={this.state.isSubTitleEnabled}
              text={this.state.text}
              textJson={this.state.newTextContent}
              infobox_links={this.state.urls ? this.state.urls : []}
              tiptapJSON={this.state.textJSONTiptap}
              references={this.state.selectedReferences ? this.state.selectedReferences : []}
            />
          </div>
          <div className='col-md-7'>
            <Divider
              orientation='vertical'
              style={{
                marginTop: 25,
                marginBottom: 25,
                display: 'inline-block',
                float: 'left',
              }}
            />
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                height: '100%',
                minHeight: '100%',
                pointerEvents: isFormDisabled ? 'none' : 'auto',
              }}
              autoComplete='off'
              onKeyPress={this.onKeyPress}
              className='trigger'
              onSubmit={this.handleSubmit}
            >
              {this.props.isFullHeight ? (
                <Header className='px-0' title='Infobox' toggleModal={this.props.toggleModal} />
              ) : (
                ''
              )}
              <div className='row'>
                <div className='col-md-12'>
                  <InputField
                    name='title'
                    required={true}
                    value={this.state.title}
                    onChange={this.onChange}
                    label={helpInfoData?.title?.label}
                    detail={helpInfoData?.title?.detail}
                    placeholder={helpInfoData?.title?.placeholder}
                    maxLength={helpInfoData?.title?.character_limit}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Label className='flex items-center gap-[8px]'>
                    <Body1 className='text-base font-semibold leading-6 text-gray-700'>
                      {helpInfoData?.subtitle?.label}
                    </Body1>
                    <AvoSwitch
                      checked={this.state.isSubTitleEnabled}
                      onChange={() => this.toggleModal('isSubTitleEnabled')}
                    />
                  </Label>
                  <SubTitleInput
                    name='subtitle'
                    placeholder={helpInfoData?.subtitle?.placeholder}
                    className={`my-2 rounded-[5px] border-[2px] font-normal leading-[19px] placeholder:text-[16px] placeholder:font-normal placeholder:leading-5 ${
                      !this.state.isSubTitleEnabled && '!text-gray-300'
                    }`}
                    onChange={this.onChange}
                    value={this.state.subtitle}
                    disabled={!this.state.isSubTitleEnabled}
                    maxLength={helpInfoData?.subtitle?.character_limit}
                  />
                  <CharacterLimit
                    currentCount={this.state.subtitle.length}
                    totalCount={helpInfoData?.subtitle?.character_limit}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['text'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.text?.detail}
                        label={helpInfoData?.text?.label}
                      />

                      <AvoEditor
                        moduleId={this.state.moduleId}
                        moduleType={this.props.type}
                        suggestions={this.state.textEditorsuggestions}
                        setValue={this.onTextChange}
                        prevValue={this.state.tempContent}
                        variables={this.state.variables}
                        numerics={this.state.numerics}
                        richTextButtonShowList={[
                          ToolbarButton.TEXT_STYLE,
                          ToolbarButton.BOLD,
                          ToolbarButton.ITALIC,
                          ToolbarButton.HIGHLIGHT,
                          ToolbarButton.BULLETED_LIST,
                          ToolbarButton.NUMBERED_LIST,
                          ToolbarButton.INSERT_LINK,
                          ToolbarButton.PHONE_NUMBER,
                          ToolbarButton.VARIABLES,
                          ToolbarButton.CONDITIONAL_TEXT,
                        ]}
                        wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                        onUpdate={this.debOnTextChangeTiptap}
                        initialContent={this.state.textJSONTiptap}
                        suggestionsToExclude={suggestionsToExclude}
                        richTextButtonsShowListTiptap={richTextButtonsShowList}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['references'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.references?.detail}
                        label={helpInfoData?.references?.label}
                      />
                    </FormGroup>
                  )}
                </div>
                <div className='col-md-10 pr-0'>
                  {helpInfoData['references'] && (
                    <FormGroup>
                      <Multiselect
                        name='references'
                        showArrow
                        options={this.props.refManagerState.refmanagers}
                        selectedValues={this.state.selectedReferences}
                        onSelect={(selectedList, selectedItem) =>
                          this.onSelect('selectedReferences', selectedList, selectedItem)
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onSelect('selectedReferences', selectedList, removedItem)
                        }
                        displayValue='source'
                        style={multiSelectstyle}
                      />
                    </FormGroup>
                  )}
                </div>
                <div className='col-md-2 p-0'>
                  <InfoboxFormButton
                    variant='contained'
                    onClick={() => this.toggleModal('refModal')}
                  >
                    CREATE
                  </InfoboxFormButton>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['url_links'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.url_links?.detail}
                        label={helpInfoData?.url_links?.label}
                      />
                      <Box style={{ marginBottom: 10 }}>
                        <InfoboxFormButton
                          variant='contained'
                          onClick={this.addURL}
                          startIcon={<AddCircleOutlineIcon style={{ marginTop: '-6px' }} />}
                        >
                          CREATE
                        </InfoboxFormButton>
                      </Box>

                      {this.state.urls.map((url, i) => (
                        <div className='row' key={i}>
                          <div className='col-md-4'>
                            <Input
                              placeholder='Name'
                              name='name'
                              value={url.name || ''}
                              onChange={(e) => this.handleURLChange(i, e)}
                            />
                          </div>
                          <div className='col-md-6'>
                            <Input
                              placeholder='URL'
                              type='url'
                              name='url'
                              onChange={(e) => this.handleURLChange(i, e)}
                              value={url.url || ''}
                            />
                          </div>
                          <div className='col-md-2 pl-0'>
                            <InfoboxFormButton
                              variant='contained'
                              onClick={(e) => this.removeURL(i)}
                              startIcon={<DeleteIcon style={{ marginTop: '-6px' }} />}
                            >
                              Remove
                            </InfoboxFormButton>
                          </div>
                        </div>
                      ))}
                    </FormGroup>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormGroup>
                    <FieldLabel detail='Add media to infoboxes' label='Media' />
                    <UploadFile
                      handleFile={this.handleImage}
                      fileName={this.state.image?.name || this.state.image || 'No file selected'}
                      allowedFormats='image/*, .pdf'
                    />
                  </FormGroup>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <FormGroup>
                    <FieldLabel label='YouTube URL' />
                    <StyledInput
                      name='video_url'
                      type='url'
                      title='Only youtube video URL is allowed'
                      pattern='https://(www\.youtube\.com|youtu\.?be)\/.+$'
                      onChange={this.onChange}
                      value={this.state.video_url || ''}
                    />
                  </FormGroup>
                </div>
              </div>

              {this.state.imagePreviewUrl && (
                <div className='row'>
                  <div className='col-md-12'>
                    <FilePreviewer {...filePreviewProps} />
                  </div>
                </div>
              )}

              <div className='row' style={{ marginLeft: 'auto', marginTop: '60px' }}>
                <CancelButton
                  style={{ color: 'black', marginRight: '10px' }}
                  onClick={this.props.toggleModal}
                >
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton type='submit' name='action'>
                  {SAVE_BUTTON}
                </CreateButton>
              </div>
              <SlidingPane
                isOpen={this.state.refModal}
                onRequestClose={() => this.toggleModal('refModal')}
                from='bottom'
                className='sliding-pan-modal side-popup-shadow'
                overlayClassName='infobox-overlay-for-zindex'
                title={
                  <div>
                    Create a Reference Manager
                    <HighlightOffIcon
                      className='backIcon'
                      onClick={() => this.toggleModal('refModal')}
                    ></HighlightOffIcon>
                  </div>
                }
                width='587px'
                closeIcon={
                  <div>
                    <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
                  </div>
                }
              >
                <RefManagerForm {...refMangerProps} />
              </SlidingPane>
            </Form>
          </div>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });
const mapDispatchToProps = (dispatch, InfoBoxFormProps) => ({
  getUniqueCode: (moduleId, type) => dispatch(getUniqueCode(moduleId, type)),
  getInfoBoxes: (type, mirrorId, moduleId) => dispatch(getInfoBoxes(type, mirrorId, moduleId)),
  getRefManagers: (type, mirrorId, moduleId) => dispatch(getRefManagers(type, mirrorId, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

const suggestionsToExclude: SuggestionTypes[] = ['knowledge_base', 'infobox', 'media', 'ehr_order'];
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoBoxForm));
