import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQuery } from '@tanstack/react-query';
import { useOnAuthStateChanged } from 'api/firebase';
import { healthcheckAPI } from 'api/healthcheck';
import { MaintenancePage } from 'components/home/MaintenancePage';
import History from 'components/utils/History';
import { contextClass } from 'components/utils/toast-message';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'routes/routing/Routes';
import { twMerge } from 'tailwind-merge';
import 'utils/axios'; // init axios interceptor
import 'utils/sentry'; // init sentry
import { REACT_APP_GOOGLE_CLIENT_ID } from './constants';

const clientId = REACT_APP_GOOGLE_CLIENT_ID;

const App = () => {
  useOnAuthStateChanged();

  const { data, error } = useQuery(['serverStatus'], healthcheckAPI.checkHealth);

  if (error || (data && data !== 'Healthy')) {
    return <MaintenancePage />;
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ToastContainer
        bodyClassName={(context) =>
          twMerge(
            contextClass[context?.type || 'default'],
            'before:block before:absolute before:-inset-1 before:w-[8px] flex pl-2'
          )
        }
        toastClassName='shadow-06 bg-white rounded-sm relative'
        hideProgressBar
      />
      <Router history={History}>
        <Routes />
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
