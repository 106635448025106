import CharacterLimit from 'components/utils/form-input/fieldCharLimit';
import { useContext, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { AvoSwitch } from '../AvoSwitch';
import AutoSuggest from '../draftJS/autoSuggest';
import { MentionProvider } from '../module/MentionContext';
import { ModuleContext } from '../module/ModuleContext';
import { Tiptap } from '../tiptap/Tiptap';
import { convertFromJSON } from '../tiptap/converter/convertFromJSON';
import { RichTextMenuButtonTypes } from '../tiptap/tiptapInterfaces';
import { Caption2 } from '../typo';
import { getEditorType } from './AvoEditorTextInput';
import { AuthorizedUserOnlyWrapper } from '../tiptap/converter/converting-button/ConvertingButton';

export interface AvoEditorProps {
  // Common
  placeholder?: string;
  characterLimitUnit?: string;

  // DraftJS
  suggestions: any;
  fullWidth?: boolean;
  moduleId: any;
  moduleType?: any; // TODO: change type to MODULE_TYPES.ALGO | MODULE_TYPES.CALCULATOR
  setVariables?: any;
  richTextButtonShowList: string[];
  variables?: any;
  numerics?: any;
  prevValue?: any;
  setValue: any;
  isNested?: any;
  isReadOnly?: any;
  disableConditionalText?: any;
  disableMentionPlugin?: boolean;
  getCharactersLength?: (length: number) => void;
  MAX_TEXT_LIMIT?: number;
  MAX_NO_OF_LINES?: number;
  showWarning?: () => void;
  isIntroductionCard?: boolean;
  gptBox?: boolean;
  knowledgeBases?: any[];
  calculateContentTextLength?: (text: any) => number;
  maintainVariables?: boolean;
  isTextInput?: boolean;
  isFormula?: boolean;
  conditionalText?: boolean;
  hasEHRVariables?: boolean;

  characterLimitTotalCount?: number;
  characterLimitCurrentCount?: number;

  // TIPTAP
  charCountMode?: 'textSize' | 'nodeSize' | 'tokenSize';
  maxLines?: number;
  onLimitReached?: () => void;
  maxCharacters?: number;
  variablesToExclude?: any[];
  wrapperClassNames?: string;
  onUpdate?: (editor: any) => any;
  suggestionsToExclude?: any[];
  initialContent?: any;
  richTextButtonsShowListTiptap?: RichTextMenuButtonTypes[];
}

export const AvoEditor = (props: AvoEditorProps) => {
  // TODO: Combine the logic passing editorType with AvoEditorTextInput
  const { module } = useContext(ModuleContext);
  const excludedSuggestionTypes = [
    ...(props.suggestionsToExclude ?? []),
    ...(props.variablesToExclude ?? []),
  ];

  if (!module) return null;
  const editorType = getEditorType(module);

  switch (editorType) {
    case 'Tiptap':
      return (
        <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
          <Tiptap
            wrapperClassNames={props.wrapperClassNames}
            suggestionsToExclude={props.suggestionsToExclude}
            variablesToExclude={props.variablesToExclude}
            charCountMode={props.charCountMode}
            onUpdate={props.onUpdate}
            initialContent={props.initialContent}
            placeholder={props.placeholder}
            maxCharacters={props.maxCharacters}
            maxLines={props.maxLines}
            richTextButtonsShowList={props.richTextButtonsShowListTiptap}
            onLimitReached={props.onLimitReached}
            characterLimitUnit={props.characterLimitUnit}
          />
        </MentionProvider>
      );
    case 'AutoSuggest':
      return <ConvertableEditor {...props} />;
  }
};

const ConvertableEditor = (props: AvoEditorProps) => {
  const [isConverted, setIsConverted] = useState(false);
  const [convertedTiptapContent, setConvertedTiptapContent] = useState(null);
  const excludedSuggestionTypes = [
    ...(props.suggestionsToExclude ?? []),
    ...(props.variablesToExclude ?? []),
  ];

  useEffect(() => {
    if (isConverted && props.prevValue) {
      const convertedContent = convertFromJSON(props.prevValue);
      if (convertedContent?.document) {
        setConvertedTiptapContent(convertedContent.document as any);
      }
    }
  }, [isConverted]);

  return (
    <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
      <AuthorizedUserOnlyWrapper>
        <div className='flex flex-row-reverse'>
          <AvoSwitch checked={isConverted} onChange={setIsConverted} />
          <Caption2>Convert DraftJS to Tiptap</Caption2>
        </div>
      </AuthorizedUserOnlyWrapper>
      <div className={twJoin(isConverted && 'hidden')}>
        <AutoSuggest
          suggestions={props.suggestions}
          setValue={props.setValue}
          fullWidth={props.fullWidth}
          prevValue={props.prevValue}
          variables={props.variables}
          numerics={props.numerics}
          isReadOnly={props.isReadOnly}
          getCharactersLength={props.getCharactersLength}
          MAX_TEXT_LIMIT={props.MAX_TEXT_LIMIT}
          MAX_NO_OF_LINES={props.MAX_NO_OF_LINES}
          placeholder={props.placeholder}
          showWarning={props.showWarning}
          gptBox={props.gptBox}
          calculateContentTextLength={props.calculateContentTextLength}
          maintainVariables={props.maintainVariables}
          isTextInput={props.isTextInput}
          conditionalText={props.conditionalText}
          knowledgeBases={props.knowledgeBases}
          type={props.moduleType}
          hasEHRVariables={props.hasEHRVariables}
          isFormula={props.isFormula}
          isNested={props.isNested}
          disableMentionPlugin={props.disableMentionPlugin}
          setVariables={props.setVariables}
          disableConditionalText={props.disableConditionalText}
          richTextButtonShowList={props.richTextButtonShowList}
        />
        {typeof props.characterLimitTotalCount === 'number' &&
          typeof props.characterLimitCurrentCount === 'number' && (
            <CharacterLimit
              totalCount={props.characterLimitTotalCount}
              currentCount={props.characterLimitCurrentCount}
              unit={props.characterLimitUnit}
            />
          )}
      </div>
      {isConverted && (
        <Tiptap
          wrapperClassNames={props.wrapperClassNames}
          suggestionsToExclude={props.suggestionsToExclude}
          variablesToExclude={props.variablesToExclude}
          charCountMode={props.charCountMode}
          onUpdate={props.onUpdate}
          initialContent={convertedTiptapContent as any}
          placeholder={props.placeholder}
          maxCharacters={props.maxCharacters}
          maxLines={props.maxLines}
          richTextButtonsShowList={props.richTextButtonsShowListTiptap}
          onLimitReached={props.onLimitReached}
          characterLimitUnit={props.characterLimitUnit}
        />
      )}
    </MentionProvider>
  );
};
