import { Icons } from 'components/utils/Icons';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { useAuthentication } from 'hooks/useAuthentication';
import { useLocation } from 'react-router';
import {
  CHANNELS,
  CUSTOM_TAGS,
  EHR_CONFIG,
  EHR_ORDER_INDIVIDUAL,
  FEATURED_AUTHOR,
  HOME,
  MEMBERS,
  NOTIFICATION,
  NUMBERS,
  SETTING,
  TAGS,
} from 'routes/urls';
import { ROLES } from '../../../api/team';
import { useTeam } from '../../../hooks/useTeam';
import { Divider } from './Divider';
import { MenuItem } from './MenuItem';

interface SideMenuProps {
  expanded: boolean;
}

export const SideMenu = ({ expanded }: SideMenuProps) => {
  const { authentication } = useAuthentication();
  const isStaff = authentication.user?.is_staff;
  const isTrialUser = authentication.user?.user_type === 'trial';
  const { pathname } = useLocation();
  const { team } = useTeam();
  const isTeamAdmin = team?.team_type === ROLES.Admin;
  return (
    <>
      <MenuItem link={HOME} label='Home' expanded={expanded} icon={<Icons.Home />} />
      <MenuItem
        link={MEMBERS}
        label='Members'
        expanded={expanded}
        icon={<Icons.Member />}
        subMenu={[
          { link: MEMBERS, label: 'Team Member' },
          { link: FEATURED_AUTHOR, label: 'Featured Author' },
        ]}
      />
      <MenuItem
        link={TAGS}
        label='Tags'
        expanded={expanded}
        icon={<Icons.Tag />}
        subMenu={
          !isTeamAdmin
            ? [
                { link: TAGS, label: 'Default' },
                { link: CUSTOM_TAGS, label: 'Custom' },
              ]
            : []
        }
      />

      <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>
        <MenuItem
          link={EHR_ORDER_INDIVIDUAL}
          label='EHR Orders'
          expanded={expanded}
          icon={<Icons.EHROrder />}
          subMenu={[
            {
              link: EHR_ORDER_INDIVIDUAL,
              label: 'Individual',
              featureType: StaffOnlyBadgeType.BETA,
            },
            //{ link: EHR_ORDER_GROUP, label: 'Group' },
          ]}
        />
      </StaffOnlyWrapper>
      {!isTrialUser && (
        <>
          <MenuItem
            link={pathname.includes(CHANNELS + '/new') ? CHANNELS + '/new' : CHANNELS}
            label='Channels'
            expanded={expanded}
            icon={<Icons.Grid />}
          />
          <MenuItem
            link={NOTIFICATION}
            label='Notification'
            expanded={expanded}
            icon={<Icons.Chat />}
          />
        </>
      )}
      <MenuItem link={SETTING} label='Setting' expanded={expanded} icon={<Icons.Setting />} />

      {isStaff && (
        <>
          <Divider />
          <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
            <MenuItem
              link={pathname.includes(NUMBERS + '/new') ? NUMBERS + '/new' : NUMBERS}
              label='Numbers'
              expanded={expanded}
              icon={<Icons.ListNumbers />}
            />
          </StaffOnlyWrapper>
          <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
            <MenuItem
              link={EHR_CONFIG}
              label='EHR Config'
              expanded={expanded}
              icon={<Icons.TaskSetting />}
            />
          </StaffOnlyWrapper>
        </>
      )}

      {/* <MenuTitle show={expanded}>AvoMD WebApp</MenuTitle> */}
    </>
  );
};
