import { ModuleLayout } from 'components/layout/ModuleLayout';
import { InfoboxResourceTable } from 'components/resources/infobox/InfoboxTable';

export const Infoboxes = () => {
  return (
    <ModuleLayout className='bg-gray-100'>
      <InfoboxResourceTable />
    </ModuleLayout>
  );
};
