import { createStyles, makeStyles } from '@material-ui/styles';

export default makeStyles(() =>
  createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      color: 'grey',
    },
  })
);
