import clsx from 'clsx';
import { HTMLAttributes } from 'react';

interface TypoProps extends HTMLAttributes<HTMLDivElement> {}

// TODO: After removing global styles (materialize and bootstrap),
//       all divs in this file should be replaced with corresponding elements such as h1, h2, p, etc.

export function H1({ className, ...props }: TypoProps) {
  return <div className={clsx('text-heading-1', className)} {...props} />;
}

export function H2({ className, ...props }: TypoProps) {
  return <div className={clsx('text-heading-2', className)} {...props} />;
}

export function H3({ className, ...props }: TypoProps) {
  return <div className={clsx('text-heading-3', className)} {...props} />;
}

export function H4({ className, ...props }: TypoProps) {
  return <div className={clsx('text-heading-4', className)} {...props} />;
}

export function H5({ className, ...props }: TypoProps) {
  return <div className={clsx('text-heading-5', className)} {...props} />;
}

export function Body1({ className, ...props }: TypoProps) {
  return <div className={clsx('text-body-1', className)} {...props} />;
}

export function Body2({ className, ...props }: TypoProps) {
  return <div className={clsx('text-body-2', className)} {...props} />;
}

export function Caption2({ className, ...props }: TypoProps) {
  return <div className={clsx('text-caption-2', className)} {...props} />;
}
