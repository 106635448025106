import { AnimatePresence, motion } from 'framer-motion';
import { HTMLAttributes, ReactNode, memo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { twJoin } from 'tailwind-merge';
import { SIDEBAR_TRANSITION_DURATION } from '.';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from '../../utils/StaffOnlyWrapper';

interface SubMenuItem {
  label: string;
  link: string;
  featureType?: StaffOnlyBadgeType;
}
interface MenuItemProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  label: string;
  expanded?: boolean;
  link?: string;
  subMenu?: SubMenuItem[];
}

export const MenuItem = memo(({ icon, label, link, subMenu, expanded = true }: MenuItemProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const selected = !!(pathname === link || subMenu?.map((item) => item.link).includes(pathname));
  const navigate = useCallback(() => {
    if (link && !selected) {
      history.push(link);
    }
  }, [history, link, pathname]);
  return (
    <>
      <div
        className={twJoin(
          'relative flex select-none items-center px-[20px] py-[12px] text-body-2 text-gray-900 hover:bg-primary-200',
          selected ? 'bg-primary-200 font-semibold text-primary-600' : 'cursor-pointer'
        )}
        onClick={navigate}
      >
        {selected && !icon && (
          <motion.div
            layoutId='moduleSidebarMenuSelector'
            className='absolute right-0 h-full w-[3px] border-r-[3px] border-primary-600 '
          />
        )}
        {icon && <MenuItemIcon title={label}>{icon}</MenuItemIcon>}
        <MenuItemLabel show={expanded} hasIcon={!!icon}>
          {label}
        </MenuItemLabel>
      </div>
      {subMenu && <SubMenu subMenu={subMenu} show={selected && expanded} indented={!!icon} />}
    </>
  );
});

interface MenuItemIconProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

const MenuItemIcon = ({ title, children }: MenuItemIconProps) => {
  return <div title={title}>{children}</div>;
};

interface MenuItemLabelProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  hasIcon: boolean;
}

const MenuItemLabel = ({ children, show, className, hasIcon }: MenuItemLabelProps) => {
  return (
    <AnimatePresence initial={false}>
      {show && (
        <motion.div
          initial={{ width: 0, marginLeft: 0, opacity: 0 }}
          animate={{ width: 'auto', marginLeft: hasIcon ? 12 : 0, opacity: 1 }}
          exit={{ width: 0, marginLeft: 0, opacity: 0 }}
          transition={{ duration: SIDEBAR_TRANSITION_DURATION }}
          className={twJoin('overflow-hidden whitespace-nowrap', className)}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface SubMenuProps {
  show: boolean;
  subMenu: SubMenuItem[];
  indented: boolean;
}

const SubMenu = ({ subMenu, show, indented }: SubMenuProps) => {
  return (
    <AnimatePresence initial={false}>
      {show && (
        <motion.div
          initial={{ width: 0, height: 0, opacity: 0 }}
          animate={{ width: 'auto', height: 'auto', opacity: 1 }}
          exit={{ width: 0, height: 0, opacity: 0 }}
          transition={{ duration: SIDEBAR_TRANSITION_DURATION }}
          className='overflow-hidden whitespace-nowrap'
        >
          {subMenu.map((item, idx) =>
            StaffOnlyWrapIf({
              condition: !!item.featureType,
              options: {
                type: item.featureType!,
              },
              element: <SubMenuItem item={item} key={idx} indented={indented} />,
            })
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface SubMenuItemProps {
  item: SubMenuItem;
  indented: boolean;
}

const SubMenuItem = ({ item, indented }: SubMenuItemProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const selected = pathname === item.link;
  return (
    <div
      className={twJoin(
        'select-none py-[8px] pr-[10px]',
        indented ? 'pl-[56px]' : 'pl-[20px]',
        selected ? 'text-caption-1 font-semibold text-primary-600' : 'cursor-pointer text-caption-2'
      )}
      onClick={() => (selected ? null : history.push(item.link))}
    >
      {item.label}
    </div>
  );
};
