import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/tiptap/arrow';
import clsx from 'clsx';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from 'components/utils/StaffOnlyWrapper';
import { Fragment, ReactNode, useMemo } from 'react';
import { MENTION_TYPES } from '../../../constants';
import { DEMOGRAPHICS_TYPE, MEDICATION_TYPE } from '../../../hooks/useEHRVariables';
import { Body2, Caption2 } from '../typo';

interface OptionType {
  id: number | string;
  title: ReactNode;
  is_beta?: boolean;
  is_staff?: boolean;
}

interface DropdownSingleProps {
  placeholder: string;
  options: OptionType[];
  value?: number | string;
  onChange: (value: number | string) => void;
  emptyDescription?: string;
}

const DropdownSingle = ({
  value,
  options,
  onChange,
  placeholder,
  emptyDescription,
}: DropdownSingleProps) => {
  const option = useMemo(() => options.find((option) => option.id === value), [options, value]);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [shift({ crossAxis: true }), offset(3)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Listbox as='div' className='relative mb-2 w-full' onChange={onChange} value={value}>
      {({ open }) => (
        <>
          <Listbox.Button
            ref={refs.setReference}
            className={clsx(
              'focus:bg-transparent', // due to material ui
              'flex w-full items-center justify-between rounded !border border-solid border-gray-300 !px-3 py-2.5'
            )}
          >
            <div
              className={clsx(
                'truncate text-body-2',
                option?.title ? 'text-gray-900' : 'text-gray-300'
              )}
            >
              {option?.title || placeholder}
            </div>
            <div className={clsx('transition-all', { 'rotate-180': open })}>
              <DropdownArrowIcon />
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <div className='relative z-10 w-full' ref={refs.setFloating} style={floatingStyles}>
              <Listbox.Options className='max-h-72 w-full overflow-y-auto rounded-b border-gray-300 bg-white shadow-04'>
                {options.length > 0 ? (
                  options.map((option) =>
                    StaffOnlyWrapIf({
                      condition: option.id === MENTION_TYPES.MESSAGE_VARIABLE,
                      options: {
                        type: StaffOnlyBadgeType.BETA,
                      },
                      element: (
                        <Listbox.Option
                          key={option.id}
                          value={option.id}
                          className={({ active }) =>
                            clsx('cursor-pointer !px-3 !py-2.5', {
                              'bg-primary-200': active,
                            })
                          }
                        >
                          {({ selected }) => (
                            <>
                              {StaffOnlyWrapIf({
                                condition:
                                  (typeof option.id === 'string' &&
                                    [MEDICATION_TYPE, DEMOGRAPHICS_TYPE].includes(option.id)) ||
                                  !!option.is_beta,
                                options: { y: -5, type: StaffOnlyBadgeType.BETA },
                                element: (
                                  <div className='flex justify-between'>
                                    <div className='truncate text-gray-900'>{option.title}</div>
                                    {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                                  </div>
                                ),
                              })}
                            </>
                          )}
                        </Listbox.Option>
                      ),
                    })
                  )
                ) : (
                  <div className='my-[34px] flex w-full max-w-[284px] flex-col gap-1 justify-self-center'>
                    <Body2 className='text-center text-gray-900'>No options available.</Body2>
                    {emptyDescription && (
                      <Caption2 className='text-center text-gray-700'>{emptyDescription}</Caption2>
                    )}
                  </div>
                )}
              </Listbox.Options>
            </div>
          </Transition>
        </>
      )}
    </Listbox>
  );
};

export default DropdownSingle;
