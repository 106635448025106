import ProfileIcon from 'assets/icons/ProfileIcon';
import { twJoin } from 'tailwind-merge';
import { getRandomNumberFromHashCode } from 'utils/utilityFunctions';
import { colors } from './colors';

interface AvatarProps {
  name?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Avatar = ({ name, size = 'md' }: AvatarProps) => {
  const randomColorIdx = getRandomNumberFromHashCode(name ?? '') % colors.length;
  return (
    <div
      className={twJoin(
        'flex items-center justify-center rounded-full font-bold text-white',
        size === 'sm' && '',
        size === 'md' && 'h-[28px] w-[28px]',
        size === 'lg' && 'h-[32px] w-[32px]'
      )}
      style={{ backgroundColor: `${colors[randomColorIdx]}` }}
    >
      {name ? name.trim()[0].toUpperCase() : <ProfileIcon />}
    </div>
  );
};

export default Avatar;
