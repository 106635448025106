import { ReactRenderer } from '@tiptap/react';
import { MentionList } from './MentionList';
import { autoUpdate, computePosition, flip, hide } from '@floating-ui/react';
import { Editor } from '@tiptap/core';
import { MutableRefObject } from 'react';

export default (
  rendererRef?: MutableRefObject<ReactRenderer | null>,
  allowNullSuggestionPrefix?: boolean
) => {
  return {
    char: '#',
    allowedPrefixes: allowNullSuggestionPrefix ? null : undefined,
    items: ({ query, editor }: { query: string; editor: Editor }) => {
      const mention = editor.storage.MentionStorage;
      return {
        ...mention,
        suggestions: mention.suggestions?.filter((suggestion) =>
          suggestion?.name?.toLowerCase().includes(query.toLowerCase())
        ),
      };
    },
    render: () => {
      let cleanup;
      let floating: HTMLDivElement;
      let popup;

      return {
        onStart: (props) => {
          const reference = document.querySelector('.suggestion') as HTMLSpanElement;
          floating = document.createElement('div');
          Object.assign(floating.style, {
            position: 'absolute',
            zIndex: 9999,
          });

          document.body.appendChild(floating);

          if (!reference) return;
          cleanup = autoUpdate(reference, floating, () => {
            computePosition(reference, floating, {
              placement: 'bottom-start',
              middleware: [hide(), flip()],
            }).then(({ x, y, middlewareData }) => {
              Object.assign(floating.style, {
                left: `${x}px`,
                top: `${y}px`,
              });
              if (middlewareData.hide) {
                Object.assign(floating.style, {
                  visibility: middlewareData.hide.referenceHidden ? 'hidden' : 'visible',
                });
              }
            });
          });

          rendererRef!.current = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });
          Object.assign((rendererRef!.current.element as HTMLElement).style, {
            position: 'relative',
          });
          floating.appendChild(rendererRef!.current.element);
        },

        onUpdate(props) {
          rendererRef!.current!.updateProps(props);
          const reference = document.querySelector('.suggestion') as HTMLSpanElement;
          if (!reference) return;

          cleanup?.();
          cleanup = autoUpdate(reference, floating, () => {
            computePosition(reference, floating, {
              placement: 'bottom-start',
            }).then(({ x, y }) => {
              Object.assign(floating.style, {
                left: `${x}px`,
                top: `${y}px`,
              });
            });
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            cleanup?.();
            floating?.remove();
            return true;
          }

          return (rendererRef!.current!.ref as any)?.onKeyDown(props);
        },

        onExit() {
          rendererRef!.current?.destroy();
          rendererRef!.current = null;
          cleanup?.();
          floating?.remove();
          popup?.[0].destroy();
        },
      };
    },
  };
};
