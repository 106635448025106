import axios from 'axios';
import { PRESIGNED_POST_URL } from '../constants';

export const editorImage = {
  generatePresignedPostUrl: (filename: string) =>
    axios.post(PRESIGNED_POST_URL, { filename }).then((response) => response.data),
  uploadImageUsingPresignedUrl: (data) => {
    return axios.post(data.preSignedURL, data.formdata, {
      transformRequest: (data, headers) => {
        // delete Auth token from axios header before sending request to S3
        delete headers['Authorization'];
        return data;
      },
    });
  },
};
