import * as React from 'react';

function SuccessSVGComponent(props) {
  return (
    <svg
      width={171}
      height={158}
      viewBox='0 0 171 158'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M58.739 30.596c-1.786.46-3.993 1.127-5.32 2.474-1.615 1.64-2.21 4.139-2.738 6.63-.05.24-.38.24-.43 0-.426-1.996-.974-4.394-2.127-5.876-1.467-1.886-3.726-2.657-5.943-3.228a.224.224 0 010-.437c1.94-.5 4.162-1.161 5.55-2.691 1.446-1.597 2.026-4.099 2.52-6.414.05-.24.38-.24.43 0 .46 2.176 1.248 4.647 2.559 6.198 1.424 1.684 3.436 2.376 5.498 2.907a.224.224 0 010 .437zM23.913 75.21c-2.552.657-5.707 1.612-7.602 3.536-2.31 2.344-3.159 5.916-3.913 9.475a.312.312 0 01-.615 0c-.609-2.853-1.393-6.28-3.041-8.398-2.096-2.695-5.324-3.798-8.493-4.613a.32.32 0 010-.625c2.772-.712 5.948-1.658 7.931-3.847 2.067-2.28 2.897-5.856 3.603-9.165a.312.312 0 01.615 0c.66 3.109 1.783 6.64 3.657 8.857 2.035 2.406 4.912 3.396 7.858 4.154a.321.321 0 010 .626zM154.71 49.428c-.866.222-1.937.547-2.581 1.2-.783.795-1.072 2.008-1.329 3.217a.106.106 0 01-.208 0c-.207-.97-.474-2.131-1.033-2.851-.711-.915-1.807-1.29-2.883-1.566a.109.109 0 010-.212c.942-.243 2.019-.563 2.692-1.306.701-.775.983-1.987 1.222-3.111a.106.106 0 01.209 0c.224 1.055.605 2.254 1.242 3.006.691.816 1.666 1.153 2.667 1.41.114.03.114.185.002.213zM58.637 125.544c-.865.222-1.936.547-2.58 1.2-.783.795-1.072 2.008-1.329 3.217a.106.106 0 01-.209 0c-.206-.969-.473-2.131-1.032-2.851-.711-.915-1.807-1.289-2.883-1.566a.109.109 0 010-.212c.94-.243 2.018-.563 2.692-1.306.7-.774.983-1.987 1.222-3.111a.106.106 0 01.209 0c.224 1.055.605 2.254 1.241 3.006.691.817 1.667 1.153 2.668 1.41a.11.11 0 01.001.213zM89.37 149.484c-.866.223-1.937.548-2.581 1.201-.783.795-1.072 2.007-1.329 3.217a.106.106 0 01-.209 0c-.206-.969-.473-2.132-1.032-2.852-.711-.914-1.807-1.289-2.883-1.566a.109.109 0 010-.212c.94-.242 2.019-.562 2.692-1.305.7-.775.983-1.988 1.222-3.112a.106.106 0 01.209 0c.224 1.056.605 2.254 1.241 3.006.691.817 1.667 1.154 2.668 1.41.114.031.114.185.001.213z'
          fill='#08A88E'
        />
        <path
          d='M94.576 133.514c28.752 0 52.061-23.392 52.061-52.248 0-28.856-23.309-52.248-52.061-52.248-28.753 0-52.062 23.392-52.062 52.248 0 28.856 23.309 52.248 52.062 52.248z'
          fill='#08A88E'
        />
        <path fill='url(#prefix__pattern0)' d='M13.662-.026h157.354v158.041H13.662z' />
        <path fill='url(#prefix__pattern1)' d='M65.233 55.6h59.133v53.036H65.233z' opacity={0.56} />
        <path
          d='M121.525 56.391c-2.947-3.023-9.145-3.579-12.018 0L94.674 74.866l-7.062 8.797c-.836 1.042-1.83 2.603-2.915 4.053a322.7 322.7 0 00-.636-.657l-4.794-4.944c-7.642-7.881-19.655 4.186-12.02 12.062 3.944 4.065 7.97 9.749 13.316 11.895 5.862 2.352 11.061-.479 14.83-5.068 8.829-10.75 17.424-21.703 26.133-32.552 2.9-3.613 3.453-8.519-.001-12.06z'
          fill='#fff'
        />
      </g>
      <defs>
        <pattern id='prefix__pattern0' patternContentUnits='objectBoundingBox' width={1} height={1}>
          <use xlinkHref='#prefix__image0' />
        </pattern>
        <pattern id='prefix__pattern1' patternContentUnits='objectBoundingBox' width={1} height={1}>
          <use xlinkHref='#prefix__image1' />
        </pattern>
        <image
          id='prefix__image0'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAFD8AABRICAYAAADMxiIyAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA'
        />
        <image
          id='prefix__image1'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB54AAAbSCAYAAADx9FnVAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA'
        />
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h171v158H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SuccessSVGComponent;
