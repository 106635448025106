import Button from 'components/utils/Button';
import DisabledInput from 'components/utils/DisabledInput';
import Input from 'components/utils/Input';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthentication } from '../../../hooks/useAuthentication';

export interface SigninFormData {
  email: string;
  password: string;
}

interface SigninFormProps {
  email?: string;
  isLoading?: boolean;
}

const SigninForm = ({ email, isLoading }: SigninFormProps) => {
  const [signing, setSigning] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
    setValue,
  } = useForm<SigninFormData>({ defaultValues: { email: email || '', password: '' } });

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email]);

  const { signIn } = useAuthentication();
  const onSubmit = async (data: SigninFormData) => {
    setSigning(true);
    await signIn(data).catch(() => setSigning(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
      <div className='space-y-[8px]'>
        {email ? (
          <DisabledInput>{email}</DisabledInput>
        ) : (
          <Input placeholder='Email' {...register('email', { required: true })} type='email' />
        )}
        <Input
          placeholder='Password'
          {...register('password', { required: true })}
          type='password'
        />
        <div className='py-[5px] text-gray-700'>
          <Link to='/auth/forgotpassword'>Forgot your password?</Link>
        </div>
        <Button
          type='submit'
          disabled={(!email && !dirtyFields.email) || !dirtyFields.password || isLoading || signing}
        >
          {isLoading || signing ? 'Signing In...' : 'Sign In'}
        </Button>
      </div>
    </form>
  );
};

export default SigninForm;
