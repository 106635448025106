import Button from 'components/utils/Button';
import { Modal } from 'components/utils/modals/Modal';

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
  action: () => void;
}

const ConfirmationModal = ({ open, title, text, onClose, action }: ConfirmationModalProps) => {
  const onClickConfirm = () => {
    action();
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Head onClose={onClose}>{title}</Modal.Head>
      <Modal.Body>
        <div>{text}</div>
        <div className='flex mt-8 justify-end gap-2'>
          <div>
            <Button.Reverse onClick={onClose} className='py-2'>
              Cancel
            </Button.Reverse>
          </div>
          <div>
            <Button onClick={onClickConfirm} className='py-2'>
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
