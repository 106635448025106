import { StyledCharacterLimitText } from 'components/utils/styled-components/FormStyle';

interface FieldCharLimitProps {
  totalCount: number;
  currentCount: number;
  unit?: string;
}

const FieldCharLimit: React.FC<FieldCharLimitProps> = ({
  currentCount,
  totalCount,
  unit = 'characters',
}) => {
  return (
    <StyledCharacterLimitText>
      {currentCount}/{totalCount} {unit}
    </StyledCharacterLimitText>
  );
};

export default FieldCharLimit;
