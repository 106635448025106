import { Box, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import React from 'react';

const styles = makeStyles({
  card: {
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 20,
  },
  linkscard: {
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: 'none',
    marginBottom: 20,
  },
  maincard: {
    background: '#FCFCFC',
    width: '476px',
    minHeight: '310px',
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    marginTop: '20px',
    borderRadius: '30px',
    padding: '14px',
  },
  subtitle: {
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    color: '#848484',
    marginBottom: '10px',
  },
  inndercard: {
    background: '#F5F5F5',
    width: '446px',
    minHeight: '181px',
    borderRadius: '20px',
  },

  title: {
    fontSize: 24,
    color: 'black',
    marginBottom: 5,
  },
  mainTitle: {
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '29px',
    letterSpacing: '0px',
    color: '#000000',
    marginBottom: '10px',
  },
  linksMainDiv: {
    marginTop: '42px',
    marginLeft: '30px',
  },
  refrenceMainDiv: {
    marginTop: '-12px',
    marginLeft: '30px',
  },
  singleCardItem: {
    marginRight: 10,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },

  text: {
    fontSize: 17,
    marginTop: 10,
  },

  bullet: {
    fontSize: 17,
    color: '#EA8065',
  },

  readableButtonTextStyle: {
    color: '#08CA6B',
    fontSize: 16,
  },

  firstCardItemStyle: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },

  cardItemStyle: {
    marginLeft: 15,
    marginRight: 10,
  },
});

const InfoboxPreview = (props: any) => {
  const classes = styles();
  const { title, subtitle, isSubTitleEnabled, infobox_links, textJson, tiptapJSON, references } =
    props;

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '22px',
            lineHeight: '22px',
            color: '#000000',
          }}
        >
          Preview
        </Typography>
      </Box>
      <Divider style={{ marginTop: 8, marginBottom: 25 }} />
      <Card className={classes.maincard}>
        <CardContent>
          <Typography className={classes.mainTitle}>{title}</Typography>
          {isSubTitleEnabled ? (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          ) : (
            ''
          )}

          <AvoEditorPreview contentTiptap={tiptapJSON} contentDraftJS={textJson} />
        </CardContent>
      </Card>
      {infobox_links.length > 0 && (
        <div className={classes.linksMainDiv}>
          <p className={classes.title}> External Link </p>
          <Card className={classes.linkscard}>
            {infobox_links.map((info_link, i) => (
              <CardActionArea key={i} onClick={() => window.open(info_link.url)}>
                <CardContent
                  style={{
                    paddingTop: '0px',
                    paddingLeft: '0px',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography className={classes.readableButtonTextStyle} component='p'>
                    {info_link.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            ))}
          </Card>
        </div>
      )}

      {references.length > 0 && (
        <div
          className={classes.refrenceMainDiv}
          style={{ marginTop: infobox_links.length > 0 ? '-12px' : '42px' }}
        >
          <p className={classes.title}> References </p>
          <Card className={classes.linkscard}>
            {references.map((reference, i) => (
              <CardActionArea
                key={i}
                onClick={() => {
                  window.open(reference.url);
                }}
                className={classes.firstCardItemStyle}
              >
                <CardContent
                  style={{
                    paddingTop: '0px',
                    paddingLeft: '0px',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography className={classes.readableButtonTextStyle} component='p'>
                    {i + 1}. {reference.source}
                  </Typography>
                </CardContent>
              </CardActionArea>
            ))}
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default InfoboxPreview;
