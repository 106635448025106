import {
  Box,
  Button,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { MemberRow } from 'components/utils/styled-components/TableStyle';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { getInitials } from 'utils/utilityFunctions';
import { ROLES } from '../../../../../api/team';
import { HOME_ROUTE, TEAM_MATE_API_URL } from '../../../../../constants';
import { useAuthentication } from '../../../../../hooks/useAuthentication';
import { useTeam } from '../../../../../hooks/useTeam';
import { Initials } from '../../styles';
import {
  HeaderCell,
  MemberName,
  MemberRole,
  RoleContainer,
  StyledMenuItem,
  StyledTableCell,
  styles,
} from './styles';

const TeamMembers = ({ isLoading, resetTeams }) => {
  const classes = styles();
  const [userId, setUserId] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [isRedirect, setIsRedirect] = useState(false);
  const [deleteMemberModal, setDeleteMemberModal] = useState(false);

  const { authentication } = useAuthentication();
  const { team, refetchTeam } = useTeam();

  const noOfAdmins = () => {
    return team.teammates.filter((member) => member.role === ROLES.Admin)?.length;
  };

  const toggleDeleteMemberModal = (id = null, user_id = null) => {
    setMemberId(id);
    setUserId(user_id);
    setDeleteMemberModal((prev) => !prev);
  };

  const changeMemberRole = (e, memberId) => {
    const payload = {
      id: memberId,
      role: e.target.value,
    };

    axios.patch(`${TEAM_MATE_API_URL}${memberId}/`, payload).then(() => {
      refetchTeam();
      resetTeams();
    });
  };

  const deleteMember = () => {
    axios.delete(TEAM_MATE_API_URL + memberId + '/').then(() => {
      refetchTeam();
      // if logged in user deleted from team
      if (userId === authentication.user?.id) {
        setIsRedirect(true);
      } else {
        resetTeams();
      }
    });
    toggleDeleteMemberModal();
  };

  if (isRedirect) return <Redirect to={HOME_ROUTE} />;

  const members = team.teammates;
  const isAdmin = team.current_teammate.role === ROLES.Admin;

  return (
    <TableContainer style={{ borderRadius: '30px', border: '1px solid #DCDCDC' }}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <HeaderCell align='left'>Members</HeaderCell>
            <HeaderCell align='left'>Role</HeaderCell>
            <HeaderCell align='center'></HeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {members?.map((member, index) => (
            <MemberRow key={member.id} className='hover-enabler' style={{ height: '70px' }}>
              <StyledTableCell style={{ borderBottom: !members[index + 1] && 'none' }}>
                <Box className='d-flex align-items-center flex-row'>
                  <Initials>{getInitials(member.user.name, 1)}</Initials>
                  <MemberName> {member.user.name} </MemberName>
                </Box>
              </StyledTableCell>

              <StyledTableCell
                align='left'
                style={{
                  paddingLeft: '20px',
                  borderBottom: !members[index + 1] && 'none',
                }}
              >
                {isAdmin ? (
                  <RoleContainer>
                    <Select
                      type='select'
                      placeholder='Role'
                      className='member-select-dropdown'
                      classes={{
                        icon: classes.icon,
                        root: classes.select,
                      }}
                      inputProps={{
                        classes: {
                          root: classes.root,
                        },
                      }}
                      name='role'
                      value={member.role}
                      IconComponent={ExpandMoreIcon}
                      disableUnderline
                      onChange={(e) => changeMemberRole(e, member.id)}
                    >
                      <StyledMenuItem key='A' value='A'>
                        Admin
                      </StyledMenuItem>
                      <StyledMenuItem key='M' value='M'>
                        Member
                      </StyledMenuItem>
                    </Select>
                  </RoleContainer>
                ) : (
                  <MemberRole>{member.role_display_value}</MemberRole>
                )}
              </StyledTableCell>

              <StyledTableCell
                align='center'
                style={{ borderBottom: !members[index + 1] && 'none' }}
              >
                {isAdmin && !(noOfAdmins() === 1 && member.role === ROLES.Admin) && (
                  <Button
                    className='hover-item'
                    onClick={() => toggleDeleteMemberModal(member.id, member.user.id)}
                  >
                    <DeleteIcon style={{ color: '#FF0000' }}>delete</DeleteIcon>
                  </Button>
                )}
              </StyledTableCell>
            </MemberRow>
          ))}
        </TableBody>
      </Table>

      <ConfirmModal
        preset='delete'
        open={deleteMemberModal}
        content='Are you sure you want to delete this Team Member'
        toggleModal={toggleDeleteMemberModal}
        performAction={deleteMember}
      />
    </TableContainer>
  );
};

export default withStyles(styles)(TeamMembers);
