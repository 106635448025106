const ALGO_ROOT_ROUTE = '/algo';
export const ALGO_ROUTE = `${ALGO_ROOT_ROUTE}/:moduleId`;
export const ALGO_CREAT_ROUTE = ALGO_ROOT_ROUTE + '/create';
export const ALGO_FLOW_ROUTE = `${ALGO_ROUTE}/flow`;
export const ALGO_RESOURCES_ROUTE = `${ALGO_ROUTE}/resources/:resource`;
const CALC_ROOT_ROUTE = '/calculator';
export const CALC_ROUTE = CALC_ROOT_ROUTE + '/:moduleId';
export const CALC_CREATE_ROUTE = CALC_ROOT_ROUTE + '/create';
export const CALC_FLOW_ROUTE = CALC_ROUTE + '/flow';
export const CALC_EQUATIONS_ROUTE = CALC_ROUTE + '/equations';
export const CALC_OUTPUTS_ROUTE = CALC_ROUTE + '/outputs';
export const CALC_RESOURCES_ROUTE = CALC_ROUTE + '/resources/:resource';
// export const CALC_ROUTE = '/calculator';

const KB_ROOT_ROUTE = '/knowledge-base';
export const KB_ROUTE = KB_ROOT_ROUTE + '/:moduleId';
export const KB_CREATE_ROUTE = KB_ROOT_ROUTE + '/create';
export const KB_FLOW_ROUTE = KB_ROUTE + '/flow';
export const KB_CONTENT_ROUTE = KB_ROUTE + '/content';
export const LOGIN_ROUTE = '/auth/login';
export const SIGNUP_ROUTE = '/signup';
export const HOME_ROUTE = '/';
export const OLD_CREATE_TEAM_ROUTE = '/createTeam';
export const CREATE_TEAM_ROUTE = '/team/create';
export const MEM_MANGEMENT_ROUTE = '/member-managment';
export const CHANNELS_ROUTE = '/channels';
export const CHANNEL_CREATE_ROUTE = '/channels/create';
export const CHANNEL_ROUTE = '/channels/:channelId';
export const TAG_MANAGEMENT_ROUTE = '/tagmanagement';
export const EHR_CONFIG_ROUTE = '/ehr-config';
export const PROFILE_ROUTE = '/profile';
export const NUMBER_ROUTE = '/numbers';
export const SIMULATE_ROUTE = '/simulate';
export const OLD_EHR_ORDER_INDIVIDUAL_ROUTE = '/ehr-order/individual';
