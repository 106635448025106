import { HTMLAttributes } from 'react';

interface DisabledInputInterface extends HTMLAttributes<HTMLDivElement> {}

const DisabledInput = ({ children }: DisabledInputInterface) => {
  return (
    <div className='w-full border border-gray-300 py-[10px] px-[12px] bg-gray-50 text-gray-400'>
      {children}
    </div>
  );
};

export default DisabledInput;
