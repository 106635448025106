import { Modal } from '../utils/modals/Modal';
import { Body1, Body2, H3, H4 } from '../utils/typo';
import { NotificationHistory } from '../../api/notification';
import AvoMdLogo from '../../assets/images/logo.png';
import { format } from 'date-fns';

interface NotificationDetailModalProps {
  open: boolean;
  onClose: () => void;
  notification?: NotificationHistory;
}

export const NotificationDetailModal = ({
  open,
  onClose,
  notification,
}: NotificationDetailModalProps) => {
  return (
    <Modal open={open} onClose={onClose} className='!px-0'>
      <Modal.Head onClose={onClose} className='border-b px-[16px] py-[12px]'>
        <div className='space-y-4'>
          <H3>Notification Detail</H3>
        </div>
      </Modal.Head>
      <Modal.Body>
        <div className='space-y-[44px] p-8'>
          <div className='space-y-4'>
            <H4>Channels</H4>
            <div>
              <Body1 className='inline-block'>{notification?.channels}</Body1>
            </div>
          </div>
          <div className='space-y-4'>
            <H4>Preview</H4>
            <div className='flex items-center gap-2 rounded-3xl border border-gray-200 p-4 shadow-04'>
              <img src={AvoMdLogo} alt='avomd-logo' style={{ height: '28px', width: '28px' }} />
              <div>
                <H4>{notification?.title}</H4>
                <Body2>{notification?.message}</Body2>
              </div>
            </div>
          </div>
          <div>
            <Body1>{notification && `Sent on ${format(notification.created, 'P')}`}</Body1>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
