export default function Phone(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' y='0.5' width='59' height='59' rx='7.5' stroke='#E5EDF0' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M40.4069 35.1243L36.3553 31.9883C36.1596 31.8374 35.9156 31.7628 35.669 31.7788C35.4223 31.7947 35.1899 31.9 35.0153 32.0749L32.8648 34.2263C32.7046 34.3881 32.4945 34.4911 32.2685 34.5186C32.0425 34.5462 31.8138 34.4966 31.6195 34.378C30.9271 33.9534 29.7683 33.1307 28.3183 31.6798C26.8684 30.231 26.0456 29.0713 25.621 28.3799C25.5023 28.1858 25.4527 27.9571 25.4804 27.7312C25.5082 27.5053 25.6115 27.2954 25.7738 27.1357L27.9243 24.9843C28.0994 24.8098 28.2048 24.5773 28.2206 24.3305C28.2363 24.0838 28.1614 23.8398 28.0098 23.6444L24.8746 19.592C24.1619 18.6726 22.8178 18.527 21.9421 19.2958C20.7884 20.3078 19.4026 21.8239 18.9994 23.5253C18.2693 26.59 19.423 30.4356 24.4938 35.5061C29.5647 40.5756 33.4106 41.7303 36.4744 41.0012C38.1759 40.596 39.6931 39.2093 40.7052 38.0567C41.473 37.181 41.3274 35.8371 40.4069 35.1243Z'
        fill='#2E3438'
      />
    </svg>
  );
}
